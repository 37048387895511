import React from "react";
import { Switch, Route, NavLink } from "react-router-dom";
// import Invoices from "./Invoices";
import _ from "lodash";
import moment from "moment-timezone";
import { notify } from "react-notify-toast";
import { BillingAPI } from "apis";
import InvoicesWrapper from "./InvoicesWrapper";

const PATHS = {
  waitForI2wApproval: "/ops-mgmt/billing/invoices/wait-for-i2w-approval",
  waitForPubApproval: "/ops-mgmt/billing/invoices/wait-for-pub-approval",
  waitForIssue: "/ops-mgmt/billing/invoices/wait-for-issue",
  waitForPubAudit: "/ops-mgmt/billing/invoices/wait-for-publisher-audit",
  waitForReceivePayment: "/ops-mgmt/billing/invoices/wait-for-receive-payment",
  overdue: "/ops-mgmt/billing/invoices/overdue",
  paid: "/ops-mgmt/billing/invoices/paid",
  badDebt: "/ops-mgmt/billing/invoices/bad-debt",
  void: "/ops-mgmt/billing/invoices/void",
};

const TABS = [
  {
    name: "I2W Approval",
    path: PATHS.waitForI2wApproval,
    queryParams: {
      status: "UNDEFINED",
      i2wApprovalStatus: "DISAPPROVED",
      approvalStatus: "DISAPPROVED",
    },
    actions: ["ADVISE", "VOID"],
    columns: ["INVOICE_STATUS", "APPROVAL_STATUS"],
  },
  {
    name: "Publisher Approval",
    path: PATHS.waitForPubApproval,
    queryParams: {
      status: "UNDEFINED",
      i2wApprovalStatus: "APPROVED",
      approvalStatus: "DISAPPROVED",
    },
    actions: ["APPROVE", "VOID"],
    columns: ["INVOICE_STATUS", "APPROVAL_STATUS"],
  },
  {
    name: "Issue",
    path: PATHS.waitForIssue,
    queryParams: {
      status: "UNDEFINED",
      approvalStatus: "APPROVED",
    },
    actions: ["ISSUE", "VOID"],
    columns: ["INVOICE_STATUS", "APPROVAL_STATUS"],
  },
  {
    name: "Pending Publisher Audit",
    path: PATHS.waitForPubAudit,
    queryParams: {
      status: "PENDING_PUBLISHER_AUDIT",
      // approvalStatus: "APPROVED",
    },
    actions: ["AUDIT", "VOID"],
    columns: ["INVOICE_STATUS", "APPROVAL_STATUS"],
  },
  {
    name: "Account Receivable",
    path: PATHS.waitForReceivePayment,
    queryParams: {
      status: "VALID",
      paymentStatus: [
        "PENDING_PAYMENT_AUTHORIZATION",
        "AUTHORIZED_PAYMENT",
        "AUTH_FAILED",
      ],
    },
    actions: ["RECEIVE_PAYMENT", "VOID", "BAD_DEBT"],
    columns: ["PAYMENT_STATUS", "AFTER_TAX_AMOUNT", "DUE_DATE"],
  },
  {
    name: "Overdue",
    path: PATHS.overdue,
    queryParams: {
      status: "VALID",
      paymentStatus: [
        "PENDING_PAYMENT_AUTHORIZATION",
        "AUTHORIZED_PAYMENT",
        "AUTH_FAILED",
      ],
      overdueStatus: "OVERDUE",
    },
    actions: ["RECEIVE_PAYMENT", "VOID", "BAD_DEBT", "SEND_OVERDUE_EMAIL"],
    columns: ["PAYMENT_STATUS", "AFTER_TAX_AMOUNT", "DUE_DATE"],
  },
  {
    name: "Paid",
    path: PATHS.paid,
    queryParams: {
      status: "VALID",
      paymentStatus: "RECEIVED_PAYMENT",
    },
    actions: ["VOID"],
    columns: ["AFTER_TAX_AMOUNT", "RECEIVED_AMOUNT", "COLLECTION_DATE"],
  },
  {
    name: "Bad Debt",
    path: PATHS.badDebt,
    queryParams: {
      paymentStatus: "BAD_DEBT",
    },
    columns: [
      "INVOICE_STATUS",
      "APPROVAL_STATUS",
      // "PAYMENT_STATUS",
      "BADDEBT_DATE",
    ],
  },
  {
    name: "Void",
    path: PATHS.void,
    queryParams: {
      status: "INVALID",
    },
    columns: ["INVOICE_STATUS", "APPROVAL_STATUS", "PAYMENT_STATUS"],
  },
];

class InvoicesViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
    };

    this.onHandleCreateInvoices = this.onHandleCreateInvoices.bind(this);
  }

  async componentDidMount() {
    document.title = `Invoices | YB Observer`;
  }

  async onHandleCreateInvoices() {
    try {
      const currentYearMonth = moment().format("YYYY-MM");
      const userPrompt = window.prompt(
        `Please insert the year and month: YYYY-MM (ex. 2021-05)`,
        currentYearMonth
      );

      if (userPrompt === "") {
        notify.show("Create failed! Please insert the year and month", "error");
        return;
      }
      if (!userPrompt) return;

      notify.show("Creating Invoices...", "warning");
      // YYYY-MM
      const month = userPrompt;
      const r = await BillingAPI.createInvoices(month);

      console.log(r);
      const result = _.get(r, "result");
      if (result !== "ok") {
        throw result;
      }

      notify.show(
        "Created invoices! Automatically reloading page...",
        "success"
      );

      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
    } catch (err) {
      console.log(err);
      notify.show("Failed to create invoices", "error");
    }
  }

  render() {
    const { isSaving } = this.state;
    const activeTabClass =
      "text-sm py-2 px-4 bg-gray-200 text-gray-900 rounded-t-lg border-b-4 border-gray-200";
    const inactiveTabClass =
      "text-sm py-2 px-4 rounded-t-lg text-gray-500 bg-white hover:bg-gray-100";
    return (
      <div>
        <div className="bg-white px-12">
          <div className="mb-4 flex justify-between pt-8 ">
            <div className="text-4xl font-extrabold text-gray-900">
              Invoices
            </div>

            <div>
              <button
                type="button"
                disabled={isSaving}
                className={`rounded shadow bg-blue-400 px-4 py-2 font-semibold text-white hover:bg-blue-600
                    ${
                      isSaving
                        ? "cursor-not-allowed bg-gray-100"
                        : "bg-white hover:bg-gray-100"
                    }`}
                onClick={this.onHandleCreateInvoices}
              >
                Create Invoices
              </button>
            </div>
          </div>

          <div className="mb-2">
            {TABS.map((tab) => {
              return (
                <NavLink
                  key={tab.name}
                  to={tab.path}
                  className={inactiveTabClass}
                  // activeClassName="border-b border-blue-500"
                  activeClassName={activeTabClass}
                >
                  {tab.name}
                </NavLink>
              );
            })}
          </div>
        </div>

        <div className="min-h-screen bg-gray-200 px-12 py-8">
          <>
            <Switch>
              {TABS.map((tab) => {
                return (
                  <Route
                    key={tab.name}
                    path={tab.path}
                    render={() => {
                      return (
                        <InvoicesWrapper
                          queryParams={tab.queryParams}
                          actions={tab.actions}
                          columns={tab.columns}
                          tabName={tab.name}
                        ></InvoicesWrapper>
                      );
                    }}
                  ></Route>
                );
              })}
            </Switch>
          </>
        </div>
      </div>
    );
  }
}

export default InvoicesViewer;
