import React from "react";
import _ from "lodash";
import NumberFormat from "../common/NumberFormat";

class AdSizesReportTable extends React.PureComponent {
  render() {
    const { reports } = this.props;
    const totalImpression = _.sumBy(reports, "impression");
    const sizesReports = _(reports)
      .map(r => {
        r.impRatio = _.round((r.impression / totalImpression) * 100);
        return r;
      })
      .orderBy(["impression"], ["desc"])
      .value();
    // adSize, impression, revenue, ecpm

    return (
      <>
        <table className="default-table">
          <thead>
            <tr>
              <th className="text-right">AdSize ({sizesReports.length})</th>
              <th>eCPM</th>
              <th>Revenue</th>
              <th colSpan="2">Impression</th>
            </tr>
          </thead>
          <tbody>
            {sizesReports.map(d => {
              return (
                <tr key={d.adSize}>
                  <td className="text-right font-mono">{d.adSize}</td>
                  <td className="font-bold">
                    {d.ecpm > 0 ? (
                      <NumberFormat value={d.ecpm} prefix="$"></NumberFormat>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="text-right">
                    {d.revenue > 0 && (
                      <NumberFormat value={d.revenue} prefix="$"></NumberFormat>
                    )}
                  </td>
                  <td className="text-right" style={{ paddingRight: "0px" }}>
                    <div className="text-gray-600">
                      {d.impression > 0 ? (
                        <span>
                          (
                          <NumberFormat
                            value={d.impRatio}
                            format={`0,0`}
                            postfix="%"
                          ></NumberFormat>
                          )
                        </span>
                      ) : (
                        "-"
                      )}
                    </div>
                  </td>
                  <td className="text-right">
                    {d.impression > 0 && (
                      <NumberFormat
                        value={d.impression}
                        format={`0,0`}
                      ></NumberFormat>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }
}

export default AdSizesReportTable;
