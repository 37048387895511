import React from "react";
import _ from "lodash";

import NumberFormat from "components/common/NumberFormat";

function PricingTiersView({ tiers }) {
  return (
    <div className="flex w-1/2 flex-col gap-1 text-sm">
      {tiers.map((tier, i) => {
        return (
          <div
            key={i}
            className="border flex w-full items-center gap-4 px-2 py-1"
          >
            <div className="w-1/3">
              <div className="text-xs font-semibold leading-none text-gray-600">
                LOWER{" "}
                <span className="font-normal" style={{ fontSize: "smaller" }}>
                  ({">"})
                </span>
              </div>
              <div className="leading-none">
                <NumberFormat
                  value={tier.lowerBound}
                  format="0,0"
                ></NumberFormat>
              </div>
            </div>
            <div className="w-1/3">
              <div className="text-xs font-semibold leading-none text-gray-600">
                UPPER{" "}
                <span
                  className="text-xs font-normal"
                  style={{ fontSize: "smaller" }}
                >
                  ({"<="})
                </span>
              </div>
              <div className="leading-none">
                {i !== tiers.length - 1 ? (
                  <>
                    {"~ "}
                    <NumberFormat
                      value={tier.upperBound}
                      format="0,0"
                    ></NumberFormat>
                  </>
                ) : (
                  "~ "
                )}
              </div>
            </div>
            <div className="w-1/3">
              <div className="text-xs font-semibold leading-none text-gray-600">
                RATE
              </div>
              <div className="font-bold leading-none">
                <NumberFormat value={tier.rate} format="0,0.00"></NumberFormat>%
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default PricingTiersView;
