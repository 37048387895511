import React from "react";
import _ from "lodash";
import { FiInfo } from "react-icons/fi";
import PopupWrapper from "../../common/PopupWrapper";

class EstimateRevInfoPopover extends React.PureComponent {
  constructor(props) {
    super(props);

    this.child = React.createRef();
    this.handleClosePopover = this.handleClosePopover.bind(this);
  }

  handleClosePopover() {
    this.child.current.handleClosePopup();
  }

  render() {
    return (
      <PopupWrapper
        ref={this.child}
        // place="right bottom"
        triggerType="hover"
        hideArrow={false}
        triggerElement={
          <span className="ml-1 text-blue-400">
            <FiInfo></FiInfo>
          </span>
        }
        popupElement={
          <div
            className="p-4 shadow-xl bg-gray-100"
            // style={{
            //   width: "800px",
            // }}
          >
            <div>
              <div className="font-bold ">
                Calculation of Estimated Billable Revenue:
              </div>
              <div className="text-gray-800">
                <div className="text-sm mt-2">
                  1. If we have enough data from last month:
                </div>
                <div className="flex gap-2 text-sm font-semibold">
                  <div>=</div>
                  <div className="border">Rev(D)</div>
                  <div>+</div>
                  <div className="border">
                    (MD - D) * (Rev(LMD) - <i>Rev(LD)</i>) / (LMD - <i>LD</i>)
                  </div>
                </div>

                <div className="text-sm mt-2">
                  2. If we don't have enough data from last month:
                </div>
                <div className="flex gap-2 text-sm font-semibold">
                  <div>=</div>
                  <div className="border">Rev(D)</div>
                  <div>+</div>
                  <div className="border">(MD - D) * Rev(LMD) / LMD</div>
                </div>
              </div>

              <div className="text-xs text-gray-800 mt-4">
                <div className="border text-sm">
                  Rev(<b>$date</b>): Accumulated Billable Revenue of a given
                  date
                </div>

                <div className="border">
                  <table>
                    <tbody>
                      <tr>
                        <td className="text-right font-bold">D: </td>
                        <td>Current Day of Current Month</td>
                      </tr>
                      <tr>
                        <td className="text-right font-bold">MD: </td>
                        <td>Last Day of Current Month</td>
                      </tr>
                      <tr>
                        <td className="text-right font-bold">LD: </td>
                        <td>Current Day of Last Month</td>
                      </tr>
                      <tr>
                        <td className="text-right font-bold">LMD: </td>
                        <td>Last Day of Last Month</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        }
      ></PopupWrapper>
    );
  }
}

export default EstimateRevInfoPopover;
