import React from "react";
import _ from "lodash";
import YieldSetsSelector from "../../ops-mgmt/account/YieldSetsSelector";

class UnitsFilterSection extends React.Component {
  render() {
    const {
      networkId,

      ysFilter,
      inspFilter,
      reqTypeFilter,
      onboardTimeFilter,
      susModeFilter,
      debugModeFilter,
      selectedYieldSet,
      unitsFilterCountSummary,
    } = this.props;
    const ufc = unitsFilterCountSummary;

    return (
      <div className="border py-2 px-2 mb-2 bg-white">
        <div className="font-semibold text-sm mb-2 flex items-center gap-2">
          YieldSet:{" "}
          <button
            type="button"
            className={`px-2 py-1 mr-1 font-semibold rounded ${
              ysFilter === "ALL"
                ? "bg-indigo-100 text-indigo-800"
                : "text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
            }`}
            onClick={() =>
              this.props.handleUnitsFilterChanged("YIELD_SET", "ALL")
            }
          >
            ALL
          </button>
          <div className="w-1/2">
            {networkId && (
              <YieldSetsSelector
                networkId={networkId}
                handleOnChange={this.props.handleYsSelectChanged}
                selectedYieldSet={selectedYieldSet}
                defaultValue={ysFilter}
              ></YieldSetsSelector>
            )}
          </div>
        </div>

        <div className="font-semibold text-sm mb-2">
          INSP:{" "}
          <button
            type="button"
            className={`px-2 py-1 mr-1 font-semibold rounded ${
              inspFilter === "ALL"
                ? "bg-indigo-100 text-indigo-800"
                : "text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
            }`}
            onClick={() => this.props.handleUnitsFilterChanged("INSP", "ALL")}
          >
            ALL
          </button>
          <button
            type="button"
            className={`px-2 py-1 mr-1 font-semibold rounded ${
              inspFilter === "INSP_ONLY"
                ? "bg-indigo-100 text-indigo-800"
                : "text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
            }`}
            onClick={() =>
              this.props.handleUnitsFilterChanged("INSP", "INSP_ONLY")
            }
          >
            INSP only ({ufc.INSP_ONLY})
          </button>
          <button
            type="button"
            className={`px-2 py-1 mr-1 font-semibold rounded ${
              inspFilter === "EXCLUDE_INSP"
                ? "bg-indigo-100 text-indigo-800"
                : "text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
            }`}
            onClick={() =>
              this.props.handleUnitsFilterChanged("INSP", "EXCLUDE_INSP")
            }
          >
            Exclude INSP ({ufc.EXCLUDE_INSP})
          </button>
        </div>

        <div className="font-semibold text-sm mb-2">
          Req Type:{" "}
          <button
            type="button"
            className={`px-2 py-1 mr-1 font-semibold rounded ${
              reqTypeFilter === "ALL"
                ? "bg-indigo-100 text-indigo-800"
                : "text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
            }`}
            onClick={() =>
              this.props.handleUnitsFilterChanged("REQ_TYPE", "ALL")
            }
          >
            ALL
          </button>
          <button
            type="button"
            className={`px-2 py-1 mr-1 font-semibold rounded ${
              reqTypeFilter === "HAS_GPT"
                ? "bg-indigo-100 text-indigo-800"
                : "text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
            }`}
            onClick={() =>
              this.props.handleUnitsFilterChanged("REQ_TYPE", "HAS_GPT")
            }
          >
            Has GPT ({ufc.HAS_GPT})
          </button>
          <button
            type="button"
            className={`px-2 py-1 mr-1 font-semibold rounded ${
              reqTypeFilter === "HAS_AMP"
                ? "bg-indigo-100 text-indigo-800"
                : "text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
            }`}
            onClick={() =>
              this.props.handleUnitsFilterChanged("REQ_TYPE", "HAS_AMP")
            }
          >
            Has AMP ({ufc.HAS_AMP})
          </button>
        </div>

        <div className="font-semibold text-sm mb-2">
          Onboarded within last 7 days :{" "}
          <button
            type="button"
            className={`px-2 py-1 mr-1 font-semibold rounded ${
              onboardTimeFilter === "ALL"
                ? "bg-indigo-100 text-indigo-800"
                : "text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
            }`}
            onClick={() =>
              this.props.handleUnitsFilterChanged("ONBOARD_TIME", "ALL")
            }
          >
            ALL
          </button>
          <button
            type="button"
            className={`px-2 py-1 mr-1 font-semibold rounded ${
              onboardTimeFilter === "NEW_ONLY"
                ? "bg-indigo-100 text-indigo-800"
                : "text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
            }`}
            onClick={() =>
              this.props.handleUnitsFilterChanged("ONBOARD_TIME", "NEW_ONLY")
            }
          >
            Newly onboarded only ({ufc.NEW_ONLY})
          </button>
          <button
            type="button"
            className={`px-2 py-1 mr-1 font-semibold rounded ${
              onboardTimeFilter === "EXCLUDE_NEW"
                ? "bg-indigo-100 text-indigo-800"
                : "text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
            }`}
            onClick={() =>
              this.props.handleUnitsFilterChanged("ONBOARD_TIME", "EXCLUDE_NEW")
            }
          >
            Exclude newly onboarded ({ufc.EXCLUDE_NEW})
          </button>
        </div>

        <div className="font-semibold text-sm mb-2">
          Suspended :{" "}
          <button
            type="button"
            className={`px-2 py-1 mr-1 font-semibold rounded ${
              susModeFilter === "ALL"
                ? "bg-indigo-100 text-indigo-800"
                : "text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
            }`}
            onClick={() =>
              this.props.handleUnitsFilterChanged("SUS_MODE", "ALL")
            }
          >
            ALL
          </button>
          <button
            type="button"
            className={`px-2 py-1 mr-1 font-semibold rounded ${
              susModeFilter === "SUS_ONLY"
                ? "bg-indigo-100 text-indigo-800"
                : "text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
            }`}
            onClick={() =>
              this.props.handleUnitsFilterChanged("SUS_MODE", "SUS_ONLY")
            }
          >
            Suspended only ({ufc.SUS_ONLY})
          </button>
          <button
            type="button"
            className={`px-2 py-1 mr-1 font-semibold rounded ${
              susModeFilter === "EXCLUDE_SUS"
                ? "bg-indigo-100 text-indigo-800"
                : "text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
            }`}
            onClick={() =>
              this.props.handleUnitsFilterChanged("SUS_MODE", "EXCLUDE_SUS")
            }
          >
            Exclude suspended units ({ufc.EXCLUDE_SUS})
          </button>
        </div>

        <div className="font-semibold text-sm mb-2">
          Debug Mode :{" "}
          <button
            type="button"
            className={`px-2 py-1 mr-1 font-semibold rounded ${
              debugModeFilter === "ALL"
                ? "bg-indigo-100 text-indigo-800"
                : "text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
            }`}
            onClick={() =>
              this.props.handleUnitsFilterChanged("DEBUG_MODE", "ALL")
            }
          >
            ALL
          </button>
          <button
            type="button"
            className={`px-2 py-1 mr-1 font-semibold rounded ${
              debugModeFilter === "DEBUG_ONLY"
                ? "bg-indigo-100 text-indigo-800"
                : "text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
            }`}
            onClick={() =>
              this.props.handleUnitsFilterChanged("DEBUG_MODE", "DEBUG_ONLY")
            }
          >
            Debug mode only ({ufc.DEBUG_ONLY})
          </button>
          <button
            type="button"
            className={`px-2 py-1 mr-1 font-semibold rounded ${
              debugModeFilter === "EXCLUDE_DEBUG"
                ? "bg-indigo-100 text-indigo-800"
                : "text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
            }`}
            onClick={() =>
              this.props.handleUnitsFilterChanged("DEBUG_MODE", "EXCLUDE_DEBUG")
            }
          >
            Exclude debug mode ({ufc.EXCLUDE_DEBUG})
          </button>
        </div>
      </div>
    );
  }
}

export default UnitsFilterSection;
