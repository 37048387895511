import _ from "lodash";
import moment from "moment-timezone";

// to pretend if user is in this timezone
// moment.tz.setDefault("America/New_York");

export function formatMoneyWithCurrency({ currency, value }) {
  return Number(value).toLocaleString("en-US", {
    style: "currency",
    currency,
  });
}

export function transformHomeDashboardData(homeDashboardData) {
  if (!homeDashboardData) return;
  let data = homeDashboardData;

  data.exchangeRates = _transformExchangeRates(data.exchangeRates);

  data.gamNetworkStatusReport = _transformToJson(data.gamNetworkStatusReport);
  data.gamNetworkPerformanceReport = _transformToJson(
    data.gamNetworkPerformanceReport
  );
  data.yieldSetStatusReport = _transformToJson(data.yieldSetStatusReport);
  data.yieldSetPerformanceReport = _transformToJson(
    data.yieldSetPerformanceReport
  );

  const pubIdByNetworkId = _.reduce(
    data.gamNetworks,
    (result, n) => {
      result[n.id] = n.pubId;
      return result;
    },
    {}
  );

  _.forEach(data.gamNetworkPerformanceReport, (r) => {
    // r.date: "2021-05-25T00:00:00.000Z"
    r.date = r.date.slice(0, 10); // 2021-05-25
    // r.date = moment.utc(r.date).format("YYYY-MM-DD");
    r.dateMs = moment.utc(r.date).unix();

    // to simplify filter
    r.pubId = pubIdByNetworkId[r.gamNetworkId];

    // all metrics are string
    r.boostingRequests = _.parseInt(r.boostingRequests);
    r.cost = _.parseInt(r.cost);
    r.increasedRevenue = _.parseInt(r.increasedRevenue);
    r.notOnboardedRevenue = _.parseInt(r.notOnboardedRevenue);
    r.onboardedGoogleAdImpression = _.parseInt(r.onboardedGoogleAdImpression);
    r.onboardedRequests = _.parseInt(r.onboardedRequests);
    r.originalRevenue = _.parseInt(r.originalRevenue);
    r.remnentRequests = _.parseInt(r.remnentRequests);
    r.totalRequests = _.parseInt(r.totalRequests);
    r.unmanagedRevenue = _.parseInt(r.unmanagedRevenue);
  });

  _.forEach(data.yieldSetPerformanceReport, (r) => {
    // r.date: "2021-05-25T00:00:00.000Z"
    r.date = r.date.slice(0, 10); // 2021-05-25
    // r.date = moment.utc(r.date).format("YYYY-MM-DD");
    r.dateMs = moment.utc(r.date).unix();

    // to simplify filter
    r.pubId = pubIdByNetworkId[r.gamNetworkId];

    // all metrics are string
    r.boostingRequests = _.parseInt(r.boostingRequests);
    r.cost = _.parseInt(r.cost);
    r.increasedRevenue = _.parseInt(r.increasedRevenue);
    r.notOnboardedRevenue = _.parseInt(r.notOnboardedRevenue);
    r.onboardedGoogleAdImpression = _.parseInt(r.onboardedGoogleAdImpression);
    r.onboardedRequests = _.parseInt(r.onboardedRequests);
    r.originalRevenue = _.parseInt(r.originalRevenue);
    r.remnentRequests = _.parseInt(r.remnentRequests);
    r.totalRequests = _.parseInt(r.totalRequests);
    r.unmanagedRevenue = _.parseInt(r.unmanagedRevenue);
  });

  _.forEach(data.gamNetworkStatusReport, (r) => {
    // to simplify filter
    r.pubId = pubIdByNetworkId[r.gamNetworkId];

    r.compatibleRequests = _.parseInt(r.compatibleRequests);
    r.compatibleUnitCount = _.parseInt(r.compatibleUnitCount);

    r.onboardedRequests = _.parseInt(r.onboardedRequests);
    r.onboardedUnitCount = _.parseInt(r.onboardedUnitCount);

    r.runningRequests = _.parseInt(r.runningRequests);
    r.runningUnitCount = _.parseInt(r.runningUnitCount);

    r.totalUnitCount = _.parseInt(r.totalUnitCount);
  });

  _.forEach(data.yieldSetStatusReport, (r) => {
    // to simplify filter
    r.pubId = pubIdByNetworkId[r.gamNetworkId];

    r.onboardedUnitCount = _.parseInt(r.onboardedUnitCount);
    r.runningUnitCount = _.parseInt(r.runningUnitCount);
    r.onboardedRequests = _.parseInt(r.onboardedRequests);
    r.runningRequests = _.parseInt(r.runningRequests);
  });

  const publishers = _.map(data.publishers, (pub) => {
    const pubNetworks = _.filter(data.gamNetworks, { pubId: pub.id });
    pub.networkIds = _.map(pubNetworks, "id");
    pub.hasPerfData = _.some(pubNetworks, { hasPerfData: true });
    return pub;
  });

  data.publishers = _.orderBy(
    publishers,
    ["hasPerfData", "id"],
    ["desc", "asc"]
  );

  data.gamNetworks = _.orderBy(
    data.gamNetworks,
    ["hasPerfData", "id"],
    ["desc", "asc"]
  );

  data.yieldSets = _.orderBy(
    data.yieldSets,
    ["hasPerfData", "id"],
    ["desc", "asc"]
  );

  return data;
}

export function calculateTrendData({
  homeDashboardData,
  selectedPubId,
  selectedNetworkId,
  selectedYieldSetIds,
  startDate,
  endDate,
  exchangeRates,
}) {
  // Rev Trend:
  //    revenueWithYB (notOnboardedRevenue + unmanagedRevenue + originalRevenue + increasedRevenue)
  //    revenueWithoutYB (notOnboardedRevenue + unmanagedRevenue + originalRevenue)
  //    increasedRevenue, originalRevenue, unnmanagedRevenue, notOnboardedRevenue, cost
  // RPM Trend:
  //    rRpmWithYB ((notOnboardedRevenue + unmanagedRevenue + originalRevenue + increasedRevenue) / totalRequests)
  //    rRpmWithoutYB ((notOnboardedRevenue + unmanagedRevenue + originalRevenue) / totalRequests)
  // Req Trend:
  //    totalRequests, onboardedRequests, remnentRequests, boostingRequests
  // Win Rate Trend:
  //    winRate (onboardedGoogleAdImpression / totalReqeusts)

  // netIncreasedRevenue,
  // totalRequests, onboardedRequests, remnentRequests, boostingRequests
  // onboardedRate, remnentRate, boostingRate
  let reports = [];
  if (selectedPubId === -1) {
    reports = _.filter(homeDashboardData.gamNetworkPerformanceReport, (r) => {
      return _isDateBetween({ date: r.date, startDate, endDate });
    });
  } else if (
    selectedPubId !== -1 &&
    selectedNetworkId === -1 &&
    selectedYieldSetIds === -1
  ) {
    reports = _.filter(homeDashboardData.gamNetworkPerformanceReport, (r) => {
      return (
        _isDateBetween({ date: r.date, startDate, endDate }) &&
        r.pubId === selectedPubId
      );
    });
  } else if (selectedNetworkId !== -1 && selectedYieldSetIds === -1) {
    reports = _.filter(homeDashboardData.gamNetworkPerformanceReport, (r) => {
      return (
        _isDateBetween({ date: r.date, startDate, endDate }) &&
        r.gamNetworkId === selectedNetworkId
      );
    });
  } else if (selectedYieldSetIds !== -1) {
    reports = _.filter(homeDashboardData.yieldSetPerformanceReport, (r) => {
      return (
        _isDateBetween({ date: r.date, startDate, endDate }) &&
        _.indexOf(selectedYieldSetIds, r.yieldSetId) !== -1
      );
    });
  }

  // by date
  let dataMap = {};
  _.forEach(reports, (r) => {
    if (!dataMap[r.date]) {
      dataMap[r.date] = {
        date: r.date,
        dateMs: r.dateMs,
        increasedRevenue: 0,
        newOriginalRevenue: 0,
        originalRevenue: 0,
        unmanagedRevenue: 0,
        notOnboardedRevenue: 0,
        cost: 0,
        totalRequests: 0,
        onboardedRequests: 0,
        remnentRequests: 0,
        boostingRequests: 0,
        onboardedGoogleAdImpression: 0,
      };
    }
    let {
      date,
      increasedRevenue,
      originalRevenue,
      unmanagedRevenue,
      notOnboardedRevenue,
      cost,
      totalRequests,
      onboardedRequests,
      remnentRequests,
      boostingRequests,
      onboardedGoogleAdImpression,
    } = r;

    // currency is different per month
    const month = date.substring(0, "2020-12".length);
    const currencyRate = exchangeRates[month];

    increasedRevenue = _normalizeRevenue(increasedRevenue * currencyRate);
    originalRevenue = _normalizeRevenue(originalRevenue * currencyRate);
    unmanagedRevenue = _normalizeRevenue(unmanagedRevenue * currencyRate);
    notOnboardedRevenue = _normalizeRevenue(notOnboardedRevenue * currencyRate);
    cost = _normalizeRevenue(cost * currencyRate);

    dataMap[r.date].increasedRevenue += _.round(increasedRevenue, 2);
    dataMap[r.date].originalRevenue += _.round(originalRevenue, 2);
    dataMap[r.date].newOriginalRevenue +=
      _.round(originalRevenue, 2) + _.round(unmanagedRevenue, 2);
    dataMap[r.date].unmanagedRevenue += _.round(unmanagedRevenue, 2);
    dataMap[r.date].notOnboardedRevenue += _.round(notOnboardedRevenue, 2);
    dataMap[r.date].cost += _.round(cost, 2);

    dataMap[r.date].totalRequests += totalRequests;
    dataMap[r.date].onboardedRequests += onboardedRequests;
    dataMap[r.date].remnentRequests += remnentRequests;
    dataMap[r.date].boostingRequests += boostingRequests;

    dataMap[r.date].onboardedGoogleAdImpression += onboardedGoogleAdImpression;
  });

  const rrr = _.map(dataMap, (r) => {
    // const revenueWithYB =
    //   r.notOnboardedRevenue +
    //   r.unmanagedRevenue +
    //   r.originalRevenue +
    //   r.increasedRevenue;
    // const revenueWithoutYB =
    //   r.notOnboardedRevenue + r.unmanagedRevenue + r.originalRevenue;

    const rWithYB = r.originalRevenue + r.increasedRevenue;
    const rWithoutYB = r.originalRevenue;

    const rRpmWithYB =
      r.boostingRequests > 0 ? (rWithYB / r.boostingRequests) * 1000 : 0;
    const rRpmWithoutYB =
      r.boostingRequests > 0 ? (rWithoutYB / r.boostingRequests) * 1000 : 0;
    // const winRate = r.onboardedGoogleAdImpression / r.totalRequests;

    const netIncreasedRevenue = r.increasedRevenue - r.cost;
    const grossRevenueLift =
      r.originalRevenue > 0
        ? (netIncreasedRevenue / r.originalRevenue) * 100
        : 0;
    const managedScore =
      r.remnentRequests > 0
        ? (r.boostingRequests / r.remnentRequests) * 100
        : 0;

    const perceivedLift =
      r.unmanagedRevenue > 0
        ? (netIncreasedRevenue / (r.unmanagedRevenue + r.originalRevenue)) * 100
        : 0;

    r.rRpmWithYB = _.round(rRpmWithYB, 2);
    r.rRpmWithoutYB = _.round(rRpmWithoutYB, 2);
    r.perceivedLift = _.round(perceivedLift, 2);
    // r.winRate = _.round(winRate * 100, 2);
    // r.revenueWithYB = _.round(revenueWithYB, 2);
    // r.revenueWithoutYB = _.round(revenueWithoutYB, 2);
    r.grossRevenueLift = _.round(grossRevenueLift, 2);
    r.managedScore = _.round(managedScore, 2);

    // Important! filter out data from when we are not optimizing but has a tiny bit of request
    if (r.managedScore < 0.1) {
      r.grossRevenueLift = 0;
      r.perceivedLift = 0;
      r.rRpmWithYB = 0;
      r.rRpmWithoutYB = 0;
    }

    return r;
  });

  return _.orderBy(rrr, ["dateMs"], ["asc"]);
}

export function calculateDistributionData({
  homeDashboardData,
  // selectedNetworkId, // highlight
  // selectedYieldSetIds, // highlight
  startDate, // filter
  endDate, // filter
  exchangeRates,
}) {
  // ad revenue (googleAdRevenue):
  // notOnboardedRevenue + unmanagedRevenue + originalRevenue + increasedRevenue
  // Net increased revenue (netIncreasedRevenue):
  // increasedRevenue - cost
  let publishersMap = {};
  let networksMap = {};
  let yieldSetsMap = {};

  _.forEach(homeDashboardData.yieldSetPerformanceReport, (r) => {
    if (!_isDateBetween({ date: r.date, startDate, endDate })) {
      return;
    }

    // currency is different per month
    const month = r.date.substring(0, "2020-10".length);
    const currencyRate = exchangeRates[month];

    // notOnboardedRevenue + unmanagedRevenue + originalRevenue + increasedRevenue
    const notOnboardedRevenue = r.notOnboardedRevenue * currencyRate;
    const unmanagedRevenue = r.unmanagedRevenue * currencyRate;
    const originalRevenue = r.originalRevenue * currencyRate;
    const increasedRevenue = r.increasedRevenue * currencyRate;
    // increasedRevenue - cost
    const cost = r.cost * currencyRate;

    if (!publishersMap[r.pubId]) {
      publishersMap[r.pubId] = {
        id: r.pubId,
        name: _.find(homeDashboardData.publishers, { id: r.pubId }).name,
        notOnboardedRevenue,
        unmanagedRevenue,
        originalRevenue,
        increasedRevenue,
        cost,
      };
    } else {
      publishersMap[r.pubId].notOnboardedRevenue += notOnboardedRevenue;
      publishersMap[r.pubId].unmanagedRevenue += unmanagedRevenue;
      publishersMap[r.pubId].originalRevenue += originalRevenue;
      publishersMap[r.pubId].increasedRevenue += increasedRevenue;
      publishersMap[r.pubId].cost += cost;
    }

    if (!networksMap[r.gamNetworkId]) {
      networksMap[r.gamNetworkId] = {
        id: r.gamNetworkId,
        name: _.find(homeDashboardData.gamNetworks, { id: r.gamNetworkId })
          .name,
        pubId: r.pubId, // for later filtering purposes
        notOnboardedRevenue,
        unmanagedRevenue,
        originalRevenue,
        increasedRevenue,
        cost,
      };
    } else {
      networksMap[r.gamNetworkId].notOnboardedRevenue += notOnboardedRevenue;
      networksMap[r.gamNetworkId].unmanagedRevenue += unmanagedRevenue;
      networksMap[r.gamNetworkId].originalRevenue += originalRevenue;
      networksMap[r.gamNetworkId].increasedRevenue += increasedRevenue;
      networksMap[r.gamNetworkId].cost += cost;
    }

    if (!yieldSetsMap[r.yieldSetId]) {
      yieldSetsMap[r.yieldSetId] = {
        id: r.yieldSetId,
        name: _.find(homeDashboardData.yieldSets, { id: r.yieldSetId }).name,
        gamNetworkId: r.gamNetworkId,
        notOnboardedRevenue,
        unmanagedRevenue,
        originalRevenue,
        increasedRevenue,
        cost,
      };
    } else {
      yieldSetsMap[r.yieldSetId].notOnboardedRevenue += notOnboardedRevenue;
      yieldSetsMap[r.yieldSetId].unmanagedRevenue += unmanagedRevenue;
      yieldSetsMap[r.yieldSetId].originalRevenue += originalRevenue;
      yieldSetsMap[r.yieldSetId].increasedRevenue += increasedRevenue;
      yieldSetsMap[r.yieldSetId].cost += cost;
    }
  });

  let pTotalGoogleAdRevenue = 0;
  let pTotalNetIncreasedRevenue = 0;
  let publishers = _.map(publishersMap, (n) => {
    const {
      id,
      name,
      notOnboardedRevenue,
      unmanagedRevenue,
      originalRevenue,
      increasedRevenue,
      cost,
    } = n;
    const googleAdRevenue =
      notOnboardedRevenue +
      unmanagedRevenue +
      originalRevenue +
      increasedRevenue;
    const netIncreasedRevenue = increasedRevenue - cost;

    pTotalGoogleAdRevenue += googleAdRevenue;
    pTotalNetIncreasedRevenue += netIncreasedRevenue;

    return {
      id,
      name,
      googleAdRevenue: _.round(_normalizeRevenue(googleAdRevenue), 2),
      // googleAdRevenuePercentage:
      netIncreasedRevenue: _.round(_normalizeRevenue(netIncreasedRevenue), 2),
      // netIncreasedRevenuePercentage:
    };
  });
  pTotalGoogleAdRevenue = _normalizeRevenue(pTotalGoogleAdRevenue);
  pTotalNetIncreasedRevenue = _normalizeRevenue(pTotalNetIncreasedRevenue);
  publishers = _.map(publishers, (p) => {
    p.googleAdRevenuePercentage = _.round(
      (p.googleAdRevenue / pTotalGoogleAdRevenue) * 100,
      2
    );
    p.netIncreasedRevenuePercentage = _.round(
      (p.netIncreasedRevenue / pTotalNetIncreasedRevenue) * 100,
      2
    );
    return p;
  });

  let nTotalGoogleAdRevenue = 0;
  let nTotalNetIncreasedRevenue = 0;
  let networks = _.map(networksMap, (n) => {
    const {
      id,
      name,
      pubId,
      notOnboardedRevenue,
      unmanagedRevenue,
      originalRevenue,
      increasedRevenue,
      cost,
    } = n;
    const googleAdRevenue =
      notOnboardedRevenue +
      unmanagedRevenue +
      originalRevenue +
      increasedRevenue;
    const netIncreasedRevenue = increasedRevenue - cost;

    nTotalGoogleAdRevenue += googleAdRevenue;
    nTotalNetIncreasedRevenue += netIncreasedRevenue;

    return {
      id,
      name,
      pubId,
      googleAdRevenue: _.round(_normalizeRevenue(googleAdRevenue), 2),
      // googleAdRevenuePercentage:
      netIncreasedRevenue: _.round(_normalizeRevenue(netIncreasedRevenue), 2),
      // netIncreasedRevenuePercentage:
    };
  });
  nTotalGoogleAdRevenue = _normalizeRevenue(nTotalGoogleAdRevenue);
  nTotalNetIncreasedRevenue = _normalizeRevenue(nTotalNetIncreasedRevenue);
  networks = _.map(networks, (n) => {
    n.googleAdRevenuePercentage = _.round(
      (n.googleAdRevenue / nTotalGoogleAdRevenue) * 100,
      2
    );
    n.netIncreasedRevenuePercentage = _.round(
      (n.netIncreasedRevenue / nTotalNetIncreasedRevenue) * 100,
      2
    );
    return n;
  });

  let ysTotalGoogleAdRevenue = 0;
  let ysTotalNetIncreasedRevenue = 0;
  let yieldSets = _.map(yieldSetsMap, (n) => {
    const {
      id,
      name,
      gamNetworkId,
      notOnboardedRevenue,
      unmanagedRevenue,
      originalRevenue,
      increasedRevenue,
      cost,
    } = n;
    const googleAdRevenue =
      notOnboardedRevenue +
      unmanagedRevenue +
      originalRevenue +
      increasedRevenue;
    const netIncreasedRevenue = increasedRevenue - cost;

    ysTotalGoogleAdRevenue += googleAdRevenue;
    ysTotalNetIncreasedRevenue += netIncreasedRevenue;

    return {
      id,
      name,
      gamNetworkId,
      googleAdRevenue: _.round(_normalizeRevenue(googleAdRevenue), 2),
      netIncreasedRevenue: _.round(_normalizeRevenue(netIncreasedRevenue), 2),
    };
  });

  ysTotalGoogleAdRevenue = _normalizeRevenue(ysTotalGoogleAdRevenue);
  ysTotalNetIncreasedRevenue = _normalizeRevenue(ysTotalNetIncreasedRevenue);
  yieldSets = _.map(yieldSets, (n) => {
    n.googleAdRevenuePercentage = _.round(
      (n.googleAdRevenue / ysTotalGoogleAdRevenue) * 100,
      2
    );
    n.netIncreasedRevenuePercentage = _.round(
      (n.netIncreasedRevenue / ysTotalNetIncreasedRevenue) * 100,
      2
    );
    return n;
  });

  // publishers: [{ id, name, metric... }]
  // networks: [{ id, name, metric... }]
  // yieldSets: [{ id, name, metric... }]
  return {
    publishers: _.orderBy(publishers, ["googleAdRevenue"], ["desc"]),
    networks: _.orderBy(networks, ["googleAdRevenue"], ["desc"]),
    yieldSets: _.orderBy(yieldSets, ["googleAdRevenue"], ["desc"]),
  };
}

function _isDateBetween({ date, startDate, endDate }) {
  return moment(date).isBetween(startDate, endDate, undefined, "[]");
}

export function calculateSummaryData({
  homeDashboardData,
  selectedPubId,
  selectedNetworkId,
  selectedYieldSetIds,
  startDate,
  endDate,
  exchangeRates,
}) {
  // netIncreasedRevenue, perceivedRevenueLift, grossRevenueLift (Managed Lift)
  // totalRequests, onboardedRequests, remnentRequests, boostingRequests
  // onboardedRate, remnentRate, boostingRate
  let reports = [];

  if (selectedPubId === -1) {
    reports = _.filter(homeDashboardData.gamNetworkPerformanceReport, (r) => {
      return _isDateBetween({ date: r.date, startDate, endDate });
    });
  } else if (
    selectedPubId !== -1 &&
    selectedNetworkId === -1 &&
    selectedYieldSetIds === -1
  ) {
    reports = _.filter(homeDashboardData.gamNetworkPerformanceReport, (r) => {
      return (
        _isDateBetween({ date: r.date, startDate, endDate }) &&
        r.pubId === selectedPubId
      );
    });
  } else if (selectedNetworkId !== -1 && selectedYieldSetIds === -1) {
    reports = _.filter(homeDashboardData.gamNetworkPerformanceReport, (r) => {
      return (
        _isDateBetween({ date: r.date, startDate, endDate }) &&
        r.gamNetworkId === selectedNetworkId
      );
    });
  } else if (selectedYieldSetIds !== -1) {
    reports = _.filter(homeDashboardData.yieldSetPerformanceReport, (r) => {
      return (
        _isDateBetween({ date: r.date, startDate, endDate }) &&
        _.indexOf(selectedYieldSetIds, r.yieldSetId) !== -1
      );
    });
  }

  let revData = {
    increasedRevenue: 0,
    cost: 0,
    unmanagedRevenue: 0,
    originalRevenue: 0,
  };

  let data = {
    netIncreasedRevenue: 0, // increasedRevenue - cost
    totalRequests: 0,
    onboardedRequests: 0,
    remnentRequests: 0,
    boostingRequests: 0,
  };

  _.forEach(reports, (r) => {
    // currency is different per month
    const month = r.date.substring(0, "2020-10".length);
    const currencyRate = exchangeRates[month];

    revData.increasedRevenue += r.increasedRevenue * currencyRate;
    revData.unmanagedRevenue += r.unmanagedRevenue * currencyRate;
    revData.originalRevenue += r.originalRevenue * currencyRate;
    revData.cost += r.cost * currencyRate;

    data.totalRequests += r.totalRequests;
    data.onboardedRequests += r.onboardedRequests;
    data.remnentRequests += r.remnentRequests;
    data.boostingRequests += r.boostingRequests;
  });

  data.onboardedRate = _.round(
    (data.onboardedRequests / data.totalRequests) * 100,
    2
  );
  data.remnentRate = _.round(
    (data.remnentRequests / data.onboardedRequests) * 100,
    2
  );
  data.boostingRate = _.round(
    (data.boostingRequests / data.remnentRequests) * 100,
    2
  );

  data.netIncreasedRevenue = revData.increasedRevenue - revData.cost;

  const perceivedLift =
    revData.unmanagedRevenue > 0
      ? ((revData.increasedRevenue - revData.cost) /
          (revData.unmanagedRevenue + revData.originalRevenue)) *
        100
      : 0;

  const grossRevenueLift =
    revData.originalRevenue > 0
      ? (data.netIncreasedRevenue / revData.originalRevenue) * 100
      : 0;

  data.netIncreasedRevenue = _.round(
    _normalizeRevenue(data.netIncreasedRevenue),
    2
  );
  data.perceivedLift = _.round(perceivedLift, 2);
  data.grossRevenueLift = _.round(grossRevenueLift, 2);

  return data;
}

export function calculateInventoryData({
  homeDashboardData,
  selectedPubId,
  selectedNetworkId,
  selectedYieldSetIds,
}) {
  // totalUnits, compatibleUnits, onboardedUnits, runningUnits
  // compatibleRate, onboardedRate, runningRate
  // onboardingScore, runningScore
  // compatibleRequests, onboardedRequests, runningRequests
  let reports = homeDashboardData.gamNetworkStatusReport;

  if (
    selectedPubId !== -1 &&
    selectedNetworkId === -1 &&
    selectedYieldSetIds === -1
  ) {
    reports = _.filter(homeDashboardData.gamNetworkStatusReport, (r) => {
      return r.pubId === selectedPubId;
    });
  } else if (selectedNetworkId !== -1 && selectedYieldSetIds === -1) {
    reports = _.filter(homeDashboardData.gamNetworkStatusReport, {
      gamNetworkId: selectedNetworkId,
    });
  } else if (selectedYieldSetIds !== -1) {
    reports = _.filter(homeDashboardData.yieldSetStatusReport, (r) => {
      return _.indexOf(selectedYieldSetIds, r.yieldSetId) !== -1;
    });
  }

  let data = {
    totalUnits: 0,
    compatibleUnits: 0,
    onboardedUnits: 0,
    runningUnits: 0,

    compatibleRequests: 0,
    onboardedRequests: 0,
    runningRequests: 0,
  };

  _.forEach(reports, (r) => {
    data.totalUnits += r.totalUnitCount;
    data.compatibleUnits += r.compatibleUnitCount;
    data.onboardedUnits += r.onboardedUnitCount;
    data.runningUnits += r.runningUnitCount;

    data.compatibleRequests += r.compatibleRequests;
    data.onboardedRequests += r.onboardedRequests;
    data.runningRequests += r.runningRequests;
  });

  data.compatibleUnitsRate =
    data.totalUnits > 0
      ? _.round((data.compatibleUnits / data.totalUnits) * 100, 2)
      : 0;
  data.onboardedUnitsRate =
    data.compatibleUnits > 0
      ? _.round((data.onboardedUnits / data.compatibleUnits) * 100, 2)
      : 0;
  data.runningUnitsRate =
    data.onboardedUnits > 0
      ? _.round((data.runningUnits / data.onboardedUnits) * 100, 2)
      : 0;
  data.onboardingScore =
    data.compatibleRequests > 0
      ? _.round((data.onboardedRequests / data.compatibleRequests) * 100, 2)
      : 0;
  data.runningScore =
    data.onboardedRequests > 0
      ? _.round((data.runningRequests / data.onboardedRequests) * 100, 2)
      : 0;

  return data;
}

function _normalizeRevenue(revenue) {
  return revenue / 1000000;
}

function _transformExchangeRates(exchangeRates) {
  /*
  {
    USD: {
      "2020-09": 1
    },
    ...
  }
  */
  let currencyMap = {};
  _.forEach(exchangeRates, (e) => {
    currencyMap[e.currency] = _.reduce(
      e.rates,
      (result, r) => {
        const month = r[0];
        const rate = r[1];
        result[month] = rate;
        return result;
      },
      {}
    );
  });

  return currencyMap;
}

function _transformToJson(reports) {
  // transform reports to json format
  const { cols, records } = reports;

  return _.reduce(
    records,
    (result, row) => {
      let r = {};
      _.forEach(cols, (column, index) => {
        r[column] = row[index];
      });
      result.push(r);
      return result;
    },
    []
  );
}
