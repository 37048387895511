import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import { Switch, Route, NavLink, withRouter } from "react-router-dom";
import { NetworkAPI } from "apis";
import LoadingUI from "components/common/LoadingUI";

const DIAGNOSER_SYMPTOMS = ["ad-unit-ecpm-monitor", "ad-unit-ivt-monitor"];

class SymptomsViewer extends React.Component {
  constructor(props) {
    super(props);

    let dates = new Array(7);
    const today = moment.utc();
    dates = _.map(dates, (d, index) => {
      return today.clone().subtract(index, "day").format("YYYY-MM-DD");
    });

    this.state = {
      dates,
      symptoms: null,
      isLoading: false,
      errMsg: null,

      currentDate: dates[1],
      currentSymptomName: null, //DIAGNOSER_SYMPTOMS[0],
    };
    this.handleCurrentSymptomChanged =
      this.handleCurrentSymptomChanged.bind(this);
    this.handleCurrentDateChanged = this.handleCurrentDateChanged.bind(this);
  }

  async componentDidMount() {
    const networkId = _.get(this.props, "networkId");

    if (networkId) {
      document.title = `${networkId} | Network Symptoms`;
    }

    this.setState({ isLoading: true });
    try {
      const symptoms = await NetworkAPI.getDiagnoserSymptoms();
      this.setState({
        symptoms,
        currentSymptomName: symptoms[0],
        isLoading: false,
        errMsg: null,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        errMsg: err,
        isLoading: false,
      });
    }
  }

  handleCurrentSymptomChanged(symptomName) {
    this.setState({ currentSymptomName: symptomName });
  }

  handleCurrentDateChanged(date) {
    this.setState({ currentDate: date });
  }

  render() {
    const { networkId } = this.props;
    const {
      dates,
      symptoms,
      isLoading,
      errMsg,
      currentSymptomName,
      currentDate,
    } = this.state;
    const pathPrefix = `/diagnoser/network/${networkId}/symptom`;

    return (
      <>
        <div className="mb-4">
          <div className="font-semibold">Symptoms</div>

          <div className="flex gap-4">
            {isLoading && <LoadingUI></LoadingUI>}
            {symptoms &&
              symptoms.map((symptomName) => {
                return (
                  <button
                    type="button"
                    key={symptomName}
                    className={`${
                      currentSymptomName === symptomName
                        ? "border-gray-500 bg-gray-200 text-gray-900"
                        : "border-gray-300 bg-white text-gray-900"
                    } rounded border px-2 py-1 text-sm font-semibold hover:border-gray-500`}
                    onClick={() =>
                      this.handleCurrentSymptomChanged(symptomName)
                    }
                  >
                    {symptomName}
                  </button>
                );
              })}
          </div>
        </div>

        <div className="mb-8">
          <div className="font-semibold">Dates</div>

          <div className="flex gap-4">
            {dates.map((date) => {
              return (
                <button
                  type="button"
                  key={date}
                  className={`${
                    currentDate === date
                      ? "border-gray-500 bg-gray-200 text-gray-900"
                      : "border-gray-300 bg-white text-gray-900"
                  } rounded border px-2 py-1 text-sm font-semibold hover:border-gray-500`}
                  onClick={() => this.handleCurrentDateChanged(date)}
                >
                  {date}
                </button>
              );
            })}
          </div>
        </div>

        <div>
          {currentSymptomName && currentDate && (
            <NavLink
              to={`${pathPrefix}/${currentSymptomName}/${currentDate}`}
              className="shadow rounded bg-blue-200 py-2 px-4 font-medium text-blue-800 hover:bg-blue-300"
            >
              Go to report: {currentSymptomName} {currentDate}
            </NavLink>
          )}
        </div>
      </>
    );
  }
}

export default SymptomsViewer;
