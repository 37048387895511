import _ from "lodash";

import { api, fetchRespJson, postData } from "apis/base";

export default {
  getPublishers,

  // ops-mgmt: account
  getPublishersWithTrialStatus,
  updatePublisherTrialStatus,
  createReseller,
  createPublisher,
  getBillingSetting,
  getPublisherPricingConfig,
  getPricingDefaultConfigs,
  getPricingDefaultTypes,
  createOrUpdatePublisherPricingConfig,
  createPricingDefaultConfig,
};

async function createPricingDefaultConfig(params) {
  const apiUrl = `${api}/create-pricing-default-config`;
  return await postData(apiUrl, params);
}

async function createOrUpdatePublisherPricingConfig(params) {
  const apiUrl = `${api}/publisher/update-pricing-config`;
  return await postData(apiUrl, params);
}

async function getPricingDefaultTypes() {
  try {
    let apiUrl = `${api}/pricing-default-types`;
    return await fetchRespJson(apiUrl);
  } catch (err) {
    console.log(err);
    throw err;
  }
}

async function getPricingDefaultConfigs() {
  try {
    let apiUrl = `${api}/pricing-default-configs`;
    return await fetchRespJson(apiUrl);
  } catch (err) {
    console.log(err);
    throw err;
  }
}

async function getPublisherPricingConfig({ publisherId }) {
  try {
    let apiUrl = `${api}/publisher/${publisherId}/pricing-config`;
    return await fetchRespJson(apiUrl);
  } catch (err) {
    console.log(err);
    throw err;
  }
}

async function getBillingSetting({ publisherId }) {
  try {
    let apiUrl = `${api}/publisher/${publisherId}/billing-setting`;
    return await fetchRespJson(apiUrl);
  } catch (err) {
    console.log(err);
    throw err;
  }
}

async function createReseller(params) {
  let apiUrl = `${api}/create-reseller`;
  return await postData(apiUrl, params);
}

async function createPublisher(params) {
  let apiUrl = `${api}/create-publisher`;
  return await postData(apiUrl, params);
}

async function getPublishersWithTrialStatus(params) {
  let apiUrl = `${api}/publishers-with-trial-status`;
  return await postData(apiUrl, params);
}

async function updatePublisherTrialStatus(params) {
  const apiUrl = `${api}/publisher/update-trial-status`;
  return await postData(apiUrl, params);
}

async function getPublishers() {
  try {
    let apiUrl = `${api}/publishers`;
    return await fetchRespJson(apiUrl);
  } catch (err) {
    console.log(err);
    throw err;
  }
}
