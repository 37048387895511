import React from "react";
import _ from "lodash";
import { notify } from "react-notify-toast";
import { VseAPI } from "apis";
import UnitState from "../common/UnitState";
import PopupWrapper from "../common/PopupWrapper";
import RecipeBox from "./recipe/RecipeBox";
import ActionPretty from "./recipe/ActionPretty";
import AdUnitBasicCard from "../snapshot/AdUnitBasicCard";
import UnitMode from "../common/UnitMode";
import NumberFormat from "../common/NumberFormat";
import { getUnitStateChangeSummary } from "../../helpers/Pending-Action";
import { UNIT_STATE } from "../../constants/Unit-State";
import VSECheckBox from "./VSECheckbox";
import ReactTooltip from "react-tooltip";
import ModalWrapper from "../common/ModalWrapper";
import VSEPageWrapper from "./VSEPageWrapper";

const PA_STATUS = {
  // TODO: wait for api
  HAS_ACTIONS: "Has Actions",
  NO_ACTIONS: "No Actions",
  HAS_CONFLICTS: "Conflicts",
};

class PendingActionsViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,

      data: null,
      filteredItems: null,

      paStatusFilter: PA_STATUS.HAS_ACTIONS,

      selectedItems: [],
      isModalOpen: false,
      isSaving: false,
      saveErrMsg: null,
    };

    this.handleFilterUnits = this.handleFilterUnits.bind(this);
    this.handleStatusFilterChanged = this.handleStatusFilterChanged.bind(this);

    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleSelectItem = this.handleSelectItem.bind(this);
    this.handleClearAll = this.handleClearAll.bind(this);
    this.handleExecuteClicked = this.handleExecuteClicked.bind(this);

    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleConfirmExecute = this.handleConfirmExecute.bind(this);
  }

  async componentDidMount() {
    const networkId = _.get(this.props, "networkId");
    console.log(networkId);

    if (networkId) {
      document.title = `PA: ${networkId} | VSE Pending Actions`;
    }

    await this._queryAndUpdateUI({ networkId: _.parseInt(networkId) });
  }

  async _queryAndUpdateUI(params) {
    this.setState({ isLoading: true });
    try {
      const { actions, networkInfo, snapshotsByUnitId } = await this.queryData(
        params
      );
      // console.log(actions);

      if (_.isEmpty(actions)) {
        return this.setState({ errMsg: "No actions found" });
      }

      const unitStateChangeSummary = getUnitStateChangeSummary(actions);

      this.setState({
        data: actions,
        unitStateChangeSummary,
        // filteredItems: actions,
        networkInfo,
        snapshotsByUnitId,
      });

      this.handleStatusFilterChanged(this.state.paStatusFilter);
    } catch (err) {
      console.log("Error querying vse pending actions", err);
    }
    this.setState({ isLoading: false });
  }

  async queryData(params) {
    const { pendingActions, networkInfo, snapshotsByUnitId } =
      await VseAPI.getVSEPendingActionsByNetwork(params);
    const { recipes } = this.props;

    const actions = _.map(pendingActions, (item) => {
      try {
        // item.actions{}
        // proposed_actions[]
        // conflicts[]
        // execution_plan[]

        if (item.actions) {
          // // get recipe name
          item.actions.proposed_actions = _.map(
            item.actions.proposed_actions,
            (action) => {
              const recipe = _.find(recipes, {
                id: action.recipe_id,
                version: action.version,
              });
              action.recipe = recipe || {};
              return action;
            }
          );
        }
      } catch (err) {
        item.actions = null;
        console.log(err);
      }

      return item;
    });
    return { actions, networkInfo, snapshotsByUnitId };
  }

  handleFilterUnits(unitIds) {
    const filteredItems = _.filter(this.state.data, (item) => {
      return _.indexOf(unitIds, item.unit_id) !== -1;
    });
    const paStatusFilter = PA_STATUS.HAS_ACTIONS;
    this.setState({ filteredItems, paStatusFilter });
  }

  handleStatusFilterChanged(paStatusFilter) {
    let filteredItems = [];
    if (paStatusFilter === PA_STATUS.HAS_ACTIONS) {
      filteredItems = _.filter(this.state.data, (item) => {
        return item.actions;
      });
    } else if (paStatusFilter === PA_STATUS.NO_ACTIONS) {
      filteredItems = _.filter(this.state.data, (item) => {
        return !item.actions;
      });
    } else if (paStatusFilter === PA_STATUS.HAS_CONFLICTS) {
      filteredItems = _.filter(this.state.data, (item) => {
        return item.actions && item.actions.conflicts.length > 0;
      });
    }

    this.setState({ paStatusFilter, filteredItems });
  }

  handleSelectAll() {
    const { selectedItems, filteredItems } = this.state;
    if (selectedItems.length === filteredItems.length) {
      this.setState({
        selectedItems: [],
      });
    } else {
      const newSelected = _.map(filteredItems, "unit_id");
      this.setState({ selectedItems: [...selectedItems, ...newSelected] });
    }
  }

  handleSelectItem(unitId) {
    const { selectedItems } = this.state;

    if (_.indexOf(selectedItems, unitId) === -1) {
      this.setState({
        selectedItems: [...selectedItems, unitId],
      });
    } else {
      const newSelectedItems = selectedItems;
      _.pull(newSelectedItems, unitId);
      this.setState({ selectedItems: newSelectedItems });
    }
  }

  handleClearAll() {
    this.setState({ selectedItems: [] });
  }

  handleExecuteClicked() {
    this.setState({ isModalOpen: true });
  }

  handleModalClose() {
    this.setState({ isModalOpen: false });
  }

  async handleConfirmExecute() {
    const params = {
      unitIds: this.state.selectedItems,
    };
    this.setState({ isSaving: true });
    try {
      const { result } = await VseAPI.executeVSEPendingActions(params);
      if (result.op === "ok") {
        notify.show("Execution successful! Reloading page...", "success");
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      } else {
        throw `Failed response: ${JSON.stringify(result)}`;
      }
    } catch (err) {
      this.setState({ saveErrMsg: err });
    }
    this.setState({ isSaving: false });
  }

  render() {
    const {
      isLoading,
      errMsg,
      data,
      filteredItems,
      recipes,
      networkInfo,
      paStatusFilter,
      snapshotsByUnitId,

      unitStateChangeSummary,

      selectedItems,
      isModalOpen,
      isSaving,
      saveErrMsg,
    } = this.state;

    return (
      <VSEPageWrapper
        isLoading={isLoading}
        errMsg={errMsg}
        networkInfo={networkInfo}
      >
        <div className="mb-4">
          <UnitStateSummary items={data}></UnitStateSummary>
        </div>
        <div className="mb-4">
          <UnitStateChangeSummary
            summary={unitStateChangeSummary}
            handleFilterUnits={this.handleFilterUnits}
          ></UnitStateChangeSummary>
        </div>
        <div className="border py-2 px-2 mb-2 bg-white">
          <div className="font-semibold text-sm mb-2">
            <button
              type="button"
              className={`px-2 py-1 mr-1 font-semibold rounded ${
                paStatusFilter === PA_STATUS.HAS_ACTIONS
                  ? "bg-indigo-200 text-indigo-900"
                  : "bg-gray-100 text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
              }`}
              onClick={() =>
                this.handleStatusFilterChanged(PA_STATUS.HAS_ACTIONS)
              }
            >
              Has actions
            </button>
            <button
              type="button"
              className={`px-2 py-1 mr-1 font-semibold rounded ${
                paStatusFilter === PA_STATUS.NO_ACTIONS
                  ? "bg-indigo-200 text-indigo-900"
                  : "bg-gray-100 text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
              }`}
              onClick={() =>
                this.handleStatusFilterChanged(PA_STATUS.NO_ACTIONS)
              }
            >
              Has no actions
            </button>
            <button
              type="button"
              className={`px-2 py-1 mr-1 font-semibold rounded ${
                paStatusFilter === PA_STATUS.HAS_CONFLICTS
                  ? "bg-indigo-200 text-indigo-900"
                  : "bg-gray-100 text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
              }`}
              onClick={() =>
                this.handleStatusFilterChanged(PA_STATUS.HAS_CONFLICTS)
              }
            >
              Conflicts
            </button>
          </div>
        </div>

        {filteredItems && (
          <div>
            Showing {filteredItems.length} out of {data.length} pending actions
          </div>
        )}

        {data && (
          <div>
            <PendingActionsTable
              items={filteredItems}
              snapshotsByUnitId={snapshotsByUnitId}
              recipes={recipes}
              selectedItems={selectedItems}
              handleSelectAll={this.handleSelectAll}
              handleSelectItem={this.handleSelectItem}
            ></PendingActionsTable>

            <ActionFooter
              selectedItems={selectedItems}
              handleClearAll={this.handleClearAll}
              handleExecuteClicked={this.handleExecuteClicked}
            ></ActionFooter>

            <ModalWrapper
              isOpen={isModalOpen}
              handleClose={this.handleModalClose}
              width="60%"
              showCloseFooter={false}
            >
              <div>
                <div className="font-bold text-xl text-gray-700 mb-2">
                  Execute the following units ({selectedItems.length}):
                </div>

                <div style={{ height: "500px", overflow: "scroll" }}>
                  <table className="w-full table text-sm border shadow">
                    <thead className="bg-gray-200 text-blue-800 text-xs border-b">
                      <tr>
                        <th className="border py-1 px-2">Unit</th>
                        <th className="border py-1 px-2">raw_revenue_ratio</th>
                        <th className="border py-1 px-2">Execution Plan</th>
                        <th className="border py-1 px-2">
                          Proposed by recipes
                        </th>
                        <th className="border py-1 px-2">Updated at</th>
                      </tr>
                    </thead>
                    <tbody className="text-gray-900 font-mono bg-white">
                      {selectedItems.map((unitId) => {
                        const r = _.find(data, { unit_id: unitId });
                        return (
                          <tr
                            key={r.unit_id}
                            className="border-b hover:bg-gray-100"
                          >
                            <td className="font-semibold py-1 px-2 whitespace-no-wrap border font-sans">
                              <div className="flex items-center gap-2 justify-between">
                                <div>
                                  <PopupWrapper
                                    place="bottom left"
                                    hideArrow={true}
                                    triggerElement={
                                      <button
                                        type="button"
                                        className="border-b border-gray-600"
                                      >
                                        {r.unit_id}{" "}
                                        <span className="text-gray-700 mr-1">
                                          {r.unit_name}
                                        </span>
                                        {r.isInsp && (
                                          <span className="text-xs text-orange-100 bg-orange-400 rounded font-bold px-2">
                                            INSP
                                          </span>
                                        )}
                                        {r.isDebug && (
                                          <span className="text-xs text-pink-100 bg-pink-500 rounded font-bold px-2">
                                            DEBUG
                                          </span>
                                        )}
                                        {r.hasGpt && (
                                          <span className="text-xs px-2 text-gray-600 bg-indigo-100 rounded">
                                            GPT
                                          </span>
                                        )}
                                        {r.hasAmp && (
                                          <span className="text-xs px-2 text-gray-600 bg-indigo-100 rounded">
                                            AMP
                                          </span>
                                        )}
                                      </button>
                                    }
                                    popupElement={
                                      <div
                                        className="shadow-lg border-4 border-gray-600"
                                        style={{ marginTop: "-9px" }}
                                      >
                                        {snapshotsByUnitId[r.unit_id] ? (
                                          <AdUnitBasicCard
                                            unit={snapshotsByUnitId[r.unit_id]}
                                            showFullWidthCard={false}
                                            isCompactMode={false}
                                          ></AdUnitBasicCard>
                                        ) : (
                                          <div>No snapshot</div>
                                        )}
                                      </div>
                                    }
                                  ></PopupWrapper>
                                  <UnitMode unit={r}></UnitMode>
                                </div>
                                <div>
                                  <UnitState state={r.unit_state}></UnitState>
                                </div>
                              </div>

                              <div className="text-xs text-gray-600 font-normal">
                                {r.ysId} {r.ysName}
                              </div>
                            </td>
                            <td className="py-1 px-3 whitespace-no-wrap border text-left">
                              {r.stat && (
                                <div className="flex flex-row-reverse gap-2 justify-start items-center">
                                  <NumberFormat
                                    value={r.stat["raw_revenue_ratio"]}
                                    format="0,0.00%"
                                  ></NumberFormat>
                                  <div className="text-xs text-gray-600 font-normal">
                                    <NumberFormat
                                      value={r.stat["raw_eligible_revenue_twd"]}
                                      format="$0,0a"
                                    ></NumberFormat>
                                  </div>
                                </div>
                              )}
                            </td>
                            <td className="py-1 px-3 whitespace-no-wrap border text-left">
                              {r.actions ? (
                                <ul className="list-disc list-inside">
                                  {r.actions.execution_plan.map((ep, i) => {
                                    return (
                                      <li key={i}>
                                        <ActionPretty item={ep}></ActionPretty>
                                      </li>
                                    );
                                  })}
                                </ul>
                              ) : (
                                "No actions"
                              )}
                              {r.actions &&
                                r.actions.conflicts &&
                                r.actions.conflicts.length > 0 && (
                                  <div>
                                    Conflicts:
                                    <div>
                                      {JSON.stringify(r.actions.conflicts)}
                                    </div>
                                  </div>
                                )}
                            </td>
                            <td className="py-1 px-3 whitespace-no-wrap border text-left">
                              {r.actions
                                ? r.actions.proposed_actions.map(
                                    (action, i) => {
                                      return (
                                        <ActionRecipeBox
                                          action={action}
                                          key={i}
                                        ></ActionRecipeBox>
                                      );
                                    }
                                  )
                                : ""}
                            </td>
                            <td className="py-1 px-3 whitespace-no-wrap border text-right">
                              {r.updated_at}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                <div className="flex flex-row-reverse mt-4 items-center">
                  <div>
                    <button
                      type="button"
                      className="px-4 py-2 bg-blue-500 rounded shadow hover:bg-blue-700 text-white font-semibold"
                      onClick={this.handleConfirmExecute}
                    >
                      {isSaving ? "Saving..." : "Confirm execution"}
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      disabled={isSaving}
                      className={`px-4 py-2 text-blue-700 ${
                        isSaving ? "cursor-not-allowed" : ""
                      }`}
                      onClick={this.handleModalClose}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="text-red-600">{saveErrMsg}</div>
                </div>
              </div>
            </ModalWrapper>
          </div>
        )}
      </VSEPageWrapper>
    );
  }
}

const UnitStateChangeSummary = (props) => {
  const { summary } = props;
  const summaryKeys = _.sortBy(_.keys(summary));

  return (
    <div>
      <table className="w-full table text-sm border shadow">
        <thead className="bg-gray-200 text-blue-800 text-xs border-b">
          <tr>
            <th className="border py-1 px-2">
              State Change ({summaryKeys.length})
            </th>
            <th className="border py-1 px-2">Count</th>
          </tr>
        </thead>
        <tbody className="text-gray-900 font-mono bg-white">
          {summaryKeys.map((s) => {
            return (
              <tr key={s} className="border-b hover:bg-gray-100">
                <td className="py-1 px-2 border">
                  {summary[s].from !== summary[s].to ? (
                    <span>
                      <span>{UNIT_STATE[summary[s].from]}</span>
                      {" => "}
                      <span>{UNIT_STATE[summary[s].to]}</span>
                    </span>
                  ) : (
                    <span>
                      Stay in <span>{UNIT_STATE[summary[s].from]}</span>
                    </span>
                  )}
                </td>
                <td
                  className="px-1 border cursor-pointer hover:font-bold"
                  onClick={() => props.handleFilterUnits(summary[s].unitIds)}
                >
                  {summary[s].unitIds.length}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const UnitStateSummary = (props) => {
  const { items } = props;
  const itemsCountByState = _.countBy(items, "unit_state");

  return (
    itemsCountByState && (
      <table className="w-full table text-sm border shadow">
        <thead className="bg-gray-200 text-blue-800 text-xs border-b">
          <tr>
            <th className="border py-1 px-2">Unit State</th>
            <th className="border py-1 px-2">Count</th>
          </tr>
        </thead>
        <tbody className="text-gray-900 font-mono bg-white">
          {_.keys(UNIT_STATE).map((state) => {
            return (
              <tr key={state} className="border-b hover:bg-gray-100">
                <td className="py-1 px-2 border">
                  {state}: {UNIT_STATE[state]}
                </td>
                <td className="px-1 border ">
                  {itemsCountByState[state] || 0}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    )
  );
};

const PendingActionsTable = (props) => {
  const {
    items,
    snapshotsByUnitId,
    selectedItems,
    handleSelectAll,
    handleSelectItem,
  } = props;

  const sortedItems = _sortItems(items);

  function _sortItems(items) {
    return _.orderBy(items, [_sort], ["desc"]);
  }

  function _sort(item) {
    return item.stat ? item.stat.raw_eligible_revenue_twd : -Infinity;
  }

  const hasNoExecutables = _.some(items, (item) => {
    return !item.actions || (item.actions && item.actions.conflicts.length > 0);
  });

  return (
    <div>
      <table
        className="w-full table text-sm border shadow"
        style={{ marginBottom: "500px" }}
      >
        <thead className="bg-gray-200 text-blue-800 text-xs border-b">
          <tr>
            <th>
              {items.length > 0 && !hasNoExecutables && (
                <div className="w-12">
                  <VSECheckBox
                    isChecked={selectedItems.length === items.length}
                    handleClick={handleSelectAll}
                  ></VSECheckBox>
                </div>
              )}
            </th>
            {/* <th></th> */}
            <th className="border py-1 px-2">Unit</th>
            <th className="border py-1 px-2">raw_revenue_ratio</th>
            <th className="border py-1 px-2">Execution Plan</th>
            <th className="border py-1 px-2">Proposed by recipes</th>
            <th className="border py-1 px-2">Updated at</th>
          </tr>
        </thead>
        <tbody className="text-gray-900 font-mono bg-white">
          {items &&
            sortedItems.map((r) => {
              return (
                <tr key={r.unit_id} className="border-b hover:bg-gray-100">
                  <td className="text-center">
                    {!hasNoExecutables && (
                      <VSECheckBox
                        isChecked={selectedItems.indexOf(r.unit_id) !== -1}
                        handleClick={() => handleSelectItem(r.unit_id)}
                      ></VSECheckBox>
                    )}
                  </td>
                  {/* <td>
                    <GoToInsightButton
                      unitId={r.unit_id}
                      qp="?tab=HEALTH_CHECK"
                      showIconOnly={true}
                    ></GoToInsightButton>
                  </td> */}
                  <td className="font-semibold py-1 px-2 whitespace-no-wrap border font-sans">
                    <div className="flex items-center gap-2 justify-between">
                      <div>
                        <PopupWrapper
                          place="bottom left"
                          hideArrow={true}
                          triggerElement={
                            <button
                              type="button"
                              className="border-b border-gray-600"
                            >
                              {r.unit_id}{" "}
                              <span className="text-gray-700 mr-1">
                                {r.unit_name}
                              </span>
                              {r.isInsp && (
                                <span className="text-xs text-orange-100 bg-orange-400 rounded font-bold px-2">
                                  INSP
                                </span>
                              )}
                              {r.isDebug && (
                                <span className="text-xs text-pink-100 bg-pink-500 rounded font-bold px-2">
                                  DEBUG
                                </span>
                              )}
                              {r.hasGpt && (
                                <span className="text-xs px-2 text-gray-600 bg-indigo-100 rounded">
                                  GPT
                                </span>
                              )}
                              {r.hasAmp && (
                                <span className="text-xs px-2 text-gray-600 bg-indigo-100 rounded">
                                  AMP
                                </span>
                              )}
                            </button>
                          }
                          popupElement={
                            <div
                              className="shadow-lg border-4 border-gray-600"
                              style={{ marginTop: "-9px" }}
                            >
                              {snapshotsByUnitId[r.unit_id] ? (
                                <AdUnitBasicCard
                                  unit={snapshotsByUnitId[r.unit_id]}
                                  showFullWidthCard={false}
                                  isCompactMode={false}
                                ></AdUnitBasicCard>
                              ) : (
                                <div>No snapshot</div>
                              )}
                            </div>
                          }
                        ></PopupWrapper>
                        <UnitMode unit={r}></UnitMode>
                      </div>
                      <div>
                        <UnitState state={r.unit_state}></UnitState>
                      </div>
                    </div>

                    <div className="text-xs text-gray-600 font-normal">
                      {r.ysId} {r.ysName}
                    </div>
                  </td>
                  <td className="py-1 px-3 whitespace-no-wrap border text-left">
                    {r.stat && (
                      <div className="flex flex-row-reverse gap-2 justify-start items-center">
                        <NumberFormat
                          value={r.stat["raw_revenue_ratio"]}
                          format="0,0.00%"
                        ></NumberFormat>
                        <div className="text-xs text-gray-600 font-normal">
                          <NumberFormat
                            value={r.stat["raw_eligible_revenue_twd"]}
                            format="$0,0a"
                          ></NumberFormat>
                        </div>
                      </div>
                    )}
                  </td>
                  <td className="py-1 px-3 whitespace-no-wrap border text-left">
                    {r.actions ? (
                      <ul className="list-disc list-inside">
                        {r.actions.execution_plan.map((ep, i) => {
                          return (
                            <li key={i}>
                              <ActionPretty item={ep}></ActionPretty>
                            </li>
                          );
                        })}
                      </ul>
                    ) : (
                      "No actions"
                    )}
                    {r.actions &&
                      r.actions.conflicts &&
                      r.actions.conflicts.length > 0 && (
                        <div>
                          Conflicts:
                          <div>{JSON.stringify(r.actions.conflicts)}</div>
                        </div>
                      )}
                  </td>
                  <td className="py-1 px-3 whitespace-no-wrap border text-left">
                    {r.actions
                      ? r.actions.proposed_actions.map((action, i) => {
                          return (
                            <ActionRecipeBox
                              action={action}
                              key={i}
                            ></ActionRecipeBox>
                          );
                        })
                      : ""}
                  </td>
                  <td className="py-1 px-3 whitespace-no-wrap border text-right">
                    {r.updated_at}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

const ActionFooter = (props) => {
  const { selectedItems, handleClearAll, handleExecuteClicked } = props;
  return (
    <div className="fixed bottom-0 left-0 w-full bg-white border-t-2 px-4 py-2">
      <div className="flex gap-2 items-center text-gray-700">
        <div className="text-sm w-48 text-right">
          Selected units ({selectedItems.length}):
        </div>
        <textarea
          rows="1"
          value={selectedItems.join(", ")}
          readOnly
          className="w-full border rounded p-1 text-xs"
        ></textarea>
        <div className="flex items-center gap-4">
          <button
            className="text-blue-900 rounded font-semibold bg-blue-200 hover:bg-blue-300 px-2 py-1 text-sm overflow-x-auto"
            type="button"
            data-tip
            data-for="reset-recipes-button"
            onClick={handleExecuteClicked}
            disabled={selectedItems.length === 0}
          >
            Execute
          </button>
          <ReactTooltip id="reset-recipes-button" type="dark" effect="solid">
            See execute units in preview
          </ReactTooltip>
          <button
            className="text-blue-600 rounded hover:bg-blue-100 px-4 py-1 text-sm overflow-x-auto"
            type="button"
            onClick={handleClearAll}
          >
            Clear
          </button>
        </div>
      </div>
    </div>
  );
};

const ActionRecipeBox = (props) => {
  const { action } = props;
  // version
  // preconditions
  // actions

  return (
    <>
      <PopupWrapper
        place="bottom left"
        hideArrow={true}
        triggerElement={
          <div className="px-2 bg-gray-200 rounded border cursor-pointer">
            {action.recipe.id} - {action.recipe.name}
          </div>
        }
        popupElement={
          <div
            className="shadow-lg border-4 border-gray-600"
            style={{ marginTop: "-9px" }}
          >
            <RecipeBox item={action.recipe}></RecipeBox>
          </div>
        }
      ></PopupWrapper>
    </>
  );
};

export default PendingActionsViewer;
