import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
Highcharts.setOptions({
  lang: {
    decimalPoint: ".",
    thousandsSep: ","
  }
});

class SegmentTimeline extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { data } = this.props;
    const segments = data.segmentInfo;

    const segmentReportsMap = _.groupBy(data.yieldLifts, "segmentId");
    let dateRange = {
      st: null,
      et: null
    };

    const netLiftData = _.map(segments, (s, i) => {
      const reports = segmentReportsMap[s.id];

      const timelineData = _.reduce(
        reports,
        (result, r) => {
          // const rev = _.round(_.sumBy(r.reports, "revenue"), 2);
          const netLift = _.round(r.netLift, 2);
          const dt = new Date(r.startDate).getTime();
          const d = [dt, netLift];

          // Keep track of date range
          if (!dateRange.st || dt < dateRange.st) {
            dateRange.st = dt;
          }
          if (!dateRange.et || dt > dateRange.et) {
            dateRange.et = dt;
          }

          result.push(d);
          return result;
        },
        []
      );

      return {
        name: s.id !== -1 ? `segment: ${s.id}` : "No segment",
        data: timelineData
      };
    });

    const options = {
      title: {
        text: `Net Rev Lift from ${moment(dateRange.st).format(
          "YYYY/MM/DD HH:mm"
        )} GMT+8 to ${moment(dateRange.et).format("YYYY/MM/DD HH:mm")} GMT+8`
      },
      chart: {
        type: "area",
        zoomType: "x",
        height: 260,
        backgroundColor: "rgba(0,0,0,0)"
      },
      time: {
        timezoneOffset: -8 * 60
      },
      tooltip: {
        xDateFormat: "%Y/%m/%d %H:%M",
        headerFormat:
          '<span style="font-size:10px">StartDate: {point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0;font-weight: bold;">{series.name}: </td>' +
          '<td style="padding:0; text-align: right;"><b>{point.y:,.2f}</b>%</td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
        crosshairs: true
      },
      xAxis: {
        type: "datetime",
        labels: { format: "{value:%m/%d}", rotation: -65 },
        tickInterval: 24 * 60 * 60 * 1000, // 1 day
        crosshair: true
      },
      yAxis: {
        title: {
          text: "Net Lift %"
        }
      },
      series: netLiftData
    };

    return (
      <>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </>
    );
  }
}

export default SegmentTimeline;
