import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import Highcharts from "highcharts";
import AnatomyCstSegmentsTable from "./AnatomyCstSegmentsTable";
import HighchartWrapper from "components/common/HighchartWrapper";
import { getGroupTypeColor, tooltipFormatter } from "./AnatomyHighchartHelper";

class AnatomyCstSegmentsSection extends React.PureComponent {
  constructor(props) {
    super(props);

    const { totalReport, segmentTotalReport } = this._caculateReports();

    let segmentColorMap = {};
    const segmentIds = _.map(segmentTotalReport, "segment_id").sort();

    const highchartColors = Highcharts.getOptions().colors;
    _.forEach(segmentIds, (id, index) => {
      segmentColorMap[id] = highchartColors[index];
    });

    const options = this._calculateChartOptions(
      segmentTotalReport,
      segmentColorMap
    );

    this.state = {
      totalReport,
      segmentTotalReport,
      options,
      segmentColorMap,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.noPbType !== this.props.noPbType) {
      const { totalReport, segmentTotalReport } = this._caculateReports();
      const options = this._calculateChartOptions(
        segmentTotalReport,
        this.state.segmentColorMap
      );

      this.setState({
        totalReport,
        segmentTotalReport,
        options,
      });
    }
  }

  _caculateReports() {
    const { data, items } = this.props;
    // data: reports
    // items: segments metadata
    const segmentInfoById = _.keyBy(items, "upr_segment_id");

    // detect segmentation (batch) change
    const batchIds = _.uniq(_.map(items, "upr_segmentation_id"));
    const hasMultiBatch = batchIds.length > 1;

    // find the most valuable segments to show in chart
    // if diff segmentation batch exists -> show all
    // if not, show top 50% or individually consists of 30%

    let totalReport = {
      total_rev: 0,
      original_rev: 0,
      increased_rev: 0,
      cost: 0,
    };
    let segmentTotalReport = _.map(data, (segmentReports, segment_id) => {
      const total_rev = _.sumBy(segmentReports, "total_rev", 0);
      const original_rev = _.sumBy(segmentReports, "original_rev", 0);
      const increased_rev = _.sumBy(segmentReports, "increased_rev", 0);
      const cost = _.sumBy(segmentReports, "cost", 0);
      const gross_lift = increased_rev / original_rev;
      const net_lift = (increased_rev - cost) / original_rev;

      totalReport.total_rev += total_rev;
      totalReport.original_rev += original_rev;
      totalReport.increased_rev += increased_rev;
      totalReport.cost += cost;

      return {
        segment_id,
        ...segmentInfoById[segment_id],
        total_rev,
        original_rev,
        increased_rev,
        cost,
        gross_lift,
        net_lift,
      };
    });

    // calculate total_rev percentage
    segmentTotalReport = _.map(segmentTotalReport, (r) => {
      r.total_rev_ratio = _.round(
        100 * (r.total_rev / totalReport.total_rev),
        2
      );
      return r;
    });

    segmentTotalReport = _.sortBy(segmentTotalReport, (r) => {
      return -1 * (_.isNaN(r.total_rev) ? Infinity : r.total_rev);
    });

    let topPercentage = 0;
    segmentTotalReport = _.map(segmentTotalReport, (r, i) => {
      if (hasMultiBatch) {
        r.isImportant = r["total_rev"] > 1; // dont show garbage 0 rev segments in chart
        return r;
      }

      const metric = "total_rev_ratio";
      if (i === 0 || r[metric] >= 30 || topPercentage <= 50) {
        topPercentage += r[metric];
        r.isImportant = true;
        return r;
      }

      return r;
    });

    return {
      totalReport,
      segmentTotalReport,
    };
  }

  _calculateChartOptions(segmentTotalReport, segmentColorMap) {
    const { data, timezone, timezoneType } = this.props;

    const metric = "net_lift";
    const title = "Perceived Net Incr Lift";

    let tz = timezone;
    if (timezoneType === "TAIPEI") {
      tz = "Asia/Taipei";
    }
    const options = _createChartOptions({
      data,
      metric,
      timezone: tz,
      title,
      segmentTotalReport,
      segmentColorMap,
    });

    return options;
  }

  render() {
    const { data, items, timezone, timezoneType, noPbType } = this.props;
    // data: reports
    // items: segments metadata
    const { totalReport, segmentTotalReport, options } = this.state;

    if (_.isEmpty(data)) return <div className="py-4 text-lg">No data</div>;

    return (
      <>
        <div className="my-2 bg-white pt-4">
          {options && (
            <HighchartWrapper
              timezoneType={timezoneType}
              noPbType={noPbType}
              options={options}
            ></HighchartWrapper>
          )}
        </div>
        <AnatomyCstSegmentsTable
          {...this.props}
          totalReport={totalReport}
          segmentTotalReport={segmentTotalReport}
          noPbType={noPbType}
        ></AnatomyCstSegmentsTable>
      </>
    );
  }
}

function _createSeriesData({
  data,
  metric,
  segmentTotalReport,
  segmentColorMap,
}) {
  const reportsBySegment = _.keyBy(segmentTotalReport, "segment_id");
  const seriesData = _.map(data, (reports, upr_segment_id, index) => {
    const data = _.map(reports, (r) => {
      return {
        x: r._time * 1000,
        y: _.round(r[metric] * 100, 2),
      };
    });

    const total_rev_ratio =
      reportsBySegment[upr_segment_id].total_rev_ratio || 0;

    return {
      name: `${upr_segment_id} (${total_rev_ratio}%)`,
      data,
      color: segmentColorMap[upr_segment_id],
      visible: reportsBySegment[upr_segment_id].isImportant === true,
      total_rev_ratio,
    };
  });

  return _.orderBy(seriesData, ["total_rev_ratio"], ["desc"]);
}

function _createChartOptions({
  data,
  metric,
  timezone,
  title,
  segmentTotalReport,
  segmentColorMap,
}) {
  const seriesData = _createSeriesData({
    data,
    metric,
    segmentTotalReport,
    segmentColorMap,
  });

  const timezoneOffset = -moment.tz(timezone).utcOffset();
  const options = {
    title: {
      text: title || metric,
    },
    time: {
      timezoneOffset: timezoneOffset,
    },
    chart: {
      type: "line",
      zoomType: "xy",
      xAxis: {
        minRange: 1,
      },
      height: 260,
      backgroundColor: "rgba(0,0,0,0)",
    },
    tooltip: {
      shared: true,
      useHTML: true,
      formatter() {
        return tooltipFormatter({
          chart: this,
          timezone,
          isSortByYValue: true,
          valuePostfix: "%",
          valueDecimalPoint: 2,
        });
      },
    },
    xAxis: { ..._getXAxisConfigDateTime({ timezone }) },
    yAxis: {
      title: {
        text: "%",
      },
      plotBands: _createYAxisPlotBands(),
    },
    series: seriesData,
  };

  return options;
}

function _createYAxisPlotBands() {
  return [
    {
      from: -9999999,
      to: 0,
      color: "#f4e6e6",
    },
  ];
}

function _getXAxisConfigDateTime({ timezone }) {
  return {
    type: "datetime",
    labels: {
      formatter: function () {
        const m = moment(this.value).tz(timezone);
        const d = m.format("DD");
        // const d = m.format("MM/DD");
        const wd = m.format("ddd");
        const label = `${d} <br/> ${wd}`;
        if (d === "01" || this.isFirst) {
          return `${d} <br/> ${wd} <br/> ${m.format("M")}`;
        }
        // if (isWeekend(this.value)) {
        //   return `<span style="color: #dd6b20;">${label}</span>`;
        // }
        return label;
      },
    },
    tickInterval: 24 * 60 * 60 * 1000, // 1 day
    crosshair: true,
  };
}

export default AnatomyCstSegmentsSection;
