import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import { NetworkAPI } from "apis";
import LoadingUI from "components/common/LoadingUI";
import NetworkInfoHeader from "../../common/NetworkInfoHeader";
import GamUprTable from "./GamUprTable";
import DateTimeFormatter from "components/common/DateTimeFormatter";

const buttonClass =
  "px-2 py-1 bg-gray-100 text-xs rounded border border-gray-400 hover:bg-gray-200 text-gray-900 font-semibold";

const PRICE_TYPE = {
  0: "Floor",
  1: "Target CPM",
  2: "LGO", // let google optimize
  "-1": "Use Brand Setting",
};

const PRICE_TYPE_BY_NAME = {
  FLOOR: 0,
  CPM: 1,
  LGO: 2,
  BRAND_SETTING: -1,
};

function _isSectionEmpty(section) {
  if (!section || _.isEmpty(section)) {
    return true;
  } else if (_.has(section, "includes") && _.has(section, "excludes")) {
    return _.isEmpty(section.includes) && _.isEmpty(section.excludes);
  } else if (_.has(section, "includes") && _.isEmpty(section.includes)) {
    return true;
  } else if (_.has(section, "excludes") && _.isEmpty(section.excludes)) {
    return true;
  } else {
    return false;
  }
}

class NetworkGamUprViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      isLoading: false,

      networkId: null,
      networkInfo: null,
      errMsg: "",

      updatedAt: null,
      uprs: null,
      newUprs: null,
      ruleFilters: null,
      priceTypeFilters: null,
      resourceFilters: null,
      currentFilter: {
        ruleType: "PUBLISHER_ONLY",
        priceType: "ALL",
        resourceType: "ALL",
      },

      isJsonView: false,
    };

    this.toggleJsonView = this.toggleJsonView.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handlePriceTypeFilter = this.handlePriceTypeFilter.bind(this);
    this.handleForceRefresh = this.handleForceRefresh.bind(this);
    this.handleResourceTypeFilter = this.handleResourceTypeFilter.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    const { networkId } = this.props.match.params;
    if (!networkId) {
      this.setState({ errMsg: "Missing Network ID" });
    } else {
      document.title = `${networkId} GAM UPRs | YB Observer`;
      this.setState({ networkId });
    }

    const networkInfo = await NetworkAPI.getNetworkInfo({
      networkId,
    });

    const units = await NetworkAPI.getUnitsByNetworkId({ networkId });
    const virtualPlacements = await NetworkAPI.getNetworkVirtualPlacements({
      networkId,
    });

    this.setState({
      networkId,
      networkInfo,

      units,
      virtualPlacements,
    });

    try {
      let { allUprs, ruleFilters, updatedAt } =
        await this._getGamUprsAndFilters({
          isForceRefresh: false,
        });

      const priceTypeFilters = this._createPriceTypeFilters(ruleFilters[2]);
      const resourceFilters = this._getI2wResourceMappingFilters(
        ruleFilters[1]
      );

      this.setState({
        uprs: allUprs,
        newUprs: allUprs,
        ruleFilters,
        priceTypeFilters,
        resourceFilters,
        updatedAt,

        isLoading: false,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        errMsg: err.toString(),
      });
    }
  }

  async _getGamUprsAndFilters({ isForceRefresh = false }) {
    const { networkId } = this.state;
    const { uprs, updatedAt } = await NetworkAPI.getGamUprs({
      networkId,
      isForceRefresh,
    });

    const {
      // placement,
      upr = {},
    } = await NetworkAPI.getNetworkResourceMapping({ networkId });

    let resourceById = _.keyBy(upr.mapping, (item) => {
      return item.id;
    });

    let allUprs = _.map(uprs, (upr) => {
      let priceSection = {};
      const setForEverythingSetting = _.find(upr.priceSection.priceSettings, {
        isSetForEverything: true,
      });
      if (setForEverythingSetting) {
        priceSection.priceType = setForEverythingSetting.priceType;
        priceSection.price = setForEverythingSetting.price;
        priceSection.currency = setForEverythingSetting.currency;
      } else {
        priceSection.priceType = PRICE_TYPE_BY_NAME.BRAND_SETTING;
        priceSection.currency = _.get(upr.priceSection.priceSettings, [
          0,
          "currency",
        ]);
        priceSection.brandedPriceSettings = upr.priceSection.priceSettings;
      }

      let targetingSection = {};
      if (!_isSectionEmpty(upr.targetingSection.inventoryTypes)) {
        targetingSection.inventoryTypes = upr.targetingSection.inventoryTypes;
      }
      if (upr.targetingSection.inventory) {
        if (!_isSectionEmpty(upr.targetingSection.inventory.unitIds)) {
          _.set(
            targetingSection,
            ["inventory", "units"], // units not unitIds!
            upr.targetingSection.inventory.unitIds
          );
        }
        if (!_isSectionEmpty(upr.targetingSection.inventory.placementIds)) {
          _.set(
            targetingSection,
            ["inventory", "placementIds"],
            upr.targetingSection.inventory.placementIds
          );
        }
        if (!_isSectionEmpty(upr.targetingSection.inventory.mcm)) {
          _.set(
            targetingSection,
            ["inventory", "mcm"],
            upr.targetingSection.inventory.mcm
          );
        }
      }
      if (!_isSectionEmpty(upr.targetingSection.browser)) {
        targetingSection.browser = upr.targetingSection.browser;
      }
      if (!_isSectionEmpty(upr.targetingSection.country)) {
        targetingSection.country = upr.targetingSection.country;
      }
      if (!_isSectionEmpty(upr.targetingSection.customs)) {
        targetingSection.customs = upr.targetingSection.customs;
      }
      if (!_isSectionEmpty(upr.targetingSection.deviceCategory)) {
        targetingSection.deviceCategory = upr.targetingSection.deviceCategory;
      }
      if (!_isSectionEmpty(upr.targetingSection.mobileApplication)) {
        targetingSection.mobileApplication =
          upr.targetingSection.mobileApplication;
      }
      if (!_isSectionEmpty(upr.targetingSection.os)) {
        targetingSection.os = upr.targetingSection.os;
      }

      return {
        ...upr,
        priceSection,
        targetingSection,
        props: _.get(resourceById, [upr.externalId, "props"], null),
      };
    });
    allUprs = _.sortBy(allUprs, "uprName");

    const { config } = await NetworkAPI.getRawNetworkFeatures({ networkId });
    let protectedUprIds = _.get(config, "publisherUPRProtectionList", []);
    let preservedUPRIds = _.get(config, "preservedUPRIds", []);

    let ruleFilters = [
      {
        key: "ALL",
        name: "ALL",
        uprs: allUprs,
      },
      {
        key: "INTOWOW_ONLY",
        name: "Intowow Rules",
        uprs: _.filter(allUprs, (upr) => {
          return _.startsWith(upr.uprName, "Intowow_");
        }),
      },
      {
        key: "PUBLISHER_ONLY",
        name: "Publisher Rules",
        uprs: _.filter(allUprs, (upr) => {
          return !_.startsWith(upr.uprName, "Intowow_");
        }),
      },
      {
        key: "PROTECTED",
        name: "Protected UPRs",
        uprs: _.filter(allUprs, (upr) => {
          return _.indexOf(protectedUprIds, _.parseInt(upr.externalId)) !== -1;
        }),
      },
      {
        key: "PRESERVED",
        name: "Preserved UPRs",
        uprs: _.filter(allUprs, (upr) => {
          return _.indexOf(preservedUPRIds, _.parseInt(upr.externalId)) !== -1;
        }),
      },
    ];

    return {
      allUprs,
      ruleFilters,
      updatedAt,
    };
  }

  async handleForceRefresh() {
    this.setState({
      isLoading: true,
      updatedAt: null,
      uprs: null,
      newUprs: null,
      ruleFilters: null,
      priceTypeFilters: null,
      resourceFilters: null,
    });
    try {
      const { allUprs, ruleFilters, updatedAt } =
        await this._getGamUprsAndFilters({
          isForceRefresh: true,
        });
      const priceTypeFilters = this._createPriceTypeFilters(ruleFilters[2]);
      const resourceFilters = this._getI2wResourceMappingFilters(
        ruleFilters[1]
      );

      this.setState({
        uprs: allUprs,
        newUprs: allUprs,
        ruleFilters,
        priceTypeFilters,
        resourceFilters,
        updatedAt,

        isLoading: false,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        errMsg: err.toString(),
      });
    }
  }

  _createPriceTypeFilters(selectedFilter) {
    const uprsGrouped = _.groupBy(selectedFilter.uprs, (upr) => {
      return _.get(upr, ["priceSection", "priceType"]);
    });
    let priceTypeFilters = _.map(_.keys(PRICE_TYPE), (pt) => {
      return {
        key: PRICE_TYPE[pt],
        name: PRICE_TYPE[pt],
        uprs: uprsGrouped[pt] || [],
      };
    });
    priceTypeFilters.unshift({
      key: "ALL",
      name: "ALL",
      uprs: selectedFilter.uprs,
    });

    return priceTypeFilters;
  }

  _getI2wResourceMappingFilters(selectedFilter) {
    const uprsGrouped = _.groupBy(selectedFilter.uprs, (upr) => {
      return _.get(upr, ["props", "type"]);
    });

    let resourceFilters = _.map(_.keys(uprsGrouped), (rt) => {
      return {
        key: rt,
        name: rt,
        uprs: uprsGrouped[rt] || [],
      };
    });
    resourceFilters.unshift({
      key: "ALL",
      name: "ALL",
      uprs: selectedFilter.uprs,
    });

    return resourceFilters;
  }

  toggleJsonView() {
    this.setState({ isJsonView: !this.state.isJsonView });
  }

  handleFilter({ selectedFilter }) {
    // currentFilter: {
    //   ruleType: "PUBLISHER_ONLY",
    //   priceType: "ALL",
    // },

    // selectedFilter: {key, name, uprs}

    const newUprs = selectedFilter.uprs;
    const currentFilter = {
      ruleType: selectedFilter.key,
      priceType: "ALL",
      resourceType: "ALL",
    };
    const priceTypeFilters = this._createPriceTypeFilters(selectedFilter);
    this.setState({ newUprs, currentFilter, priceTypeFilters });
  }

  handlePriceTypeFilter(selectedFilter) {
    const newUprs = selectedFilter.uprs;
    let currentFilter = {
      ...this.state.currentFilter,
      priceType: selectedFilter.key,
    };
    this.setState({ newUprs, currentFilter });
  }

  handleResourceTypeFilter(selectedFilter) {
    const newUprs = selectedFilter.uprs;
    let currentFilter = {
      ...this.state.currentFilter,
      resourceType: selectedFilter.key,
    };
    this.setState({ newUprs, currentFilter });
  }

  render() {
    const {
      networkId,
      networkInfo,

      isLoading,
      errMsg,

      updatedAt,
      uprs,
      newUprs,

      ruleFilters,
      priceTypeFilters,
      currentFilter,
      resourceFilters,

      units,
      virtualPlacements,

      isJsonView,
    } = this.state;

    const end = moment();
    const start = moment(updatedAt);
    const duration = moment.duration(end.diff(start));
    const updatedAgo = duration.asHours();

    return (
      <div>
        {networkInfo && (
          <NetworkInfoHeader
            // networkId={networkId}
            networkInfo={networkInfo}
          ></NetworkInfoHeader>
        )}

        <div className="min-h-screen bg-gray-200 px-12 py-8">
          <div className="flex items-center justify-between">
            <PageTitle title="Network GAM UPRs"></PageTitle>
          </div>

          {isLoading && <LoadingUI></LoadingUI>}
          {errMsg && <div className="text-red-800">{errMsg}</div>}

          {uprs && (
            <div>
              <div className="mb-2 flex items-center justify-end gap-2">
                <div className="text-sm">
                  Updated at:{" "}
                  <DateTimeFormatter datetime={updatedAt}></DateTimeFormatter>
                  {updatedAgo > 1 && (
                    <span className="pl-2 font-semibold text-red-600">
                      (Rules could be outdated)
                    </span>
                  )}
                </div>

                <button className={buttonClass} onClick={this.toggleJsonView}>
                  {!isJsonView ? "View original JSON" : "View in table"}
                </button>

                <button
                  className={buttonClass}
                  onClick={this.handleForceRefresh}
                >
                  Force Refresh
                </button>
              </div>

              {!isJsonView && updatedAt && (
                <div className="rounded mb-2 w-full bg-white p-4">
                  <div className="flex flex-wrap items-center text-sm">
                    <div className="mr-1 font-semibold text-gray-700">
                      Rule Filter:{" "}
                    </div>
                    {ruleFilters.map((item) => {
                      const isSelected = item.key === currentFilter.ruleType;
                      return (
                        <button
                          key={item.name}
                          type="button"
                          className={`border rounded mb-1 mr-1 border-gray-200 ${
                            isSelected ? "bg-blue-200" : "bg-white"
                          }  px-2 py-1 text-gray-800 hover:border-blue-400`}
                          onClick={() => {
                            this.handleFilter({
                              filterFn: item.filterFn,
                              currentFilter,
                              selectedFilter: item,
                            });
                          }}
                        >
                          {item.name} ({item.uprs.length})
                        </button>
                      );
                    })}
                  </div>

                  {currentFilter.ruleType !== "INTOWOW_ONLY" && (
                    <div className="mt-2 flex flex-wrap items-center text-sm">
                      <div className="mr-1 font-semibold text-gray-700">
                        Price Type Filter:{" "}
                      </div>
                      {priceTypeFilters.map((item) => {
                        const isSelected = currentFilter.priceType === item.key;
                        return (
                          <button
                            key={item.name}
                            type="button"
                            className={`border rounded mb-1 mr-1 border-gray-200 ${
                              isSelected ? "bg-blue-200" : "bg-white"
                            }  px-2 py-1 text-gray-800 hover:border-blue-400`}
                            onClick={() => {
                              this.handlePriceTypeFilter(item);
                            }}
                          >
                            {item.name} ({item.uprs.length})
                          </button>
                        );
                      })}
                    </div>
                  )}

                  {currentFilter.ruleType === "INTOWOW_ONLY" && (
                    <div className="mt-2 flex flex-wrap items-center text-sm">
                      <div className="mr-1 font-semibold text-gray-700">
                        UPR Resource Filter:{" "}
                      </div>
                      {resourceFilters.map((item) => {
                        const isSelected =
                          currentFilter.resourceType === item.key;
                        return (
                          <button
                            key={item.name}
                            type="button"
                            className={`border rounded mb-1 mr-1 border-gray-200 ${
                              isSelected ? "bg-blue-200" : "bg-white"
                            }  px-2 py-1 text-gray-800 hover:border-blue-400`}
                            onClick={() => {
                              this.handleResourceTypeFilter(item);
                            }}
                          >
                            {item.name} ({item.uprs.length})
                          </button>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}

              <div className="flex w-full">
                <div className="w-full">
                  <div className="mt-2 mb-1 flex items-center justify-between">
                    <div className="text-lg font-semibold">
                      GAM UPRs ({newUprs ? newUprs.length : 0}):{" "}
                    </div>
                  </div>

                  {updatedAt && (
                    <>
                      {isJsonView ? (
                        <textarea
                          style={{ width: "100%", height: "600px" }}
                          defaultValue={JSON.stringify(uprs, null, 4)}
                          readOnly
                        ></textarea>
                      ) : (
                        <div style={{ marginBottom: "600px" }}>
                          <GamUprTable
                            items={newUprs}
                            networkInfo={networkInfo}
                            networkId={networkId}
                            isI2wRuleSelected={
                              currentFilter.ruleType === "INTOWOW_ONLY"
                            }
                            units={units}
                            virtualPlacements={virtualPlacements}
                          ></GamUprTable>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function PageTitle({ title }) {
  return <div className="text-3xl font-extrabold">{title}</div>;
}

export default NetworkGamUprViewer;
