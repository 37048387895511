import React from "react";
import _ from "lodash";
import NumberFormat from "../common/NumberFormat";
import SegmentTargeting from "./SegmentTargeting";
import ReactTooltip from "react-tooltip";

class SegmentTable extends React.Component {
  constructor(props) {
    super(props);

    this.handleGoToExperiments = this.handleGoToExperiments.bind(this);
  }

  // shouldComponentUpdate() {
  //   return false;
  // }

  handleGoToExperiments(segmentId) {
    const element = document.getElementById(
      `segment-${segmentId}-exp-reports-button`
    );
    element.innerHTML = "Loading...";

    setTimeout(() => {
      this.props.handleSegmentSelect(segmentId);
      element.innerHTML = "EXP Reports";
    }, 500);
  }

  render() {
    const { data } = this.props;
    const { segmentInfo } = data;

    let segmentMap = _.groupBy(data.yieldLifts, "segmentId");

    const segmentDataMap = _.reduce(
      segmentMap,
      (result, experiments, segmentId) => {
        const segmentMetrics = _.reduce(
          experiments,
          (r, segment) => {
            const reports = segment.reports;
            // const totalRev = _.sumBy(reports, "revenue"); old google eligible
            const totalRev = _.sumBy(reports, "targetRevenue");

            r.totalRev += totalRev;

            return r;
          },
          {
            totalRev: 0,
          }
        );

        const latestExp = _.first(
          _.take(_.orderBy(experiments, "expId", "desc"), 1)
        );

        result[segmentId] = {
          latestExpGrossLift: _.get(latestExp, "grossLift"),
          latestExpNetLift: _.get(latestExp, "netLift"),
          latestExpMode: _.get(data, ["expInfo", latestExp.expId, "mode"]),
          revenue: _.round(segmentMetrics.totalRev, 2),
          latestExpTotalViewability: _.get(latestExp, "totalViewability"),
          latestExpAdxViewability: _.get(latestExp, "adxViewability"),
        };
        return result;
      },
      {}
    );
    const segments = _(segmentInfo)
      .map((s) => {
        s.revenue = segmentDataMap[s.id].revenue;
        s.grossLift = segmentDataMap[s.id].latestExpGrossLift;
        s.netLift = segmentDataMap[s.id].latestExpNetLift;
        s.mode = segmentDataMap[s.id].latestExpMode;
        s.totalViewability = segmentDataMap[s.id].latestExpTotalViewability;
        s.adxViewability = segmentDataMap[s.id].latestExpAdxViewability;
        return s;
      })
      .orderBy("id", "desc")
      .value();

    return (
      <>
        <div className="bg-gray-100 mt-4">
          <table className="table w-full text-sm border">
            <thead className="bg-gray-200 text-gray-800">
              <tr>
                <th rowSpan="2" className="text-center p-2 border">
                  Batch
                  <br />
                  ID
                </th>
                <th rowSpan="2" className="text-center p-2 border">
                  Segment
                  <br />
                  ID
                </th>
                <th colSpan="6" className="text-center p-2 border">
                  Targeting
                </th>
                <th colSpan="4" className="text-center p-2 border">
                  Latest Experiment
                </th>
                <th rowSpan="2" className="text-center p-2 border">
                  Revenue
                </th>
                <th rowSpan="2" className="text-center p-2 border">
                  Action
                </th>
              </tr>
              <tr>
                <th className="text-center border text-gray-900 font-semibold p-2">
                  Country
                </th>
                <th className="text-center border text-gray-900 font-semibold p-2">
                  OS
                </th>
                <th className="text-center border text-gray-900 font-semibold p-2">
                  Device Category
                </th>
                <th className="text-center border text-gray-900 font-semibold p-2">
                  Hour
                </th>
                <th className="text-center border text-gray-900 font-semibold p-2">
                  Sizes
                </th>
                <th className="text-center border text-gray-900 font-semibold p-2">
                  Customs
                </th>

                <th className="text-center border text-gray-900 font-semibold p-2">
                  Gross Rev Lift
                </th>
                <th className="text-center border text-gray-900 font-semibold p-2">
                  Net Rev Lift
                </th>
                <th className="text-center border text-gray-900 font-semibold p-2">
                  Mode
                </th>
                <th
                  className="text-center border text-gray-900 font-semibold p-2"
                  title="AV Viewable Impression / AV Measurable Impression"
                >
                  Viewability
                </th>
              </tr>
            </thead>
            <tbody>
              {segments.map((segment, i) => {
                const targeting = segment.targeting || {};
                const previousBatchId = _.get(segments, `[${i - 1}].batchId`);

                const cellStyle =
                  previousBatchId && segment.batchId !== previousBatchId
                    ? "border-t-2 border-indigo-300"
                    : "";

                return (
                  <tr key={segment.id} className={cellStyle}>
                    <td className="p-2 border text-center text-gray-800 whitespace-no-wrap">
                      {segment.batchId}
                    </td>
                    <td className="p-2 border text-center font-bold text-gray-800 whitespace-no-wrap">
                      {segment.id === -1 ? "No segment" : segment.id}

                      <div className="font-normal">{segment.intention}</div>

                      {segment.configId && (
                        <>
                          <div className="text-center">
                            <button
                              type="button"
                              onClick={() =>
                                window.open(
                                  `${window.location.origin}/#/segment-research/config/${segment.configId}/update`
                                )
                              }
                              data-tip
                              data-for={`src-${segment.id}`}
                            >
                              <span
                                title="Segment Research"
                                className="text-pink-100 bg-purple-500 rounded font-bold px-2 text-xs"
                              >
                                SR
                              </span>
                            </button>
                          </div>

                          <ReactTooltip
                            id={`src-${segment.id}`}
                            type="dark"
                            effect="solid"
                            place="top"
                          >
                            Open Segment Research Config
                          </ReactTooltip>
                        </>
                      )}
                    </td>
                    <td className="p-2 border text-left break-words align-top">
                      <SegmentTargeting
                        target={targeting.country}
                      ></SegmentTargeting>
                    </td>
                    <td className="p-2 border text-left break-words align-top">
                      <SegmentTargeting
                        target={targeting.os}
                      ></SegmentTargeting>
                    </td>
                    <td className="p-2 border text-left break-words align-top">
                      <SegmentTargeting
                        target={targeting.device_category}
                      ></SegmentTargeting>
                    </td>
                    <td className="p-2 border text-left break-words align-top">
                      <SegmentTargeting
                        target={targeting.hour}
                      ></SegmentTargeting>
                    </td>
                    <td className="p-2 border text-left break-words align-top">
                      <SegmentTargeting
                        targetKey="sizes"
                        target={targeting.sizes}
                      ></SegmentTargeting>
                    </td>
                    <td className="p-2 border text-left break-words align-top">
                      {targeting.customs ? (
                        <SegmentTargeting
                          targetKey="customs"
                          target={targeting.customs}
                        ></SegmentTargeting>
                      ) : (
                        <SegmentTargeting
                          targetKey="custom"
                          target={targeting.custom}
                        ></SegmentTargeting>
                      )}
                    </td>

                    <td
                      className={`p-2 border font-bold text-right ${
                        segment.grossLift < 0 ? "text-red-600" : ""
                      }`}
                    >
                      <NumberFormat
                        value={segment.grossLift}
                        postfix="%"
                      ></NumberFormat>
                    </td>
                    <td
                      className={`p-2 border font-bold text-right ${
                        segment.netLift < 0 ? "text-red-600" : ""
                      }`}
                    >
                      <NumberFormat
                        value={segment.netLift}
                        postfix="%"
                      ></NumberFormat>
                    </td>
                    <td className="p-2 border font-bold text-center">
                      {segment.mode}
                    </td>
                    <td className="p-2 border font-bold text-right">
                      <div>
                        <small className="text-gray-700 font-normal">
                          AdX:{" "}
                        </small>
                        <NumberFormat
                          value={segment.adxViewability}
                          postfix="%"
                        ></NumberFormat>
                      </div>
                      <div className="font-normal text-gray-800">
                        <small className="text-gray-700 font-normal">
                          Total:{" "}
                        </small>
                        <NumberFormat
                          value={segment.totalViewability}
                          postfix="%"
                        ></NumberFormat>
                      </div>
                    </td>
                    <td className="p-2 border text-right">
                      <NumberFormat
                        value={segment.revenue}
                        prefix="$"
                      ></NumberFormat>
                    </td>
                    <td className="p-2 border text-center">
                      <button
                        id={`segment-${segment.id}-exp-reports-button`}
                        type="button"
                        onClick={(e) => this.handleGoToExperiments(segment.id)}
                        className="text-gray-900 font-bold py-2 px-4 rounded bg-gray-300 shadow text-sm hover:bg-gray-400"
                      >
                        EXP Reports
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default SegmentTable;
