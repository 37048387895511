import React from "react";
import _ from "lodash";

import CSTIntegrationControl from "./CSTIntegrationControl";
import CSTGeneralControl from "./CSTGeneralControl";
import RegisteredInventorySuggestion from "./RegisteredInventorySuggestion";
import { transformWithAbTestHelper } from "./CstConfigHelper";

const INVENTORY_INTEGRATIONS = {
  GPT: "gpt",
  MOBILE_ANDROID: "mobile_android",
  MOBILE_IOS: "mobile_ios",
  VIDEO: "video",
  AMP: "amp",
};
class CSTConfigForm extends React.Component {
  constructor(props) {
    super(props);

    let { config } = props;
    const integrations = _.values(INVENTORY_INTEGRATIONS);
    // integrations: ['gpt', 'mobile_android', 'mobile_ios', 'video', 'amp']

    const transformedConfig = transformWithAbTestHelper({
      config,
      isInit: true,
    }); // first time transform (has no __xxx params)

    this.state = {
      config: transformedConfig,
      integrations,
      integrationErrMsgs: {},
    };

    this.handleGeneralSectionChanged =
      this.handleGeneralSectionChanged.bind(this);
    this.handleIntSectionChanged = this.handleIntSectionChanged.bind(this);
    this.handleMergeRIConfig = this.handleMergeRIConfig.bind(this);
    this.validateAbTestSection = this.validateAbTestSection.bind(this);
  }

  handleIntSectionChanged(intConfig) {
    // console.log("int config changed", intConfig);
    const newConfig = this.state.config;
    newConfig.inventory_integration = intConfig;
    this.props.handleConfigChanged(newConfig);
  }

  handleGeneralSectionChanged(generalConfig) {
    // console.log("generalConfig changed", generalConfig);
    let newConfig = this.state.config;
    newConfig.general = generalConfig;

    const transformedConfig = transformWithAbTestHelper({ config: newConfig });
    this.props.handleConfigChanged(transformedConfig);

    this.validateAbTestSection(transformedConfig);
  }

  validateAbTestSection(config) {
    // update integration error msg
    // return hasError
    let hasError = false;
    let integrationErrMsgs = {};
    // const bValues = _.get(config, ["general", "benchmarkValues"]);
    // const oValues = _.get(config, ["general", "managedValues"]);

    _.forIn(config.inventory_integration, (item, key) => {
      const ab = item.ab_test_traffic_allocation;
      if (ab.__is_enabled) {
        _.forIn(ab.__opt_map, (v, k) => {
          if (_.isNil(v) || v === "") {
            hasError = true;
            _.set(
              integrationErrMsgs,
              [
                key,
                "ab_test_traffic_allocation",
                "traffic_ratio_for_opt_values",
              ],
              `All values should have a ratio`
            );
            return;
          }
        });
        if (_.sum(_.values(ab.__opt_map)) !== 100) {
          hasError = true;
          _.set(
            integrationErrMsgs,
            [key, "ab_test_traffic_allocation", "traffic_ratio_for_opt_values"],
            `Ratios should add up to 100`
          );
          return;
        }

        _.forIn(ab.__bm_map, (v, k) => {
          if (_.isNil(v) || v === "") {
            hasError = true;
            _.set(
              integrationErrMsgs,
              [
                key,
                "ab_test_traffic_allocation",
                "traffic_ratio_for_bm_values",
              ],
              `All values should have a ratio`
            );
            return;
          }
        });
        if (_.sum(_.values(ab.__bm_map)) !== 100) {
          hasError = true;
          _.set(
            integrationErrMsgs,
            [key, "ab_test_traffic_allocation", "traffic_ratio_for_bm_values"],
            `Ratios should add up to 100`
          );
          return;
        }
      }
    });

    this.setState({ integrationErrMsgs });
    if (hasError) {
      this.props.handleConfigIsInvalid(hasError);
    }
    return hasError;
  }

  handleMergeRIConfig(intConfig) {
    const newConfig = this.state.config;
    newConfig.inventory_integration = _.defaultsDeep(
      intConfig,
      newConfig.inventory_integration
    );
    console.log(newConfig);
    this.props.handleConfigChanged(newConfig);
  }

  render() {
    const { config, integrations, integrationErrMsgs, hasError } = this.state;
    const { networkId, networkInfo, handleConfigIsInvalid, getRISuggestion } =
      this.props;

    return (
      <>
        <div>
          <div className="mb-2 text-2xl font-bold text-gray-800">General</div>
          <CSTGeneralControl
            networkId={networkId}
            networkInfo={networkInfo}
            config={config.general}
            handleConfigChanged={this.handleGeneralSectionChanged}
            handleConfigIsInvalid={handleConfigIsInvalid}
          ></CSTGeneralControl>
        </div>
        <div className="mt-10">
          {integrationErrMsgs && !_.isEmpty(integrationErrMsgs) && (
            <div className="bg-red-200 p-2 font-semibold text-red-800">
              Errors in <b>Inventory Integrations</b> section
              <div>{JSON.stringify(integrationErrMsgs, null, 4)}</div>
            </div>
          )}

          <div className="flex items-center justify-between">
            <div className="text-2xl font-bold text-gray-800">
              Inventory Integrations
            </div>

            <RegisteredInventorySuggestion
              getRISuggestion={getRISuggestion}
              handleMergeRIConfig={this.handleMergeRIConfig}
            ></RegisteredInventorySuggestion>
          </div>

          {integrations.map((item) => {
            return (
              <CSTIntegrationControl
                key={item}
                integration={item}
                networkId={networkId}
                config={config.inventory_integration}
                configGeneral={config.general}
                handleConfigChanged={this.handleIntSectionChanged}
              ></CSTIntegrationControl>
            );
          })}
        </div>
      </>
    );
  }
}

export default CSTConfigForm;
