import React from "react";
import _ from "lodash";
import TargetingsControl from "./TargetingsControl";

const basicInputClass =
  "bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded py-1 px-2 block appearance-none leading-normal";

class NewSegmentControl extends React.PureComponent {
  constructor(props) {
    super(props);

    const { segment_key = "", targeting = {} } = this.props.currentSegment;

    this.state = {
      segmentKey: segment_key,
      targeting,

      errorMsg: "",

      isEditing: false,
    };

    this.handleSegmentKeyChanged = this.handleSegmentKeyChanged.bind(this);
    this.handleTargetingChanged = this.handleTargetingChanged.bind(this);

    this.handleAddNewSegment = this.handleAddNewSegment.bind(this);
    this.handleCancelAddNewSegment = this.handleCancelAddNewSegment.bind(this);

    this.handleIsEditing = this.handleIsEditing.bind(this);
  }

  handleSegmentKeyChanged(e) {
    this.setState({ segmentKey: e.target.value });
  }

  handleTargetingChanged(newTargeting) {
    this.setState({ targeting: newTargeting });
  }

  handleCancelAddNewSegment() {
    this.setState({
      segmentKey: "",
      targetingString: "",
      errorMsg: "",
      isEditing: false,
    });

    // add the original segment back
    if (this.props.currentSegment && this.props.currentSegment.segment_key) {
      this.props.handleAddNewSet(this.props.currentSegment);
    } else {
      this.props.handleCancelAddNewSet();
    }
  }

  handleAddNewSegment() {
    // todo: remove targetString
    const { segmentKey, targeting, isEditing } = this.state;
    console.log("add new segment", segmentKey, targeting);
    // return;

    if (isEditing) {
      this.setState({
        errorMsg: "Customs is still editing",
      });
      return;
    }

    if (!segmentKey) {
      this.setState({
        errorMsg: "Add Segment Key first",
      });
      return;
    }

    if (segmentKey.toString().search(/^[a-zA-Z0-9-_]+$/) === -1) {
      this.setState({
        errorMsg:
          "Segment Key should be alphabets, numbers, dash and underscore only",
      });
      return;
    }

    // segment key is unique!
    const isUniq = _.find(this.props.segments, (s) => {
      return s.segment_key === segmentKey;
    });

    if (!_.isUndefined(isUniq)) {
      this.setState({
        errorMsg: "Segment Key should be unique",
      });
      return;
    }

    if (!targeting.upr_segment_id) {
      this.setState({
        errorMsg: "Missing UPR Segment ID",
      });
      return;
    }

    // let targeting = {};
    // if (targetingString && targetingString !== "") {
    //   try {
    //     targeting = JSON.parse(targetingString);
    //   } catch (err) {
    //     this.setState({
    //       errorMsg: "targeting not a valid JSON",
    //     });
    //     return;
    //   }
    // }

    const segment = {
      segment_key: segmentKey,
      targeting,
      isEditable: true,
    };
    this.setState({
      segmentKey: "",
      targetingString: "",
      errorMsg: "",
      isEditing: false,
    });
    this.props.handleAddNewSet(segment);
  }

  handleIsEditing(isEditing) {
    this.setState({
      isEditing,
    });
  }

  render() {
    const { segmentKey, targetingString, targeting, errorMsg, isEditing } =
      this.state;
    const { isEdit, networkId } = this.props;

    return (
      <div className="rounded shadow bg-white">
        <div className="border-b px-2 pt-2">
          {isEdit ? "Edit" : "New"} Segment:
        </div>
        <div className="mb-1 px-2">
          <div className="text-sm">
            <div className="mb-2">
              <div>
                <div className="font-semibold">Segment Key:</div>
                <input
                  type="text"
                  value={segmentKey}
                  className={basicInputClass + " w-full"}
                  onChange={this.handleSegmentKeyChanged}
                  autoFocus
                ></input>
                {isEdit && (
                  <div className="text-xs text-red-700">
                    Change segment key so that AutoPilot knows the targetings
                    for this segment has been changed.
                  </div>
                )}
              </div>

              <div className="border-t mt-2">
                <div className="font-semibold">Targetings:</div>
                <div>
                  <TargetingsControl
                    networkId={networkId}
                    targeting={targeting}
                    targetingString={targetingString}
                    handleTargetingChanged={this.handleTargetingChanged}
                    handleIsEditing={this.handleIsEditing}
                  ></TargetingsControl>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="border-t mt-1 flex items-center justify-end py-2 px-2">
          <div className="mr-2 text-red-600">{errorMsg}</div>
          {/* <div>{JSON.stringify(targeting, null, 3)}</div> */}

          <button
            type="button"
            className={`rounded px-4 text-blue-600 hover:text-blue-800`}
            onClick={this.handleCancelAddNewSegment}
          >
            Cancel
          </button>
          <button
            type="button"
            className={`text-blue-800 ${
              isEditing ? "bg-gray-300" : "bg-blue-200"
            } rounded px-2 py-1 font-semibold`}
            onClick={this.handleAddNewSegment}
          >
            Add segment
          </button>
        </div>
      </div>
    );
  }
}

export default NewSegmentControl;
