import React from "react";
import _ from "lodash";
import HighchartChart from "../../common/HighchartChart";

class TrendUprImpression extends React.Component {
  render() {
    const { reports } = this.props;
    const options = createChartOptions(reports);

    return (
      <>
        <HighchartChart options={options}></HighchartChart>
      </>
    );
  }
}

function createSeriesData(reports) {
  const series = _.map(reports, r => {
    const d = [new Date(r.date).getTime(), r.impressionRatio * 100];

    return d;
  });

  return [
    {
      name: "UPR Ratio",
      data: series
    }
  ];
}

function createChartOptions(reports) {
  const seriesData = createSeriesData(reports);
  const options = {
    title: {
      text: "UPR Impression Ratio"
    },
    chart: {
      type: "line",
      zoomType: "x",
      height: 260,
      backgroundColor: "rgba(0,0,0,0)"
    },
    tooltip: {
      // xDateFormat: "%Y/%m/%d %H:%M",
      // headerFormat:
      //   '<span style="font-size:10px">StartDate: {point.key}</span><table>',
      // pointFormat:
      //   '<tr><td style="color:{series.color};padding:0;font-weight: bold;">{series.name}: </td>' +
      //   '<td style="padding:0; text-align: right;"><b>{point.y:,.2f}</b>%</td></tr>',
      // footerFormat: "</table>",
      valueDecimals: 2,
      // shared: true,
      // useHTML: true,
      crosshairs: true
    },
    xAxis: {
      type: "datetime",
      labels: { format: "{value:%m/%d %a}", rotation: -65 },
      tickInterval: 24 * 60 * 60 * 1000, // 1 day
      crosshair: true
    },
    yAxis: {
      title: {
        text: "Percentage %"
      },
      min: 0
      // max: 100,
    },
    series: seriesData
  };

  return options;
}

export default TrendUprImpression;
