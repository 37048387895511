import React from "react";
import _ from "lodash";
import { FiCheck, FiX } from "react-icons/fi";

function EnabledIcon({ isEnabled }) {
  if (isEnabled) {
    return <FiCheck className="text-green-600"></FiCheck>;
  } else {
    return <FiX className="text-red-600"></FiX>;
  }
}

export default EnabledIcon;
