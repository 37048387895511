import React from "react";
import _ from "lodash";
import TrendRequestByGroupType from "./TrendRequestByGroupType";
import RequestReportTables from "./RequestReportTables";
// import TrendIED from "./TrendIED";

class BenchmarkValidation extends React.Component {
  render() {
    const { reports, iedReports } = this.props;

    // 1. Reports group by date and group type
    // 2. Reports group by date, group type and requested sizes

    const overallReports = _(reports)
      .groupBy((r) => `${r.date}_${r.groupType}`)
      .map((reports) => {
        const date = _.get(_.first(reports), "date");
        const groupType = _.get(_.first(reports), "groupType");
        const request = _.sumBy(reports, "request");
        const impression = _.sumBy(reports, "impression");
        const revenue = _.sumBy(reports, "revenue");

        const str = request > 0 ? impression / request : 0;
        const ecpm = impression > 0 ? (revenue * 1000) / impression : 0;
        const rpm = request > 0 ? (revenue * 1000) / request : 0;

        const adxMeasurableImpression = _.sumBy(
          reports,
          "adxMeasurableImpression"
        );
        const adxViewableImpression = _.sumBy(reports, "adxViewableImpression");

        const adxViewability =
          adxMeasurableImpression > 0
            ? adxViewableImpression / adxMeasurableImpression
            : 0;

        // Calculate Eligible Metrics
        // Eligible: Adx, AdSense, OpenBidding, SmartBidding
        // adx, openBidding, eb, ads
        const eligibleImpression = _.sumBy(reports, (r) => {
          return (
            r.adxImpression +
            r.openBiddingImpression +
            r.ebImpression +
            r.adsImpression
          );
        });
        const eligibleRevenue = _.sumBy(reports, (r) => {
          return (
            r.adxRevenue + r.openBiddingRevenue + r.ebRevenue + r.adsRevenue
          );
        });

        const eligibleStr = request > 0 ? eligibleImpression / request : 0;
        const eligibleEcpm =
          eligibleImpression > 0
            ? (eligibleRevenue * 1000) / eligibleImpression
            : 0;
        const eligibleRpm =
          request > 0 ? (eligibleRevenue * 1000) / request : 0;

        return {
          date,
          groupType,
          request,
          impression,
          str,
          ecpm,
          rpm,
          revenue,
          adxMeasurableImpression,
          adxViewableImpression,
          adxViewability,
          eligibleImpression,
          eligibleRevenue,
          eligibleStr,
          eligibleEcpm,
          eligibleRpm,
        };
      })
      .value();

    const requestMap = _(reports)
      .groupBy("date")
      .reduce((result, r, date) => {
        result[date] = _.sumBy(r, "request");

        return result;
      }, {});

    const sizesReports = _.map(reports, (r) => {
      r.requestRatio = _.round(r.request / requestMap[r.date], 4);
      return r;
    });

    return (
      <div className="py-4 px-4 bg-gray-300">
        {reports && (
          <>
            {/* <div className="text-sm p-2 text-gray-800">
              <div className="font-semibold text-lg text-gray-700 mb-2">
                Benchmark Discrepancy's Influence on Net Increased Revenue:
              </div>

              <div className="text-xs text-gray-600 text-left mt-2">
                <div>Calculations:</div>
                <i>Current Net Increased Revenue:</i>{" "}
                <span>
                  (Total RPM - Benchmark RPM) * Total Managed Requests - Cost
                </span>
              </div>

              <div className="text-xs text-gray-600 text-left">
                <i>Alternate Net Increased Revenue:</i>{" "}
                <span>
                  (Total RPM - Unmanaged RPM) * Total Managed Requests - Cost
                </span>
              </div>
            </div>
            <div className="bg-white rounded-t shadow-md p-4">
              <div className="text-gray-800 text-sm">
                <div>
                  Intowow Earning Difference <b>(IED)</b>:{" "}
                  <span>
                    <i>Current Net Increased Revenue</i> -{" "}
                    <i>Alternate Net Increased Revenue</i>
                  </span>
                </div>

                <div>
                  Earning Difference Sensory Impact <b>(EDSI)</b>:{" "}
                  <span>IED ÷ Total Managed Eligible Revenue</span>
                </div>
              </div>
              <div className="flex">
                <div className="w-2/3 py-2">
                  <TrendIED reports={iedReports}></TrendIED>
                </div>
                <div className="w-1/3">
                  <div className="text-xs text-left mt-4">
                    <div className="border rounded p-3">
                      <div className="font-bold text-gray-700 text-sm flex items-center">
                        <div
                          style={{
                            height: "16px",
                            width: "16px",
                            backgroundColor: "#90ED7D",
                            marginRight: "8px",
                          }}
                        ></div>
                        {"IED > 0: We could be abusing benchmark."}
                      </div>
                      <p className="text-gray-700 mt-2">
                        Further look at <b>EDSI</b> to see the significance of
                        the earning difference. <br />
                        If EDSI is tolerable (eg. <b>{"<5%"}</b>), we don't have
                        to act upon it becaused it is not significant to
                        publishers.
                      </p>
                    </div>

                    <div className="border rounded p-3 mt-4">
                      <div className="font-bold text-gray-700 text-sm flex items-center">
                        <div
                          style={{
                            height: "16px",
                            width: "16px",
                            backgroundColor: "#f96868",
                            marginRight: "8px",
                          }}
                        ></div>
                        {"IED < 0: Our earnings could be under-estimated."}
                      </div>
                      <p className="text-gray-700 mt-2">
                        <i>EDSI is not relavant here.</i> We should look at the{" "}
                        <b>IED amount</b> to evaluate the impact on Intowow
                        earnings. Every penny counts.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="font-semibold text-sm p-2 text-gray-800">
              <div className="font-semibold text-lg text-gray-700 mb-2">
                Benchmark Validation:
              </div>
            </div>
            <div className="bg-white rounded-t shadow-md p-2">
              <div className="p-2">
                <div className="text-gray-800 text-left text-sm">
                  * Eligible: AdX, AdSense, Open Bidding, Exchange Bidding,
                  Smart Bidding
                </div>
                <div className="text-xs text-gray-600 text-left mt-2">
                  discrepancy: (benchmark - unmanaged) / (ummanaged)
                </div>
              </div>
              <div className="flex py-2">
                <div className="w-1/3">
                  <TrendRequestByGroupType
                    reports={overallReports}
                    metric="eligibleEcpm"
                  ></TrendRequestByGroupType>
                </div>

                <div className="w-1/3">
                  <TrendRequestByGroupType
                    reports={overallReports}
                    metric="eligibleRpm"
                  ></TrendRequestByGroupType>
                </div>

                <div className="w-1/3">
                  <TrendRequestByGroupType
                    reports={overallReports}
                    metric="eligibleStr"
                  ></TrendRequestByGroupType>
                </div>
              </div>
            </div>

            <RequestReportTables
              overallReports={overallReports}
              sizesReports={sizesReports}
            ></RequestReportTables>
          </>
        )}
      </div>
    );
  }
}

export default BenchmarkValidation;
