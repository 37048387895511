import React from "react";
import { Switch, Route } from "react-router-dom";
// import AutoPilotConfigUnitViewer from "./AutoPilotConfigUnitViewer";
import ControlCenterAutoPilotViewer from "./ControlCenterAutoPilotViewer";
import AutoPilotViewerForUnitWithHints from "./AutoPilotViewerForUnitWithHints";

class ControlCenterViewer extends React.Component {
  componentDidMount() {
    document.body.style.backgroundColor = "#fcfcfc";
  }

  render() {
    return (
      <div className="">
        <div className="bg-blue-900 px-2">
          <div className="text-blue-100 text-sm font-bold">
            YB Control Center
          </div>
        </div>

        <>
          <Switch>
            <Route
              exact
              path="/control-center/unit/:unitId/auto-pilot/config"
              component={AutoPilotViewerForUnitWithHints}
            ></Route>
            {/*             
            <Route
              exact
              path="/control-center/unit/:unitId/auto-pilot/config/base"
              component={AutoPilotViewerForUnitWithHints}
            ></Route>

            <Route
              exact
              path="/control-center/unit/:unitId/auto-pilot/config/hint"
              component={AutoPilotViewerForUnitWithHints}
            ></Route>
             */}

            {/*             
            <Route
              exact
              path="/control-center/unit/:unitId/auto-pilot/config"
              component={ControlCenterAutoPilotViewer}
            ></Route> */}

            <Route
              exact
              path="/control-center/network/:networkId/auto-pilot/config"
              component={ControlCenterAutoPilotViewer}
            ></Route>
          </Switch>
        </>
      </div>
    );
  }
}

export default ControlCenterViewer;
