import React from "react";
import ComparisonHelper from "./ComparisonHelper";
import { FiExternalLink, FiCopy } from "react-icons/fi";
import copy from "copy-to-clipboard";

const GPT_DOC_LINK =
  "https://docs.google.com/document/d/1fnt6R1aizrgoGbLvLq1941P-ZL7pIBDtrrBHY4HGMz8";
const GPT_PARAMS_DOC_LINK =
  "https://docs.google.com/spreadsheets/d/1F_09CBY7GvNJyw6mhoA-sm47W1pr6qfIRQJf5UD1lA0";

class GptRequestsComparison extends React.Component {
  constructor(props) {
    super(props);
    // let srcUrl = `https://securepubads.g.doubleclick.net/gampad/ads?gdfp_req=1&pvsid=968532920689985&correlator=4131140122665953&output=ldjh&impl=fifs&adsid=ChEI8LLj-QUQytWw8ab64Ke-ARJBACDrt13YI-Q9jr4sYeJErN86JxV6pMHmS_Rao4q7Edh4Z-upj8TIi9D0TI_8rbQU2OEer9fdFksm2z1lhoXXtkM&eid=21066031%2C21066799%2C21066393%2C21066752&vrg=2020071601&tfcd=0&guci=2.2.0.0.2.2.0.0&sc=0&sfv=1-0-37&ecs=20200817&iu_parts=71024502%2CIN2W_OPT%2Cwinter_cpbl_m_all_300250_12&enc_prev_ius=%2F0%2F1%2F2&prev_iu_szs=300x250&prev_scp=in2w_key4%3Ddebug&cookie=ID%3D7a18bcaa1cc8d77d%3AT%3D1570698598%3AS%3DALNI_MbUonOoiMGxSskk8qcrURyoqtEQOg&bc=23&abxe=1&lmt=1597525318&dt=1597641041937&dlt=1597641041648&idt=265&frm=20&biw=411&bih=731&oid=3&adxs=56&adys=61&adks=311191330&ucis=1&ifi=1&u_tz=480&u_his=2&u_h=731&u_w=411&u_ah=731&u_aw=411&u_cd=30&u_sd=2.625&flash=0&url=http%3A%2F%2Fts.cpbl.com.tw%2F&dssz=7&icsg=42&std=0&csl=73&vis=1&scr_x=0&scr_y=0&psz=395x302&msz=395x250&ga_vid=2080128969.1570698584&ga_sid=1597641042&ga_hid=1612447364&ga_fc=true&fws=0&ohw=0`;
    // let dstUrl = `https://securepubads.g.doubleclick.net/gampad/ads?gdfp_req=1&pvsid=1666043633865193&correlator=3207067895012884&output=ldjh&impl=fif&eid=21066799%2C21066753&vrg=2020071601&tfcd=0&guci=2.2.0.0.2.2.0.0&sc=0&sfv=1-0-37&ecs=20200817&iu_parts=71024502%2CIN2W_OPT%2Cwinter_cpbl_m_all_300250_12&enc_prev_ius=%2F0%2F1%2F2&prev_iu_szs=300x250&prev_scp=in2w_key4%3D-1m-tk%26in2w_key%3D5%26in2w_key2%3Dnope%2Coptimization%26in2w_key3%3Dadx15%26in2w_key5%3Doptimization%26in2w_key6%3D-1mhtkqgz%26in2w_key7%3D15%26in2w_key8%3D5%252C6%26in2w_key9%3Doptimization_request%26in2w_key12%3Doptimization&eri=6&cookie=ID%3D7a18bcaa1cc8d77d%3AT%3D1570698598%3AS%3DALNI_MbUonOoiMGxSskk8qcrURyoqtEQOg&cdm=ts.cpbl.com.tw&bc=23&abxe=1&lmt=1597641042&dt=1597641042361&dlt=1597641042087&idt=246&ea=0&frm=23&biw=411&bih=731&isw=300&ish=250&oid=3&adxs=56&adys=61&adks=2441545565&ucis=29p648ukykv3&ifi=1&ifk=3792240173&u_tz=480&u_his=2&u_h=731&u_w=411&u_ah=731&u_aw=411&u_cd=30&u_sd=2.625&flash=0&nhd=1&iag=3&url=http%3A%2F%2Fts.cpbl.com.tw%2F&top=ts.cpbl.com.tw&dssz=6&icsg=10&std=0&vis=1&scr_x=0&scr_y=0&psz=300x250&msz=300x250&ga_vid=2080128969.1570698584&ga_sid=1597641042&ga_hid=1355783761&ga_fc=true&fws=256&ohw=0&btvi=0`;

    this.state = {
      isLoading: false,
      errMsg: null,

      url1: "",
      url2: "",
      diffResult: null,

      compareUnitId: null,
      diffs: [],

      sharableComparisonLink: "",
      isCopied: false,
    };

    this.compareUrls = this.compareUrls.bind(this);
    this.onUrlChange = this.onUrlChange.bind(this);
    this.shareComparisonLink = this.shareComparisonLink.bind(this);
  }

  componentDidMount() {
    document.title = `GPT Requests Comparison | YB Tools`;

    const { url1, url2 } = _getCompareLinksFromUrl(this.props.location.search);
    if (url1 && url2) {
      this.setState({
        url1,
        url2,
      });

      this.compareUrls({ url1, url2 });
    }
  }

  shareComparisonLink() {
    const { url1, url2 } = this.state;

    const sharableComparisonLink = _createSharableComparisonLink({
      url1,
      url2,
    });
    console.log(sharableComparisonLink);
    const r = copy(sharableComparisonLink);

    this.setState({
      sharableComparisonLink,
      isCopied: r,
    });
  }

  onUrlChange(urlKey, event) {
    const value = event.target.value;
    this.setState({ [urlKey]: value });
  }

  compareUrls({ url1, url2 }) {
    // console.log("comparing", url1, url2);

    try {
      const a = ComparisonHelper.diff(url1, url2);
      // console.log(a);

      this.setState({
        diffResult: JSON.stringify(a, null, 4),
        compareUnitId: a.unit,
        diffs: a.diff,
        errMsg: null,
      });
    } catch (err) {
      this.setState({ errMsg: err.toString() });
    }
  }

  render() {
    const { errMsg, diffs, compareUnitId } = this.state;

    return (
      <>
        <div className="bg-white px-12">
          <h1 className="font-extrabold text-gray-900 text-4xl pt-8 pb-2">
            <div className="text-base leading-none font-bold text-gray-600">
              Tools
            </div>
            GPT Requests Comparison
          </h1>
        </div>

        <div className="bg-gray-200 px-12 py-8 min-h-full">
          <div>
            <div className="flex items-center justify-end pb-4">
              <a
                className="flex items-center inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800 hover:bg-gray-300 rounded px-4 py-2 ml-8"
                href={GPT_DOC_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="pr-2">
                  <FiExternalLink></FiExternalLink>
                </span>
                Link to documentation
              </a>

              <a
                className="flex items-center inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800 hover:bg-gray-300 rounded px-4 py-2 ml-8"
                href={GPT_PARAMS_DOC_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="pr-2">
                  <FiExternalLink></FiExternalLink>
                </span>
                Link to GPT Parameter Traits
              </a>
            </div>

            <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="url1"
                >
                  URL 1
                </label>
                <textarea
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="url1"
                  value={this.state.url1}
                  onChange={(e) => this.onUrlChange("url1", e)}
                ></textarea>
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="url1"
                >
                  URL 2
                </label>
                <textarea
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="url2"
                  value={this.state.url2}
                  onChange={(e) => this.onUrlChange("url2", e)}
                ></textarea>
              </div>

              <div className="flex items-center justify-between">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={() =>
                    this.compareUrls({
                      url1: this.state.url1,
                      url2: this.state.url2,
                    })
                  }
                >
                  Compare
                </button>

                <button
                  className="flex items-center bg-blue-200 hover:text-blue-900 text-blue-700 font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={this.shareComparisonLink}
                >
                  <span className="pr-2">
                    <FiCopy></FiCopy>
                  </span>
                  Copy Sharable Link
                </button>
              </div>

              <div className="mt-2">
                <div className="flex items-center justify-end">
                  {this.state.isCopied && (
                    <div className="text-green-600 font-bold">Copied!</div>
                  )}
                  {/* {this.state.sharableComparisonLink && (
                    <textarea
                      className="bg-gray-400 appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      value={this.state.sharableComparisonLink}
                      readOnly
                      rows="1"
                    ></textarea>
                  )} */}
                </div>
              </div>
            </form>
          </div>

          {errMsg ? (
            <div className="bg-red-200 text-red-800 font-semibold p-4 rounded">
              {errMsg}
            </div>
          ) : (
            <>
              {compareUnitId && (
                <div className="pt-4">
                  <div className="text-xl font-semibold text-blue-900 mb-2">
                    Diff Result ({diffs.length})
                  </div>

                  <div className="p-4 text-blue-900 bg-gray-100 rounded text-lg font-bold">
                    <div className="text-gray-600 text-base font-semibold">
                      Unit
                    </div>
                    {compareUnitId}
                  </div>

                  <div className="p-4 bg-white mb-4">
                    {diffs.length > 0 ? (
                      <table className="table-auto w-full">
                        <thead className="text-sm text-gray-800 align-bottom">
                          <tr>
                            <th className="px-4 py-2 text-left">Key</th>
                            <th className="px-4 py-2 text-left">URL 1</th>
                            <th className="px-4 py-2 text-left">URL 2</th>
                          </tr>
                        </thead>
                        <tbody>
                          {diffs.map((d) => {
                            return (
                              <tr key={d.key} className="hover:bg-gray-100">
                                <td className="border px-4 py-2 whitespace-no-wrap font-bold text-gray-800">
                                  {d.key}
                                </td>

                                <td className="border px-4 py-2 break-all">
                                  {d.src}
                                </td>
                                <td className="border px-4 py-2 break-all">
                                  {d.dst}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <div>No diffs.</div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

function _createSharableComparisonLink({ url1, url2 }) {
  const baselink = `${window.location.origin}/#/tools/gpt-requests-comparison`;
  const encodedUrl1 = encodeURIComponent(url1);
  const encodedUrl2 = encodeURIComponent(url2);
  const queryString = `url1=${encodedUrl1}&url2=${encodedUrl2}`;
  return `${baselink}?${queryString}`;
}

function _getCompareLinksFromUrl(url) {
  const urlParams = new URLSearchParams(url);
  const url1 = urlParams.get("url1");
  const url2 = urlParams.get("url2");
  return { url1, url2 };
}

// class ClickToCopyWrapper extends React.PureComponent {
//   constructor(props) {
//     super(props);

//     this.state = {
//       isCopied: false,
//     };
//   }

//   render() {
//     const { isCopied } = this.state;
//     const { copyText } = this.props;
//     return (
//       <>
//         <div className="flex items-center">
//           <CopyToClipboard
//             text={copyText}
//             onCopy={() => this.setState({ isCopied: true })}
//           >
//             {this.props.children ? this.props.children : "Copy"}
//           </CopyToClipboard>
//           <ReactTooltip id={copyText} type="dark" effect="solid">
//             {isCopied ? "Copied!" : "Click to copy"}
//           </ReactTooltip>
//         </div>
//       </>
//     );
//   }
// }

export default GptRequestsComparison;
