import React from "react";
import _ from "lodash";

import { NetworkAPI } from "apis";
import LoadingUI from "../../../common/LoadingUI";
import NetworkInfoHeader from "../../common/NetworkInfoHeader";
import ModalWrapper from "../../../common/ModalWrapper";
import DateTimeFormatter from "../../../common/DateTimeFormatter";
import { notify } from "react-notify-toast";
import NetworkUPRSegmentationForm from "./NetworkUPRSegmenationForm";
import PreviewInJsonPopover from "../PreviewInJsonPopover";
import UprUsageEstimation from "./UprUsageEstimation";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import UPRSegmentationConfigTable from "./UPRSegmentationConfigTable";
import SuggestionPricesSection from "./SuggestionPricesSection";
import UnitSettingModal from "./UnitSettingModal";

class NetworkUPRSegmentationViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      isLoading: false,

      networkId: null,
      networkInfo: null,
      errMsg: null,

      config: null,
      createdAt: null,
      uprSegmentationId: null,

      currentRule: null,
      originalRules: null, // save original for diff
      segmentRules: null,

      modalType: null, // "ADD", "EDIT"
      isModalOpen: false,
      formErrMsg: null,

      isUnitSettingModalOpen: false,

      isSaving: false,
      saveErrMsg: null,

      // suggestions
      isSuggestionFormShown: false,
      priceSuggestions: null,
      isSuggestionLoading: false,
    };

    this.handleCurrentRuleChanged = this.handleCurrentRuleChanged.bind(this);
    this.handleOpenEdit = this.handleOpenEdit.bind(this);
    this.handleOpenAdd = this.handleOpenAdd.bind(this);
    this.handleOpenDuplicate = this.handleOpenDuplicate.bind(this);
    this.handleOpenUnitSettings = this.handleOpenUnitSettings.bind(this);

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleEditRule = this.handleEditRule.bind(this);
    this.handleAddRule = this.handleAddRule.bind(this);
    this.handleRemoveRule = this.handleRemoveRule.bind(this);

    this.handleSaveCustomUprs = this.handleSaveCustomUprs.bind(this);
    this.handleClearCustomUprs = this.handleClearCustomUprs.bind(this);

    this.handleJsonPreview = this.handleJsonPreview.bind(this);

    this.getPriceSuggestion = this.getPriceSuggestion.bind(this);

    this.handleSaveUnitSettings = this.handleSaveUnitSettings.bind(this);
    this.handleRemoveUnitSettings = this.handleRemoveUnitSettings.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      const { networkId } = this.props.match.params;
      if (!networkId) {
        throw new Error("Missing Network ID");
      }
      const networkInfo = await NetworkAPI.getNetworkInfo({ networkId });
      if (!networkInfo) {
        throw new Error("Invalid Network");
      }

      document.title = `${networkId} Network UPR Segmentation Config | YB Observer`;

      const { config, createdAt, uprSegmentationId } =
        await NetworkAPI.getNetworkUprSegmentation({ networkId });

      this.setState({
        networkId,
        networkInfo,

        segmentRules: _.cloneDeep(config),
        config,
        createdAt,
        uprSegmentationId,

        isLoading: false,
        errMsg: null,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        errMsg: err.toString(),
      });
    }
  }

  async handleClearCustomUprs() {
    const { networkId } = this.state;
    this.setState({ isSaving: true });
    try {
      console.log("update upr", networkId);
      const { result } = await NetworkAPI.updateNetworkCustomUpr({
        networkId,
        customSettings: [], // to clear custom uprs
      });
      if (result.op === "ok" || result.op === "OK") {
        notify.show("Custom UPRs cleared! Reloading page...", "success");
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      } else {
        throw `Failed response: ${JSON.stringify(result)}`;
      }
    } catch (err) {
      this.setState({ saveErrMsg: err });
    }
    this.setState({ isSaving: false });
  }

  async handleSaveCustomUprs() {
    const { networkId, segmentRules } = this.state;
    this.setState({ isSaving: true });
    try {
      console.log("update upr segmentation config", networkId, segmentRules);
      const { result } = await NetworkAPI.createNetworkUprSegmentation({
        networkId,
        config: segmentRules,
      });
      console.log(result);
      if (result === "ok" || result === "OK") {
        this.setState({ saveErrMsg: null });
        notify.show("UPR segmentation updated! Reloading page...", "success");
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      } else {
        throw `Failed response: ${JSON.stringify(result)}`;
      }
    } catch (err) {
      this.setState({ saveErrMsg: err });
    }
    this.setState({ isSaving: false });
  }

  async getPriceSuggestion({ params }) {
    this.setState({ isSuggestionLoading: true });
    try {
      // proposed_prices,
      // stats
      const priceSuggestions = await NetworkAPI.getFirstLayerPriceSuggestion({
        networkId: this.state.networkId,
        params,
      });

      let suggestionsBySegment = {};
      for (const p of priceSuggestions.proposed_prices) {
        // upr_segment_id could look like 1001_0 or just 1001
        const parts = p.upr_segment_id.toString().split("_");
        const uprSegmentId = parseInt(_.first(parts));
        // v3
        if (parts.length > 1) {
          const unitIndex = _.last(parts);

          if (unitIndex === "0") {
            _.set(suggestionsBySegment, [uprSegmentId, "parent"], p.prices);
          } else {
            // has unit suggestions
            _.set(suggestionsBySegment, [uprSegmentId, "pricesByUnit"], {});
            _.set(
              suggestionsBySegment,
              [uprSegmentId, "pricesByUnit", unitIndex],
              p.prices
            );
          }
        } else {
          _.set(suggestionsBySegment, [uprSegmentId, "parent"], p.prices);
        }
      }

      this.setState({
        priceSuggestions: {
          ...priceSuggestions,
          suggestionsBySegment,
        },
        totalUprSlot: params.total_upr_slot,
      });
    } catch (err) {
      console.log(err);
      notify.show(`Price Suggestion Error: ${err}`, "error");
    }
    this.setState({ isSuggestionLoading: false });
  }

  handleCurrentRuleChanged(newRule) {
    this.setState({
      currentRule: newRule,
    });
  }

  handleOpenAdd() {
    const defaultRule = {
      targeting: {},
    };
    this.setState({
      isModalOpen: true,
      modalType: "ADD",
      currentRule: defaultRule,
    });
  }

  handleOpenEdit(rule) {
    console.log("edit", rule);
    this.setState({ currentRule: rule, isModalOpen: true, modalType: "EDIT" });
  }

  handleOpenDuplicate(rule) {
    let duplicateRule = _.cloneDeep(rule);
    duplicateRule.id = "";
    duplicateRule.name = `${rule.name} copied`;

    this.setState({
      currentRule: duplicateRule,
      isModalOpen: true,
      modalType: "ADD",
    });
  }

  handleOpenUnitSettings(rule) {
    console.log("unit setting", rule);
    this.setState({ isUnitSettingModalOpen: true, currentRule: rule });
  }

  handleCloseModal() {
    this.setState({
      isModalOpen: false,
      currentRule: null,
      modalType: null,
      formErrMsg: null,
      isUnitSettingModalOpen: false,
    });
  }

  validateRule(newRule) {
    let errMsg = null;
    if (!newRule.id) {
      return (errMsg = "ID is required");
    }
    if (!newRule.name) {
      return (errMsg = "Name is required");
    }

    if (!newRule.optimization) {
      return (errMsg = "Optimization is required");
    }

    if (!newRule.targeting || _.isEmpty(newRule.targeting)) {
      return (errMsg = "Targeting is required");
    }

    if (!newRule.detector_prices || _.isEmpty(newRule.detector_prices)) {
      return (errMsg = "Detector prices is required");
    }

    // catchall prices is optional

    if (
      newRule.optimization !== "dt" &&
      (!newRule.prices || _.isEmpty(newRule.prices))
    ) {
      return (errMsg = "Prices is required");
    }

    return errMsg;
  }

  handleEditRule() {
    let { segmentRules } = this.state;
    let newRule = this.state.currentRule;
    console.log(newRule);

    let errMsg = this.validateRule(newRule);
    if (errMsg) {
      return this.setState({
        formErrMsg: errMsg,
      });
    }

    // Alterations
    if (newRule.optimization === "dt") {
      newRule.prices = [0];
    }

    const editedRule = newRule;
    const newRules = _.map(segmentRules, (r) => {
      // find the edited rule
      if (r.id === editedRule.id) {
        r = editedRule;
      }

      return r;
    });

    this.setState({ segmentRules: newRules });
    this.handleCloseModal();
  }

  handleAddRule() {
    let { segmentRules } = this.state;
    let newRule = this.state.currentRule;
    console.log(newRule);

    if (!newRule.id) {
      return this.setState({
        formErrMsg: "ID is required",
      });
    }
    if (!newRule.name) {
      return this.setState({
        formErrMsg: "Name is required",
      });
    }

    if (!newRule.optimization) {
      return this.setState({
        formErrMsg: "Optimization is required",
      });
    }

    if (!newRule.targeting || _.isEmpty(newRule.targeting)) {
      return this.setState({
        formErrMsg: "Targeting is required",
      });
    }

    if (!newRule.detector_prices || _.isEmpty(newRule.detector_prices)) {
      return this.setState({
        formErrMsg: "Detector prices is required",
      });
    }

    // catchall prices is optional

    if (newRule.optimization === "dt") {
      newRule.prices = [0];
    }
    if (!newRule.prices || _.isEmpty(newRule.prices)) {
      return this.setState({
        formErrMsg: "Prices is required",
      });
    }

    newRule.id = _.parseInt(newRule.id);
    // console.log(segmentRules, newRule);

    // ID should be unique
    const isIdUniq =
      _.find(segmentRules, (r) => {
        return r.id === newRule.id;
      }) === undefined;
    if (!isIdUniq) {
      this.setState({
        formErrMsg: "This ID already exists, please change the ID number.",
      });
    } else {
      let newRules = [...segmentRules, newRule];
      newRules = _.sortBy(newRules, ["id"]);
      this.setState({
        formErrMsg: null,
        segmentRules: newRules,
      });
      this.handleCloseModal();
    }
  }

  handleRemoveRule(rule) {
    const userConfirm = window.confirm(`Remove rule ${rule.id} ${rule.name}?`);
    if (!userConfirm) return;

    let { segmentRules } = this.state;
    const newRules = _.filter(segmentRules, (r) => {
      return r.id !== rule.id;
    });

    this.setState({ segmentRules: newRules });
  }

  handleSaveUnitSettings(unitSettings) {
    let { segmentRules, currentRule } = this.state;
    let newRule = {
      ...currentRule,
      unit_segmented_price_settings: unitSettings,
    };
    console.log(newRule);
    this.setState({ newRule });

    const editedRule = newRule;
    const newRules = _.map(segmentRules, (r) => {
      // find the edited rule
      if (r.id === editedRule.id) {
        r = editedRule;
      }

      return r;
    });

    this.setState({ segmentRules: newRules });
    this.handleCloseModal();
  }

  handleRemoveUnitSettings() {
    let { segmentRules, currentRule } = this.state;
    const userConfirm = window.confirm(
      `Remove unit_segmented_price_settings for rule ${currentRule.id} ${currentRule.name}?`
    );
    if (!userConfirm) return;

    let newRule = {
      ...currentRule,
    };
    newRule = _.omit(newRule, "unit_segmented_price_settings");
    console.log(newRule);
    this.setState({ newRule });

    const editedRule = newRule;
    const newRules = _.map(segmentRules, (r) => {
      // find the edited rule
      if (r.id === editedRule.id) {
        r = editedRule;
      }

      return r;
    });

    this.setState({ segmentRules: newRules });
    this.handleCloseModal();
  }

  handleJsonPreview() {
    let newRule = this.state.currentRule;
    console.log(newRule);
  }

  render() {
    const {
      networkId,
      networkInfo,

      config,
      createdAt,
      uprSegmentationId,

      currentRule,
      segmentRules,

      isLoading,
      errMsg,

      isModalOpen,
      modalType,
      formErrMsg,
      isUnitSettingModalOpen,

      isSaving,
      saveErrMsg,

      isSuggestionFormShown,
      totalUprSlot,
      priceSuggestions,
      isSuggestionLoading,
    } = this.state;

    let modalAction = modalType === "ADD" ? "Add" : "Save edit";

    return (
      <div>
        {isLoading && <LoadingUI></LoadingUI>}
        {errMsg && <div className="text-red-800">{errMsg}</div>}

        {networkInfo && (
          <div>
            <NetworkInfoHeader networkInfo={networkInfo}></NetworkInfoHeader>
            <div className="min-h-screen bg-gray-200 px-12 py-8">
              <div className="flex items-center justify-between">
                <PageTitle title="Network UPR Segmentation Config"></PageTitle>
              </div>

              <div className="flex w-full">
                <div className="mt-2 w-3/4 pr-2">
                  <div className="mb-2 flex items-center justify-between">
                    <div>
                      {uprSegmentationId && (
                        <div>UPR Segmentation ID: {uprSegmentationId}</div>
                      )}
                    </div>
                    <div>
                      <button
                        type="button"
                        className="shadow rounded bg-blue-200 py-2 px-4 font-medium text-blue-800 hover:bg-blue-300"
                        onClick={this.handleOpenAdd}
                      >
                        Add new rule
                      </button>
                    </div>
                  </div>

                  <UPRSegmentationConfigTable
                    items={segmentRules}
                    priceSuggestions={priceSuggestions}
                    // networkInfo={networkInfo}
                    handleOpenEdit={this.handleOpenEdit}
                    handleOpenDuplicate={this.handleOpenDuplicate}
                    handleRemoveRule={this.handleRemoveRule}
                    handleOpenUnitSettings={this.handleOpenUnitSettings}
                  ></UPRSegmentationConfigTable>
                </div>

                <div className="w-1/4 pl-2">
                  <div className="mb-4 text-sm font-semibold text-gray-700">
                    <div className="text-gray-600">Created at:</div>
                    <DateTimeFormatter datetime={createdAt}></DateTimeFormatter>
                  </div>

                  {/* TODO */}
                  {/* <div className="py-4 border-b">
                    <CustomUPRDiffView
                      originalRules={originalRules}
                      newRules={segmentRules}
                    ></CustomUPRDiffView>
                  </div> */}

                  <div className="mt-4 py-4">
                    <UprUsageEstimation
                      networkId={networkId}
                    ></UprUsageEstimation>
                  </div>

                  <div className="mt-4 flex justify-center">
                    <button
                      type="button"
                      className="shadow rounded w-full bg-blue-400 py-3 font-semibold text-white hover:bg-blue-600"
                      onClick={this.handleSaveCustomUprs}
                    >
                      Save UPR Segmentation
                    </button>
                  </div>

                  {/* <div className="flex justify-center mt-4">
                    <button
                      type="button"
                      className="bg-red-400 hover:bg-red-600 text-white font-semibold py-3 px-8 shadow rounded"
                      onClick={this.handleClearCustomUprs}
                    >
                      Clear Custom UPRs
                    </button>
                  </div> */}

                  <div className="font-semibold text-red-600">{saveErrMsg}</div>

                  {uprSegmentationId && (
                    <div className="rounded border mt-8 border-blue-200 bg-white px-4 py-2 text-sm">
                      <div
                        className={`-mx-4 -my-2 flex cursor-pointer items-center justify-between px-4 py-2 ${
                          isSuggestionFormShown ? "" : "hover:bg-blue-100"
                        }`}
                        onClick={() => {
                          this.setState({
                            isSuggestionFormShown:
                              !this.state.isSuggestionFormShown,
                          });
                        }}
                      >
                        <div className="text-lg font-bold text-gray-700">
                          Suggestions
                        </div>
                        <div>
                          {isSuggestionFormShown ? (
                            <FiChevronUp></FiChevronUp>
                          ) : (
                            <FiChevronDown></FiChevronDown>
                          )}
                        </div>
                      </div>

                      {isSuggestionFormShown && (
                        <div className="border-t -mx-4 my-2">
                          {isSuggestionLoading ? (
                            <LoadingUI iconOnly={true}></LoadingUI>
                          ) : (
                            <div className="px-4 pt-2">
                              <SuggestionPricesSection
                                totalUprSlot={totalUprSlot}
                                handleGetSuggestion={this.getPriceSuggestion}
                              ></SuggestionPricesSection>

                              {priceSuggestions && (
                                <PreviewInJsonPopover
                                  triggerElement={
                                    <span className="cursor-pointer bg-gray-100 px-4 text-blue-600 hover:bg-gray-200 hover:underline">
                                      Stats
                                    </span>
                                  }
                                  json={JSON.stringify(
                                    priceSuggestions.stats,
                                    null,
                                    4
                                  )}
                                  popoverWidth={"300px"}
                                ></PreviewInJsonPopover>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>

              {isModalOpen && (
                <ModalWrapper
                  isOpen={isModalOpen}
                  showCloseFooter={false}
                  handleClose={this.handleCloseModal}
                  width="80%"
                >
                  <div>
                    <div className="mb-2 text-xl font-bold text-gray-700">
                      {modalAction} Rule
                    </div>
                    <div
                      className="border overflow-y-auto"
                      style={{ maxHeight: "420px" }}
                    >
                      <NetworkUPRSegmentationForm
                        networkId={networkId}
                        networkInfo={networkInfo}
                        rule={currentRule}
                        modalType={modalType} // "ADD", "EDIT"
                        handleCurrentRuleChanged={this.handleCurrentRuleChanged}
                      ></NetworkUPRSegmentationForm>
                    </div>
                    <div className="mt-4 flex w-full">
                      <div className="w-1/3"></div>
                      <div className="flex w-1/3 items-center justify-center">
                        <PreviewInJsonPopover
                          place={"top"}
                          popoverWidth={"400px"}
                          json={JSON.stringify(this.state.currentRule, null, 4)}
                          triggerElement={
                            <button
                              type="button"
                              className="px-4 py-2 text-blue-700"
                              onClick={this.handleJsonPreview}
                              disabled={isSaving}
                            >
                              Preview in JSON
                            </button>
                          }
                        ></PreviewInJsonPopover>
                      </div>
                      <div className="flex w-1/3 items-center justify-end">
                        <div className="flex flex-row-reverse gap-4">
                          <button
                            type="button"
                            className={`px-4 py-2 ${
                              isSaving
                                ? "bg-gray-400 text-gray-800"
                                : "bg-blue-500 text-white hover:bg-blue-700"
                            }  rounded shadow font-semibold`}
                            // onClick={() => onRemoveHint(this.state.confirmingHint)}
                            onClick={
                              modalType === "ADD"
                                ? this.handleAddRule
                                : this.handleEditRule
                            }
                            disabled={isSaving}
                          >
                            {isSaving ? "Saving..." : modalAction}
                          </button>

                          <button
                            type="button"
                            disabled={isSaving}
                            className={`px-4 py-2 text-blue-700 ${
                              isSaving ? "cursor-not-allowed" : ""
                            }`}
                            onClick={this.handleCloseModal}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="text-red-600">{formErrMsg}</div>
                  </div>
                  {/* <div>{JSON.stringify(currentRule, null, 4)}</div> */}
                </ModalWrapper>
              )}

              {isUnitSettingModalOpen && (
                <UnitSettingModal
                  networkInfo={networkInfo}
                  formErrMsg={formErrMsg}
                  isUnitSettingModalOpen={isUnitSettingModalOpen}
                  currentRule={currentRule}
                  priceSuggestions={priceSuggestions}
                  isSaving={isSaving}
                  handleSaveUnitSettings={this.handleSaveUnitSettings}
                  handleRemoveUnitSettings={this.handleRemoveUnitSettings}
                  handleCloseModal={this.handleCloseModal}
                ></UnitSettingModal>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

function PageTitle({ title }) {
  return <div className="text-3xl font-extrabold">{title}</div>;
}

export default NetworkUPRSegmentationViewer;
