import React from "react";
// import _ from "lodash";
import TrendCombinedChartForCst from "./TrendCombinedChartFinalForCst";

const fixedWidthStyle = { maxWidth: "1280px", margin: "auto" };

class TrendSectionForCst extends React.Component {
  render() {
    const {
      reports,
      selectedPubId,
      selectedNetworkId,
      selectedYieldSetIds,
      startDate,
      endDate,
      selectedCurrency,
      summaryData,
    } = this.props;

    return (
      <div style={fixedWidthStyle}>
        <div className="flex items-start justify-between">
          <div className="mb-4 text-2xl font-bold ">Performance Trend</div>
        </div>

        <TrendCombinedChartForCst
          reports={reports}
          selectedPubId={selectedPubId}
          selectedNetworkId={selectedNetworkId}
          selectedYieldSetIds={selectedYieldSetIds}
          startDate={startDate}
          endDate={endDate}
          selectedCurrency={selectedCurrency}
          summaryData={summaryData}
        ></TrendCombinedChartForCst>
      </div>
    );
  }
}

export default TrendSectionForCst;
