import _ from "lodash";

import { api, fetchRespJson, postData } from "apis/base";

export default {
  // auto pilot
  getAutoPilotConfig,
  updateAutoPilotConfig,
  updateAutoPilotUserHints,
  forceIterateAutoPilotUnit,

  // auto pilot segment research config
  getActiveSegmentResearchConfigs,
  getSegmentResearchConfigById,
  getExpGroupById,
  getSegmentMetadata,
  createSegmentResearchConfig,
  pauseSegmentConfig,
  resumeSegmentConfig,

  // segments
  getSegmentsByUnit,

  // batch update ap config
  getUnitInfos,
  batchExecuteApConfig,
};

async function getSegmentsByUnit({ unitId }) {
  let apiUrl = `${api}/unit/${unitId}/segments`;
  const segments = await fetchRespJson(apiUrl);
  return segments;
}

async function getUnitInfos({ unitIds }) {
  const apiUrl = `${api}/unit/infos`;
  let updateParams = {
    unitIds,
  };
  return await postData(apiUrl, updateParams);
}

async function batchExecuteApConfig({
  unitIds,
  operations,
  targetings,
  shouldForceIterate,
}) {
  const apiUrl = `${api}/autopilot/config/batch-operation`;
  let updateParams = {
    unitIds,
    operations,
    targetings,
    shouldForceIterate,
  };
  return await postData(apiUrl, updateParams);
}

async function getAutoPilotConfig({ unitId, networkId }) {
  // /unit/689/autopilot/config
  // /network/15/autopilot/config

  if (unitId) {
    const apiUrl = `${api}/unit/${unitId}/autopilot/config`;
    const resultData = await fetchRespJson(apiUrl);

    // // Important! special case! Added extra outer layer of array
    // transform config...
    const c = resultData.config;
    const dc = resultData.defaultConfig;
    if (
      _.has(c, "prophet.benchmark_abuse_sizes") &&
      c.prophet.benchmark_abuse_sizes.length > 0
    ) {
      c.prophet.benchmark_abuse_sizes = _.first(
        c.prophet.benchmark_abuse_sizes
      );
    }

    if (
      _.has(dc, "prophet.benchmark_abuse_sizes") &&
      dc.prophet.benchmark_abuse_sizes.length > 0
    ) {
      dc.prophet.benchmark_abuse_sizes = _.first(
        dc.prophet.benchmark_abuse_sizes
      );
    }

    // // Important! special case! custom segmentation custom -> customs
    const segs = _.get(
      c,
      ["segmentation", "custom_segmentation", "segments"],
      null
    );
    if (segs && segs.length > 0) {
      const newSegs = _.map(segs, (seg) => {
        if (seg.deprecated) {
          // ignore
          return seg;
        }

        const custom = _.get(seg, ["targeting", "custom"]);
        if (custom) {
          seg.targeting.customs = [custom];
          seg.targeting = _.omit(seg.targeting, "custom");
        }

        return seg;
      });

      _.set(c, ["segmentation", "custom_segmentation", "segments"], newSegs);
    }

    resultData.config = c;
    resultData.defaultConfig = dc;

    return resultData;
  }

  if (networkId) {
    const apiUrl = `${api}/network/${networkId}/autopilot/config`;
    const resultData = await fetchRespJson(apiUrl);

    // // Important! special case! Added extra outer layer of array
    // transform config...
    const c = resultData.config;
    const dc = resultData.defaultConfig;
    if (
      _.has(c, "prophet.benchmark_abuse_sizes") &&
      c.prophet.benchmark_abuse_sizes.length > 0
    ) {
      c.prophet.benchmark_abuse_sizes = _.first(
        c.prophet.benchmark_abuse_sizes
      );
    }

    if (
      _.has(dc, "prophet.benchmark_abuse_sizes") &&
      dc.prophet.benchmark_abuse_sizes.length > 0
    ) {
      dc.prophet.benchmark_abuse_sizes = _.first(
        dc.prophet.benchmark_abuse_sizes
      );
    }

    resultData.config = c;
    resultData.defaultConfig = dc;

    return resultData;
  }
}

async function forceIterateAutoPilotUnit({ unitId }) {
  const apiUrl = `${api}/unit/${unitId}/autopilot/command`;
  let updateParams = {
    unitId,
    commandOptions: {
      shouldForceIterate: true,
    },
  };
  return await postData(apiUrl, updateParams);
}

async function updateAutoPilotUserHints({ unitId, userHints }) {
  const apiUrl = `${api}/unit/${unitId}/autopilot/user-hints`;
  let updateParams = {
    unitId,
    userHints,
  };
  return await postData(apiUrl, updateParams);
}

async function updateAutoPilotConfig({
  networkId,
  unitId,
  config,
  commandOptions,
}) {
  if (unitId) {
    const apiUrl = `${api}/unit/${unitId}/autopilot/config`;
    let updateParams = {
      unitId,
      config,
      commandOptions,
    };
    return await postData(apiUrl, updateParams);
  }

  if (networkId) {
    const apiUrl = `${api}/network/${networkId}/autopilot/config`;
    let updateParams = {
      networkId,
      config,
    };
    return await postData(apiUrl, updateParams);
  }
}

async function pauseSegmentConfig({ configId, unitId }) {
  const apiUrl = `${api}/update-segment-research-config/${configId}/pause`;
  return await postData(apiUrl, { configId, unitId });
}

async function resumeSegmentConfig({ configId, unitId }) {
  const apiUrl = `${api}/update-segment-research-config/${configId}/resume`;
  return await postData(apiUrl, { configId, unitId });
}

async function createSegmentResearchConfig(params) {
  const apiUrl = `${api}/segment-research-config/create`;
  return await postData(apiUrl, params);
}

async function getSegmentMetadata(segmentId) {
  let apiUrl = `${api}/segment-metadata/${segmentId}`;
  return await fetchRespJson(apiUrl);
}

async function getActiveSegmentResearchConfigs() {
  let apiUrl = `${api}/segment-research-config-list`;
  return await fetchRespJson(apiUrl);
}

async function getSegmentResearchConfigById({ configId }) {
  let apiUrl = `${api}/segment-research-config/${configId}`;
  return await fetchRespJson(apiUrl);
}

async function getExpGroupById({ expGroupId }) {
  let apiUrl = `${api}/experiment-group/${expGroupId}`;
  return await fetchRespJson(apiUrl);
}
