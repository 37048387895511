import React from "react";
import _, { update } from "lodash";
import { withRouter } from "react-router-dom";
import { notify } from "react-notify-toast";
import { Link } from "react-router-dom";
import { FiChevronLeft } from "react-icons/fi";
import { VseAPI } from "apis";
import RecipeFormWrapper from "./RecipeFormWrapper";

class RecipeUpdateViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,
      recipe: null,
    };

    this.handleUpdateRecipe = this.handleUpdateRecipe.bind(this);
  }

  async componentDidMount() {
    const { recipeId } = this.props.match.params;
    document.title = `Update Recipe ${recipeId} | VSE`;
    if (!recipeId) {
      this.setState({ errMsg: `Recipe ID ${recipeId} does not exist.` });
      return;
    }

    this.setState({ isLoading: true });

    try {
      const { recipes } = await VseAPI.getAllRecipes();
      // console.log(recipes);
      const recipe = _.find(recipes, { id: _.parseInt(recipeId) });
      // console.log(recipe);
      if (!recipe) {
        this.setState({
          isLoading: false,
          errMsg: `Recipe ID ${recipeId} does not exist.`,
        });
        return;
      }

      const r = {
        ...recipe,
        actionTemplates: recipe.action_templates,
        checkCriterias: recipe.criteria,
      };

      this.setState({ recipe: r, errMsg: null });
    } catch (err) {
      this.setState({ errMsg: "Error loading recipe" });
    }

    this.setState({ isLoading: false });
  }

  async handleUpdateRecipe(finalRecipe) {
    console.log("update recipe", finalRecipe);
    const { recipe } = this.state;
    const params = {
      recipe: finalRecipe,
      currentVersion: recipe.version,
      recipeId: recipe.id,
    };
    try {
      console.log("update recipe", params);
      const { result } = await VseAPI.updateRecipe(params);
      if (result.op === "ok") {
        notify.show("Recipe updated! Reloading page...", "success");
        setTimeout(() => {
          // window.location.reload(false);
          this.props.history.push("/vse/recipes");
        }, 3000);
      } else {
        throw `Failed response: ${JSON.stringify(result)}`;
      }
    } catch (err) {
      throw err;
    }
  }

  render() {
    const { isLoading, errMsg, recipe } = this.state;
    return (
      <div>
        <div className="-mt-4 mb-4">
          <Link to="/vse/recipes">
            <div className="flex">
              <div className="px-2 -ml-2 py-2 font-semibold text-gray-800 flex gap-2 items-center justify-self-start underline hover:bg-gray-300 rounded">
                <FiChevronLeft></FiChevronLeft> Recipes
              </div>
            </div>
          </Link>
        </div>

        {isLoading && "Loading..."}

        {errMsg && errMsg}

        {recipe && (
          <RecipeFormWrapper
            recipe={recipe}
            formType="UPDATE"
            handleSubmit={this.handleUpdateRecipe}
          ></RecipeFormWrapper>
        )}
      </div>
    );
  }
}

export default withRouter(RecipeUpdateViewer);
