import React from "react";
import _ from "lodash";

import { NetworkAPI } from "apis";
import LoadingUI from "../../../common/LoadingUI";
import NetworkInfoHeader from "../../common/NetworkInfoHeader";
import CustomTargetingPopover from "./CustomTargetingPopover";
import UprTargetingPopover from "../custom-upr/UprTargetingPopover";
import DateTimeFormatter from "components/common/DateTimeFormatter";
import { formatMoneyWithCurrency } from "components/intowow-business/platform-index/PlatformIndexReportsHelper";
import UprPriceSectionPopover from "../custom-upr/UprPriceSectionPopover";
import ReactJson from "@microlink/react-json-view";

const buttonActionClass =
  "px-2 py-1 bg-gray-200 text-xs rounded border border-gray-400 shadow hover:bg-gray-300 text-gray-900 font-semibold";

const buttonClass =
  "px-2 py-1 bg-gray-100 text-xs rounded border border-gray-400 hover:bg-gray-200 text-gray-900 font-semibold";

class NetworkPrebidSummaryViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      isLoading: false,

      networkId: null,
      networkInfo: null,
      errMsg: "",
      summary: null,
      isJsonView: true,
    };
    this.toggleJsonView = this.toggleJsonView.bind(this);
    this.scrollToLabel = this.scrollToLabel.bind(this);
    this.handleUpdatePrebidCache = this.handleUpdatePrebidCache.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    const { networkId } = this.props.match.params;
    if (!networkId) {
      this.setState({ errMsg: "Missing Network ID" });
    } else {
      document.title = `${networkId} Prebid Summary | YB Observer`;
      this.setState({ networkId });
    }

    const networkInfo = await NetworkAPI.getNetworkInfo({
      networkId,
    });
    this.setState({
      networkId,
      networkInfo,
    });

    try {
      // const units = await NetworkAPI.getUnitsByNetworkId({ networkId });
      // const virtualPlacements = await NetworkAPI.getNetworkVirtualPlacements({
      //   networkId,
      // });
      const summary = await NetworkAPI.getPrebidSummary({ networkId });

      this.setState({
        summary,
        isLoading: false,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        errMsg: err.toString(),
      });
    }
  }

  toggleJsonView() {
    this.setState({ isJsonView: !this.state.isJsonView });
  }

  async handleUpdatePrebidCache() {
    const { networkId } = this.state;
    if (!networkId) return;
    const userConfirm = window.confirm(
      `Update Prebid Cache for network ${networkId}? (This could take up to 15 minutes)`
    );
    if (!userConfirm) return;

    this.setState({ isTriggerLoading: true, updateCacheErrMsg: null });

    try {
      const r = await NetworkAPI.triggerUpdatePrebidCache({ networkId });
      console.log(r);

      window.alert(
        "Update prebid cache successfully triggered. Please wait about 15 min for it to finish updating."
      );

      this.setState({ isTriggerLoading: false });
    } catch (err) {
      this.setState({
        updateCacheErrMsg: typeof err !== "string" ? err.toString() : err,
        isTriggerLoading: false,
      });
    }
  }

  scrollToLabel(label) {
    const element = document.getElementById(label);

    if (element) {
      window.scrollTo({
        behavior: "auto",
        top: element.offsetTop - 56,
      });
    }
  }

  render() {
    const {
      networkId,
      networkInfo,

      isLoading,
      errMsg,
      summary,

      isJsonView,

      isTriggerLoading,
      updateCacheErrMsg,
    } = this.state;

    return (
      <div>
        {networkInfo && (
          <NetworkInfoHeader
            // networkId={networkId}
            networkInfo={networkInfo}
          ></NetworkInfoHeader>
        )}

        <div className="min-h-screen bg-gray-200 px-12 py-8">
          <div className="flex items-center justify-between">
            <PageTitle title="Network Prebid Summary"></PageTitle>
          </div>
          {isLoading && <LoadingUI></LoadingUI>}
          {errMsg && <div className="text-red-800">{errMsg}</div>}

          {summary && (
            <div>
              <div className="flex w-full">
                <div className="w-3/4">
                  <div className="mt-2 mb-1 flex items-center justify-between">
                    {/* <button
                      className={buttonClass}
                      onClick={this.toggleJsonView}
                    >
                      {!isJsonView ? "View In JSON" : "View in table"}
                    </button> */}
                  </div>

                  {summary && (
                    <>
                      {isJsonView ? (
                        <div className="bg-white">
                          <ReactJson
                            src={summary}
                            name={false}
                            displayDataTypes={false}
                            theme={"monokai"}
                            enableClipboard={false}
                          ></ReactJson>
                        </div>
                      ) : (
                        <div style={{ marginBottom: "600px" }}>TODO</div>
                      )}
                    </>
                  )}
                </div>

                <div className="w-1/4 pl-4">{""}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function PageTitle({ title }) {
  return <div className="text-3xl font-extrabold">{title}</div>;
}

export default NetworkPrebidSummaryViewer;
