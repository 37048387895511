import React from "react";
import _ from "lodash";

import { notify } from "react-notify-toast";

import { NetworkAPI } from "apis";
import NetworksTable from "./NetworksTable";
import LoadingUI from "../../common/LoadingUI";
import CurrencySelector from "../../common/CurrencySelector";
import NumberFormat from "components/common/NumberFormat";

class UpdateNetworkAdServingCost extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      isLoading: false,

      networkId: null,
      tempNetwork: null,
      errMsg: "",

      algoCostCpm: null,
      costCpm: null,
      freeQuota: null,
      costCpmCurrency: null,
    };

    this.handleUpdate = this.handleUpdate.bind(this);
    this.onInputChanged = this.onInputChanged.bind(this);
    this.handleCurrencyChanged = this.handleCurrencyChanged.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      const { networkId } = this.props.match.params;
      const queryParams = {
        networkIds: [networkId],
      };
      const items = await NetworkAPI.getNetworks(queryParams);
      console.log(items[0]);

      const item = items[0];
      const algoCostCpm = _.get(item, "algoCostCpm");
      const costCpm = _.get(item, "adServingCost.costCpm");
      const freeQuota = _.get(item, "adServingCost.freeQuota");
      const costCpmCurrency = _.get(
        item,
        "adServingCost.costCpmCurrency",
        "USD"
      ); // default USD

      this.setState({
        networkId,
        tempNetwork: item,

        algoCostCpm,
        costCpm,
        freeQuota,
        costCpmCurrency,

        isLoading: false,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        errMsg: err.toString(),
      });
    }
  }

  onInputChanged(value, key) {
    this.setState({
      [key]: value,
    });
  }

  handleCurrencyChanged(currency) {
    this.setState({ costCpmCurrency: currency });
  }

  async handleUpdate() {
    try {
      const { networkId, algoCostCpm, costCpm, freeQuota, costCpmCurrency } =
        this.state;

      if (
        _.isNil(algoCostCpm) ||
        _.isNil(costCpm) ||
        _.isNil(freeQuota) ||
        _.isNil(costCpmCurrency)
      ) {
        throw "Missing 'Algo Cost CPM', 'Cost CPM', or 'Free Quota or 'costCpmCurrency'!";
      }

      let params = {
        networkId,
        algoCostCpm,
        costCpm,
        freeQuota,
        costCpmCurrency,
      };

      this.setState({
        isSaving: true,
      });
      console.log(params);
      const result = await NetworkAPI.updateNetworkAdServingCost(params);
      console.log(result);

      notify.show(
        "Network updated! Redirecting back to Networks...",
        "success"
      );

      this.setState({
        isSaving: false,
        errMsg: "",
      });

      setTimeout(() => {
        // window.location.reload(false);
        this.props.history.goBack();
      }, 3000);
    } catch (err) {
      console.log(err);
      this.setState({
        errMsg: err,
        isSaving: false,
      });
    }
  }

  render() {
    const {
      networkId,
      tempNetwork,

      algoCostCpm,
      costCpm,
      freeQuota,
      costCpmCurrency,

      isLoading,
      isSaving,
      errMsg,
    } = this.state;

    const inputClass =
      "bg-white flex w-full border-2 border-gray-300 rounded py-1 px-3 appearance-none leading-normal focus:outline-none focus:border-blue-400 focus:shadow-inner hover:shadow hover:border-blue-300";

    return (
      <div>
        <div className="bg-white px-12 pt-8 pb-4">
          <div className="text-4xl font-extrabold text-gray-900">
            Update Network Ad Serving Cost
          </div>

          {tempNetwork && (
            <NetworksTable
              items={[tempNetwork]}
              hideAction={true}
            ></NetworksTable>
          )}
        </div>

        {isLoading && <LoadingUI></LoadingUI>}

        {tempNetwork && (
          <div className="min-h-screen bg-gray-200 px-12 py-8">
            <div>
              <div className="flex justify-center">
                <div className="border rounded shadow w-1/2 p-4">
                  <div className="py-2 text-lg font-bold">
                    {tempNetwork.networkId} - {tempNetwork.name}
                  </div>

                  <div className="my-4">
                    <label className="font-semibold text-gray-800">
                      Algo Cost CPM (in USD)
                    </label>
                    <div>
                      <input
                        type="number"
                        step="0.000001"
                        className={inputClass}
                        value={algoCostCpm}
                        onChange={(e) =>
                          this.onInputChanged(e.target.value, "algoCostCpm")
                        }
                      ></input>
                    </div>
                  </div>

                  <hr></hr>

                  <div className="my-4">
                    <label className="font-semibold text-gray-800">
                      Cost CPM Currency
                    </label>
                    <div>
                      <CurrencySelector
                        selectedCurrency={costCpmCurrency}
                        handleCurrencyChanged={this.handleCurrencyChanged}
                      ></CurrencySelector>
                    </div>
                  </div>

                  <div className="my-4">
                    <label className="font-semibold text-gray-800">
                      Cost CPM (in {costCpmCurrency})
                    </label>
                    <div>
                      <input
                        type="number"
                        step="0.000001"
                        className={inputClass}
                        value={costCpm}
                        onChange={(e) =>
                          this.onInputChanged(e.target.value, "costCpm")
                        }
                      ></input>
                    </div>
                  </div>

                  <hr></hr>

                  <div className="my-4">
                    <label className="font-semibold text-gray-800">
                      Free Quota (
                      <NumberFormat
                        value={freeQuota}
                        format="0,0a"
                      ></NumberFormat>
                      )
                    </label>
                    <div>
                      <input
                        type="number"
                        className={inputClass}
                        value={freeQuota}
                        min="0"
                        onChange={(e) =>
                          this.onInputChanged(e.target.value, "freeQuota")
                        }
                      ></input>
                    </div>
                  </div>
                  {/* <div>{JSON.stringify(this.state, null, 4)}</div> */}
                  <div className="mt-4 flex flex-row-reverse items-center">
                    <div>
                      <button
                        type="button"
                        disabled={isSaving}
                        className="rounded shadow bg-blue-500 px-4 py-2 font-semibold text-white hover:bg-blue-700"
                        onClick={() => this.handleUpdate()}
                      >
                        {isSaving ? "Saving..." : "Update Network"}
                      </button>
                    </div>
                    <div>
                      <button
                        type="button"
                        disabled={isSaving}
                        className={`px-4 py-2 text-blue-700 ${
                          isSaving ? "cursor-not-allowed" : ""
                        }`}
                        onClick={() => this.props.history.goBack()}
                      >
                        Back
                      </button>
                    </div>
                    <div className="text-red-600">{errMsg}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default UpdateNetworkAdServingCost;
