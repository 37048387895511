import React from "react";
import _ from "lodash";
import TextAreaWithJsonCheck from "components/common/TextAreaWithJsonCheck";

class ConfigGroupForm extends React.Component {
  constructor(props) {
    super(props);

    const { expGroup } = this.props;
    let {
      name = "",
      traffic_ratio = "",
      layers = "",
      metadata = "",
    } = expGroup || {};

    if (typeof layers !== "string") {
      layers = JSON.stringify(layers);
    }
    if (typeof metadata !== "string") {
      metadata = JSON.stringify(metadata);
    }

    this.state = {
      name,
      trafficRatio: traffic_ratio,
      layers,
      metadata,

      hasError: false,
      errMsg: null,
    };

    this.handleInputChanged = this.handleInputChanged.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expGroup !== this.props.expGroup) {
      // this.setState({ expGroup: this.props.expGroup });
      let {
        name = "",
        traffic_ratio = "",
        layers = "",
        metadata = "",
      } = this.props.expGroup || {};

      if (typeof layers !== "string") {
        layers = JSON.stringify(layers);
      }
      if (typeof metadata !== "string") {
        metadata = JSON.stringify(metadata);
      }

      this.setState({
        name,
        trafficRatio: traffic_ratio,
        layers,
        metadata,
      });
    }
  }

  handleInputChanged(key, value) {
    this.setState({
      [key]: value,
    });
  }

  handleSave() {
    const { name, trafficRatio, layers, metadata } = this.state;

    if (name === "" || name === null || name === undefined) {
      this.setState({
        hasError: true,
        errMsg: "Please fill in the Name",
      });
      return;
    }

    if (
      trafficRatio === "" ||
      trafficRatio === null ||
      trafficRatio === undefined
    ) {
      this.setState({
        hasError: true,
        errMsg: "Please fill in a Traffic Ratio",
      });
      return;
    }

    if (!this._checkIsJsonValid(layers)) {
      this.setState({ hasError: true, errMsg: "Layers not a valid json" });
      return;
    }

    if (!this._checkIsJsonValid(metadata)) {
      this.setState({ hasError: true, errMsg: "Metadata not a valid json" });
      return;
    }

    const group = {
      name,
      traffic_ratio: parseInt(trafficRatio),
      layers: JSON.parse(layers),
      metadata: JSON.parse(metadata),
    };

    if (this.props.isEditing) {
      this.props.onEdit(group);
    } else {
      this.props.onAdd(group);
    }
  }

  _checkIsJsonValid(jsonText) {
    let isValid = true;

    try {
      JSON.parse(jsonText);
    } catch (err) {
      isValid = false;
      // console.log(err);
    }

    return isValid;
  }

  render() {
    const { name, trafficRatio, layers, metadata, hasError, errMsg } =
      this.state;
    // const { onAddGroup } = this.props;
    const { isEditing } = this.props;

    return (
      <div className="p-4">
        <div className="mb-4">
          <div className="font-semibold text-gray-800">1. Name</div>
          <input
            type="text"
            className="border border-gray-400 p-2 rounded w-2/3"
            value={name}
            onChange={(e) => this.handleInputChanged("name", e.target.value)}
          ></input>
        </div>

        <div className="mb-4">
          <div className="font-semibold text-gray-800">2. Traffic Ratio</div>
          <div className="text-gray-700 text-sm">0, 1 - 60</div>

          <input
            type="number"
            className="border border-gray-400 p-2 rounded w-1/3"
            value={trafficRatio}
            onChange={(e) =>
              this.handleInputChanged("trafficRatio", e.target.value)
            }
          ></input>
        </div>

        <div className="mb-4">
          <div className="font-semibold text-gray-800">3. Layers</div>
          <TextAreaWithJsonCheck
            inputValue={layers}
            handleInputChanged={(value) =>
              this.handleInputChanged("layers", value)
            }
          ></TextAreaWithJsonCheck>
        </div>

        <div className="mb-4">
          <div className="font-semibold text-gray-800">4. Metadata</div>
          <TextAreaWithJsonCheck
            inputValue={metadata}
            handleInputChanged={(value) =>
              this.handleInputChanged("metadata", value)
            }
          ></TextAreaWithJsonCheck>
        </div>

        {hasError && (
          <div className="text-red-600 px-4 py-2 bg-red-100">{errMsg}</div>
        )}

        <div className="flex items-center text-sm mt-8">
          <button
            type="button"
            className="bg-blue-500 hover:bg-blue-700 text-white font-semibold rounded shadow py-1 px-8"
            onClick={this.handleSave}
          >
            {isEditing ? "Save edit" : "Add group"}
          </button>

          <button
            type="button"
            className="text-blue-500 font-semibold py-1 px-8"
            onClick={this.props.onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }
}

export default ConfigGroupForm;
