import _ from "lodash";

export const PRESETS = {
  NETWORK_BILLABLE: "Network Billable",
  GOOGLE_ELIGIBLE: "Google Eligible",
  PREBID: "Prebid",
  BILLABLE_HOUSE_ONLY: "Billable House Only",
  PREBID_WITHOUT_BILLABLE_HOUSE: "Prebid without Billable House",
  GUARENTEED: "Guarenteed",
  ALL: "All",
  CUSTOM: "Custom",
};

export const DEMAND_TYPES = {
  AD_EXCHANGE: "Ad Exchange",
  AD_SENSE: "AdSense",
  EXCHANGE_BIDDING: "Exchange Bidding",
  PRICE_PRIORITY: "Price Priority",
  NETWORK: "Network",
  BULK: "Bulk",
  HEADER_BIDDING: "Header Bidding",
  BILLABLE_HOUSE: "Billable House",
  MEDIATION: "Mediation",
  SDK_BIDDING: "SDK Bidding",
  SPONSORSHIP: "Sponsorship",
  STANDARD: "Standard",
  PREFERRED_DEAL: "Preferred Deal",
  HOUSE: "House",
};

export function getAllDemandTypes() {
  return _.values(DEMAND_TYPES);
}

export function getGoogleEligibleDemandTypes() {
  return [
    DEMAND_TYPES.AD_EXCHANGE,
    DEMAND_TYPES.AD_SENSE,
    DEMAND_TYPES.EXCHANGE_BIDDING,
  ];
}

export function getPrebidDemandTypes() {
  return [
    DEMAND_TYPES.PRICE_PRIORITY,
    DEMAND_TYPES.HEADER_BIDDING,
    DEMAND_TYPES.BILLABLE_HOUSE,
  ];
}

export function getGuarenteedDemandTypes() {
  return [
    DEMAND_TYPES.SPONSORSHIP,
    DEMAND_TYPES.STANDARD,
    DEMAND_TYPES.PREFERRED_DEAL,
  ];
}

export function getHouseDemandTypes() {
  return [DEMAND_TYPES.HOUSE];
}

export const DEMAND_TYPES_ABBR = {
  "Ad Exchange": "adx",
  AdSense: "ads",
  "Exchange Bidding": "eb",
  Network: "nw",
  "Price Priority": "pp",
  Bulk: "bk",
  "Header Bidding": "hb",
  "Billable House": "bh",
  Mediation: "md",
  "SDK Bidding": "sdkb",
  Sponsorship: "ss",
  Standard: "std",
  "Preferred Deal": "pd",
  House: "house",
};

export const demandTypePrefixes = [
  "sponsorship",
  "standard",
  "preferredDeal",
  "adx",
  "ads",
  "openBidding",
  "eb",
  "pricePriority",
  "bulk",
  "network",
  "mediation",
  "house",
];

export const demandTypeIndex = _.reduce(
  demandTypePrefixes,
  (result, dt, index) => {
    result[dt] = index;
    return result;
  },
  {}
);
