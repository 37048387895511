import React from "react";
import _ from "lodash";

import ModalWrapper from "../../../common/ModalWrapper";
import LoadingUI from "components/common/LoadingUI";

class SyncResultModal extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { isLoading, syncResult, isOpenViewModal, handleClose } = this.props;

    return (
      <ModalWrapper
        isOpen={isOpenViewModal}
        showCloseFooter={true}
        handleClose={handleClose}
      >
        <>
          {isLoading ? (
            <LoadingUI iconOnly={true}></LoadingUI>
          ) : (
            <>
              <div>
                <div className="mb-2 text-lg font-semibold">Sync Result:</div>
                <div className="overflow-y-auto" style={{ maxHeight: "300px" }}>
                  <textarea
                    className="w-full bg-gray-200"
                    rows={10}
                    defaultValue={syncResult}
                    readOnly
                  ></textarea>
                </div>
              </div>
            </>
          )}
        </>
      </ModalWrapper>
    );
  }
}

export default SyncResultModal;
