import React from "react";
import _ from "lodash";

import GeoCountrySelector from "../GeoCountrySelector";

class PublisherFormGAMProfile extends React.Component {
  constructor(props) {
    super(props);

    const { geoCountryId } = props;

    this.state = {
      geoCountryId,

      errorMsg: "",
    };

    this.handleNextStep = this.handleNextStep.bind(this);
    this.onInputChanged = this.onInputChanged.bind(this);
  }

  onInputChanged(value, key) {
    this.setState({
      [key]: value,
    });
  }

  handleNextStep() {
    // 1. validate (move inside child component)
    //  - if pass -> save to state, go to step 2
    //  - if failed -> show error message (currentErrorMsg)

    const { geoCountryId } = this.state;
    let finalForm = {};

    if (!geoCountryId || geoCountryId === "") {
      this.setState({
        errorMsg: "Please your GAM country",
      });
      return;
    }

    finalForm.geoCountryId = geoCountryId;

    this.props.handleSubmit(finalForm);
  }

  render() {
    const { handleGoToLastStep, isSaving } = this.props;
    const { errorMsg } = this.state;

    return (
      <div>
        <div className="border-b mb-8 border-gray-400 text-3xl font-bold text-gray-700">
          Invoice Setting
        </div>
        <div>
          <div className="text-lg font-semibold text-gray-800">
            What is your invoicing country?
          </div>
          <div>
            <GeoCountrySelector
              handleOnChange={(value) =>
                this.onInputChanged(value, "geoCountryId")
              }
            ></GeoCountrySelector>
          </div>
        </div>

        <div className="mb-6 font-semibold text-red-600">{errorMsg}</div>
        <div className="mt-8 flex justify-end">
          <button
            type="button"
            className="rounded px-6 py-2 font-semibold text-blue-600"
            onClick={handleGoToLastStep}
            disabled={isSaving}
          >
            Previous
          </button>

          <button
            type="button"
            className="rounded bg-blue-500 px-6 py-2 font-semibold text-white"
            onClick={this.handleNextStep}
            disabled={isSaving}
          >
            {isSaving ? "Creating Publisher..." : "Create Publisher"}
          </button>
        </div>
      </div>
    );
  }
}

export default PublisherFormGAMProfile;
