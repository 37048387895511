import React from "react";
import _ from "lodash";
import { NetworkAPI } from "apis";
import PriceTargetingSelector from "./PriceTargetingSelector";
import PriceTargetingControl from "./PriceTargetingControl";
import AdvertiserAndBrandsSelector from "./AdvertiserAndBrandsSelector";
import { GAM_CREATIVE_TYPES } from "./Constants";
import TextAreaWithJsonCheck from "components/common/TextAreaWithJsonCheck";
import ClickToCopyWrapper from "components/common/ClickToCopyWrapper";

const inputClass =
  "bg-white flex w-full border border-gray-400 rounded py-1 px-3 appearance-none leading-normal focus:outline-none focus:border-blue-400 focus:shadow-inner hover:border-gray-500";

const PRICE_TYPE_NAME = {
  FLOOR: 0,
  TARGET_CPM: 1,
  // LGO: 2, // not an option for brand price settings
};

class BrandPriceSettingControl extends React.PureComponent {
  constructor(props) {
    super(props);

    const { currentBrandPriceSetting } = props;
    const { price = "", priceType = "" } = currentBrandPriceSetting;
    const targeting = _.get(currentBrandPriceSetting, ["targeting"], {
      // sizes: {
      //   includes: [],
      // },
    });
    // creativeType: no key = "ALL". always only have a single item
    const creativeType = _.get(currentBrandPriceSetting, [
      "targeting",
      "creativeTypes",
      0,
    ]);

    this.state = {
      price,
      priceType,
      targeting,
      creativeType: creativeType || "ALL",

      errMsg: null,
    };

    this.handlePriceTypeChanged = this.handlePriceTypeChanged.bind(this);
    this.handlePriceChanged = this.handlePriceChanged.bind(this);
    this.handlePriceTargetingChanged =
      this.handlePriceTargetingChanged.bind(this);
    this.handleCreativeTypeChanged = this.handleCreativeTypeChanged.bind(this);
    this.handleAdvertiserAndBrandsChanged =
      this.handleAdvertiserAndBrandsChanged.bind(this);
    this.handleAddBrandPriceSetting =
      this.handleAddBrandPriceSetting.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handlePriceTypeChanged(priceType) {
    this.setState({ priceType });
  }

  handlePriceChanged(e) {
    const price = e.target.value ? parseFloat(e.target.value) : "";
    this.setState({ price });
  }

  handlePriceTargetingChanged(priceTargeting) {
    const targeting = this.state.targeting;
    let newTargeting = {
      ...targeting,
    };
    if (priceTargeting) {
      newTargeting.sizes = priceTargeting;
    } else {
      newTargeting = _.omit(newTargeting, "sizes");
    }
    this.setState({ targeting: newTargeting });
  }

  handleCreativeTypeChanged(ct) {
    this.setState({ creativeType: ct });
  }

  handleAdvertiserAndBrandsChanged(selectedOptions) {
    let optionsByAdvertiser = _(selectedOptions)
      .groupBy("advertiserId")
      .reduce((result, options) => {
        const advertiserId = _.first(options).advertiserId;
        let brandIds = [];
        _.forEach(options, (option) => {
          if (option.type === "BRAND") {
            brandIds.push(option.value);
          }
        });
        result.push({
          advertiserId,
          brandIds,
        });
        return result;
      }, []);
    const targeting = this.state.targeting;
    const newTargeting = {
      ...targeting,
      advertisersAndBrands: optionsByAdvertiser,
    };
    this.setState({ targeting: newTargeting });

    // "advertisersAndBrands": [
    //   // Include unclassified advertisers or brands
    //     // advertiserId: "0", brandIds: []

    //   {
    //     !"advertiserId": string, // \d+
    //     !"brandIds": [string] // \d+
    //   },
    //   ...
    // ]
  }

  handleAddBrandPriceSetting() {
    const { priceType, price, targeting, creativeType } = this.state;

    // validate
    if (priceType === null || priceType === undefined || priceType === "") {
      return this.setState({ errMsg: "Price Type is required" });
    }

    if (price === null || price === undefined || price === "") {
      return this.setState({ errMsg: "Price is required" });
    }

    // only one type of targeting is required!
    // Creative, Sizes or Advertiser and Brands
    if (
      creativeType === null ||
      creativeType === undefined ||
      creativeType === ""
    ) {
      return this.setState({ errMsg: "Creative Type is required" });
    }

    // sizes or advertiser is required
    if (creativeType === "ALL") {
      const sizesIncludes = _.get(targeting, ["sizes", "includes"], []);
      const sizesExcludes = _.get(targeting, ["sizes", "excludes"], []);
      const hasSizes = sizesIncludes.length > 0 || sizesExcludes.length > 0;

      const advertisersAndBrands = _.get(targeting, "advertisersAndBrands", []);
      const hasAdvertisers = advertisersAndBrands.length > 0;

      if (!hasSizes && !hasAdvertisers) {
        return this.setState({
          errMsg: "Need Sizes or Advertiser and Brands Targeting",
        });
      }
    }

    // advertisersAndBrands max is 49
    // sizes max is 250
    const advertisersAndBrands = _.get(targeting, "advertisersAndBrands", []);
    if (advertisersAndBrands.length > 49) {
      return this.setState({
        errMsg: "Cannot have more than 49 Advertiser and Brands Targeting",
      });
    }
    const sizesIncludes = _.get(targeting, ["sizes", "includes"], []);
    const sizesExcludes = _.get(targeting, ["sizes", "excludes"], []);
    if (sizesIncludes.length > 250 || sizesExcludes.length > 250) {
      return this.setState({
        errMsg: "Cannot have more than 250 Sizes Targeting",
      });
    }

    this.setState({ errMsg: null });
    let newSetting = {
      price,
      priceType,
      targeting,
    };
    if (creativeType !== "ALL") {
      newSetting.targeting.creativeTypes = [creativeType]; // array with single item
    }
    this.props.handleAddSetting(newSetting);
  }

  handleCancel() {
    this.setState({
      price: "",
      priceType: null,
      targeting: {},

      errMsg: null,
    });
    this.props.handleCancel();
  }

  render() {
    const { currency, networkId } = this.props;
    const { price, priceType, creativeType, targeting, errMsg } = this.state;

    return (
      <div className="rounded bg-white p-2 text-sm">
        <div className="border-b mb-4 text-base">New Brand Price Setting</div>

        <div className="mb-4">
          <div className="block font-bold text-gray-800">Price Type</div>
          <div className="flex items-center gap-4">
            <label
              htmlFor={`radio_brand_pricing_floor`}
              className="cursor-pointer"
            >
              <input
                id={`radio_brand_pricing_floor`}
                type="radio"
                name={`radio_brand_pricing`}
                value={PRICE_TYPE_NAME.FLOOR}
                checked={priceType === PRICE_TYPE_NAME.FLOOR}
                onChange={() =>
                  this.handlePriceTypeChanged(PRICE_TYPE_NAME.FLOOR)
                }
              />{" "}
              Floor
            </label>

            <label
              htmlFor={`radio_brand_pricing_targetcpm`}
              className="cursor-pointer"
            >
              <input
                id={`radio_brand_pricing_targetcpm`}
                type="radio"
                name={`radio_brand_pricing`}
                value={PRICE_TYPE_NAME.TARGET_CPM}
                checked={priceType === PRICE_TYPE_NAME.TARGET_CPM}
                onChange={() =>
                  this.handlePriceTypeChanged(PRICE_TYPE_NAME.TARGET_CPM)
                }
              />{" "}
              Target CPM
            </label>
          </div>
        </div>

        <div className="mb-4">
          <label
            className="block font-bold text-gray-800"
            htmlFor="branded_price"
          >
            Price in {currency}
          </label>
          <input
            className={inputClass}
            id="branded_price"
            type="number"
            value={price}
            step={0.01}
            min={0}
            style={{ width: "200px" }}
            onChange={this.handlePriceChanged}
          />
        </div>

        <div className="mb-4">
          <div className="block font-bold text-gray-800">
            GAM Creative Formats
          </div>
          <div className="flex items-center gap-4">
            <label
              htmlFor={`radio_brand_creative_type_all`}
              className="cursor-pointer"
            >
              <input
                id={`radio_brand_creative_type_all`}
                type="radio"
                name={`radio_brand_creative_type`}
                value={"ALL"}
                checked={creativeType === "ALL"}
                onChange={() => this.handleCreativeTypeChanged("ALL")}
              />{" "}
              Any Creative Type
            </label>

            <label
              htmlFor={`radio_brand_creative_type_display`}
              className="cursor-pointer"
            >
              <input
                id={`radio_brand_creative_type_display`}
                type="radio"
                name={`radio_brand_creative_type`}
                value={GAM_CREATIVE_TYPES["Display"]}
                checked={creativeType === GAM_CREATIVE_TYPES["Display"]}
                onChange={() =>
                  this.handleCreativeTypeChanged(GAM_CREATIVE_TYPES["Display"])
                }
              />{" "}
              Display Only
            </label>

            <label
              htmlFor={`radio_brand_creative_type_video`}
              className="cursor-pointer"
            >
              <input
                id={`radio_brand_creative_type_video`}
                type="radio"
                name={`radio_brand_creative_type`}
                value={GAM_CREATIVE_TYPES["Video"]}
                checked={creativeType === GAM_CREATIVE_TYPES["Video"]}
                onChange={() =>
                  this.handleCreativeTypeChanged(GAM_CREATIVE_TYPES["Video"])
                }
              />{" "}
              Video Only
            </label>
          </div>
        </div>

        <div className="mb-4">
          <label className="block font-bold text-gray-800">
            Sizes Targeting
          </label>
          <PriceTargetingControl
            networkId={networkId}
            targetingType="sizes"
            targeting={targeting["sizes"]}
            handleTargetingChanged={this.handlePriceTargetingChanged}
          ></PriceTargetingControl>
        </div>

        <div className="mb-4">
          <label className="block font-bold text-gray-800">
            Advertisers and Brands Targeting
          </label>
          <AdvertiserAndBrandsControl
            networkId={networkId}
            targeting={targeting["advertisersAndBrands"]}
            handleChanged={this.handleAdvertiserAndBrandsChanged}
          ></AdvertiserAndBrandsControl>
        </div>

        <div className="border-t mt-1 flex items-center justify-end py-2 px-2">
          <div className="text-red-600">{errMsg}</div>
          <button
            type="button"
            className="mb-2 px-4 py-1 text-blue-700 hover:underline"
            onClick={this.handleCancel}
          >
            Cancel
          </button>
          <button
            type="button"
            className="rounded shadow mb-2 bg-blue-200 px-4 py-1 font-semibold text-blue-700 hover:bg-blue-300"
            onClick={this.handleAddBrandPriceSetting}
          >
            Add Brand Price Setting
          </button>
        </div>
      </div>
    );
  }
}

const DEFAULT_ADVERTISERS_AND_BRANDS = [
  {
    advertiserId: "0",
    brandIds: [],
  },
  {
    advertiserId: "111",
    brandIds: ["222", "333"],
  },
];
class AdvertiserAndBrandsControl extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      controlType: "JSON", // "SELECTOR"
      jsonString: props.targeting ? JSON.stringify(props.targeting) : "",

      finalTargeting: null,
      verifyErrMsg: null,
    };

    this.handleControlTypeChanged = this.handleControlTypeChanged.bind(this);
    this.handleInputChanged = this.handleInputChanged.bind(this);
    this.handleVerify = this.handleVerify.bind(this);
  }

  handleInputChanged(inputValue) {
    this.setState({ jsonString: inputValue });
  }

  handleControlTypeChanged(type) {
    this.setState({ controlType: type });
  }

  handleVerify() {
    this.setState({ finalTargeting: null });

    setTimeout(() => {
      try {
        this.setState({
          finalTargeting: JSON.parse(this.state.jsonString),
          verifyErrMsg: null,
        });
        this.props.handleChanged(this.state.finalTargeting);
      } catch (err) {
        this.setState({ verifyErrMsg: err.toString() });
      }
    });
  }

  render() {
    const { networkId, targeting, handleChanged } = this.props;
    const { controlType, jsonString, finalTargeting, verifyErrMsg } =
      this.state;
    const defaultValue = JSON.stringify(DEFAULT_ADVERTISERS_AND_BRANDS);

    return (
      <>
        <div className="flex items-center gap-4">
          <label
            htmlFor={`radio_advb_control_type_json`}
            className="cursor-pointer"
          >
            <input
              id={`radio_advb_control_type_json`}
              type="radio"
              name={`radio_advb_control_type`}
              value={"JSON"}
              checked={controlType === "JSON"}
              onChange={() => this.handleControlTypeChanged("JSON")}
            />{" "}
            JSON
          </label>

          <label
            htmlFor={`radio_advb_control_type_selector`}
            className="cursor-pointer"
          >
            <input
              id={`radio_advb_control_type_selector`}
              type="radio"
              name={`radio_advb_control_type`}
              value={"SELECTOR"}
              checked={controlType === "SELECTOR"}
              onChange={() => this.handleControlTypeChanged("SELECTOR")}
            />{" "}
            Selector
          </label>
        </div>

        {controlType === "JSON" ? (
          <div className="border p-2">
            <div className="flex items-center justify-between text-xs text-gray-700">
              <div style={{ width: "90%" }}>ex. {defaultValue} </div>
              <ClickToCopyWrapper copyText={defaultValue}></ClickToCopyWrapper>
            </div>
            <TextAreaWithJsonCheck
              rows={6}
              inputValue={jsonString}
              handleInputChanged={this.handleInputChanged}
            ></TextAreaWithJsonCheck>
            <div className="pt-2">
              <button
                type="button"
                className="rounded shadow mb-2 bg-teal-200 px-4 py-1 font-semibold text-teal-700 hover:bg-teal-300"
                onClick={this.handleVerify}
              >
                Verify Advertisers and Brands JSON
              </button>
              {verifyErrMsg && (
                <div className="text-red-700">{verifyErrMsg}</div>
              )}
              {finalTargeting && (
                <AdvertiserAndBrandsSelector
                  networkId={networkId}
                  targeting={finalTargeting}
                  isDisabled={true}
                  // handleTargetingChanged={handleChanged}
                ></AdvertiserAndBrandsSelector>
              )}
            </div>
          </div>
        ) : (
          <AdvertiserAndBrandsSelector
            networkId={networkId}
            targeting={targeting}
            handleTargetingChanged={handleChanged}
          ></AdvertiserAndBrandsSelector>
        )}
      </>
    );
  }
}

export default BrandPriceSettingControl;
