import React from "react";
import _ from "lodash";

import { NetworkAPI } from "apis";
import LoadingUI from "../../../common/LoadingUI";
import { notify } from "react-notify-toast";
import DateTimeFormatter from "components/common/DateTimeFormatter";
import { getFeaturesSchema } from "./FeaturesSchema";
import FeaturesConfig from "./FeaturesConfig";
import ConfigDiffView from "components/control-center/auto-pilot/config/ConfigDiffView";
import PreviewFeaturesAndSaveModal from "./PreviewFeaturesAndSaveModal";
import PublisherInfoHeader from "./PublisherInfoHeader";

class PublisherFeaturesViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,

      appName: null,
      config: null,
      updatedAt: null,

      schemasGroupedBySection: null,
      currentEditingKey: null,

      newConfig: null,
      isSaving: false,
      saveErrMsg: null,

      isReviewModalOpened: false,
    };

    this.handleConfigTextChanged = this.handleConfigTextChanged.bind(this);
    this.handleSaveRawFeatures = this.handleSaveRawFeatures.bind(this);

    this.handleStartEditMode = this.handleStartEditMode.bind(this);
    this.handleCancelEditMode = this.handleCancelEditMode.bind(this);
    this.handleApplyChanges = this.handleApplyChanges.bind(this);

    this.handleCloseReviewModal = this.handleCloseReviewModal.bind(this);
    this.handleOpenReviewModal = this.handleOpenReviewModal.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      const { pubId } = this.props.match.params;
      if (!pubId) {
        throw new Error("Missing Publisher ID");
      }

      document.title = `${pubId} Publisher Features | YB Observer`;

      const { defaults, schema } = await NetworkAPI.getFeaturesSchema();
      const schemasGroupedBySection = getFeaturesSchema(defaults);

      const r = await NetworkAPI.getRawPublisherFeatures({ pubId });
      // console.log(r);

      this.setState({
        pubId,

        appName: r.app_name,
        // config: JSON.stringify(r.config, null, 4),
        // newConfig: JSON.stringify(r.config, null, 4),

        config: _.cloneDeep(r.config, null, 4),
        newConfig: _.cloneDeep(r.config, null, 4),
        updatedAt: r.updated_at,

        //new
        schemasGroupedBySection,
        defaultConfig: defaults,

        isLoading: false,
        errMsg: null,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        errMsg: err.toString(),
      });
    }
  }

  handleConfigTextChanged(inputValue) {
    this.setState({ newConfig: inputValue });
  }

  async handleSaveRawFeatures() {
    this.setState({ isSaving: true });

    try {
      const { pubId, appName, newConfig } = this.state;
      const params = {
        pubId,
        appName,
        config: newConfig,
      };
      const r = await NetworkAPI.createPublisherFeatures(params);
      console.log("save result", r);
      this.setState({
        isSaving: false,
        saveErrMsg: null,
      });

      notify.show("Features saved! Automatically reloading page...", "success");

      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
    } catch (err) {
      console.log("Failed to save publisher features", err);
      this.setState({
        isSaving: false,
        saveErrMsg: err.toString(),
      });
    }
  }

  // configKey: "enabled" / "prophet.publisher_refresh_control_type"
  handleStartEditMode(configKey) {
    console.log("start editing", configKey);
    this.setState({
      currentEditingKey: configKey,
    });
  }

  handleCancelEditMode() {
    console.log("cancel ....");
    this.setState({
      currentEditingKey: null,
    });
  }

  handleApplyChanges({ sectionConfigKey, configKey, newValue, isUseDefault }) {
    console.log("apply changes", {
      sectionConfigKey,
      configKey,
      newValue,
      isUseDefault,
    });

    let newConfig = this.state.newConfig;

    if (isUseDefault) {
      // remove from config
      if (sectionConfigKey) {
        _.unset(newConfig, [sectionConfigKey, configKey]);
      } else {
        _.unset(newConfig, configKey);
      }
    } else {
      // add/edit to config
      if (sectionConfigKey) {
        _.set(newConfig, [sectionConfigKey, configKey], newValue);
      } else {
        _.set(newConfig, configKey, newValue);
      }
    }
    // empty keys with empty object or array value
    // eg. {"forerunner": {} } -> {}
    newConfig = _.omitBy(newConfig, (c) => {
      return typeof c === "object" && _.isEmpty(c);
    });

    this.setState({
      currentEditingKey: null,
      newConfig,
    });
  }

  handleCloseReviewModal() {
    this.setState({ isReviewModalOpened: false });
  }

  handleOpenReviewModal() {
    this.setState({ isReviewModalOpened: true });
  }

  render() {
    const {
      networkId,
      pubId,
      networkInfo,
      publisherInfo,

      config,
      updatedAt,

      isLoading,
      errMsg,

      newConfig,
      isSaving,
      saveErrMsg,

      schemasGroupedBySection,
      currentEditingKey,
      defaultConfig,

      isReviewModalOpened,
    } = this.state;

    return (
      <div>
        {isLoading && <LoadingUI></LoadingUI>}
        {errMsg && <div className="text-red-800">{errMsg}</div>}

        {pubId && (
          <div>
            {/* <NetworkInfoHeader networkInfo={networkInfo}></NetworkInfoHeader> */}
            <PublisherInfoHeader pubId={pubId}></PublisherInfoHeader>
            <div className="bg-white px-12 py-8 min-h-screen">
              <div className="flex items-center justify-between">
                <PageTitle title="Publisher Features"></PageTitle>
              </div>

              <div className="w-full flex">
                <div className="mt-2 w-3/4 pr-2">
                  <FeaturesConfig
                    currentEditingKey={currentEditingKey}
                    handleStartEditMode={this.handleStartEditMode}
                    handleCancelEditMode={this.handleCancelEditMode}
                    handleApplyChanges={this.handleApplyChanges}
                    schemasGroupedBySection={schemasGroupedBySection}
                    newConfig={newConfig}
                  ></FeaturesConfig>
                  {/* <TextAreaWithJsonCheck
                    rows={18}
                    inputValue={config}
                    handleInputChanged={this.handleConfigTextChanged}
                  ></TextAreaWithJsonCheck> */}
                </div>

                <div className="pl-2 w-1/4">
                  {updatedAt && (
                    <div className="text-gray-600 text-sm mb-4">
                      Last update time:
                      <br />
                      <DateTimeFormatter
                        datetime={updatedAt}
                      ></DateTimeFormatter>
                    </div>
                  )}

                  <div
                    className="overflow-y-auto"
                    style={{ maxHeight: "420px" }}
                  >
                    <ConfigDiffView
                      type="FEATURES"
                      originalConfig={config}
                      newConfig={newConfig}
                      defaultConfig={defaultConfig}
                    ></ConfigDiffView>
                  </div>

                  <div className="flex justify-center mt-4">
                    <button
                      type="button"
                      className={`font-semibold py-3 px-8 shadow rounded ${
                        isSaving
                          ? "bg-gray-400 text-white cursor-not-allowed"
                          : "bg-blue-400 hover:bg-blue-600 text-white"
                      }`}
                      // onClick={this.handleSaveRawFeatures}
                      onClick={this.handleOpenReviewModal}
                      disabled={isSaving}
                    >
                      Preview then save features
                    </button>
                  </div>
                  <div className="text-red-600 font-semibold">{saveErrMsg}</div>
                </div>
              </div>

              <PreviewFeaturesAndSaveModal
                finalConfig={newConfig}
                isReviewModalOpened={isReviewModalOpened}
                handleCloseReviewModal={this.handleCloseReviewModal}
                isSaving={isSaving}
                saveErrMsg={saveErrMsg}
                handleConfirmSaveConfig={this.handleSaveRawFeatures}
              ></PreviewFeaturesAndSaveModal>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function PageTitle({ title }) {
  return <div className="text-3xl font-extrabold">{title}</div>;
}

export default PublisherFeaturesViewer;
