import React from "react";
import SegmentTable from "./SegmentTable";
import SegmentsOverallTimeline from "./SegmentsOverallTimeline";

class SegmentOverview extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { data, handleSegmentSelect } = this.props;

    return (
      <>
        <div className="bg-gray-100 mt-4">
          <div className="py-4 flex">
            <div className="w-full">
              <SegmentsOverallTimeline data={data}></SegmentsOverallTimeline>
            </div>
          </div>

          <SegmentTable
            data={data}
            handleSegmentSelect={handleSegmentSelect}
          ></SegmentTable>
        </div>
      </>
    );
  }
}

export default SegmentOverview;
