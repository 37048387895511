import React from "react";
import _ from "lodash";
import NumberFormat from "../../common/NumberFormat";
import { getWeekdayAbbr } from "../../../helpers/DateTransform";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";

class TableRequestSizesByRequestType extends React.Component {
  constructor(props) {
    super(props);

    const { sizesReports, selectedDate } = props;

    const dates = _(sizesReports)
      .map("date")
      .uniq()
      .sort()
      .value();

    const reports = this.filterReports(selectedDate);

    this.state = {
      dates,

      selectedDate: selectedDate,
      nextDate: getNextDate(dates, selectedDate),
      prevDate: getPreviousDate(dates, selectedDate),
      reports
    };

    this.handleSelectPreviousDate = this.handleSelectPreviousDate.bind(this);
    this.handleSelectNextDate = this.handleSelectNextDate.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedDate !== this.props.selectedDate) {
      this.selectDiffDate(this.props.selectedDate);
    }
  }

  filterReports(date) {
    return _(this.props.sizesReports)
      .filter(r => {
        return r.date === date;
      })
      .orderBy(
        ["request", "requestedSizes", "requestType"],
        ["desc", "asc", "asc"]
      )
      .value();
  }

  selectDiffDate(diffDate) {
    this.props.handleSelectDate(diffDate);

    this.setState({
      selectedDate: diffDate,
      nextDate: getNextDate(this.state.dates, diffDate),
      prevDate: getPreviousDate(this.state.dates, diffDate),
      reports: this.filterReports(diffDate)
    });
  }

  handleSelectPreviousDate() {
    const diffDate = this.state.prevDate;
    this.selectDiffDate(diffDate);
  }

  handleSelectNextDate() {
    const diffDate = this.state.nextDate;
    this.selectDiffDate(diffDate);
  }

  render() {
    const { reports, prevDate, nextDate, selectedDate } = this.state;

    const weekendAbbr = getWeekdayAbbr(selectedDate);

    return (
      <>
        {selectedDate ? (
          <div id="ad-sizes-distribution-table" className="min-h-screen">
            <div className="py-2 border-b border-gray-400">
              <div className="flex justify-between items-center">
                <div className="font-bold text-gray-700 text-xl mr-2">
                  {selectedDate} {weekendAbbr}
                </div>

                <div className="flex">
                  <button
                    type="button"
                    disabled={!!!prevDate}
                    className={`px-6 py-2 rounded shadow mr-1 ${
                      !!prevDate
                        ? "bg-indigo-300 hover:bg-indigo-400 cursor-pointer"
                        : "bg-gray-400 cursor-not-allowed"
                    }`}
                    onClick={this.handleSelectPreviousDate}
                  >
                    <FiChevronLeft></FiChevronLeft>
                  </button>
                  <button
                    type="button"
                    disabled={!!!nextDate}
                    className={`px-6 py-2 rounded shadow ${
                      !!nextDate
                        ? "bg-indigo-300 hover:bg-indigo-400 cursor-pointer"
                        : "bg-gray-400 cursor-not-allowed"
                    }`}
                    onClick={this.handleSelectNextDate}
                  >
                    <FiChevronRight></FiChevronRight>
                  </button>
                </div>
              </div>
            </div>

            <TableGroupByRequestSizes
              reports={reports}
            ></TableGroupByRequestSizes>
          </div>
        ) : (
          <div>Select a date from the table above</div>
        )}
      </>
    );
  }
}

class TableGroupByRequestSizes extends React.PureComponent {
  render() {
    const { reports, handleHighlightRow } = this.props;

    // const demandTypes = getDemandTypes(reports);
    const reportsGroupBySizes = _.groupBy(reports, "requestedSizes");
    const sizes = _.keys(reportsGroupBySizes);

    return (
      <>
        <div className="text-sm text-gray-700 py-2">
          {sizes.length} groups of different request sizes
        </div>

        {sizes.map(size => {
          const gr = reportsGroupBySizes[size];
          const totalRequestRatio = _.sumBy(gr, "requestRatio");

          return (
            <div key={size} className="flex mb-2">
              <div className="p-4 text-right" style={{ width: "12%" }}>
                <span className="block uppercase text-xs text-gray-600 font-semibold">
                  Request Ratio
                </span>
                <div className="font-semibold text-gray-800">
                  {_.isNumber(totalRequestRatio) ? (
                    <NumberFormat
                      value={totalRequestRatio}
                      format="0,0.00%"
                    ></NumberFormat>
                  ) : (
                    "-"
                  )}
                </div>
              </div>

              <div
                className="border-b p-4 bg-white rounded shadow-md"
                style={{ width: "88%" }}
              >
                <div className="">
                  <span className="block uppercase text-xs text-gray-600 font-semibold">
                    Requested Sizes
                  </span>
                  <div className="font-semibold text-gray-800">{size}</div>
                </div>

                <div className="mt-2">
                  <RequestSizesTable
                    reports={gr}
                    handleHighlightRow={handleHighlightRow}
                  ></RequestSizesTable>
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  }
}

class RequestSizesTable extends React.PureComponent {
  render() {
    const { reports } = this.props;

    // group by request Type
    const reportsGroupByRequestType = _.groupBy(reports, "requestType");

    return (
      <div className="w-full overflow-x-auto">
        {reports &&
          _.keys(reportsGroupByRequestType).map(requestType => {
            const rtReports = reportsGroupByRequestType[requestType];
            const requestTypeStyle = getRequestTypeStyle(requestType);

            return (
              <div key={requestType}>
                <div
                  className="font-semibold px-2 text-gray-800"
                  style={requestTypeStyle}
                >
                  {requestType}
                </div>

                <table className="w-full text-sm">
                  <thead className="bg-gray-200 text-blue-800 text-xs border-b">
                    <tr>
                      <th
                        rowSpan="2"
                        className="text-left px-2"
                        // style={{ width: "30px" }}
                      >
                        Device Cat
                      </th>
                      <th className="text-right py-1 px-2">Request</th>
                      <th className="text-right py-1 px-2">Request %</th>
                      <th className="text-right py-1 px-2">Impression</th>
                      <th className="text-right py-1 px-2">STR</th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-900 bg-white">
                    {rtReports.map((r, i) => {
                      return (
                        <tr key={i} className="border-b hover:bg-gray-100">
                          <td className="text-left py-1 px-2">
                            {r.deviceCategory}
                          </td>
                          <td className="text-right font-mono text-gray-800 py-1 px-2">
                            {_.isNumber(r.request) ? (
                              <NumberFormat
                                value={r.request}
                                format="0,0"
                              ></NumberFormat>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td className="text-right font-mono text-gray-800 py-1 px-2">
                            {_.isNumber(r.requestRatio) ? (
                              <NumberFormat
                                value={r.requestRatio}
                                format="0,0.00%"
                              ></NumberFormat>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td className="text-right font-mono text-gray-800 py-1 px-2">
                            {_.isNumber(r.impression) ? (
                              <NumberFormat
                                value={r.impression}
                                format="0,0"
                              ></NumberFormat>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td className="text-right font-mono text-gray-800 py-1 px-2 font-bold">
                            {_.isNumber(r.str) ? (
                              <NumberFormat
                                value={r.str}
                                format="0,0.00%"
                              ></NumberFormat>
                            ) : (
                              "-"
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            );
          })}

        {/* <table className="w-full table-fixed text-sm">
          <thead className="bg-gray-200 text-blue-800 text-xs border-b">
            <tr>
              <th rowSpan="2" className="text-center" style={{ width: "30px" }}>
                Device Cat
              </th>
              <th className="text-right py-1 px-2">Request</th>
              <th className="text-right py-1 px-2">Impression</th>
              <th className="text-right py-1 px-2">STR</th>
            </tr>
          </thead>
          <tbody className="text-gray-900 bg-white">
            {reports.map((r, i) => {
              return (
                <tr key={i} className="border-b hover:bg-gray-100">
                  <td className="text-center py-1 px-2">{r.deviceCategory}</td>
                  <td className="text-right font-mono text-gray-800 py-1 px-2">
                    {_.isNumber(r.request) ? (
                      <NumberFormat
                        value={r.request}
                        format="0,0"
                      ></NumberFormat>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="text-right font-bold font-mono text-gray-800 py-1 px-2">
                    {_.isNumber(r.str) ? (
                      <NumberFormat
                        value={r.str}
                        format="0,0.00%"
                      ></NumberFormat>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="text-right font-bold font-mono text-gray-800 py-1 px-2">
                    {_.isNumber(r.ecpm) ? (
                      <NumberFormat
                        value={r.ecpm}
                        format="$0,0.00"
                      ></NumberFormat>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="text-right font-bold font-mono text-gray-800 py-1 px-2">
                    {_.isNumber(r.rpm) ? (
                      <NumberFormat
                        value={r.rpm}
                        format="$0,0.00"
                      ></NumberFormat>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="text-right font-mono text-gray-800 py-1 px-2">
                    {_.isNumber(r.impression) ? (
                      <NumberFormat
                        value={r.impression}
                        format="0,0"
                      ></NumberFormat>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="text-right font-mono text-gray-800 py-1 px-2">
                    {_.isNumber(r.revenue) ? (
                      <NumberFormat
                        value={r.revenue}
                        format="$0,0.00"
                      ></NumberFormat>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="text-right font-mono text-gray-800 py-1 px-2 border-r-2 border-gray-400 last:border-r-0">
                    {_.isNumber(r.adxViewability) ? (
                      <NumberFormat
                        value={r.adxViewability}
                        format="0,0.00%"
                      ></NumberFormat>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table> */}
      </div>
    );
  }
}

function getRequestTypeStyle(type) {
  switch (type) {
    case "GPT": {
      return { backgroundColor: "#e7e7ff" };
    }
    case "AMP": {
      return { backgroundColor: "#d7f5e5" };
    }
    default: {
      // nothin
    }
  }
}

function getNextDate(dates, targetDate) {
  const index = _.indexOf(dates, targetDate);

  // has no next date
  if (index === dates.length - 1) {
    return null;
  } else {
    return dates[index + 1];
  }
}

function getPreviousDate(dates, targetDate) {
  const index = _.indexOf(dates, targetDate);

  // has no previous date
  if (index === 0) {
    return null;
  } else {
    return dates[index - 1];
  }
}

export default TableRequestSizesByRequestType;
