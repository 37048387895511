import React from "react";
import _ from "lodash";

import ModalWrapper from "../../../common/ModalWrapper";
import { NetworkAPI } from "apis";
import DateTimeFormatter from "components/common/DateTimeFormatter";
import BuildReleaseStatusLabel from "./BuildReleaseStatusLabel";

const buttonActionClass =
  "px-2 py-0 bg-gray-200 text-xs rounded border border-gray-400 shadow hover:bg-gray-300 text-gray-900";

class UpdateStatusModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      errMsg: null,
      build: props.build,
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleReleaseIntegration = this.handleReleaseIntegration.bind(this);
    this.handleReleaseProduction = this.handleReleaseProduction.bind(this);
  }

  async handleReleaseIntegration() {
    this.setState({ isSaving: true, errMsg: null });
    try {
      const params = {
        networkId: this.props.networkId,
        buildId: this.props.build.buildId,
      };
      const r = await NetworkAPI.updateIntegrationReleaseTime(params);
      await this.props.handleRefreshAfterBuild();
    } catch (err) {
      this.setState({
        errMsg: err,
      });
    }
    this.setState({ isSaving: false });
  }

  async handleReleaseProduction() {
    this.setState({ isSaving: true, errMsg: null });
    try {
      const params = {
        networkId: this.props.networkId,
        buildId: this.props.build.buildId,
      };
      const r = await NetworkAPI.updateProductionReleaseTime(params);
      await this.props.handleRefreshAfterBuild();
    } catch (err) {
      this.setState({
        errMsg: err,
      });
    }
    this.setState({ isSaving: false });
  }

  handleCancel() {
    this.props.handleClose();
  }

  render() {
    const { isSaving, errMsg } = this.state;
    const { isOpenViewModal, handleClose, buildId, build } = this.props;

    return (
      <ModalWrapper
        isOpen={isOpenViewModal}
        showCloseFooter={true}
        handleClose={handleClose}
      >
        <>
          <div className="border-b -mx-6 mb-4 px-6 pb-2 text-lg font-bold">
            {buildId}{" "}
            <span className="font-normal">
              ({build.packageName} version {build.packageVersion})
            </span>
          </div>

          <div className="overflow-y-auto" style={{ height: "360px" }}>
            <div className="text-lg font-semibold">
              Release Status:
              <BuildReleaseStatusLabel
                buildId={build.buildId}
                integrationReleasedAt={build.integrationReleasedAt}
                productionReleasedAt={build.productionReleasedAt}
              ></BuildReleaseStatusLabel>
            </div>
            <div>
              Integration released at:{" "}
              <DateTimeFormatter
                datetime={build.integrationReleasedAt}
              ></DateTimeFormatter>{" "}
              {!build.integrationReleasedAt && (
                <button
                  type="button"
                  className={buttonActionClass}
                  onClick={this.handleReleaseIntegration}
                >
                  Release Integration
                </button>
              )}
            </div>
            <div>
              Production released at:{" "}
              <DateTimeFormatter
                datetime={build.productionReleasedAt}
              ></DateTimeFormatter>{" "}
              {build.integrationReleasedAt && !build.productionReleasedAt && (
                <button
                  type="button"
                  className={buttonActionClass}
                  onClick={this.handleReleaseProduction}
                >
                  Release Production
                </button>
              )}
            </div>
            <div className="text-red-600">{errMsg}</div>
          </div>
        </>
      </ModalWrapper>
    );
  }
}

export default UpdateStatusModal;
