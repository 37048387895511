import React from "react";
import _ from "lodash";
import NumberFormat from "components/common/NumberFormat";

function MaxCurrentView({
  title,
  max,
  current,
  maxHint,
  diff = 0,
  isEstimate = false,
}) {
  const hasMax = max !== null && max > 0;
  const hasCurrent = current !== null && current > 0;
  const percentage = hasMax ? (current + diff) / max : "";

  return (
    <div className="mb-2">
      <div className="text-gray-700 text-sm">{title}</div>
      <div className="flex text-gray-900">
        <div className="border-r pr-2">
          {hasMax ? (
            <>
              <NumberFormat value={max} format="0,0"></NumberFormat>
              <span className="text-gray-700">{maxHint && "*"}</span>
            </>
          ) : (
            "-"
          )}
        </div>
        <div className="pl-2 font-semibold">
          {hasCurrent ? (
            <>
              <NumberFormat value={current + diff} format="0,0"></NumberFormat>{" "}
              <span className="text-sm">
                {isEstimate ? (
                  <span
                    className={diff >= 0 ? "text-green-600" : "text-red-600"}
                  >
                    {diff >= 0 ? "+" : ""}
                    <NumberFormat value={diff} format="0,0"></NumberFormat>
                  </span>
                ) : (
                  "current"
                )}
              </span>
            </>
          ) : (
            "-"
          )}
        </div>
        <div className="pl-2">
          {hasMax && hasCurrent && (
            <span className="text-sm">
              (
              <NumberFormat
                value={percentage}
                format={_.round(percentage, 4) > 0.01 ? "0,0%" : "0,0.00%"}
              ></NumberFormat>
              )
            </span>
          )}
        </div>
      </div>
      {maxHint && (
        <div className="text-xs italic text-gray-700">*{maxHint}</div>
      )}
    </div>
  );
}

export default MaxCurrentView;
