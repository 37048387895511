import React from "react";
import _ from "lodash";
import AdSizesFormatter from "./AdSizesFormatter";

class SetOfAdSizesFormatter extends React.PureComponent {
  render() {
    const {
      setsOfAdSizes,
      showActionButtons,
      handleRemoveSet,
      handleEditSet,
    } = this.props;

    if (!setsOfAdSizes || !_.isArray(setsOfAdSizes)) {
      return "None";
    }

    const percentage = _.round((1 / setsOfAdSizes.length) * 100);

    return (
      <div>
        {setsOfAdSizes.map((adSizes, i) => {
          return (
            <div key={i} className="flex items-center mb-1">
              <div className="pr-1 text-gray-600 font-normal">
                <small>{percentage}%</small>
              </div>
              <div className="flex justify-between p-1 bg-gray-100 border w-full">
                {/* <div className="px-1 text-gray-600">({adSizes.length})</div> */}
                <AdSizesFormatter
                  adSizes={adSizes}
                  showRemoveButton={false}
                ></AdSizesFormatter>

                {showActionButtons && (
                  <div className="flex w-1/3 justify-end">
                    {typeof adSizes === "string" ? (
                      ""
                    ) : (
                      <button
                        type="button"
                        className="text-blue-700 px-2 hover:text-blue-900"
                        onClick={() => handleEditSet(i)}
                      >
                        Edit
                      </button>
                    )}
                    <button
                      type="button"
                      className="text-blue-700 px-2 hover:text-blue-900"
                      onClick={() => handleRemoveSet(i)}
                    >
                      Remove
                    </button>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default SetOfAdSizesFormatter;
