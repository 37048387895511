import React from "react";
import moment from "moment-timezone";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

class HighchartWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();

    this.state = {
      options: this.props.options,
    };

    this.handleCursorMouseOut = this.handleCursorMouseOut.bind(this);
  }

  componentDidUpdate(prevProps) {
    // console.log(prevProps.options, this.props.options);
    if (prevProps.options !== this.props.options) {
      // console.log("yo");
      this.setState({ options: this.props.options });
    }
  }

  handleCursorMouseOut() {
    const chart = this.ref.current.chart;
    _reinitializeChart(chart);
  }

  render() {
    const { options } = this.state;
    if (!options.xAxis) {
      options.xAxis = _getXAxisConfigDateTime();
    }

    return (
      <div onMouseOut={this.handleCursorMouseOut}>
        <HighchartsReact
          ref={this.ref}
          highcharts={Highcharts}
          options={options}
        />
      </div>
    );
  }
}

function _getXAxisConfigDateTime() {
  return {
    type: "datetime",
    labels: {
      formatter: function () {
        const m = moment(this.value);
        const d = m.format("DD");
        // const d = m.format("MM/DD");
        const wd = m.format("ddd");
        const label = `${d} <br/> ${wd}`;
        if (d === "01" || this.isFirst) {
          return `${d} <br/> ${wd} <br/> ${m.format("M")}`;
        }
        // if (isWeekend(this.value)) {
        //   return `<span style="color: #dd6b20;">${label}</span>`;
        // }
        return label;
      },
    },
    tickInterval: 24 * 60 * 60 * 1000, // 1 day
    crosshair: true,
  };
}

// revert chart back to initial state before hover
// - hide tooltip
// - set all series back to normal state instead of hover
// - points should not be focused
// - hide crosshairs
function _reinitializeChart(chart) {
  for (let i = 0; i < chart.series.length; i++) {
    let s = chart.series[i];
    s.setState("normal", true);

    let points = chart.series[i].points;
    for (let j = 0; j < points.length; j++) {
      try {
        points[j].onMouseOut();
      } catch (err) {
        //console.log(err);
      }
    }

    chart.series[i].chart.redraw();
  }

  chart.tooltip.hide();
  chart.xAxis[0].hideCrosshair();
}

export default HighchartWrapper;
