import React from "react";
import _ from "lodash";
import ModalWrapper from "../common/ModalWrapper";
import PasswordEyeInput from "../common/PasswordEyeInput";

const inputClassDisabled =
  "bg-gray-300 flex w-full border border-gray-400 rounded py-1 px-3 appearance-none leading-normal";

class VaultUserNewPasswordModal extends React.Component {
  constructor(props) {
    super(props);

    const { user } = props;

    this.state = {
      user,
      newPassword: "",
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleNewPWChanged = this.handleNewPWChanged.bind(this);
  }

  handleCancel() {
    this.setState({
      user: null,
      newPassword: "",
    });
    this.props.handleClose();
  }

  handleSubmit() {
    const { newPassword } = this.state;
    this.props.handleSave({ newPassword });
  }

  handleNewPWChanged(e) {
    this.setState({ newPassword: e.target.value });
  }

  render() {
    const {
      user,
      isOpenModal,
      // handleClose,
      isSaving,
      saveErrorMsg,
    } = this.props;
    const { newPassword } = this.state;

    return (
      <ModalWrapper
        isOpen={isOpenModal}
        showCloseFooter={false}
        // handleClose={handleClose}
        width="50%"
      >
        <div>
          <div className="mb-4 text-lg font-bold border-b pb-2 px-6 -mx-6">
            Set new password:
          </div>
          <div>
            <form
              autoComplete="false"
              onSubmit={(e) => {
                e.preventDefault();
                return this.handleSubmit();
              }}
            >
              <div>
                <div className="my-2">
                  <label
                    className="block text-gray-800 font-semibold mb-1 pr-4 leading-none"
                    htmlFor="user-email"
                  >
                    Email{" "}
                    <span className="text-gray-700 text-sm font-normal">
                      (Use the email that is used to sign in to Observer)
                    </span>
                  </label>
                  <div className="w-2/3">
                    <input
                      className={inputClassDisabled}
                      id="user-email"
                      type="email"
                      defaultValue={user.username}
                      disabled={true}
                    ></input>
                  </div>
                </div>

                <div className="my-2">
                  <label
                    className="block text-gray-800 font-semibold mb-1 pr-4 leading-none"
                    htmlFor="i2w-vault-user-new-password"
                  >
                    New Password
                  </label>
                  <div className="w-2/3">
                    <PasswordEyeInput
                      id="i2w-vault-user-new-password"
                      value={newPassword}
                      onChange={this.handleNewPWChanged}
                      required={true}
                    ></PasswordEyeInput>
                  </div>
                </div>
              </div>

              <div className="flex justify-end items-center mt-8">
                <div className="text-red-700">{saveErrorMsg}</div>
                <button
                  className="flex-shrink-0 border-transparent border-4 text-blue-500 hover:text-blue-800 text-sm py-1 px-2 rounded"
                  type="button"
                  onClick={this.handleCancel}
                >
                  Cancel
                </button>

                <button
                  className="flex-shrink-0 bg-blue-500 hover:bg-blue-800 border-blue-500 hover:border-blue-700 text-sm border-4 text-white py-1 px-2 rounded font-semibold"
                  type="submit"
                  disabled={isSaving}
                >
                  {isSaving ? "Saving..." : "Change password"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </ModalWrapper>
    );
  }
}

export default VaultUserNewPasswordModal;
