import React from "react";
import _ from "lodash";
import Select from "react-select";

import HighchartWrapper from "components/common/HighchartWrapper";

class DxDyTrendsViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,

      bucketsMap: null,
      bucketOptions: null,

      selectedBucket: null,
      filteredBuckets: [],

      chartOptions: null,
    };

    this.onBucketSelect = this.onBucketSelect.bind(this);
    this.drawCharts = this.drawCharts.bind(this);
    this.jsonFileUpload = this.jsonFileUpload.bind(this);
  }

  componentDidMount() {
    document.title = `Dx Dy Trends | YB Tools`;
  }

  onDataUploaded(data) {
    let buckets = _.map(data, (d) => {
      return {
        bucketId: d.bucket_id,
        os: d["OS Category"],
        sequence: d["Sequence"],
        priceHigh: d["Price high"],
        priceLow: d["Price low"],
        ...d,
      };
    });

    const bucketsMap = _.groupBy(buckets, "bucketId");
    const bucketOptions = _.map(buckets, (b) => {
      return {
        bucketId: b.bucketId,
        os: b.os,
        sequence: b.sequence,
        priceHigh: b.priceHigh,
        priceLow: b.priceLow,
      };
    });

    this.setState({
      buckets,
      bucketsMap,
      bucketOptions,
    });
  }

  onBucketSelect = (bucketId) => {
    const { bucketOptions, bucketsMap } = this.state;
    const selectedBucket = _.find(bucketOptions, { bucketId: bucketId });

    this.setState({ selectedBucket });

    setTimeout(() => {
      this.drawCharts();
    }, 0);
  };

  drawCharts() {
    const { selectedBucket, bucketsMap } = this.state;
    const r = bucketsMap[selectedBucket.bucketId];
    const metrics = [
      "floor",
      "prg_req",
      "h_lift",
      "h_rpm",
      "g_cs_str",
      "g_ecpm",
    ];
    const groups = ["dx", "dy", "o", "bm"];

    const chartOptions = _.map(metrics, (metric) => {
      const seriesData = _.map(groups, (group) => {
        const data = _.map(r, (d) => {
          return {
            x: new Date(d["Date"]).getTime(),
            y: d[`${group}_${metric}`],
          };
        });
        return {
          name: group,
          data,
        };
      });

      const options = {
        title: {
          text: metric,
        },
        // time: {
        //   timezoneOffset: timezoneOffset,
        // },
        chart: {
          type: "spline",
          height: 260,
          backgroundColor: "rgba(0,0,0,0)",
          zoomType: "x",
        },
        tooltip: {
          shared: true,
          backgroundColor: "rgba(255,255,255,1)", // use full white to make looking at all those numbers easier to read
        },
        plotOptions: {
          // spline: {
          //   marker: {
          //     enabled: false,
          //   },
          // },
        },
        yAxis: [
          {
            title: {
              text: "",
            },
          },
        ],
        // xAxis: {
        //   ..._getXAxisConfigDateTime({ timezone }),
        // },
        series: seriesData,
      };

      return {
        title: metric,
        options,
      };
    });

    this.setState({ chartOptions });
  }

  jsonFileUpload(e) {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = (e) => {
      const { data } = JSON.parse(e.target.result);
      this.onDataUploaded(data);
    };
  }

  render() {
    const { errMsg, isLoading, selectedBucket, bucketOptions, chartOptions } =
      this.state;

    return (
      <>
        <div className="bg-white px-12">
          <h1 className="pt-8 pb-2 text-4xl font-extrabold text-gray-900">
            <div className="text-base font-bold leading-none text-gray-600">
              Tools
            </div>
            Dx Dy Trends
          </h1>
        </div>

        <div className="min-h-full bg-gray-200 px-12 py-8">
          <div className="mb-4">
            Upload JSON File:{" "}
            <input type="file" onChange={this.jsonFileUpload} />
          </div>

          <div>
            {bucketOptions && (
              <div>
                <div>Bucket:</div>
                <BucketSelector
                  buckets={bucketOptions}
                  handleBucketChanged={this.onBucketSelect}
                  selectedBucket={selectedBucket}
                ></BucketSelector>
              </div>
            )}
          </div>

          {selectedBucket && (
            <div>
              <div className="border rounded mb-2 bg-gray-100 p-4">
                <div>Bucket ID: {selectedBucket.bucketId}</div>
                <div>Sequence: {selectedBucket.sequence}</div>
                <div>OS: {selectedBucket.os}</div>
                <div>
                  Price High: {selectedBucket.priceHigh} Price Low:{" "}
                  {selectedBucket.priceLow}
                </div>
              </div>

              <div className="bg-white p-4">
                {chartOptions &&
                  chartOptions.map((cOption, i) => {
                    return (
                      <HighchartWrapper
                        key={i}
                        options={cOption.options}
                      ></HighchartWrapper>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

class BucketSelector extends React.Component {
  constructor(props) {
    super(props);

    const { buckets, selectedBucket } = props;
    const options = _.map(buckets, (c) => {
      const value = c.bucketId;
      const label = `${c.bucketId} - ${c.os} - Seq ${c.sequence} (Price: ${c.priceLow} - ${c.priceHigh})`;
      return { value, label };
    });

    const selectedValue = _.find(options, { value: selectedBucket });

    this.state = {
      options,
      selectedValue,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedBucket !== this.props.selectedBucket) {
      const selectedValue = _.find(this.state.options, {
        value: this.props.selectedBucket,
      });
      this.setState({
        selectedValue,
      });
    }
  }

  handleChange(option) {
    this.setState({
      selectedValue: option,
    });

    this.props.handleBucketChanged(option.value);
  }

  render() {
    const { options, selectedValue } = this.state;

    return (
      <>
        <Select
          className="z-50 w-1/2"
          defaultValue={selectedValue}
          value={selectedValue}
          onChange={this.handleChange}
          options={options}
          isSearchable={true}
          // styles={{
          //   control: () => ({
          //     paddingLeft: 3,
          //   }),
          // }}
        />
      </>
    );
  }
}

export default DxDyTrendsViewer;
