import React from "react";
import _ from "lodash";

import UprPriceSection from "./UprPriceSection";
import UprTargetingPopover from "../custom-upr/UprTargetingPopover";
import ClickToCopyWrapper from "components/common/ClickToCopyWrapper";
import OpenLinkInNewWindowWrapper from "components/common/OpenLinkInNewWindowWrapper";
import { formatMoneyWithCurrency } from "components/playground/home-dashboard-v1/ReportsHelper";
import { FaFileImport } from "react-icons/fa";
import Tooltip from "react-simple-tooltip";
import { AiOutlineImport } from "react-icons/ai";
import { FiShare } from "react-icons/fi";

const PRICE_TYPE = {
  0: "Floor",
  1: "Target CPM",
  2: "LGO", // let google optimize
  "-1": "Use Brand Setting",
};

const buttonActionClass =
  "px-2 py-1 bg-gray-200 text-xs rounded border border-gray-400 shadow hover:bg-gray-300 text-gray-900 font-semibold";

const buttonClass =
  "px-2 py-1 bg-gray-100 text-xs rounded border border-gray-400 hover:bg-gray-200 text-gray-900 font-semibold";

function GamUprTable({
  items,
  units,
  virtualPlacements,
  networkId,
  networkInfo,
  currency,
  isI2wRuleSelected,
}) {
  const networkCode = networkInfo.code;

  return (
    <table className="table w-full text-sm">
      <thead className="border-b bg-gray-200 text-xs text-blue-800">
        <tr>
          <th className="border py-1 px-2 text-center ">External ID</th>
          <th className="border py-1 px-2 text-left ">Name</th>
          <th className="border py-1 px-2 text-center ">Status</th>
          <th className="border py-1 px-2 text-right ">Price Section</th>
          <th className="border py-1 px-2 text-left">Targeting Section</th>
          {/* {isI2wRuleSelected && ( */}
          <th className="border py-1 px-2 text-right">Type</th>
          {/* )} */}
          {isI2wRuleSelected && (
            <th className="border py-1 px-2 text-right">Props Section</th>
          )}
          <th className="border py-1 px-2 text-center">Actions</th>
        </tr>
      </thead>
      <tbody className="bg-white  text-gray-900">
        {items.map((item, i) => {
          const gamLink = `https://admanager.google.com/${networkCode}#inventory/pricing/detail/pricing_id=${item.externalId}&tab=settings`;

          function propsElement(item) {
            let element = "";
            if (!item.props) {
              return "-";
            }

            switch (item.props.type) {
              case "KEY16": {
                element = (
                  <div>
                    <div>UPR ID: {item.props.uprId}</div>
                    <PriceSectionView
                      item={{
                        price: item.props.price,
                        priceType: item.props.priceType,
                      }}
                    ></PriceSectionView>
                  </div>
                );
                break;
              }
              case "KEY16L1": {
                element = (
                  <div>
                    <PriceSectionView
                      item={{
                        price: item.props.price,
                        priceType: item.props.priceType,
                      }}
                    ></PriceSectionView>
                  </div>
                );
                break;
              }

              case "CUSTOM": {
                const priceSettings = _.get(item, [
                  "props",
                  "priceSection",
                  "priceSettings",
                ]);
                element = (
                  <div>
                    <div>ID: {item.props.id}</div>
                    <div className="font-semibold">
                      Price Settings ({priceSettings.length}):
                    </div>
                    <div className="flex-row gap-2 bg-gray-200">
                      {priceSettings &&
                        priceSettings.map((s, i) => {
                          return (
                            <PriceSettingView s={s} key={i}></PriceSettingView>
                          );
                        })}
                    </div>
                  </div>
                );
                break;
              }

              case "UPR_SEGMENTATION": {
                const priceSettings = _.get(item, ["props", "priceSettings"]);
                element = (
                  <div>
                    <div>Segment ID: {item.props.segmentId}</div>
                    <div>Price Setting ID: {item.props.priceSettingId}</div>
                    <div>Price Index: {item.props.priceIndex}</div>
                    <div>
                      OPT Type:{" "}
                      <span className="rounded bg-teal-100 px-1 font-semibold text-teal-900">
                        {item.props.optType}
                      </span>
                    </div>

                    <div className="border-t">
                      <div className="font-semibold">
                        Price Settings ({priceSettings.length}):
                      </div>
                      <div className="flex-row gap-2 bg-gray-200">
                        {priceSettings &&
                          priceSettings.map((s, i) => {
                            return (
                              <PriceSettingView
                                s={s}
                                key={i}
                              ></PriceSettingView>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                );
                break;
              }

              case "PREBID": {
                const priceSettings = _.get(item, ["props", "priceSettings"]);
                element = (
                  <div>
                    <div className="font-semibold">
                      Price Settings ({priceSettings.length}):
                    </div>
                    <div className="flex-row gap-2 bg-gray-200">
                      {priceSettings &&
                        priceSettings.map((s, i) => {
                          return (
                            <PriceSettingView s={s} key={i}></PriceSettingView>
                          );
                        })}
                    </div>
                  </div>
                );
                break;
              }

              default: {
                //
              }
            }

            return element;
          }

          return (
            <tr key={i} className="border-b hover:bg-gray-100">
              <td className="border py-1 px-2 text-right">
                <div className="flex items-center justify-between">
                  <span className="font-mono">{item.externalId}</span>

                  <div className="flex items-center text-blue-500">
                    <OpenLinkInNewWindowWrapper
                      href={gamLink}
                      tooltipText="Open in GAM"
                    ></OpenLinkInNewWindowWrapper>
                    <ClickToCopyWrapper
                      copyText={gamLink}
                      tooltipMessage="Click to copy GAM link"
                    ></ClickToCopyWrapper>
                  </div>
                </div>
              </td>
              <td className="border py-1 px-2 text-left">{item.uprName}</td>
              <td className="border py-1 px-2 text-center">{item.status}</td>
              <td className="border py-1 px-2 text-right">
                <UprPriceSection
                  item={item.priceSection}
                  currency={item.priceSection.currency}
                  networkId={networkId}
                ></UprPriceSection>
              </td>
              <td className="border py-1 px-2 text-left font-mono">
                <div>
                  {item.targetingSection && (
                    <UprTargetingPopover
                      networkId={networkId}
                      units={units}
                      virtualPlacements={virtualPlacements}
                      targetingSection={item.targetingSection}
                      triggerElement={
                        <div className="inline-block cursor-pointer  text-blue-600 hover:text-blue-700 hover:underline">
                          {Object.keys(item.targetingSection).join(", ")}
                        </div>
                      }
                    ></UprTargetingPopover>
                  )}
                </div>
              </td>
              {/* {isI2wRuleSelected && ( */}
              <td className="border py-1 px-2 text-right">
                {item.props ? item.props.type : "-"}
              </td>
              {/* )} */}
              {isI2wRuleSelected && (
                <td className="border py-1 px-2 text-right">
                  {propsElement(item)}
                </td>
              )}
              <td className="border py-1 px-2 text-center text-base font-semibold text-blue-500">
                <Tooltip
                  placement="top"
                  background="rgba(0, 0, 0, 0.9)"
                  content={
                    <div className="whitespace-no-wrap text-center text-xs">
                      Import to Custom UPR
                    </div>
                  }
                  padding={8}
                  radius={4}
                >
                  <button
                    type="button"
                    className="flex items-center px-2 hover:text-blue-700"
                    onClick={() => {
                      window.open(
                        `${window.location.origin}/#/ops-mgmt/account/network/${networkId}/upr-setting?importId=${item.externalId}`
                      );
                    }}
                  >
                    <FiShare></FiShare>
                  </button>
                </Tooltip>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

function PriceSettingView({ s }) {
  return (
    <div>
      <PriceSectionView
        item={{ price: s.price, priceType: s.priceType }}
        currency={s.currency}
      ></PriceSectionView>
      <div className="text-xs">
        {/* {s.isSetForEverything && (
          <div className="rounded bg-orange-400 px-2 text-orange-900">ISFE</div>
        )} */}
        Is Set For Everything: {s.isSetForEverything ? "true" : "false"}
      </div>
    </div>
  );
}

function PriceSectionView({ item, currency = "USD" }) {
  const { priceType, price } = item;
  return (
    <div className="flex items-end justify-end gap-1">
      <div className="text-xs font-medium text-gray-600">
        {PRICE_TYPE[priceType]}
      </div>
      <div>
        {formatMoneyWithCurrency({
          currency,
          value: price,
        })}
      </div>
    </div>
  );
}

export default GamUprTable;
