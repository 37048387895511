import React from "react";
import _ from "lodash";

import ModalWrapper from "../../../common/ModalWrapper";
import { NetworkAPI } from "apis";
import { FiDownload } from "react-icons/fi";

class BuildDetailsModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      input: null,
      downloadUrl: null,
    };

    this.handleDownload = this.handleDownload.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      const { networkId, buildId } = this.props;
      const { input, downloadUrl } = await NetworkAPI.getBuildDetails({
        networkId,
        buildId,
      });

      this.setState({
        input,
        downloadUrl,
      });
    } catch (err) {
      console.log(err);
    }
    this.setState({ isLoading: false });
  }

  handleDownload() {
    if (this.state.downloadUrl) {
      window.open(this.state.downloadUrl);
    }
  }

  render() {
    const { isLoading, input } = this.state;
    const { isOpenViewModal, handleClose, buildId, build } = this.props;

    return (
      <ModalWrapper
        isOpen={isOpenViewModal}
        showCloseFooter={true}
        handleClose={handleClose}
      >
        <>
          <div className="mb-4 text-lg font-bold border-b pb-2 px-6 -mx-6">
            {buildId}{" "}
            <span className="font-normal">
              ({build.packageName} version {build.packageVersion})
            </span>
          </div>

          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <>
              <div>
                <div className="flex justify-center items-center">
                  <button
                    type="button"
                    className="flex items-center gap-1 px-10 py-2 my-2 shadow bg-blue-200 text-blue-900 font-semibold rounded hover:bg-blue-300"
                    onClick={this.handleDownload}
                  >
                    <FiDownload></FiDownload>
                    Download build
                  </button>
                </div>

                <div className="text-lg font-semibold">Build Input:</div>
                <div className="overflow-y-auto" style={{ height: "360px" }}>
                  <textarea
                    className="bg-gray-300 text-sm w-full"
                    rows={20}
                    defaultValue={JSON.stringify(input, null, 4)}
                    readOnly
                  ></textarea>
                </div>
              </div>
            </>
          )}
        </>
      </ModalWrapper>
    );
  }
}

export default BuildDetailsModal;
