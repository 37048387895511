import React from "react";
import _ from "lodash";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import NumberFormat from "../../common/NumberFormat";
import {
  CHECK_STATUS,
  EVAL_KEYS,
  KEY_FORMAT,
} from "../../../constants/HealthCheck";

class OverviewTable extends React.PureComponent {
  constructor(props) {
    super(props);

    const overviewEvalKey = props.overviewEvalKey; // ex. raw_revenue_ratio
    const sortKey = "NAME"; // sort by check name

    const overviewReports = this._transformOverviewReports(
      this.props.reports,
      sortKey,
      overviewEvalKey
    );

    this.state = {
      sortKey,
      overviewReports,
      overviewEvalKey,
    };

    this.handleSortKeyChanged = this.handleSortKeyChanged.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.overviewEvalKey !== this.props.overviewEvalKey) {
      const { sortKey } = this.state;
      const overviewReports = this._transformOverviewReports(
        this.props.reports,
        sortKey,
        this.props.overviewEvalKey
      );

      this.setState({
        overviewReports,
        overviewEvalKey: this.props.overviewEvalKey,
      });
    }

    if (
      prevProps.ysFilter === this.props.ysFilter &&
      prevProps.inspFilter === this.props.inspFilter &&
      prevProps.reqTypeFilter === this.props.reqTypeFilter &&
      prevProps.onboardTimeFilter === this.props.onboardTimeFilter &&
      prevProps.susModeFilter === this.props.susModeFilter &&
      prevProps.debugModeFilter === this.props.debugModeFilter
    ) {
      return;
    }
    const { sortKey, overviewEvalKey } = this.state;
    const overviewReports = this._transformOverviewReports(
      this.props.reports,
      sortKey,
      overviewEvalKey
    );

    this.setState({ overviewReports });
  }

  _transformOverviewReports(reports, sortKey, overviewEvalKey) {
    let checksMap = {};
    let overviewReports = [];
    let secondaryEvalKey = EVAL_KEYS[overviewEvalKey].sec;
    _.forEach(reports, (report) => {
      _.forEach(report.check_results, (check) => {
        let c = _.get(checksMap, [check.name, check.result]);
        if (!c) {
          _.set(checksMap, [check.name, check.result, "count"], 0);
          _.set(checksMap, [check.name, check.result, overviewEvalKey], 0);
          // if has secondary metric
          if (secondaryEvalKey) {
            _.set(checksMap, [check.name, check.result, secondaryEvalKey], 0);
          }
        }
        checksMap[check.name][check.result].count++;
        checksMap[check.name][check.result][overviewEvalKey] += _.get(
          report,
          ["stat", overviewEvalKey],
          0
        );
        if (secondaryEvalKey) {
          checksMap[check.name][check.result][secondaryEvalKey] += _.get(
            report,
            ["stat", secondaryEvalKey],
            0
          );
        }
      });
    });

    _.forIn(checksMap, (c, checkName) => {
      let r = { name: checkName };
      let maxEvalKey = 0;
      _.forEach(CHECK_STATUS, (cs) => {
        const count = _.get(c[cs], "count");
        let statValue = _.get(c[cs], overviewEvalKey, 0);
        let secValue = _.get(c[cs], secondaryEvalKey, 0);

        r[cs] = {
          count,
          statValue,
          secValue,
        };
        if (statValue > maxEvalKey) {
          maxEvalKey = statValue;
        }
      });

      _.forEach(CHECK_STATUS, (cs) => {
        if (r[cs].statValue === maxEvalKey) {
          r[cs].isMax = true;
          return false; // exit early
        }
      });
      overviewReports.push(r);
    });
    // console.log(checksMap);
    // console.log(overviewReports);
    overviewReports = this.sortReports(overviewReports, sortKey);

    return overviewReports;
  }

  handleSortKeyChanged(key) {
    const sortedReports = this.sortReports(this.state.overviewReports, key);
    this.setState({ sortKey: key, overviewReports: sortedReports });
  }

  sortReports(reports, sortKey) {
    const sortedReports = _.orderBy(
      reports,
      _orderFn,
      sortKey === "NAME" ? "asc" : "desc"
    );
    function _orderFn(r) {
      if (sortKey === "NAME") {
        return r.name;
      }
      return r[sortKey].statValue || -99999;
    }
    return sortedReports;
  }

  render() {
    const { sortKey, overviewReports, overviewEvalKey } = this.state;
    let {
      reports,
      filterUnitsReports,
      checkNameFilter,
      checkStatusFilter,
      handleOverviewEvalKeyChanged,
    } = this.props;

    return (
      <div>
        <div className="py-1 text-center">
          Showing{" "}
          <span className="font-semibold bg-gray-300 px-2">
            {overviewEvalKey}
          </span>
          {EVAL_KEYS[overviewEvalKey].sec && (
            <span>
              <span>, </span>
              <span className="font-semibold bg-gray-300 px-2">
                {`${EVAL_KEYS[overviewEvalKey].sec}`}
              </span>
            </span>
          )}
          <span className="ml-4">
            (Data is in <span className="font-semibold">TWD</span>)
          </span>
          <button
            type="button"
            onClick={handleOverviewEvalKeyChanged}
            className="px-2 bg-gray-300 rounded shadow"
          >
            change
          </button>
        </div>
        <table className="w-full table text-sm border shadow">
          <thead className="bg-gray-200 text-blue-800 text-xs border-b">
            <tr>
              <th className="border text-right py-1 px-2 w-1/6">
                <div
                  className={`${
                    sortKey === "NAME" ? "text-gray-800" : "text-gray-200"
                  }
                cursor-pointer flex gap-1 justify-end items-center hover:text-gray-600
                `}
                  onClick={() => this.handleSortKeyChanged("NAME")}
                >
                  <FiArrowUp></FiArrowUp>
                  <span className="text-blue-800">
                    Checks ({overviewReports.length})
                  </span>
                </div>
              </th>
              {CHECK_STATUS.map((cs) => {
                return (
                  <th key={cs} className="border text-right py-1 px-2 w-1/6">
                    <div
                      className={`${
                        sortKey === cs ? "text-gray-800" : "text-gray-200"
                      }
                    cursor-pointer flex gap-1 justify-end items-center hover:text-gray-600
                    `}
                      onClick={() => this.handleSortKeyChanged(cs)}
                    >
                      <FiArrowDown></FiArrowDown>
                      <span className="text-blue-800">{cs}</span>
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="text-gray-900 font-mono bg-white">
            {overviewReports.map((r) => {
              return (
                <tr
                  key={r.name}
                  className={`
                  ${checkNameFilter === r.name ? "bg-gray-200" : ""}
                  border-b hover:bg-gray-100`}
                >
                  <td className="text-right py-1 px-2 whitespace-no-wrap border">
                    <span
                      className={checkNameFilter === r.name ? "font-bold" : ""}
                    >
                      {r.name}
                    </span>
                  </td>

                  {CHECK_STATUS.map((cs) => {
                    const { count, isMax, statValue, secValue } = r[cs];
                    return (
                      <td
                        key={cs}
                        className={`
                        ${
                          checkNameFilter === r.name && checkStatusFilter === cs
                            ? "bg-gray-400"
                            : ""
                        }
                        text-right py-1 px-2 border hover:bg-gray-300 cursor-pointer
                      `}
                        onClick={() => filterUnitsReports(r.name, cs)}
                      >
                        {count && (
                          <div
                            className={`${
                              checkNameFilter === r.name &&
                              checkStatusFilter === cs
                                ? "font-bold"
                                : ""
                            } flex flex-row-reverse gap-2 justify-start items-center`}
                          >
                            <div className={`${isMax && "bg-teal-200"} px-1`}>
                              {/* {overviewEvalKey}:{" "} */}
                              <NumberFormat
                                value={statValue}
                                format={KEY_FORMAT[overviewEvalKey]}
                              ></NumberFormat>
                            </div>
                            {
                              <div className="text-xs text-gray-600">
                                {/* {EVAL_KEYS[overviewEvalKey].sec}:{" "} */}
                                <NumberFormat
                                  value={secValue}
                                  format={
                                    KEY_FORMAT[EVAL_KEYS[overviewEvalKey].sec]
                                  }
                                ></NumberFormat>
                              </div>
                            }
                          </div>
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default OverviewTable;
