import React from "react";
import _ from "lodash";

class NewAdSizeControl extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      width: "",
      height: "",

      hasError: false,
      errorMsg: "",
    };

    this.handleInputChanged = this.handleInputChanged.bind(this);
    this.handleAddClicked = this.handleAddClicked.bind(this);
  }

  handleInputChanged({ event, key }) {
    if (key === "width") {
      this.setState({
        width: event.target.value,
      });

      return;
    }
    if (key === "height") {
      this.setState({
        height: event.target.value,
      });

      return;
    }
  }

  handleAddClicked() {
    // validation
    const { width, height } = this.state;
    if (!width || !height) {
      this.setState({
        hasError: true,
        errorMsg: "width and height are required",
      });
      return;
    }

    const intWidth = _.parseInt(width);
    const intHeight = _.parseInt(height);

    if (intWidth <= 0 || intHeight <= 0) {
      this.setState({
        hasError: true,
        errorMsg: "width and height cannot be smaller or equal to 0",
      });
      return;
    }

    // check duplicate
    const { adSizes } = this.props;
    let hasError = false;
    _.forEach(adSizes, (size) => {
      const w = size[0];
      const h = size[1];

      if (intWidth === w && intHeight === h) {
        hasError = true;
        return false;
      }
    });
    if (hasError) {
      this.setState({
        hasError: true,
        errorMsg: "This ad size already exists",
      });
      return;
    }

    const adSize = [intWidth, intHeight];
    this.props.handleAddNewAdSize(adSize);

    this.setState({
      width: "",
      height: "",
      hasError: false,
      errorMsg: "",
    });
  }

  render() {
    const { width, height, errorMsg } = this.state;
    const basicInputClass =
      "bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded py-1 px-2 block appearance-none leading-normal";

    return (
      <div className="bg-white rounded shadow">
        <div className="border-b px-2 pt-2">
          New Ad Size:{" "}
          {(width || height) && (
            <span className="text-gray-600">
              {width}x{height}
            </span>
          )}
        </div>
        <div className="flex justify-between p-2">
          <div className="flex items-center">
            <div className="flex items-center">
              <label
                className="font-semibold text-gray-600 mr-1"
                htmlFor="new-size-width"
              >
                Width
              </label>
              <input
                id="new-size-width"
                className={`${
                  // hasError ? errorInputClass : basicInputClass
                  basicInputClass
                } w-20`}
                type="number"
                step="1"
                min={0}
                value={width}
                onChange={(e) =>
                  this.handleInputChanged({ event: e, key: "width" })
                }
              ></input>
            </div>
            <div className="px-2 text-gray-800">x</div>
            <div className="flex items-center">
              <label
                className="font-semibold text-gray-600 mr-1"
                htmlFor="new-size-height"
              >
                Height
              </label>
              <input
                id="new-size-height"
                className={`${
                  // hasError ? errorInputClass : basicInputClass
                  basicInputClass
                } w-20`}
                type="number"
                step="1"
                min={0}
                value={height}
                onChange={(e) =>
                  this.handleInputChanged({ event: e, key: "height" })
                }
              ></input>
            </div>
          </div>

          <button
            type="button"
            className={`text-blue-700 bg-blue-100 font-semibold rounded px-2`}
            onClick={this.handleAddClicked}
          >
            Add
          </button>
        </div>
        <div className="text-red-600 px-2">{errorMsg}</div>
      </div>
    );
  }
}

export default NewAdSizeControl;
