import React from "react";
import _ from "lodash";
import { FiExternalLink } from "react-icons/fi";
import LoadingUI from "../common/LoadingUI";
import DateTimeFormatter from "../common/DateTimeFormatter";
import NetworkStatusIndicator from "../common/NetworkStatusIndicator";
import ReactTooltip from "react-tooltip";
import VaultAccountViewModal from "./VaultAccountViewModal";
import ClickToCopyWrapper from "../common/ClickToCopyWrapper";

const buttonActionClass =
  "px-2 py-2 mr-1 my-1 bg-blue-400 text-xs rounded shadow hover:bg-blue-600 text-white font-semibold";

const buttonActionRedClass =
  "px-2 py-2 mr-1 my-1 bg-red-400 text-xs rounded shadow hover:bg-red-600 text-white font-semibold";

const thClass =
  "px-4 py-2 text-gray-600 text-left text-xs tracking-wider uppercase";
const tdClass = "px-4 py-2 border";

// ex. https://admanager.google.com/57931037
const GAM_CONSOLE_URL = "https://admanager.google.com";

class VaultAccountsTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isAccountModalOpen: false,
      currentAccount: null,
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleGoToGAMConsole = this.handleGoToGAMConsole.bind(this);
  }

  async handleOpenModal(account) {
    const newAccount = await this.props.getVaultAccountById(account.id);
    this.setState({
      isAccountModalOpen: true,
      currentAccount: newAccount,
    });
  }

  handleCloseModal() {
    this.setState({
      isAccountModalOpen: false,
      currentAccount: null,
    });
  }

  handleGoToGAMConsole(networkCode) {
    window.open(`${GAM_CONSOLE_URL}/${networkCode}`);
  }

  handleOpenPasswordModal(account) {
    this.setState({
      isPWModalOpen: true,
      currentAccount: account,
    });
  }

  render() {
    const {
      items,
      isLoading,
      errMsg,
      handleOpenEditModal,
      handleDeleteAccount,
      handleOpenBindModal,
      handleOpenPasswordModal,
      handleOpenStatusModal,
      isLoadingModal,
    } = this.props;
    const { isAccountModalOpen, currentAccount } = this.state;

    if (isLoading) {
      return <LoadingUI></LoadingUI>;
    }

    if (errMsg) {
      return <div className="text-red-700">{errMsg}</div>;
    }

    if (!items) {
      return "no items";
    }

    return (
      <div>
        <table className="min-w-full shadow divide-y">
          <thead className="bg-gray-100">
            <tr>
              <th className={thClass}>id</th>
              <th
                className={thClass}
                // style={{ width: "40%" }}
              >
                email
              </th>
              <th className={thClass}>networks</th>
              <th className={thClass + " text-right"}>Created at</th>
              <th className={thClass + " text-right"}>Updated at</th>
              <th className={thClass + " text-right"}>Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y text-sm">
            {items.map((item) => {
              return (
                <tr key={item.email} className="hover:bg-gray-100">
                  <td className={tdClass}>{item.id}</td>
                  <td className={tdClass}>
                    <div className="leading-none">
                      <span
                        className="font-medium cursor-pointer text-blue-600 hover:underline"
                        onClick={() => this.handleOpenModal(item)}
                      >
                        {item.email}
                      </span>
                    </div>
                    <div>
                      <span
                        className={`px-2 rounded text-xs ${
                          item.status === 1
                            ? "text-green-800 bg-green-200"
                            : "text-red-800 bg-red-200"
                        }`}
                      >
                        {item.status === 1 ? "Active" : "Disabled"}
                      </span>
                    </div>
                  </td>

                  <td className={tdClass}>
                    {item.networks.map((network, i) => {
                      const gamLink = `${GAM_CONSOLE_URL}/${network.code}`;

                      if (!network.networkId) {
                        return (
                          <div key={i} className="text-gray-700">
                            N/A code: {network.code}
                          </div>
                        );
                      }

                      return (
                        <div
                          key={i}
                          className="flex items-end justify-between text-sm "
                        >
                          <div className="flex items-center">
                            <div className="flex items-center mr-1">
                              <NetworkStatusIndicator
                                status={network.status}
                              ></NetworkStatusIndicator>
                              <span className="ml-1">{network.networkId}</span>
                            </div>

                            <div className="font-medium">{network.name}</div>

                            {network.networkId && (
                              <div className="flex items-center">
                                <div className="ml-2 text-blue-700 text-xs">
                                  <ClickToCopyWrapper
                                    copyText={gamLink}
                                    tooltipMessage="Copy GAM console link"
                                  ></ClickToCopyWrapper>
                                </div>

                                <div>
                                  <button
                                    type="button"
                                    className="rounded px-1 hover:shadow text-xs flex items-center text-blue-700 bg-gray-100"
                                    onClick={() =>
                                      this.handleGoToGAMConsole(network.code)
                                    }
                                    data-tip
                                    data-for={`${network.code}-external-link`}
                                  >
                                    <FiExternalLink></FiExternalLink>
                                  </button>
                                  <ReactTooltip
                                    id={`${network.code}-external-link`}
                                    type="dark"
                                    effect="solid"
                                  >
                                    Open this network's GAM console
                                  </ReactTooltip>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </td>
                  <td className={tdClass + " text-right text-xs text-gray-800"}>
                    <DateTimeFormatter
                      datetime={item.created_at}
                      isLineBreak={true}
                    ></DateTimeFormatter>
                  </td>
                  <td className={tdClass + " text-right text-xs text-gray-800"}>
                    <DateTimeFormatter
                      datetime={item.updated_at}
                      isLineBreak={true}
                    ></DateTimeFormatter>
                  </td>

                  <td className={tdClass + " text-right"}>
                    <button
                      type="button"
                      className={buttonActionClass}
                      onClick={() => handleOpenPasswordModal(item)}
                      data-tip
                      data-for={`${item.email}-pw-button`}
                    >
                      PW
                    </button>

                    <ReactTooltip
                      id={`${item.email}-pw-button`}
                      type="dark"
                      effect="solid"
                    >
                      Change password
                    </ReactTooltip>

                    <button
                      type="button"
                      className={buttonActionClass}
                      onClick={() => handleOpenEditModal(item)}
                      data-tip
                      data-for={`${item.email}-edit-button`}
                    >
                      Edit
                    </button>

                    <ReactTooltip
                      id={`${item.email}-edit-button`}
                      type="dark"
                      effect="solid"
                    >
                      Edit account
                    </ReactTooltip>

                    <button
                      type="button"
                      className={buttonActionClass}
                      onClick={() => handleOpenStatusModal(item)}
                      data-tip
                      data-for={`${item.email}-update-status-button`}
                    >
                      Status
                    </button>

                    <ReactTooltip
                      id={`${item.email}-update-status-button`}
                      type="dark"
                      effect="solid"
                    >
                      Update account status
                    </ReactTooltip>

                    <button
                      type="button"
                      className={buttonActionClass}
                      onClick={() => handleOpenBindModal(item)}
                      data-tip
                      data-for={`${item.email}-bind-button`}
                    >
                      Bind
                    </button>
                    <ReactTooltip
                      id={`${item.email}-bind-button`}
                      type="dark"
                      effect="solid"
                    >
                      Bind network to account
                    </ReactTooltip>

                    <button
                      type="button"
                      className={buttonActionRedClass}
                      onClick={() => handleDeleteAccount(item)}
                      data-tip
                      data-for={`${item.email}-delete-button`}
                    >
                      Delete
                    </button>
                    <ReactTooltip
                      id={`${item.email}-delete-button`}
                      type="dark"
                      effect="solid"
                    >
                      Delete account
                    </ReactTooltip>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {isAccountModalOpen && (
          <VaultAccountViewModal
            account={currentAccount}
            isOpenModal={isAccountModalOpen}
            handleClose={this.handleCloseModal}
            isLoadingModal={isLoadingModal}
          ></VaultAccountViewModal>
        )}
      </div>
    );
  }
}

export default VaultAccountsTable;
