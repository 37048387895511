import React from "react";
import _ from "lodash";
import Select from "react-select";
// import { formatMoneyWithCurrency } from "./ReportsHelper";
// import NumberFormat from "../../common/NumberFormat";
import RevenueDistributionDrilldownPieChart from "./RevenueDistributionDrilldownPieChart";

const DISTRIBUTION_TYPE = {
  GARD: "GOOGLE_AD_REVENUE_DISTRIBTUTION",
  NIRDL: "NET_INCREASED_REVENUE_DISTRIBUTION",
};

class RevenueDistributionSection extends React.Component {
  constructor(props) {
    super(props);

    const options = [
      {
        value: DISTRIBUTION_TYPE.GARD,
        label: "Ad Revenue Distribution",
      },
      {
        value: DISTRIBUTION_TYPE.NIRDL,
        label: "Net Increased Revenue Distribution",
      },
    ];
    const selectedValue = options[0];

    this.state = {
      options,
      selectedValue,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  // componentDidUpdate(prevProps) {
  //   // console.log(prevProps, this.props);
  //   if (
  //     prevProps.startDate !== this.props.startDate ||
  //     prevProps.endDate !== this.props.endDate ||
  //     prevProps.selectedNetworkId !== this.props.selectedNetworkId ||
  //     prevProps.selectedYieldSetId !== this.props.selectedYieldSetId ||
  //     prevProps.selectedCurrency !== this.props.selectedCurrency
  //   ) {
  //     console.log("should update distribution charts");
  //     // this.setState({
  //     //   // options: _createChartOptions(this.props.reports),
  //     // });
  //   }
  // }

  handleChange(option) {
    this.setState({
      selectedValue: option,
    });
  }

  render() {
    const { options, selectedValue } = this.state;
    const {
      distributionData,
      selectedPubId,
      selectedNetworkId,
      selectedYieldSetIds,
      startDate,
      endDate,
      selectedCurrency,
    } = this.props;

    return (
      <div className="p-4">
        {/* <div className="font-semibold text-lg text-gray-800 mb-4 text-center">
          Revenue Distribution
        </div> */}

        <Select
          // className="w-24"
          defaultValue={selectedValue}
          value={selectedValue}
          onChange={this.handleChange}
          options={options}
          isSearchable={false}
        />

        <div>
          {selectedValue.value === DISTRIBUTION_TYPE.GARD && (
            <RevenueDistributionDrilldownPieChart
              reports={distributionData}
              metricKey="googleAdRevenue"
              selectedPubId={selectedPubId}
              selectedNetworkId={selectedNetworkId}
              selectedYieldSetIds={selectedYieldSetIds}
              selectedCurrency={selectedCurrency}
              startDate={startDate}
              endDate={endDate}
            ></RevenueDistributionDrilldownPieChart>
          )}

          {selectedValue.value === DISTRIBUTION_TYPE.NIRDL && (
            <RevenueDistributionDrilldownPieChart
              reports={distributionData}
              metricKey="netIncreasedRevenue"
              selectedPubId={selectedPubId}
              selectedNetworkId={selectedNetworkId}
              selectedYieldSetIds={selectedYieldSetIds}
              selectedCurrency={selectedCurrency}
              startDate={startDate}
              endDate={endDate}
            ></RevenueDistributionDrilldownPieChart>
          )}
        </div>
      </div>
    );
  }
}

export default RevenueDistributionSection;
