import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import NumberFormat from "../../common/NumberFormat";
import { FiArrowDown } from "react-icons/fi";
import DateTimeFormatter from "../../common/DateTimeFormatter";
import PopupWrapper from "../../common/PopupWrapper";
import SegmentBasicTable from "../../common/SegmentBasicTable";

class SegmentsDetailPopover extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { triggerElement, segments } = this.props;
    return (
      <PopupWrapper
        place="bottom center"
        triggerType="hover"
        hideArrow={true}
        triggerElement={triggerElement}
        popupElement={
          <div style={{ maxWidth: "400px" }}>
            <SegmentBasicTable segments={segments}></SegmentBasicTable>
          </div>
        }
      ></PopupWrapper>
    );
  }
}

export default SegmentsDetailPopover;
