import React from "react";
import { Switch, Route, NavLink, Redirect } from "react-router-dom";
import _ from "lodash";
import SymptomsViewer from "./SymptomsViewer";
import SymptomReportViewer from "./SymptomReportViewer";
import { NetworkAPI } from "apis";
import NetworkPageWrapper from "./NetworkPageWrapper";

const PATH_PREFIX = "/diagnoser/network/:networkId";
const DIAGNOSER_PATHS = {
  BASE: PATH_PREFIX,
  SYMPTOMS: `${PATH_PREFIX}/symptoms`,
  SYMPTOM_REPORT: `${PATH_PREFIX}/symptom/:symptomName/:reportDate`,
};

class DiagnoserByNetworkViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,

      networkId: null,
      networkInfo: null,
    };
  }

  async componentDidMount() {
    const networkId = _.get(this.props, "match.params.networkId");
    this.setState({ networkId, isLoading: true });

    try {
      const networkInfo = await this.queryNetworkInfo(networkId);
      this.setState({ networkInfo, errMsg: null });
    } catch (err) {
      this.setState({
        errMsg: err,
        networkInfo: null,
      });
    }
    this.setState({ isLoading: false });
  }

  async queryNetworkInfo(networkId) {
    return await NetworkAPI.getNetworkInfo({ networkId });
  }

  render() {
    const { networkId, networkInfo, isLoading, errMsg } = this.state;

    const pathPrefix = `/diagnoser/network/${networkId}`;
    const paths = {
      SYMPTOMS: {
        path: `${pathPrefix}/symptoms`,
        title: "Symptoms",
      },
    };
    const basePath = paths.SYMPTOMS.path;

    return (
      <div>
        <div className="border-b bg-gray-100 py-2 px-12">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <div className="text-lg font-semibold text-gray-800">
                Diagnoser
                {/* <NavLink
                  to={vseRecipes}
                  className="text-gray-900 rounded px-2 py-1 hover:bg-gray-300"
                >
                  VSE
                </NavLink> */}
              </div>

              <div className="flex gap-4">
                {_.keys(paths).map((path) => {
                  return (
                    <NavLink
                      key={paths[path].path}
                      to={paths[path].path}
                      className="rounded border border-gray-100 px-2 py-1 text-sm font-semibold text-gray-900 hover:border-gray-300"
                      activeClassName="bg-gray-300 border border-gray-300"
                    >
                      {paths[path].title}
                    </NavLink>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <NetworkPageWrapper
          isLoading={isLoading}
          errMsg={errMsg}
          networkInfo={networkInfo}
        >
          {networkId ? (
            <Switch>
              <Route
                path={DIAGNOSER_PATHS.SYMPTOMS}
                render={(props) => {
                  return (
                    <SymptomsViewer
                      {...props}
                      networkId={networkId}
                    ></SymptomsViewer>
                  );
                }}
              ></Route>

              <Route
                path={DIAGNOSER_PATHS.SYMPTOM_REPORT}
                render={(props) => {
                  return (
                    <SymptomReportViewer
                      {...props}
                      networkId={networkId}
                    ></SymptomReportViewer>
                  );
                }}
              ></Route>

              <Route
                exact
                path={DIAGNOSER_PATHS.BASE}
                render={() => <Redirect to={basePath}></Redirect>}
              ></Route>
            </Switch>
          ) : (
            <div className="bg-white text-red-700">Invalid networkId</div>
          )}
        </NetworkPageWrapper>
      </div>
    );
  }
}

export default DiagnoserByNetworkViewer;
