import React from "react";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import moment from "moment-timezone";
import { IoMdRefresh } from "react-icons/io";
import { FiExternalLink } from "react-icons/fi";
import ReactTooltip from "react-tooltip";
import queryString from "query-string";
import { notify } from "react-notify-toast";

import ObserverHeader from "./ObserverHeader";
import SegmentOverview from "./SegmentOverview";
import SegmentData from "./SegmentData";
import SegmentExperiments from "./SegmentExperiments";
import SegmentList from "./SegmentList";
import { getItem, setItem } from "../../helpers/LocalStorage";
import DateRangeSelector from "../common/DateRangeSelector";
import ClickToCopyWrapper from "../common/ClickToCopyWrapper";
import ObserverDemandTypeFilter from "./ObserverDemandTypeFilter";
import GoToInsightButton from "../common/GoToInsightButton";
import GoToAPConfigButton from "../common/GoToAPConfigButton";

const mode = {
  OLD: "OLD_TABLE",
  NEW: "NEW_TABLE",
};

const modeStorageKey = "OBSERVER_MODE";

class ObserverOverview extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();

    const preferenceMode = getItem(modeStorageKey);
    this.state = {
      mode: preferenceMode || mode.OLD,
      segmentId: null,

      // filter data once segment is selected
      segmentData: null,
    };

    this.handleSegmentSelect = this.handleSegmentSelect.bind(this);
    this.handleViewModeChange = this.handleViewModeChange.bind(this);
  }

  async componentDidMount() {
    const { data } = this.props;

    const value = queryString.parse(this.props.location.search);
    const segmentId = parseInt(value.segmentId);
    const expId = parseInt(value.expId);
    const expGroupId = parseInt(value.expGroupId);

    if (segmentId) {
      // notify.show("Deep linking...", "success");
      const isValid = _.findIndex(data.segmentInfo, { id: segmentId }) !== -1;
      if (isValid) {
        this.handleSegmentSelect(segmentId, true);
        // need segment to be selected to first to find exp element
      } else {
        notify.show(
          `Deep link: 'segmentId' ${segmentId} is not found`,
          "error"
        );
        return;
      }

      if (expId) {
        const isValid =
          data.expInfo[expId] && data.expInfo[expId].segmentId === segmentId;
        if (isValid) {
          notify.show(`Searching EXP-${expId}...`, "success");
          setTimeout(() => {
            // console.log(this.child);
            this.child.current.scrollToExp(expId, true);
          }, 2000);
          return;
        } else {
          notify.show(`Deep link: 'expId' ${expId} is not found`, "error");
        }
      } else if (expGroupId) {
        const group = data.groupInfo[expGroupId];
        const isValid =
          group && data.expInfo[group.expId].segmentId === segmentId;
        if (isValid) {
          notify.show(`Searching Exp Group ID ${expGroupId}...`, "success");
          setTimeout(() => {
            // console.log(this.child);
            this.child.current.scrollToExpGroup(expGroupId, true, true);
          }, 2000);
          return;
        } else {
          notify.show(
            `Deep link: 'expGroupId' ${expGroupId} is not found`,
            "error"
          );
        }
      }
      return;
    }

    // auto show reports since there is no segments
    if (_.get(data, "segmentInfo[0].id") === -1) {
      this.handleSegmentSelect(-1, false);
    } else if (_.get(data, "segmentInfo", []).length === 1) {
      this.handleSegmentSelect(data.segmentInfo[0].id, false);
    }
  }

  filterSegmentData(segmentId) {
    const originalData = this.props.data;

    const yieldLifts = _.filter(originalData.yieldLifts, { segmentId });
    const segmentInfo = _.find(originalData.segmentInfo, { id: segmentId });

    const expInfo = _(originalData.expInfo)
      .values()
      .filter({ segmentId })
      .mapKeys("id")
      .value();

    const segmentExpGroupIds = _.reduce(
      yieldLifts,
      (result, r) => {
        _.forEach(r.reports, (expReport) => {
          result.push(expReport.expGroupId);
        });
        return result;
      },
      []
    );

    const groupInfo = _.reduce(
      originalData.groupInfo,
      (result, group, expGroupId) => {
        if (_.indexOf(segmentExpGroupIds, _.parseInt(expGroupId)) !== -1) {
          result[expGroupId] = group;
        }
        return result;
      },
      {}
    );

    // Build segment family
    const expLink = _(originalData.expInfo)
      .groupBy(({ segmentId }) => `${segmentId || "unit"}`)
      .mapValues((exps) => {
        // Order by expId desc
        exps.sort((l, r) => r.id - l.id);

        return _.map(exps, ({ id }, index, c) => {
          const next = index === 0 ? undefined : c[index - 1].id;
          const prev = index === c.length - 1 ? undefined : c[index + 1].id;
          return { id, next, prev };
        });
      })
      .values()
      .flatten()
      .keyBy("id")
      .value();

    return {
      yieldLifts,
      segmentInfo,
      expInfo,
      groupInfo,
      expLink,
    };
  }

  handleSegmentSelect(segmentId, shouldScroll = true) {
    // clear data first!!
    this.setState({ segmentId: null });

    const segmentData = this.filterSegmentData(segmentId);

    this.setState({ segmentId, segmentData });

    if (shouldScroll) {
      setTimeout(() => {
        const element = document.getElementById("top-of-segment-experiences");
        if (element) {
          window.scrollTo({
            behavior: "smooth",
            top: element.offsetTop,
          });
        }
      }, 500);
    }
  }

  handleViewModeChange(mode) {
    if (this.state.mode === mode) {
      return;
    }

    var eleId = "old-table-mode";
    if (mode === mode.NEW) {
      eleId = "new-table-mode";
    }

    const element = document.getElementById(eleId);
    const originalWording = element.innerHTML;
    element.innerHTML = "Loading...";

    setItem(modeStorageKey, mode);

    setTimeout(() => {
      this.setState({ mode });
      element.innerHTML = originalWording;
    }, 500);
  }

  render() {
    const {
      data,
      demandTypePreset,
      demandTypes,
      billableDemandTypes,
      selectedDateRange,
      onDateRangeChange,
      handleForceRefresh,
      handleReqCalcChanged,
      useNewRequest,
    } = this.props;
    const { segmentData } = this.state;

    const { dateRange } = data;
    const numOfExperiments = _.keys(_.get(data, "expInfo"), {}).length;
    const numOfSegments = _.get(data, "segmentInfo", []).length;

    const currentUrl = window.location.href;
    const url = _.first(currentUrl.split("?"));
    const segmentDeepLinkUrl = `${url}?segmentId=${this.state.segmentId}`;

    return (
      <>
        <ObserverHeader data={data}></ObserverHeader>

        {/* Offset from top of the screen */}
        <div style={{ height: "20px" }}></div>

        <div className="px-4 mb-4 text-gray-200">
          <div className="my-2 flex items-center justify-between">
            <div className="flex items-center gap-4">
              {selectedDateRange && (
                <DateRangeSelector
                  selectedDateRange={selectedDateRange}
                  onDateRangeChange={onDateRangeChange}
                ></DateRangeSelector>
              )}

              <button
                type="button"
                className="px-2 py-1 bg-gray-400 text-gray-800 rounded font-medium flex items-center hover:bg-white"
                data-tip
                data-for="observer-force-update-tooltip"
                onClick={handleForceRefresh}
              >
                <IoMdRefresh></IoMdRefresh>
                <span className="text-sm pl-1">Force Update</span>
              </button>

              <ReactTooltip
                id="observer-force-update-tooltip"
                type="dark"
                effect="solid"
              >
                Click to update data immediately
              </ReactTooltip>

              <ReqCalcSelector
                useNewRequest={useNewRequest}
                handleReqCalcChanged={handleReqCalcChanged}
              ></ReqCalcSelector>
            </div>

            <div>
              {this.state.segmentId && (
                <ViewModeSelector
                  selectedMode={this.state.mode}
                  handleViewModeChange={this.handleViewModeChange}
                ></ViewModeSelector>
              )}
            </div>
          </div>

          <div>
            Timezone: {data.networkSetting.timezone}, Currency:{" "}
            {data.networkSetting.currency}
          </div>

          <div>
            Data ranging from{" "}
            <b>
              {moment(data.dateRange.startDate)
                .tz("Asia/Taipei")
                .format("YYYY-MM-DD HH:mm:ss")}{" "}
              GMT+8
            </b>{" "}
            to{" "}
            <b>
              {moment(data.dateRange.endDate)
                .tz("Asia/Taipei")
                .format("YYYY-MM-DD HH:mm:ss")}{" "}
              GMT+8
            </b>
            <span className="float-right">
              Updated at {data.reportCreateTime}{" "}
            </span>
          </div>
        </div>

        <div className="bg-gray-100 m-4">
          <ObserverDemandTypeFilter
            demandTypePreset={demandTypePreset}
            demandTypes={demandTypes}
            billableDemandTypes={billableDemandTypes}
            handleDemandTypesChanged={this.props.handleDemandTypesChanged}
          ></ObserverDemandTypeFilter>
        </div>

        {numOfExperiments > 0 && (
          <div>
            {numOfSegments > 0 && (
              <SegmentOverview
                demandTypePreset={demandTypePreset}
                demandTypes={demandTypes}
                data={data}
                handleSegmentSelect={this.handleSegmentSelect}
              ></SegmentOverview>
            )}

            <div id="top-of-segment-experiences" className=" sticky top-0 z-50">
              <div
                className="py-1 px-4 text-white flex justify-between items-center"
                style={{ backgroundColor: "#0F202F" }}
              >
                <div className="text-sm flex gap-6">
                  <div>
                    {data.networkSetting.networkId}{" "}
                    {data.networkSetting.networkName}
                  </div>
                  <div>
                    Unit {data.unitId} {data.unitInfo.name}
                  </div>
                  <div className="flex items-center pr-3" title="Ext Unit ID">
                    {data.unitInfo.extUnitId}{" "}
                    <ClickToCopyWrapper
                      copyText={data.unitInfo.extUnitId}
                    ></ClickToCopyWrapper>
                  </div>
                </div>
                <div className="flex gap-4 justify-end">
                  <GoToInsightButton unitId={data.unitId}></GoToInsightButton>
                  <GoToAPConfigButton unitId={data.unitId}></GoToAPConfigButton>
                </div>
              </div>

              <div className="flex items-center justify-between py-2 px-4 bg-blue-900">
                {this.state.segmentId ? (
                  <div className="text-xl text-gray-100">
                    {this.state.segmentId === -1 ? (
                      "No Segment"
                    ) : (
                      <div className="flex items-center">
                        <div className="whitespace-no-wrap">
                          <div className="flex gap-1">
                            Segment: {this.state.segmentId}{" "}
                            <ClickToCopyWrapper
                              copyText={segmentDeepLinkUrl}
                              tooltipMessage="Click to copy deep link URL"
                              iconType="LINK"
                            ></ClickToCopyWrapper>
                          </div>
                        </div>

                        <SegmentData
                          segmentId={this.state.segmentId}
                          data={data}
                        ></SegmentData>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="text-xl text-gray-200">
                    Select a segment from the table above to view experiments.
                  </div>
                )}

                <div className="text-white">
                  <div className="text-right">
                    CostCPM:{" "}
                    <span
                      style={{ fontSize: "smaller" }}
                      className="text-gray-400"
                    >
                      $
                    </span>
                    {data.networkSetting.costCPM}{" "}
                    <span
                      style={{ fontSize: "smaller" }}
                      className="text-gray-400 font-mono"
                    >
                      USD
                    </span>
                  </div>
                  {data.networkSetting.currency !== "USD" && (
                    <div className="text-right text-gray-300">
                      ~{" "}
                      <span
                        style={{ fontSize: "smaller" }}
                        className="text-gray-400"
                      >
                        $
                      </span>
                      {data.costCPMInCurrency}{" "}
                      <span
                        style={{ fontSize: "smaller" }}
                        className="text-gray-400 font-mono"
                      >
                        {data.networkSetting.currency}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {this.state.segmentId && segmentData ? (
              <div className="bg-gray-800">
                {this.state.mode === mode.NEW && (
                  <SegmentList
                    data={data}
                    segmentId={this.state.segmentId}
                  ></SegmentList>
                )}
                {this.state.mode === mode.OLD && (
                  <SegmentExperiments
                    ref={this.child}
                    segmentId={this.state.segmentId}
                    dateRange={dateRange}
                    timezone={data.networkSetting.timezone}
                    groupParamsMap={data.groupParamsMap}
                    yieldLifts={segmentData.yieldLifts}
                    segmentInfo={segmentData.segmentInfo}
                    expInfo={segmentData.expInfo}
                    groupInfo={segmentData.groupInfo}
                    expLink={segmentData.expLink}
                  ></SegmentExperiments>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </>
    );
  }
}

class ReqCalcSelector extends React.PureComponent {
  render() {
    const { useNewRequest, handleReqCalcChanged } = this.props;

    return (
      <div className="text-sm">
        <button
          type="button"
          disabled={useNewRequest == "false"}
          onClick={() => handleReqCalcChanged(false)}
          className={`font-bold py-2 px-3 rounded-l shadow text-sm ${
            useNewRequest == "false"
              ? "bg-white text-blue-900 cursor-default"
              : "text-gray-300 bg-gray-600 hover:bg-gray-500 hover:text-gray-100"
          }`}
        >
          <div className="flex items-center gap-2">
            {useNewRequest == "false" ? <span>&#10003; </span> : ""}
            <span>Current Req</span>
            {useNewRequest == "false" ? "" : <FiExternalLink></FiExternalLink>}
          </div>
        </button>

        <button
          type="button"
          disabled={useNewRequest == "true"}
          onClick={() => handleReqCalcChanged(true)}
          className={`font-bold py-2 px-3 rounded-r shadow text-sm ${
            useNewRequest == "true"
              ? "bg-white text-blue-900 cursor-default"
              : "text-gray-300 bg-gray-600 hover:bg-gray-500 hover:text-gray-100"
          }`}
        >
          <div className="flex items-center gap-2">
            {useNewRequest == "true" ? <span>&#10003; </span> : ""}
            <span id="new-table-mode">New Req</span>
            {useNewRequest == "true" ? "" : <FiExternalLink></FiExternalLink>}
          </div>
        </button>
      </div>
    );
  }
}

class ViewModeSelector extends React.Component {
  render() {
    const { selectedMode, handleViewModeChange } = this.props;

    return (
      <div className="text-sm">
        <button
          type="button"
          onClick={(e) => handleViewModeChange(mode.OLD)}
          className={`font-bold py-1 px-2 rounded-l shadow text-sm ${
            selectedMode === mode.OLD
              ? "bg-white text-blue-900 cursor-default"
              : "text-gray-300 bg-gray-600 hover:bg-gray-500 hover:text-gray-100"
          }`}
        >
          {selectedMode === mode.OLD ? <span>&#10003; </span> : ""}
          <span id="old-table-mode">Table View</span>
        </button>

        <button
          type="button"
          onClick={() => handleViewModeChange(mode.NEW)}
          className={`font-bold py-1 px-2 rounded-r shadow text-sm ${
            selectedMode === mode.NEW
              ? "bg-white text-blue-900 cursor-default"
              : "text-gray-300 bg-gray-600 hover:bg-gray-500 hover:text-gray-100"
          }`}
        >
          {selectedMode === mode.NEW ? <span>&#10003; </span> : ""}
          <span id="new-table-mode">New Table View</span>
        </button>
      </div>
    );
  }
}

export default withRouter(ObserverOverview);
