import _ from "lodash";
import { INVENTORY_REGISTRY } from "constants/InventoryRegistry";
import { RI_CONFIG_SCHEMA } from "./CSTConfigSchema";

export function getInventoryRegForOverrides({ integration, ri, riConfig }) {
  let currentSchema = _.find(RI_CONFIG_SCHEMA[integration], { key: ri });

  // gpt, amp, video, mobile
  const rtMap = {
    gpt: "gpt",
    video: "video",
    amp: "amp",
    mobile_android: "mobile",
    mobile_ios: "mobile",
  };

  const requestType = rtMap[integration]; // ex. gpt
  const currentConfig = riConfig;
  const inventoryFormats = currentConfig["optimized_formats"]; // ex. ["banner"]
  let deviceCategory = ri; // ex. desktop
  deviceCategory = deviceCategory === "phone" ? "smartphone" : deviceCategory;
  const browsers = currentConfig["browsers"] || []; // ex. ["chrome", "safari"]
  const os = currentConfig["operating_systems"] || []; // ex. ["android", "ios"]

  const filteredItems = _.filter(INVENTORY_REGISTRY, (r) => {
    const props = r.label.split(" + ");
    // only 4 props is allowed now
    if (props.length < 4) {
      return false;
    }

    const rt = props[0];
    const ivf = props[1];
    const dc = props[2];
    const extra = props[3];

    let isExtraFiltered = false;
    if (integration === "mobile_android") {
      isExtraFiltered = extra === "android";
    } else if (integration === "mobile_ios") {
      isExtraFiltered = extra === "ios";
    } else {
      const schemaKeys = _.map(currentSchema.schema, "key");
      if (_.indexOf(schemaKeys, "browsers") !== -1) {
        isExtraFiltered = _.indexOf(browsers, extra) !== -1;
      }
      if (_.indexOf(schemaKeys, "operating_systems") !== -1) {
        isExtraFiltered = _.indexOf(os, extra) !== -1;
      }
    }

    return (
      rt === requestType &&
      _.indexOf(inventoryFormats, ivf) !== -1 &&
      dc === deviceCategory &&
      isExtraFiltered
    );
  });

  return _.map(filteredItems, (item) => {
    return {
      registry_id: item.id,
      registry_name: item.label,
    };
  });
}
