import React from "react";
import _ from "lodash";
import { NetworkAPI } from "apis";
import { CSVLink } from "react-csv";
import LoadingUI from "components/common/LoadingUI";

class SymptomReportViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,

      symptomName: null,
      reportDate: null,
      data: null,
    };
  }

  async componentDidMount() {
    const networkId = _.get(this.props, "networkId");
    const symptomName = _.get(this.props, "match.params.symptomName");
    const reportDate = _.get(this.props, "match.params.reportDate");

    if (networkId) {
      document.title = `${networkId} | Diagnoser Symptom Report`;
    }

    if (symptomName && reportDate) {
      this.setState({ symptomName, reportDate });
    }

    const params = {
      networkId,
      symptomName,
      reportDate,
    };
    await this._queryAndUpdateUI(params);
  }

  async _queryAndUpdateUI(params) {
    this.setState({ isLoading: true });
    try {
      const { report } = await this.queryData(params);
      if (_.isEmpty(report)) {
        return this.setState({
          errMsg: "No report found",
          isLoading: false,
        });
      } else {
        this.setState({
          data: report,
        });
      }
    } catch (err) {
      console.log("Error querying diagnoser report", err);
      this.setState({ errMsg: err });
    }
    this.setState({ isLoading: false });
  }

  async queryData(params) {
    // networkId, symptomName, reportDate
    const { report } = await NetworkAPI.getDiagnoserReport(params);
    return { report };
  }

  render() {
    const { networkId } = this.props;
    const { isLoading, errMsg, data, symptomName, reportDate } = this.state;
    const fileName = `${networkId}_${symptomName}_${reportDate}.csv`;

    return (
      <div>
        <div className="text-lg font-semibold">
          Symptom: {symptomName} <br></br>Date: {reportDate}
        </div>
        {isLoading && <LoadingUI></LoadingUI>}
        {errMsg && <div className="text-red-700">{errMsg}</div>}
        {data && (
          <div>
            <div className="float-right py-4">
              <CSVLink
                data={data}
                separator=","
                filename={fileName}
                className="shadow rounded bg-blue-200 py-2 px-4 font-medium text-blue-800 hover:bg-blue-300"
              >
                Download CSV
              </CSVLink>
            </div>
            <div className="w-full overflow-scroll">
              <CsvTable data={data}></CsvTable>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function CsvTable({ data }) {
  const rowsWithColumns = _parseCsvToRowsAndColumn(data.trim());
  let headerRow = rowsWithColumns.splice(0, 1)[0];

  const renderTableHeader = (row) => {
    if (row && row.map) {
      return (
        <thead className="border-b bg-gray-200 text-xs text-blue-800">
          <tr>
            {row.map((column, i) => (
              <th key={i} className="border py-1 px-2 text-right">
                {column}
              </th>
            ))}
          </tr>
        </thead>
      );
    }
  };

  const renderTableBody = (rows) => {
    if (rows && rows.map) {
      return (
        <tbody className="bg-white font-mono text-gray-900">
          {rows.map((row, rowIdx) => (
            <tr key={rowIdx}>
              {row.map &&
                row.map((column, colIdx) => (
                  <td
                    className="whitespace-no-wrap border py-1 px-2 text-right"
                    key={colIdx}
                  >
                    {column}
                  </td>
                ))}
            </tr>
          ))}
        </tbody>
      );
    }
  };

  return (
    <table className="border shadow table w-full text-sm">
      {renderTableHeader(headerRow)}
      {renderTableBody(rowsWithColumns)}
    </table>
  );
}

function _parseCsvToRowsAndColumn(csvText, csvColumnDelimiter = ",") {
  const rows = csvText.split("\n");
  if (!rows || rows.length === 0) {
    return [];
  }

  return rows.map((row) => row.split(csvColumnDelimiter));
}

export default SymptomReportViewer;
