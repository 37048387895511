import React from "react";
import _ from "lodash";

class ConfigGroupView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: null,

      config: null,
    };
  }

  render() {
    const { group } = this.props;

    if (!group) return "no group";

    return (
      <>
        <div className="border p-4 bg-white">
          <div>
            <div>Name: {group.name}</div>
            <div>Traffic Ratio: {group.traffic_ratio}</div>
            <div></div>
          </div>
          <div>
            <button
              type="button"
              className="px-4 py-2 bg-white rounded shadow hover:bg-gray-100 text-gray-800"
              onClick={this.props.handleEditClicked}
            >
              Edit
            </button>
            <button
              type="button"
              className="px-4 py-2 bg-white rounded shadow hover:bg-gray-100 text-gray-800"
              onClick={this.props.handleRemoveClicked}
            >
              Remove
            </button>
            <button
              type="button"
              className="px-4 py-2 bg-white rounded shadow hover:bg-gray-100 text-gray-800"
              onClick={this.props.handleDuplicateClicked}
            >
              Duplicate
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default ConfigGroupView;
