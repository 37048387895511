import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
Highcharts.setOptions({
  lang: {
    decimalPoint: ".",
    thousandsSep: ",",
  },
});

class HighchartChart extends React.PureComponent {
  render() {
    const { options } = this.props;
    return <HighchartsReact highcharts={Highcharts} options={options} />;
  }
}

export default HighchartChart;
