import React from "react";
import _ from "lodash";
import TrendRequestByRequestType from "./TrendRequestByRequestType";
import InventoryReportTables from "./InventoryReportTables";

class InventoryView extends React.Component {
  render() {
    const { reports } = this.props;

    const overallReports = _(reports)
      .groupBy(r => `${r.date}_${r.requestType}`)
      .map(reports => {
        const date = _.get(_.first(reports), "date");
        const requestType = _.get(_.first(reports), "requestType");
        const request = _.sumBy(reports, "request");
        const impression = _.sumBy(reports, "impression");

        const str = request > 0 ? impression / request : 0;

        return {
          date,
          requestType,
          request,
          impression,
          str
        };
      })
      .value();

    const requestMap = _(reports)
      .groupBy("date")
      .reduce((result, r, date) => {
        result[date] = _.sumBy(r, "request");

        return result;
      }, {});

    const sizesReports = _.map(reports, r => {
      r.requestRatio = _.round(r.request / requestMap[r.date], 4);
      return r;
    });

    return (
      <div className="py-8 px-4 bg-gray-300">
        {reports && (
          <>
            <div className="bg-white rounded-t shadow-md p-2">
              <div className="flex py-2">
                <div className="w-full">
                  <TrendRequestByRequestType
                    reports={overallReports}
                  ></TrendRequestByRequestType>
                </div>
              </div>
            </div>

            <InventoryReportTables
              overallReports={overallReports}
              sizesReports={sizesReports}
            ></InventoryReportTables>
          </>
        )}
      </div>
    );
  }
}

export default InventoryView;
