import React from "react";
import _ from "lodash";
import NumberFormat from "../../common/NumberFormat";
// import PopupWrapper from "../common/PopupWrapper";
// import Popup from "reactjs-popup";

/**
 *
 * paymentId
 * publisher
 *  publisherId
 *  name
 * collectionDate
 * collectionStatus
 * invoice
 *  ...
 * amount
 * receivedAmt
 * status
 */

class PaymentsTable extends React.PureComponent {
  render() {
    // showBasicOnly: only show publisher, invoice, receivable amount
    const { items, isSaving, onReceiveClicked, showBasicOnly } = this.props;
    const thClass =
      "px-4 py-2 text-gray-600 text-left text-xs tracking-wider uppercase";
    const tdClass = "px-4 py-2";

    return (
      <>
        <table className="min-w-full shadow divide-y">
          <thead className="bg-gray-100">
            <tr>
              <th className={thClass}>Publisher</th>
              <th className={thClass}>Invoice</th>

              {!showBasicOnly && <th className={thClass}>Collection Status</th>}
              <th className={thClass}>Receivable Amount</th>

              {!showBasicOnly && <th className={thClass}>Payment Status</th>}
            </tr>
          </thead>
          <tbody className="bg-white divide-y text-sm">
            {items.map((item) => {
              return (
                <tr
                  key={item.paymentId}
                  className={showBasicOnly ? "" : "hover:bg-gray-100"}
                >
                  <td className={tdClass}>
                    {item.billingProfile.publisher.publisherId} -{" "}
                    {item.billingProfile.publisher.name}
                  </td>

                  <td className={tdClass}>
                    <span className="font-semibold text-gray-900">
                      {item.invoice.invoiceNo} (
                      {item.invoice.startDate.substring(0, 7)})
                    </span>
                    <div className="text-gray-700">
                      Due date: {item.invoice.dueDate.substring(0, 10)}
                    </div>
                  </td>

                  {!showBasicOnly && (
                    <td className={tdClass + " text-gray-700"}>
                      {item.collectionStatus}
                    </td>
                  )}

                  <td className={tdClass}>
                    <NumberFormat value={item.amount} prefix="$"></NumberFormat>
                    <div className="text-xs text-gray-600 leading-none">
                      {item.invoice.currency}
                    </div>
                  </td>

                  {!showBasicOnly && (
                    <td className={tdClass + " text-gray-700"}>
                      {item.status}
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }
}

export default PaymentsTable;
