import _ from "lodash";

const api = process.env.REACT_APP_OBSERVER_API_ENDPOINT;
// const api = "http://emily.yieldbooster.io:9000";
// const api = "https://observer-dev.yieldbooster.io";
// const api = "https://observer-prod.yieldbooster.io";
// const api = "https://observer-backup-api.yieldbooster.io";

export { api, fetchRespJson, postData, fetchRespJsonWithoutCredentials };

async function fetchRespJsonWithoutCredentials(url) {
  try {
    const response = await fetch(url);
    const resultJson = await response.json();

    if (response.ok) {
      return resultJson; // parses JSON response into native JavaScript objects
    } else {
      throw JSON.stringify(resultJson);
    }
  } catch (err) {
    console.log("error get data", err);
    throw err;
  }
}

async function fetchRespJson(url) {
  try {
    const response = await fetch(url, {
      credentials: "include",
    });
    const resultJson = await response.json();

    if (response.ok) {
      return resultJson; // parses JSON response into native JavaScript objects
    } else {
      throw JSON.stringify(resultJson);
    }
  } catch (err) {
    console.log("error get data", err);
    throw err;
  }
}

async function postData(url, data) {
  try {
    // Default options are marked with *
    const response = await fetch(url, {
      method: "POST",
      // mode: 'cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      // redirect: 'follow', // manual, *follow, error
      // referrerPolicy: 'no-referrer', // no-referrer, *client
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    const resultJson = await response.json();

    if (response.ok) {
      return resultJson; // parses JSON response into native JavaScript objects
    } else {
      throw JSON.stringify(resultJson);
    }
  } catch (err) {
    console.log("error post data", err);
    throw err;
  }
}
