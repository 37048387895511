import React from "react";
import _ from "lodash";

const CriteriaPretty = (props) => {
  const { item } = props;
  const { check_id, check_name, accepted_results } = item;

  return (
    <span>
      <span>
        {check_id}: {check_name}
      </span>
      <span className="mx-1">=</span>
      <span>{JSON.stringify(accepted_results)}</span>
    </span>
  );
};

export default CriteriaPretty;
