import React from "react";
import _ from "lodash";
import TrendUprImpression from "./TrendUprImpression";
import NumberFormat from "../../common/NumberFormat";
import { getWeekdayAbbr } from "../../../helpers/DateTransform";

class UprValidationView extends React.PureComponent {
  constructor(props) {
    super(props);

    let publisherReports = _.clone(props.reportsByRules);
    const intowowReports = _.remove(publisherReports, r => {
      return _.includes(_.lowerCase(r.name), "intowow");
    });

    const intowowReportsByDate = _.groupBy(intowowReports, "date");
    const uprValidationReports = _(props.reports)
      .map(r => {
        const intowowReport = _.get(intowowReportsByDate, [r.date, 0], {});
        r.revenue = intowowReport.revenue;
        r.ecpm = intowowReport.ecpm;
        return r;
      })
      .orderBy(props.reports, ["date"], ["desc"])
      .value();

    this.state = {
      uprValidationReports,
      intowowReports,
      publisherReports
    };
  }

  render() {
    const { uprValidationReports, publisherReports } = this.state;

    return (
      <>
        <div className="py-8 px-4 bg-gray-300 min-h-screen">
          <div className="bg-white rounded-t shadow-md p-2">
            <div className="flex py-2">
              <TrendUprImpression
                reports={uprValidationReports}
              ></TrendUprImpression>
            </div>
            <UprValidationTable
              reports={uprValidationReports}
            ></UprValidationTable>
            <div className="my-4">
              <div className="text-gray-800 font-semibold">Publisher Rules</div>
              <RulesTable reports={publisherReports}></RulesTable>
            </div>
          </div>
        </div>
      </>
    );
  }
}

class RulesTable extends React.PureComponent {
  render() {
    let { reports } = this.props;
    reports = _.orderBy(reports, ["date"], ["desc"]);
    const reportsGroupByDate = _.groupBy(reports, "date");

    return (
      <table className="w-full table text-sm border shadow">
        <thead className="bg-gray-200 text-blue-800 text-xs border-b">
          <tr>
            <th className="border w-1/6 text-right py-1 px-4">Date</th>
            <th className="border w-2/6 text-right py-1 px-4">Name</th>
            <th className="border w-1/6 text-right py-1 px-4">Impression</th>
            <th className="border w-1/6 text-right py-1 px-4">Revenue</th>
            <th className="border w-1/6 text-right py-1 px-4">eCPM</th>
          </tr>
        </thead>
        <tbody className="text-gray-900 font-mono bg-white">
          {reports.map((r, i) => {
            const weekdayAbbr = getWeekdayAbbr(r.date);
            const numOfRulesPerDate = reportsGroupByDate[r.date].length;
            const lastDate = _.get(reports, [i - 1, "date"]);

            return (
              <tr key={i} className="border-b hover:bg-gray-100">
                {r.date !== lastDate && (
                  <td
                    className="text-right py-1 px-4 whitespace-no-wrap border"
                    rowSpan={numOfRulesPerDate}
                  >
                    {i === 0 && <span className="font-bold">*</span>}
                    {r.date} {weekdayAbbr}
                  </td>
                )}

                <td className="text-right py-1 px-4 border">{r.name}</td>

                <td className="text-right py-1 px-4 border">
                  {_.isNumber(r.impression) ? (
                    <NumberFormat
                      value={r.impression}
                      format="0,0"
                    ></NumberFormat>
                  ) : (
                    "-"
                  )}
                </td>

                <td className="text-right py-1 px-4 border">
                  {_.isNumber(r.revenue) ? (
                    <NumberFormat
                      value={r.revenue}
                      format="$0,0.00"
                    ></NumberFormat>
                  ) : (
                    "-"
                  )}
                </td>

                <td className="text-right py-1 px-4 border">
                  {_.isNumber(r.ecpm) ? (
                    <NumberFormat
                      value={r.ecpm}
                      format="$0,0.00"
                    ></NumberFormat>
                  ) : (
                    "-"
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

class UprValidationTable extends React.PureComponent {
  render() {
    let { reports } = this.props;
    return (
      <table className="w-full table text-sm border shadow">
        <thead className="bg-gray-200 text-blue-800 text-xs border-b">
          <tr>
            <th rowSpan="2" className="border text-right py-1 px-4">
              Date
            </th>
            <th rowSpan="2" className="border text-right py-1 px-4">
              Optimization Adx Impression
            </th>
            <th colSpan="3" className="border text-center py-1 px-4">
              Intowow Rule Adx
            </th>
            <th rowSpan="2" className="border text-right py-1 px-4">
              Imp Ratio
            </th>
          </tr>
          <tr>
            <th className="border text-right py-1 px-4">Impression</th>
            <th className="border text-right py-1 px-4">Revenue</th>
            <th className="border text-right py-1 px-4">eCPM</th>
          </tr>
        </thead>
        <tbody className="text-gray-900 font-mono bg-white">
          {reports.map((r, i) => {
            const weekdayAbbr = getWeekdayAbbr(r.date);
            return (
              <tr key={i} className="border-b hover:bg-gray-100">
                <td className="text-right py-1 px-4 whitespace-no-wrap border">
                  {i === 0 && <span className="font-bold">*</span>}
                  {r.date} {weekdayAbbr}
                </td>

                <td className="text-right py-1 px-4 border">
                  {_.isNumber(r.optimizationAdxImpression) ? (
                    <NumberFormat
                      value={r.optimizationAdxImpression}
                      format="0,0"
                    ></NumberFormat>
                  ) : (
                    "-"
                  )}
                </td>

                <td className="text-right py-1 px-4 border">
                  {_.isNumber(r.intowowRuleAdxImpression) ? (
                    <NumberFormat
                      value={r.intowowRuleAdxImpression}
                      format="0,0"
                    ></NumberFormat>
                  ) : (
                    "-"
                  )}
                </td>

                <td className="text-right py-1 px-4 border">
                  {_.isNumber(r.revenue) ? (
                    <NumberFormat
                      value={r.revenue}
                      format="$0,0.00"
                    ></NumberFormat>
                  ) : (
                    "-"
                  )}
                </td>

                <td className="text-right py-1 px-4 border">
                  {_.isNumber(r.ecpm) ? (
                    <NumberFormat
                      value={r.ecpm}
                      format="$0,0.00"
                    ></NumberFormat>
                  ) : (
                    "-"
                  )}
                </td>

                <td className="text-right py-1 px-4 border">
                  {_.isNumber(r.impressionRatio) ? (
                    <NumberFormat
                      value={r.impressionRatio}
                      format="0,0.00%"
                    ></NumberFormat>
                  ) : (
                    "-"
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default UprValidationView;
