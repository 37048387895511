import React from "react";

class ExperimentGroupAnchor extends React.PureComponent {
  render() {
    const { expGroupId } = this.props;
    return (
      <div style={{ position: "relative" }}>
        <div
          id={`exp-group-anchor-${expGroupId}`}
          style={{ position: "absolute", top: "-110px" }}
        >
          {""}
          {/* ({`exp-group-anchor-${expGroupId}`}) */}
        </div>
      </div>
    );
  }
}

export default ExperimentGroupAnchor;
