import React, { Suspense } from "react";
import moment from "moment-timezone";
import _ from "lodash";
import { CstAPI, NetworkAPI } from "apis";
import LoadingUI from "../common/LoadingUI";
import UnitsSelector from "components/common/UnitsSelector";
import NetworkInfoHeader from "components/ops-mgmt/common/NetworkInfoHeader";
import { FiChevronDown } from "react-icons/fi";
import CustomDateRangeButton from "components/common/CustomDateRangeButton";

class NetworkCstReportViewer extends React.Component {
  constructor(props) {
    super(props);

    const networkId = _.get(props, "match.params.networkId");

    this.state = {
      isLoading: false,
      data: null,
      errMsg: null,

      networkId,
      networkInfo: null,
    };

    this.handleGetReport = this.handleGetReport.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      const { networkId } = this.props.match.params;
      if (!networkId) {
        throw new Error("Missing Network ID");
      }
      const networkInfo = await NetworkAPI.getNetworkInfo({ networkId });
      if (!networkInfo) {
        throw new Error("Invalid Network");
      }

      document.title = `${networkId} CST Report | YB Observer`;

      this.setState({
        networkId,
        networkInfo,
        isLoading: false,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        errMsg: err.toString(),
      });
    }
  }

  async handleGetReport(params) {
    this.setState({ isLoading: true, errMsg: null });

    const networkId = _.get(this.props, "match.params.networkId");
    try {
      const { link } = await CstAPI.getCstReport({ ...params, networkId });
      // download immediately
      window.open(link);
    } catch (err) {
      console.log("Error getting cst report", err);
      this.setState({ errMsg: err });
    }

    this.setState({ isLoading: false });
  }

  render() {
    const { networkId, networkInfo, isLoading, errMsg } = this.state;

    return (
      <>
        {networkInfo && (
          <div>
            <NetworkInfoHeader networkInfo={networkInfo}></NetworkInfoHeader>
            <div className="bg-gray-200 px-12 py-8 min-h-screen">
              <div className="flex items-center justify-between">
                <PageTitle title="CST Report Export CSV"></PageTitle>
              </div>

              <div className="p-4 bg-white rounded">
                <CstReportFilter
                  isLoading={isLoading}
                  networkId={networkId}
                  handleFilter={this.handleGetReport}
                ></CstReportFilter>
              </div>
              {isLoading && <LoadingUI></LoadingUI>}
              {errMsg && <div className="text-red-800">{errMsg}</div>}
            </div>
          </div>
        )}
      </>
    );
  }
}

function _convertDateToYMD(date) {
  const dd = new Date(date);
  let d = dd.getDate();
  let m = dd.getMonth() + 1; // Month from 0 to 11
  let y = dd.getFullYear();
  let finalString = `${y}-${m <= 9 ? "0" + m : m}-${d <= 9 ? "0" + d : d}`;
  return finalString;
}

const DATE_RANGES = [0, 1, 3, 7]; // days
const DEFAULT_DATE_RANGE = 1;

class CstReportFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    const { startDate, endDate } = this._getDateRangeDates(DEFAULT_DATE_RANGE);

    this.state = {
      startDate,
      endDate,
      extGamUnitIds: null,

      currentDateRange: DEFAULT_DATE_RANGE,
      isDateRangeCustom: false,
    };

    this.handleDateRangeChanged = this.handleDateRangeChanged.bind(this);
    this.handleCustomDateRangeChanged =
      this.handleCustomDateRangeChanged.bind(this);
    this.handleDateRangeCanceled = this.handleDateRangeCanceled.bind(this);

    this.handleUnitsChanged = this.handleUnitsChanged.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
  }

  _getDateRangeDates(num) {
    const today = moment();
    const dateFormat = "YYYY-MM-DD";
    // today
    if (num === 0) {
      const startDate = today.clone().startOf("day").format(dateFormat);
      const endDate = startDate;

      return { startDate, endDate };
    }
    // yesterday
    if (num === 1) {
      const startDate = today
        .clone()
        .startOf("day")
        .subtract(1, "day")
        .format(dateFormat);
      const endDate = startDate;

      return { startDate, endDate };
    }

    const startDate = today
      .clone()
      .startOf("day")
      .subtract(num - 1, "days")
      .format(dateFormat);

    const endDate = today.clone().startOf("day").format(dateFormat);

    return { startDate, endDate };
  }

  handleDateRangeChanged(dr) {
    if (dr === "CUSTOM") {
      // this.setState({ currentDateRange: dr, startDate: null, endDate: null });
      this.setState({
        isDateRangeCustom: true,
        startDate: null,
        endDate: null,
      });
    } else {
      const { startDate, endDate } = this._getDateRangeDates(dr);
      this.setState({
        isDateRangeCustom: false,
        currentDateRange: dr,
        startDate,
        endDate,
      });
    }
  }

  // startDate, endDate are date objects
  handleCustomDateRangeChanged({ startDate, endDate }) {
    this.setState({
      isDateRangeCustom: true,
      startDate: _convertDateToYMD(startDate),
      endDate: _convertDateToYMD(endDate),
    });
  }

  handleDateRangeCanceled() {
    // currentDateRange is not changed when choosing custom date range
    // so we know the previous selected date range
    this.handleDateRangeChanged(this.state.currentDateRange);
    // this.setState({ isDateRangeCustom: false });
  }

  handleFilter() {
    const { startDate, endDate, extGamUnitIds } = this.state;
    const params = {
      startDate,
      endDate,
      extGamUnitIds,
    };
    this.props.handleFilter(params);
  }

  handleUnitsChanged(extGamUnitIds) {
    this.setState({ extGamUnitIds });
  }

  isFilterValid() {
    const { startDate, endDate, extGamUnitIds } = this.state;
    if (!startDate) return false;
    if (!endDate) return false;
    // if (!extGamUnitIds) return false;
    // if (extGamUnitIds.length < 1) return false;
    return true;
  }

  render() {
    const { networkId, isLoading } = this.props;
    const {
      currentDateRange,
      startDate,
      endDate,
      isDateRangeCustom,
      extGamUnitIds,
    } = this.state;
    const isFilterDisabled = !this.isFilterValid() || isLoading;

    return (
      <div>
        <div className="mb-4">
          <div className="font-semibold text-indigo-900">
            Date Range: (
            <span className="mx-1 text-sm text-gray-800">
              <span className="font-normal text-gray-600">From</span>{" "}
              {startDate} <span className="font-normal text-gray-600">to</span>{" "}
              {endDate}
            </span>
            )
          </div>

          <div className="flex justify-start items-center mb-4 text-sm">
            {DATE_RANGES.map((dr) => {
              return (
                <button
                  key={dr}
                  type="button"
                  className={`px-4 py-2 mr-1 font-semibold rounded ${
                    !isDateRangeCustom && currentDateRange === dr
                      ? "bg-indigo-100 text-indigo-800"
                      : "text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
                  }`}
                  onClick={() => this.handleDateRangeChanged(dr)}
                >
                  {dr === 0 ? (
                    "Today"
                  ) : (
                    <span>
                      {dr === 1 ? (
                        "Yesterday"
                      ) : (
                        <span>Last {dr > 1 && dr} days</span>
                      )}
                    </span>
                  )}
                </button>
              );
            })}
            <CustomDateRangeButton
              onOpen={() => this.handleDateRangeChanged("CUSTOM")}
              handleDateRangeChanged={this.handleCustomDateRangeChanged}
              handleDateRangeCanceled={this.handleDateRangeCanceled}
              customButton={
                <button
                  type="button"
                  className={`px-4 py-2 mr-1 font-semibold rounded ${
                    isDateRangeCustom
                      ? "bg-indigo-100 text-indigo-800"
                      : "text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
                  }`}
                >
                  <div className="flex items-center gap-2">
                    {isDateRangeCustom && startDate && endDate ? (
                      <span>
                        Custom:
                        <span className="mx-1">
                          <span className="font-normal text-gray-600">
                            From
                          </span>{" "}
                          {startDate}{" "}
                          <span className="font-normal text-gray-600">to</span>{" "}
                          {endDate}
                        </span>
                      </span>
                    ) : (
                      "Custom "
                    )}
                    <FiChevronDown className="text-gray-500"></FiChevronDown>
                  </div>
                </button>
              }
            ></CustomDateRangeButton>
          </div>
        </div>

        <div className="mb-4">
          <div className="font-semibold text-indigo-900">
            Select units{" "}
            {extGamUnitIds && <span>({extGamUnitIds.length})</span>}:{" "}
          </div>
          <UnitsSelector
            networkId={networkId}
            handleChanged={this.handleUnitsChanged}
            selectedItems={extGamUnitIds}
          ></UnitsSelector>
        </div>

        <div className="flex justify-end">
          <button
            type="button"
            onClick={this.handleFilter}
            className={`px-6 py-2 rounded text-white font-semibold ${
              isFilterDisabled
                ? "bg-blue-200"
                : "bg-blue-400 hover:shadow hover:bg-blue-500"
            }`}
            disabled={isFilterDisabled}
          >
            Get report
          </button>
        </div>
      </div>
    );
  }
}

export default NetworkCstReportViewer;

function PageTitle({ title }) {
  return <div className="text-3xl font-extrabold">{title}</div>;
}
