import React from "react";
import _ from "lodash";
import { CSVLink } from "react-csv";

class ExportToCsvButton extends React.Component {
  transformToCsv(data) {
    const keys = _.keys(data[0]);
    const columns = _.map(keys, (key) => {
      return {
        label: key,
        key,
      };
    });

    const records = data;
    return { columns, records };
  }

  render() {
    const { data, filename } = this.props;

    return (
      <CSVLink
        className="text-blue-600 bg-gray-100 font-semibold rounded px-4 py-2 hover:bg-gray-200"
        data={data}
        filename={filename}
      >
        Export to CSV
      </CSVLink>
    );
  }
}

export default ExportToCsvButton;
