import React from "react";
import _ from "lodash";
import FeaturesConfigItem from "./FeaturesConfigItem";

const configSections = [
  {
    sectionKey: "base",
    sectionConfigKey: "", // the outer-most layer of config
    title: "Base",
  },
];

class FeaturesConfig extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: configSections[0].sectionKey, // BASE
    };

    this.onTabSelected = this.onTabSelected.bind(this);
  }

  onTabSelected(tab) {
    if (this.state.activeTab === tab) return;
    this.setState({ activeTab: tab });
  }

  render() {
    const {
      schemasGroupedBySection,
      currentEditingKey,
      handleStartEditMode,
      handleCancelEditMode,
      handleApplyChanges,
      newConfig,
    } = this.props;
    const { activeTab } = this.state;

    if (!schemasGroupedBySection) {
      return "";
    }

    let sections = configSections;

    return (
      <>
        {/* <div className="flex px-4">
          {sections.map((s) => {
            const isActive = activeTab === s.sectionKey;

            return (
              <div
                key={s.sectionKey}
                className={`px-4 py-2 font-semibold ${
                  isActive ? "border-b-2 border-blue-400" : "text-gray-600"
                } ${
                  currentEditingKey
                    ? isActive
                      ? ""
                      : "cursor-not-allowed"
                    : "cursor-pointer hover:text-black"
                }`}
                onClick={() => {
                  if (currentEditingKey) {
                    notify.show(
                      "Apply changes before moving on to the next section.",
                      "warning",
                      3000
                    );
                    return;
                  }
                  return this.onTabSelected(s.sectionKey);
                }}
              >
                {s.title}
              </div>
            );
          })}
        </div> */}

        <div>
          {sections.map((s) => {
            const isCurrentTab = activeTab === s.sectionKey;
            const configSchemas = _.get(schemasGroupedBySection, [
              s.sectionKey,
              "configSchemas",
            ]);

            if (isCurrentTab) {
              return (
                <FeaturesConfigItem
                  key={s.sectionKey}
                  sectionTitle={s.title}
                  sectionConfigKey={s.sectionConfigKey}
                  configSchemas={configSchemas}
                  currentEditingKey={currentEditingKey}
                  handleStartEditMode={handleStartEditMode}
                  handleCancelEditMode={handleCancelEditMode}
                  handleApplyChanges={handleApplyChanges}
                  newConfig={newConfig}
                ></FeaturesConfigItem>
              );
            } else {
              return "";
            }
          })}
        </div>
      </>
    );
  }
}

export default FeaturesConfig;
