import React from "react";
import _ from "lodash";

class TextAreaWithJsonCheck extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: this.props.inputValue || "",
      errMsg: null,
      validMsg: null,
    };

    this.handleInputChanged = this.handleInputChanged.bind(this);
    this.handleJsonCheck = this.handleJsonCheck.bind(this);
    this.handleBeautifyJson = this.handleBeautifyJson.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.inputValue !== this.props.inputValue) {
      this.setState({ inputValue: this.props.inputValue });
    }
  }

  handleInputChanged(e) {
    const inputValue = e.target.value;
    this.setState({ inputValue, errMsg: null, validMsg: null });

    this.props.handleInputChanged(inputValue);
  }

  handleJsonCheck() {
    const { inputValue } = this.state;

    const hasError = this._checkIsValidJson(inputValue);
    let errMsg = null;
    let validMsg = null;
    if (hasError) {
      errMsg = "Not a valid JSON";
    } else {
      validMsg = "JSON valid";
    }

    this.setState({
      errMsg,
      validMsg,
    });
  }

  handleBeautifyJson() {
    const { inputValue } = this.state;

    const hasError = this._checkIsValidJson(inputValue);
    let errMsg = null;
    if (hasError) {
      errMsg = "Not a valid JSON";
    } else {
      const json = JSON.parse(inputValue);

      this.setState({
        inputValue: JSON.stringify(json, null, 4),
      });
    }

    this.setState({
      errMsg,
    });
  }

  _checkIsValidJson(jsonText) {
    let hasError = false;

    try {
      JSON.parse(jsonText);
    } catch (err) {
      hasError = true;
      // console.log(err);
    }

    return hasError;
  }

  render() {
    // const { handleInputChanged, inputValue } = this.props;
    const { rows } = this.props;
    const { inputValue, errMsg, validMsg } = this.state;

    return (
      <>
        <textarea
          rows={rows || 10}
          className="border rounded w-full border-gray-400 p-2"
          value={inputValue}
          // onChange={(e) => this.handleInputChanged("layers", e.target.value)}
          onChange={this.handleInputChanged}
        ></textarea>
        <div>
          <button
            type="button"
            onClick={this.handleJsonCheck}
            className="rounded shadow bg-white px-4 py-2 text-gray-800 hover:bg-gray-100"
          >
            Check JSON format
          </button>
          <button
            type="button"
            onClick={this.handleBeautifyJson}
            className="rounded shadow bg-white px-4 py-2 text-gray-800 hover:bg-gray-100"
          >
            Beautify JSON
          </button>
        </div>

        {errMsg && (
          <div className="bg-red-100 px-4 py-2 text-red-600">{errMsg}</div>
        )}
        {validMsg && (
          <div className="bg-green-100 px-4 py-2 text-green-600">
            {validMsg}
          </div>
        )}
      </>
    );
  }
}

export default TextAreaWithJsonCheck;
