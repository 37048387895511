import _ from "lodash";
import moment from "moment-timezone";
import { trialTypes } from "../constants/FilterTypes";
import { snapshotSortByDayKeyMap } from "../constants/SortTypes";

export function transformSnapshotData(resultData, dailyGoalMap) {
  resultData = _.map(resultData, (d) => {
    d.daily_goal = _.get(dailyGoalMap, d.gam_network_id);

    if (d.trial_status) {
      const expDate = _.get(d, "trial_status.exp_date");
      const now = moment();

      const expDiff = moment(now).diff(expDate);
      const expDiffString = moment.duration(expDiff).humanize();
      const isExpired = expDiff > 0;

      const billableStartDate = _.get(d, "trial_status.billable_start_date");

      if (expDate) {
        d.trial_status.expDiff = expDiff;
        d.trial_status.expDiffString = expDiffString;
        d.trial_status.isExpired = isExpired;
      }

      if (billableStartDate) {
        const billableDiff = moment(now).diff(billableStartDate);
        const billableDiffString = moment.duration(billableDiff).humanize();

        d.trial_status.billableDiff = billableDiff;
        d.trial_status.billableDiffString = billableDiffString;
      }

      if (expDate && isExpired && !billableStartDate) {
        d.trial_status.trialType = trialTypes.TRIAL_EXPIRED;
      } else if (expDate && !billableStartDate) {
        d.trial_status.trialType = trialTypes.IN_FREE_TRIAL;
      } else if (billableStartDate) {
        d.trial_status.trialType = trialTypes.BILLABLE;
      } else {
        d.trial_status.trialType = trialTypes.OTHERS;
      }
    }

    const todayMetrics = _.find(d.trend, "isToday");

    // unit infos
    d.running_units = _getUnitInfo(d.running_unit_ids, d.unit_metrics_info);
    d.video_units = _getUnitInfo(d.video_unit_ids, d.unit_metrics_info);
    d.refresh_units = _getUnitInfo(d.refresh_unit_ids, d.unit_metrics_info);
    d.worst_units = _getUnitInfo(d.worst_unit_ids, d.unit_metrics_info);
    d.paused_units = _getUnitInfo(d.paused_unit_ids, d.unit_metrics_info);
    d.suspended_units = _getUnitInfo(d.suspended_unit_ids, d.unit_metrics_info);
    d.inspection_units = _getUnitInfo(
      d.inspection_unit_ids,
      d.unit_metrics_info
    );
    d.debug_units = _getUnitInfo(d.debug_unit_ids, d.unit_metrics_info);
    d.custom_seg_units = _getUnitInfo(
      d.custom_seg_unit_ids,
      d.unit_metrics_info
    );
    d.amp_units = _getUnitInfo(d.amp_unit_ids, d.unit_metrics_info);
    d.gpt_units = _getUnitInfo(d.gpt_unit_ids, d.unit_metrics_info);
    d.sdk_units = _getUnitInfo(d.sdk_unit_ids, d.unit_metrics_info);
    d.src_units = _getUnitInfo(d.src_unit_ids, d.unit_metrics_info);
    d.newly_onboarded_units = _getUnitInfo(
      d.newly_onboarded_unit_ids,
      d.unit_metrics_info
    );
    d.recently_changed_units = _getUnitInfo(
      d.recently_changed_unit_ids,
      d.unit_metrics_info
    );
    d.ivt_units = _getUnitInfo(d.ivt_unit_ids, d.unit_metrics_info);
    d.ivf_units = _getUnitInfo(d.ivf_unit_ids, d.unit_metrics_info);

    d = _.omit(d, "unit_metrics_info");

    return { ...d, ...todayMetrics };
  });

  return resultData;
}

function _getUnitInfo(targetUnitIds, unitInfos) {
  return _.compact(
    _.map(targetUnitIds, (id) => {
      return unitInfos[id];
    })
  );
}

export function orderNetworksBy(data, sortKey, sortByDayKey, isOrderByDesc) {
  // console.log("orderNetworksBy", data, sortKey, sortByDayKey, isOrderByDesc);

  if (!sortKey) {
    return;
  }

  if (sortByDayKey === snapshotSortByDayKeyMap.TODAY) {
    const filteredData = _.orderBy(
      data,
      [
        (network) => {
          const trends = _.orderBy(network.trend, "date", "desc");
          return trends[1] ? trends[1][sortKey] : 0;
        },
      ],
      [isOrderByDesc ? "desc" : "asc"]
    );

    return filteredData;
  } else if (sortByDayKey === snapshotSortByDayKeyMap.D_1) {
    const filteredData = _.orderBy(
      data,
      [
        (network) => {
          const trends = _.orderBy(network.trend, "date", "desc");
          return trends[1] ? trends[1][sortKey] : 0;
        },
      ],
      [isOrderByDesc ? "desc" : "asc"]
    );

    return filteredData;
  } else if (sortByDayKey === snapshotSortByDayKeyMap.PAST_3_DAYS_AVG) {
    const filteredData = _.orderBy(
      data,
      [
        (network) => {
          const trends = _.orderBy(network.trend, "date", "desc").slice(1, 4);
          const mean = _.meanBy(trends, (t) => {
            return t[sortKey];
          });

          return _.isNaN(mean) ? 0 : mean;
        },
      ],
      [isOrderByDesc ? "desc" : "asc"]
    );

    return filteredData;
  } else if (sortByDayKey === snapshotSortByDayKeyMap.PAST_7_DAYS_AVG) {
    const filteredData = _.orderBy(
      data,
      [
        (network) => {
          const trends = _.orderBy(network.trend, "date", "desc").slice(1, 8);
          const mean = _.meanBy(trends, (t) => {
            return t[sortKey];
          });

          return _.isNaN(mean) ? 0 : mean;
        },
      ],
      [isOrderByDesc ? "desc" : "asc"]
    );

    return filteredData;
  } else {
    // should not come here
    return data;
  }
}

export function searchNetworkByInput(
  data,
  inputValue,
  { isYieldSetReport = false }
) {
  let filterKeys = [
    "gam_network_id",
    "name",
    "publisher_name",
    "reseller_name",
  ];

  if (isYieldSetReport) {
    filterKeys = ["yield_set_id", "yield_set_name"];
  }

  const fData = _.filter(data, (d) => {
    let isMatch = false;

    _.forEach(filterKeys, (fKey) => {
      if (d[fKey] && _.toLower(d[fKey]).includes(_.toLower(inputValue))) {
        isMatch = true;
      }
    });

    return isMatch;
  });

  return fData;
}

const metricsWithTwd = [
  "gross_increased_revenue",
  "net_increased_revenue",
  "gross_revenue",
  "net_revenue",
  "raw_eligible_revenue",
  "holistic_increased_revenue",
  "net_holistic_increased_revenue",
  "all_holistic_increased_revenue",
  "net_all_holistic_increased_revenue",

  // new
  "raw_eligible_rev",
  "net_billable_rev",
  "google_rev",
  "holistic_rev",
  "net_google_inc_rev",
  "net_holistic_inc_rev",

  // new
  "g_inc_rev_net",
  "g_inc_rev_gross",

  "h_inc_rev_net",
  "h_inc_rev_gross",

  "g_rev_wyb_t",
  "g_rev_woyb_t",
  "g_rev_wyb_o",
  "g_rev_woyb_o",
  "g_rev_wyb_no",
  "g_rev_woyb_no",
  "g_rev_wyb_r",
  "g_rev_woyb_r",
  "g_rev_wyb_p",
  "g_rev_woyb_p",

  "h_rev_wyb_t",
  "h_rev_woyb_t",
  "h_rev_wyb_o",
  "h_rev_woyb_o",
  "h_rev_wyb_no",
  "h_rev_woyb_no",
  "h_rev_wyb_r",
  "h_rev_woyb_r",
  "h_rev_wyb_p",
  "h_rev_woyb_p",

  "g_rpm_wyb_t",
  "g_rpm_woyb_t",
  "h_rpm_wyb_t",
  "h_rpm_woyb_t",

  "g_rpm_wyb_o",
  "g_rpm_woyb_o",
  "h_rpm_wyb_o",
  "h_rpm_woyb_o",

  "g_rpm_wyb_no",
  "g_rpm_woyb_no",
  "h_rpm_wyb_no",
  "h_rpm_woyb_no",

  "g_rpm_wyb_r",
  "g_rpm_woyb_r",
  "h_rpm_wyb_r",
  "h_rpm_woyb_r",

  "g_rpm_wyb_p",
  "g_rpm_woyb_p",
  "h_rpm_wyb_p",
  "h_rpm_woyb_p",

  // "extra_ad_serving_cost",
  "cost",
  "billable_inc_rev_gross",
  "billable_inc_rev_net",

  "pscore_g_rev",
  "pscore_h_rev",
  "dscore_g_rev",
  "dscore_h_rev",
  "uprkey_g_rev",
  "uprkey_h_rev",
];

// Used in Health-Check-Unit.js
export function transformUnitMoneyMetrics(unit, isInTwd) {
  let t = unit;
  _.forEach(metricsWithTwd, (metric) => {
    if (!t[metric]) return;
    t[`${metric}_trans`] = isInTwd ? t[`${metric}_twd`] : t[metric];

    if (t.last_day_metrics) {
      t.last_day_metrics[`${metric}_trans`] = isInTwd
        ? t.last_day_metrics[`${metric}_twd`]
        : t.last_day_metrics[metric];
    }

    if (t.last_2_day_metrics) {
      t.last_2_day_metrics[`${metric}_trans`] = isInTwd
        ? t.last_2_day_metrics[`${metric}_twd`]
        : t.last_2_day_metrics[metric];
    }

    if (t.last_3_day_metrics) {
      t.last_3_day_metrics[`${metric}_trans`] = isInTwd
        ? t.last_3_day_metrics[`${metric}_twd`]
        : t.last_3_day_metrics[metric];
    }
  });

  return t;
}

function _transformMoneyMetrics(units, metric, isInTwd) {
  // console.log(units);
  return _.map(units, (t) => {
    t[`${metric}_trans`] = isInTwd ? t[`${metric}_twd`] : t[metric];

    if (t.last_day_metrics) {
      t.last_day_metrics[`${metric}_trans`] = isInTwd
        ? t.last_day_metrics[`${metric}_twd`]
        : t.last_day_metrics[metric];
    }

    if (t.last_2_day_metrics) {
      t.last_2_day_metrics[`${metric}_trans`] = isInTwd
        ? t.last_2_day_metrics[`${metric}_twd`]
        : t.last_2_day_metrics[metric];
    }

    if (t.last_3_day_metrics) {
      t.last_3_day_metrics[`${metric}_trans`] = isInTwd
        ? t.last_3_day_metrics[`${metric}_twd`]
        : t.last_3_day_metrics[metric];
    }

    return t;
  });
}

export function transformNetworkDataToMatchCurrency(network, isInTwd) {
  let transformedNetwork = _.cloneDeep(network);

  _.forEach(metricsWithTwd, (metric) => {
    transformedNetwork[`${metric}_trans`] = isInTwd
      ? network[`${metric}_twd`]
      : network[metric];

    transformedNetwork.trend = _.map(network.trend, (t) => {
      t[`${metric}_trans`] = isInTwd ? t[`${metric}_twd`] : t[metric];
      return t;
    });

    transformedNetwork.hero_units = _transformMoneyMetrics(
      network.hero_units,
      metric,
      isInTwd
    );

    transformedNetwork.inspection_units = _transformMoneyMetrics(
      network.inspection_units,
      metric,
      isInTwd
    );

    transformedNetwork.debug_units = _transformMoneyMetrics(
      network.debug_units,
      metric,
      isInTwd
    );

    transformedNetwork.custom_seg_units = _transformMoneyMetrics(
      network.custom_seg_units,
      metric,
      isInTwd
    );

    transformedNetwork.running_units = _transformMoneyMetrics(
      network.running_units,
      metric,
      isInTwd
    );

    transformedNetwork.worst_units = _transformMoneyMetrics(
      network.worst_units,
      metric,
      isInTwd
    );

    transformedNetwork.video_units = _transformMoneyMetrics(
      network.video_units,
      metric,
      isInTwd
    );

    transformedNetwork.refresh_units = _transformMoneyMetrics(
      network.refresh_units,
      metric,
      isInTwd
    );

    transformedNetwork.paused_units = _transformMoneyMetrics(
      network.paused_units,
      metric,
      isInTwd
    );

    transformedNetwork.suspended_units = _transformMoneyMetrics(
      network.suspended_units,
      metric,
      isInTwd
    );

    transformedNetwork.amp_units = _transformMoneyMetrics(
      network.amp_units,
      metric,
      isInTwd
    );

    transformedNetwork.gpt_units = _transformMoneyMetrics(
      network.gpt_units,
      metric,
      isInTwd
    );

    transformedNetwork.sdk_units = _transformMoneyMetrics(
      network.sdk_units,
      metric,
      isInTwd
    );

    transformedNetwork.src_units = _transformMoneyMetrics(
      network.src_units,
      metric,
      isInTwd
    );

    transformedNetwork.newly_onboarded_units = _transformMoneyMetrics(
      network.newly_onboarded_units,
      metric,
      isInTwd
    );

    transformedNetwork.recently_changed_units = _transformMoneyMetrics(
      network.recently_changed_units,
      metric,
      isInTwd
    );

    transformedNetwork.not_onboarded_units = _.map(
      network.not_onboarded_units,
      (t) => {
        t[`${metric}_trans`] = isInTwd ? t[`${metric}_twd`] : t[metric];
        return t;
      }
    );

    transformedNetwork.ivt_units = _transformMoneyMetrics(
      network.ivt_units,
      metric,
      isInTwd
    );

    transformedNetwork.ivf_units = _transformMoneyMetrics(
      network.ivf_units,
      metric,
      isInTwd
    );
  });

  return transformedNetwork;
}
