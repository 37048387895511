import React from "react";
import _ from "lodash";

const inputClass =
  "bg-white flex w-full border border-gray-300 rounded py-1 px-4 appearance-none leading-normal focus:outline-none focus:border-blue-400 focus:shadow-inner hover:shadow";

const labelClass = "font-semibold text-gray-800";
const radioClass = "font-semibold text-sm text-gray-900 cursor-pointer";

class PublisherAuditSectionForm extends React.PureComponent {
  constructor(props) {
    super(props);

    // setting.publisherAuditRequired
    // setting.publisherAuditRecipients
    const { setting } = this.props;
    const { publisherAuditRequired = "", publisherAuditRecipients = [] } =
      setting;

    let publisherAuditRecipientsStr = "";
    if (publisherAuditRecipients && publisherAuditRecipients.length > 0) {
      publisherAuditRecipientsStr = publisherAuditRecipients.join(",");
    }

    this.state = {
      publisherAuditRequired,
      publisherAuditRecipients,
      publisherAuditRecipientsStr,

      // errorObj[key] = errMsg
      errorObj: {},
    };
  }

  onInputChanged(value, key) {
    this.setState({ [key]: value });

    // check valid
    const { isValid, errMsg } = this.checkKeyValid(key, value);
    if (!isValid) {
      const newErrorObj = { ...this.state.errorObj, [key]: errMsg };
      this.setState({ errorObj: newErrorObj });
      return;
    } else {
      let newErrorObj = this.state.errorObj;
      newErrorObj = _.omit(newErrorObj, key);
      this.setState({ errorObj: newErrorObj });
    }

    // transform to valid type
    const { setting } = this.props;
    let newValue = value;
    let newKey = key;
    switch (key) {
      case "phoneCountryCode":
      case "phoneNumber": {
        // newValue = _.parseInt(newValue); //no
        break;
      }

      case "billingRecipientsStr": {
        newKey = "billingRecipients";
        newValue = newValue.split(",");
        this.setState({ [newKey]: newValue });
        break;
      }

      case "publisherAuditRecipientsStr": {
        newKey = "publisherAuditRecipients";
        newValue = newValue.split(",");
        this.setState({ [newKey]: newValue });
        break;
      }

      default: {
        //
      }
    }

    const changedSetting = { ...setting, [newKey]: newValue };
    this.props.onSettingChanged(changedSetting);
    // if (key === "billingRecipientsStr") {
    //   newSetting["billingRecipient"] = value.split(",");
    // }
  }

  checkKeyValid(key, value) {
    let isValid = true;
    let errMsg = null;

    // errorObj[key] = errMsg
    switch (key) {
      case "phoneNumber":
      case "phoneCountryCode": {
        const newValue = _.parseInt(value);
        if (_.isNaN(newValue)) {
          isValid = false;
          errMsg = `${key} should be a number`;
          break;
        }
      }

      default: {
        //
      }
    }

    return { isValid, errMsg };
  }

  checkIsSectionValid(setting) {
    // check the whole section
    let isValid = true;
    const requiredKeys = ["publisherAuditRequired"];
    for (let key of requiredKeys) {
      if (
        setting[key] === "" ||
        setting[key] === undefined ||
        setting[key] === null
      ) {
        isValid = false;
        this.setState({ errorObj: { [key]: `${key} is required` } });
        break;
      }
    }

    return isValid;
  }

  render() {
    const {
      publisherAuditRequired,
      publisherAuditRecipients,
      publisherAuditRecipientsStr,

      errorObj,
    } = this.state;

    return (
      <div className="border shadow rounded mb-4 bg-gray-200 p-4">
        <div className="border-b mb-6 mt-2 border-gray-400 text-xl font-bold text-gray-900">
          Publisher Audit
        </div>
        {/* <div>{JSON.stringify(this.state, null, 4)}</div> */}

        <div className="mb-4 flex">
          <div className="w-2/3">
            <label className={labelClass}>Is Publisher Audit Required?*</label>

            <div className="flex items-center gap-4 py-1">
              <label htmlFor={`pa_req_true`} className={radioClass}>
                <input
                  id={`pa_req_true`}
                  type="radio"
                  name={`pa_req`}
                  value={true}
                  checked={publisherAuditRequired === true}
                  onChange={(e) =>
                    this.onInputChanged(true, "publisherAuditRequired")
                  }
                  required
                />{" "}
                true
              </label>

              <label htmlFor={`pa_req_false`} className={radioClass}>
                <input
                  id={`pa_req_false`}
                  type="radio"
                  name={`pa_req`}
                  value={false}
                  checked={publisherAuditRequired === false}
                  onChange={(e) =>
                    this.onInputChanged(false, "publisherAuditRequired")
                  }
                />{" "}
                false
              </label>
            </div>
          </div>
          <div className="flex w-1/3 items-end pb-1">
            {errorObj.publisherAuditRequired && (
              <div className="w-full bg-red-100 px-2 py-1 text-sm text-red-800">
                {errorObj.publisherAuditRequired}
              </div>
            )}
          </div>
        </div>

        <div className="mb-4 flex">
          <div className="w-2/3">
            <label className={labelClass}>Publisher Audit Recipients</label>

            <textarea
              className={inputClass}
              value={publisherAuditRecipientsStr}
              onChange={(e) =>
                this.onInputChanged(
                  e.target.value,
                  "publisherAuditRecipientsStr"
                )
              }
              rows={3}
              required
            ></textarea>
            <div className="text-xs text-gray-700">
              {publisherAuditRecipients.length} emails
            </div>
          </div>
          <div className="flex w-1/3 items-end pb-1">
            {errorObj.publisherAuditRecipients && (
              <div className="w-full bg-red-100 px-2 py-1 text-sm text-red-800">
                {errorObj.publisherAuditRecipients}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default PublisherAuditSectionForm;
