import React from "react";
import _ from "lodash";
import Checkbox from "../../../common/Checkbox";
import CurrencySelector from "components/common/CurrencySelector";

class CurrencyControl extends React.PureComponent {
  constructor(props) {
    super(props);

    // props:
    // valueSchema={valueSchema}
    // inputValue={inputValue}
    // handleNewValueChanged={handleNewValueChanged}
    // handleHasError={handleHasError}

    const currency = this.props.inputValue;
    let errorMsg = null;

    this.state = {
      currency,
      errorMsg,
    };

    this.handleCurrencyChanged = this.handleCurrencyChanged.bind(this);
  }

  handleCurrencyChanged(currency) {
    this.props.handleNewValueChanged(currency);
  }

  render() {
    const { valueSchema } = this.props;
    const { currency, errorMsg } = this.state;

    return (
      <div className="border rounded ml-4 bg-white p-2 text-sm text-gray-700">
        <>
          <CurrencySelector
            selectedCurrency={currency}
            handleCurrencyChanged={this.handleCurrencyChanged}
          ></CurrencySelector>

          <div className="text-red-600">{errorMsg}</div>
        </>
      </div>
    );
  }
}

export default CurrencyControl;
