import React from "react";
import _, { first } from "lodash";
import moment from "moment-timezone";
import { calculateReportsForDemandTypeCompare } from "./AnatomyCstReportHelper";
import HighchartWrapper from "components/common/HighchartWrapper";
import Highcharts from "highcharts";
import { tooltipFormatter } from "./AnatomyHighchartHelper";
import {
  PRESETS,
  getGoogleEligibleDemandTypes,
  getPrebidDemandTypes,
} from "constants/DemandType";

const PRESET_OPTIONS = [
  PRESETS.NETWORK_BILLABLE,
  PRESETS.GOOGLE_ELIGIBLE,
  PRESETS.PREBID,
];
const METRIC_OPTIONS = ["rrpm", "str", "ecpm"];
class DemandTypeCompareChart extends React.Component {
  constructor(props) {
    super(props);

    this.ref1 = React.createRef();

    const { groupType, layerNum, metric, groupTypes } = props;

    this.state = {
      // chart
      options: null,

      // filter options
      groupTypes,
      layerNums: ["all", "1", "2"], // [all, 1, 2] or [all]
      metrics: METRIC_OPTIONS,

      // filter
      groupType,
      layerNum,
      metric,

      compareToGroupType: "b",
    };

    this.handleFilterChanged = this.handleFilterChanged.bind(this);
  }

  componentDidMount() {
    const { data, unitInfo, getFilterParams } = this.props;
    const filterParams = getFilterParams();

    const { groupType, layerNum, metric } = this.props;
    const selectedFilter = {
      ...filterParams,

      groupType,
      layerNum,
      metric,
      // layerNum: "all", // all, 1, 2
      // metric: "rrpm", // rrpm, str, ecpm
      // groupType: "o", //
      compareToGroupType: "b",
    };
    const seriesData = _createSeriesData({
      data,
      unitInfo,
      filter: selectedFilter,
    });
    const options = _createChartOptions({
      seriesData,
      timezone: unitInfo.timezone,
      filter: selectedFilter,
    });

    this.setState({ options });
  }

  handleFilterChanged({ type, value }) {
    const { layerNum, metric, groupType, compareToGroupType } = this.state;
    const { unitInfo, data } = this.props;
    const filterParams = this.props.getFilterParams();

    const selectedFilter = {
      ...filterParams,
      layerNum: type === "layerNum" ? value : layerNum,
      metric: type === "metric" ? value : metric,
      groupType: type === "groupType" ? value : groupType,
      compareToGroupType:
        type === "compareToGroupType" ? value : compareToGroupType,
    };
    const seriesData = _createSeriesData({
      data,
      unitInfo,
      filter: selectedFilter,
    });
    const options = _createChartOptions({
      seriesData,
      timezone: unitInfo.timezone,
      filter: selectedFilter,
    });

    this.setState({
      options,
      layerNum: type === "layerNum" ? value : layerNum,
      metric: type === "metric" ? value : metric,
      groupType: type === "groupType" ? value : groupType,
      compareToGroupType:
        type === "compareToGroupType" ? value : compareToGroupType,
    });
  }

  render() {
    const {
      // chart
      options,

      // filter options
      groupTypes,
      layerNums,
      metrics,

      // selected filter
      groupType,
      layerNum,
      metric,
      compareToGroupType,
    } = this.state;

    return (
      <div>
        <div>
          <div className="border mb-2 py-2 px-2">
            <div className="mb-2 flex items-center gap-2 text-sm font-semibold">
              <div style={{ width: "92px" }}>Group Types: </div>

              {groupTypes.map((item) => {
                return (
                  <button
                    key={item}
                    type="button"
                    className={`rounded px-2 py-1 font-semibold ${
                      groupType === item
                        ? "bg-indigo-100 text-indigo-800"
                        : "text-gray-700 hover:bg-gray-200 hover:text-indigo-800"
                    }`}
                    onClick={() =>
                      this.handleFilterChanged({
                        type: "groupType",
                        value: item,
                      })
                    }
                  >
                    {item}
                  </button>
                );
              })}
            </div>
            <div className="mb-2 flex items-center gap-2 text-sm font-semibold">
              <div style={{ width: "92px" }}>Compare To: </div>
              {groupTypes.map((item) => {
                return (
                  <button
                    key={item}
                    type="button"
                    className={`rounded px-2 py-1 font-semibold ${
                      compareToGroupType === item
                        ? "bg-indigo-100 text-indigo-800"
                        : "text-gray-700 hover:bg-gray-200 hover:text-indigo-800"
                    }`}
                    onClick={() =>
                      this.handleFilterChanged({
                        type: "compareToGroupType",
                        value: item,
                      })
                    }
                  >
                    {item}
                  </button>
                );
              })}
            </div>
            <div className="border-t mt-1 flex items-center gap-2 pt-1 text-sm font-semibold">
              Layer:{" "}
              <div>
                {layerNums.map((item) => {
                  return (
                    <button
                      key={item}
                      type="button"
                      className={`rounded px-2 py-1 font-semibold ${
                        layerNum === item
                          ? "bg-indigo-100 text-indigo-800"
                          : "text-gray-700 hover:bg-gray-200 hover:text-indigo-800"
                      }`}
                      onClick={() =>
                        this.handleFilterChanged({
                          type: "layerNum",
                          value: item,
                        })
                      }
                    >
                      {item}
                    </button>
                  );
                })}
              </div>
            </div>

            <div className="border-t mt-1 flex items-center gap-2 pt-1 text-sm font-semibold">
              Metric:{" "}
              <div>
                {metrics.map((item) => {
                  return (
                    <button
                      key={item}
                      type="button"
                      className={`rounded px-2 py-1 font-semibold ${
                        metric === item
                          ? "bg-pink-100 text-pink-800"
                          : "text-gray-700 hover:bg-gray-200 hover:text-pink-800"
                      }`}
                      onClick={() =>
                        this.handleFilterChanged({
                          type: "metric",
                          value: item,
                        })
                      }
                    >
                      {item}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div>
          {options && (
            <HighchartWrapper
              ref={this.ref1}
              options={options}
            ></HighchartWrapper>
          )}
        </div>
      </div>
    );
  }
}

// filter: layerNum, metric, groupType, compareToGroupType, isIncludeGhostImp
function _createSeriesData({ data, unitInfo, filter }) {
  const seriesData = _.map(PRESET_OPTIONS, (preset) => {
    let demandTypes = [];
    if (preset === PRESETS.GOOGLE_ELIGIBLE) {
      demandTypes = getGoogleEligibleDemandTypes();
    } else if (preset === PRESETS.NETWORK_BILLABLE) {
      demandTypes = unitInfo.billableDemandTypes;
    } else if (preset === PRESETS.PREBID) {
      demandTypes = getPrebidDemandTypes();
    }

    let finalData = [];
    const calculatedData = calculateReportsForDemandTypeCompare({
      reports: data.performance_report,
      aggregation: filter.aggregation,
      demandTypes,
      costCpm: unitInfo.costCpm,
      isIncludeGhostImp: filter.isIncludeGhostImp,
    });

    const groupedData = _.groupBy(calculatedData, "group_type");
    const compareData = groupedData[filter.compareToGroupType];

    finalData = _.map(groupedData[filter.groupType], (d) => {
      const c = _.find(compareData, { _time: d._time });

      return {
        x: d._time * 1000,
        y: d[`_layer${filter.layerNum}_${filter.metric}`],
        gt: filter.groupType,
        compareTo: {
          y: c[`_layer${filter.layerNum}_${filter.metric}`],
          gt: filter.compareToGroupType,
        },
      };
    });

    return {
      name: preset,
      data: finalData,
    };
  });

  return seriesData;
}

function _createChartOptions({ seriesData, timezone, filter }) {
  const timezoneOffset = -moment.tz(timezone).utcOffset();
  const { metric, layerNum } = filter;

  let min, max;
  if (metric === "str") {
    min = 0;
    max = 100;
  }

  let valueDecimalPoint = 2;
  if (metric === "req") valueDecimalPoint = 0;
  if (metric === "rrpm_per") valueDecimalPoint = 1;
  if (metric === "rrpm" || metric === "rrpm_net" || metric === "ecpm")
    valueDecimalPoint = 3;

  const chartTitle = "";
  const options = {
    title: {
      text: chartTitle,
    },
    time: {
      timezoneOffset: timezoneOffset,
    },
    chart: {
      type: "spline",
      height: 260,
      backgroundColor: "rgba(0,0,0,0)",
      zoomType: "x",
    },
    tooltip: {
      shared: true,
      useHTML: true,
      backgroundColor: "rgba(255,255,255,1)", // use full white to make looking at all those numbers easier to read
      formatter() {
        return _tooltipFormatter({
          chart: this,
          timezone,
          isSortByYValue: true,
          // valuePrefix: metric === "cost" ? "$" : "",
          valuePostfix:
            metric === "str" || metric === "rrpm_per" || metric === "cost"
              ? "%"
              : "",
          valueDecimalPoint,
        });
      },
    },
    plotOptions: {
      spline: {
        marker: {
          enabled: false,
        },
      },
    },
    yAxis: [
      {
        title: {
          text: "",
        },
        min,
        max,
      },
    ],
    xAxis: {
      ..._getXAxisConfigDateTime({ timezone }),
    },
    series: seriesData,
  };

  return options;
}

export function _tooltipFormatter({
  chart,
  timezone,
  isSortByYValue = false,
  valuePrefix = "",
  valuePostfix = "",
  valueDecimalPoint = 0,
}) {
  let sortedPoints = chart.points;
  if (isSortByYValue) {
    sortedPoints = chart.points.sort((pointA, pointB) => pointB.y - pointA.y);
  }

  return `
    <div style="text-align: center; font-size: 12px; margin-bottom: 2px;">
      ${moment.tz(chart.x, timezone).format("YYYY-MM-DD HH:mm")}
    </div>

    <div>
      <table style="margin: 0 auto;">
        <tbody>
          ${sortedPoints
            .map((point) => {
              const compareTo = point.point.compareTo;
              const diff = _.round(point.y - compareTo.y, valueDecimalPoint);
              const isWin = diff >= 0;
              return `
                <tr>
                  <td>
                    <span style="color: ${point.color};">\u25CF</span>
                  </td>
                  <td style="text-align: right; padding-right: 3px;">
                    ${point.series.name}:
                  </td>
                  
                  <td style="text-align: right; font-weight: 600;">
                    <span style="font-weight: ${isWin ? 600 : 400};">
                      (${point.point.gt}) 
                      <span style="font-weight: 400; color: 'light-gray';">${
                        point.point.prefix || valuePrefix
                      }</span>
                      ${Highcharts.numberFormat(point.y, valueDecimalPoint)} 
                        <span style="font-weight: 400; color: 'light-gray';">${
                          point.point.postfix || valuePostfix
                        }</span>
                      </span>
                    </span>
                  </td>
                  <td style="text-align: center; font-weight: 500; color: 'gray'; padding-left: 2px; padding-right: 2px;">
                    vs 
                  </td>
                  <td style="text-align: right; font-weight: 600;">
                    <span style="font-weight: ${!isWin ? 600 : 400};">
                      (${compareTo.gt}) 
                      <span style="font-weight: 400; color: 'light-gray';">${
                        point.point.prefix || valuePrefix
                      }</span>
                      ${Highcharts.numberFormat(compareTo.y, valueDecimalPoint)}
                      <span style="font-weight: 400; color: 'light-gray';">${
                        point.point.postfix || valuePostfix
                      }</span>
                    </span>
                  </td>
                  <td style="text-align: right; font-weight: 400;">
                    <span style="color: ${isWin ? "green" : "red"}">
                      (${isWin ? "+" : ""}${diff})
                    </span>
                  </td>
                </tr>
              `;
            })
            .join("")}
        </tbody>
      </table>
    </div>
  `;
}

function _getXAxisConfigDateTime({ timezone }) {
  return {
    type: "datetime",
    labels: {
      formatter: function () {
        const m = moment(this.value).tz(timezone);
        const d = m.format("DD");
        // const d = m.format("MM/DD");
        const wd = m.format("ddd");
        const label = `${d} <br/> ${wd}`;
        if (d === "01" || this.isFirst) {
          return `${d} <br/> ${wd} <br/> ${m.format("M")}`;
        }
        // if (isWeekend(this.value)) {
        //   return `<span style="color: #dd6b20;">${label}</span>`;
        // }
        return label;
      },
    },
    tickInterval: 24 * 60 * 60 * 1000, // 1 day
    crosshair: true,
  };
}

export default DemandTypeCompareChart;
