import React from "react";

const UNIT_STATE = {
  0: "Unknown",
  1: "Wait for initialization",
  2: "Inspection",
  3: "Normal optimization",
  4: "Suspicious optimization",
  5: "Pause optimization",
  6: "Hibernation",
  7: "Debug",
  8: "Detention",
  9: "Negative optimization",
};

class UnitState extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { state } = this.props;
    return <span className="px-2 bg-gray-300">{UNIT_STATE[state]}</span>;

    // switch (state) {
    //   case 1: {
    //     return (
    //       <>
    //         <span
    //           role="img"
    //           aria-label={unit.mode}
    //           title="Boosting"
    //           style={{ fontSize: "12px" }}
    //         >
    //           🚀
    //         </span>
    //         {showName && "Boosting"}
    //       </>
    //     );
    //   }
    //   default: {
    //     return null;
    //   }
    // }
  }
}

export default UnitState;
