import React from "react";
import numeral from "numeral";

class NumberFormat extends React.PureComponent {
  render() {
    const { value, prefix, postfix, format } = this.props;

    function formatValue(value) {
      return numeral(value).format(format || `0,0.00`);
    }

    return (
      <>
        {prefix && prefix}
        {formatValue(value)}
        {postfix && postfix}
      </>
    );
  }
}
export default NumberFormat;
