import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import HighchartChart from "../../common/HighchartChart";

const metricsMap = {
  eligibleEcpm: {
    key: "eligibleEcpm",
    title: "eCPM*",
    yAxisTitle: "$ eCPM*",
    calculate: value => {
      return _.round(value, 2);
    },
    transform: value => {
      return `$${value}`;
    }
  },
  eligibleRpm: {
    key: "eligibleRpm",
    title: "rRPM*",
    yAxisTitle: "$ rRPM*",
    calculate: value => {
      return _.round(value, 2);
    },
    transform: value => {
      return `$${value}`;
    }
  },
  eligibleStr: {
    key: "eligibleStr",
    title: "STR*",
    yAxisTitle: "Percentage %",
    yAxisMin: 0,
    yAxisMax: 100,
    calculate: value => {
      return _.round(value * 100, 2);
    },
    transform: value => {
      return `${value}%`;
    }
  }
};

class TrendRequestByGroupType extends React.Component {
  constructor(props) {
    super(props);

    const defaultMetric = props.metric;

    this.state = {
      selectedMetric: defaultMetric,
      options: createChartOptions(props.reports, defaultMetric)
    };

    this.handleSelectMetric = this.handleSelectMetric.bind(this);
  }

  handleSelectMetric(metricKey) {
    this.setState({
      selectedMetric: metricKey,
      options: createChartOptions(this.props.reports, metricKey)
    });
  }

  render() {
    const { options } = this.state;

    return <HighchartChart options={options}></HighchartChart>;
  }
}

function createSeriesData(reports, metric) {
  let reportsMap = _(reports)
    .groupBy(r => {
      return r.date + "_" + r.groupType;
    })
    .mapValues(rr => _.first(rr))
    .value();

  const metricCalculate = metricsMap[metric].calculate;
  const metricTransform = metricsMap[metric].transform;

  // let discrepancySeries = [];
  const metricSeries = _(reports)
    .groupBy("groupType")
    .reduce((result, reports, groupType) => {
      const series = _.map(reports, r => {
        // only calculate discrepancy in unmanged report
        if (groupType === "unmanaged") {
          // get benchmark value to calculate discrepancy
          let bValue = _.get(reportsMap, [`${r.date}_benchmark`, metric]);
          let uValue = r[metric];
          bValue = metricCalculate ? metricCalculate(bValue) : bValue;
          uValue = metricCalculate ? metricCalculate(uValue) : uValue;

          const discrepancy = _.round(bValue - uValue, 2);

          // (benchmark - unmanaged) / (ummanaged) to "unmanaged"
          const discrepancyPercentage = _.round(
            ((bValue - uValue) / uValue) * 100,
            2
          );

          // discrepancySeries.push({
          //   x: new Date(r.date).getTime(),
          //   y: discrepancyPercentage,
          //   color: discrepancyPercentage > 0 ? "#9ae6b4" : "#feb2b2",

          //   discrepancyPercentageLabel: `${discrepancyPercentage}%`,
          //   discrepancyLabel: `${discrepancy}`
          // });

          const y = metricCalculate ? metricCalculate(r[metric]) : r[metric];
          return {
            x: new Date(r.date).getTime(),
            y,
            tooltipLabel: metricTransform ? metricTransform(y) : y,
            // discrepancy label in tooltip
            discrepancyPercentageLabel: _.isNaN(discrepancyPercentage)
              ? null
              : `${discrepancyPercentage}%`,
            discrepancyLabel: _.isNaN(discrepancy)
              ? null
              : `(${discrepancy > 0 ? "+" : ""}${discrepancy})`
          };
        }

        const y = metricCalculate ? metricCalculate(r[metric]) : r[metric];
        return {
          x: new Date(r.date).getTime(),
          y,
          tooltipLabel: metricTransform ? metricTransform(y) : y
        };
      });

      result.push({
        name: groupType,
        data: series,
        color: getGroupTypeStyle(groupType)
      });

      return result;
    }, []);

  // return metricSeries;
  return [
    ...metricSeries
    // {
    //   name: "discrepancy",
    //   data: discrepancySeries,
    //   yAxis: 1,
    //   type: "column",
    //   maxPointWidth: 12,
    //   showInLegend: false
    // }
  ];
}

function createChartOptions(reports, metric) {
  const metricOptions = metricsMap[metric];
  const metricTitle = metricOptions.title;
  const metricYAxisTitle = metricOptions.yAxisTitle;
  const metricYAxisMin = metricOptions.yAxisMin;
  const metricYAxisMax = metricOptions.yAxisMax;

  const seriesData = createSeriesData(reports, metric);
  const options = {
    title: {
      text: metricTitle
    },
    chart: {
      type: "line",
      zoomType: "x",
      height: 260,
      backgroundColor: "rgba(0,0,0,0)"
    },
    tooltip: {
      xDateFormat: "%Y/%m/%d %H:%M",
      formatter: function() {
        const headFormat = `<b>${moment(this.x).format("YYYY-MM-DD")}</b><br/>`;
        let uPointFormat = "";
        let bPointFormat = "";
        let dPointFormat = "";
        for (let point of this.points) {
          switch (point.series.name) {
            case "unmanaged": {
              const p = point.point.tooltipLabel
                ? point.point.tooltipLabel
                : point.y;
              uPointFormat = `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${p}</b><br/>`;

              if (point.point.discrepancyPercentageLabel) {
                dPointFormat = `<span style="margin-top: 8px;">discrepancy</span><br/>
                <span style="font-size: larger; font-weight: bold;">${point.point.discrepancyPercentageLabel}</span> ${point.point.discrepancyLabel}<br/>`;
              }
              break;
            }

            case "benchmark": {
              const p = point.point.tooltipLabel
                ? point.point.tooltipLabel
                : point.y;
              bPointFormat = `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${p}</b><br/>`;
              break;
            }

            // case "discrepancy": {
            //   dPointFormat = `<span style="margin-top: 8px;"><span style="color:${
            //     point.color
            //   }">\u25CF</span> discrepancy</span><br/>
            //     <span style="font-size: larger; font-weight: bold;">${
            //       point.point.discrepancyPercentageLabel
            //     }</span> (${point.point.discrepancyLabel > 0 ? "+" : ""}${
            //     point.point.discrepancyLabel
            //   })<br/>`;
            // }

            default: {
              //
            }
          }
        }

        const format = `
          ${headFormat}
          ${dPointFormat}
          ${dPointFormat ? "<span>---------------</span><br/>" : ""}
          ${bPointFormat}
          ${uPointFormat}
          `;

        return format;
      },
      shared: true,
      crosshairs: true
    },
    xAxis: getXAxisConfigDateTime(),
    yAxis: [
      {
        title: {
          text: metricYAxisTitle
        },
        min: metricYAxisMin,
        max: metricYAxisMax
        // height: "70%"
      }
      // {
      //   title: {
      //     text: "%"
      //   },
      //   height: "30%",
      //   top: "70%",
      //   offset: 0,
      //   opposite: true,
      //   min: -30,
      //   max: 30,
      //   plotLines: [
      //     {
      //       value: 0,
      //       color: "lightgray"
      //     }
      //   ]
      // }
    ],
    series: seriesData
  };

  return options;
}

function getGroupTypeStyle(groupType) {
  switch (groupType) {
    case "benchmark": {
      return "#9593FF";
    }
    case "optimization": {
      return "#BBE8D0";
    }
    case "unmanaged": {
      return "#8e8e8e";
    }
    default: {
      // nothin
    }
  }
}

function getXAxisConfigDateTime() {
  return {
    type: "datetime",
    labels: {
      formatter: function() {
        const m = moment(this.value);
        const d = m.format("DD");
        // const d = m.format("MM/DD");
        const wd = m.format("ddd");
        const label = `${d} <br/> ${wd}`;
        if (d === "01" || this.isFirst) {
          return `${d} <br/> ${wd} <br/> ${m.format("M")}`;
        }
        // if (isWeekend(this.value)) {
        //   return `<span style="color: #dd6b20;">${label}</span>`;
        // }
        return label;
      }
    },
    tickInterval: 24 * 60 * 60 * 1000, // 1 day
    crosshair: true
  };
}

export default TrendRequestByGroupType;
