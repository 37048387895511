import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import Highcharts from "highcharts";
import { calculateReportsForDemandTypeCompare } from "./AnatomyCstReportHelper";
import HighchartWrapper from "components/common/HighchartWrapper";
import {
  getGroupTypeColor,
  getGroupTypeSeriesOptions,
} from "./AnatomyHighchartHelper";
import {
  PRESETS,
  getGoogleEligibleDemandTypes,
  getPrebidDemandTypes,
} from "constants/DemandType";

const PRESET_OPTIONS = [
  PRESETS.NETWORK_BILLABLE,
  PRESETS.GOOGLE_ELIGIBLE,
  PRESETS.PREBID,
  "Billable House Only",
  "Prebid without Billable House",
];
const METRIC_OPTIONS = ["rrpm", "str", "ecpm"];
class DetectorMetricChart extends React.Component {
  constructor(props) {
    super(props);

    this.ref1 = React.createRef();
    this.ref2 = React.createRef();

    const { datetime, groupType, layerNum, metric } = props;

    this.state = {
      // chart
      options: null,
      reqOptions: null,

      // filter options
      layerNums: ["all", 1, 2], // [all, 1, 2] or [all]
      metrics: METRIC_OPTIONS,
      demandTypePresets: PRESET_OPTIONS,

      datetime,
      groupType,
      layerNum,
      metric,
      // demandTypePreset, // get from filterParams
    };

    this.handleFilterChanged = this.handleFilterChanged.bind(this);
  }

  componentDidMount() {
    const { data, unitInfo, getFilterParams } = this.props;
    const filterParams = getFilterParams();

    const { datetime, groupType, layerNum, metric } = this.props;
    const selectedFilter = {
      ...filterParams,

      groupType,
      layerNum,
      metric,
      datetime,
      demandTypePreset: filterParams.demandTypePreset,
    };
    const seriesData = _createSeriesData({
      data,
      unitInfo,
      filter: selectedFilter,
    });
    const reqSeriesData = _createSeriesData({
      type: "req",
      data,
      unitInfo,
      filter: selectedFilter,
    });
    const options = _createChartOptions({
      seriesData,
      timezone: unitInfo.timezone,
      filter: selectedFilter,
    });
    const reqOptions = _createReqChartOptions({
      seriesData: reqSeriesData,
      timezone: unitInfo.timezone,
      filter: selectedFilter,
    });

    this.setState({
      options,
      reqOptions,
      demandTypePreset: filterParams.demandTypePreset,
      bValue: options._bValue,
      oValue: options._oValue,
      xValue: options._xValue,
    });
  }

  handleFilterChanged({ type, value }) {
    const { data, unitInfo, getFilterParams } = this.props;
    const filterParams = getFilterParams();
    const { datetime, layerNum, metric, groupType, demandTypePreset } =
      this.state;

    const selectedFilter = {
      ...filterParams,
      datetime,
      layerNum: type === "layerNum" ? value : layerNum,
      metric: type === "metric" ? value : metric,
      groupType: type === "groupType" ? value : groupType,
      demandTypePreset: type === "demandTypePreset" ? value : demandTypePreset,
    };
    const seriesData = _createSeriesData({
      data,
      unitInfo,
      filter: selectedFilter,
    });
    const reqSeriesData = _createSeriesData({
      type: "req",
      data,
      unitInfo,
      filter: selectedFilter,
    });
    const options = _createChartOptions({
      seriesData,
      timezone: unitInfo.timezone,
      filter: selectedFilter,
    });
    const reqOptions = _createReqChartOptions({
      seriesData: reqSeriesData,
      timezone: unitInfo.timezone,
      filter: selectedFilter,
    });

    this.setState({
      options,
      reqOptions,
      layerNum: type === "layerNum" ? value : layerNum,
      metric: type === "metric" ? value : metric,
      groupType: type === "groupType" ? value : groupType,
      demandTypePreset: type === "demandTypePreset" ? value : demandTypePreset,
      bValue: options._bValue,
      oValue: options._oValue,
      xValue: options._xValue,
    });
  }

  render() {
    const { unitInfo } = this.props;
    const {
      // chart
      options,
      reqOptions,

      // filter options
      groupTypes,
      layerNums,
      metrics,
      demandTypePresets,

      // selected filter
      layerNum,
      metric,
      demandTypePreset,
      datetime,

      //
      bValue,
      oValue,
      xValue,
    } = this.state;

    const datetimeStr = moment
      .tz(datetime, unitInfo.timezone)
      .format("YYYY-MM-DD HH:mm");

    return (
      <div>
        <div>
          <div className="border mb-2 py-2 px-2">
            <div className="mb-2 flex items-center gap-2 text-sm font-semibold">
              Datetime: {datetimeStr}
            </div>
            <div className="border-t mt-1 flex items-center gap-2 pt-1 text-sm font-semibold">
              Layer:{" "}
              <div>
                {layerNums.map((item) => {
                  return (
                    <button
                      key={item}
                      type="button"
                      className={`rounded px-2 py-1 font-semibold ${
                        layerNum === item
                          ? "bg-indigo-100 text-indigo-800"
                          : "text-gray-700 hover:bg-gray-200 hover:text-indigo-800"
                      }`}
                      onClick={() =>
                        this.handleFilterChanged({
                          type: "layerNum",
                          value: item,
                        })
                      }
                    >
                      {item}
                    </button>
                  );
                })}
              </div>
            </div>

            <div className="border-t mt-1 flex items-center gap-2 pt-1 text-sm font-semibold">
              Metric:{" "}
              <div>
                {metrics.map((item) => {
                  return (
                    <button
                      key={item}
                      type="button"
                      className={`rounded px-2 py-1 font-semibold ${
                        metric === item
                          ? "bg-pink-100 text-pink-800"
                          : "text-gray-700 hover:bg-gray-200 hover:text-pink-800"
                      }`}
                      onClick={() =>
                        this.handleFilterChanged({
                          type: "metric",
                          value: item,
                        })
                      }
                    >
                      {item}
                    </button>
                  );
                })}
              </div>
            </div>
            <div className="border-t mt-1 flex items-center gap-2 pt-1 text-sm font-semibold">
              Demand Type Groups:{" "}
              <div>
                {demandTypePresets.map((item) => {
                  return (
                    <button
                      key={item}
                      type="button"
                      className={`rounded px-2 py-1 font-semibold ${
                        demandTypePreset === item
                          ? "bg-pink-100 text-pink-800"
                          : "text-gray-700 hover:bg-gray-200 hover:text-pink-800"
                      }`}
                      onClick={() =>
                        this.handleFilterChanged({
                          type: "demandTypePreset",
                          value: item,
                        })
                      }
                    >
                      {item}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        {bValue !== undefined && (
          <div className="flex items-center justify-center gap-4 bg-gray-300 px-4 font-medium text-gray-900">
            <div>
              b: {metric !== "str" && "$"}
              {bValue}
              {metric === "str" && "%"}
            </div>
            <div>
              o: {metric !== "str" && "$"}
              {oValue}
              {metric === "str" && "%"}
            </div>
            {xValue !== null && xValue !== undefined && (
              <div>
                x: {metric !== "str" && "$"}
                {xValue}
                {metric === "str" && "%"}
              </div>
            )}
          </div>
        )}

        <div>
          {options && (
            <HighchartWrapper
              ref={this.ref1}
              options={options}
            ></HighchartWrapper>
          )}
        </div>
        <div>
          {reqOptions && (
            <HighchartWrapper
              ref={this.ref2}
              options={reqOptions}
            ></HighchartWrapper>
          )}
        </div>
      </div>
    );
  }
}

// filter: layerNum, metric, groupType
// type: if "req" -> for tiny req chart
function _createSeriesData({ type, data, unitInfo, filter }) {
  // only one line
  // xAxis: detectors' prices
  // yAxis: metric value
  const detectorPricesData = _(data.floor_price_report)
    .filter((r) => {
      return (
        r.upr_segment_id === filter.segmentId &&
        r.time * 1000 === filter.datetime
        // filter out "b" later
        //  && r.price !== -1 // b = -1
      );
    })
    .map((r) => {
      return {
        price: r.price,
        groupType: r.group_type,
      };
    })
    .sortBy("price")
    .value();

  let demandTypes = [];
  if (filter.demandTypePreset === PRESETS.GOOGLE_ELIGIBLE) {
    demandTypes = getGoogleEligibleDemandTypes();
  } else if (filter.demandTypePreset === PRESETS.NETWORK_BILLABLE) {
    demandTypes = unitInfo.billableDemandTypes;
  } else if (filter.demandTypePreset === PRESETS.PREBID) {
    demandTypes = getPrebidDemandTypes();
  } else if (filter.demandTypePreset === "Billable House Only") {
    demandTypes = ["Billable House"];
  } else if (filter.demandTypePreset === "Prebid without Billable House") {
    demandTypes = _.filter(getPrebidDemandTypes(), (t) => {
      return t !== "Billable House";
    });
  }

  let finalData = [];
  const calculatedData = calculateReportsForDemandTypeCompare({
    reports: data.performance_report,
    aggregation: filter.aggregation,
    demandTypes,
    costCpm: unitInfo.costCPMInCurrency,
    isIncludeGhostImp: filter.isIncludeGhostImp,
  });
  const dataOfDay = _.filter(
    calculatedData,
    (r) => r._time * 1000 === filter.datetime
  );
  const groupedData = _.keyBy(dataOfDay, "group_type");
  const numOfDetectors = _.sumBy(_.keys(groupedData), (gt) => {
    return _.startsWith(gt, "d") ? 1 : 0;
  });

  // for req chart
  if (type === "req") {
    const reqData = _.map(detectorPricesData, (pData) => {
      return {
        x: pData.price,
        y: groupedData[pData.groupType][
          `_layer${filter.layerNum === 2 ? "2" : "1"}_req`
        ],
        yAxis: 1,
        groupType: pData.groupType,
        color: getGroupTypeColor(pData.groupType, numOfDetectors),
      };
    });
    return [
      {
        name: "req",
        color: "#5b5b5b",
        data: _.filter(
          reqData,
          (d) =>
            d.groupType !== "b" && d.groupType !== "o" && d.groupType !== "x"
        ),
        dataLabels: [
          {
            format: "{point.groupType}",
          },
        ],
      },
    ];
  } else {
    finalData = _.map(detectorPricesData, (pData) => {
      const mKey = `_layer${filter.layerNum}_${filter.metric}`;
      return {
        x: pData.price,
        y: groupedData[pData.groupType][mKey],
        groupType: pData.groupType,
        color: getGroupTypeColor(pData.groupType, numOfDetectors),
      };
    });

    return [
      {
        name: filter.metric,
        data: finalData,
        dataLabels: [
          {
            format: "{point.groupType}",
          },
        ],
      },
    ];
  }
}

function _createChartOptions({ seriesData, timezone, filter }) {
  const timezoneOffset = -moment.tz(timezone).utcOffset();
  const { metric, layerNum } = filter;

  const finalData = _.get(seriesData, [0, "data"], {});
  const bData = _.find(finalData, { groupType: "b" }) || {};
  const oData = _.find(finalData, { groupType: "o" }) || {};
  const xData = _.find(finalData, { groupType: "x" }) || {};

  let min, max;
  if (metric === "str") {
    min = 0;
    max = 100;
  } else {
    min = _.get(_.minBy(finalData, "y"), "y");
    max = _.get(_.maxBy(finalData, "y"), "y");
  }

  // filter out "b" and "o" and "x"
  seriesData[0].data = _.filter(finalData, (d) => {
    return d.groupType !== "b" && d.groupType !== "o" && d.groupType !== "x";
  });

  let valueDecimalPoint = 2;
  if (metric === "req") valueDecimalPoint = 0;
  if (metric === "rrpm_per") valueDecimalPoint = 1;
  if (metric === "rrpm" || metric === "rrpm_net" || metric === "ecpm")
    valueDecimalPoint = 3;

  let plotLines = [];
  if (layerNum !== 2) {
    plotLines = [
      {
        color: getGroupTypeColor("b", 10),
        ...getGroupTypeSeriesOptions("b"),
        value: bData.y,
        label: {
          text: `b ${bData.y}`,
          align: "right",
        },
      },
      {
        color: getGroupTypeColor("o", 10),
        ...getGroupTypeSeriesOptions("o"),
        value: oData.y,
        label: {
          text: `o ${oData.y}`,
          align: "right",
        },
      },
      {
        color: getGroupTypeColor("x", 10),
        ...getGroupTypeSeriesOptions("x"),
        value: xData.y,
        label: {
          text: `x ${xData.y}`,
          align: "right",
        },
      },
    ];
  }

  const chartTitle = "";
  const options = {
    _bValue: bData.y,
    _oValue: oData.y,
    _xValue: xData.y,
    title: {
      text: chartTitle,
    },
    time: {
      timezoneOffset: timezoneOffset,
    },
    chart: {
      type: "line",
      height: 260,
      backgroundColor: "rgba(0,0,0,0)",
      zoomType: "x",
    },
    tooltip: {
      shared: true,
      useHTML: true,
      backgroundColor: "rgba(255,255,255,1)", // use full white to make looking at all those numbers easier to read
      formatter() {
        return _tooltipFormatter({
          chart: this,
          timezone,
          isSortByYValue: true,
          // valuePrefix: metric === "cost" ? "$" : "",
          valuePostfix:
            metric === "str" || metric === "rrpm_per" || metric === "cost"
              ? "%"
              : "",
          valueDecimalPoint,
        });
      },
    },
    plotOptions: {
      line: {
        marker: {
          enabled: true,
        },
      },
      series: {
        dataLabels: {
          enabled: true,
          allowOverlap: true,
        },
      },
    },
    yAxis: [
      {
        title: {
          text: "",
        },
        min,
        max,
        plotLines,
      },
    ],
    xAxis: {},
    series: seriesData,
  };

  return options;
}

function _createReqChartOptions({ seriesData, timezone }) {
  const finalData = _.get(seriesData, [0, "data"]);

  // filter out "b" and "o" and "x"
  seriesData[0].data = _.filter(finalData, (d) => {
    return d.groupType !== "b" && d.groupType !== "o";
  });

  let valueDecimalPoint = 0;

  const chartTitle = "";
  const options = {
    title: {
      text: chartTitle,
    },
    chart: {
      type: "line",
      height: 130,
      backgroundColor: "rgba(0,0,0,0)",
      zoomType: "x",
    },
    tooltip: {
      shared: true,
      useHTML: true,
      backgroundColor: "rgba(255,255,255,1)", // use full white to make looking at all those numbers easier to read
      formatter() {
        return _tooltipFormatter({
          chart: this,
          timezone,
          isSortByYValue: true,
          valueDecimalPoint,
        });
      },
    },
    plotOptions: {
      line: {
        marker: {
          enabled: true,
        },
      },
      series: {
        dataLabels: {
          enabled: true,
          allowOverlap: true,
        },
      },
    },
    yAxis: [
      {
        title: {
          text: "",
        },
      },
    ],
    xAxis: {},
    series: seriesData,
  };

  return options;
}

export function _tooltipFormatter({
  chart,
  isSortByYValue = false,
  valuePrefix = "",
  valuePostfix = "",
  valueDecimalPoint = 0,
}) {
  let sortedPoints = chart.points;
  if (isSortByYValue) {
    sortedPoints = chart.points.sort((pointA, pointB) => pointB.y - pointA.y);
  }
  const groupType = chart.points[0].point.groupType;
  return `
    <div style="text-align: center; font-size: 12px; margin-bottom: 2px;">
      ${groupType}
    </div>
    
    <div>
      <table style="margin: 0 auto;">
        <tbody>
          ${sortedPoints
            .map((point) => {
              const isOB =
                point.series.name === "b" ||
                point.series.name === "o" ||
                point.series.name === "x";
              return `
                <tr>
                  <td>
                    <span style="color: ${point.color};">\u25CF</span>
                  </td>
                  <td style="text-align: right; padding-right: 3px; ${
                    isOB && `font-weight: 600;`
                  }">
                    <span style="${isOB && "border-bottom: 1px solid black;"}">
                      ${point.series.name}
                    </span>:
                  </td>
                  <td style="text-align: right; font-weight: 600;">
                    <span style="${isOB && "border-bottom: 1px solid black;"}">
                      <span style="font-weight: 400; color: 'light-gray';">${
                        point.point.prefix || valuePrefix
                      }</span>
                      ${Highcharts.numberFormat(point.y, valueDecimalPoint)}
                      <span style="font-weight: 400; color: 'light-gray';">${
                        point.point.postfix || valuePostfix
                      }</span>
                    </span>
                  </td>
                </tr>
              `;
            })
            .join("")}
        </tbody>
      </table>
    </div>
  `;
}

export default DetectorMetricChart;
