import React from "react";
import _ from "lodash";
import Select from "react-select";
import { IoIosArrowDown } from "react-icons/io";
import PopupWrapper from "../../common/PopupWrapper";
import ReactTooltip from "react-tooltip";
import { DATE_RANGE_TYPE } from "./DateHelper";
import DateRangePopover from "./DateRangePopover";

const fixedWidthStyle = { maxWidth: "1280px", margin: "auto" };

class YbMockupHomeTopFilterHeader extends React.Component {
  constructor(props) {
    super(props);

    const dateRangeType = DATE_RANGE_TYPE.THIS_MONTH;
    const { startDate, endDate } = props;

    this.state = {
      dateRangeType,
      startDate,
      endDate,
    };

    this.child = React.createRef();
    this.handleClosePopover = this.handleClosePopover.bind(this);
  }

  handleClosePopover() {
    this.child.current.handleClosePopup();
  }

  render() {
    const {
      isReseller,
      publishers,
      selectedPubId,
      networks,
      selectedNetworkId,
      handleInventoryFilterChanged,
      yieldSets,
      selectedYieldSetIds,
      selectedCurrency,
      currencies,
      handleCurrencyChanged,
      startDate,
      endDate,
    } = this.props;
    return (
      <>
        <div className="bg-gray-200 px-12 py-2">
          <div className="flex justify-between" style={fixedWidthStyle}>
            <div className="flex">
              <InventoryFilter
                isReseller={isReseller}
                publishers={publishers}
                selectedPubId={selectedPubId}
                networks={networks}
                selectedNetworkId={selectedNetworkId}
                selectedYieldSetIds={selectedYieldSetIds}
                handleInventoryFilterChanged={handleInventoryFilterChanged}
                yieldSets={yieldSets}
              ></InventoryFilter>

              <div className="mx-2">
                <PopupWrapper
                  ref={this.child}
                  place="bottom left"
                  triggerType="click"
                  hideArrow={false}
                  triggerElement={
                    <div className="border rounded hover:shadow focus:outline-none inline-flex cursor-pointer items-center border-gray-400 bg-white px-4 py-2 text-sm text-gray-700">
                      <span className="mr-4">
                        <span className="text-sm text-gray-600">
                          From <b className="text-gray-700">{startDate}</b> to{" "}
                          <b className="text-gray-700">{endDate}</b>
                        </span>
                      </span>

                      <IoIosArrowDown></IoIosArrowDown>
                    </div>
                  }
                  popupElement={
                    <DateRangePopover
                      startDate={startDate}
                      endDate={endDate}
                      handleClosePopover={this.handleClosePopover}
                      handleDateRangeFilterChanged={
                        this.props.handleDateRangeFilterChanged
                      }
                    ></DateRangePopover>
                  }
                ></PopupWrapper>
              </div>
            </div>

            <CurrencySelector
              currencies={currencies}
              selectedCurrency={selectedCurrency}
              handleCurrencyChanged={handleCurrencyChanged}
            ></CurrencySelector>
          </div>
        </div>
      </>
    );
  }
}

class CurrencySelector extends React.PureComponent {
  constructor(props) {
    super(props);

    const options = _.map(props.currencies, (c) => {
      return { value: c, label: c };
    });

    const selectedValue = _.find(options, { value: props.selectedCurrency });

    this.state = {
      options,
      selectedValue,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(option) {
    this.setState({
      selectedValue: option,
    });

    this.props.handleCurrencyChanged(option.value);
  }

  render() {
    const { options, selectedValue } = this.state;

    return (
      <>
        {/* <div className="inline-flex items-center px-4 py-2 border border-gray-400 rounded hover:shadow text-sm text-gray-700 bg-white cursor-pointer focus:outline-none">
          <span className="mr-2">USD</span>
          <IoIosArrowDown></IoIosArrowDown>
        </div> */}
        <Select
          className="z-50 w-24"
          defaultValue={selectedValue}
          value={selectedValue}
          onChange={this.handleChange}
          options={options}
          isSearchable={false}
          // styles={{
          //   control: () => ({
          //     paddingLeft: 3,
          //   }),
          // }}
        />
      </>
    );
  }
}

class InventoryFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};

    this.child = React.createRef();
    this.handleClosePopover = this.handleClosePopover.bind(this);
  }

  handleClosePopover() {
    this.child.current.handleClosePopup();
  }

  render() {
    const {
      isReseller,
      publishers,
      selectedPubId,

      networks,
      selectedNetworkId,
      selectedYieldSetIds,
      handleInventoryFilterChanged,
      yieldSets,
    } = this.props;

    let selectorText = "";

    if (selectedPubId === -1) {
      selectorText = <b>All publishers</b>;
    } else if (selectedNetworkId === -1) {
      if (selectedPubId !== -1) {
        const pName = _.find(publishers, { id: selectedPubId }).name;
        selectorText = (
          <span>
            Publisher: <b>{pName}</b>
          </span>
        );
      } else {
        selectorText = <b>All networks</b>;
      }
    } else if (selectedYieldSetIds !== -1 && selectedNetworkId !== -1) {
      const nName = _.find(networks, { id: selectedNetworkId }).name;
      let yss = _.filter(yieldSets, (y) => {
        return _.indexOf(selectedYieldSetIds, y.id) !== -1;
      });
      let yNames = _.map(yss, "name").join(", ");
      selectorText = (
        <span>
          Network: <b style={{ marginRight: "4px" }}>{nName}</b> Yield Sets(
          {yss.length}): <b>{yNames}</b>
        </span>
      );
    } else if (selectedYieldSetIds === -1 && selectedNetworkId !== -1) {
      const nName = _.find(networks, { id: selectedNetworkId }).name;
      selectorText = (
        <span>
          Network: <b>{nName}</b>
        </span>
      );
    }

    return (
      <>
        <PopupWrapper
          ref={this.child}
          place="bottom left"
          triggerType="click"
          hideArrow={false}
          triggerElement={
            <button
              type="button"
              style={{ width: "600px" }}
              className="border rounded hover:shadow focus:outline-none inline-flex cursor-pointer items-center border-gray-400 bg-white px-4 py-2 text-sm text-gray-700"
            >
              <span className="truncate mr-2">{selectorText}</span>
              <IoIosArrowDown></IoIosArrowDown>
            </button>
          }
          popupElement={
            <InventoryFilterPopup
              isReseller={isReseller}
              publishers={publishers}
              selectedPubId={selectedPubId}
              networks={networks}
              selectedNetworkId={selectedNetworkId}
              selectedYieldSetIds={selectedYieldSetIds}
              handleInventoryFilterChanged={handleInventoryFilterChanged}
              yieldSets={yieldSets}
              handleClosePopover={this.handleClosePopover}
            ></InventoryFilterPopup>
          }
        ></PopupWrapper>
      </>
    );
  }
}

class InventoryFilterPopup extends React.PureComponent {
  constructor(props) {
    super(props);

    const {
      isReseller,
      publishers,
      selectedPubId,
      networks,
      selectedNetworkId,
      selectedYieldSetIds,
    } = props;

    // filter networks
    let networkOptions = null;
    if (selectedPubId !== -1) {
      networkOptions = _.filter(this.props.networks, {
        pubId: selectedPubId,
      });
    }

    // filter yieldsets
    let yieldSetOptions = null;
    if (selectedNetworkId !== -1) {
      yieldSetOptions = _.filter(this.props.yieldSets, {
        gamNetworkId: selectedNetworkId,
      });
    }

    this.state = {
      isReseller,
      publisherOptions: publishers,
      selectedPubId,

      networkOptions,
      selectedNetworkId,
      yieldSetOptions,
      selectedYieldSetIds,
    };

    this.handlePublisherSelected = this.handlePublisherSelected.bind(this);
    this.handleNetworkSelected = this.handleNetworkSelected.bind(this);
    this.handleYieldSetSelected = this.handleYieldSetSelected.bind(this);
    this.handleSelectAllYieldSets = this.handleSelectAllYieldSets.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleApplyFilter = this.handleApplyFilter.bind(this);
  }

  handlePublisherSelected(pubId) {
    if (pubId === -1) {
      this.setState({
        selectedPubId: pubId,
        selectedNetworkId: -1,
        selectedYieldSetIds: -1,
      });
    } else {
      // filter networks
      const filteredNetworks = _.filter(this.props.networks, {
        pubId,
      });
      const networkOptions = filteredNetworks;
      let selectedNetworkId = -1;
      // if only one network, auto select that network
      if (networkOptions.length === 1) {
        selectedNetworkId = networkOptions[0].id;
      }
      this.setState({
        selectedPubId: pubId,
        networkOptions,
        selectedNetworkId,
        selectedYieldSetIds: -1,
      });
    }
  }

  handleNetworkSelected(networkId) {
    if (networkId === -1) {
      this.setState({
        selectedNetworkId: networkId,
        selectedYieldSetIds: -1,
      });
    } else {
      // filter yieldsets
      const filteredYieldSets = _.filter(this.props.yieldSets, {
        gamNetworkId: networkId,
      });
      const yieldSetOptions = filteredYieldSets;
      this.setState({
        selectedNetworkId: networkId,
        selectedYieldSetIds: -1,
        yieldSetOptions,
      });
    }
  }

  handleYieldSetSelected(ysId) {
    if (ysId === -1) {
      return this.setState({ selectedYieldSetIds: -1 });
    }

    const { selectedYieldSetIds } = this.state;
    let ysIds =
      _.isNil(selectedYieldSetIds) || selectedYieldSetIds === -1
        ? []
        : [...selectedYieldSetIds];
    if (_.indexOf(selectedYieldSetIds, ysId) !== -1) {
      // remove
      ysIds = _.filter(ysIds, (y) => {
        return y !== ysId;
      });
    } else {
      // add
      ysIds.push(ysId);
    }

    this.setState({
      selectedYieldSetIds: ysIds,
    });
  }

  handleSelectAllYieldSets() {
    const { selectedYieldSetIds, yieldSetOptions } = this.state;
    // deselect all
    if (selectedYieldSetIds.length === yieldSetOptions.length) {
      this.setState({
        selectedYieldSetIds: -1,
      });
    } else {
      const ysIds = _.map(yieldSetOptions, "id");
      this.setState({
        selectedYieldSetIds: ysIds,
      });
    }
  }

  handleApplyFilter() {
    const { selectedPubId, selectedNetworkId, selectedYieldSetIds } =
      this.state;
    this.props.handleInventoryFilterChanged({
      selectedPubId,
      selectedNetworkId,
      selectedYieldSetIds,
    });
    this.props.handleClosePopover();
  }

  handleCancel() {
    // close and nothing happened
    this.props.handleClosePopover();
  }

  render() {
    const {
      isReseller,
      publisherOptions,
      selectedPubId,

      networkOptions,
      selectedNetworkId,
      yieldSetOptions,
      selectedYieldSetIds,
    } = this.state;

    return (
      <>
        <div className="p-4 shadow-2xl" style={{ width: "600px" }}>
          {isReseller && (
            <div className="border-b mb-3 pb-3">
              <div className="mb-2 text-sm font-semibold text-gray-700">
                Publishers:
              </div>

              <div className="flex">
                <div className="border-r mr-3 mb-2 border-gray-400 pr-3">
                  <button
                    type="button"
                    className={`border rounded inline-flex cursor-pointer px-6 py-1 text-sm ${
                      selectedNetworkId === -1
                        ? "bg-blue-600 text-white"
                        : "bg-white text-gray-800"
                    } hover:border-blue-400`}
                    onClick={() => this.handlePublisherSelected(-1)}
                  >
                    All
                  </button>
                </div>

                <div
                  className="flex flex-wrap"
                  style={{ maxHeight: "160px", overflowY: "auto" }}
                >
                  {publisherOptions.map((n) => {
                    let btnClass =
                      "inline-flex border rounded px-3 py-1 text-sm mr-3 mb-2";
                    if (!n.hasPerfData) {
                      btnClass +=
                        " cursor-not-allowed text-gray-500 border-gray-200";
                    } else if (selectedPubId === n.id) {
                      btnClass += " bg-blue-600 text-white";
                    } else {
                      btnClass += " cursor-pointer hover:border-blue-400";
                    }

                    return (
                      <React.Fragment key={n.id}>
                        <button
                          type="button"
                          data-tip
                          data-for={`pubfilter-${n.id}`}
                          className={btnClass}
                          onClick={() => this.handlePublisherSelected(n.id)}
                        >
                          {n.name}

                          <span className="text-gray-400"></span>
                        </button>

                        {!n.hasPerfData && (
                          <ReactTooltip
                            id={`pubfilter-${n.id}`}
                            type="dark"
                            effect="solid"
                            place="top"
                          >
                            Has no reporting data
                          </ReactTooltip>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          )}

          {selectedPubId !== -1 && (
            <div>
              <div className="mb-2 text-sm font-semibold text-gray-700">
                Networks:
              </div>

              <div className="flex">
                <div className="border-r mr-3 mb-2 border-gray-400 pr-3">
                  <button
                    type="button"
                    className={`border rounded inline-flex cursor-pointer px-6 py-1 text-sm ${
                      selectedNetworkId === -1
                        ? "bg-blue-600 text-white"
                        : "bg-white text-gray-800"
                    } hover:border-blue-400`}
                    onClick={() => this.handleNetworkSelected(-1)}
                  >
                    All
                  </button>
                </div>

                <div
                  className="flex flex-wrap"
                  style={{ maxHeight: "160px", overflowY: "auto" }}
                >
                  {networkOptions.map((n) => {
                    let btnClass =
                      "inline-flex border rounded px-3 py-1 text-sm mr-3 mb-2";
                    if (!n.hasPerfData) {
                      btnClass +=
                        " cursor-not-allowed text-gray-500 border-gray-200";
                    } else if (selectedNetworkId === n.id) {
                      btnClass += " bg-blue-600 text-white";
                    } else {
                      btnClass += " cursor-pointer hover:border-blue-400";
                    }

                    return (
                      <React.Fragment key={n.id}>
                        <button
                          type="button"
                          data-tip
                          data-for={`networkfilter-${n.id}`}
                          className={btnClass}
                          onClick={() => this.handleNetworkSelected(n.id)}
                        >
                          {n.name}

                          <span className="text-gray-400"></span>
                        </button>

                        {!n.hasPerfData && (
                          <ReactTooltip
                            id={`networkfilter-${n.id}`}
                            type="dark"
                            effect="solid"
                            place="top"
                          >
                            Has no reporting data
                          </ReactTooltip>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          )}

          <div>
            {selectedNetworkId !== -1 && (
              <div className="border-t mt-3 pt-3">
                <div className="mb-2 flex items-center gap-4 text-sm font-semibold text-gray-700">
                  Yield Sets:
                  <div>
                    <button
                      type="button"
                      className="text-xs text-blue-600 hover:text-blue-800"
                      onClick={this.handleSelectAllYieldSets}
                    >
                      {selectedYieldSetIds.length === yieldSetOptions.length
                        ? "Deselect all"
                        : "Select all"}
                    </button>
                  </div>
                </div>

                <div className="flex">
                  <div className="border-r mr-3 mb-2 border-gray-400 pr-3">
                    <button
                      type="button"
                      className={`white border rounded inline-flex cursor-pointer px-6 py-1 text-sm ${
                        selectedYieldSetIds === -1
                          ? "bg-blue-600 text-white"
                          : "bg-white text-gray-800"
                      } hover:border-blue-400`}
                      onClick={() => this.handleYieldSetSelected(-1)}
                    >
                      All
                    </button>
                  </div>

                  <div
                    className="flex flex-wrap"
                    style={{ maxHeight: "160px", overflowY: "auto" }}
                  >
                    {yieldSetOptions &&
                      yieldSetOptions.map((ys) => {
                        let btnClass =
                          "inline-flex border rounded px-3 py-1 text-sm mr-3 mb-2";
                        if (!ys.hasPerfData) {
                          btnClass +=
                            " cursor-not-allowed text-gray-500 border-gray-200";
                        } else if (
                          _.indexOf(selectedYieldSetIds, ys.id) !== -1
                        ) {
                          btnClass += " bg-blue-600 text-white";
                        } else {
                          btnClass += " cursor-pointer hover:border-blue-400";
                        }

                        return (
                          <React.Fragment key={ys.id}>
                            <button
                              type="button"
                              data-tip
                              data-for={`ysfilter-${ys.id}`}
                              className={btnClass}
                              onClick={() => this.handleYieldSetSelected(ys.id)}
                            >
                              {ys.name}
                              <span className="text-gray-400"></span>
                            </button>

                            {!ys.hasPerfData && (
                              <ReactTooltip
                                id={`ysfilter-${ys.id}`}
                                type="dark"
                                effect="solid"
                                place="top"
                              >
                                Has no reporting data
                              </ReactTooltip>
                            )}
                          </React.Fragment>
                        );
                      })}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="border-t flex justify-end pt-4">
            <button
              type="button"
              className="px-6 text-blue-500 hover:text-blue-600"
              onClick={this.handleCancel}
            >
              Cancel
            </button>

            <button
              type="button"
              className="rounded inline-flex justify-center bg-blue-500 py-2 px-10 text-sm text-white shadow-sm hover:bg-blue-600"
              onClick={this.handleApplyFilter}
            >
              Apply
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default YbMockupHomeTopFilterHeader;
