import React from "react";
import _ from "lodash";
import BillingProfileSelector from "../../BillingProfileSelector";
import BillingProfileFormModal from "./BillingProfileFormModal";

const labelClass = "font-semibold text-gray-800";

class BillingProfileEdit extends React.PureComponent {
  constructor(props) {
    super(props);

    const { billingProfile, billingProfileId } = this.props;

    this.state = {
      isLoading: false,
      billingProfile,
      billingProfileId,

      isModalOpen: false,
    };

    this.handleBillingProfileChanged =
      this.handleBillingProfileChanged.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleBillingProfileChanged(billingProfile) {
    this.props.onBillingProfileIdChanged(billingProfile.profileId);
  }

  handleOpenModal() {
    this.setState({ isModalOpen: true });
  }

  handleCloseModal({ newProfileId }) {
    console.log(newProfileId);
    if (newProfileId) {
      this.setState({ billingProfileId: newProfileId });
      this.props.onBillingProfileIdChanged(newProfileId);
    }
    this.setState({ isModalOpen: false });
  }

  render() {
    const { publisherId, billingProfile } = this.props;
    const { billingProfileId, isModalOpen } = this.state;

    return (
      <div>
        <div className="mb-4">
          <label className={labelClass}>Select Profile</label>
          <div className="w-full">
            <BillingProfileSelector
              ref={this.profileSelectorRef}
              publisherId={publisherId}
              selectedValue={billingProfileId}
              handleOnChange={this.handleBillingProfileChanged}
            ></BillingProfileSelector>
          </div>
          <div className="mt-2">
            <button
              type="button"
              className="border rounded cursor-pointer bg-blue-200 px-2 py-1 text-sm font-semibold text-blue-900 hover:bg-blue-300"
              onClick={this.handleOpenModal}
            >
              Create New Billing Profile
            </button>
          </div>
        </div>

        {isModalOpen && (
          <BillingProfileFormModal
            publisherId={publisherId}
            isOpenModal={isModalOpen}
            billingProfile={billingProfile}
            handleCloseModal={this.handleCloseModal}
          ></BillingProfileFormModal>
        )}
      </div>
    );
  }
}

export default BillingProfileEdit;
