import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import Highcharts from "highcharts";
import HighchartChart from "../../common/HighchartChart";

class PlatformIndexChart extends React.PureComponent {
  render() {
    const options = _createChartOptions(
      this.props.reports,
      this.props.currencyString
    );

    return <HighchartChart options={options}></HighchartChart>;
  }
}

function _createChartOptions(reports, currencyString) {
  const seriesData = _createSeriesData(reports);

  const options = {
    title: {
      text: "",
    },
    chart: {
      zoomType: "x",
    },
    tooltip: {
      shared: true,
      followPointer: true,
      ..._getTooltipConfig(currencyString),
    },
    xAxis: {
      ..._getXAxisConfigDateTime(),
      // // ..._createPlotBands(reports),
      ..._createPlotLines(reports),
    },
    yAxis: {
      // crosshair: {
      //   width: 2,
      //   // color: "#4fd1c5",
      //   color: "gray",
      //   dashStyle: "shortdot",
      // },
      title: {
        text: currencyString,
      },
      // min: 0,
    },
    series: seriesData,
    // plotOptions: {
    //   series: {
    //     // max number of points: https://api.highcharts.com/highcharts/plotOptions.series.turboThreshold
    //     turboThreshold: 2000, // default is 1000, performance might be effected
    //   },
    // },
  };

  return options;
}

function _createSeriesData(reports) {
  const ecpmSeries = _.map(reports, (r) => {
    return {
      x: new Date(r.date).getTime(),
      y: r.ecpm,
    };
  });
  const rrpmSeries = _.map(reports, (r) => {
    return {
      x: new Date(r.date).getTime(),
      y: r.request_rpm,
    };
  });

  return [
    {
      name: "Request RPM",
      type: "line",
      data: rrpmSeries,
      // color: "black",
      color: "#E04848",
      // opacity: 0.5,
    },
    {
      name: "eCPM",
      type: "line",
      data: ecpmSeries,
      color: "#98AAB9",
    },
  ];
}

function _getTooltipConfig(currencyString) {
  return {
    backgroundColor: "rgba(255,255,255,1)",
    formatter() {
      const headFormat = `<div style="font-size:12px; font-weight: bold; text-align: center;">${moment(
        this.x
      ).format("YYYY/MM/DD ddd")}</div>`;

      let dataRowsFormat = "";
      let total = 0;
      // reverse points so that "billable" is on top of "potential"
      for (let point of _.reverse(this.points)) {
        total = point.total;
        const p = Highcharts.numberFormat(point.y, 2);
        let pointFormat = `<b>${p}</b>`;

        const rowFormat = `
          <tr>
            <td style="padding-top: 4px;font-weight: bold; text-align: right;padding-right: 4px;">
              <span style="color:${point.color}">\u25CF</span> ${point.series.name}: ${currencyString}
            </td>
            <td style="padding-top: 4px; text-align: right;">
              ${pointFormat}
            </td>
          </tr>
        `;
        dataRowsFormat += rowFormat;
      }

      let format = `
        ${headFormat}
        <table style="font-size: 12px;">
          ${dataRowsFormat}
        </table>
        `;

      return format;
    },
    shared: true,
    useHTML: true,
    crosshairs: true,
  };
}

function _getXAxisConfigDateTime() {
  return {
    type: "datetime",
    dateTimeLabelFormats: {
      day: "%m/%e",
    },
    // labels: {
    //   formatter: function () {
    //     const m = moment(this.value);
    //     const d = m.format("DD");
    //     // const d = m.format("MM/DD");
    //     const wd = m.format("ddd");
    //     const label = `${d} <br/> ${wd}`;
    //     if (d === "01" || this.isFirst) {
    //       return `${d} <br/> ${wd} <br/> <span style="color: #3182ce;	letter-spacing: 0.05em;">${m
    //         .format("MMM")
    //         .toUpperCase()}</span>`;
    //     }
    //     // if (isWeekend(this.value)) {
    //     //   return `<span style="font-weight: bold; color: black;">${label}</span>`;
    //     // }
    //     return label;
    //   },
    // },
    tickInterval: 24 * 60 * 60 * 1000, // 1 day
    crosshair: true,
  };
}

function _createPlotLines(reports) {
  const halfDay = 43200 * 1000;
  const dates = _.reduce(
    reports,
    (result, r) => {
      const date = new Date(r.date);
      if (date.getDate() === 1) {
        result.push({
          monthString: date.toLocaleString("en", { month: "short" }),
          value: date.getTime() - halfDay,
        });
      }
      return result;
    },
    []
  );

  const plotLines = _.map(dates, (d) => {
    return {
      color: "#fbd38d",
      width: 1,
      value: d.value,
      label: {
        text: d.monthString,
        verticalAlign: "top",
        textAlign: "right",
        y: 24, // lower label from the top
        style: {
          color: "#f29c09",
          fontWeight: "bold",
        },
      },
    };
  });

  return {
    plotLines,
  };
}

export default PlatformIndexChart;
