import React from "react";
import _ from "lodash";
import ClickToCopyButton from "../common/ClickToCopyButton";
import PasswordEyeInput from "../common/PasswordEyeInput";

const inputClassDisabled =
  "bg-gray-300 flex w-full border border-gray-400 rounded py-1 px-3 appearance-none leading-normal";

class VaultAccountView extends React.PureComponent {
  render() {
    const { account } = this.props;

    return (
      <div className="mb-4">
        <div>
          <div className="my-2">
            <label
              className="block text-gray-800 font-semibold mb-1 pr-4 leading-none"
              htmlFor="account-email"
            >
              Email
            </label>
            <div className="flex items-center gap-2">
              <div className="w-2/3">
                <input
                  id="account-email"
                  type="email"
                  defaultValue={account.email}
                  className={inputClassDisabled}
                  disabled={true}
                ></input>
              </div>
              <div>
                <ClickToCopyButton copyText={account.email}></ClickToCopyButton>
              </div>
            </div>
          </div>

          <div className="my-2">
            <label
              className="block text-gray-800 font-semibold mb-1 pr-4 leading-none"
              htmlFor="i2w-vault-account-password"
            >
              Password
            </label>
            <div className="flex items-center gap-2">
              <div className="w-2/3">
                {/* <input
                  id="i2w-vault-account-password"
                  type="password"
                  defaultValue={account.password}
                  className={inputClassDisabled}
                  disabled={true}
                ></input> */}
                <PasswordEyeInput
                  id="i2w-vault-account-password"
                  defaultValue={account.password}
                  disabled={true}
                ></PasswordEyeInput>
              </div>
              <div>
                <ClickToCopyButton
                  copyText={account.password}
                ></ClickToCopyButton>
              </div>
            </div>
          </div>
        </div>

        <div className="my-2">
          <label
            className="block text-gray-800 font-semibold mb-1 pr-4 leading-none"
            htmlFor="account-recov-email"
          >
            Recovery email
          </label>
          <div className="flex items-center gap-2">
            <div className="w-2/3">
              <input
                className={inputClassDisabled}
                id="account-recov-email"
                type="email"
                defaultValue={account.recoveryEmail}
                disabled={true}
              ></input>
            </div>
            <div>
              <ClickToCopyButton
                copyText={account.recoveryEmail}
              ></ClickToCopyButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VaultAccountView;
