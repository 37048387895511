import React from "react";
import _ from "lodash";

import { PublisherAPI } from "apis";
import PublishersTable from "./PublishersTable";
import PublisherFilter from "../../common/PublisherFilter";

class Publishers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      publishers: [],
      filteredPublishers: [],
      isLoading: false,
      isSearching: false,
      errMsg: "",
    };

    this.handleSearchPublisher = this.handleSearchPublisher.bind(this);
  }

  async componentDidMount() {
    document.title = `Publishers | YB Observer`;
    this.setState({ isLoading: true });
    try {
      let publishers = await PublisherAPI.getPublishersWithTrialStatus();

      publishers = _.orderBy(publishers, ["publisherId"], ["desc"]);
      this.setState({
        publishers,
        filteredPublishers: publishers,
        isLoading: false,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        errMsg: err.toString(),
      });
    }
  }

  handleSearchPublisher(inputValue) {
    let filterKeys = ["publisherId", "name", "accountType", "type"];

    let filteredPublishers = _.filter(this.state.publishers, (item) => {
      let isMatch = false;

      _.forEach(filterKeys, (fKey) => {
        const value = _.get(item, fKey);

        if (value && _.toLower(value).includes(_.toLower(inputValue))) {
          isMatch = true;
        }
      });

      return isMatch;
    });

    filteredPublishers = _.orderBy(
      filteredPublishers,
      ["publisherId"],
      ["desc"]
    );

    if (inputValue === "") {
      this.setState({
        isSearching: false,
        filteredPublishers,
      });
    } else {
      this.setState({
        isSearching: true,
        filteredPublishers,
      });
    }
  }

  render() {
    const { publishers, filteredPublishers, errMsg, isLoading, isSearching } =
      this.state;

    return (
      <div>
        <div className="bg-white px-12">
          <div className="mb-4 flex justify-between pt-8 ">
            <div className="text-4xl font-extrabold text-gray-900">
              Publishers
            </div>

            <div className="flex gap-4">
              <div>
                <button
                  type="button"
                  className="rounded shadow bg-blue-400 px-4 py-2 font-semibold text-white hover:bg-blue-600"
                  onClick={() =>
                    this.props.history.push(
                      "/ops-mgmt/account/create-publisher"
                    )
                  }
                >
                  Create Publisher
                </button>
              </div>

              <div>
                <button
                  type="button"
                  className="rounded shadow bg-blue-400 px-4 py-2 font-semibold text-white hover:bg-blue-600"
                  onClick={() =>
                    this.props.history.push("/ops-mgmt/account/create-reseller")
                  }
                >
                  Create Reseller
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="min-h-screen bg-gray-200 px-12 py-8">
          {isLoading ? (
            "Loading..."
          ) : (
            <div>
              {errMsg ? (
                <div>{errMsg}</div>
              ) : (
                <>
                  <div className="mb-4">
                    <PublisherFilter
                      handleSearch={this.handleSearchPublisher}
                      placeholder={"Search by publisher id and name"}
                    ></PublisherFilter>
                    <div className="my-1 mx-1 text-sm text-gray-700">
                      {isSearching
                        ? `Found ${filteredPublishers.length} out of `
                        : ""}
                      {publishers.length} publishers
                    </div>
                  </div>
                  <PublishersTable items={filteredPublishers}></PublishersTable>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Publishers;
