import React from "react";
import _ from "lodash";

const HOUR_OPTIONS = [
  {
    label: "1H",
    value: 1,
  },
  {
    label: "3H",
    value: 3,
  },
  {
    label: "4H",
    value: 4,
  },
  {
    label: "6H",
    value: 6,
  },
  {
    label: "8H",
    value: 8,
  },
  {
    label: "12H",
    value: 12,
  },
  {
    label: "Daily",
    value: 24,
  },
];

class AnatomyTimeAggregationFilter extends React.Component {
  constructor(props) {
    super(props);

    const { aggregation } = this.props;

    this.state = {
      options: HOUR_OPTIONS,
      aggregation: aggregation || 24,
    };

    this.handleAggregationChanged = this.handleAggregationChanged.bind(this);
  }

  handleAggregationChanged(aggregation) {
    this.setState({
      aggregation,
    });
    //TODO
    this.props.recalculate({ aggregation });
  }

  render() {
    const { options, aggregation } = this.state;

    return (
      <div className="p-4">
        <div className="mb-1 font-semibold text-gray-700">
          Time Aggregation:
        </div>
        <div className="flex items-center justify-start text-sm">
          <div>
            {options.map((option) => {
              return (
                <div key={option.value} className="inline-block">
                  <button
                    disabled={aggregation === option.value}
                    type="button"
                    className={`rounded mr-1 px-4 py-1 font-semibold text-gray-700 ${
                      aggregation === option.value
                        ? "bg-blue-200"
                        : "bg-gray-100 hover:bg-gray-200 hover:text-gray-800"
                    }`}
                    onClick={() => this.handleAggregationChanged(option.value)}
                  >
                    {option.label}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default AnatomyTimeAggregationFilter;
