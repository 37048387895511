import React from "react";
import _ from "lodash";

import DateTimeFormatter from "components/common/DateTimeFormatter";
import VPDetailPopover from "./VPDetailPopover";

const VirtualPlacementsTable = (props) => {
  const { networkId, items, handleEditClicked, handleDelete } = props;

  return (
    <div>
      <table
        className="border shadow table w-full text-sm"
        style={{ marginBottom: "500px" }}
      >
        <thead className="border-b bg-gray-200 text-xs text-blue-800">
          <tr>
            <th className="border py-1 px-2">ID</th>
            <th className="border py-1 px-2">Name</th>
            <th className="border py-1 px-2">Units</th>
            <th className="border py-1 px-2">Updated At</th>
            <th className="border py-1 px-2">Actions</th>
          </tr>
        </thead>
        <tbody className="bg-white font-mono text-gray-900">
          {items &&
            items.map((item) => {
              return (
                <tr
                  key={item.virtualPlacementId}
                  className="border-b hover:bg-gray-100"
                >
                  <td className="whitespace-no-wrap border py-1 px-3 text-center">
                    {item.virtualPlacementId}
                  </td>

                  <td className="whitespace-no-wrap border w-1/4 py-1 px-3 text-left">
                    {item.name}
                  </td>

                  <td className="whitespace-no-wrap border w-1/4 py-1 px-3 text-right">
                    <VPDetailPopover
                      triggerElement={
                        <div className="cursor-pointer hover:underline">
                          {item.gamUnitIds.length} units
                        </div>
                      }
                      gamUnitIds={item.gamUnitIds}
                      networkId={networkId}
                    ></VPDetailPopover>
                  </td>

                  <td className="whitespace-no-wrap border py-1 px-3 text-left text-gray-700">
                    <DateTimeFormatter
                      datetime={item.updatedAt}
                    ></DateTimeFormatter>
                  </td>

                  <td className="whitespace-no-wrap border py-1 px-3 text-right">
                    <div className="flex items-center justify-end gap-4">
                      <button
                        type="button"
                        className="text-blue-600 hover:underline"
                        onClick={() => handleEditClicked(item)}
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        className="text-blue-600 hover:underline"
                        onClick={() => handleDelete(item)}
                      >
                        Remove
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default VirtualPlacementsTable;
