import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import queryString from "query-string";
import { VseAPI } from "apis";
import { calculateUnitsFilterCountSummary } from "../../../helpers/Health-Check-Unit";
import OverviewTable from "./OverviewTable";
import UnitsCheckSummaryTable from "./UnitsCheckSummaryTable";
import UnitsFilterSection from "./UnitsFilterSection";
import VSEPageWrapper from "../VSEPageWrapper";

class HealthCheckViewer extends React.Component {
  constructor(props) {
    super(props);

    const overviewEvalKey = "raw_revenue_ratio_filtered";

    this.state = {
      isLoading: false,
      errMsg: null,

      networkId: null,

      data: null,
      filteredUnits: null,

      // Checks filter
      checkNameFilter: "ALL",
      checkStatusFilter: "ALL",

      overviewEvalKey,

      // units filter
      ysFilter: "ALL",
      inspFilter: "ALL", //"INSP_ONLY",
      reqTypeFilter: "ALL",
      onboardTimeFilter: "ALL",
      susModeFilter: "EXCLUDE_SUS",
      debugModeFilter: "EXCLUDE_DEBUG",

      selectedYieldSet: null, // change to null to clear selector
      unitsFilterCountSummary: {},

      // Checks filter
      // statusFilterKey: "ALL",
      // checkFilterKey: "ALL",
      // countOnlyKeys: "ALL",
    };

    this.filterUnitsReports = this.filterUnitsReports.bind(this);
    this.handleUnitsFilterChanged = this.handleUnitsFilterChanged.bind(this);
    this.handleYsSelectChanged = this.handleYsSelectChanged.bind(this);

    this.handleOverviewEvalKeyChanged =
      this.handleOverviewEvalKeyChanged.bind(this);
  }

  async componentDidMount() {
    const networkId = _.get(this.props, "networkId");

    if (networkId) {
      document.title = `HC: ${networkId} | Health Checks`;
    }

    // if has url params -> update state -> run handleUnitsFilterChanged()
    // console.log(this.props.history);
    // this.props.history.push({
    //   // pathname: '/client',
    //   search: "?" + new URLSearchParams({ clientId: 123 }).toString(),
    // });
    // const url = new URL(window.location);
    // console.log(url);
    // url.searchParams.set("key", 123);
    // window.history.pushState({}, "", url);
    const urlQueryParams = queryString.parse(this.props.location.search);
    // console.log(urlQueryParams);
    if (urlQueryParams && !_.isEmpty(urlQueryParams)) {
      let newFilters = _.defaults(urlQueryParams, this.state);
      // console.log(newFilters);
      this.setState({ ...newFilters });
    }

    this.setState({ isLoading: true, networkId });
    await this._queryAndUpdateUI({ networkId });
    this.handleUnitsFilterChanged();
    this.setState({ isLoading: false });
  }

  async _queryAndUpdateUI(params) {
    try {
      const data = await this.queryData(params);
      console.log(data);

      if (_.isEmpty(data)) {
        this.setState({ errMsg: "No data found" });
      }

      const unitsFilterCountSummary = calculateUnitsFilterCountSummary(
        data.units
      );
      this.setState({
        data,
        filteredUnits: data.units,
        unitsFilterCountSummary,
      });
    } catch (err) {
      console.log("Error querying health check reports", err);
      this.setState({ errMsg: err });
    }
  }

  async queryData({ networkId }) {
    const params = { networkId };
    const data = await VseAPI.getHealthChecksByNetwork(params);
    return data;
  }

  filterUnitsReports(checkName, cs) {
    this.setState({
      checkNameFilter: checkName,
      checkStatusFilter: cs,
    });
  }

  handleYsSelectChanged(ys) {
    const type = "YIELD_SET";
    const filterValue = ys.yieldSetId;
    this.setState({ ysFilter: filterValue, selectedYieldSet: filterValue });
    this.handleUnitsFilterChanged(type, filterValue);
  }

  // Top: Units filter
  handleUnitsFilterChanged(type, filterValue) {
    if (!this.state.data) return;
    const allUnits = this.state.data.units;
    let filteredUnits = [];

    let ysFilter = type === "YIELD_SET" ? filterValue : this.state.ysFilter;
    let inspFilter = type === "INSP" ? filterValue : this.state.inspFilter;
    let reqTypeFilter =
      type === "REQ_TYPE" ? filterValue : this.state.reqTypeFilter;
    let onboardTimeFilter =
      type === "ONBOARD_TIME" ? filterValue : this.state.onboardTimeFilter;
    let susModeFilter =
      type === "SUS_MODE" ? filterValue : this.state.susModeFilter;
    let debugModeFilter =
      type === "DEBUG_MODE" ? filterValue : this.state.debugModeFilter;
    // console.log(this.state);

    filteredUnits = _.filter(allUnits, (u) => {
      let ysMatch = true,
        inspMatch = true,
        reqTypeMatch = true,
        onboardTimeMatch = true,
        susModeMatch = true,
        debugModeMatch = true;
      if (ysFilter !== "ALL") {
        ysMatch = u.ysId == ysFilter;
      }
      if (inspFilter === "INSP_ONLY") {
        inspMatch = u.isInsp;
      }
      if (inspFilter === "EXCLUDE_INSP") {
        inspMatch = !u.isInsp;
      }
      if (reqTypeFilter === "HAS_GPT") {
        reqTypeMatch = u.hasGpt;
      }
      if (reqTypeFilter === "HAS_AMP") {
        reqTypeMatch = u.hasAmp;
      }
      if (onboardTimeFilter === "NEW_ONLY") {
        onboardTimeMatch = u.isNewlyOnboarded;
      }
      if (onboardTimeFilter === "EXCLUDE_NEW") {
        onboardTimeMatch = !u.isNewlyOnboarded;
      }
      if (susModeFilter === "SUS_ONLY") {
        susModeMatch = u.mode === 3;
      }
      if (susModeFilter === "EXCLUDE_SUS") {
        susModeMatch = u.mode !== 3;
      }
      if (debugModeFilter === "DEBUG_ONLY") {
        debugModeMatch = u.isDebug;
      }
      if (debugModeFilter === "EXCLUDE_DEBUG") {
        debugModeMatch = !u.isDebug;
      }

      return (
        ysMatch &&
        inspMatch &&
        reqTypeMatch &&
        onboardTimeMatch &&
        susModeMatch &&
        debugModeMatch
      );
    });

    const statKey = "raw_eligible_revenue_twd";
    let sumRevenue = _.sumBy(filteredUnits, (u) =>
      _.get(u, ["stat", statKey], 0)
    );

    filteredUnits = _.map(filteredUnits, (u) => {
      if (!u.stat) {
        u.stat = {};
      }
      u.stat.raw_revenue_ratio_filtered =
        _.get(u, ["stat", statKey], 0) / sumRevenue;
      return u;
    });

    this._changeQueryParams({
      ysFilter,
      inspFilter,
      reqTypeFilter,
      onboardTimeFilter,
      susModeFilter,
      debugModeFilter,
    });
    return this.setState({
      ysFilter,
      inspFilter,
      reqTypeFilter,
      onboardTimeFilter,
      susModeFilter,
      debugModeFilter,
      filteredUnits,
      selectedYieldSet: type === "YIELD_SET" && filterValue === "ALL" && null,
    });
  }

  _changeQueryParams(queryParams) {
    this.props.history.push({
      search: "?" + new URLSearchParams(queryParams).toString(),
    });
  }

  handleOverviewEvalKeyChanged() {
    const { overviewEvalKey } = this.state;
    const newKey =
      overviewEvalKey === "raw_revenue_ratio"
        ? "raw_revenue_ratio_filtered"
        : "raw_revenue_ratio";
    this.setState({
      overviewEvalKey: newKey,
    });
  }

  render() {
    const {
      isLoading,
      errMsg,
      networkId,
      data,
      filteredUnits,
      checkNameFilter,
      checkStatusFilter,
      overviewEvalKey,

      ysFilter,
      inspFilter,
      reqTypeFilter,
      onboardTimeFilter,
      susModeFilter,
      debugModeFilter,
      selectedYieldSet,
      unitsFilterCountSummary,
    } = this.state;
    const {
      networkInfo,
      // units,
      updatedAt,
      snapshotUpdatedAt,
      snapshotsByUnitId,
    } = data || {};
    const units = filteredUnits;
    let ut = moment(updatedAt);
    ut = `${ut.format("YYYY-MM-DD HH:mm")} (${ut.fromNow()})`;
    let sut = moment(snapshotUpdatedAt);
    sut = `${sut.format("YYYY-MM-DD HH:mm")} (${sut.fromNow()})`;
    const ufc = unitsFilterCountSummary;

    return (
      <>
        <VSEPageWrapper
          isLoading={isLoading}
          errMsg={errMsg}
          networkInfo={networkInfo}
        >
          <>
            <UnitsFilterSection
              networkId={networkId}
              ysFilter={ysFilter}
              inspFilter={inspFilter}
              reqTypeFilter={reqTypeFilter}
              onboardTimeFilter={onboardTimeFilter}
              susModeFilter={susModeFilter}
              debugModeFilter={debugModeFilter}
              selectedYieldSet={selectedYieldSet}
              unitsFilterCountSummary={unitsFilterCountSummary}
              handleUnitsFilterChanged={this.handleUnitsFilterChanged}
              handleYsSelectChanged={this.handleYsSelectChanged}
            ></UnitsFilterSection>
            {/* <div>
              ysFilter: {ysFilter}
              <br />
              inspFilter: {inspFilter}
              <br />
              reqTypeFilter: {reqTypeFilter}
              <br />
              onboardTimeFilter: {onboardTimeFilter}
              <br />
              susModeFilter: {susModeFilter}
              <br />
              debugModeFilter: {debugModeFilter}
              <br />
              checkNameFilter: {checkNameFilter}
              <br />
              checkStatusFilter: {checkStatusFilter}
              <br />
            </div> */}

            {units && units.length > 0 && (
              <>
                <div className="flex justify-between">
                  <div className="text-2xl font-semibold">
                    Overview{" "}
                    <span className="text-gray-700 text-base font-medium">
                      Showing <b>{units.length}</b> out of {data.units.length}{" "}
                      active units
                    </span>
                  </div>

                  <div className="text-right text-xs text-gray-700">
                    <div>Checks updated at: {ut}</div>
                    <div>Snapshot updated at: {sut}</div>
                  </div>
                </div>

                <div className="p-4 bg-white mb-4">
                  <div>
                    <OverviewTable
                      reports={units}
                      filterUnitsReports={this.filterUnitsReports}
                      checkNameFilter={checkNameFilter}
                      checkStatusFilter={checkStatusFilter}
                      overviewEvalKey={overviewEvalKey}
                      ysFilter={ysFilter}
                      inspFilter={inspFilter}
                      reqTypeFilter={reqTypeFilter}
                      onboardTimeFilter={onboardTimeFilter}
                      susModeFilter={susModeFilter}
                      debugModeFilter={debugModeFilter}
                      handleOverviewEvalKeyChanged={
                        this.handleOverviewEvalKeyChanged
                      }
                    ></OverviewTable>
                  </div>
                </div>

                <div className="p-4 bg-white">
                  <UnitsCheckSummaryTable
                    reports={units}
                    checkNameFilter={checkNameFilter}
                    checkStatusFilter={checkStatusFilter}
                    filterUnitsReports={this.filterUnitsReports}
                    overviewEvalKey={overviewEvalKey}
                    snapshotsByUnitId={snapshotsByUnitId}
                    ysFilter={ysFilter}
                    inspFilter={inspFilter}
                    reqTypeFilter={reqTypeFilter}
                    onboardTimeFilter={onboardTimeFilter}
                    susModeFilter={susModeFilter}
                    debugModeFilter={debugModeFilter}
                  ></UnitsCheckSummaryTable>
                </div>
              </>
            )}
          </>
        </VSEPageWrapper>
      </>
    );
  }
}

export default HealthCheckViewer;
