export const RI_CONFIG_SCHEMA = {
  gpt: [
    {
      key: "desktop",
      schema: [
        {
          key: "enable_optimization",
          title: "Enable Optimization",
          valueType: "boolean",
        },
        {
          key: "time_granularity",
          title: "Time Granularity",
          valueType: "integer_enum",
          enum: [1, 3, 6],
        },
        {
          key: "optimized_formats",
          title: "Time Granularity",
          valueType: "array",
          arrayOptions: [
            "banner",
            "anchor",
            "out_of_page",
            "interstitial",
            "rewarded",
            "others",
          ],
        },
        {
          key: "browsers",
          title: "Browsers",
          valueType: "array",
          arrayOptions: ["chrome", "safari", "edge", "others"],
        },
      ],
    },

    {
      key: "phone",
      schema: [
        {
          key: "enable_optimization",
          title: "Enable Optimization",
          valueType: "boolean",
        },
        {
          key: "time_granularity",
          title: "Time Granularity",
          valueType: "integer_enum",
          enum: [1, 3, 6],
        },
        {
          key: "optimized_formats",
          title: "Time Granularity",
          valueType: "array",
          arrayOptions: [
            "banner",
            "anchor",
            "out_of_page",
            "interstitial",
            "rewarded",
            "others",
          ],
        },
        {
          key: "operating_systems",
          title: "Operating Systems",
          valueType: "array",
          arrayOptions: ["android", "ios"],
        },
      ],
    },

    {
      key: "tablet", // same as phone
      schema: [
        {
          key: "enable_optimization",
          title: "Enable Optimization",
          valueType: "boolean",
        },
        {
          key: "time_granularity",
          title: "Time Granularity",
          valueType: "integer_enum",
          enum: [1, 3, 6],
        },
        {
          key: "optimized_formats",
          title: "Time Granularity",
          valueType: "array",
          arrayOptions: [
            "banner",
            "anchor",
            "out_of_page",
            "interstitial",
            "rewarded",
            "others",
          ],
        },
        {
          key: "operating_systems",
          title: "Operating Systems",
          valueType: "array",
          arrayOptions: ["android", "ios"],
        },
      ],
    },
    {
      key: "connecttv", // same as desktop
      schema: [
        {
          key: "enable_optimization",
          title: "Enable Optimization",
          valueType: "boolean",
        },
        {
          key: "time_granularity",
          title: "Time Granularity",
          valueType: "integer_enum",
          enum: [1, 3, 6],
        },
        {
          key: "optimized_formats",
          title: "Time Granularity",
          valueType: "array",
          arrayOptions: [
            "banner",
            "anchor",
            "out_of_page",
            "interstitial",
            "rewarded",
            "others",
          ],
        },
        {
          key: "browsers",
          title: "Browsers",
          valueType: "array",
          arrayOptions: ["chrome", "safari", "edge", "others"],
        },
      ],
    },
  ],
  mobile_android: [
    {
      key: "phone",
      schema: [
        {
          key: "enable_optimization",
          title: "Enable Optimization",
          valueType: "boolean",
        },
        {
          key: "time_granularity",
          title: "Time Granularity",
          valueType: "integer_enum",
          enum: [1, 3, 6],
        },
        {
          key: "optimized_formats",
          title: "Time Granularity",
          valueType: "array",
          arrayOptions: [
            "banner",
            "app_open",
            "interstitial",
            "rewarded",
            "others",
          ],
        },
      ],
    },

    {
      key: "tablet", // same as phone
      schema: [
        {
          key: "enable_optimization",
          title: "Enable Optimization",
          valueType: "boolean",
        },
        {
          key: "time_granularity",
          title: "Time Granularity",
          valueType: "integer_enum",
          enum: [1, 3, 6],
        },
        {
          key: "optimized_formats",
          title: "Time Granularity",
          valueType: "array",
          arrayOptions: [
            "banner",
            "app_open",
            "interstitial",
            "rewarded",
            "others",
          ],
        },
      ],
    },
  ],
  mobile_ios: [
    {
      key: "phone",
      schema: [
        {
          key: "enable_optimization",
          title: "Enable Optimization",
          valueType: "boolean",
        },
        {
          key: "time_granularity",
          title: "Time Granularity",
          valueType: "integer_enum",
          enum: [1, 3, 6],
        },
        {
          key: "optimized_formats",
          title: "Time Granularity",
          valueType: "array",
          arrayOptions: [
            "banner",
            "app_open",
            "interstitial",
            "rewarded",
            "others",
          ],
        },
      ],
    },

    {
      key: "tablet", // same as phone
      schema: [
        {
          key: "enable_optimization",
          title: "Enable Optimization",
          valueType: "boolean",
        },
        {
          key: "time_granularity",
          title: "Time Granularity",
          valueType: "integer_enum",
          enum: [1, 3, 6],
        },
        {
          key: "optimized_formats",
          title: "Time Granularity",
          valueType: "array",
          arrayOptions: [
            "banner",
            "app_open",
            "interstitial",
            "rewarded",
            "others",
          ],
        },
      ],
    },
  ],

  video: [
    {
      key: "desktop",
      schema: [
        {
          key: "enable_optimization",
          title: "Enable Optimization",
          valueType: "boolean",
        },
        {
          key: "time_granularity",
          title: "Time Granularity",
          valueType: "integer_enum",
          enum: [1, 3, 6],
        },
        {
          key: "optimized_formats",
          title: "Time Granularity",
          valueType: "array",
          arrayOptions: ["instream_audio", "instream_video", "others"],
        },
        {
          key: "browsers",
          title: "Browsers",
          valueType: "array",
          arrayOptions: ["chrome", "safari", "edge", "others"],
        },
      ],
    },

    {
      key: "phone",
      schema: [
        {
          key: "enable_optimization",
          title: "Enable Optimization",
          valueType: "boolean",
        },
        {
          key: "time_granularity",
          title: "Time Granularity",
          valueType: "integer_enum",
          enum: [1, 3, 6],
        },
        {
          key: "optimized_formats",
          title: "Time Granularity",
          valueType: "array",
          arrayOptions: ["instream_audio", "instream_video", "others"],
        },
        {
          key: "operating_systems",
          title: "Operating Systems",
          valueType: "array",
          arrayOptions: ["android", "ios"],
        },
      ],
    },

    {
      key: "tablet", // same as phone
      schema: [
        {
          key: "enable_optimization",
          title: "Enable Optimization",
          valueType: "boolean",
        },
        {
          key: "time_granularity",
          title: "Time Granularity",
          valueType: "integer_enum",
          enum: [1, 3, 6],
        },
        {
          key: "optimized_formats",
          title: "Time Granularity",
          valueType: "array",
          arrayOptions: ["instream_audio", "instream_video", "others"],
        },
        {
          key: "operating_systems",
          title: "Operating Systems",
          valueType: "array",
          arrayOptions: ["android", "ios"],
        },
      ],
    },
    {
      key: "connecttv", // same as desktop
      schema: [
        {
          key: "enable_optimization",
          title: "Enable Optimization",
          valueType: "boolean",
        },
        {
          key: "time_granularity",
          title: "Time Granularity",
          valueType: "integer_enum",
          enum: [1, 3, 6],
        },
        {
          key: "optimized_formats",
          title: "Time Granularity",
          valueType: "array",
          arrayOptions: ["instream_audio", "instream_video", "others"],
        },
        {
          key: "browsers",
          title: "Browsers",
          valueType: "array",
          arrayOptions: ["chrome", "safari", "edge", "others"],
        },
      ],
    },
  ],

  amp: [
    {
      key: "desktop",
      schema: [
        {
          key: "enable_optimization",
          title: "Enable Optimization",
          valueType: "boolean",
        },
        {
          key: "time_granularity",
          title: "Time Granularity",
          valueType: "integer_enum",
          enum: [1, 3, 6],
        },
        {
          key: "optimized_formats",
          title: "Time Granularity",
          valueType: "array",
          arrayOptions: ["banner"],
        },
        {
          key: "browsers",
          title: "Browsers",
          valueType: "array",
          arrayOptions: ["chrome", "safari", "edge", "others"],
        },
      ],
    },

    {
      key: "phone",
      schema: [
        {
          key: "enable_optimization",
          title: "Enable Optimization",
          valueType: "boolean",
        },
        {
          key: "time_granularity",
          title: "Time Granularity",
          valueType: "integer_enum",
          enum: [1, 3, 6],
        },
        {
          key: "optimized_formats",
          title: "Time Granularity",
          valueType: "array",
          arrayOptions: ["banner"],
        },
        {
          key: "operating_systems",
          title: "Operating Systems",
          valueType: "array",
          arrayOptions: ["android", "ios"],
        },
      ],
    },

    {
      key: "tablet", // same as phone
      schema: [
        {
          key: "enable_optimization",
          title: "Enable Optimization",
          valueType: "boolean",
        },
        {
          key: "time_granularity",
          title: "Time Granularity",
          valueType: "integer_enum",
          enum: [1, 3, 6],
        },
        {
          key: "optimized_formats",
          title: "Time Granularity",
          valueType: "array",
          arrayOptions: ["banner"],
        },
        {
          key: "operating_systems",
          title: "Operating Systems",
          valueType: "array",
          arrayOptions: ["android", "ios"],
        },
      ],
    },
    {
      key: "connecttv", // same as desktop
      schema: [
        {
          key: "enable_optimization",
          title: "Enable Optimization",
          valueType: "boolean",
        },
        {
          key: "time_granularity",
          title: "Time Granularity",
          valueType: "integer_enum",
          enum: [1, 3, 6],
        },
        {
          key: "optimized_formats",
          title: "Time Granularity",
          valueType: "array",
          arrayOptions: ["banner"],
        },
        {
          key: "browsers",
          title: "Browsers",
          valueType: "array",
          arrayOptions: ["chrome", "safari", "edge", "others"],
        },
      ],
    },
  ],
};
