import React from "react";
import { FiCopy, FiLink } from "react-icons/fi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tooltip from "react-simple-tooltip";

class ClickToCopyWrapper extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isCopied: false,
    };
  }

  render() {
    const { isCopied } = this.state;
    const {
      copyText,
      tooltipMessage = "Click to copy",
      buttonText = "",
      iconType = "COPY",
    } = this.props;
    const copyTextStr =
      typeof copyText !== "string" ? copyText.toString() : copyText;
    return (
      <>
        <div className="flex items-center">
          <Tooltip
            background="rgba(0, 0, 0, 0.9)"
            placement="top"
            content={
              <div className="whitespace-no-wrap text-center text-xs">
                {isCopied ? "Copied!" : tooltipMessage}
              </div>
            }
            padding={8}
            radius={4}
          >
            <CopyToClipboard
              text={copyTextStr}
              onCopy={() => this.setState({ isCopied: true })}
            >
              <div
                className="flex cursor-pointer items-center gap-1 px-2 hover:text-blue-600"
                onMouseLeave={() => this.setState({ isCopied: false })}
              >
                {buttonText}
                {iconType === "COPY" && <FiCopy></FiCopy>}
                {iconType === "LINK" && <FiLink></FiLink>}
              </div>
            </CopyToClipboard>
          </Tooltip>
        </div>
      </>
    );
  }
}

export default ClickToCopyWrapper;
