import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import ExperimentGroups from "./ExperimentGroups";
import NumberFormat from "../common/NumberFormat";
import ClickToCopyWrapper from "../common/ClickToCopyWrapper";
import MetadataPopover from "../common/MetadataPopover";

class ExperimentSection extends React.PureComponent {
  goToDebugPage({ expId }) {
    window.open(`${window.location.origin}/#/anatomy/debug/${expId}`);
  }

  render() {
    const {
      data,
      segmentInfo,
      groupInfo,
      expInfo,
      expLink,
      dateRange,
      timezone,
      scrollToExp,
      scrollToExpGroup,
      highlightExpGroupId,
      deeplinkExpGroupId,
    } = this.props;

    const reports = data.reports;

    const expStartDate = moment(data.startDate).tz("Asia/Taipei");
    const dateRangeStartDate = moment(dateRange.startDate);
    const isDataIncomplete = expStartDate.isBefore(dateRangeStartDate);

    const currentUrl = window.location.href;
    const url = _.first(currentUrl.split("?"));
    const expDeepLinkUrl = `${url}?segmentId=${data.segmentId}&expId=${data.expId}`;

    const expParams = expInfo[data.expId].parameters;

    return (
      expInfo[data.expId] && (
        <div
          id={`#expbox-${data.expId}`}
          className="bg-gray-200 mx-2 my-8 rounded shadow"
        >
          {/* Experiment Title */}
          <div className="bg-gray-400 p-4 rounded-t">
            <div className="flex">
              <div className="flex items-center text-blue-700">
                <button
                  type="button"
                  className="mr-2 text-blue-700 font-mono hover:underline"
                  onClick={() => this.goToDebugPage({ expId: data.expId })}
                >
                  EXP-{data.expId}{" "}
                </button>

                <ClickToCopyWrapper
                  copyText={`${data.expId}`}
                  tooltipMessage="Click to copy Exp ID"
                ></ClickToCopyWrapper>

                <ClickToCopyWrapper
                  copyText={expDeepLinkUrl}
                  tooltipMessage="Click to copy deep link URL"
                  iconType="LINK"
                ></ClickToCopyWrapper>
              </div>

              <MetadataPopover
                metadata={expInfo[data.expId].parameters}
                position="right"
                triggerType="hover"
              >
                <span className="border-b border-dashed border-gray-600 ml-4 font-mono">
                  {expInfo[data.expId].name}
                </span>
              </MetadataPopover>
            </div>

            <div className="flex justify-between">
              <div className="text-3xl font-bold text-gray-900">
                <span>
                  {moment(data.startDate)
                    .tz("Asia/Taipei")
                    .format("YYYY-MM-DD HH:mm Z")}
                </span>
                <span className="mx-2 text-gray-600">·</span>
                <span>
                  {moment(data.startDate)
                    .tz(timezone)
                    .format("YYYY-MM-DD HH:mm Z")}
                </span>

                <div>
                  <span>
                    GrossLift:{" "}
                    <span
                      className={`${data.grossLift < 0 ? "text-red-600" : ""}`}
                    >
                      {data.grossLift}%
                    </span>
                  </span>
                  <span className="mx-2 text-gray-600">·</span>
                  <span>
                    NetLift:{" "}
                    <span
                      className={`${data.netLift < 0 ? "text-red-600" : ""}`}
                    >
                      {data.netLift}%
                    </span>
                  </span>
                  <span className="mx-2 text-gray-600">·</span>
                  <span>
                    AdX Viewability:{" "}
                    <NumberFormat
                      value={data.adxViewability}
                      postfix="%"
                    ></NumberFormat>
                  </span>
                  <span className="mx-2 text-gray-600">·</span>
                  Traffic:{" "}
                  <span className="font-bold">
                    {expParams.dispatcher.goal_quantity}%
                  </span>
                </div>
              </div>
              <div className="inline-flex">
                <button
                  type="button"
                  onClick={() => scrollToExp(expLink[data.expId].prev)}
                  className={`text-white font-bold py-2 px-4 rounded ${
                    expLink[data.expId].prev
                      ? "bg-blue-500 hover:bg-blue-700"
                      : "bg-gray-500"
                  }`}
                >
                  Previous
                </button>

                <button
                  type="button"
                  onClick={() => scrollToExp(expLink[data.expId].next)}
                  className={`text-white font-bold py-2 px-4 rounded ml-1 ${
                    expLink[data.expId].next
                      ? "bg-blue-500 hover:bg-blue-700"
                      : "bg-gray-500"
                  }`}
                >
                  Next
                </button>
              </div>
            </div>
          </div>

          {isDataIncomplete && (
            <div className="m-2 py-2 px-4 bg-red-200 border border-red-400 text-red-800 rounded text-sm">
              Data is incomplete. Report is queried from{" "}
              {dateRangeStartDate.format("YYYY-MM-DD HH:mm")}, where this
              experiment starts from {expStartDate.format("YYYY-MM-DD HH:mm")}.
              <p className="font-bold">
                Try a larger date range to show the full report for this
                experiment.
              </p>
            </div>
          )}

          {/* Experiment Groups */}
          <div className="p-4">
            <ExperimentGroups
              reports={reports}
              groupInfo={groupInfo}
              expId={data.expId}
              segmentId={data.segmentId}
              data={data}
              scrollToExpGroup={scrollToExpGroup}
              highlightExpGroupId={highlightExpGroupId}
              deeplinkExpGroupId={deeplinkExpGroupId}
            ></ExperimentGroups>
          </div>
        </div>
      )
    );
  }
}

export default ExperimentSection;
