import React from "react";
import _ from "lodash";
import CountrySelector from "components/common/CountrySelector";

class CountriesControl extends React.PureComponent {
  constructor(props) {
    super(props);

    const countries = this.props.inputValue;
    let errorMsg = null;

    if (_.isEmpty(countries)) {
      errorMsg = "Please select at least one country";
      this.props.handleHasError(true);
    }

    this.state = {
      countries,
      errorMsg,
    };

    this.handleChanged = this.handleChanged.bind(this);
  }

  handleChanged(countries) {
    this.setState({ countries, errorMsg: null });

    if (_.isEmpty(countries)) {
      this.setState({
        errorMsg: "Please select at least one country",
      });
      this.props.handleHasError(true);
      return;
    }

    this.props.handleHasError(false);
    this.props.handleNewValueChanged(countries);
  }

  render() {
    const { valueSchema } = this.props;
    // const configKey = valueSchema.key;

    const {
      // radioValue,
      countries,
      errorMsg,
    } = this.state;

    return (
      <div className="border rounded ml-4 bg-white p-2 text-sm text-gray-700">
        <>
          <CountrySelector
            selectedItems={countries}
            handleChanged={this.handleChanged}
            showCountryId={true}
          ></CountrySelector>

          <div className="text-red-600">{errorMsg}</div>
        </>
      </div>
    );
  }
}

export default CountriesControl;
