import React from "react";
import _ from "lodash";

function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

class PublisherFormContactInfo extends React.Component {
  constructor(props) {
    super(props);

    const { userEmail } = props;

    this.state = {
      userEmail,

      errorMsg: "",
    };

    this.onInputChanged = this.onInputChanged.bind(this);
    this.handleNextStep = this.handleNextStep.bind(this);
  }

  onInputChanged(value, key) {
    this.setState({
      [key]: value,
    });
  }

  handleNextStep() {
    const { userEmail } = this.state;

    if (!userEmail || userEmail === "") {
      this.setState({
        errorMsg: "Please fill in 'User Email' for new user",
      });
      return;
    }

    if (userEmail && userEmail !== "") {
      const result = validateEmail(userEmail);
      console.log(result);
      if (!result) {
        this.setState({
          errorMsg: "Please fill in 'User Email' in the correct email format",
        });
        return;
      }
    }

    let finalForm = {
      userEmail,
    };

    this.props.handleSubmit(finalForm);
  }

  render() {
    const { handleGoToLastStep } = this.props;
    const { userEmail, errorMsg } = this.state;

    const inputClass =
      "bg-white flex w-full border-2 border-gray-300 rounded py-1 px-4 appearance-none leading-normal focus:outline-none focus:border-blue-400 focus:shadow-inner hover:shadow hover:border-blue-300";

    return (
      <div>
        <div className="text-2xl text-gray-700 font-bold mb-6 mt-12 border-b border-gray-400">
          First user account for this publisher
        </div>

        <div className="mb-4">
          <label className="font-semibold text-gray-800">User Email</label>
          <div>
            <input
              type="email"
              className={inputClass}
              value={userEmail}
              onChange={(e) => this.onInputChanged(e.target.value, "userEmail")}
            ></input>
          </div>
        </div>

        <div className="text-red-600 mb-6 font-semibold">{errorMsg}</div>

        <div className="flex justify-end">
          <button
            type="button"
            className="text-blue-600 rounded font-semibold px-6 py-2"
            onClick={handleGoToLastStep}
          >
            Previous
          </button>

          <button
            type="button"
            className="bg-blue-500 rounded font-semibold text-white px-6 py-2"
            onClick={this.handleNextStep}
          >
            Next Step
          </button>
        </div>
      </div>
    );
  }
}

export default PublisherFormContactInfo;
