import React from "react";
import { Switch, Route, Link } from "react-router-dom";
import ConfigWrapper from "./research-config/ConfigWrapper";
import ListConfigsViewer from "./research-config/ListConfigsViewer";

const paths = {
  list: "/segment-research/list",
  create: "/segment-research/config/create",
  update: "/segment-research/config/:configId/update",
};

class SegmentResearchViewer extends React.Component {
  componentDidMount() {
    document.body.style.backgroundColor = "#fcfcfc";
  }

  render() {
    return (
      <>
        <div className="bg-blue-900 px-2">
          <div className="text-blue-100 text-sm font-bold py-1">
            <Link to={paths.list}>Segment Research Groups</Link>
          </div>
        </div>

        <Switch>
          <Route exact path={paths.list} component={ListConfigsViewer}></Route>
          <Route
            exact
            path={paths.update}
            // component={UpdateConfigViewer}
            component={ConfigWrapper}
          ></Route>
          <Route
            exact
            path={paths.create}
            // component={CreateConfigViewer}
            component={ConfigWrapper}
          ></Route>
        </Switch>
        {/* <Switch>
            <Route path="/ops-mgmt/billing" component={BillingViewer}></Route>
          </Switch> */}
      </>
    );
  }
}

export default SegmentResearchViewer;
