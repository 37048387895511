import React from "react";
import _ from "lodash";

const inputClass =
  "bg-white flex w-full border border-gray-400 rounded py-1 px-3 appearance-none leading-normal focus:outline-none focus:border-blue-400 focus:shadow-inner hover:border-gray-500";

const PRICE_TYPE_NAME = {
  FLOOR: 0,
  TARGET_CPM: 1,
  LGO: 2,
  BRAND_SETTING: -1,
};

class PricingSectionControl extends React.PureComponent {
  constructor(props) {
    super(props);

    const { priceSection } = this.props;
    const { priceType = PRICE_TYPE_NAME.FLOOR, price = "" } = priceSection;

    this.state = {
      radioValue: priceType,
      price,
    };

    this.handlePriceTypeChanged = this.handlePriceTypeChanged.bind(this);
    this.handlePriceChanged = this.handlePriceChanged.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.priceSection, this.props.priceSection)) {
      const { priceSection } = this.props;
      const { priceType = PRICE_TYPE_NAME.FLOOR, price = "" } = priceSection;

      this.setState({
        radioValue: priceType,
        price,
      });
    }
  }

  // radioValue
  handlePriceTypeChanged(priceType) {
    this.setState({ radioValue: priceType, price: null });
    this.props.handlePriceTypeChanged(priceType);
  }

  handlePriceChanged(e) {
    const price = parseFloat(e.target.value);
    this.setState({ price });
    this.props.handlePriceChanged(price);
  }

  render() {
    const { currency } = this.props;
    const { radioValue, price } = this.state;

    return (
      <div>
        <div className="mb-4">
          <div className="block font-bold text-gray-800">Price Type</div>
          <div className="flex items-center gap-4">
            <label htmlFor={`radio_pricing_floor`} className="cursor-pointer">
              <input
                id={`radio_pricing_floor`}
                type="radio"
                name={`radio_pricing`}
                value={PRICE_TYPE_NAME.FLOOR}
                checked={radioValue === PRICE_TYPE_NAME.FLOOR}
                onChange={() =>
                  this.handlePriceTypeChanged(PRICE_TYPE_NAME.FLOOR)
                }
              />{" "}
              Floor
            </label>

            <label
              htmlFor={`radio_pricing_targetcpm`}
              className="cursor-pointer"
            >
              <input
                id={`radio_pricing_targetcpm`}
                type="radio"
                name={`radio_pricing`}
                value={PRICE_TYPE_NAME.TARGET_CPM}
                checked={radioValue === PRICE_TYPE_NAME.TARGET_CPM}
                onChange={() =>
                  this.handlePriceTypeChanged(PRICE_TYPE_NAME.TARGET_CPM)
                }
              />{" "}
              Target CPM
            </label>

            <label htmlFor={`radio_pricing_lgo`} className="cursor-pointer">
              <input
                id={`radio_pricing_lgo`}
                type="radio"
                name={`radio_pricing`}
                value={PRICE_TYPE_NAME.LGO}
                checked={radioValue === PRICE_TYPE_NAME.LGO}
                onChange={() =>
                  this.handlePriceTypeChanged(PRICE_TYPE_NAME.LGO)
                }
              />{" "}
              LGO
            </label>

            <label htmlFor={`radio_pricing_nothing`} className="cursor-pointer">
              <input
                id={`radio_pricing_nothing`}
                type="radio"
                name={`radio_pricing`}
                value={PRICE_TYPE_NAME.BRAND_SETTING}
                checked={radioValue === PRICE_TYPE_NAME.BRAND_SETTING}
                onChange={() =>
                  this.handlePriceTypeChanged(PRICE_TYPE_NAME.BRAND_SETTING)
                }
              />{" "}
              Use Brand Price Setting
            </label>
          </div>
        </div>

        {radioValue !== PRICE_TYPE_NAME.BRAND_SETTING &&
          radioValue !== PRICE_TYPE_NAME.LGO && (
            <div className="mb-4">
              <label className="block font-bold text-gray-800" htmlFor="price">
                Price in {currency}
              </label>
              <input
                className={inputClass}
                id="price"
                type="number"
                value={price}
                step={0.01}
                min={0}
                style={{ width: "200px" }}
                onChange={this.handlePriceChanged}
              />
            </div>
          )}
      </div>
    );
  }
}

export default PricingSectionControl;
