import React from "react";
import _ from "lodash";

import CurrencySelector from "components/common/CurrencySelector";
import GeoCountrySelector from "../../GeoCountrySelector";

const inputClass =
  "bg-white flex w-full border border-gray-300 rounded py-1 px-4 appearance-none leading-normal focus:outline-none focus:border-blue-400 focus:shadow-inner hover:shadow";

const labelClass = "font-semibold text-gray-800";
const radioClass = "font-semibold text-sm text-gray-900 cursor-pointer";
const errorClass =
  "w-full bg-red-200 px-2 py-1 text-sm font-semibold text-red-900";

class GeneralSectionForm extends React.Component {
  constructor(props) {
    super(props);

    const {
      firstName = "",
      lastName = "",
      email = "",
      phoneNumber = "",
      phoneCountryCode = "",
      companyName = "",
      companyAddress = "",
      taxId = "",
      billingRecipients = "",
      currency = "",
      geoCountry = "",
      paymentTerm = "",
      paymentTermStartDate = "",
      taxInclusive = "",
      tax = "",
    } = this.props.setting;

    let billingRecipientsStr = "";
    if (billingRecipients && billingRecipients.length > 0) {
      billingRecipientsStr = billingRecipients.join(",");
    }
    let geoCountryId = null;
    if (geoCountry) {
      geoCountryId = _.get(geoCountry, "countryId");
    }

    this.state = {
      firstName,
      lastName,
      email,
      phoneNumber,
      phoneCountryCode,
      companyName,
      companyAddress,
      taxId,
      billingRecipients,
      billingRecipientsStr, //
      currency,
      geoCountry,
      geoCountryId, //
      paymentTerm,
      paymentTermStartDate,
      taxInclusive,
      tax,

      // errorObj[key] = errMsg
      errorObj: {},
    };
  }

  onInputChanged(value, key) {
    this.setState({ [key]: value });

    // check valid
    const { isValid, errMsg } = this.checkKeyValid(key, value);
    if (!isValid) {
      const newErrorObj = { ...this.state.errorObj, [key]: errMsg };
      this.setState({ errorObj: newErrorObj });
      return;
    } else {
      let newErrorObj = this.state.errorObj;
      newErrorObj = _.omit(newErrorObj, key);
      this.setState({ errorObj: newErrorObj });
    }

    // transform to valid type
    const { setting } = this.props;
    let newValue = value;
    let newKey = key;
    switch (key) {
      case "phoneCountryCode":
      case "phoneNumber": {
        // newValue = _.parseInt(newValue); //no
        break;
      }

      case "billingRecipientsStr": {
        newKey = "billingRecipients";
        newValue = newValue.split(",");
        this.setState({ [newKey]: newValue });
        break;
      }

      default: {
        //
      }
    }

    const changedSetting = { ...setting, [newKey]: newValue };
    this.props.onSettingChanged(changedSetting);
    // if (key === "billingRecipientsStr") {
    //   newSetting["billingRecipient"] = value.split(",");
    // }
  }

  checkKeyValid(key, value) {
    let isValid = true;
    let errMsg = null;

    // errorObj[key] = errMsg
    switch (key) {
      case "phoneNumber":
      case "phoneCountryCode": {
        const newValue = _.parseInt(value);
        if (_.isNaN(newValue)) {
          isValid = false;
          errMsg = `${key} should be a number`;
          break;
        }
      }

      default: {
        //
      }
    }

    return { isValid, errMsg };
  }

  checkIsSectionValid(setting) {
    // check the whole section
    let isValid = true;
    const requiredKeys = [
      "firstName",
      "lastName",
      "email",
      "billingRecipients",
      "currency",
      "geoCountryId",
      "taxInclusive",
      "tax",
    ];
    for (let key of requiredKeys) {
      if (
        setting[key] === "" ||
        setting[key] === undefined ||
        setting[key] === null
      ) {
        isValid = false;
        this.setState({ errorObj: { [key]: `${key} is required` } });
        break;
      }
    }

    return isValid;
  }

  render() {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      phoneCountryCode,
      companyName,
      companyAddress,
      taxId,
      billingRecipients,
      billingRecipientsStr, //
      currency,
      geoCountry,
      geoCountryId, //
      paymentTerm,
      paymentTermStartDate,
      taxInclusive,
      tax,
      errorObj,
    } = this.state;

    return (
      <div className="border shadow rounded mb-4 bg-gray-200 p-4">
        <div className="border-b mb-6 mt-2 border-gray-400 text-xl font-bold text-gray-900">
          General
        </div>
        {/* <div>{JSON.stringify(this.state, null, 4)}</div> */}

        <div className="mb-4 flex">
          <div className="w-2/3">
            <div className="flex gap-4">
              <div className="w-1/2">
                <label className={labelClass}>First Name*</label>
                <input
                  type="text"
                  className={inputClass}
                  value={firstName}
                  onChange={(e) =>
                    this.onInputChanged(e.target.value, "firstName")
                  }
                  required
                ></input>
              </div>

              <div className="w-1/2">
                <label className={labelClass}>Last Name*</label>
                <input
                  type="text"
                  className={inputClass}
                  value={lastName}
                  onChange={(e) =>
                    this.onInputChanged(e.target.value, "lastName")
                  }
                  required
                ></input>
              </div>
            </div>
          </div>

          <div className="flex w-1/3 items-end pb-1">
            {(errorObj.firstName || errorObj.lastName) && (
              <div className={errorClass}>
                {errorObj.firstName}
                {errorObj.lastName}
              </div>
            )}
          </div>
        </div>

        <div className="mb-4 flex">
          <div className="w-2/3">
            <label className={labelClass}>Email*</label>
            <input
              type="email"
              className={inputClass}
              value={email}
              onChange={(e) => this.onInputChanged(e.target.value, "email")}
              required
            ></input>
          </div>
          <div className="flex w-1/3 items-end pb-1">
            {errorObj.email && (
              <div className={errorClass}>{errorObj.email}</div>
            )}
          </div>
        </div>

        <div className="mb-4 flex">
          <div className="w-2/3">
            <div className="flex gap-4">
              <div className="w-1/3">
                <label className={labelClass}>Phone Country Code</label>
                <input
                  type="text"
                  className={inputClass}
                  value={phoneCountryCode}
                  onChange={(e) =>
                    this.onInputChanged(e.target.value, "phoneCountryCode")
                  }
                ></input>
              </div>
              <div className="w-2/3">
                <label className={labelClass}>Phone Number</label>
                <input
                  type="text"
                  className={inputClass}
                  value={phoneNumber}
                  onChange={(e) =>
                    this.onInputChanged(e.target.value, "phoneNumber")
                  }
                ></input>
              </div>
            </div>
          </div>
          <div className="flex w-1/3 items-end pb-1">
            {(errorObj.phoneCountryCode || errorObj.phoneNumber) && (
              <div className={errorClass}>
                {errorObj.phoneCountryCode}
                {errorObj.phoneNumber}
              </div>
            )}
          </div>
        </div>

        <div className="mb-4 flex">
          <div className="w-2/3">
            <label className={labelClass}>Company Name</label>
            <input
              type="text"
              className={inputClass}
              value={companyName}
              onChange={(e) =>
                this.onInputChanged(e.target.value, "companyName")
              }
            ></input>
          </div>
          <div className="flex w-1/3 items-end pb-1">
            {errorObj.companyName && (
              <div className={errorClass}>{errorObj.companyName}</div>
            )}
          </div>
        </div>

        <div className="mb-4 flex">
          <div className="w-2/3">
            <label className={labelClass}>Company Address</label>
            <input
              type="text"
              className={inputClass}
              value={companyAddress}
              onChange={(e) =>
                this.onInputChanged(e.target.value, "companyAddress")
              }
            ></input>
          </div>
          <div className="flex w-1/3 items-end pb-1">
            {errorObj.companyAddress && (
              <div className={errorClass}>{errorObj.companyAddress}</div>
            )}
          </div>
        </div>

        <div className="mb-4 flex">
          <div className="w-2/3">
            <label className={labelClass}>Tax ID</label>
            <input
              type="text"
              className={inputClass}
              value={taxId || ""}
              onChange={(e) => this.onInputChanged(e.target.value, "taxId")}
            ></input>
          </div>
          <div className="flex w-1/3 items-end pb-1">
            {errorObj.taxId && (
              <div className={errorClass}>{errorObj.taxId}</div>
            )}
          </div>
        </div>

        <div className="mb-4 flex">
          <div className="w-2/3">
            <label className={labelClass}>Billing Recipients*</label>

            <textarea
              className={inputClass}
              value={billingRecipientsStr}
              onChange={(e) =>
                this.onInputChanged(e.target.value, "billingRecipientsStr")
              }
              rows={3}
              required
            ></textarea>
            <div className="text-xs text-gray-700">
              {billingRecipients ? billingRecipients.length : 0} emails
            </div>
          </div>
          <div className="flex w-1/3 items-end pb-1">
            {errorObj.billingRecipientsStr && (
              <div className={errorClass}>{errorObj.billingRecipients}</div>
            )}
          </div>
        </div>

        <div className="mb-4 flex">
          <div className="flex w-2/3 gap-4">
            <div>
              <label className={labelClass}>Currency*</label>
              <CurrencySelector
                selectedCurrency={currency}
                handleCurrencyChanged={(c) =>
                  this.onInputChanged(c, "currency")
                }
              ></CurrencySelector>
            </div>

            <div className="w-1/2">
              <label className={labelClass}>Country*</label>
              <GeoCountrySelector
                selectedValue={geoCountryId}
                handleOnChange={(c) => this.onInputChanged(c, "geoCountryId")}
              ></GeoCountrySelector>
            </div>
          </div>
          <div className="flex w-1/3 items-end pb-1">
            {(errorObj.currency || errorObj.geoCountryId) && (
              <div className={errorClass}>
                {errorObj.currency}
                {errorObj.geoCountryId}
              </div>
            )}
          </div>
        </div>

        <div className="mb-4 flex">
          <div className="flex w-2/3 gap-4">
            <div>
              <label className={labelClass}>Payment Term (days)</label>
              <input
                type="text"
                className={inputClass}
                value={paymentTerm}
                onChange={(e) =>
                  this.onInputChanged(e.target.value, "paymentTerm")
                }
              ></input>
            </div>
            <div>
              <label className={labelClass}>Payment Term Start Date</label>
              <input
                type="text"
                className={inputClass}
                value={paymentTermStartDate}
                onChange={(e) =>
                  this.onInputChanged(e.target.value, "paymentTermStartDate")
                }
              ></input>
            </div>
          </div>
          <div className="flex w-1/3 items-end pb-1">
            {(errorObj.paymentTerm || errorObj.paymentTermStartDate) && (
              <div className={errorClass}>
                {errorObj.paymentTerm}
                {errorObj.paymentTermStartDate}
              </div>
            )}
          </div>
        </div>

        <div className="mb-4 flex">
          <div className="flex w-2/3 gap-4">
            <div className="w-1/4">
              <label className={labelClass}>Is Tax Inclusive?*</label>

              <div className="flex items-center gap-4 py-1">
                <label htmlFor={`tax_inclusive_true`} className={radioClass}>
                  <input
                    id={`tax_inclusive_true`}
                    type="radio"
                    name={`tax_inclusive`}
                    value={true}
                    checked={taxInclusive === true}
                    onChange={(e) => this.onInputChanged(true, "taxInclusive")}
                  />{" "}
                  true
                </label>

                <label htmlFor={`tax_inclusive_false`} className={radioClass}>
                  <input
                    id={`tax_inclusive_false`}
                    type="radio"
                    name={`tax_inclusive`}
                    value={false}
                    checked={taxInclusive === false}
                    onChange={(e) => this.onInputChanged(false, "taxInclusive")}
                  />{" "}
                  false
                </label>
              </div>
            </div>
          </div>
          <div className="flex w-1/3 items-end pb-1">
            {errorObj.taxInclusive && (
              <div className={errorClass}>{errorObj.taxInclusive}</div>
            )}
          </div>
        </div>

        <div className="mb-4 flex">
          <div className="flex w-2/3 gap-4">
            <div className="w-1/4">
              <label className={labelClass}>Tax (%)*</label>
              <input
                type="text"
                className={inputClass}
                value={tax}
                onChange={(e) => this.onInputChanged(e.target.value, "tax")}
                required
              ></input>
            </div>
          </div>
          <div className="flex w-1/3 items-end pb-1">
            {errorObj.tax && <div className={errorClass}>{errorObj.tax}</div>}
          </div>
        </div>
      </div>
    );
  }
}

export default GeneralSectionForm;
