import React from "react";
import Popup from "reactjs-popup";

class PopupWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();
    this.handleOpenPopup = this.handleOpenPopup.bind(this);
    this.handleClosePopup = this.handleClosePopup.bind(this);
  }

  handleOpenPopup() {
    this.ref.current.open();
  }

  handleClosePopup() {
    this.ref.current.close();
  }

  render() {
    const { triggerElement, popupElement, place, triggerType, hideArrow } =
      this.props;
    const position = place || "right center";
    const type = triggerType || "click";
    const isOpen = this.props.isOpen || false;
    const arrowStyle = !!hideArrow ? { display: "none" } : {};

    return (
      <Popup
        ref={this.ref}
        contentStyle={{
          width: "auto",
          zIndex: 9999999999,
          padding: 0,
          margin: 0,
          backgroundColor: "#FFFFFF",
        }}
        arrowStyle={arrowStyle}
        trigger={triggerElement}
        position={position}
        open={isOpen}
        on={type}
        onOpen={this.props.onOpen}
      >
        {popupElement}
      </Popup>
    );
  }
}

// function PopupWrapper(props) {
//   const { triggerElement, popupElement, place, triggerType, hideArrow } = props;
//   const [isPopupShown, setIsPopupShown] = useState(false);
//   const position = place || "right center";
//   const type = triggerType || "click";
//   const isOpen = props.isOpen || false;
//   const arrowStyle = !!hideArrow ? { display: "none" } : {};

//   function handleClosePopup() {
//     setIsPopupShown(false);
//   }

//   return (
//     <>
//       <Popup
//         contentStyle={{
//           width: "auto",
//           zIndex: 9999999999,
//           padding: 0,
//           margin: 0,
//         }}
//         arrowStyle={arrowStyle}
//         trigger={triggerElement}
//         position={position}
//         open={isOpen}
//         onOpen={() => setIsPopupShown(true)}
//         onClose={() => setIsPopupShown(false)}
//         on={type}
//       >
//         {isPopupShown ? popupElement : ""}
//       </Popup>
//     </>
//   );
// }

export default PopupWrapper;
