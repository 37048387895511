import React from "react";
import _ from "lodash";

import { Switch, Route, NavLink } from "react-router-dom";
import { NetworkAPI } from "apis";
import LoadingUI from "../../../common/LoadingUI";
import NetworkInfoHeader from "../../common/NetworkInfoHeader";
import { buttonConfirmClass } from "helpers/StyleClass";
import VirtualPlacementsTable from "./VirtualPlacementsTable";
import CreateOrUpdateVirtualPlacement from "./CreateOrUpdateVirtualPlacement";
import { notify } from "react-notify-toast";

const PATH_PREFIX = "/ops-mgmt/account/network/:networkId/virtual-placement";
const PATHS = {
  LIST: `${PATH_PREFIX}/list`,
  CREATE: `${PATH_PREFIX}/create`,
  UPDATE: `${PATH_PREFIX}/:virtualPlacementId/update`,
};
class VirtualPlacementsViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,

      items: null,
      currentItem: null, // for edit
    };

    this.handleGoToEdit = this.handleGoToEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      const { networkId } = this.props.match.params;
      if (!networkId) {
        throw new Error("Missing Network ID");
      }
      const networkInfo = await NetworkAPI.getNetworkInfo({ networkId });
      if (!networkInfo) {
        throw new Error("Invalid Network");
      }

      document.title = `${networkId} Network Virtual Placements | YB Observer`;

      await this.queryVirtualPlacements(networkId);

      this.setState({
        networkId,
        networkInfo,

        isLoading: false,
        errMsg: null,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        errMsg: err.toString(),
      });
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      // Route changed!

      // console.log(
      //   "route changed",
      //   prevProps.location.pathname,
      //   this.props.location.pathname
      // );

      // Query again if page coming back from create or update
      if (
        (_.endsWith(prevProps.location.pathname, "/update") ||
          _.endsWith(prevProps.location.pathname, "/create")) &&
        _.endsWith(this.props.location.pathname, "/list")
      ) {
        const { networkId } = this.props.match.params;
        await this.queryVirtualPlacements(networkId);
      }
    }
  }

  async queryVirtualPlacements(networkId) {
    const items = await NetworkAPI.getNetworkVirtualPlacements({
      networkId,
    });

    this.setState({
      items: _.orderBy(items, ["virtualPlacementId"], ["asc"]),
    });
  }

  handleGoToEdit(item) {
    this.setState({
      currentItem: item,
    });
    const { networkId } = this.state;
    const url = `/ops-mgmt/account/network/${networkId}/virtual-placement/${item.virtualPlacementId}/update`;
    this.props.history.push(url);
  }

  async handleDelete(item) {
    const userConfirm = window.confirm(
      `Delete virtual placement ${item.virtualPlacementId} ${item.name}?`
    );
    if (!userConfirm) return;

    try {
      const { networkId } = this.state;
      const { virtualPlacementId } = item;
      const params = {
        virtualPlacementId,
        networkId,
      };

      const r = await NetworkAPI.deleteNetworkVirtualPlacement(params);
      console.log(r);

      notify.show("Virtual Placement deleted!", "success");

      await this.queryVirtualPlacements(networkId);
    } catch (err) {
      console.log(err);
      notify.show(`Failed to delete! ${err}`, "error");
    }
  }

  render() {
    const {
      networkId,
      networkInfo,

      items,

      isLoading,
      errMsg,

      currentItem,
    } = this.state;

    return (
      <div>
        {isLoading && <LoadingUI></LoadingUI>}
        {errMsg && <div className="text-red-800">{errMsg}</div>}

        {networkInfo && (
          <div>
            <NetworkInfoHeader networkInfo={networkInfo}></NetworkInfoHeader>

            <Switch>
              <Route
                path={PATHS.LIST}
                render={(props) => {
                  return (
                    <div className="min-h-screen bg-white px-12 py-8">
                      <div className="mb-2 flex items-center justify-between">
                        <PageTitle title="Network Virtual Placements"></PageTitle>
                      </div>

                      <div className="mb-2 flex items-end justify-between gap-4">
                        <div>{items ? items.length : 0} virtual placements</div>

                        <NavLink
                          to={`/ops-mgmt/account/network/${networkId}/virtual-placement/create`}
                          className={buttonConfirmClass}
                        >
                          Create new virtual placement
                        </NavLink>
                      </div>

                      <div>
                        {items.length > 0 && (
                          <VirtualPlacementsTable
                            items={items}
                            networkId={networkId}
                            handleEditClicked={this.handleGoToEdit}
                            handleDelete={this.handleDelete}
                          ></VirtualPlacementsTable>
                        )}
                      </div>
                    </div>
                  );
                }}
              ></Route>

              <Route
                path={PATHS.CREATE}
                render={(props) => {
                  return (
                    <div className="min-h-screen bg-white px-12 py-8">
                      <div className="mb-2 flex items-center justify-between">
                        <PageTitle title="Create Network Virtual Placement"></PageTitle>
                      </div>
                      <CreateOrUpdateVirtualPlacement
                        {...props}
                        formtType="ADD"
                        virtualPlacement={{}}
                        networkId={networkId}
                      ></CreateOrUpdateVirtualPlacement>
                    </div>
                  );
                }}
              ></Route>

              <Route
                path={PATHS.UPDATE}
                render={(props) => {
                  return (
                    <div className="min-h-screen bg-white px-12 py-8">
                      <div className="mb-2 flex items-center justify-between">
                        <PageTitle title="Update Network Virtual Placement"></PageTitle>
                      </div>
                      <CreateOrUpdateVirtualPlacement
                        {...props}
                        formtType="UPDATE"
                        virtualPlacement={currentItem}
                        networkId={networkId}
                      ></CreateOrUpdateVirtualPlacement>
                    </div>
                  );
                }}
              ></Route>
            </Switch>
          </div>
        )}
      </div>
    );
  }
}

function PageTitle({ title }) {
  return <div className="text-3xl font-extrabold">{title}</div>;
}

export default VirtualPlacementsViewer;
