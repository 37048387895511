import _ from "lodash";

// GAM Creative Types
export const GAM_CREATIVE_TYPES = {
  Display: "1905220446",
  Video: "81665115",
};

export const GAM_CREATIVE_TYPES_NAME = {
  1905220446: "Display Only",
  81665115: "Video Only",
};

// GAM Inventory Types
export const GAM_INVENTORY_TYPES = {
  Display: "868923144",
  "Mobile app": "1634561820",
  "In-stream video and audio": "563536709",
};

export const GAM_INVENTORY_TYPES_NAME = {
  868923144: "Display",
  1634561820: "Mobile app",
  563536709: "In-stream video and audio",
};

// GAM Video Positions
export const GAM_VIDEO_POSITIONS = {
  "Pre-roll": "113194248",
  "Any mid-roll": "1467291011",
  "Post-roll": "266538485",
};

export const GAM_VIDEO_POSITIONS_NAME = _.invert(GAM_VIDEO_POSITIONS);

// GAM Inventory Format
export const GAM_INVENTORY_FORMATS = {
  Anchor: "678",
  "App open": "2914",
  Banner: "696",
  "Instream Audio": "370",
  "Instream Video": "1272",
  Interstitial: "1000",
  "Native custom rendering (a. select both)": "1218",
  "Native custom rendering (b. select both)": "2622", //"1218" and "2622", // pair appear
  Rewarded: "3956",
  "Rewarded interstitial": "2524",
};

export const GAM_INVENTORY_FORMATS_NAME = _.invert(GAM_INVENTORY_FORMATS);

// GAM Sensitive Categories
export const GAM_SENSITIVE_CATEGORIES = {
  "Astrology & Esoteric": "1010030",
  "Consumer Loans": "1010027",
  "Cosmetic Procedures & Body Modification": "1010019",
  Dating: "1010004",
  "Downloadable Utilities": "1010008",
  "Drugs & Supplements": "1010023",
  "Get Rich Quick": "1010010",
  Politics: "1010003",
  "References to Sex": "1010031",
  Religion: "1010005",
  Sensationalism: "1010037",
  "Sexual & Reproductive Health": "1010024",
  "Significant Skin Exposure": "1010036",
  "Social Casino Games": "1010035",
  "Video Games (Casual & Online)": "1010007",
  "Weight Loss": "1010018",
};

export const GAM_SENSITIVE_CATEGORIES_NAME = _.invert(GAM_SENSITIVE_CATEGORIES);
