import React from "react";
import _ from "lodash";
import { DashboardAPI } from "apis";
import LoadingUI from "../../common/LoadingUI";
import {
  transformPlatfromIndexData,
  convertReportsByCurrency,
  formatMoneyWithCurrency,
} from "./PlatformIndexReportsHelper";
import PlatformIndexChart from "./PlatformIndexChart";
import ExportToCsvButton from "./ExportToCsvButton";
import CurrencySelector from "../../playground/home-dashboard-v2/CurrencySelector";
import PlatformIndexFilter from "./PlatformIndexFilter";

class PlatformIndexViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errMsg: null,

      isLoading: false,
      originalReports: null,
      reports: null,
      exchangeRates: null,
      updatedAt: null,

      targetCurrency: "USD",
      currencies: null,

      isNewDataLoading: false,
    };

    this.queryAndUpdateUI = this.queryAndUpdateUI.bind(this);
    this.queryReports = this.queryReports.bind(this);
    this.handleCurrencyChanged = this.handleCurrencyChanged.bind(this);
  }

  async componentDidMount() {
    document.title = `Platform Index | YB Observer`;
  }

  async queryAndUpdateUI(params) {
    this.setState({
      isLoading: true,
      errMsg: null,
      originalReports: null,
      reports: null,
      exchangeRates: null,
      updatedAt: null,
      currencies: null,
    });
    try {
      const { updatedAt, reports, exchangeRates } = await this.queryReports(
        params
      );
      console.log(reports, exchangeRates);

      if (_.isEmpty(reports)) {
        this.setState({ errMsg: "No data found" });
      }

      const currencies = _.keys(exchangeRates);

      this.setState({
        originalReports: reports,
        reports,
        exchangeRates,
        updatedAt,
        currencies,
      });
    } catch (err) {
      console.log("Error querying platform index report", err);
      this.setState({ errMsg: err.toString() });
    }
    this.setState({ isLoading: false });
  }

  async queryReports(params) {
    console.log(params);
    const data = await DashboardAPI.getPlatformIndexReports(params);

    // transform reports to json format
    const finalReports = transformPlatfromIndexData(data);
    return finalReports;
  }

  handleCurrencyChanged(targetCurrency) {
    const { originalReports, exchangeRates } = this.state;
    const reports = convertReportsByCurrency({
      reports: _.cloneDeep(originalReports),
      exchangeRates: exchangeRates,
      targetCurrency,
    });

    this.setState({ reports, targetCurrency });
  }

  render() {
    const { reports, currencies, targetCurrency, isLoading } = this.state;
    const cs = formatMoneyWithCurrency({
      currency: targetCurrency,
      value: 0,
    });
    const firstZero = cs.indexOf("0");
    // start from 0 to the first 0
    const currencyString = `${cs.substring(0, firstZero)}`;

    // platform-index_EURO
    const filename = `platform-index_${targetCurrency}`;

    return (
      <>
        <div className="bg-gray-200 px-12 py-8 min-h-screen">
          <div className="p-4 bg-white mb-4">
            <PlatformIndexFilter
              handleFilter={this.queryAndUpdateUI}
              isLoading={isLoading}
            ></PlatformIndexFilter>
          </div>

          <div className="bg-white mb-4">
            {this.state.errMsg && (
              <div className="p-4">{this.state.errMsg}</div>
            )}

            {isLoading ? (
              <div className="p-4 h-screen">
                <LoadingUI></LoadingUI>
              </div>
            ) : (
              <div>
                {reports && (
                  <>
                    {/* <div>{JSON.stringify(reports, null, 4)}</div> */}

                    <div className="p-4">
                      <div className="flex justify-end gap-4 items-center mb-4">
                        <ExportToCsvButton
                          data={reports}
                          filename={filename}
                        ></ExportToCsvButton>

                        <CurrencySelector
                          currencies={currencies}
                          selectedCurrency={targetCurrency}
                          handleCurrencyChanged={this.handleCurrencyChanged}
                        ></CurrencySelector>
                      </div>

                      <PlatformIndexChart
                        reports={reports}
                        currencyString={currencyString}
                      ></PlatformIndexChart>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default PlatformIndexViewer;
