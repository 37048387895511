import React from "react";
import _ from "lodash";
import Checkbox from "../../../common/Checkbox";

class ClientRefreshLabelsControl extends React.PureComponent {
  constructor(props) {
    super(props);

    const categories = this.props.inputValue;
    let errorMsg = null;

    // if (_.isEmpty(categories)) {
    //   errorMsg = "Please select at least one label";
    //   this.props.handleHasError(true);
    // }

    this.state = {
      categories,
      errorMsg,
    };

    this.handleMultiSelectChanged = this.handleMultiSelectChanged.bind(this);
  }

  handleMultiSelectChanged(categories) {
    this.setState({ categories, errorMsg: null });

    // if (_.isEmpty(categories)) {
    //   this.setState({
    //     errorMsg: "Please select at least one label",
    //   });
    //   this.props.handleHasError(true);
    //   return;
    // }

    this.props.handleHasError(false);
    this.props.handleNewValueChanged(categories);
  }

  render() {
    const { valueSchema } = this.props;

    const { categories, errorMsg } = this.state;

    return (
      <div className="border rounded ml-4 bg-white p-2 text-sm text-gray-700">
        <>
          <MultiSelectCheckboxes
            inputValue={categories}
            options={valueSchema.arrayOptions}
            handleMultiSelectChanged={this.handleMultiSelectChanged}
          ></MultiSelectCheckboxes>

          <div className="text-red-600">{errorMsg}</div>
        </>
      </div>
    );
  }
}

class MultiSelectCheckboxes extends React.PureComponent {
  constructor(props) {
    super(props);

    const { inputValue } = this.props;

    this.state = {
      valuesMap: _.reduce(
        inputValue,
        (result, v) => {
          result[v] = true;
          return result;
        },
        {}
      ),
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(option) {
    let valuesMap = { ...this.state.valuesMap };
    valuesMap[option] = !this.state.valuesMap[option];

    const values = _.reduce(
      valuesMap,
      (result, v, k) => {
        if (v) {
          result.push(k);
        }
        return result;
      },
      []
    );

    this.setState({ valuesMap, values });
    this.props.handleMultiSelectChanged(values);
  }

  render() {
    const { options } = this.props;
    const { valuesMap } = this.state;

    return (
      <>
        {options.map((option) => {
          return (
            <div key={option}>
              <label className="flex cursor-pointer items-center">
                <Checkbox
                  isChecked={valuesMap[option] || false}
                  onChange={() => this.onChange(option)}
                ></Checkbox>
                <span className="ml-1 align-middle text-sm font-semibold text-gray-600 hover:text-gray-700">
                  {option}
                </span>
              </label>
            </div>
          );
        })}
      </>
    );
  }
}

export default ClientRefreshLabelsControl;
