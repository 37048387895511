import _ from "lodash";

import { api, postData, fetchRespJson } from "apis/base";

export default {
  // ops-mgmt: billing
  getInvoices,
  createInvoices,
  adviseInvoice,
  approveInvoice,
  issueInvoice,
  auditInvoice,
  voidInvoice,
  badDebtInvoice,
  getPayments,
  receivePayment,
  sendOverdueEmail,

  getInvoiceNotes,
  createInvoiceNote,
  updateInvoiceNote,
  deleteInvoiceNote,

  getBankAccounts,
  getBillingProfiles,
  createBillingProfile,
  createOrUpdateBillingSetting,
};

async function createBillingProfile({ publisherId, params }) {
  const apiUrl = `${api}/publisher/${publisherId}/create-billing-profile`;
  return await postData(apiUrl, { params });
}

async function createOrUpdateBillingSetting({ publisherId, params }) {
  const apiUrl = `${api}/publisher/${publisherId}/create-or-update-billing-setting`;
  return await postData(apiUrl, { params });
}

async function getBillingProfiles({ publisherId }) {
  const apiUrl = `${api}/billing-profiles?publisherId=${publisherId}`;
  return await fetchRespJson(apiUrl);
}

async function getBankAccounts() {
  const apiUrl = `${api}/bank-accounts`;
  return await fetchRespJson(apiUrl);
}

async function deleteInvoiceNote({ noteId }) {
  const apiUrl = `${api}/invoice/note/delete/${noteId}`;
  let params = {};
  return await postData(apiUrl, params);
}

async function updateInvoiceNote({ noteId, note }) {
  const apiUrl = `${api}/invoice/note/update/${noteId}`;
  let params = { note };
  return await postData(apiUrl, params);
}

async function createInvoiceNote({ invoiceId, note }) {
  const apiUrl = `${api}/invoice/${invoiceId}/create-note`;
  let params = { note };
  return await postData(apiUrl, params);
}

async function getInvoiceNotes({ invoiceId }) {
  const apiUrl = `${api}/invoice/${invoiceId}/notes`;
  return await fetchRespJson(apiUrl);
}

async function sendOverdueEmail({ invoiceId }) {
  const apiUrl = `${api}/invoice/send-overdue-email`;
  let updateParams = { invoiceId };
  return await postData(apiUrl, updateParams);
}

async function receivePayment(params) {
  const apiUrl = `${api}/payment/receive`;
  return await postData(apiUrl, params);
}

async function getPayments(queryParams) {
  try {
    let apiUrl = `${api}/payments`;
    return await postData(apiUrl, queryParams);
  } catch (err) {
    console.log(err);
    throw err;
  }
}

async function getInvoices(queryParams) {
  try {
    let apiUrl = `${api}/invoices`;
    return await postData(apiUrl, queryParams);
  } catch (err) {
    console.log(err);
    throw err;
  }
}

async function auditInvoice({ invoiceId, pubId, poNumber }) {
  const apiUrl = `${api}/invoice/audit`;
  let updateParams = {
    invoiceId,
    pubId,
    poNumber,
  };
  return await postData(apiUrl, updateParams);
}

async function issueInvoice({ invoiceId, pubId }) {
  const apiUrl = `${api}/invoice/issue`;
  let updateParams = {
    invoiceId,
    pubId,
  };
  return await postData(apiUrl, updateParams);
}

async function adviseInvoice(invoiceId) {
  const apiUrl = `${api}/invoice/advise`;
  let updateParams = {
    invoiceId,
  };
  return await postData(apiUrl, updateParams);
}

async function approveInvoice(invoiceId) {
  const apiUrl = `${api}/invoice/approve`;
  let updateParams = {
    invoiceId,
  };
  return await postData(apiUrl, updateParams);
}

async function voidInvoice({ invoiceId, reason }) {
  const apiUrl = `${api}/invoice/void`;
  let updateParams = {
    invoiceId,
    reason,
  };
  return await postData(apiUrl, updateParams);
}

async function badDebtInvoice({ paymentId }) {
  const apiUrl = `${api}/invoice/baddebt`;
  let updateParams = {
    paymentId,
  };
  return await postData(apiUrl, updateParams);
}

async function createInvoices(month) {
  const apiUrl = `${api}/invoices/create`;
  let updateParams = {
    month,
  };
  return await postData(apiUrl, updateParams);
}
