import React from "react";
import moment from "moment-timezone";

class DateTimeFormatter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.formatDatetime = this.formatDatetime.bind(this);
  }

  formatDatetime(value) {
    if (!value) {
      return "-";
    }

    const mValue = moment(value);
    const mFormat = this.props.format || "YYYY/MM/DD HH:mm:ss";
    const mFromNow = mValue.fromNow();

    if (this.props.fromNowOnly) {
      return mFromNow;
    }

    if (this.props.isLineBreak) {
      return (
        <div>
          {mValue.format(mFormat)}
          <div>({mFromNow})</div>
        </div>
      );
    }

    return `${mValue.format(mFormat)} (${mFromNow})`;
  }

  render() {
    const { datetime } = this.props;
    const formattedDatetime = this.formatDatetime(datetime);
    return formattedDatetime;
  }
}

export default DateTimeFormatter;
