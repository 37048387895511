import React from "react";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import DateTimeFormatter from "components/common/DateTimeFormatter";

const RELEASE_STATUS = {
  RELEASED_INT: "Integration Released",
  RELEASED_PROD: "Production Released",
};
function BuildReleaseStatusLabel({
  // createdAt,
  buildId,
  integrationReleasedAt,
  productionReleasedAt,
}) {
  let status = "-";
  if (integrationReleasedAt && !productionReleasedAt) {
    status = RELEASE_STATUS.RELEASED_INT;
  }
  if (integrationReleasedAt && productionReleasedAt) {
    status = RELEASE_STATUS.RELEASED_PROD;
  }

  return (
    <div>
      <div data-tip data-for={`"release-status-${buildId}`}>
        {status}
      </div>
      {status !== "-" && (
        <ReactTooltip
          id={`"release-status-${buildId}`}
          type="dark"
          effect="solid"
          place="top"
        >
          <DateTimeFormatter
            datetime={
              status === RELEASE_STATUS.RELEASED_INT
                ? integrationReleasedAt
                : productionReleasedAt
            }
          ></DateTimeFormatter>
        </ReactTooltip>
      )}
    </div>
  );
}

export default BuildReleaseStatusLabel;
