import React from "react";
import _ from "lodash";

import { NetworkAPI } from "apis";
import NetworksTable from "./NetworksTable";
import ItemsFilter from "../common/ItemsFilter";

class Networks extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      filteredItems: [],
      isLoading: false,
      isSearching: false,
      errMsg: "",
    };

    this.handleSearch = this.handleSearch.bind(this);
  }

  async componentDidMount() {
    document.title = "Networks | YB Observer";
    this.setState({ isLoading: true });
    try {
      let items = await NetworkAPI.getNetworks();
      // console.log(items);

      items = _.orderBy(items, ["networkId"], ["desc"]);
      this.setState({
        items,
        filteredItems: items,
        isLoading: false,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        errMsg: err.toString(),
      });
    }
  }

  handleSearch(inputValue) {
    let filterKeys = ["networkId", "name", "status"];

    let filteredItems = _.filter(this.state.items, (item) => {
      let isMatch = false;

      _.forEach(filterKeys, (fKey) => {
        const value = _.get(item, fKey);

        if (value && _.toLower(value).includes(_.toLower(inputValue))) {
          isMatch = true;
        }
      });

      return isMatch;
    });

    filteredItems = _.orderBy(filteredItems, ["networkId"], ["desc"]);

    if (inputValue === "") {
      this.setState({
        isSearching: false,
        filteredItems,
      });
    } else {
      this.setState({
        isSearching: true,
        filteredItems,
      });
    }
  }

  render() {
    const { items, filteredItems, errMsg, isLoading, isSearching } = this.state;

    return (
      <div>
        <div className="bg-white px-12">
          <div className="flex justify-between pt-8 mb-4 ">
            <div className="font-extrabold text-gray-900 text-4xl">
              Networks
            </div>
          </div>
        </div>

        <div className="bg-gray-200 px-12 py-8 min-h-screen">
          {isLoading ? (
            "Loading..."
          ) : (
            <div>
              {errMsg ? (
                <div>{errMsg}</div>
              ) : (
                <>
                  <div className="mb-4">
                    <ItemsFilter
                      handleSearch={this.handleSearch}
                      placeholder={"Search by network id, name, status"}
                    ></ItemsFilter>
                    <div className="text-gray-700 my-1 mx-1 text-sm">
                      {isSearching
                        ? `Found ${filteredItems.length} out of `
                        : ""}
                      {items.length} networks
                    </div>
                  </div>
                  <NetworksTable items={filteredItems}></NetworksTable>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Networks;
