import React from "react";
import { Switch, Route, NavLink, withRouter } from "react-router-dom";
import _ from "lodash";
import { VseAPI } from "apis";
import VseByNetworkViewer from "./VseByNetworkViewer";
import RecipesView from "./RecipesView";
import RecipeCreateViewer from "./RecipeCreateViewer";
import RecipeUpdateViewer from "./RecipeUpdateViewer";
import ChecksView from "./ChecksView";

const vsePaths = {
  network: "/vse/network/:networkId",
  checks: "/vse/checks",
  recipes: "/vse/recipes",
  recipeCreate: "/vse/recipe/create",
  recipeUpdate: "/vse/recipe/:recipeId/update",
};

class VseViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,

      recipes: null,
    };
  }

  async componentDidMount() {
    await this._queryAndUpdateUI();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      // Route changed!

      // console.log(
      //   "route changed",
      //   prevProps.location.pathname,
      //   this.props.location.pathname
      // );

      // Query recipes again if recipe has been created or updated
      if (
        _.startsWith(prevProps.location.pathname, "/vse/recipe") &&
        this.props.location.pathname === "/vse/recipes"
      ) {
        // console.log("query recipes");
        await this._queryAndUpdateUI();
      }
    }
  }

  async _queryAndUpdateUI(params) {
    this.setState({ isLoading: true });
    try {
      const { recipes } = await this.queryData(params);

      if (_.isEmpty(recipes)) {
        this.setState({ errMsg: "No recipes found" });
      }

      this.setState({
        recipes,
      });
    } catch (err) {
      console.log("Error querying vse recipes", err);
      this.setState({ errMsg: "Error querying vse recipes" });
    }
    this.setState({ isLoading: false });
  }

  async queryData(params) {
    const { recipes } = await VseAPI.getAllRecipes();
    // console.log(recipes);

    return { recipes };
  }

  render() {
    const { isLoading, errMsg, recipes } = this.state;

    let vseSection = null;
    const loadingSection = <div>Loading...</div>;
    const errMsgFullpage = (
      <div className="min-h-screen bg-white text-red-700">{errMsg}</div>
    );

    if (isLoading) {
      vseSection = loadingSection;
    }
    if (errMsg) {
      vseSection = errMsgFullpage;
    }

    return (
      <div>
        <div>{vseSection && vseSection}</div>
        {!vseSection && recipes && (
          <Switch>
            <Route
              path={vsePaths.network}
              render={(props) => {
                return (
                  <VseByNetworkViewer
                    {...props}
                    recipes={recipes}
                  ></VseByNetworkViewer>
                );
              }}
            ></Route>

            <Route
              path={vsePaths.recipes}
              render={(props) => {
                return (
                  <VSEBaseWrapper includeTabHeader={true}>
                    <RecipesView {...props} recipes={recipes}></RecipesView>
                  </VSEBaseWrapper>
                );
              }}
            ></Route>

            <Route
              path={vsePaths.recipeCreate}
              render={(props) => {
                return (
                  <VSEBaseWrapper>
                    <RecipeCreateViewer></RecipeCreateViewer>
                  </VSEBaseWrapper>
                );
              }}
            ></Route>

            <Route
              path={vsePaths.recipeUpdate}
              render={(props) => {
                return (
                  <VSEBaseWrapper>
                    <RecipeUpdateViewer></RecipeUpdateViewer>
                  </VSEBaseWrapper>
                );
              }}
            ></Route>

            <Route
              path={vsePaths.checks}
              render={(props) => {
                return (
                  <VSEBaseWrapper includeTabHeader={true}>
                    <ChecksView {...props} recipes={recipes}></ChecksView>
                  </VSEBaseWrapper>
                );
              }}
            ></Route>
          </Switch>
        )}
      </div>
    );
  }
}

const VSEBaseWrapper = (props) => {
  return (
    <div>
      <VSEBaseHeader></VSEBaseHeader>

      {props.includeTabHeader && <VSETabHeader></VSETabHeader>}

      <div className="bg-gray-200 px-12 py-8 min-h-screen">
        {props.children}
      </div>
    </div>
  );
};

const TABS = {
  RECIPES: {
    title: "Recipes",
    path: vsePaths.recipes,
  },
  CHECKS: {
    title: "Checks",
    path: vsePaths.checks,
  },
};
const VSETabHeader = (props) => {
  const activeTabClass =
    "py-1 px-6 mr-2 bg-gray-200 text-gray-900 rounded-t-lg border-b-4 border-gray-200";
  const inactiveTabClass =
    "py-1 px-6 mr-2 rounded-t-lg text-gray-500 bg-white hover:bg-gray-100 cursor-pointer";

  return (
    <div className="bg-white px-12 flex pt-2">
      {_.values(TABS).map((tab) => {
        return (
          <NavLink
            key={tab.title}
            to={tab.path}
            className={inactiveTabClass}
            // activeClassName="border-b border-blue-500"
            activeClassName={activeTabClass}
          >
            {tab.title}
          </NavLink>
        );
      })}
    </div>
  );
};

const VSEBaseHeader = (props) => {
  return (
    <div className="py-2 px-12 bg-gray-100 border-b">
      <div className="flex items-center justify-between">
        <div className="flex gap-4 items-center">
          <div className="font-semibold text-lg text-gray-800">VSE</div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(VseViewer);
