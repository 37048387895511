import React from "react";
import _ from "lodash";
import { NetworkAPI, UnitAPI } from "apis";
import NetworkPageWrapper from "components/diagnoser/NetworkPageWrapper";
import YieldSetsSelector from "components/ops-mgmt/account/YieldSetsSelector";
import ClickToCopyWrapper from "components/common/ClickToCopyWrapper";

const UNIT_STATUS = {
  RUNNING: 0,
  PAUSED: 1,
};

class UnitsSearchViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,

      isSearchLoading: false,
      searchErrMsg: null,

      // networkId: null,
      // networkInfo: null
      ysFilter: "ALL",
      selectedYieldSet: null,
      statusFilter: "ALL",

      filter: {},

      result: null,
    };

    this.handleFilterChanged = this.handleFilterChanged.bind(this);
    this.searchUnits = this.searchUnits.bind(this);
  }

  async componentDidMount() {
    const networkId = _.get(this.props, "match.params.networkId");
    this.setState({ networkId, isLoading: true });

    if (networkId) {
      document.title = `Search: ${networkId}`;

      try {
        const networkInfo = await NetworkAPI.getNetworkInfo({ networkId });
        this.setState({ networkInfo, errMsg: null });
      } catch (err) {
        this.setState({
          errMsg: err,
          networkInfo: null,
        });
      }
      this.setState({ isLoading: false });
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      // Route changed!
      // console.log(
      //   "route changed",
      //   prevProps.location.pathname,
      //   this.props.location.pathname
      // );
    }
  }

  handleFilterChanged(filterType, filterValue) {
    let filter = { ...this.state.filter };
    switch (filterType) {
      case "STATUS": {
        if (filterValue !== "ALL") {
          filter.status = UNIT_STATUS[filterValue];
        }

        this.setState({ statusFilter: filterValue });
      }
    }

    console.log(filter);
    this.setState({ filter });
  }

  async searchUnits() {
    this.setState({ isSearchLoading: true });
    try {
      const { filter, networkId, showKey } = this.state;
      const result = await UnitAPI.searchUnits({ networkId, filter });
      console.log(result);
      const resultItems = _.map(
        result,
        showKey === "EXT_GAM_UNIT_ID" ? "extUnitId" : "unitId"
      );
      this.setState({ result, resultItems });
    } catch (err) {
      console.log(err);
      this.setState({
        searchErrMsg: err,
      });
    }

    this.setState({ isSearchLoading: false });
  }

  render() {
    const {
      isLoading,
      errMsg,
      isSearchLoading,
      searchErrMsg,
      networkId,
      networkInfo,
      ysFilter,
      selectedYieldSet,
      statusFilter,
      isFilterDisabled = false,
      result,
    } = this.state;

    return (
      <>
        <BaseHeader title="Units Search"></BaseHeader>
        <NetworkPageWrapper
          isLoading={isLoading}
          errMsg={errMsg}
          networkInfo={networkInfo}
        >
          <div className="border mb-2 bg-white p-4">
            {/* <div className="mb-2 flex items-center gap-2 text-sm font-semibold">
              YieldSet:{" "}
              <button
                type="button"
                className={`rounded mr-1 px-2 py-1 font-semibold ${
                  ysFilter === "ALL"
                    ? "bg-indigo-100 text-indigo-800"
                    : "text-gray-700 hover:bg-gray-200 hover:text-indigo-800"
                }`}
                // onClick={() =>
                //   this.props.handleUnitsFilterChanged("YIELD_SET", "ALL")
                // }
              >
                ALL
              </button>
              <div className="w-1/2">
                {networkId && (
                  <YieldSetsSelector
                    networkId={networkId}
                    // handleOnChange={this.props.handleYsSelectChanged}
                    selectedYieldSet={selectedYieldSet}
                    defaultValue={ysFilter}
                  ></YieldSetsSelector>
                )}
              </div>
            </div> */}

            <div className="mb-2 text-sm font-semibold">
              Unit Status:{" "}
              <button
                type="button"
                className={`rounded mr-1 px-2 py-1 font-semibold ${
                  statusFilter === "ALL"
                    ? "bg-indigo-100 text-indigo-800"
                    : "text-gray-700 hover:bg-gray-200 hover:text-indigo-800"
                }`}
                onClick={() => this.handleFilterChanged("STATUS", "ALL")}
              >
                ONBOARDED
              </button>
              {_.keys(UNIT_STATUS).map((status) => {
                return (
                  <button
                    key={status}
                    type="button"
                    className={`rounded mr-1 px-2 py-1 font-semibold ${
                      statusFilter === status
                        ? "bg-indigo-100 text-indigo-800"
                        : "text-gray-700 hover:bg-gray-200 hover:text-indigo-800"
                    }`}
                    onClick={() => this.handleFilterChanged("STATUS", status)}
                  >
                    {status}
                  </button>
                );
              })}
            </div>

            {/*  */}

            <button
              type="button"
              onClick={this.searchUnits}
              className={`rounded mt-2 px-6 py-2 font-semibold text-white ${
                isFilterDisabled
                  ? "bg-blue-200"
                  : "shadow bg-blue-400 hover:bg-blue-500"
              }`}
              disabled={isFilterDisabled}
            >
              Get units
            </button>
          </div>

          {result && <ResultWrapper result={result}></ResultWrapper>}
        </NetworkPageWrapper>
      </>
    );
  }
}

class ResultWrapper extends React.PureComponent {
  constructor(props) {
    super(props);

    const showKey = "EXT_GAM_UNIT_ID";
    this.state = {
      showKey,
      result: props.result,
      resultItems: this._getResultItems(showKey, props.result),
    };

    this.handleShowKeyChanged = this.handleShowKeyChanged.bind(this);
  }

  _getResultItems(showKey, result) {
    return _.map(
      result,
      showKey === "EXT_GAM_UNIT_ID" ? "extUnitId" : "unitId"
    );
  }

  handleShowKeyChanged(showKey) {
    const { result } = this.state;
    const resultItems = this._getResultItems(showKey, result);
    this.setState({ showKey, resultItems });
  }

  render() {
    const { resultItems, showKey } = this.state;

    return (
      <div className="border bg-white p-4">
        <div className="mb-2 py-2">
          <div className="mb-2 text-sm font-semibold">
            Show:{" "}
            <button
              type="button"
              className={`rounded mr-1 px-2 py-1 font-semibold ${
                showKey === "EXT_GAM_UNIT_ID"
                  ? "bg-indigo-100 text-indigo-800"
                  : "text-gray-700 hover:bg-gray-200 hover:text-indigo-800"
              }`}
              onClick={() => this.handleShowKeyChanged("EXT_GAM_UNIT_ID")}
            >
              Ext Gam Unit Id
            </button>
            <button
              type="button"
              className={`rounded mr-1 px-2 py-1 font-semibold ${
                showKey === "GAM_UNIT_ID"
                  ? "bg-indigo-100 text-indigo-800"
                  : "text-gray-700 hover:bg-gray-200 hover:text-indigo-800"
              }`}
              onClick={() => this.handleShowKeyChanged("GAM_UNIT_ID")}
            >
              Unit Id
            </button>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="italic">{resultItems.length} units</div>

          <div className="rounded px-1 text-sm text-blue-500 hover:bg-blue-100">
            <ClickToCopyWrapper
              copyText={resultItems && resultItems.join(",")}
              buttonText="Copy"
            ></ClickToCopyWrapper>
          </div>
        </div>

        <textarea
          className="border rounded h-64 w-full px-2 py-1"
          value={resultItems && resultItems.join(",")}
          readOnly
        ></textarea>
      </div>
    );
  }
}

const BaseHeader = ({ title }) => {
  return (
    <div className="border-b bg-gray-100 py-2 px-12">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div className="text-lg font-semibold text-gray-800">{title}</div>
        </div>
      </div>
    </div>
  );
};

export default UnitsSearchViewer;
