import React from "react";
import _ from "lodash";
import ReactTooltip from "react-tooltip";

class SegmentData extends React.Component {
  render() {
    const { data, segmentId } = this.props;
    const segment = _.find(data.segmentInfo, { id: segmentId });
    const targeting = segment.targeting;

    return (
      <>
        <div className="text-base">{segment.intention}</div>
        <table className="ml-4 w-full text-sm">
          <tbody>
            <tr>
              <td className="border border-gray-600 px-2">
                {targeting.country ? (
                  targeting.country.includes ? (
                    <>
                      <div className="text-green-500">Includes</div>
                      <div>
                        {targeting.country.includes.map((value, i) => {
                          return (
                            <span key={i}>
                              {value}
                              {targeting.country.includes.length - 1 === i
                                ? ""
                                : ", "}
                            </span>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="text-red-500">Excludes</div>
                      <div>
                        {targeting.country.excludes.map((value, i) => {
                          return (
                            <span key={i}>
                              {value}
                              {targeting.country.excludes.length - 1 === i
                                ? ""
                                : ", "}
                            </span>
                          );
                        })}
                      </div>
                    </>
                  )
                ) : (
                  "-"
                )}
              </td>
              <td className="border border-gray-600 px-2">
                {targeting.os ? (
                  targeting.os.includes ? (
                    <>
                      <div className="text-green-500">Includes</div>
                      <div>
                        {targeting.os.includes.map((value, i) => {
                          return (
                            <span key={i}>
                              {value}
                              {targeting.os.includes.length - 1 === i
                                ? ""
                                : ", "}
                            </span>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="text-red-500">Excludes</div>
                      <div>
                        {targeting.os.excludes.map((value, i) => {
                          return (
                            <span key={i}>
                              {value}
                              {targeting.os.excludes.length - 1 === i
                                ? ""
                                : ", "}
                            </span>
                          );
                        })}
                      </div>
                    </>
                  )
                ) : (
                  "-"
                )}
              </td>
              <td className="border border-gray-600 px-2">
                {targeting.device_category ? (
                  targeting.device_category.includes ? (
                    <>
                      <div className="text-green-500">Includes</div>
                      <div>
                        {targeting.device_category.includes.map((value, i) => {
                          return (
                            <span key={i}>
                              {value}
                              {targeting.device_category.includes.length - 1 ===
                              i
                                ? ""
                                : ", "}
                            </span>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="text-red-500">Excludes</div>
                      <div>
                        {targeting.device_category.excludes.map((value, i) => {
                          return (
                            <span key={i}>
                              {value}
                              {targeting.device_category.excludes.length - 1 ===
                              i
                                ? ""
                                : ", "}
                            </span>
                          );
                        })}
                      </div>
                    </>
                  )
                ) : (
                  "-"
                )}
              </td>
              <td className="border border-gray-600 px-2">
                {targeting.hour ? (
                  targeting.hour.includes ? (
                    <>
                      <div className="text-green-500">Includes</div>
                      <div>
                        {targeting.hour.includes.map((value, i) => {
                          return (
                            <span key={i}>
                              {value}
                              {targeting.hour.includes.length - 1 === i
                                ? ""
                                : ", "}
                            </span>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="text-red-500">Excludes</div>
                      <div>
                        {" "}
                        {targeting.hour.excludes.map((value, i) => {
                          return (
                            <span key={i}>
                              {value}
                              {targeting.hour.excludes.length - 1 === i
                                ? ""
                                : ", "}
                            </span>
                          );
                        })}
                      </div>
                    </>
                  )
                ) : (
                  "-"
                )}
              </td>

              <td className="border border-gray-600 px-2">
                {targeting.sizes ? (
                  <>
                    <div className="text-green-500">Includes</div>
                    <div>
                      {targeting.sizes.map((sizeSet, i) => {
                        return (
                          <span key={i}>
                            {typeof sizeSet === "string"
                              ? sizeSet
                              : sizeSet.join("x")}
                            {targeting.sizes.length - 1 === i ? "" : ", "}
                          </span>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  "-"
                )}
              </td>

              <td className="border border-gray-600 px-2 align-bottom">
                {targeting.custom ? (
                  <>
                    <div
                      data-tip
                      data-for={`${segmentId}-custom-targeting`}
                      className="text-white"
                    >
                      custom
                    </div>

                    <ReactTooltip
                      id={`${segmentId}-custom-targeting`}
                      type="dark"
                      effect="solid"
                      place={"top"}
                    >
                      {JSON.stringify(targeting.custom)}
                    </ReactTooltip>
                  </>
                ) : (
                  "-"
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  }
}

export default SegmentData;
