import React from "react";
import _ from "lodash";
import { Switch, Route, Redirect } from "react-router-dom";

import SnapshotViewer from "./components/snapshot/SnapshotViewer";
import YieldSetSnapshotViewer from "./components/snapshot/YieldSetSnapshotViewer";
import ObserverViewer from "./components/observer/ObserverViewer";
import UnitInsightViewer from "./components/unit-insight/UnitInsightViewer";
import ControlCenterViewer from "./components/control-center/ControlCenterViewer";
import BizViewer from "./components/biz/BizViewer";
import ReportingStatusViewer from "./components/healthcheck/ReportingStatusViewer";
import GptRequestsComparison from "./components/tools/GptRequestsComparison";
import HomeDashboardViewer from "./components/playground/HomeDashboardViewer";
import BatchUpdateAPConfigs from "./components/tools/BatchUpdateAPConfigs";
import YbConsoleOpsLogViewer from "./components/console-ops-log/YbConsoleOpsLogViewer";
import OpsManagementViewer from "./components/ops-mgmt/OpsManagementViewer";
import IntowowBusinessViewer from "./components/intowow-business/I2wBusinessViewer";
import AnatomyDebugViewer from "./components/observer/AnatomyDebugViewer";
import SegmentResearchViewer from "./components/segment-research/SegmentResearchViewer";
import VseViewer from "./components/vse/VseViewer";
import VaultViewer from "./components/vault/VaultViewer";
import UsersViewer from "./components/vault/VaultUsersViewer";
import NetworkCstReportViewer from "components/cst/NetworkCstReportViewer";
import AnatomyCSTViewer from "components/anatomy-cst/AnatomyCSTViewer";
import DiagnoserViewer from "components/diagnoser/DiagnoserViewer";
import UnitsSearchViewer from "components/snapshot/UnitsSearchViewer";
import DxDyTrendsViewer from "components/tools/DxDyTrendsViewer";

const PATHS = {
  // console
  ybConsoleOpsLog: "/console/ops-log",

  // playground
  ybConsoleHomeDashboard: "/playground/console-overview-mockup/:pubId",
  ybConsoleHomeDashboardReseller:
    "/playground/console-overview-mockup-reseller/:pubId",

  // tools
  batchUpdate: "/tools/batch-update-auto-pilot-config",
  gptRequestsComparison: "/tools/gpt-requests-comparison",
  dxDyTrends: "/tools/dx-dy-trends",

  // control-center (ap configs)
  controlCenter: "/control-center",

  // auto pilot segment research configs
  segmentResearch: "/segment-research",

  // ops-mgmt
  opsManagement: "/ops-mgmt",

  // healthcheck
  reportingStatus: "/healthcheck/reporting-status",

  // biz
  bizOverview: "/biz/overview",
  bizNetwork: "/biz/network/:networkId",
  intowowRevenue: "/revenue/overview",
  intowowBilling: "/billing/overview",

  intowowBusiness: "/business",
  // businessBilling: "/business/billing",

  // unit-insight
  unitInsight: "/unit-insight/:unitId",

  // cst-report
  cstReport: "/cst/report/:networkId",

  // snapshot
  snapshot: "/snapshot/:snapshotKey",
  snapshotLatest: "/snapshot/latest",
  yieldSetSnapshot: "/network/:networkId/yield-set-snapshot",

  // observer
  observer: "/observer/:unitId",
  anatomy: "/anatomy/:unitId", // just a rename for observer
  anatomyDebug: "/anatomy/debug/:expId",

  // cst
  anatomyCST: "/anatomy/cst/:unitId",

  // vse
  vseBase: "/vse",

  diagnoserBase: "/diagnoser",

  // vault
  vaultBase: "/vault-accounts",

  // users
  usersBase: "/vault-users",

  // units search
  unitsSearch: "/network/:networkId/units/search",
};

function MainRouter() {
  return (
    <>
      <div className="relative h-screen">
        <Switch>
          <Route
            path={PATHS.ybConsoleOpsLog}
            component={YbConsoleOpsLogViewer}
          ></Route>

          <Route
            path={PATHS.ybConsoleHomeDashboard}
            component={HomeDashboardViewer}
          ></Route>

          <Route
            path={PATHS.ybConsoleHomeDashboardReseller}
            component={HomeDashboardViewer}
          ></Route>

          {/* TOOLS */}
          <Route
            path={PATHS.batchUpdate}
            component={BatchUpdateAPConfigs}
          ></Route>

          <Route
            path={PATHS.gptRequestsComparison}
            component={GptRequestsComparison}
          ></Route>

          <Route path={PATHS.dxDyTrends} component={DxDyTrendsViewer}></Route>

          <Route
            path={PATHS.controlCenter}
            component={ControlCenterViewer}
          ></Route>

          <Route
            path={PATHS.segmentResearch}
            component={SegmentResearchViewer}
          ></Route>

          <Route
            path={PATHS.opsManagement}
            component={OpsManagementViewer}
          ></Route>

          <Route
            path={PATHS.reportingStatus}
            component={ReportingStatusViewer}
          ></Route>

          <Route path={PATHS.bizOverview} component={BizViewer}></Route>
          <Route path={PATHS.bizNetwork} component={BizViewer}></Route>

          <Route path={PATHS.vseBase} component={VseViewer}></Route>
          <Route path={PATHS.diagnoserBase} component={DiagnoserViewer}></Route>

          <Route path={PATHS.vaultBase} component={VaultViewer}></Route>
          <Route path={PATHS.usersBase} component={UsersViewer}></Route>

          <Route
            path={PATHS.intowowBusiness}
            component={IntowowBusinessViewer}
          ></Route>

          <Route
            exact
            path={PATHS.unitInsight}
            component={UnitInsightViewer}
          ></Route>

          <Route
            exact
            path={PATHS.cstReport}
            component={NetworkCstReportViewer}
          ></Route>

          <Route
            exact
            path={PATHS.yieldSetSnapshot}
            component={YieldSetSnapshotViewer}
          ></Route>

          <Route exact path={PATHS.snapshot} component={SnapshotViewer}></Route>
          <Route
            exact
            path={PATHS.unitsSearch}
            component={UnitsSearchViewer}
          ></Route>

          <Route
            path={PATHS.anatomyCST}
            render={(props) => {
              const unitId = _.get(props, "match.params.unitId");
              return (
                <AnatomyCSTViewer
                  key={unitId}
                  unitId={unitId}
                ></AnatomyCSTViewer>
              );
            }}
          ></Route>

          <Route
            path={PATHS.anatomyDebug}
            render={(props) => {
              const expId = _.get(props, "match.params.expId");
              return (
                <AnatomyDebugViewer
                  key={expId}
                  expId={expId}
                ></AnatomyDebugViewer>
              );
            }}
          ></Route>

          <Route
            path={PATHS.observer}
            render={(props) => {
              const unitId = _.get(props, "match.params.unitId");
              return (
                <ObserverViewer key={unitId} unitId={unitId}></ObserverViewer>
              );
            }}
          ></Route>

          <Route
            path={PATHS.anatomy}
            render={(props) => {
              const unitId = _.get(props, "match.params.unitId");
              return (
                <ObserverViewer key={unitId} unitId={unitId}></ObserverViewer>
              );
            }}
          ></Route>

          <Route
            exact
            path="/"
            render={() => <Redirect to={PATHS.snapshotLatest}></Redirect>}
          ></Route>
        </Switch>
      </div>
    </>
  );
}

export default MainRouter;
