import React from "react";
import _ from "lodash";
import Select from "react-select";

import { BillingAPI } from "apis";

class BankAccountSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      options: [],
      selectedValue: null,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    const items = await BillingAPI.getBankAccounts();
    console.log(items);

    let options = _.map(items, (r) => {
      return {
        value: r.bankAccountId,
        label: `[${r.bankAccountId}] ${r.accountName} (${r.accountNo})`,
      };
    });

    const selectedValue = _.find(options, { value: this.props.selectedValue });
    console.log(selectedValue, this.props.selectedValue);

    this.setState({
      items,
      options,
      selectedValue,
    });
  }

  handleChange(option) {
    this.setState({
      selectedValue: option,
    });

    // this.props.handleOnChange(option.value);

    if (this.props.isMulti) {
      const selectedValues = _.map(option, "value");
      const results = _.filter(this.state.items, (item) => {
        return _.indexOf(selectedValues, item.bankAccountId) !== -1;
      });
      this.props.handleOnChange(results);
    } else {
      const result = _.find(this.state.items, (item) => {
        return option.value === item.bankAccountId;
      });
      this.props.handleOnChange(result);
    }
  }

  render() {
    const { options, selectedValue } = this.state;

    return (
      <>
        <Select
          maxMenuHeight="80px"
          value={selectedValue}
          onChange={this.handleChange}
          options={options}
          isMulti={this.props.isMulti}
        />
      </>
    );
  }
}

export default BankAccountSelector;
