import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import Checkbox from "components/common/Checkbox";
// import { FiChevronDown } from "react-icons/fi";
// import CustomDateRangeButton from "components/common/CustomDateRangeButton";

function _convertDateToYMD(date) {
  const dd = new Date(date);
  let d = dd.getDate();
  let m = dd.getMonth() + 1; // Month from 0 to 11
  let y = dd.getFullYear();
  let finalString = `${y}-${m <= 9 ? "0" + m : m}-${d <= 9 ? "0" + d : d}`;
  return finalString;
}

function _getDateRangeDates(
  dateRange,
  granularity,
  timezone = "Asia/Taipei",
  includeCurrentDay
) {
  // ex. dateRange: 3, granularity: 'day'
  let today = moment.tz(timezone);
  today = includeCurrentDay ? today.startOf("hour") : today.endOf("day");
  // const today = moment.tz(timezone).startOf("hour"); //.endOf("day"); //.startOf("hour");
  const DATEFORMAT = "YYYY-MM-DD HH:mm";
  const endDate = today
    .clone()
    .subtract(includeCurrentDay ? 0 : 1, "day")
    .format(DATEFORMAT);
  const endDateNotIncludeToday = today
    .clone()
    .endOf("day")
    .subtract(1, "day")
    .format(DATEFORMAT);
  const startDate = today
    .clone()
    .endOf("day")
    .subtract(dateRange, granularity)
    .startOf("day")
    .format(DATEFORMAT);

  return { startDate, endDate, endDateNotIncludeToday };
}

const DEFAULT_DATE_RANGES = [3, 7, 14, 30]; // days
class DateRangeFilter extends React.Component {
  constructor(props) {
    super(props);

    const {
      dateRangeGranularity = "day",
      dateRanges = DEFAULT_DATE_RANGES,
      selectedDateRange,
      // startDate,
      // endDate,
      timezone, // optional
    } = props;

    const includeCurrentDay = true; // always include today
    let { startDate, endDate, endDateNotIncludeToday } = _getDateRangeDates(
      selectedDateRange,
      dateRangeGranularity,
      timezone,
      includeCurrentDay
    );
    this.props.onDateRangeChange({
      startDate,
      endDate,
      endDateNotIncludeToday,
      dateRangeNum: selectedDateRange,
    });

    this.state = {
      dateRangeGranularity,
      dateRanges,

      startDate,
      endDate,
      includeCurrentDay,

      currentDateRange: selectedDateRange,
      isDateRangeCustom: false,
    };

    this.handleDateRangeChanged = this.handleDateRangeChanged.bind(this);
    this.handleCustomDateRangeChanged =
      this.handleCustomDateRangeChanged.bind(this);
    this.handleDateRangeCanceled = this.handleDateRangeCanceled.bind(this);
    this.handleIncludeCurrentDayChanged =
      this.handleIncludeCurrentDayChanged.bind(this);

    this.handleFilter = this.handleFilter.bind(this);
  }

  handleIncludeCurrentDayChanged() {
    this.setState({ includeCurrentDay: !this.state.includeCurrentDay });
    setTimeout(() => {
      this.handleDateRangeChanged(this.state.currentDateRange);
    });
  }

  handleDateRangeChanged(dr) {
    if (dr === "CUSTOM") {
      // this.setState({ currentDateRange: dr, startDate: null, endDate: null });
      this.setState({
        isDateRangeCustom: true,
        startDate: null,
        endDate: null,
      });
    } else {
      const { startDate, endDate, endDateNotIncludeToday } = _getDateRangeDates(
        dr,
        this.state.dateRangeGranularity,
        this.props.timezone,
        this.state.includeCurrentDay
      );
      this.setState({
        isDateRangeCustom: false,
        currentDateRange: dr,
        startDate,
        endDate,
      });
      this.props.onDateRangeChange({
        startDate,
        endDate,
        endDateNotIncludeToday,
        dateRangeNum: dr,
      });
    }
  }

  // startDate, endDate are date objects
  handleCustomDateRangeChanged({ startDate, endDate }) {
    this.setState({
      isDateRangeCustom: true,
      startDate: _convertDateToYMD(startDate),
      endDate: _convertDateToYMD(endDate),
    });
  }

  handleDateRangeCanceled() {
    // currentDateRange is not changed when choosing custom date range
    // so we know the previous selected date range
    this.handleDateRangeChanged(this.state.currentDateRange);
    // this.setState({ isDateRangeCustom: false });
  }

  handleFilter() {
    const { startDate, endDate } = this.state;
    const params = {
      startDate,
      endDate,
    };
    this.props.handleFilter(params);
  }

  render() {
    const { timezone } = this.props;
    const {
      dateRangeGranularity,
      dateRanges,
      currentDateRange,
      startDate,
      endDate,
      isDateRangeCustom,
      includeCurrentDay,
    } = this.state;
    const isFilterDisabled = this.props.isLoading;

    return (
      <div className="mb-8">
        <div>
          <div className="mb-1 font-semibold text-indigo-900">
            Date Range:{" "}
            <span className="text-sm text-gray-800">
              ( <span className="font-normal text-gray-600">From</span>{" "}
              {_formatDate(startDate)}{" "}
              <span className="font-normal text-gray-600">to</span>{" "}
              {_formatDate(endDate)}
              <span className="font-normal text-gray-600">
                {" "}
                in {timezone}
              </span>{" "}
              )
            </span>
          </div>

          <div className="mb-4 flex items-center justify-start text-sm">
            {dateRanges.map((dr) => {
              return (
                <button
                  key={dr}
                  type="button"
                  className={`rounded mr-1 px-4 py-2 font-semibold ${
                    !isDateRangeCustom && currentDateRange === dr
                      ? "bg-indigo-200 text-indigo-800"
                      : "text-gray-700 hover:bg-gray-200 hover:text-indigo-800"
                  }`}
                  onClick={() => this.handleDateRangeChanged(dr)}
                >
                  Last {dr > 1 && dr} {dateRangeGranularity}
                  {dr > 1 && "s"}
                </button>
              );
            })}
            {/* Always include today for now */}
            {/* <label className="rounded flex cursor-pointer items-center bg-blue-100 px-4 py-2">
              <Checkbox
                isChecked={includeCurrentDay}
                onChange={this.handleIncludeCurrentDayChanged}
              ></Checkbox>
              <span className="ml-1 align-middle text-sm font-semibold text-gray-600 hover:text-gray-700">
                Include Today?
              </span>
            </label> */}
            {/* <CustomDateRangeButton
              onOpen={() => this.handleDateRangeChanged("CUSTOM")}
              handleDateRangeChanged={this.handleCustomDateRangeChanged}
              handleDateRangeCanceled={this.handleDateRangeCanceled}
              customButton={
                <button
                  type="button"
                  className={`rounded mr-1 px-4 py-2 font-semibold ${
                    isDateRangeCustom
                      ? "bg-indigo-200 text-indigo-800"
                      : "text-gray-700 hover:bg-gray-200 hover:text-indigo-800"
                  }`}
                >
                  <div className="flex items-center gap-2">
                    {isDateRangeCustom && startDate && endDate ? (
                      <span>
                        Custom:
                        <span className="mx-1">
                          <span className="font-normal text-gray-600">
                            From
                          </span>{" "}
                          {startDate}{" "}
                          <span className="font-normal text-gray-600">to</span>{" "}
                          {endDate}
                        </span>
                      </span>
                    ) : (
                      "Custom "
                    )}
                    <FiChevronDown className="text-gray-500"></FiChevronDown>
                  </div>
                </button>
              }
            ></CustomDateRangeButton> */}
          </div>
        </div>
      </div>
    );
  }
}

function _formatDate(date) {
  return moment(date).format("YYYY-MM-DD HH:mm ddd");
}

export default DateRangeFilter;
