import React from "react";
import _ from "lodash";
import Checkbox from "components/common/Checkbox";
import CurrencyFormat from "components/common/CurrencyFormat";
import { getGroupTypeColor } from "./AnatomyHighchartHelper";

class SegmentStickyHeader extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedGroupTypes: props.selectedGroupTypes,
      includeToday: props.includeToday,
    };

    this.handleGroupTypeSelected = this.handleGroupTypeSelected.bind(this);
    this.handleIncludeTodayChanged = this.handleIncludeTodayChanged.bind(this);
  }

  handleGroupTypeSelected(groupType) {
    let { selectedGroupTypes } = this.state;
    let groupTypes = null;

    if (groupType === "ALL") {
      const isAllSelected =
        selectedGroupTypes.length === this.props.groupTypes.length;
      groupTypes = isAllSelected ? [] : this.props.groupTypes;
    } else {
      if (_.indexOf(selectedGroupTypes, groupType) === -1) {
        groupTypes = [...selectedGroupTypes, groupType];
      } else {
        groupTypes = _.without(selectedGroupTypes, groupType);
      }
    }

    this.setState({
      selectedGroupTypes: groupTypes,
    });

    this.props.handleSelectedGroupTypes(groupTypes);
  }

  handleIncludeTodayChanged() {
    const newIncludeToday = !this.state.includeToday;
    this.setState({ includeToday: newIncludeToday });
    this.props.handleIncludeTodayChanged(newIncludeToday);
  }

  render() {
    const { uprSegmentId, items, groupTypes, detectorFloorPriceMap, currency } =
      this.props;
    const { selectedGroupTypes, includeToday } = this.state;
    const segment = _.find(items, { upr_segment_id: uprSegmentId });
    const isAllSelected = selectedGroupTypes.length === groupTypes.length;

    return (
      <div
        id="anatomy-chart-head"
        className="shadow sticky top-0 z-50 mt-4 flex items-center justify-between bg-blue-100 px-4 py-2"
      >
        <div className="text-lg font-bold">
          {segment.upr_segment_id} {segment.name}
        </div>

        <div className="flex items-center">
          <label className="rounded flex cursor-pointer items-center bg-blue-100 px-4 py-2">
            <Checkbox
              isChecked={includeToday}
              onChange={this.handleIncludeTodayChanged}
            ></Checkbox>
            <span className="ml-1 align-middle text-sm font-semibold text-gray-600 hover:text-gray-700">
              Include Today?
            </span>
          </label>
        </div>

        <div className="flex gap-6">
          <button
            type="button"
            className="rounded border border-gray-400 bg-gray-100 px-2 text-xs text-gray-800 hover:border-gray-500 hover:text-gray-900"
            onClick={() => this.handleGroupTypeSelected("ALL")}
          >
            {isAllSelected ? "Clear" : "Select"} All
          </button>

          {groupTypes.map((groupType) => {
            const floorPrice = detectorFloorPriceMap[groupType];

            return (
              <div key={groupType} className="flex flex-col items-end">
                <label
                  className="flex cursor-pointer items-center gap-1 border-b-2"
                  style={{
                    borderColor: getGroupTypeColor(
                      groupType,
                      groupTypes.length > 10 ? 10 : 5 // should filter b, o to get numOfDetector
                    ),
                  }}
                >
                  <Checkbox
                    isChecked={_.indexOf(selectedGroupTypes, groupType) !== -1}
                    onChange={() => this.handleGroupTypeSelected(groupType)}
                  ></Checkbox>
                  <span className="align-middle text-sm font-semibold text-gray-700 hover:text-gray-800">
                    {groupType}
                  </span>
                </label>

                {!_.isNil(floorPrice) && (
                  <span className="text-sm leading-none text-gray-800">
                    <small>
                      <span className="text-gray-700">
                        <CurrencyFormat currency={currency}></CurrencyFormat>
                      </span>
                      {floorPrice}
                    </small>
                  </span>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default SegmentStickyHeader;
