import React from "react";
// import Zoom from "react-reveal/Zoom"; // Importing Zoom effect
import Fade from "react-reveal/Fade";

class FadeInWrapper extends React.PureComponent {
  render() {
    return <Fade>{this.props.children}</Fade>;
  }
}

export default FadeInWrapper;
