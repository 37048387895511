import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import HighchartChart from "../../common/HighchartChart";

// import { isWeekend } from "../../../helpers/DateTransform";
const colors = [
  // "#058DC7", // blue
  // "#50B432", // green
  "#e17501", // ...orange
  "#E1AD01", // ...yellow
  "#ED561B", // bright orange
  // "#DDDF00", // bright yellow
  "#24CBE5", // light blue
  "#64E572", // bright green
  "#6AF9C4", // green yellow
];

class RequestChartSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      optionsChart1: createChartOptions1(props.reports),
    };
  }

  render() {
    const { optionsChart1 } = this.state;

    return (
      <>
        <div className="flex py-4">
          <div className="w-1/2">
            <div className="text-gray-800 font-semibold text-center mb-3 pl-8">
              Total Request vs Managed Request
            </div>
            <HighchartChart options={optionsChart1}></HighchartChart>
          </div>
        </div>
      </>
    );
  }
}

function createChartOptions1(reports) {
  // other_cs_imp_loss: 2.35
  // disp_cs_mr_loss: 2.03
  const metrics = [
    {
      text: "Total Request",
      key: "total_request",
      color: "#768489",
      // color: colors[2],
      // tooltipExtra: {
      //   text: "Raw Eligible Rev",
      //   key: "rawEligibleRevenue",
      // },
    },
    {
      text: "Managed Request",
      key: "managed_request",
      // color: colors[3],
      color: "#02A8E0",
      // tooltipExtra: {
      //   text: "Billable Raw Eligible Rev",
      //   key: "billableRevenue",
      // },
    },
  ];
  let minY = 0;
  let maxY = 100;
  const series = _.map(metrics, (m) => {
    return {
      name: m.text,
      color: m.color,
      data: _.map(reports, (r) => {
        const y = r[m.key];
        minY = y < minY ? y : minY;
        maxY = y > maxY ? y : maxY;
        return {
          x: new Date(r.date).getTime(),
          y,
          // tooltipExtra: {
          //   name: m.tooltipExtra.text,
          //   data: r[m.tooltipExtra.key],
          // },
        };
      }),
    };
  });

  const options = {
    title: {
      text: null,
    },
    chart: {
      type: "line",
      zoomType: "x",
      height: 260,
      backgroundColor: "rgba(0,0,0,0)",
    },
    tooltip: {
      xDateFormat: "%Y/%m/%d %a",
      headerFormat:
        '<div style="font-size:10px; font-weight: bold; text-align: center;">{point.key}</div><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0;font-weight: bold; text-align: right;padding-right: 4px;">{series.name}: </td>' +
        '<td style="padding:0; text-align: right;"><b>{point.y}</b></td></tr>' +
        // '<tr><td style="color:{series.color};padding:0;font-weight: bold; text-align: right;padding-right: 4px;">{point.tooltipExtra.name}: </td>' +
        // '<td style="padding:0; text-align: right;"><b>${point.tooltipExtra.data:,.2f}</b></td></tr>',
        "",
      footerFormat: "</table>",
      // valueDecimals: 2,
      shared: true,
      useHTML: true,
      crosshairs: true,
    },
    xAxis: getXAxisConfigDateTime(),
    yAxis: {
      title: {
        text: "Req",
      },
      // min: minY,
      // max: maxY,
    },
    series,
  };

  return options;
}

function getXAxisConfigDateTime() {
  return {
    type: "datetime",
    labels: {
      formatter: function () {
        const m = moment(this.value);
        const d = m.format("MM/DD");
        const wd = m.format("ddd");
        const label = `${d} <br/> ${wd}`;
        // if (isWeekend(this.value)) {
        //   return `<span style="color: #dd6b20;">${label}</span>`;
        // }
        return label;
      },
    },
    tickInterval: 24 * 60 * 60 * 1000, // 1 day
    crosshair: true,
  };
}

export default RequestChartSection;
