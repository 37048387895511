import React from "react";
import _ from "lodash";

import ModalWrapper from "../../../common/ModalWrapper";
import Checkbox from "components/common/Checkbox";
import LoadingUI from "components/common/LoadingUI";
import CSTIntegrationControl from "./CSTIntegrationControl";

const inputClass =
  "bg-white flex border border-gray-400 rounded py-1 px-3 appearance-none leading-normal focus:outline-none focus:border-blue-400 focus:shadow-inner hover:border-gray-500";
const inputClassDisabled =
  "bg-gray-300 flex border border-gray-400 rounded py-1 px-3 appearance-none leading-normal";

const titleClass = "block text-gray-800 text-sm font-bold"; //"font-semibold";

const INVENTORY_INTEGRATIONS = {
  GPT: "gpt",
  MOBILE_ANDROID: "mobile_android",
  MOBILE_IOS: "mobile_ios",
  VIDEO: "video",
  AMP: "amp",
};
class RegisteredInventorySuggestionModal extends React.Component {
  constructor(props) {
    super(props);

    const integrations = _.values(INVENTORY_INTEGRATIONS);
    const checked = _.reduce(
      integrations,
      (r, int) => {
        r[int] = { enabled: false, min_traffic_ratio: 0.3 };
        if (int === "gpt") {
          r[int] = { enabled: true, min_traffic_ratio: 0.3 };
        }
        return r;
      },
      {}
    );

    this.state = {
      integrations,
      checked,
      isLoading: false,
      errMsg: null,
      suggestion: null,

      isInvalid: false,
    };

    // this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleGetSuggestion = this.handleGetSuggestion.bind(this);
  }

  handleCancel() {
    this.setState({
      integrations: null,
      checked: null,
      isLoading: false,
      errMsg: null,
      suggestion: null,
      isValid: null,
    });
    this.props.handleCloseModal();
  }

  async handleGetSuggestion() {
    const { checked } = this.state;
    console.log(checked);

    this.setState({ isLoading: true, suggestion: null });
    try {
      const r = await this.props.getRISuggestion(checked);
      this.setState({ errMsg: null, isLoading: false, suggestion: r });
    } catch (err) {
      this.setState({ errMsg: err, isLoading: false, suggestion: null });
    }
  }

  handleSave() {
    const { suggestion } = this.state;
    this.props.handleSave(suggestion.config.inventory_integration);
  }

  render() {
    const { isModalOpen } = this.props;
    const { integrations, checked, isLoading, errMsg, suggestion } = this.state;

    const isInvalid = !suggestion;
    return (
      <ModalWrapper
        isOpen={isModalOpen}
        showCloseFooter={false}
        handleClose={this.handleCloseModal}
        width="80%"
      >
        <div className="mb-4 text-xl font-bold border-b py-4 px-6 -mx-6 -mt-5">
          Inventory Integrations Suggestion
        </div>

        <div className="text-sm text-gray-700">
          Suggestion will be based on "inventory_format", "dc", "os" - if their
          impression ratio is larger than the one specified (0.3: 30%).
        </div>
        <div className="flex items-center justify-between bg-gray-200 border p-2">
          <div className="flex gap-4 items-center">
            {integrations.map((integration) => {
              return (
                <div key={integration}>
                  <label className="cursor-pointer flex items-center">
                    <Checkbox
                      isChecked={checked[integration].enabled}
                      onChange={() => {
                        let newChecked = {
                          ...this.state.checked,
                        };
                        newChecked[integration].enabled =
                          this.state.checked[integration].enabled === true
                            ? false
                            : true;
                        this.setState({ checked: newChecked });
                      }}
                    ></Checkbox>
                    <span className="mx-1 align-middle text-gray-800 hover:text-gray-700 font-medium text-sm">
                      {integration}
                    </span>
                    <div>
                      <input
                        type="number"
                        className={inputClass}
                        style={{ width: "80px" }}
                        min={0}
                        step={0.1}
                        value={checked[integration].min_traffic_ratio}
                        onChange={(e) => {
                          let newChecked = {
                            ...this.state.checked,
                          };
                          newChecked[integration].min_traffic_ratio =
                            parseFloat(e.target.value);
                          this.setState({ checked: newChecked });
                        }}
                      ></input>
                    </div>
                  </label>
                </div>
              );
            })}
          </div>
          <button
            type="button"
            onClick={this.handleGetSuggestion}
            className="bg-white px-4 py-1 border rounded hover:shadow"
          >
            Get Suggestion
          </button>
        </div>
        {suggestion && (
          <div className="text-lg font-bold border-b mt-4">Preview: </div>
        )}

        <div className="overflow-y-auto" style={{ maxHeight: "430px" }}>
          {isLoading && <LoadingUI></LoadingUI>}

          {errMsg && <div className="text-red-600">{errMsg}</div>}

          {suggestion && (
            <div className="text-sm">
              {integrations.map((item) => {
                return (
                  <CSTIntegrationControl
                    key={item}
                    integration={item}
                    config={suggestion.config.inventory_integration}
                    isReadonly={true}
                  ></CSTIntegrationControl>
                );
              })}
            </div>
          )}
        </div>

        <div className="flex flex-row-reverse mt-4 items-center">
          <div>
            {!isInvalid && (
              <button
                type="button"
                className={`px-4 py-2 ${
                  isInvalid
                    ? "bg-gray-400 text-gray-800"
                    : "bg-blue-500 hover:bg-blue-700 text-white"
                }  rounded shadow font-semibold`}
                onClick={this.handleSave}
                disabled={isInvalid}
              >
                Merge with current config
              </button>
            )}
          </div>
          <div>
            <button
              type="button"
              disabled={false}
              className="px-4 py-2 text-blue-700"
              onClick={this.handleCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </ModalWrapper>
    );
  }
}

export default RegisteredInventorySuggestionModal;
