import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import ReactTooltip from "react-tooltip";
import ExperimentGroups from "./ExperimentGroups";

class ExperimentDetail extends React.Component {
  constructor(props) {
    super(props);

    this.handleGoToExp = this.handleGoToExp.bind(this);
  }

  handleGoToExp(expId) {
    if (!expId) return;
    this.props.goToExp(expId);
  }

  render() {
    const { data, expId, expLink } = this.props;
    const { groupInfo, expInfo } = data;

    const exp = _.find(data.yieldLifts, { expId: expId });

    const reports = exp.reports;

    return (
      <>
        {/* Experiment Title */}
        <div className="bg-gray-400 px-4 pb-2 rounded-t sticky top-0">
          <div className="font-mono flex justify-between pt-4 pb-2">
            <div>
              EXP-{exp.expId}{" "}
              <span
                className="border-b border-dashed border-gray-600"
                data-tip
                data-for={`parameters-${exp.expId}`}
              >
                {expInfo[exp.expId].name}
              </span>
              <ReactTooltip
                id={`parameters-${exp.expId}`}
                type="dark"
                effect="solid"
              >
                <div>
                  <pre>
                    {JSON.stringify(expInfo[exp.expId].parameters, null, 2)}
                  </pre>
                </div>
              </ReactTooltip>
            </div>
            <button
              type="button"
              className="underline"
              onClick={this.props.close}
            >
              Close
            </button>
          </div>

          <div className="flex justify-between">
            <div className="text-2xl font-bold text-gray-900 flex justify-between">
              <span className="mr-2">
                {moment(exp.startDate)
                  .tz("Asia/Taipei")
                  .format("YYYY-MM-DD HH:mm")}{" "}
                GMT+8
              </span>
              <span className="mx-2 text-gray-600">·</span>
              <span className="mx-2">Mode: {expInfo[exp.expId].mode}</span>
              <span className="mx-2 text-gray-600">·</span>

              {exp.grossLift && (
                <div>
                  GrossLift: {exp.grossLift}%
                  <span className="mx-2 text-gray-600">·</span>
                  NetLift: {exp.netLift}%
                </div>
              )}
            </div>
            <div className="inline-flex">
              <button
                type="button"
                onClick={() => this.handleGoToExp(expLink[exp.expId].prev)}
                className={`text-white font-bold py-2 px-4 rounded ${
                  expLink[exp.expId].prev
                    ? "bg-blue-500 hover:bg-blue-700"
                    : "bg-gray-500"
                }`}
              >
                Previous
              </button>

              <button
                type="button"
                onClick={() => this.handleGoToExp(expLink[exp.expId].next)}
                className={`text-white font-bold py-2 px-4 rounded ml-1 ${
                  expLink[exp.expId].next
                    ? "bg-blue-500 hover:bg-blue-700"
                    : "bg-gray-500"
                }`}
              >
                Next
              </button>
            </div>
          </div>
        </div>

        {/* Experiment Groups */}
        <div className="px-4 bg-gray-200">
          <ExperimentGroups
            reports={reports}
            groupInfo={groupInfo}
            expId={exp.expId}
          ></ExperimentGroups>
        </div>
      </>
    );
  }
}

export default ExperimentDetail;
