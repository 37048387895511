import React from "react";
import _ from "lodash";
import CustomTargetingKeysSelector from "components/ops-mgmt/account/cst-config/CustomTargetingKeysSelector";
import CustomTargetingValuesSelector from "components/ops-mgmt/account/cst-config/CustomTargetingValuesSelector";

const OPERATOR = {
  IS: "IS",
  IS_NOT: "IS_NOT",
};

const basicInputClass =
  "bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded py-1 px-2 block appearance-none leading-normal";

class CustomTargetingControlV2 extends React.PureComponent {
  constructor(props) {
    super(props);
    // props:
    // customValue
    // handleAddNewSet
    const {
      keyId = "",
      valueIds = [],
      operator = OPERATOR.IS,
    } = props.customValue;

    this.state = {
      keyId,
      valueIds,
      valueIdsString: valueIds ? valueIds.join(",") : "",
      operator,

      useSelector: true,

      errorMsg: null,
    };

    this.handleKeyIdInputChanged = this.handleKeyIdInputChanged.bind(this);
    this.handleKeyIdChanged = this.handleKeyIdChanged.bind(this);
    this.handleValueIdsInputChanged =
      this.handleValueIdsInputChanged.bind(this);
    this.handleValueIdsChanged = this.handleValueIdsChanged.bind(this);
    this.handleOPChanged = this.handleOPChanged.bind(this);

    this.handleAddCustom = this.handleAddCustom.bind(this);
    this.handleCancelCustom = this.handleCancelCustom.bind(this);

    this.handleIsUseSelectorChanged =
      this.handleIsUseSelectorChanged.bind(this);
  }

  handleAddCustom() {
    const { keyId, valueIdsString, valueIds, operator } = this.state;
    if (!keyId || keyId === "") {
      this.setState({ errorMsg: "Missing Key ID" });
      return;
    }
    if (!valueIdsString || valueIdsString === "") {
      this.setState({ errorMsg: "Missing Value IDs" });
      return;
    }

    // keyId, valueIds needs to be saved as string...
    let finalValue = {
      keyId: keyId + "",
      valueIds: _.map(valueIds, (id) => id + ""),
      operator,
    };
    this.props.handleAddNewSet({ newSet: finalValue, outerIndex: null });

    this.setState({
      errorMsg: null,
    });
  }

  handleCancelCustom() {
    this.setState({
      keyId: "",
      valueIds: [],
      operator: OPERATOR.IS,
      valueIdsString: "",

      errorMsg: null,
    });
    this.props.handleCancelNewSet();
  }

  handleKeyIdInputChanged(e) {
    const keyId = e.target.value;
    this.setState({ keyId });
  }

  handleKeyIdChanged(option) {
    this.setState({ keyId: option ? option.value : "" });
  }

  handleValueIdsInputChanged(e) {
    const valueIdsString = e.target.value;
    this.setState({ valueIdsString });

    try {
      const trimmedString = valueIdsString.replace(/\s/g, ""); // remove whitespace
      const valueIds = _.compact(_.split(trimmedString, ","));
      this.setState({ valueIds });
    } catch (err) {
      console.log(err);
    }
  }

  handleValueIdsChanged(options) {
    const valueIds = _.map(options, "value");
    this.setState({ valueIds, valueIdsString: valueIds.join(",") });
  }

  handleOPChanged(operator) {
    this.setState({ operator });
  }

  handleIsUseSelectorChanged() {
    this.setState({
      useSelector: !this.state.useSelector,

      keyId: "",
      valueIds: [],
      operator: OPERATOR.IS,
      valueIdsString: "",
      errorMsg: null,
    });
  }

  render() {
    const { networkId } = this.props;
    const { keyId, valueIds, valueIdsString, operator, errorMsg, useSelector } =
      this.state;

    return (
      <>
        <div className="rounded border shadow my-2 border-gray-500 bg-white p-2">
          {/* <div className="border-b">New Custom</div> */}
          <div>
            <div className="flex items-center justify-between font-semibold">
              Key ID:
              <button
                type="button"
                className="text-blue-600 hover:text-blue-700"
                onClick={this.handleIsUseSelectorChanged}
              >
                {useSelector ? "Manually input IDs" : "Choose from Selector"}
              </button>
            </div>
            {useSelector ? (
              <CustomTargetingKeysSelector
                keyId={keyId}
                networkId={networkId}
                handleOnChange={this.handleKeyIdChanged}
              ></CustomTargetingKeysSelector>
            ) : (
              <input
                type="text"
                value={keyId}
                className={basicInputClass}
                onChange={this.handleKeyIdInputChanged}
              ></input>
            )}
          </div>
          <div>
            <div className="font-semibold">Value IDs: </div>
            {useSelector ? (
              <div>
                {" "}
                {keyId ? (
                  <CustomTargetingValuesSelector
                    networkId={networkId}
                    keyId={keyId}
                    managedValueIds={valueIds}
                    isMulti={true}
                    handleOnChange={this.handleValueIdsChanged}
                  ></CustomTargetingValuesSelector>
                ) : (
                  <span className="text-gray-700">Select Key ID first</span>
                )}
              </div>
            ) : (
              <div>
                <span className="text-gray-700">
                  comma separated (eg: 123,456)
                </span>
                <textarea
                  rows={3}
                  className={basicInputClass + " w-full"}
                  value={valueIdsString}
                  onChange={this.handleValueIdsInputChanged}
                ></textarea>
                <div className="text-xs text-gray-600">
                  {valueIds.length} value IDs
                </div>
              </div>
            )}
          </div>
          <div>
            <div className="font-semibold">Operator</div>

            <div className="flex items-center gap-4">
              <label htmlFor={`custom_is`} className="cursor-pointer">
                <input
                  id={`custom_is`}
                  type="radio"
                  name={`custom_targeting_radio`}
                  value={OPERATOR.IS}
                  checked={operator === OPERATOR.IS}
                  onChange={() => this.handleOPChanged(OPERATOR.IS)}
                />{" "}
                IS
              </label>

              <label htmlFor={`custom_isnot`} className="cursor-pointer">
                <input
                  id={`custom_isnot`}
                  type="radio"
                  name={`custom_targeting_radio`}
                  value={OPERATOR.IS_NOT}
                  checked={operator === OPERATOR.IS_NOT}
                  onChange={() => this.handleOPChanged(OPERATOR.IS_NOT)}
                />{" "}
                IS NOT
              </label>
            </div>
          </div>
          <div className="border-t mt-1 flex items-center justify-end py-2 px-2">
            <div className="text-red-600">{errorMsg}</div>
            <button
              type="button"
              className="mb-2 px-4 py-1 text-blue-700 hover:underline"
              onClick={this.handleCancelCustom}
            >
              Cancel
            </button>
            <button
              type="button"
              className="rounded shadow mb-2 bg-blue-200 px-4 py-1 font-semibold text-blue-700 hover:bg-blue-300"
              onClick={this.handleAddCustom}
            >
              Save Custom
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default CustomTargetingControlV2;
