import React from "react";
import _ from "lodash";
import PricingDefaultConfigSelector from "./PricingDefaultConfigSelector";
import PricingDefaultConfigModal from "./PricingDefaultConfigModal";

class PricingConfigForm extends React.Component {
  constructor(props) {
    super(props);

    const { publisherConfig } = props;
    const { pricingDefaultConfigId } = publisherConfig || {};

    this.state = {
      publisherConfig,
      pricingDefaultConfigId,

      isModalOpen: false,
    };

    this.handleDefaultConfigChanged =
      this.handleDefaultConfigChanged.bind(this);

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleOpenCreateModal = this.handleOpenCreateModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentDidUpdate(prevProps) {
    //
    const oldConfigId = _.get(
      prevProps,
      "publisherConfig.pricingDefaultConfigId"
    );
    const newConfigId = _.get(
      this.props,
      "publisherConfig.pricingDefaultConfigId"
    );
    if (newConfigId !== oldConfigId) {
      this.setState({
        publisherConfig: this.props.publisherConfig,
        pricingDefaultConfigId: newConfigId,
      });
    }
  }

  handleDefaultConfigChanged({ pricingDefaultConfigId }) {
    this.setState({ pricingDefaultConfigId });
  }

  handleSave() {
    const { pricingDefaultConfigId } = this.state;
    this.props.handleSaveClicked({ pricingDefaultConfigId });
  }

  handleCancel() {
    this.props.handleCancelClicked();
  }

  handleOpenCreateModal() {
    this.setState({ isModalOpen: true });
  }

  handleCloseModal() {
    this.setState({ isModalOpen: false });
  }

  render() {
    const { isSaving, isModalOpen, publisherConfig, pricingDefaultConfigId } =
      this.state;

    return (
      <div className="border rounded p-4">
        <div>
          <div className="flex justify-between">
            <div className="text-lg font-semibold">
              1. Select Pricing Config:
            </div>
            <button
              type="button"
              className="rounded font-semibold text-blue-500 hover:text-blue-600"
              onClick={this.handleOpenCreateModal}
              disabled={isSaving}
            >
              Create a new pricing config
            </button>
          </div>
          <PricingDefaultConfigSelector
            selectedValue={pricingDefaultConfigId}
            handleOnChange={this.handleDefaultConfigChanged}
          ></PricingDefaultConfigSelector>
        </div>

        {/* <div className="mb-6 font-semibold text-red-600">{errorMsg}</div> */}
        <div className="mt-8 flex justify-end">
          <button
            type="button"
            className="rounded px-6 py-2 font-semibold text-blue-600"
            onClick={this.handleCancel}
            disabled={isSaving}
          >
            Cancel
          </button>

          <button
            type="button"
            className="rounded bg-blue-500 px-6 py-2 font-semibold text-white"
            onClick={this.handleSave}
            disabled={isSaving}
          >
            {isSaving ? "Saving Config..." : "Save Pricing Config"}
          </button>
        </div>

        {isModalOpen && (
          <PricingDefaultConfigModal
            isModalOpen={isModalOpen}
            handleCloseModal={this.handleCloseModal}
            handleConfigChanged={this.props.handleConfigChanged}
          ></PricingDefaultConfigModal>
        )}
      </div>
    );
  }
}

export default PricingConfigForm;
