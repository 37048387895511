import React from "react";
import _ from "lodash";

import ModalWrapper from "../../../common/ModalWrapper";
import ManagedRatioChangedAlert from "./ManagedRatioChangedAlert";
import ClickToCopyWrapper from "components/common/ClickToCopyWrapper";

const inputClass =
  "bg-white flex border border-gray-400 rounded py-1 px-3 appearance-none leading-normal focus:outline-none focus:border-blue-400 focus:shadow-inner hover:border-gray-500";

const titleClass = "block text-gray-800 text-sm font-bold"; //"font-semibold";

const MATCH_TYPE = {
  FULL_MATCH: "str",
  REGEX: "regex",
};

class DomainSpecificManagedRatioModal extends React.Component {
  constructor(props) {
    super(props);

    const { domainSpecificSetting } = props;
    const {
      domain = "",
      match_type = MATCH_TYPE.FULL_MATCH,
      managed_ratio = "",
    } = domainSpecificSetting;

    this.state = {
      domain,
      matchType: match_type,
      managedRatio: managed_ratio,

      // if dirty alert midnight
      isManagedRatioDirty: false,

      errMsg: null,
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleCancel() {
    this.setState({
      domain: "",
      matchType: "",
      managedRatio: "",
      isManagedRatioDirty: false,

      errMsg: null,
    });
    this.props.handleCloseModal();
  }

  handleSave() {
    const { domain, matchType, managedRatio } = this.state;

    // validate
    if (!matchType || matchType === "") {
      return this.setState({ errMsg: "Match Type is required" });
    }

    if (!domain || domain === "") {
      return this.setState({ errMsg: "Domain is required" });
    }

    if (
      managedRatio === null ||
      managedRatio === undefined ||
      managedRatio === ""
    ) {
      return this.setState({ errMsg: "Managed Ratio is required" });
    }

    if (!(managedRatio >= 0 && managedRatio <= 1)) {
      return this.setState({ errMsg: "Managed Ratio should be between 0 - 1" });
    }

    // Check for duplicates: same matchType and domain
    const allKeys = _.map(
      this.props.allDomainSettings,
      (s) => `${s.match_type}_${s.domain}`
    );
    const key = `${matchType}_${domain}`;
    const duplicates = _.filter(allKeys, (k) => k === key);

    const { isEdit } = this.props;
    if (
      (isEdit && duplicates.length > 1) ||
      (!isEdit && duplicates.length > 0)
    ) {
      return this.setState({
        errMsg: `"${domain}" with the same match type already exists`,
      });
    }

    const saveSetting = {
      domain: _.trim(domain),
      match_type: matchType,
      managed_ratio: managedRatio,
    };
    this.props.handleSave(saveSetting);
    this.setState({ errMsg: null });
  }

  handleChanged({ key, value }) {
    if (key === "managedRatio") {
      this.setState({ isManagedRatioDirty: true });
    }

    this.setState({
      [key]: value,
    });
  }

  render() {
    const { isModalOpen, networkInfo } = this.props;
    const { domain, matchType, managedRatio, errMsg, isManagedRatioDirty } =
      this.state;

    // const isInvalid = !suggestion;
    const isInvalid = false;
    return (
      <ModalWrapper
        isOpen={isModalOpen}
        showCloseFooter={false}
        handleClose={this.handleCloseModal}
        width="80%"
      >
        <div className="border-b -mx-6 mb-4 -mt-5 py-4 px-6 text-xl font-bold">
          Domain Specific Managed Ratio
        </div>

        <div className="border rounded p-4">
          <div className="mb-4">
            <label className={titleClass}>Match Type:</label>

            <div className="flex gap-4">
              <label htmlFor={`match_type_str`} className="cursor-pointer">
                <input
                  id={`match_type_str`}
                  type="radio"
                  value="str"
                  checked={matchType === "str"}
                  onChange={() =>
                    this.handleChanged({
                      key: "matchType",
                      value: "str",
                    })
                  }
                  required
                />{" "}
                Full Match
              </label>

              <label htmlFor={`match_type_regex`} className="cursor-pointer">
                <input
                  id={`match_type_regex`}
                  type="radio"
                  value="regex"
                  checked={matchType === "regex"}
                  onChange={() =>
                    this.handleChanged({
                      key: "matchType",
                      value: "regex",
                    })
                  }
                  required
                />{" "}
                Regular Expression
              </label>
            </div>
          </div>

          <div className="mb-4">
            <label className={titleClass}>
              Domain:{" "}
              {matchType === "regex" && (
                <span>
                  (
                  <a
                    target="_blank"
                    className="text-blue-600 hover:text-blue-800"
                    href="https://regex101.com/"
                  >
                    Verify Regex here
                  </a>
                  )
                </span>
              )}
            </label>
            {matchType === "regex" ? (
              <div className="flex gap-1 text-sm leading-tight text-gray-600">
                For example:{" "}
                <span className="px-1 text-gray-700">
                  {`^.+\\.abc\\.com$|^abc\\.com$`}
                </span>
                <ClickToCopyWrapper
                  copyText={`^.+\\.abc\\.com$|^abc\\.com$`}
                ></ClickToCopyWrapper>
              </div>
            ) : (
              <div className="flex gap-1 text-sm leading-tight text-gray-600">
                For example:{" "}
                <span className="px-1 text-gray-700">{`www.abc.com`}</span>
                <ClickToCopyWrapper
                  copyText={`www.abc.com`}
                ></ClickToCopyWrapper>
              </div>
            )}

            <input
              className={inputClass + " w-1/2"}
              type="text"
              value={domain}
              onChange={(e) =>
                this.handleChanged({
                  key: "domain",
                  value: e.target.value,
                })
              }
            ></input>
          </div>

          <div className="mb-4">
            <label className={titleClass}>Managed Ratio (0 - 1): </label>

            <input
              className={inputClass + " w-1/4"}
              type="number"
              value={managedRatio}
              onChange={(e) => {
                return this.handleChanged({
                  key: "managedRatio",
                  value: !_.isEmpty(e.target.value)
                    ? parseFloat(e.target.value)
                    : e.target.value,
                });
              }}
              min={0}
              max={1}
              step={0.1}
            ></input>

            {isManagedRatioDirty && (
              <ManagedRatioChangedAlert
                networkInfo={networkInfo}
              ></ManagedRatioChangedAlert>
            )}
          </div>
        </div>

        <div className="mt-4 flex flex-row items-center justify-end">
          {errMsg && (
            <div className="my-1 bg-red-100 text-red-700">{errMsg}</div>
          )}
          <div>
            <button
              type="button"
              disabled={false}
              className="px-4 py-2 text-blue-700"
              onClick={this.handleCancel}
            >
              Cancel
            </button>
          </div>
          <div>
            {!isInvalid && (
              <button
                type="button"
                className={`px-4 py-2 ${
                  isInvalid
                    ? "bg-gray-400 text-gray-800"
                    : "bg-blue-500 text-white hover:bg-blue-700"
                }  rounded shadow font-semibold`}
                onClick={this.handleSave}
                disabled={isInvalid}
              >
                Add Domain Setting
              </button>
            )}
          </div>
        </div>
      </ModalWrapper>
    );
  }
}

export default DomainSpecificManagedRatioModal;
