import React from "react";
import _ from "lodash";
import LoadingUI from "../common/LoadingUI";
import TimeOfDayProgressBar from "../common/TimeOfDayProgressBar";

const NetworkPageWrapper = (props) => {
  const loadingFullpage = (
    <div className="h-screen">
      <LoadingUI></LoadingUI>
    </div>
  );
  const errMsgFullpage = <div className="min-h-screen">{props.errMsg}</div>;

  let pageChildren = "";
  if (props.isLoading) {
    pageChildren = loadingFullpage;
  } else if (props.errMsg) {
    pageChildren = errMsgFullpage;
  } else {
    pageChildren = props.children;
  }

  let titleElement = "";
  if (props.networkInfo) {
    const { networkInfo } = props;

    titleElement = (
      <div className="flex items-end justify-between">
        <div>
          <div className="text-2xl font-bold leading-none text-gray-900">
            [{networkInfo.networkId}] {networkInfo && networkInfo.name}
          </div>
        </div>
        <div>
          <div className="flex items-center text-base font-semibold">
            <div className="flex items-center">
              <div className="mr-2">
                {networkInfo.timezone.replace("_", " ")}
              </div>
              <div className="text-xs" style={{ marginTop: "2px" }}>
                <TimeOfDayProgressBar
                  timezone={networkInfo.timezone}
                ></TimeOfDayProgressBar>
              </div>
            </div>
            <span className="mx-2 font-hairline text-gray-500">|</span>
            {networkInfo.currency}
          </div>
        </div>
      </div>
    );
  } else if (props.title) {
    titleElement = (
      <div className="text-4xl font-extrabold text-gray-900">{props.title}</div>
    );
  } else {
    titleElement = "";
  }

  return (
    <>
      <div className="shadow bg-white">
        <div className="shadow border-b sticky top-0 bg-white px-12 py-2">
          {titleElement}
        </div>

        <div className="min-h-screen bg-gray-100 py-8 px-12">
          {pageChildren}
        </div>
      </div>
    </>
  );
};

export default NetworkPageWrapper;
