import React from "react";
import _ from "lodash";

import NumberFormat from "components/common/NumberFormat";
import { formatMoneyWithCurrency } from "components/playground/home-dashboard-v1/ReportsHelper";
import PricingTiersView from "./PricingTiersView";

const PRICING_TYPE = {
  0: "Tiered Rev Share",
  1: "Flat Fee",
  2: "Rev Share with Cost Compensation",
};

const labelClass = "font-medium uppercase text-gray-600 text-sm";
const labelStyle = {
  width: "140px",
  display: "inline-block",
};

function PricingDefaultConfigView({ item }) {
  const { pricingDefaultConfigId, pricingType, name, config } = item;
  const {
    currency,
    fee,
    rate_type,
    pricing_tiers,
    refund_rate,
    rev_share_rate,
    cost_share_rate,
  } = config;

  return (
    <div className="border rounded bg-gray-100 p-2 text-gray-800">
      <div className="border-b mb-2 flex">
        <div style={labelStyle}>
          <span className={labelClass}>ID: </span>
          {pricingDefaultConfigId}
        </div>
        <div>
          <span className={labelClass}>Name: </span>
          {name}
        </div>
        <div className="ml-8">
          <span className={labelClass}>Pricing Type: </span>
          {PRICING_TYPE[pricingType]}
        </div>
      </div>

      <div>
        <span className={labelClass} style={labelStyle}>
          Currency:{" "}
        </span>
        {currency}
      </div>

      {fee && (
        <div>
          <span className={labelClass} style={labelStyle}>
            Fee:{" "}
          </span>
          {/* <NumberFormat value={fee} format="0,0.00"></NumberFormat> */}
          {formatMoneyWithCurrency({
            currency,
            value: fee,
          })}
        </div>
      )}

      {rate_type && (
        <div>
          <span className={labelClass} style={labelStyle}>
            Rate Type:{" "}
          </span>
          {rate_type}
        </div>
      )}

      {refund_rate && (
        <div>
          <span className={labelClass} style={labelStyle}>
            Refund Rate:{" "}
          </span>
          <NumberFormat value={refund_rate} format="0,0"></NumberFormat>%
        </div>
      )}

      {rev_share_rate && (
        <div>
          <span className={labelClass} style={labelStyle}>
            Rev Share Rate:{" "}
          </span>
          <NumberFormat value={rev_share_rate} format="0,0"></NumberFormat>%
        </div>
      )}

      {cost_share_rate && (
        <div>
          <span className={labelClass} style={labelStyle}>
            Cost Share Rate:{" "}
          </span>
          <NumberFormat value={cost_share_rate} format="0,0"></NumberFormat>%
        </div>
      )}

      {pricing_tiers && (
        <div>
          <div className={labelClass}>Pricing Tiers:</div>
          <PricingTiersView tiers={pricing_tiers}></PricingTiersView>
        </div>
      )}
    </div>
  );
}

export default PricingDefaultConfigView;
