import React from "react";
import _ from "lodash";
import ModalWrapper from "components/common/ModalWrapper";

class PreviewFeaturesAndSaveModal extends React.PureComponent {
  render() {
    const {
      finalConfig,
      isReviewModalOpened,
      handleCloseReviewModal,
      isSaving,
      saveErrMsg,
      handleConfirmSaveConfig,
    } = this.props;

    if (!isReviewModalOpened) return "";

    return (
      <ModalWrapper
        isOpen={isReviewModalOpened}
        showCloseFooter={false}
        handleClose={handleCloseReviewModal}
        width="60%"
      >
        <div>
          <div className="font-bold text-xl text-gray-700 mb-2">
            Preview Features
          </div>

          <div
            className="overflow-y-auto border"
            style={{ maxHeight: "320px" }}
          >
            <pre>{JSON.stringify(finalConfig, null, 2)}</pre>
          </div>

          <div className="flex flex-row-reverse mt-4 items-center">
            <div>
              <button
                type="button"
                className="px-4 py-2 bg-blue-500 rounded shadow hover:bg-blue-700 text-white font-semibold"
                onClick={handleConfirmSaveConfig}
              >
                {isSaving ? "Saving..." : "Confirm save"}
              </button>
            </div>
            <div>
              <button
                type="button"
                disabled={isSaving}
                className={`px-4 py-2 text-blue-700 ${
                  isSaving ? "cursor-not-allowed" : ""
                }`}
                onClick={handleCloseReviewModal}
              >
                Cancel
              </button>
            </div>
            <div className="text-red-600">{saveErrMsg}</div>
          </div>
        </div>
      </ModalWrapper>
    );
  }
}

export default PreviewFeaturesAndSaveModal;
