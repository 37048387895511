import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import YbMockupHomeTopFilterHeader from "./TopFilterHeader";
import { DATE_RANGE_TYPE, getDateRangeByType } from "./DateHelper";
import {
  transformHomeDashboardData,
  calculateInventoryData,
  calculateSummaryData,
  calculateDistributionData,
  calculateTrendData,
} from "./ReportsHelper";
import HomeDashboardBody from "./HomeDashboardBody";

function _convertDateToYMD(date) {
  return moment(date).format("YYYY-MM-DD");
}

class HomeDashboardWrapperV2 extends React.Component {
  constructor(props) {
    super(props);

    const { resultData, isReseller, pubId, version, isHistoryDateRange } =
      this.props;
    const homeDashboardData = transformHomeDashboardData(resultData);

    const { publishers, gamNetworks, reportDateRange } = homeDashboardData;
    console.log(publishers);

    // Preselect the only publisher, otherwise "All"
    const selectedPubId =
      publishers.length === 1 ? _.get(publishers, [0, "id"], -1) : -1;

    // For Reseller
    let filteredNetworks = gamNetworks;
    if (selectedPubId !== -1) {
      filteredNetworks = _.filter(gamNetworks, { pubId: selectedPubId });
    }

    // Preselect the only network, otherwise "All"
    const selectedNetworkId =
      gamNetworks.length === 1 ? _.get(gamNetworks, [0, "id"], -1) : -1;

    // const selectedPubId = -1; // Default: All publishers
    // const selectedNetworkId = -1; // Default: All networks
    const selectedYieldSetIds = -1; // All YieldSets
    const selectedCurrency = "USD";

    const currencies = _.keys(homeDashboardData.exchangeRates);
    const exchangeRates = homeDashboardData.exchangeRates[selectedCurrency];

    let dateRange = {};
    if (isHistoryDateRange) {
      // use reports date range
      dateRange = reportDateRange;
    } else {
      // default to "this month"
      const { startDate, endDate } = getDateRangeByType(
        DATE_RANGE_TYPE.DEFAULT
      );
      dateRange.startDate = startDate;
      dateRange.endDate = endDate;
    }
    const { startDate, endDate } = dateRange;

    this.state = {
      // filter
      selectedPubId,
      filteredNetworks,
      currencies,

      selectedNetworkId,
      selectedYieldSetIds,
      selectedCurrency,

      startDate,
      endDate,

      // data
      homeDashboardData,
      exchangeRates,

      isReseller,
      publishers,

      inventoryData: calculateInventoryData({
        homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetIds,
      }),
      summaryData: calculateSummaryData({
        homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetIds,
        startDate,
        endDate,
        exchangeRates,
      }),
      distributionData: calculateDistributionData({
        homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetIds,
        startDate,
        endDate,
        exchangeRates,
      }),
      trendData: calculateTrendData({
        homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetIds,
        startDate,
        endDate,
        exchangeRates,
      }),
    };

    this.handlePublisherChanged = this.handlePublisherChanged.bind(this);
    this.handleInventoryFilterChanged =
      this.handleInventoryFilterChanged.bind(this);
    this.handleDateRangeFilterChanged =
      this.handleDateRangeFilterChanged.bind(this);
    this.handleCurrencyChanged = this.handleCurrencyChanged.bind(this);
  }

  handlePublisherChanged(pubId) {
    this.setState({ selectedPubId: pubId });
    console.log("Publisher changed: ", pubId);
  }

  handleInventoryFilterChanged({
    selectedPubId,
    selectedNetworkId,
    selectedYieldSetIds,
  }) {
    console.log(
      "Inventory Filter changed: ",
      selectedPubId,
      selectedNetworkId,
      selectedYieldSetIds
    );
    this.setState({
      selectedPubId,
      selectedNetworkId,
      selectedYieldSetIds,
      inventoryData: calculateInventoryData({
        homeDashboardData: this.state.homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetIds,
      }),
      summaryData: calculateSummaryData({
        homeDashboardData: this.state.homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetIds,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        exchangeRates: this.state.exchangeRates,
      }),
      distributionData: calculateDistributionData({
        homeDashboardData: this.state.homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetIds,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        exchangeRates: this.state.exchangeRates,
      }),
      trendData: calculateTrendData({
        homeDashboardData: this.state.homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetIds,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        exchangeRates: this.state.exchangeRates,
      }),
    });
  }

  handleDateRangeFilterChanged({ startDate, endDate }) {
    // Important! Convert a date object (with timezone) to just YYYY-MM-DD format
    var sd = _convertDateToYMD(startDate);
    var ed = _convertDateToYMD(endDate);

    console.log("Date Range Filter changed: ", sd, ed);
    this.setState({
      startDate: sd,
      endDate: ed,
      summaryData: calculateSummaryData({
        homeDashboardData: this.state.homeDashboardData,
        selectedPubId: this.state.selectedPubId,
        selectedNetworkId: this.state.selectedNetworkId,
        selectedYieldSetIds: this.state.selectedYieldSetIds,
        startDate: sd,
        endDate: ed,
        exchangeRates: this.state.exchangeRates,
      }),
      distributionData: calculateDistributionData({
        homeDashboardData: this.state.homeDashboardData,
        selectedPubId: this.state.selectedPubId,
        selectedNetworkId: this.state.selectedNetworkId,
        selectedYieldSetIds: this.state.selectedYieldSetIds,
        startDate: sd,
        endDate: ed,
        exchangeRates: this.state.exchangeRates,
      }),
      trendData: calculateTrendData({
        homeDashboardData: this.state.homeDashboardData,
        selectedPubId: this.state.selectedPubId,
        selectedNetworkId: this.state.selectedNetworkId,
        selectedYieldSetIds: this.state.selectedYieldSetIds,
        startDate: sd,
        endDate: ed,
        exchangeRates: this.state.exchangeRates,
      }),
    });
  }

  handleCurrencyChanged(currency) {
    console.log("Currency changed: ", currency);
    const {
      homeDashboardData,
      startDate,
      endDate,
      selectedPubId,
      selectedNetworkId,
      selectedYieldSetIds,
    } = this.state;
    const exchangeRates = homeDashboardData.exchangeRates[currency];
    this.setState({
      selectedCurrency: currency,
      exchangeRates,
      startDate,
      endDate,
      summaryData: calculateSummaryData({
        homeDashboardData: homeDashboardData,
        selectedPubId,
        selectedNetworkId: selectedNetworkId,
        selectedYieldSetIds: selectedYieldSetIds,
        startDate,
        endDate,
        exchangeRates,
      }),
      distributionData: calculateDistributionData({
        homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetIds,
        startDate,
        endDate,
        exchangeRates,
      }),
      trendData: calculateTrendData({
        homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetIds,
        startDate,
        endDate,
        exchangeRates,
      }),
    });
  }

  render() {
    const {
      homeDashboardData,

      isReseller,
      selectedPubId,
      publishers,
      filteredNetworks,
      selectedNetworkId,
      selectedYieldSetIds,
      inventoryData,
      summaryData,
      startDate,
      endDate,
      distributionData,
      trendData,
      currencies,
      selectedCurrency,
    } = this.state;

    if (!homeDashboardData) {
      return "No Data";
    }
    console.log(selectedYieldSetIds);

    return (
      <>
        <div className="bg-white">
          <div className="shadow sticky top-0 z-40">
            <YbMockupHomeTopFilterHeader
              isReseller={isReseller}
              selectedPubId={selectedPubId}
              publishers={publishers}
              networks={filteredNetworks}
              selectedNetworkId={selectedNetworkId}
              selectedYieldSetIds={selectedYieldSetIds}
              yieldSets={homeDashboardData.yieldSets}
              handleInventoryFilterChanged={this.handleInventoryFilterChanged}
              currencies={currencies}
              selectedCurrency={selectedCurrency}
              handleCurrencyChanged={this.handleCurrencyChanged}
              startDate={startDate}
              endDate={endDate}
              handleDateRangeFilterChanged={this.handleDateRangeFilterChanged}
            ></YbMockupHomeTopFilterHeader>
          </div>

          <div className="px-12 py-6">
            <HomeDashboardBody
              updatedTime={homeDashboardData.updatedTime}
              selectedPubId={selectedPubId}
              selectedNetworkId={selectedNetworkId}
              selectedYieldSetIds={selectedYieldSetIds}
              inventoryData={inventoryData}
              summaryData={summaryData}
              startDate={startDate}
              endDate={endDate}
              distributionData={distributionData}
              trendData={trendData}
              selectedCurrency={selectedCurrency}
            ></HomeDashboardBody>
          </div>
        </div>
      </>
    );
  }
}

export default HomeDashboardWrapperV2;
