import React from "react";
import _, { over } from "lodash";

import ModalWrapper from "../../../common/ModalWrapper";
import { NetworkAPI } from "apis";
import LoadingUI from "components/common/LoadingUI";
import { networkProduct } from "constants/NetworkProduct";
import { FiDownload } from "react-icons/fi";
import OpenLinkInNewWindowBtn from "components/common/OpenLinkInNewWindowBtn";

class TempBuildModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      packages: null,

      packageName: undefined,
      packageVersion: undefined,
      versions: null,

      // override inputs
      overrideProduct: 3, // default cstv2p
      tempInput: null,
      tempDownloadUrl: null,

      isSaving: false,
      saveErrMsg: null,
    };

    this.handleChangePackageName = this.handleChangePackageName.bind(this);
    this.handleChangePackageVersion =
      this.handleChangePackageVersion.bind(this);
    this.handleChangeProduct = this.handleChangeProduct.bind(this);
    this.handleCreateTempBuild = this.handleCreateTempBuild.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      const { networkId } = this.props;
      const { packages } = await NetworkAPI.getBuildPackages();

      this.setState({
        packages,

        // packageName,
        packageVersion: "latest",
        // versions: [], // get more from package on select
      });

      this.handleChangePackageName(_.get(_.first(packages), "name"));
    } catch (err) {
      console.log(err);
    }
    this.setState({ isLoading: false });
  }

  handleChangePackageName(packageName) {
    const p = _.find(this.state.packages, { name: packageName });
    const pVersions = _.map(_.get(p, "versions", []), "version");
    const versions = ["latest", ...pVersions];
    this.setState({
      packageName,
      versions,
    });
  }

  handleChangePackageVersion(packageVersion) {
    this.setState({
      packageVersion,
    });
  }

  handleChangeProduct(product) {
    this.setState({
      overrideProduct: product,
    });
  }

  async handleCreateTempBuild() {
    const { packageName, packageVersion, overrideProduct } = this.state;

    this.setState({ isSaving: true });
    try {
      const overriddenInput = {
        network: {
          product: overrideProduct,
        },
      };

      //const { input, downloadUrl }
      const r = await this.props.handleCreateTempBuild({
        packageName,
        packageVersion,
        overriddenInput,
      });

      const { input, downloadUrl } = r;
      this.setState({
        tempInput: input,
        tempDownloadUrl: downloadUrl,
        saveErrMsg: null,
      });
    } catch (err) {
      this.setState({
        saveErrMsg: err.toString(),
      });
    }

    this.setState({ isSaving: false });
  }

  handleDownload() {
    if (this.state.tempDownloadUrl) {
      window.open(this.state.tempDownloadUrl);
    }
  }

  render() {
    const {
      isLoading,
      packages,
      packageName,
      versions,
      packageVersion,
      isSaving,
      saveErrMsg,
      overrideProduct,
      tempInput,
      tempDownloadUrl,
    } = this.state;
    const { networkId, isOpenViewModal, handleClose } = this.props;
    const products = _.map(networkProduct, (value, key) => {
      return {
        value: _.parseInt(key),
        name: value,
      };
    });

    return (
      <ModalWrapper
        isOpen={isOpenViewModal}
        showCloseFooter={true}
        handleClose={handleClose}
      >
        <>
          <div className="border-b -mx-6 mb-4 px-6 pb-2 text-lg font-bold">
            Create a temporary build:
          </div>

          {isLoading ? (
            <div>
              <LoadingUI></LoadingUI>
            </div>
          ) : (
            <>
              {!tempDownloadUrl && (
                <>
                  <div className="mb-4">
                    <div className="font-semibold">1. Select package:</div>

                    <div className="flex items-center gap-4">
                      <div className="relative inline-block w-1/2">
                        <select
                          value={packageName}
                          onChange={(e) =>
                            this.handleChangePackageName(e.target.value)
                          }
                          className="border rounded shadow focus:outline-none block w-full appearance-none border-gray-400 bg-white px-4 py-2 pr-8 leading-tight hover:border-gray-500 focus:shadow-outline"
                        >
                          {packages &&
                            packages.map((p) => {
                              return (
                                <option key={p.name} value={p.name}>
                                  {p.name}
                                </option>
                              );
                            })}
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg
                            className="h-4 w-4 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="font-semibold">2. Select version:</div>

                    <div className="flex items-center gap-4">
                      {versions &&
                        versions.map((version) => {
                          return (
                            <label
                              htmlFor={`${version}_radio`}
                              className="cursor-pointer"
                              key={version}
                            >
                              <input
                                id={`${version}_radio`}
                                type="radio"
                                name={`package_version_radio`}
                                value={version}
                                checked={packageVersion === version}
                                onChange={() =>
                                  this.handleChangePackageVersion(version)
                                }
                              />{" "}
                              {version}
                            </label>
                          );
                        })}
                    </div>
                  </div>

                  <div className="border-b mb-4 text-lg font-bold text-gray-700">
                    Override network settings:
                  </div>
                  <div className="mb-4">
                    <div className="font-semibold">3. Network Product:</div>

                    <div className="flex items-center gap-4">
                      {products &&
                        products.map((product) => {
                          return (
                            <label
                              htmlFor={`${product.name}_radio`}
                              className="cursor-pointer"
                              key={product.value}
                            >
                              <input
                                id={`${product.name}_radio`}
                                type="radio"
                                name={`product_radio`}
                                value={product.value}
                                checked={overrideProduct === product.value}
                                onChange={() =>
                                  this.handleChangeProduct(product.value)
                                }
                              />{" "}
                              {product.name}
                            </label>
                          );
                        })}
                    </div>
                  </div>

                  {(overrideProduct === 3 || overrideProduct === 4) && (
                    <div className="flex gap-2 bg-orange-200 px-4 py-2 text-sm text-orange-900">
                      Make sure <b>CST Config</b> is set to successfully build a
                      CSTv2 code snippet.
                      <OpenLinkInNewWindowBtn
                        href={`${window.location.origin}/#/ops-mgmt/account/network/${networkId}/cst-config`}
                      ></OpenLinkInNewWindowBtn>
                    </div>
                  )}

                  <button
                    type="button"
                    className="shadow rounded my-2 flex items-center gap-1 bg-blue-200 px-10 py-2 font-semibold text-blue-900 hover:bg-blue-300"
                    onClick={this.handleCreateTempBuild}
                    disabled={isSaving}
                  >
                    {isSaving ? "Building..." : "Launch temp build"}
                  </button>
                  <div className="my-1 text-red-600">{saveErrMsg}</div>
                </>
              )}
            </>
          )}

          {tempDownloadUrl && (
            <>
              <div>
                <div className="flex items-center justify-center">
                  <button
                    type="button"
                    className="shadow rounded my-2 flex items-center gap-1 bg-blue-200 px-10 py-2 font-semibold text-blue-900 hover:bg-blue-300"
                    onClick={this.handleDownload}
                  >
                    <FiDownload></FiDownload>
                    Download temp build
                  </button>
                </div>

                <div className="text-lg font-semibold">Temp Build Input:</div>
                <div className="overflow-y-auto" style={{ height: "360px" }}>
                  <textarea
                    className="w-full bg-gray-300 text-sm"
                    rows={20}
                    defaultValue={JSON.stringify(tempInput, null, 4)}
                    readOnly
                  ></textarea>
                </div>
              </div>
            </>
          )}
        </>
      </ModalWrapper>
    );
  }
}

export default TempBuildModal;
