import React from "react";
import _ from "lodash";
import { notify } from "react-notify-toast";

import { VaultAPI } from "apis";
import { buttonConfirmClass } from "helpers/StyleClass";
import { decrypt, encrypt, initVault, generateVaultHash } from "./VaultHelper";
import VaultAccountBindNetworkModal from "./VaultAccountBindNetworkModal";
import VaultAccountsTable from "./VaultAccountsTable";
import VaultAccountFormModal from "./VaultAccountFormModal";
import VaultAccountChangePasswordModal from "./VaultAccountChangePasswordModal";
import MasterPasswordForm from "./MasterPasswordForm";
import VaultAccountStatusModal from "./VaultAccountStatusModal";

class VaultViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,

      isVaultOpen: false,

      mpErrorMsg: null,
      vaultAccounts: null,

      modalType: "ADD", // ADD, EDIT
      isModalOpen: false,
      isSaving: false,
      isRemoving: false,
      saveErrorMsg: null,
      currentAccount: {},

      isBindModalOpen: false,
      isPWModalOpen: false,
      isStatusModalOpen: false,

      isLoadingModal: false,
    };

    this.handleSubmitMP = this.handleSubmitMP.bind(this);
    this.handleOpenAddModal = this.handleOpenAddModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenEditModal = this.handleOpenEditModal.bind(this);
    this.handleSaveAccount = this.handleSaveAccount.bind(this);
    this.handleDeleteAccount = this.handleDeleteAccount.bind(this);
    this.handleOpenBindModal = this.handleOpenBindModal.bind(this);

    this.handleBindNetwork = this.handleBindNetwork.bind(this);
    this.handleRemoveBindNetwork = this.handleRemoveBindNetwork.bind(this);
    this.handleClearSaveErrorMsg = this.handleClearSaveErrorMsg.bind(this);

    this.handleOpenPWModal = this.handleOpenPWModal.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);

    this.handleOpenStatusModal = this.handleOpenStatusModal.bind(this);
    this.handleUpdateAccountStatus = this.handleUpdateAccountStatus.bind(this);

    this.getVaultAccountById = this.getVaultAccountById.bind(this);
  }

  async componentDidMount() {
    document.title = "Vault GAM Accounts | YB Observer";
    // await this.handleSubmitMP("intowow666");
  }

  async handleBindNetwork({ networkCode, accountId, isForceBind }) {
    this.setState({ isSaving: true });
    try {
      const masterHash = await generateVaultHash();
      const saveParams = {
        networkCode,
        accountId,
        isForceBind,
        vaultHash: masterHash,
      };
      const r = await VaultAPI.bindNetworkToVaultAccount(saveParams);
      console.log("bind network response", r);

      this.setState({ saveErrorMsg: null });
      notify.show("Network bind saved!", "success");

      setTimeout(() => {
        this.handleGetVaultAccounts();
        this.handleCloseModal();
      }, 500);
    } catch (err) {
      this.setState({
        saveErrorMsg: err.toString(),
      });
    }

    this.setState({ isSaving: false });
  }

  async handleRemoveBindNetwork(networkCode) {
    this.setState({ isRemoving: true });
    try {
      const masterHash = await generateVaultHash();
      const saveParams = {
        networkCode,
        vaultHash: masterHash,
      };
      const r = await VaultAPI.removeNetworkAccountBinding(saveParams);
      console.log("bind network response", r);

      this.setState({ saveErrorMsg: null });
      notify.show("Network bind removed!", "success");

      setTimeout(() => {
        this.handleGetVaultAccounts();
        this.handleCloseModal();
      }, 500);
    } catch (err) {
      this.setState({
        saveErrorMsg: err.toString(),
      });
    }

    this.setState({ isRemoving: false });
  }

  handleClearSaveErrorMsg() {
    this.setState({ saveErrorMsg: null });
  }

  async handleDeleteAccount(account) {
    const userConfirm = window.confirm(`Delete this account ${account.email}?`);
    if (!userConfirm) return;

    try {
      const masterHash = await generateVaultHash();
      const deleteParams = {
        accountId: account.id,
        vaultHash: masterHash,
      };

      const r = await VaultAPI.deleteVaultAccount(deleteParams);
      console.log(r);

      notify.show("Account deleted!", "success");

      setTimeout(() => {
        this.handleGetVaultAccounts();
      }, 500);
    } catch (err) {
      console.error(err);
      notify.show(`Failed to delete account: ${err.toString()}`, "error");
    }
  }

  async handleChangePassword({ oldPassword, newPassword }) {
    this.setState({ isSaving: true });
    try {
      const { currentAccount } = this.state;
      const originalPassword = decrypt(currentAccount.secret);
      const originalRecoveryEmail = decrypt(currentAccount.recovery_email);
      if (oldPassword !== originalPassword) {
        throw "Wrong old password";
      } else {
        const masterHash = await generateVaultHash();

        const saveParams = {
          email: currentAccount.email,
          secret: encrypt(newPassword),
          recoveryEmail: encrypt(originalRecoveryEmail),
          vaultHash: masterHash,
        };

        const r = await VaultAPI.updateVaultAccount(saveParams);
        console.log("save account response", r);

        this.setState({ saveErrorMsg: null });
        notify.show("Account saved!", "success");

        setTimeout(() => {
          this.handleGetVaultAccounts();
          this.handleCloseModal();
        }, 500);
      }
    } catch (err) {
      this.setState({
        saveErrorMsg: err.toString(),
      });
    }

    this.setState({ isSaving: false });
  }

  async handleSaveAccount(account) {
    const { modalType } = this.state;
    // console.log("save account", modalType, account);

    this.setState({ isSaving: true });
    try {
      const masterHash = await generateVaultHash();

      const saveParams = {
        email: account.email,
        secret: encrypt(account.password),
        recoveryEmail: encrypt(account.recoveryEmail),
        vaultHash: masterHash,
      };

      const r =
        modalType === "ADD"
          ? await VaultAPI.createVaultAccount(saveParams)
          : await VaultAPI.updateVaultAccount(saveParams);
      console.log("save account response", r);

      this.setState({ saveErrorMsg: null });
      notify.show("Account saved!", "success");

      setTimeout(() => {
        this.handleGetVaultAccounts();
        this.handleCloseModal();
      }, 500);
    } catch (err) {
      this.setState({
        saveErrorMsg: err.toString(),
      });
    }

    this.setState({ isSaving: false });
  }

  async handleUpdateAccountStatus(account) {
    // console.log("update account status", account);

    this.setState({ isSaving: true });
    try {
      const masterHash = await generateVaultHash();

      const saveParams = {
        accountId: account.accountId,
        status: account.status,
        vaultHash: masterHash,
      };

      const r = await VaultAPI.updateVaultAccountStatus(saveParams);
      console.log("update account status response", r);

      this.setState({ saveErrorMsg: null });
      notify.show("Account status updated!", "success");

      setTimeout(() => {
        this.handleGetVaultAccounts();
        this.handleCloseModal();
      }, 500);
    } catch (err) {
      this.setState({
        saveErrorMsg: err.toString(),
      });
    }

    this.setState({ isSaving: false });
  }

  handleOpenAddModal() {
    this.setState({ isModalOpen: true, modalType: "ADD", currentAccount: {} });
  }

  handleCloseModal() {
    this.setState({
      isModalOpen: false,
      isPWModalOpen: false,
      isBindModalOpen: false,
      isStatusModalOpen: false,
      currentAccount: {},
      saveErrorMsg: null,
    });
  }

  // get the newest account info when opening any edit modal
  async getVaultAccountById(accountId) {
    this.setState({ isLoadingModal: true });

    let account = null;
    try {
      account = await VaultAPI.getVaultGamAccountById({ accountId });
      console.log(account);
    } catch (err) {
      console.log(
        "failed to get this vault account",
        err.error || err.toString()
      );
    }
    this.setState({ isLoadingModal: false });

    return account;
  }

  handleOpenBindModal(account) {
    this.setState({ isBindModalOpen: true, currentAccount: account });
  }

  async handleOpenEditModal(account) {
    // console.log("opene edit", account);
    const newAccount = await this.getVaultAccountById(account.id);
    this.setState({
      isModalOpen: true,
      modalType: "EDIT",
      currentAccount: newAccount,
    });
  }

  async handleOpenPWModal(account) {
    const newAccount = await this.getVaultAccountById(account.id);
    this.setState({
      isPWModalOpen: true,
      currentAccount: newAccount,
    });
  }

  async handleOpenStatusModal(account) {
    const newAccount = await this.getVaultAccountById(account.id);
    this.setState({
      isStatusModalOpen: true,
      currentAccount: newAccount,
    });
  }

  async handleGetVaultAccounts() {
    this.setState({ isLoading: true });
    try {
      let accounts = await VaultAPI.getVaultGamAccounts();
      accounts = _.orderBy(accounts, ["id"], ["desc"]);
      this.setState({ vaultAccounts: accounts });
    } catch (err) {
      console.log("failed to get vault accounts", err.error || err.toString());
      this.setState({ isVaultOpen: false });
    }
    this.setState({ isLoading: false });
  }

  async handleSubmitMP(masterPassword) {
    this.setState({ isLoading: true });
    try {
      // console.log("mp", masterPassword);
      await initVault(masterPassword);

      this.setState({ mpErrorMsg: null, isVaultOpen: true });

      await this.handleGetVaultAccounts();
    } catch (err) {
      console.log("wrong password", err);
      this.setState({ mpErrorMsg: "Wrong Password" });
    }

    this.setState({ isLoading: false });
  }

  render() {
    const {
      mpErrorMsg,
      isVaultOpen,
      vaultAccounts,
      isLoading,
      errMsg,

      modalType,
      isModalOpen,
      isSaving,
      isRemoving,
      saveErrorMsg,
      currentAccount,

      isBindModalOpen,
      isPWModalOpen,
      isStatusModalOpen,
      isLoadingModal,
    } = this.state;

    return (
      <div className="bg-white">
        <div className="px-12">
          <div className="flex justify-between pt-8 mb-4">
            <div className="font-extrabold text-gray-900 text-4xl">
              Vault GAM Accounts
            </div>
          </div>
        </div>
        <div className="bg-gray-200 px-12 py-8 min-h-screen">
          {isVaultOpen ? (
            <div>
              <div className="flex justify-between items-end">
                <div className="text-sm text-gray-800 pb-1">
                  {vaultAccounts && vaultAccounts.length} accounts
                </div>
                <button
                  type="button"
                  className={buttonConfirmClass}
                  onClick={this.handleOpenAddModal}
                >
                  Add account
                </button>
              </div>

              <VaultAccountsTable
                items={vaultAccounts}
                isLoading={isLoading}
                errMsg={errMsg}
                handleOpenEditModal={this.handleOpenEditModal}
                handleDeleteAccount={this.handleDeleteAccount}
                handleOpenBindModal={this.handleOpenBindModal}
                handleOpenPasswordModal={this.handleOpenPWModal}
                handleOpenStatusModal={this.handleOpenStatusModal}
                getVaultAccountById={this.getVaultAccountById}
                isLoadingModal={isLoadingModal}
              ></VaultAccountsTable>

              {isPWModalOpen && (
                <VaultAccountChangePasswordModal
                  account={currentAccount}
                  isOpenModal={isPWModalOpen}
                  isSaving={isSaving}
                  saveErrorMsg={saveErrorMsg}
                  handleClose={this.handleCloseModal}
                  handleSave={this.handleChangePassword}
                  isLoadingModal={isLoadingModal}
                ></VaultAccountChangePasswordModal>
              )}

              {isStatusModalOpen && (
                <VaultAccountStatusModal
                  account={currentAccount}
                  isOpenModal={isStatusModalOpen}
                  isSaving={isSaving}
                  saveErrorMsg={saveErrorMsg}
                  handleClose={this.handleCloseModal}
                  handleSave={this.handleUpdateAccountStatus}
                  isLoadingModal={isLoadingModal}
                ></VaultAccountStatusModal>
              )}

              {isModalOpen && (
                <VaultAccountFormModal
                  modalType={modalType}
                  isOpenModal={isModalOpen}
                  handleClose={this.handleCloseModal}
                  isSaving={isSaving}
                  saveErrorMsg={saveErrorMsg}
                  account={currentAccount}
                  handleSave={this.handleSaveAccount}
                  isLoadingModal={isLoadingModal}
                ></VaultAccountFormModal>
              )}

              {isBindModalOpen && (
                <VaultAccountBindNetworkModal
                  isOpenModal={isBindModalOpen}
                  handleClose={this.handleCloseModal}
                  isSaving={isSaving}
                  isRemoving={isRemoving}
                  saveErrorMsg={saveErrorMsg}
                  account={currentAccount}
                  handleBindNetwork={this.handleBindNetwork}
                  handleRemoveBindNetwork={this.handleRemoveBindNetwork}
                  handleClearSaveErrorMsg={this.handleClearSaveErrorMsg}
                ></VaultAccountBindNetworkModal>
              )}
            </div>
          ) : (
            <MasterPasswordForm
              handleSubmitMP={this.handleSubmitMP}
              mpErrorMsg={mpErrorMsg}
              isLoading={isLoading}
            ></MasterPasswordForm>
          )}
        </div>
      </div>
    );
  }
}

export default VaultViewer;
