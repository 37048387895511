import React from "react";
import _ from "lodash";
import ReactModal from "react-modal";
import ModalWrapper from "../common/ModalWrapper";
import { decrypt } from "./VaultHelper";
import VaultAccountView from "./VaultAccountView";
import LoadingUI from "components/common/LoadingUI";

ReactModal.setAppElement("body");

class VaultAccountViewModal extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { isOpenModal, handleClose, account, isLoadingModal } = this.props;

    account.password = decrypt(account.secret);
    account.recoveryEmail = decrypt(account.recovery_email);

    return (
      <ModalWrapper
        isOpen={isOpenModal}
        showCloseFooter={true}
        handleClose={handleClose}
        width="50%"
      >
        <div>
          <div className="mb-4 text-lg font-bold border-b pb-2 px-6 -mx-6">
            Account: {account.email}
          </div>
          {isLoadingModal ? (
            <LoadingUI></LoadingUI>
          ) : (
            <VaultAccountView account={account}></VaultAccountView>
          )}
        </div>
      </ModalWrapper>
    );
  }
}

export default VaultAccountViewModal;
