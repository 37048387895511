import React from "react";
import _ from "lodash";
import { PublisherAPI } from "apis";

class PublisherInfoHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      publisherInfo: null,
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      const { pubId } = this.props;
      if (!pubId) {
        throw new Error("Missing Publisher ID");
      }

      const queryParams = {
        publisherId: pubId,
      };
      const pubs = await PublisherAPI.getPublishersWithTrialStatus(queryParams);
      // console.log(pubs[0]);

      const pub = pubs[0];
      const expireDate = _.get(pub, "accountStatus.trialStatus.expDate");
      const billableStartDate = _.get(
        pub,
        "accountStatus.trialStatus.billableStartDate"
      );
      const publisherInfo = {
        ...pub,
        expireDate,
        billableStartDate,
      };

      this.setState({
        publisherInfo,

        isLoading: false,
        errMsg: null,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        errMsg: err.toString(),
      });
    }
  }

  render() {
    const { pubId } = this.props;
    const { publisherInfo } = this.state;

    return (
      <div className="shadow border-b sticky top-0 bg-white px-12 py-2">
        <div className="flex items-end justify-between">
          <div>
            <div className="text-xs font-semibold uppercase leading-none text-gray-600">
              Publisher
            </div>
            <div className="text-2xl font-bold leading-none text-gray-900">
              [{pubId}] {publisherInfo && publisherInfo.name}
            </div>
          </div>
          <div></div>
        </div>
      </div>
    );
  }
}

export default PublisherInfoHeader;
