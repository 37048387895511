import React from "react";
import _ from "lodash";
import Select from "react-select";

import { CommonAPI } from "apis";

class GeoCountrySelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      options: [],
      selectedValue: null,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    const items = await CommonAPI.getGeoCountries();
    // console.log(items);

    let options = _.map(items, (r) => {
      const n = _.startCase(r.name.toLowerCase());
      return {
        value: r.countryId,
        label: `${n} (${r.alpha2}) (${r.alpha3})`,
      };
    });

    const selectedValue = _.find(options, { value: this.props.selectedValue });

    this.setState({
      items,
      options,
      selectedValue,
    });
  }

  handleChange(option) {
    this.setState({
      selectedValue: option,
    });

    // this.props.handleOnChange(option.value);

    if (this.props.isMulti) {
      const selectedValues = _.map(option, "value");
      const results = _.filter(this.state.items, (item) => {
        return _.indexOf(selectedValues, item.countryId) !== -1;
      });
      this.props.handleOnChange(results);
    } else {
      this.props.handleOnChange(option.value);
    }
  }

  render() {
    const { options, selectedValue } = this.state;

    return (
      <>
        <Select
          value={selectedValue}
          onChange={this.handleChange}
          options={options}
          isMulti={this.props.isMulti}
        />
      </>
    );
  }
}

export default GeoCountrySelector;
