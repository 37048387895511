import React from "react";
import _ from "lodash";

const inputClass =
  "bg-white flex w-full border border-gray-300 rounded py-1 px-4 appearance-none leading-normal focus:outline-none focus:border-blue-400 focus:shadow-inner hover:shadow";

const labelClass = "font-semibold text-gray-800";
const radioClass = "font-semibold text-sm text-gray-900 cursor-pointer";

class OverdueSettingSectionForm extends React.PureComponent {
  constructor(props) {
    super(props);

    // setting.overdueSetting
    // setting.overdueSetting.recipient
    // setting.overdueSetting.recipient.firstName
    // setting.overdueSetting.recipient.lastName
    // setting.overdueSetting.recipient.emailAddress
    // setting.overdueSetting.cc
    const { setting } = this.props;
    const overdueSetting = setting.overdueSetting || {};
    const {
      firstName = "",
      lastName = "",
      email = "",
    } = overdueSetting.recipient || {};
    const { cc } = overdueSetting;

    let ccStr = "";
    if (cc && cc.length > 0) {
      ccStr = cc.join(",");
    }

    this.state = {
      recipientFirstName: firstName,
      recipientLastName: lastName,
      recipientEmail: email,
      cc,
      ccStr,

      // errorObj[key] = errMsg
      errorObj: {},
    };
  }

  onInputChanged(value, key) {
    this.setState({ [key]: value });

    // check valid
    const { isValid, errMsg } = this.checkKeyValid(key, value);
    if (!isValid) {
      const newErrorObj = { ...this.state.errorObj, [key]: errMsg };
      this.setState({ errorObj: newErrorObj });
      return;
    } else {
      let newErrorObj = this.state.errorObj;
      newErrorObj = _.omit(newErrorObj, key);
      this.setState({ errorObj: newErrorObj });
    }

    // transform to valid type
    const { setting } = this.props;
    let newValue = value;
    let newKey = key;

    let changedSetting = setting || {};
    let { overdueSetting = {} } = changedSetting;
    if (_.startsWith(key, "recipient")) {
      let recipient = { ...overdueSetting.recipient };

      if (key === "recipientFirstName") {
        recipient.firstName = value;
      } else if (key === "recipientLastName") {
        recipient.lastName = value;
      } else if (key === "recipientEmail") {
        recipient.email = value;
      }

      overdueSetting.recipient = recipient;
    } else if (key === "ccStr") {
      if (!_.isEmpty(value)) {
        overdueSetting.cc = _.compact(
          _.map(_.compact(value.split(",")), _.trim)
        );
        this.setState({ cc: overdueSetting.cc });
      } else {
        overdueSetting.cc = null;
        this.setState({ cc: overdueSetting.cc });
      }
    }

    changedSetting.overdueSetting = overdueSetting;
    this.props.onSettingChanged(changedSetting);
  }

  checkKeyValid(key, value) {
    let isValid = true;
    let errMsg = null;

    // errorObj[key] = errMsg
    switch (key) {
      // case "phoneNumber":
      // case "phoneCountryCode": {
      //   const newValue = _.parseInt(value);
      //   if (_.isNaN(newValue)) {
      //     isValid = false;
      //     errMsg = `${key} should be a number`;
      //     break;
      //   }
      // }

      default: {
        //
      }
    }

    return { isValid, errMsg };
  }

  checkIsSectionValid(setting) {
    // check the whole section
    const { overdueSetting = {} } = setting;
    let isValid = true;
    return isValid;
  }

  render() {
    const {
      recipientFirstName,
      recipientLastName,
      recipientEmail,
      cc,
      ccStr,

      // errorObj[key] = errMsg
      errorObj: {},
    } = this.state;

    return (
      <div className="border shadow rounded mb-4 bg-gray-200 p-4">
        <div className="border-b mb-6 mt-2 border-gray-400 text-xl font-bold text-gray-900">
          Overdue Setting
        </div>

        <div className="mb-4">
          <div className="w-2/3">
            <div className="flex gap-4">
              <div className="w-1/2">
                <label className={labelClass}>Recipient First Name</label>
                <input
                  type="text"
                  className={inputClass}
                  value={recipientFirstName}
                  onChange={(e) =>
                    this.onInputChanged(e.target.value, "recipientFirstName")
                  }
                  required
                ></input>
              </div>

              <div className="w-1/2">
                <label className={labelClass}>Recipient Last Name</label>
                <input
                  type="text"
                  className={inputClass}
                  value={recipientLastName}
                  onChange={(e) =>
                    this.onInputChanged(e.target.value, "recipientLastName")
                  }
                  required
                ></input>
              </div>
            </div>
          </div>

          <div className="w-1/3">{/* FOR ERROR */}</div>
        </div>

        <div className="mb-4 flex">
          <div className="w-2/3">
            <label className={labelClass}>Recipient Email</label>
            <input
              type="text"
              className={inputClass}
              value={recipientEmail}
              onChange={(e) =>
                this.onInputChanged(e.target.value, "recipientEmail")
              }
              required
            ></input>
          </div>
          <div className="w-1/3"></div>
        </div>

        <div className="mb-4 flex">
          <div className="w-2/3">
            <label className={labelClass}>CC Emails</label>

            <textarea
              className={inputClass}
              value={ccStr}
              onChange={(e) => this.onInputChanged(e.target.value, "ccStr")}
              rows={3}
              required
            ></textarea>
            <div className="text-xs text-gray-700">
              {cc ? cc.length : 0} emails
            </div>
          </div>
          <div className="w-1/3"></div>
        </div>
      </div>
    );
  }
}

export default OverdueSettingSectionForm;
