import React from "react";
import _ from "lodash";
import Select from "react-select";
import {
  PROPERTIES,
  OPERATIONS,
  CHECK_STATUS,
} from "../../../constants/Recipes";
import { UNIT_STATE } from "../../../constants/Unit-State";
import { UNIT_STATUS } from "../../../constants/Unit-Status";
import CriteriaPretty from "./CriteriaPretty";
import ChecksSelector from "../health-check/ChecksSelector";

const basicInputClass =
  "bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded py-1 px-4 block w-full appearance-none leading-normal";

class CriteriasEditor extends React.Component {
  constructor(props) {
    super(props);

    this.handleAddCC = this.handleAddCC.bind(this);
    this.handleRemoveCC = this.handleRemoveCC.bind(this);
  }

  handleAddCC(item) {
    this.props.handleAdd(item);
  }

  handleRemoveCC(item) {
    this.props.handleRemove(item);
  }

  render() {
    const { checks, checkCriterias } = this.props;

    return (
      <div>
        <div className="mb-4 flex flex-col gap-2 w-1/2">
          {checks &&
            checkCriterias.map((p) => {
              const check = _.find(checks, { id: p.check_id });
              const item = { ...p, check_name: check.name };

              return (
                <div
                  className="bg-gray-100 px-2 flex justify-between items-center"
                  key={p.check_id}
                >
                  <CriteriaPretty item={item}></CriteriaPretty>

                  <button
                    type="button"
                    className="text-blue-600 hover:underline px-2"
                    onClick={() => this.handleRemoveCC(p)}
                  >
                    Remove
                  </button>
                </div>
              );
            })}
        </div>
        <div>
          <CCEditor
            checkCriterias={checkCriterias}
            handleAddCC={this.handleAddCC}
          ></CCEditor>
        </div>
      </div>
    );
  }
}

class CCEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      check: null,
      results: [],

      finalCheckId: "",
      finalAcceptedResults: [],
    };

    this.handleCheckChanged = this.handleCheckChanged.bind(this);
    this.handleResultsChanged = this.handleResultsChanged.bind(this);
    this.handleAddClicked = this.handleAddClicked.bind(this);
  }

  handleCheckChanged(check) {
    this.setState({ check, finalCheckId: check.id });
  }

  handleResultsChanged(options) {
    // console.log(options);
    this.setState({
      results: options,
      finalAcceptedResults: _.map(options, "value"),
    });
    // this.setState({ op: option, finalOp: option.value });
  }

  handleAddClicked() {
    const { finalCheckId, finalAcceptedResults } = this.state;
    const newItem = {
      check_id: finalCheckId,
      accepted_results: finalAcceptedResults,
    };
    this.props.handleAddCC(newItem);
    this.setState({
      check: null,
      results: [],

      finalCheckId: "",
      finalAcceptedResults: [],
    });
  }

  isAddClickable() {
    let isClickable = false;
    const { finalCheckId, finalAcceptedResults } = this.state;
    if (
      finalCheckId &&
      finalAcceptedResults &&
      finalAcceptedResults.length > 0
    ) {
      isClickable = true;
    }

    return isClickable;
  }

  render() {
    const { checkCriterias, handleAddCC } = this.props;
    const {
      check,
      results,

      finalCheckId,
      finalAcceptedResults,
    } = this.state;

    return (
      <>
        <div className="border p-2">
          <div className="flex items-end gap-4">
            <div className="flex flex-col flex-auto">
              <span className="font-semibold text-gray-800 text-sm">check</span>
              <ChecksSelector
                excludeChecks={checkCriterias}
                selectedCheck={check}
                handleOnChange={this.handleCheckChanged}
              ></ChecksSelector>
            </div>
            <div className="flex flex-col flex-auto">
              <span className="font-semibold text-gray-800 text-sm">
                accepted results
              </span>

              <AcceptedResultsSelector
                selectedValue={results}
                handleChange={this.handleResultsChanged}
              ></AcceptedResultsSelector>
            </div>
            <div>
              <button
                type="button"
                className="bg-blue-100 rounded font-semibold px-4 py-2 text-blue-800"
                onClick={this.handleAddClicked}
              >
                Add
              </button>
            </div>
          </div>
        </div>
        {/* <div>{JSON.stringify(this.state)}</div> */}
      </>
    );
  }
}

const AcceptedResultsSelector = (props) => {
  const checkStatuses = _.keys(CHECK_STATUS);
  const options = _.map(checkStatuses, (p) => {
    return { value: p, label: p };
  });

  const { selectedValue, handleChange } = props;
  return (
    <Select
      defaultValue={selectedValue}
      value={selectedValue}
      onChange={handleChange}
      options={options}
      isSearchable={true}
      isMulti={true}
    ></Select>
  );
};

const PropertySelector = (props) => {
  const { preconditions } = props;
  const existingProperties = _.map(preconditions, "property");
  // {value, label}
  const properties = _.difference(_.keys(PROPERTIES), existingProperties);
  const options = _.map(properties, (p) => {
    const key = PROPERTIES[p].key;
    return { value: key, label: key };
  });

  const { selectedValue, handleChange } = props;

  return (
    <Select
      className="w-64"
      defaultValue={selectedValue}
      value={selectedValue}
      onChange={handleChange}
      options={options}
      isSearchable={false}
    ></Select>
  );
};

const OperationSelector = (props) => {
  const { property, selectedValue, handleChange } = props;
  if (!property) {
    return <div>-</div>;
  }

  const availableOps = PROPERTIES[property].availableOps;

  // {value, label}
  const items = _.intersection(_.keys(OPERATIONS), availableOps);

  const options = _.map(items, (item) => {
    const i = OPERATIONS[item];
    return { value: i.key, label: i.description };
  });

  return (
    <Select
      className="w-56"
      defaultValue={selectedValue}
      value={selectedValue}
      onChange={handleChange}
      options={options}
      isSearchable={false}
    ></Select>
  );
};

class ValueSelector extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { property, op, selectedValue, handleChange } = this.props;

    if (!property || !op) {
      return <div>-</div>;
    }

    let isMulti = OPERATIONS[op].isMulti;

    if (property === PROPERTIES.unit_state.key) {
      return (
        <UnitStateSelector
          selectedValue={selectedValue}
          handleChange={handleChange}
          isMulti={isMulti}
        ></UnitStateSelector>
      );
    }

    if (property === PROPERTIES.unit_status.key) {
      return (
        <UnitStatusSelector
          selectedValue={selectedValue}
          handleChange={handleChange}
          isMulti={isMulti}
        ></UnitStatusSelector>
      );
    }

    if (property === PROPERTIES.unit_age.key) {
      return (
        <input
          type="number"
          value={selectedValue}
          className={basicInputClass}
          autoFocus
          onChange={(e) => handleChange(e.target.value)}
        ></input>
      );
    }

    if (property === PROPERTIES.unit_state_age.key) {
      return (
        <input
          type="number"
          value={selectedValue}
          className={basicInputClass}
          min={1}
          autoFocus
          onChange={(e) => handleChange(e.target.value)}
        ></input>
      );
    }

    return "Not implemented";
  }
}

const UnitStateSelector = (props) => {
  // {value, label}
  const items = _.keys(UNIT_STATE);
  const options = _.map(items, (item) => {
    const name = UNIT_STATE[item];
    return { value: item, label: `${item}: ${name}` };
  });

  const { selectedValue, handleChange, isMulti } = props;

  return (
    <Select
      defaultValue={selectedValue}
      value={selectedValue}
      onChange={handleChange}
      options={options}
      isSearchable={false}
      isMulti={isMulti}
    ></Select>
  );
};

const UnitStatusSelector = (props) => {
  // {value, label}
  const items = _.keys(UNIT_STATUS);
  const options = _.map(items, (item) => {
    const name = UNIT_STATUS[item];
    return { value: item, label: `${item}: ${name}` };
  });

  const { selectedValue, handleChange, isMulti } = props;

  return (
    <Select
      defaultValue={selectedValue}
      value={selectedValue}
      onChange={handleChange}
      options={options}
      isSearchable={false}
      isMulti={isMulti}
    ></Select>
  );
};

export default CriteriasEditor;
