import React from "react";
import _ from "lodash";
import { getAutoPilotConfigSchema } from "../constants/AutoPilotConfig";
import DateTimeFormatter from "../../common/DateTimeFormatter";
import AutoPilotConfig from "./config/AutoPilotConfig";
import ConfigDiffView from "./config/ConfigDiffView";
import ScrollToTop from "../../common/ScrollToTop";

class AutoPilotConfigControl extends React.Component {
  constructor(props) {
    super(props);

    // { enabled: true, forerunner: {...} }
    const {
      config,
      defaultConfig,
      // isUnitConfig,
      isHintConfig,
    } = this.props;

    // { system: {sectionTitle, config...}, forerunner: {...} }
    const schemasGroupedBySection = getAutoPilotConfigSchema(
      defaultConfig,
      isHintConfig
    );

    this.state = {
      currentEditingKey: null,

      schemasGroupedBySection,

      defaultConfig,
      originalConfig: config,
      newConfig: _.cloneDeep(config),
    };

    this.handleStartEditMode = this.handleStartEditMode.bind(this);
    this.handleCancelEditMode = this.handleCancelEditMode.bind(this);
    this.handleApplyChanges = this.handleApplyChanges.bind(this);

    this.handleSaveClicked = this.handleSaveClicked.bind(this);
  }

  // configKey: "enabled" / "prophet.publisher_refresh_control_type"
  handleStartEditMode(configKey) {
    console.log("start editing", configKey);
    this.setState({
      currentEditingKey: configKey,
    });
  }

  handleCancelEditMode() {
    console.log("cancel ....");
    this.setState({
      currentEditingKey: null,
    });
  }

  handleApplyChanges({ sectionConfigKey, configKey, newValue, isUseDefault }) {
    console.log("apply changes", {
      sectionConfigKey,
      configKey,
      newValue,
      isUseDefault,
    });

    let newConfig = this.state.newConfig;

    if (isUseDefault) {
      // remove from config
      if (sectionConfigKey) {
        _.unset(newConfig, [sectionConfigKey, configKey]);
      } else {
        _.unset(newConfig, configKey);
      }
    } else {
      // add/edit to config
      if (sectionConfigKey) {
        _.set(newConfig, [sectionConfigKey, configKey], newValue);
      } else {
        _.set(newConfig, configKey, newValue);
      }
    }

    // // remove if allowed_device_categories is empty
    // if (
    //   _.has(newConfig, "segmentation.allowed_device_categories") &&
    //   newConfig.segmentation.allowed_device_categories === ""
    // ) {
    //   newConfig.segmentation = _.omit(
    //     newConfig.segmentation,
    //     "allowed_device_categories"
    //   );
    // }

    // to remove empty array values like ad_sizes
    // eg. {allowed_ad_sizes: []} -> {}
    // The following keys are allowed to have empty value!
    // forbid_client_refresh_labels
    newConfig.prophet = _.omitBy(newConfig.prophet, (c, key) => {
      if (key === "forbid_client_refresh_labels") return false;
      return typeof c === "object" && _.isEmpty(c);
    });

    // empty keys with empty object or array value
    // eg. {"forerunner": {} } -> {}
    newConfig = _.omitBy(newConfig, (c) => {
      return typeof c === "object" && _.isEmpty(c);
    });

    this.setState({
      currentEditingKey: null,
      newConfig,
    });
  }

  handleSaveClicked() {
    this.props.handleSaveConfig({
      config: this.state.newConfig,
      // networkId,
      // unitId,
    });
  }

  render() {
    const {
      currentEditingKey,
      schemasGroupedBySection,
      newConfig,
      originalConfig,
      defaultConfig,
    } = this.state;
    const { unitId, networkId, updatedAt, isUnitConfig, isHintConfig } =
      this.props;

    return (
      newConfig && (
        <>
          <div className="mt-2 flex">
            {/* MAIN CONFIG */}
            <div className="w-2/3">
              <AutoPilotConfig
                unitId={unitId}
                networkId={networkId}
                isUnitConfig={isUnitConfig}
                isHintConfig={isHintConfig}
                currentEditingKey={currentEditingKey}
                handleStartEditMode={this.handleStartEditMode}
                handleCancelEditMode={this.handleCancelEditMode}
                handleApplyChanges={this.handleApplyChanges}
                schemasGroupedBySection={schemasGroupedBySection}
                newConfig={newConfig}
                handleShowAdvancedSettings={this.handleShowAdvancedSettings}
              ></AutoPilotConfig>
            </div>

            {/* SIDE */}
            <div className="fixed right-0 bottom-0 w-1/3" style={{ top: 108 }}>
              {updatedAt && (
                <div className="text-sm text-gray-600">
                  Last update time:
                  <br />
                  <DateTimeFormatter datetime={updatedAt}></DateTimeFormatter>
                </div>
              )}

              <div className="mt-8">
                <div className="overflow-y-auto" style={{ maxHeight: "420px" }}>
                  <ConfigDiffView
                    originalConfig={originalConfig}
                    newConfig={newConfig}
                    defaultConfig={defaultConfig}
                  ></ConfigDiffView>
                </div>

                <div className="my-4 text-center">
                  <button
                    type="button"
                    disabled={!!currentEditingKey}
                    className={`rounded shadow bottom-0 p-4 font-semibold text-white ${
                      currentEditingKey
                        ? "cursor-not-allowed bg-gray-400"
                        : "bg-blue-400 hover:bg-blue-600"
                    }`}
                    onClick={this.handleSaveClicked}
                  >
                    {isHintConfig ? (
                      <span>
                        Preview <b>hint config</b> then save
                      </span>
                    ) : (
                      <span>
                        Preview <b>base config</b> then save
                      </span>
                    )}
                  </button>
                </div>
              </div>

              <div>
                <ScrollToTop></ScrollToTop>
              </div>
            </div>
          </div>
        </>
      )
    );
  }
}

export default AutoPilotConfigControl;
