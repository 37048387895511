import React from "react";

function LoginButton(props) {
  return <SignInWithSlackButton></SignInWithSlackButton>;
}

class SignInWithSlackButton extends React.Component {
  render() {
    const authUrl = "https://slack.com/oauth/authorize";
    const clientId = process.env.REACT_APP_SLACK_CLIENT_ID;

    let redirectUrl = encodeURIComponent(
      `${process.env.REACT_APP_OBSERVER_API_ENDPOINT}/login`
    );

    if (process.env.REACT_APP_ENV === "staging") {
      redirectUrl = encodeURIComponent(
        `${process.env.REACT_APP_OBSERVER_API_ENDPOINT}/staging-login`
      );
    }

    const websiteRedirectUri = encodeURIComponent(`${window.location.href}`);

    return (
      <a
        // href={`${authUrl}?scope=identity.basic&client_id=${clientId}`}
        href={`${authUrl}?scope=identity.basic,identity.email&client_id=${clientId}&redirect_uri=${redirectUrl}&state=${websiteRedirectUri}`}
      >
        <img
          alt="Sign in with Slack"
          height="40"
          width="172"
          src="https://platform.slack-edge.com/img/sign_in_with_slack.png"
          srcSet="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x"
        />
      </a>
    );
  }
}

export default LoginButton;
