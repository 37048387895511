import React from "react";
import _ from "lodash";

import { notify } from "react-notify-toast";
import { PublisherAPI } from "apis";
import PublisherInfoHeader from "../../network-features/PublisherInfoHeader";
import LoadingUI from "components/common/LoadingUI";
import PricingConfigForm from "./PricingConfigForm";
import PricingDefaultConfigView from "./PricingDefaultConfigView";
import { buttonConfirmClass } from "helpers/StyleClass";

class PricingConfigViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,

      isEditMode: false,
      publisherConfig: null,
    };

    this.handleEditClicked = this.handleEditClicked.bind(this);
    this.handleCancelClicked = this.handleCancelClicked.bind(this);
    this.handleSaveClicked = this.handleSaveClicked.bind(this);
    this.handleConfigChanged = this.handleConfigChanged.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });

    try {
      const { publisherId } = this.props.match.params;
      if (!publisherId) {
        throw new Error("Missing Publisher ID");
      }
      document.title = `Publisher ${publisherId} Pricing Config | YB Observer`;

      const r = await PublisherAPI.getPublisherPricingConfig({ publisherId });
      console.log(r);

      let publisherConfig = null;
      if (r) {
        publisherConfig = _.get(r, "pricingDefaultConfig");
      }

      this.setState({
        publisherId,
        publisherConfig,

        isLoading: false,
        errMsg: null,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        errMsg: err.toString(),
        setting: null,
      });
    }
  }

  handleEditClicked() {
    this.setState({ isEditMode: true });
  }

  handleCancelClicked() {
    this.setState({ isEditMode: false });
  }

  handleConfigChanged(newConfig) {
    this.setState({ publisherConfig: newConfig });
  }

  async handleSaveClicked({ pricingDefaultConfigId }) {
    try {
      const params = {
        publisherId: parseInt(this.state.publisherId),
        pricingDefaultConfigId: pricingDefaultConfigId,
      };
      const { result } =
        await PublisherAPI.createOrUpdatePublisherPricingConfig(params);
      console.log(result);
      notify.show("Successfully updated pricing config", "success");
      const publisherConfig = _.get(result, "pricingDefaultConfig");
      this.setState({ publisherConfig });

      setTimeout(() => {
        this.handleCancelClicked();
      });
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    const { errMsg, isLoading, publisherConfig, publisherId, isEditMode } =
      this.state;

    return (
      <div>
        {publisherId && (
          <PublisherInfoHeader pubId={publisherId}></PublisherInfoHeader>
        )}

        <div className="min-h-screen bg-white px-12 py-8">
          <div className="flex items-center justify-between">
            <PageTitle title="Publisher Pricing Config"></PageTitle>
          </div>

          <div className="mt-4">
            {isLoading && <LoadingUI></LoadingUI>}

            {/* <div>{JSON.stringify(publisherConfig)}</div> */}

            <div className="w-2/3">
              {!isEditMode && publisherConfig && (
                <>
                  <div className="text-lg font-semibold">Pricing Config:</div>
                  <PricingDefaultConfigView
                    item={publisherConfig}
                  ></PricingDefaultConfigView>
                  <div className="flex justify-end">
                    <button
                      className={buttonConfirmClass}
                      onClick={this.handleEditClicked}
                    >
                      Change Config
                    </button>
                  </div>
                </>
              )}

              {isEditMode && (
                <>
                  <PricingConfigForm
                    publisherConfig={publisherConfig}
                    handleCancelClicked={this.handleCancelClicked}
                    handleSaveClicked={this.handleSaveClicked}
                    handleConfigChanged={this.handleConfigChanged}
                  ></PricingConfigForm>
                </>
              )}

              {errMsg && (
                <div className="rounded mb-4 bg-red-100 p-4 font-semibold text-red-700">
                  {errMsg}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function PageTitle({ title }) {
  return <div className="text-3xl font-extrabold">{title}</div>;
}

export default PricingConfigViewer;
