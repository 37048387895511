import React from "react";
import { Switch, Route, NavLink, withRouter } from "react-router-dom";
import _ from "lodash";
import DiagnoserByNetworkViewer from "./DiagnoserByNetworkViewer";

const basePaths = {
  base: "/diagnoser",
  network: "/diagnoser/network/:networkId",
};

class DiagnoserViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,
    };
  }

  async componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      // Route changed!
      // console.log(
      //   "route changed",
      //   prevProps.location.pathname,
      //   this.props.location.pathname
      // );
    }
  }

  render() {
    const { isLoading, errMsg } = this.state;

    let baseSection = null;
    const loadingSection = <div>Loading...</div>;
    const errMsgFullpage = (
      <div className="min-h-screen bg-white text-red-700">{errMsg}</div>
    );

    if (isLoading) {
      baseSection = loadingSection;
    }
    if (errMsg) {
      baseSection = errMsgFullpage;
    }

    return (
      <div>
        <div>{baseSection && baseSection}</div>
        {!baseSection && (
          <Switch>
            <Route
              path={basePaths.network}
              render={(props) => {
                return (
                  <DiagnoserByNetworkViewer
                    {...props}
                  ></DiagnoserByNetworkViewer>
                );
              }}
            ></Route>

            <Route
              exact
              path={basePaths.base}
              render={() => (
                <div className="text-white">Diagnoser Base View</div>
              )}
            ></Route>
          </Switch>
        )}
      </div>
    );
  }
}

// const BaseWrapper = (props) => {
//   return (
//     <div>
//       <BaseHeader title="Diagnoser"></BaseHeader>

//       <div className="min-h-screen bg-gray-200 px-12 py-8">
//         {props.children}
//       </div>
//     </div>
//   );
// };

// const BaseHeader = ({ title }) => {
//   return (
//     <div className="border-b bg-gray-100 py-2 px-12">
//       <div className="flex items-center justify-between">
//         <div className="flex items-center gap-4">
//           <div className="text-lg font-semibold text-gray-800">{title}</div>
//         </div>
//       </div>
//     </div>
//   );
// };

export default withRouter(DiagnoserViewer);
