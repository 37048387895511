import React from "react";
import _ from "lodash";
import Select from "react-select";

import { NetworkAPI } from "apis";

class NetworksSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      options: [],
      selectedValue: null,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    const items = await NetworkAPI.getNetworks();

    const valueKey = this.props.valueKey || "networkId";

    let options = _.map(items, (r) => {
      return {
        value: r[valueKey],
        label: `${r.networkId} - ${r.name}`,
      };
    });

    options = _.orderBy(options, ["value"], ["desc"]);

    this.setState({
      items,
      options,
    });
  }

  handleChange(option) {
    this.setState({
      selectedValue: option,
    });

    if (this.props.isMulti) {
      const ids = _.map(option, (o) => {
        return o.value;
      });
      this.props.handleOnChange(ids);
    } else {
      const valueKey = this.props.valueKey || "networkId";
      const item = _.find(this.state.items, { [valueKey]: option.value });
      this.props.handleOnChange(item);
    }
  }

  render() {
    const { options, selectedValue } = this.state;

    return (
      <>
        <Select
          value={selectedValue}
          onChange={this.handleChange}
          options={options}
          isMulti={this.props.isMulti}
        />
      </>
    );
  }
}

export default NetworksSelector;
