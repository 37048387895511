import React from "react";
import _ from "lodash";
import queryString from "query-string";
import { NetworkAPI } from "apis";
import LoadingUI from "../../../common/LoadingUI";
import NetworkInfoHeader from "../../common/NetworkInfoHeader";
import { formatMoneyWithCurrency } from "../../../playground/home-dashboard-v2/ReportsHelper";
import UprTargetingPopover from "./UprTargetingPopover";
import DateTimeFormatter from "../../../common/DateTimeFormatter";
import { notify } from "react-notify-toast";
import UprPriceSectionPopover from "./UprPriceSectionPopover";
import ReactTooltip from "react-tooltip";
import { FiCheckCircle, FiXCircle } from "react-icons/fi";
import CustomUPRSettingModal from "./CustomUPRSettingModal";
import BatchUpdateRulesModal from "./BatchUpdateRulesModal";

const buttonActionClass =
  "px-2 py-1 bg-gray-200 text-xs rounded border border-gray-400 shadow hover:bg-gray-300 text-gray-900 font-semibold";

class NetworkUPRSettingViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      isLoading: false,

      networkId: null,
      networkInfo: null,
      errMsg: "",

      units: [],
      virtualPlacements: [],
      currentRule: null,
      originalRules: null, // save original for diff
      customRules: null,

      labels: [],
      configsByLabel: [],

      modalType: null, // "ADD", "EDIT"
      isModalOpen: false,
      formErrMsg: null,

      isEditing: false,
      isSaving: false,
      saveErrMsg: null,

      isBatchUpdateModalOpen: false,
    };

    this.handleCurrentRuleChanged = this.handleCurrentRuleChanged.bind(this);
    this.handleOpenEdit = this.handleOpenEdit.bind(this);
    this.handleOpenAdd = this.handleOpenAdd.bind(this);
    this.handleOpenDuplicate = this.handleOpenDuplicate.bind(this);

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleEditRule = this.handleEditRule.bind(this);
    this.handleAddRule = this.handleAddRule.bind(this);
    this.handleRemoveRule = this.handleRemoveRule.bind(this);
    this.handleToggleEnabled = this.handleToggleEnabled.bind(this);
    this.handleToggleEnabledByLabel =
      this.handleToggleEnabledByLabel.bind(this);

    this.handleSaveCustomUprs = this.handleSaveCustomUprs.bind(this);
    this.handleClearCustomUprs = this.handleClearCustomUprs.bind(this);

    this.handleIsEditing = this.handleIsEditing.bind(this);
    this.handleOpenBatchUpdateModal =
      this.handleOpenBatchUpdateModal.bind(this);
    this.handleBatchUpdateRules = this.handleBatchUpdateRules.bind(this);
    this.scrollToLabel = this.scrollToLabel.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      const { networkId } = this.props.match.params;
      if (!networkId) {
        throw new Error("Missing Network ID");
      }
      const networkInfo = await NetworkAPI.getNetworkInfo({ networkId });
      if (!networkInfo) {
        throw new Error("Invalid Network");
      }

      document.title = `${networkId} Network Custom UPR | YB Observer`;

      const units = await NetworkAPI.getUnitsByNetworkId({ networkId });
      const virtualPlacements = await NetworkAPI.getNetworkVirtualPlacements({
        networkId,
      });
      const { proposedAt, deployedAt, customSettings } =
        await NetworkAPI.getNetworkCustomUpr({ networkId });
      // New schema for v2: customs is an array of array instead of an array of objects
      // convert customs to v2
      _.forEach(customSettings, (c) => {
        if (c.targetingSection && c.targetingSection.customs) {
          if (!_.isArray(c.targetingSection.customs[0])) {
            c.targetingSection.customs = [c.targetingSection.customs];
          }
        }
      });

      this._groupLabels(customSettings);

      // Has import querystring
      // const value = queryString.parse(this.props.location.search);
      const { importId } = queryString.parse(this.props.location.search);
      if (importId) {
        this.handleAutoImport({ importId });
      }

      this.setState({
        networkId,
        networkInfo,
        originalRules: customSettings,
        customRules: customSettings,
        proposedAt,
        deployedAt,
        units,
        virtualPlacements,

        isLoading: false,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        errMsg: err.toString(),
      });
    }
  }

  _groupLabels(customSettings) {
    const configsByLabel = _.groupBy(customSettings, "label");
    let labels = _.keys(configsByLabel).map((label, i) => {
      // label could be undefined
      return {
        name: `${label === "undefined" ? "No label" : label} (${
          configsByLabel[label].length
        })`,
        value: label,
        numOfItems: configsByLabel[label].length,
        index: label === "undefined" ? 99999 : i,
      };
    });
    labels = _.sortBy(labels, "index");

    this.setState({ labels, configsByLabel });
  }

  async handleClearCustomUprs() {
    const { networkId } = this.state;
    this.setState({ isSaving: true });
    try {
      console.log("update upr", networkId);
      const { result } = await NetworkAPI.updateNetworkCustomUpr({
        networkId,
        customSettings: [], // to clear custom uprs
      });
      if (result.op === "ok" || result.op === "OK") {
        notify.show("Custom UPRs cleared! Reloading page...", "success");
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      } else {
        throw `Failed response: ${JSON.stringify(result)}`;
      }
    } catch (err) {
      this.setState({ saveErrMsg: err });
    }
    this.setState({ isSaving: false });
  }

  async handleSaveCustomUprs() {
    const { networkId, customRules } = this.state;
    this.setState({ isSaving: true });
    try {
      console.log("update upr", networkId, customRules);
      const { result } = await NetworkAPI.updateNetworkCustomUpr({
        networkId,
        customSettings: customRules,
      });
      console.log(result);
      if (result.op === "ok" || result.op === "OK") {
        this.setState({ saveErrMsg: null });
        notify.show("Rule updated! Reloading page...", "success");
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      } else {
        throw `Failed response: ${JSON.stringify(result)}`;
      }
    } catch (err) {
      this.setState({ saveErrMsg: err.toString() });
    }
    this.setState({ isSaving: false });
  }

  handleCurrentRuleChanged(newRule) {
    this.setState({
      currentRule: newRule,
    });
  }

  handleAutoImport({ importId }) {
    this.handleOpenAdd({ importId });
  }

  handleOpenAdd({ importId }) {
    const defaultRule = {
      enabled: true,
      targetingSection: {},
      priceSection: {
        priceType: 0,
      },
    };
    this.setState({
      isModalOpen: true,
      modalType: "ADD",
      currentRule: defaultRule,
      importId,
    });
  }

  handleOpenEdit(rule) {
    console.log("edit", rule);
    this.setState({ currentRule: rule, isModalOpen: true, modalType: "EDIT" });
  }

  handleOpenDuplicate(rule) {
    let duplicateRule = _.cloneDeep(rule);
    duplicateRule.id = "";
    duplicateRule.name = `${rule.name} copied`;

    this.setState({
      currentRule: duplicateRule,
      isModalOpen: true,
      modalType: "ADD",
    });
  }

  handleCloseModal() {
    this.setState({
      isModalOpen: false,
      currentRule: null,
      modalType: null,
      formErrMsg: null,
      isEditing: false,
      isBatchUpdateModalOpen: false,
    });
  }

  validate() {
    let hasError = false;
    let newRule = this.state.currentRule;

    if (!newRule.id) {
      this.setState({
        formErrMsg: "ID is required",
      });
      hasError = true;
      return hasError;
    }
    if (!newRule.name) {
      this.setState({
        formErrMsg: "Name is required",
      });
      hasError = true;
      return hasError;
    }
    if (!newRule.priceSection) {
      this.setState({
        formErrMsg: "Pricing is required",
      });
      hasError = true;
      return hasError;
    } else {
      // LGO (2) and Use Brand Setting (-1) has no need for price
      if (
        newRule.priceSection.priceType !== 2 &&
        newRule.priceSection.priceType !== -1 &&
        (newRule.priceSection.price === null ||
          newRule.priceSection.price === undefined ||
          newRule.priceSection.price === "")
      ) {
        this.setState({
          formErrMsg: "Price is required",
        });
        hasError = true;
        return hasError;
      }
    }

    if (!newRule.targetingSection || _.isEmpty(newRule.targetingSection)) {
      this.setState({
        formErrMsg: "Targeting is required",
      });
      hasError = true;
      return hasError;
    }

    const unitIds = _.get(newRule, [
      "targetingSection",
      "inventory",
      "unitIds",
    ]);
    // const placementIds = _.get(newRule, [
    //   "targetingSection",
    //   "inventory",
    //   "placementIds",
    // ]);
    // const virtualPlacementIds = _.get(newRule, [
    //   "targetingSection",
    //   "inventory",
    //   "virtualPlacementIds",
    // ]);
    const excludedUnitIds = _.get(newRule, [
      "targetingSection",
      "inventory",
      "excludedUnitIds",
    ]);
    const hasUnitIds = unitIds && unitIds.length > 0;
    // const hasPlacementIds = placementIds && placementIds.length > 0;
    // const hasVirtualPlacementIds =
    // virtualPlacementIds && virtualPlacementIds.length > 0;
    const hasExcludeUnitIds = excludedUnitIds && excludedUnitIds.length > 0;

    // Inventory Targeting is not required 10/13/2023
    // if (
    //   !hasUnitIds &&
    //   !hasPlacementIds &&
    //   !hasVirtualPlacementIds &&
    //   !hasExcludeUnitIds
    // ) {
    //   this.setState({
    //     formErrMsg: "Targeting inventory is required",
    //   });

    //   hasError = true;
    //   return hasError;
    // }

    // unitIds and excludeUnitIds cannot include the same unit ids
    if (hasUnitIds && hasExcludeUnitIds) {
      const duplicateIds = _.intersection(unitIds, excludedUnitIds);
      if (duplicateIds.length > 0) {
        this.setState({
          formErrMsg: `Targeting units cannot be targeted in both Unit IDs and Exclude Unit IDs`,
        });

        hasError = true;
        return hasError;
      }
    }
  }

  handleEditRule() {
    if (this.state.isEditing) {
      return this.setState({
        formErrMsg: "Customs or Brand Price Settings is still editing",
      });
    }

    const hasError = this.validate();
    if (hasError) return;

    let { customRules } = this.state;
    let editedRule = this.state.currentRule;

    const unitIds = _.get(editedRule, [
      "targetingSection",
      "inventory",
      "unitIds",
    ]);
    const excludedUnitIds = _.get(editedRule, [
      "targetingSection",
      "inventory",
      "excludedUnitIds",
    ]);
    const placementIds = _.get(editedRule, [
      "targetingSection",
      "inventory",
      "placementIds",
    ]);
    const virtualPlacementIds = _.get(editedRule, [
      "targetingSection",
      "inventory",
      "virtualPlacementIds",
    ]);

    // remove empty inventory array...
    // ex. placementIds: [] // is not allowed
    if (unitIds && unitIds.length === 0) {
      editedRule = _.omit(editedRule, ["targetingSection.inventory.unitIds"]);
    }
    if (excludedUnitIds && excludedUnitIds.length === 0) {
      editedRule = _.omit(editedRule, [
        "targetingSection.inventory.excludedUnitIds",
      ]);
    }
    if (placementIds && placementIds.length === 0) {
      editedRule = _.omit(editedRule, [
        "targetingSection.inventory.placementIds",
      ]);
    }
    if (virtualPlacementIds && virtualPlacementIds.length === 0) {
      editedRule = _.omit(editedRule, [
        "targetingSection.inventory.virtualPlacementIds",
      ]);
    }

    const inv = _.get(editedRule, ["targetingSection", "inventory"], null);
    if (!inv || _.isEmpty(inv)) {
      editedRule = _.omit(editedRule, ["targetingSection.inventory"]);
    }
    if (editedRule.label === "") {
      editedRule.label = undefined;
    }

    const newRules = _.map(customRules, (r) => {
      // find the edited rule
      if (r.id === editedRule.id) {
        r = editedRule;
      }

      return r;
    });

    this._groupLabels(newRules);
    this.setState({ customRules: newRules });
    this.handleCloseModal();
  }

  handleAddRule() {
    if (this.state.isEditing) {
      return this.setState({
        formErrMsg: "Customs or Brand Price Settings is still editing",
      });
    }

    const hasError = this.validate();
    if (hasError) return;

    let { customRules } = this.state;
    let newRule = this.state.currentRule;

    const unitIds = _.get(newRule, [
      "targetingSection",
      "inventory",
      "unitIds",
    ]);
    const excludedUnitIds = _.get(newRule, [
      "targetingSection",
      "inventory",
      "excludedUnitIds",
    ]);
    const placementIds = _.get(newRule, [
      "targetingSection",
      "inventory",
      "placementIds",
    ]);
    const virtualPlacementIds = _.get(newRule, [
      "targetingSection",
      "inventory",
      "virtualPlacementIds",
    ]);
    // remove empty inventory array...
    // ex. placementIds: [] // is not allowed
    if (unitIds && unitIds.length === 0) {
      newRule = _.omit(newRule, ["targetingSection.inventory.unitIds"]);
    }
    if (excludedUnitIds && excludedUnitIds.length === 0) {
      newRule = _.omit(newRule, ["targetingSection.inventory.excludedUnitIds"]);
    }
    if (placementIds && placementIds.length === 0) {
      newRule = _.omit(newRule, ["targetingSection.inventory.placementIds"]);
    }
    if (virtualPlacementIds && virtualPlacementIds.length === 0) {
      newRule = _.omit(newRule, [
        "targetingSection.inventory.virtualPlacementIds",
      ]);
    }

    const inv = _.get(newRule, ["targetingSection", "inventory"], null);
    if (!inv || _.isEmpty(inv)) {
      newRule = _.omit(newRule, ["targetingSection.inventory"]);
    }
    if (newRule.label === "") {
      newRule.label = undefined;
    }

    newRule.id = _.parseInt(newRule.id);
    // console.log(customRules, newRule);

    // ID should be unique
    const isIdUniq =
      _.find(customRules, (r) => {
        return r.id === newRule.id;
      }) === undefined;
    if (!isIdUniq) {
      this.setState({
        formErrMsg: "This ID already exists, please change the ID number.",
      });
    } else {
      let newRules = [...customRules, newRule];
      newRules = _.sortBy(newRules, ["id"]);

      this._groupLabels(newRules);
      this.setState({
        formErrMsg: null,
        customRules: newRules,
      });
      this.handleCloseModal();
    }
  }

  handleRemoveRule(rule) {
    const userConfirm = window.confirm(`Remove rule: ${rule.id} ${rule.name}?`);
    if (!userConfirm) return;

    let { customRules } = this.state;
    const newRules = _.filter(customRules, (r) => {
      return r.id !== rule.id;
    });
    this._groupLabels(newRules);

    this.setState({ customRules: newRules });
  }

  handleToggleEnabled(rule) {
    let { customRules } = this.state;
    const newRules = _.map(customRules, (r) => {
      if (r.id === rule.id) {
        r.enabled = !rule.enabled;
      }
      return r;
    });

    this._groupLabels(newRules);
    this.setState({ customRules: newRules });
  }

  handleToggleEnabledByLabel({ label, isEnable }) {
    const isNoLabel = label === "undefined";
    const userConfirm = window.confirm(
      `${isEnable ? "Enable" : "Disable"} all rules in ${
        isNoLabel ? "No label" : label
      }?`
    );

    if (!userConfirm) return;

    let { customRules } = this.state;
    const newRules = _.map(customRules, (r) => {
      if (isNoLabel && !r.label) {
        r.enabled = isEnable ? true : false;
        return r;
      }

      if (r.label === label) {
        r.enabled = isEnable ? true : false;
        return r;
      }

      return r;
    });

    this._groupLabels(newRules);
    this.setState({ customRules: newRules });
  }

  handleIsEditing(isEditing) {
    this.setState({ isEditing });
  }

  handleOpenBatchUpdateModal() {
    this.setState({ isBatchUpdateModalOpen: true });
  }

  handleBatchUpdateRules(ruleIds, newTargetingSection, targetingType) {
    let { customRules } = this.state;

    const newRules = _.map(customRules, (r) => {
      if (_.indexOf(ruleIds, r.id) !== -1) {
        r.targetingSection = {
          ...r.targetingSection,
          [targetingType]: newTargetingSection[targetingType],
        };
        console.log(r);
      }
      return r;
    });

    console.log(newRules);

    this._groupLabels(newRules);

    this.setState({
      customRules: newRules,
      isBatchUpdateModalOpen: false,
    });
  }

  scrollToLabel(label) {
    const element = document.getElementById(label);

    if (element) {
      window.scrollTo({
        behavior: "auto",
        top: element.offsetTop - 56,
      });
    }
  }

  render() {
    const {
      networkId,
      networkInfo,
      originalRules,
      customRules,
      proposedAt,
      deployedAt,

      currentRule,
      units,
      virtualPlacements,

      labels,
      configsByLabel,

      isLoading,
      errMsg,

      isModalOpen,
      modalType,
      formErrMsg,
      isEditing,

      isSaving,
      saveErrMsg,

      isBatchUpdateModalOpen,

      importId,
    } = this.state;

    return (
      <div>
        {isLoading && <LoadingUI></LoadingUI>}
        {errMsg && <div className="text-red-800">{errMsg}</div>}

        {networkInfo && (
          <div>
            <NetworkInfoHeader networkInfo={networkInfo}></NetworkInfoHeader>

            <div className="min-h-screen bg-gray-200 px-12 py-8">
              <div className="flex items-center justify-between">
                <PageTitle title="Network Custom UPRs"></PageTitle>
              </div>

              <div className="flex w-full">
                <div className="my-2 w-3/4 pr-2">
                  <div className="rounded flex items-center justify-between bg-white p-4">
                    <div>
                      <button
                        type="button"
                        className={buttonActionClass}
                        onClick={this.handleOpenBatchUpdateModal}
                      >
                        Batch update rules
                      </button>
                    </div>
                    <button
                      type="button"
                      className="shadow rounded bg-blue-200 py-2 px-4 font-medium text-blue-800 hover:bg-blue-300"
                      onClick={this.handleOpenAdd}
                    >
                      Add custom rule
                    </button>
                  </div>

                  <div style={{ marginBottom: "600px" }}>
                    <div className="my-4 flex flex-wrap items-center text-sm">
                      <div className="mr-1 font-semibold text-gray-700">
                        Jump to:{" "}
                      </div>
                      {labels.map((label) => {
                        return (
                          <button
                            key={label.value}
                            type="button"
                            className="border rounded mb-1 mr-1 border-gray-200 bg-white px-2 py-1 text-gray-800 hover:border-blue-400"
                            onClick={() => {
                              this.scrollToLabel(label.value);
                            }}
                          >
                            {label.name}
                          </button>
                        );
                      })}
                    </div>

                    {labels.map((label) => {
                      return (
                        <div
                          id={label.value}
                          key={label.value}
                          className="border mb-8 border-gray-400"
                        >
                          <div className="flex items-center justify-between  bg-white p-4 text-xl font-semibold text-gray-800">
                            <div>
                              {label.value === "undefined"
                                ? "No label"
                                : label.value}
                            </div>

                            <div className="flex items-center gap-2">
                              <div>
                                {
                                  <div className="flex items-center justify-end gap-4 bg-white px-4 text-sm">
                                    <button
                                      className="text-blue-600 hover:underline"
                                      onClick={() =>
                                        this.handleToggleEnabledByLabel({
                                          label: label.value,
                                          isEnable: true,
                                        })
                                      }
                                    >
                                      Enable All
                                    </button>
                                    <button
                                      className="text-blue-600 hover:underline"
                                      onClick={() =>
                                        this.handleToggleEnabledByLabel({
                                          label: label.value,
                                          isEnable: false,
                                        })
                                      }
                                    >
                                      Disable All
                                    </button>
                                  </div>
                                }
                              </div>

                              <div>{label.numOfItems}</div>
                            </div>
                          </div>

                          <UPRSettingsTable
                            networkId={networkId}
                            items={configsByLabel[label.value]}
                            networkInfo={networkInfo}
                            units={units}
                            virtualPlacements={virtualPlacements}
                            handleOpenEdit={this.handleOpenEdit}
                            handleOpenDuplicate={this.handleOpenDuplicate}
                            handleRemoveRule={this.handleRemoveRule}
                            handleToggleEnabled={this.handleToggleEnabled}
                          ></UPRSettingsTable>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="w-1/4 pl-2">
                  <div className="mb-4 text-sm font-semibold text-gray-700">
                    <div className="text-gray-600">Proposed at:</div>
                    <DateTimeFormatter
                      datetime={proposedAt}
                    ></DateTimeFormatter>
                  </div>

                  <div className="text-sm font-semibold text-gray-700">
                    <div className="text-gray-600">Deployed at:</div>
                    <DateTimeFormatter
                      datetime={deployedAt}
                    ></DateTimeFormatter>
                  </div>

                  {/* TODO */}
                  {/* <div className="py-4 border-b">
                    <CustomUPRDiffView
                      originalRules={originalRules}
                      newRules={customRules}
                    ></CustomUPRDiffView>
                  </div> */}

                  <div className="mt-4 flex justify-center">
                    <button
                      type="button"
                      className="shadow rounded bg-blue-400 py-3 px-8 font-semibold text-white hover:bg-blue-600"
                      onClick={this.handleSaveCustomUprs}
                    >
                      Save Custom UPRs
                    </button>
                  </div>

                  {/* <div className="flex justify-center mt-4">
                    <button
                      type="button"
                      className="bg-red-400 hover:bg-red-600 text-white font-semibold py-3 px-8 shadow rounded"
                      onClick={this.handleClearCustomUprs}
                    >
                      Clear Custom UPRs
                    </button>
                  </div> */}

                  <div className="font-semibold text-red-600">{saveErrMsg}</div>
                </div>
              </div>

              {isModalOpen && (
                <CustomUPRSettingModal
                  networkId={networkId}
                  networkInfo={networkInfo}
                  currentRule={currentRule}
                  isModalOpen={isModalOpen}
                  modalType={modalType}
                  importId={importId}
                  formErrMsg={formErrMsg}
                  isEditing={isEditing}
                  isSaving={isSaving}
                  handleCloseModal={this.handleCloseModal}
                  handleIsEditing={this.handleIsEditing}
                  handleCurrentRuleChanged={this.handleCurrentRuleChanged}
                  handleAddRule={this.handleAddRule}
                  handleEditRule={this.handleEditRule}
                ></CustomUPRSettingModal>
              )}

              {isBatchUpdateModalOpen && (
                <BatchUpdateRulesModal
                  networkId={networkId}
                  networkInfo={networkInfo}
                  isModalOpen={isBatchUpdateModalOpen}
                  customRules={customRules}
                  //
                  formErrMsg={formErrMsg}
                  isEditing={isEditing}
                  isSaving={isSaving}
                  handleCloseModal={this.handleCloseModal}
                  handleBatchUpdateRules={this.handleBatchUpdateRules}
                ></BatchUpdateRulesModal>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

class UPRSettingsTable extends React.PureComponent {
  render() {
    const {
      items,
      units,
      virtualPlacements,
      networkInfo,
      handleOpenEdit,
      handleRemoveRule,
      handleOpenDuplicate,
      handleToggleEnabled,
      networkId,
    } = this.props;

    if (!items) return "";

    if (items.length === 0) {
      return <div>No Custom UPRs</div>;
    }

    const itemsByEnabled = _.groupBy(items, "enabled");
    const enabledNum = _.get(itemsByEnabled, ["true"], []).length;
    const disabledNum = _.get(itemsByEnabled, ["false"], []).length;

    return (
      <div>
        <div className="-mt-6 flex justify-end gap-2 bg-white px-4 py-2 text-sm">
          <div className="flex items-center">
            <span className="text-green-600">
              <FiCheckCircle></FiCheckCircle>
            </span>
            : {enabledNum}
          </div>
          <div className="flex items-center">
            <span className="text-red-600">
              <FiXCircle></FiXCircle>
            </span>
            : {disabledNum}
          </div>
        </div>

        <table className="border shadow table w-full text-sm">
          <thead className="border-b bg-gray-200 text-xs text-blue-800">
            <tr>
              <th className="border py-1 px-2 text-center">Enabled</th>
              <th className="border py-1 px-2 text-right">ID</th>
              <th className="border py-1 px-2 text-right">Name</th>
              <th className="border py-1 px-2 text-center">Targeting</th>
              <th className="border py-1 px-2 text-right">Prices</th>
              <th className="border py-1 px-2 text-right">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white text-gray-900">
            {items.map((item) => {
              return (
                <tr key={item.id} className="border-b hover:bg-gray-100">
                  <td className="border py-1 px-2 text-center">
                    <div className="flex items-center justify-center text-center text-lg">
                      {item.enabled ? (
                        <span className="text-green-600">
                          <FiCheckCircle></FiCheckCircle>
                        </span>
                      ) : (
                        <span className="text-red-600">
                          <FiXCircle></FiXCircle>
                        </span>
                      )}
                    </div>
                  </td>
                  <td className="border py-1 px-2 text-right">
                    <span className="font-mono">{item.id}</span>
                  </td>

                  <td className="border py-1 px-2 text-right font-medium">
                    {item.name}
                    {/* <div
                      className="break-words"
                      style={{ maxWidth: "200px", overflow: "scroll" }}
                    >
                      {item.name}
                    </div> */}
                  </td>

                  <td className="border py-1 px-2 text-center">
                    <UprTargetingPopover
                      networkId={networkId}
                      targetingSection={item.targetingSection}
                      units={units}
                      virtualPlacements={virtualPlacements}
                      triggerElement={
                        // <div className="cursor-pointer hover:underline">
                        //   {JSON.stringify(Object.keys(item.targetingSection))}
                        // </div>
                        <div>
                          <button
                            data-tip
                            data-for={item.id + item.name}
                            type="button"
                            className="rounded bg-blue-100 py-2 px-4 font-semibold text-blue-600 hover:bg-blue-200"
                          >
                            {Object.keys(item.targetingSection).length}
                          </button>
                          <ReactTooltip
                            id={item.id + item.name}
                            type="dark"
                            effect="solid"
                          >
                            {Object.keys(item.targetingSection).join(", ")}
                          </ReactTooltip>
                        </div>
                      }
                    ></UprTargetingPopover>
                  </td>

                  <td className="whitespace-no-wrap border py-1 px-2 text-right">
                    <div className="-mx-2 px-2">
                      <div className="text-xs font-medium leading-none text-gray-600">
                        {PRICE_TYPE[item.priceSection.priceType]}
                      </div>
                      {item.priceSection.priceType !== -1 &&
                        item.priceSection.priceType !== 2 && (
                          <div className="font-mono">
                            {formatMoneyWithCurrency({
                              currency: networkInfo.currency,
                              value: item.priceSection.price,
                            })}
                          </div>
                        )}
                    </div>
                    {item.priceSection.brandedPriceSettings && (
                      <div className="border-t">
                        <UprPriceSectionPopover
                          networkId={networkInfo.networkId}
                          currency={networkInfo.currency}
                          targetingSection={item.priceSection}
                          triggerElement={
                            <div className="cursor-pointer hover:underline">
                              <BrandedPriceView
                                item={item.priceSection}
                                currency={networkInfo.currency}
                              ></BrandedPriceView>
                            </div>
                          }
                        ></UprPriceSectionPopover>
                      </div>
                    )}
                  </td>

                  <td className="whitespace-no-wrap border py-1 px-2 text-right">
                    <div className="flex items-center justify-end gap-4">
                      <button
                        type="button"
                        className={`rounded text-blue-600 hover:underline ${
                          item.enabled
                            ? "hover:text-red-600"
                            : "hover:text-green-600"
                        }`}
                        onClick={() => handleToggleEnabled(item)}
                      >
                        {item.enabled ? "Disable" : "Enable"}
                      </button>
                      <button
                        type="button"
                        className="text-blue-600 hover:underline"
                        onClick={() => handleOpenEdit(item)}
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        className="text-blue-600 hover:underline"
                        onClick={() => handleOpenDuplicate(item)}
                      >
                        Duplicate
                      </button>
                      <button
                        type="button"
                        className="text-blue-600 hover:text-red-600 hover:underline"
                        onClick={() => handleRemoveRule(item)}
                      >
                        Remove
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

// price type: 0:floor, 1:targetCPM, 2: LGO
const PRICE_TYPE = {
  0: "Floor",
  1: "Target CPM",
  2: "LGO", // let google optimize
  "-1": "Use Brand Setting",
};
function BrandedPriceView({ item, currency }) {
  const { brandedPriceSettings } = item;

  return (
    <div>
      <span className="rounded bg-green-200 px-2 text-xs text-green-900">
        Branded ({brandedPriceSettings.length})
      </span>
    </div>
  );
}

function PageTitle({ title }) {
  return <div className="text-3xl font-extrabold">{title}</div>;
}

export default NetworkUPRSettingViewer;
