import React from "react";
import _ from "lodash";
import Select from "react-select";

import { CommonAPI } from "apis";

class GeoCountrySelectorWithPresets extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      options: [],
      selectedValue: null,

      presets: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handlePresetSelected = this.handlePresetSelected.bind(this);
  }

  async componentDidMount() {
    const items = await CommonAPI.getGeoCountries();
    const presets = await CommonAPI.getCountryPresets();

    let options = _.map(items, (r) => {
      const n = _.startCase(r.name.toLowerCase());
      return {
        value: r.countryId,
        label: `${n} (${r.alpha2}) (${r.alpha3})`,
        countryCode: r.numeric,
        alpha2: r.alpha2,
        alpha3: r.alpha3,
      };
    });

    this.setState({
      items,
      options,

      presets,
    });
  }

  handleChange(option) {
    this.setState({
      selectedValue: option,
    });

    const selectedValues = _.map(option, "value");
    const results = _.filter(this.state.items, (item) => {
      return _.indexOf(selectedValues, item.countryId) !== -1;
    });
    this.props.handleOnChange(results);
  }

  handlePresetSelected(preset) {
    const presetOptions = _.filter(this.state.options, (option) => {
      return _.indexOf(preset.countryCodes, option.countryCode) !== -1;
    });

    this.handleChange(presetOptions);
  }

  render() {
    const { items, options, selectedValue, presets } = this.state;

    return (
      <>
        <div className="flex justify-start items-center mb-2 text-sm">
          {presets &&
            items &&
            presets.map((preset) => {
              return (
                <button
                  key={preset.name}
                  type="button"
                  className={`px-4 py-1 mr-1 font-semibold rounded bg-gray-100 text-gray-700 hover:text-gray-800 hover:bg-gray-200`}
                  onClick={() => this.handlePresetSelected(preset)}
                >
                  {preset.name}
                </button>
              );
            })}
        </div>

        <Select
          value={selectedValue}
          onChange={this.handleChange}
          options={options}
          isMulti={true}
        />
      </>
    );
  }
}

export default GeoCountrySelectorWithPresets;
