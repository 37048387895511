export const GAM_COUNTRY = {
  2004: "Afghanistan",
  2008: "Albania",
  2010: "Antarctica",
  2012: "Algeria",
  2016: "American Samoa",
  2020: "Andorra",
  2024: "Angola",
  2028: "Antigua and Barbuda",
  2031: "Azerbaijan",
  2032: "Argentina",
  2036: "Australia",
  2040: "Austria",
  2044: "The Bahamas",
  2048: "Bahrain",
  2050: "Bangladesh",
  2051: "Armenia",
  2052: "Barbados",
  2056: "Belgium",
  2060: "Bermuda",
  2064: "Bhutan",
  2068: "Bolivia",
  2070: "Bosnia and Herzegovina",
  2072: "Botswana",
  2074: "Bouvet Island",
  2076: "Brazil",
  2084: "Belize",
  2086: "British Indian Ocean Territory",
  2090: "Solomon Islands",
  2092: "British Virgin Islands",
  2096: "Brunei",
  2100: "Bulgaria",
  2104: "Myanmar (Burma)",
  2108: "Burundi",
  2112: "Belarus",
  2116: "Cambodia",
  2120: "Cameroon",
  2124: "Canada",
  2132: "Cape Verde",
  2136: "Cayman Islands",
  2140: "Central African Republic",
  2144: "Sri Lanka",
  2148: "Chad",
  2152: "Chile",
  2156: "China",
  2158: "Taiwan",
  2162: "Christmas Island",
  2166: "Cocos (Keeling) Islands",
  2170: "Colombia",
  2174: "Comoros",
  2175: "Mayotte",
  2178: "Republic of the Congo",
  2180: "Democratic Republic of the Congo",
  2184: "Cook Islands",
  2188: "Costa Rica",
  2191: "Croatia",
  2196: "Cyprus",
  2203: "Czechia",
  2204: "Benin",
  2208: "Denmark",
  2212: "Dominica",
  2214: "Dominican Republic",
  2218: "Ecuador",
  2222: "El Salvador",
  2226: "Equatorial Guinea",
  2231: "Ethiopia",
  2232: "Eritrea",
  2233: "Estonia",
  2234: "Faroe Islands",
  2238: "Falkland Islands (Islas Malvinas)",
  2239: "South Georgia and the South Sandwich Islands",
  2242: "Fiji",
  2246: "Finland",
  2250: "France",
  2254: "French Guiana",
  2258: "French Polynesia",
  2260: "French Southern and Antarctic Lands",
  2262: "Djibouti",
  2266: "Gabon",
  2268: "Georgia",
  2270: "The Gambia",
  2275: "Palestine",
  2276: "Germany",
  2288: "Ghana",
  2292: "Gibraltar",
  2296: "Kiribati",
  2300: "Greece",
  2304: "Greenland",
  2308: "Grenada",
  2312: "Guadeloupe",
  2316: "Guam",
  2320: "Guatemala",
  2324: "Guinea",
  2328: "Guyana",
  2332: "Haiti",
  2334: "Heard Island and McDonald Islands",
  2336: "Vatican City",
  2340: "Honduras",
  2344: "Hong Kong",
  2348: "Hungary",
  2352: "Iceland",
  2356: "India",
  2360: "Indonesia",
  2368: "Iraq",
  2372: "Ireland",
  2376: "Israel",
  2380: "Italy",
  2384: "Cote d'Ivoire",
  2388: "Jamaica",
  2392: "Japan",
  2398: "Kazakhstan",
  2400: "Jordan",
  2404: "Kenya",
  2410: "South Korea",
  2414: "Kuwait",
  2417: "Kyrgyzstan",
  2418: "Laos",
  2422: "Lebanon",
  2426: "Lesotho",
  2428: "Latvia",
  2430: "Liberia",
  2434: "Libya",
  2438: "Liechtenstein",
  2440: "Lithuania",
  2442: "Luxembourg",
  2446: "Macau",
  2450: "Madagascar",
  2454: "Malawi",
  2458: "Malaysia",
  2462: "Maldives",
  2466: "Mali",
  2470: "Malta",
  2474: "Martinique",
  2478: "Mauritania",
  2480: "Mauritius",
  2484: "Mexico",
  2492: "Monaco",
  2496: "Mongolia",
  2498: "Moldova",
  2499: "Montenegro",
  2500: "Montserrat",
  2504: "Morocco",
  2508: "Mozambique",
  2512: "Oman",
  2516: "Namibia",
  2520: "Nauru",
  2524: "Nepal",
  2528: "Netherlands",
  2530: "Netherlands Antilles",
  2535: "Caribbean Netherlands",
  2531: "Curacao",
  2533: "Aruba",
  2534: "Sint Maarten",
  2540: "New Caledonia",
  2548: "Vanuatu",
  2554: "New Zealand",
  2558: "Nicaragua",
  2562: "Niger",
  2566: "Nigeria",
  2570: "Niue",
  2574: "Norfolk Island",
  2578: "Norway",
  2580: "Northern Mariana Islands",
  2581: "United States Minor Outlying Islands",
  2583: "Federated States of Micronesia",
  2584: "Marshall Islands",
  2585: "Palau",
  2586: "Pakistan",
  2591: "Panama",
  2598: "Papua New Guinea",
  2600: "Paraguay",
  2604: "Peru",
  2608: "Philippines",
  2612: "Pitcairn Islands",
  2616: "Poland",
  2620: "Portugal",
  2624: "Guinea-Bissau",
  2626: "Timor-Leste",
  2630: "Puerto Rico",
  2634: "Qatar",
  2638: "Reunion",
  2642: "Romania",
  2643: "Russia",
  2646: "Rwanda",
  2652: "Saint Barthelemy",
  2654: "Saint Helena, Ascension and Tristan da Cunha",
  2659: "Saint Kitts and Nevis",
  2660: "Anguilla",
  2662: "Saint Lucia",
  2663: "Saint Martin",
  2666: "Saint Pierre and Miquelon",
  2670: "Saint Vincent and the Grenadines",
  2674: "San Marino",
  2678: "Sao Tome and Principe",
  2682: "Saudi Arabia",
  2686: "Senegal",
  2688: "Serbia",
  2690: "Seychelles",
  2694: "Sierra Leone",
  2702: "Singapore",
  2703: "Slovakia",
  2704: "Vietnam",
  2705: "Slovenia",
  2706: "Somalia",
  2710: "South Africa",
  2716: "Zimbabwe",
  2724: "Spain",
  2732: "Western Sahara",
  2736: "Sudan",
  2740: "Suriname",
  2744: "Svalbard and Jan Mayen",
  2748: "Eswatini",
  2752: "Sweden",
  2756: "Switzerland",
  2762: "Tajikistan",
  2764: "Thailand",
  2768: "Togo",
  2772: "Tokelau",
  2776: "Tonga",
  2780: "Trinidad and Tobago",
  2784: "United Arab Emirates",
  2788: "Tunisia",
  2792: "Turkey",
  2795: "Turkmenistan",
  2796: "Turks and Caicos Islands",
  2798: "Tuvalu",
  2800: "Uganda",
  2804: "Ukraine",
  2807: "North Macedonia",
  2818: "Egypt",
  2826: "United Kingdom",
  2831: "Guernsey",
  2832: "Jersey",
  2834: "Tanzania",
  2840: "United States",
  2850: "U.S. Virgin Islands",
  2854: "Burkina Faso",
  2858: "Uruguay",
  2860: "Uzbekistan",
  2862: "Venezuela",
  2876: "Wallis and Futuna",
  2882: "Samoa",
  2887: "Yemen",
  2894: "Zambia",
  2900: "Kosovo",
};
