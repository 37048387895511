import React from "react";

class HeaderTabs extends React.PureComponent {
  render() {
    // tabs: key, title
    const { tabs, selectedTabKey, handleTabChange } = this.props;

    return (
      <>
        <div className="flex items-center text-sm">
          {tabs.map(tab => {
            return (
              <button
                key={tab.key}
                type="button"
                className={`px-4 py-2 -mb-1 ${
                  selectedTabKey === tab.key
                    ? "text-black border-b-2 border-black cursor-default"
                    : "text-gray-600 hover:text-gray-900"
                } ${tab.disabled ? "cursor-not-allowed" : ""}`}
                onClick={() =>
                  tab.disabled ? false : handleTabChange(tab.key)
                }
                disabled={selectedTabKey === tab.key}
              >
                {tab.title}
              </button>
            );
          })}
        </div>
      </>
    );
  }
}

export default HeaderTabs;
