import React from "react";
import _ from "lodash";
import HighchartChart from "../../common/HighchartChart";

const metricsMap = {
  request: {
    key: "request",
    title: "Requests By Client Request Type",
    yAxisTitle: "Request"
    // yAxisMin: 0,
    // yAxisMax: 100,
  }
  // ecpm: {
  //   key: "ecpm",
  //   title: "eCPM",
  //   yAxisTitle: "$ eCPM"
  // },
  // rpm: {
  //   key: "rpm",
  //   title: "rRPM",
  //   yAxisTitle: "$ rRPM"
  // },
  // str: {
  //   key: "str",
  //   title: "STR",
  //   yAxisTitle: "Percentage %",
  //   yAxisMin: 0,
  //   yAxisMax: 100,
  //   calculate: value => {
  //     return value * 100;
  //   }
  // }
};
// const metrics = _.values(metricsMap);

class TrendRequestByRequestType extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: createChartOptions(props.reports)
    };
  }

  render() {
    const { options } = this.state;

    return <HighchartChart options={options}></HighchartChart>;
  }
}

function createSeriesData(reports, metric) {
  return _(reports)
    .groupBy("requestType")
    .reduce((result, reports, groupType) => {
      const series = _.map(reports, r => {
        return [new Date(r.date).getTime(), r[metric]];
      });

      result.push({
        name: groupType,
        data: series
        // color: getGroupTypeStyle(groupType)
      });

      return result;
    }, []);
}

function createChartOptions(reports) {
  const metric = "request";
  const metricOptions = metricsMap[metric];
  const metricTitle = metricOptions.title;
  const metricYAxisTitle = metricOptions.yAxisTitle;
  const metricYAxisMin = metricOptions.yAxisMin;
  const metricYAxisMax = metricOptions.yAxisMax;

  const seriesData = createSeriesData(reports, metric);
  const options = {
    title: {
      text: `${metricTitle}`
      // text: `${metricTitle} by group type of last 7 days`
    },
    chart: {
      type: "line",
      zoomType: "x",
      height: 260,
      backgroundColor: "rgba(0,0,0,0)"
    },
    tooltip: {
      // xDateFormat: "%Y/%m/%d %H:%M",
      // headerFormat:
      //   '<span style="font-size:10px">StartDate: {point.key}</span><table>',
      // pointFormat:
      //   '<tr><td style="color:{series.color};padding:0;font-weight: bold;">{series.name}: </td>' +
      //   '<td style="padding:0; text-align: right;"><b>{point.y:,.2f}</b>%</td></tr>',
      // footerFormat: "</table>",
      // valueDecimals: 2,
      shared: true,
      // useHTML: true,
      crosshairs: true
    },
    xAxis: {
      type: "datetime",
      labels: { format: "{value:%m/%d %a}", rotation: -65 },
      tickInterval: 24 * 60 * 60 * 1000, // 1 day
      crosshair: true
    },
    yAxis: {
      title: {
        text: metricYAxisTitle
      },
      min: metricYAxisMin,
      max: metricYAxisMax
    },
    series: seriesData
  };

  return options;
}

export default TrendRequestByRequestType;
