import React from "react";

const VSECheckBox = (props) => {
  const { isChecked, handleClick } = props;
  return (
    <input
      style={{
        width: "18px",
        height: "18px",
        marginTop: "4px",
      }}
      type="checkbox"
      checked={isChecked}
      onChange={handleClick}
    ></input>
  );
};

export default VSECheckBox;
