import React from "react";
import _ from "lodash";
import { FiArrowDown } from "react-icons/fi";
import GoToInsightButton from "../../common/GoToInsightButton";
import NumberFormat from "../../common/NumberFormat";
import AdUnitBasicCard from "../../snapshot/AdUnitBasicCard";
import PopupWrapper from "../../common/PopupWrapper";
import ClickToCopyWrapper from "../../common/ClickToCopyWrapper";
import UnitMode from "../../common/UnitMode";
import {
  CHECK_STATUS,
  EVAL_KEYS,
  KEY_FORMAT,
} from "../../../constants/HealthCheck";

class UnitsCheckSummaryTable extends React.PureComponent {
  constructor(props) {
    super(props);

    const { reports, overviewEvalKey, checkNameFilter, checkStatusFilter } =
      props;
    const sortKey = overviewEvalKey;
    const statusFilterKey = checkStatusFilter;
    const checkFilterKey = checkNameFilter;

    const { filteredReports, checks, unitReports } = this._transformReports(
      reports,
      statusFilterKey,
      checkFilterKey,
      sortKey
    );

    this.state = {
      sortKey,
      statusFilterKey,
      checkFilterKey,
      countOnlyKeys: null, // array of checks or null (ALL)

      unitReports,
      filteredReports,

      checks,
      selectedUnitIds: [],
    };

    this.handleSortKeyChanged = this.handleSortKeyChanged.bind(this);
    this.handleStatusFilterChanged = this.handleStatusFilterChanged.bind(this);
    this.handleCheckFilterChanged = this.handleCheckFilterChanged.bind(this);
    this.filterReports = this.filterReports.bind(this);
    this.handleCountOnlyFilterChanged =
      this.handleCountOnlyFilterChanged.bind(this);
    this.handleSelectUnit = this.handleSelectUnit.bind(this);
  }

  componentDidUpdate(prevProps) {
    // console.log("did update", prevProps, this.props);
    if (
      prevProps.checkNameFilter !== this.props.checkNameFilter ||
      prevProps.checkStatusFilter !== this.props.checkStatusFilter
    ) {
      const { unitReports } = this.state;
      const { checkNameFilter, checkStatusFilter } = this.props;
      console.log("check filter changed", checkNameFilter, checkStatusFilter);
      let filteredReports = this.filterReports(
        unitReports,
        checkStatusFilter,
        checkNameFilter
      );
      filteredReports = this.sortReports(filteredReports, this.state.sortKey);

      this.setState({
        filteredReports,
        statusFilterKey: checkStatusFilter,
        checkFilterKey: checkNameFilter,
        // sortKey: checkStatusFilter,
      });
    }

    if (
      prevProps.ysFilter === this.props.ysFilter &&
      prevProps.inspFilter === this.props.inspFilter &&
      prevProps.reqTypeFilter === this.props.reqTypeFilter &&
      prevProps.onboardTimeFilter === this.props.onboardTimeFilter &&
      prevProps.susModeFilter === this.props.susModeFilter &&
      prevProps.debugModeFilter === this.props.debugModeFilter
    ) {
      return;
    } else {
      console.log("filter changed", this.props.reports);
      const { filteredReports, checks, unitReports } = this._transformReports(
        this.props.reports,
        this.state.statusFilterKey,
        this.state.checkFilterKey,
        this.state.sortKey
      );

      this.setState({ filteredReports, checks, unitReports });
    }
  }

  _transformReports(reports, statusFilterKey, checkFilterKey, sortKey) {
    let checks = _.map(reports[0].check_results, "name").sort();
    let unitReports = _.map(reports, (report) => {
      report.summary = _.countBy(report.check_results, "result");
      report.csMap = _.groupBy(report.check_results, "result");
      return report;
    });

    let filteredReports = this.filterReports(
      reports,
      statusFilterKey,
      checkFilterKey
    );
    filteredReports = this.sortReports(filteredReports, sortKey);
    return {
      filteredReports,
      checks,
      unitReports,
    };
  }

  handleSortKeyChanged(sortKey) {
    const filteredReports = this.sortReports(
      this.state.filteredReports,
      sortKey
    );
    this.setState({ sortKey, filteredReports });
  }

  handleStatusFilterChanged(cs) {
    if (cs === this.state.statusFilterKey) return;

    this.props.filterUnitsReports(this.state.checkFilterKey, cs);
  }

  handleCheckFilterChanged(c) {
    if (c === this.state.checkFilterKey) return;

    this.props.filterUnitsReports(c, this.state.statusFilterKey);
  }

  sortReports(reports, sortKey) {
    const filteredReports = _.orderBy(reports, _orderFn, "desc");
    function _orderFn(r) {
      if (_.includes(CHECK_STATUS, sortKey)) {
        return r.summary[sortKey] || -99999;
      }

      return r.stat[sortKey] || -99999;
    }
    return filteredReports;
  }

  filterReports(reports, cs, checkName) {
    if (checkName === "ALL") {
      checkName = null;
    }
    if (cs === "ALL") {
      cs = null;
    }
    let filteredReports = _.filter(reports, (r) => {
      return _.find(r.check_results, (cr) => {
        if (cs && checkName) {
          return cr.name === checkName && cr.result === cs;
        }
        if (cs) {
          return cr.result === cs;
        }
        if (checkName) {
          return cr.name === checkName;
        }
        return true;
      });
    });
    return filteredReports;
  }

  _recountCheckCounts(reports, countOnlyKeys) {
    let unitReports = _.map(reports, (report) => {
      let checkResults = [];
      if (!countOnlyKeys) {
        checkResults = report.check_results;
      } else {
        checkResults = _.filter(report.check_results, (r) => {
          return _.includes(countOnlyKeys, r.name);
        });
      }
      report.summary = _.countBy(checkResults, "result");
      return report;
    });

    return unitReports;
  }

  handleCountOnlyFilterChanged(check) {
    if (check === "ALL") {
      let unitReports = this._recountCheckCounts(this.state.unitReports, null);
      return this.setState({ countOnlyKeys: null, unitReports });
    }

    const { countOnlyKeys } = this.state;
    let keys = [];
    if (!countOnlyKeys) {
      keys.push(check);
    } else {
      const isPreviouslySelected = _.includes(countOnlyKeys, check);
      if (isPreviouslySelected) {
        // remove from list
        keys = _.filter(countOnlyKeys, (k) => {
          return k !== check;
        });
      } else {
        // add to list
        keys = [...countOnlyKeys, check];
      }

      if (keys.length === 0) {
        keys = null;
      }
    }

    let unitReports = this._recountCheckCounts(this.state.unitReports, keys);
    this.setState({ countOnlyKeys: keys, unitReports });
  }

  handleSelectUnit(unitId) {
    if (unitId === "ALL") {
      let newUnits = _.map(this.state.filteredReports, (u) => {
        return u.unit_id;
      });
      this.setState({ selectedUnitIds: newUnits });
      return;
    }

    const { selectedUnitIds } = this.state;
    let newUnits = [];
    if (_.includes(selectedUnitIds, unitId)) {
      newUnits = _.filter(selectedUnitIds, (uid) => {
        return uid !== unitId;
      });
    } else {
      newUnits = [...selectedUnitIds, unitId];
    }
    this.setState({ selectedUnitIds: newUnits });
  }

  render() {
    const {
      sortKey,
      statusFilterKey,
      checkFilterKey,
      countOnlyKeys,
      filteredReports,
      checks,
      selectedUnitIds,
    } = this.state;
    const { overviewEvalKey, snapshotsByUnitId } = this.props;

    return (
      <div>
        <div className="border py-2 px-2 mb-2">
          <div className="font-semibold text-sm mb-2">
            Filter check status:{" "}
            <button
              type="button"
              className={`px-2 py-1 mr-1 font-semibold rounded ${
                statusFilterKey === "ALL"
                  ? "bg-indigo-100 text-indigo-800"
                  : "text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
              }`}
              onClick={() => this.handleStatusFilterChanged("ALL")}
            >
              ALL
            </button>
            {CHECK_STATUS.map((cs) => {
              return (
                <button
                  key={cs}
                  type="button"
                  className={`px-2 py-1 mr-1 font-semibold rounded ${
                    statusFilterKey === cs
                      ? "bg-indigo-100 text-indigo-800"
                      : "text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
                  }`}
                  onClick={() => this.handleStatusFilterChanged(cs)}
                >
                  {cs}
                </button>
              );
            })}
          </div>
          <div className="font-semibold text-sm border-t mt-1 pt-1">
            Filter check:{" "}
            <button
              type="button"
              className={`px-2 py-1 mr-1 font-semibold rounded ${
                checkFilterKey === "ALL"
                  ? "bg-indigo-100 text-indigo-800"
                  : "text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
              }`}
              onClick={() => this.handleCheckFilterChanged("ALL")}
            >
              ALL
            </button>
            <div>
              {checks.map((c) => {
                return (
                  <button
                    key={c}
                    type="button"
                    className={`px-2 py-1 mr-1 font-semibold rounded ${
                      checkFilterKey === c
                        ? "bg-indigo-100 text-indigo-800"
                        : "text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
                    }`}
                    onClick={() => this.handleCheckFilterChanged(c)}
                  >
                    {c}
                  </button>
                );
              })}
            </div>
          </div>

          <div className="font-semibold text-sm border-t mt-1 pt-1">
            Count ONLY:{" "}
            <button
              type="button"
              className={`px-2 py-1 mr-1 font-semibold rounded ${
                countOnlyKeys === null
                  ? "bg-pink-100 text-pink-800"
                  : "text-gray-700 hover:text-pink-800 hover:bg-gray-200"
              }`}
              onClick={() => this.handleCountOnlyFilterChanged("ALL")}
            >
              ALL
            </button>
            <div>
              {checks.map((c) => {
                return (
                  <button
                    key={c}
                    type="button"
                    className={`px-2 py-1 mr-1 font-semibold rounded ${
                      _.includes(countOnlyKeys, c)
                        ? "bg-pink-100 text-pink-800"
                        : "text-gray-700 hover:text-pink-800 hover:bg-gray-200"
                    }`}
                    onClick={() => this.handleCountOnlyFilterChanged(c)}
                  >
                    {c}
                  </button>
                );
              })}
            </div>
          </div>
        </div>

        {/* <div>
          statusFilterKey: {statusFilterKey}
          <br />
          checkFilterKey: {checkFilterKey}
          <br />
          countOnlyKeys: {countOnlyKeys}
          <br />
        </div> */}

        <div className="text-gray-800 italic">
          Showing <b>{filteredReports.length}</b> out of{" "}
          {this.props.reports.length} units
        </div>

        <table
          className="w-full table text-sm border shadow"
          style={{ marginBottom: "500px" }}
        >
          <thead className="bg-gray-200 text-blue-800 text-xs border-b">
            <tr>
              <th className="pl-2">
                <input
                  style={{
                    width: "18px",
                    height: "18px",
                    marginTop: "4px",
                  }}
                  type="checkbox"
                  checked={selectedUnitIds.length === filteredReports.length}
                  onChange={() => this.handleSelectUnit("ALL")}
                ></input>
              </th>
              <th></th>
              <th></th>
              <th className="border py-1 px-2">Unit</th>

              <th className="border text-center py-1 px-2">
                <div
                  className={`${
                    sortKey === overviewEvalKey
                      ? "text-gray-800"
                      : "text-gray-200"
                  }
                      cursor-pointer flex gap-1 justify-center items-center hover:text-gray-600
                    `}
                  onClick={() => this.handleSortKeyChanged(overviewEvalKey)}
                >
                  <FiArrowDown></FiArrowDown>
                  <span className="text-blue-800 pr-2">{overviewEvalKey}</span>
                </div>
                {/* <div className="text-xs">{EVAL_KEYS[overviewEvalKey].sec}</div> */}
              </th>

              {CHECK_STATUS.map((cs) => {
                return (
                  <th key={cs} className="border text-center py-1 px-2">
                    <div
                      className={`${
                        sortKey === cs ? "text-gray-800" : "text-gray-200"
                      }
                      cursor-pointer flex gap-1 justify-center items-center hover:text-gray-600
                    `}
                      onClick={() => this.handleSortKeyChanged(cs)}
                    >
                      <FiArrowDown></FiArrowDown>
                      <span className="text-blue-800 pr-2">{cs}</span>
                    </div>
                  </th>
                );
              })}

              <th className="border text-center py-1 px-2">Failed Checks</th>
              <th className="border text-center py-1 px-2">NMD Checks</th>
            </tr>
          </thead>
          <tbody className="text-gray-900 font-mono bg-white">
            {filteredReports &&
              filteredReports.map((r, i) => {
                return (
                  <tr key={r.unit_id} className="border-b hover:bg-gray-100">
                    <td className="pl-2">
                      <input
                        style={{
                          width: "18px",
                          height: "18px",
                          marginTop: "4px",
                        }}
                        type="checkbox"
                        checked={selectedUnitIds.indexOf(r.unit_id) !== -1}
                        onChange={() => this.handleSelectUnit(r.unit_id)}
                      ></input>
                    </td>
                    <td className="text-right text-gray-600 pr-2">{i + 1}</td>
                    <td className="px-1 border">
                      <GoToInsightButton
                        unitId={r.unit_id}
                        qp="?tab=HEALTH_CHECK"
                        showIconOnly={true}
                      ></GoToInsightButton>
                    </td>
                    <td className="font-semibold py-1 px-2 whitespace-no-wrap border font-sans">
                      <div className="flex items-center gap-2">
                        <PopupWrapper
                          place="bottom left"
                          hideArrow={true}
                          triggerElement={
                            <button
                              type="button"
                              className="border-b border-gray-600"
                            >
                              {r.unit_id}{" "}
                              <span className="text-gray-700 mr-1">
                                {r.unit_name}
                              </span>
                              {r.isInsp && (
                                <span className="text-xs text-orange-100 bg-orange-400 rounded font-bold px-2">
                                  INSP
                                </span>
                              )}
                              {r.isDebug && (
                                <span className="text-xs text-pink-100 bg-pink-500 rounded font-bold px-2">
                                  DEBUG
                                </span>
                              )}
                              {r.hasGpt && (
                                <span className="text-xs px-2 text-gray-600 bg-indigo-100 rounded">
                                  GPT
                                </span>
                              )}
                              {r.hasAmp && (
                                <span className="text-xs px-2 text-gray-600 bg-indigo-100 rounded">
                                  AMP
                                </span>
                              )}
                            </button>
                          }
                          popupElement={
                            <div
                              className="shadow-lg border-4 border-gray-600"
                              style={{ marginTop: "-9px" }}
                            >
                              <AdUnitBasicCard
                                unit={snapshotsByUnitId[r.unit_id]}
                                showFullWidthCard={false}
                                isCompactMode={false}
                              ></AdUnitBasicCard>
                            </div>
                          }
                        ></PopupWrapper>
                        <UnitMode unit={r}></UnitMode>
                      </div>

                      <div className="text-xs text-gray-600 font-normal">
                        {r.ysId} {r.ysName}
                      </div>
                    </td>
                    <td className="py-1 px-3 whitespace-no-wrap border text-right">
                      <div className="flex flex-row-reverse gap-2 justify-start items-center">
                        <NumberFormat
                          value={r.stat[overviewEvalKey]}
                          format={KEY_FORMAT[overviewEvalKey]}
                        ></NumberFormat>
                        <div className="text-xs text-gray-600 font-normal">
                          <NumberFormat
                            value={r.stat[EVAL_KEYS[overviewEvalKey].sec]}
                            format={KEY_FORMAT[EVAL_KEYS[overviewEvalKey].sec]}
                          ></NumberFormat>
                        </div>
                      </div>
                    </td>

                    {CHECK_STATUS.map((cs) => {
                      return (
                        <td key={cs} className="text-center py-1 px-2 border">
                          <span
                            className="px-2"
                            data-tip
                            data-for={`${cs}-${r.unit_id}-cc`}
                          >
                            {r.summary[cs]}
                          </span>
                          {/* Very bad performance with tooltip added... */}
                          {/* {r.csMap[cs] && (
                          <ReactTooltip
                            id={`${cs}-${r.unit_id}-cc`}
                            type="dark"
                            effect="solid"
                            place={"top"}
                          >
                            {r.csMap[cs].map((cc) => {
                              return <div key={cc.name}>{cc.name}</div>;
                            })}
                          </ReactTooltip>
                        )} */}
                        </td>
                      );
                    })}

                    <td className="text-left py-1 px-2 border text-xs text-gray-700">
                      {r.csMap["FAIL"] &&
                        r.csMap["FAIL"].map((cc, index) => {
                          return (
                            <div key={cc.name} className="whitespace-no-wrap">
                              {index + 1}: {cc.name}
                            </div>
                          );
                        })}
                    </td>

                    <td className="text-left py-1 px-2 border text-xs text-gray-700">
                      {r.csMap["NEED_MORE_DATA"] &&
                        r.csMap["NEED_MORE_DATA"].map((cc, index) => {
                          return (
                            <div key={cc.name} className="whitespace-no-wrap">
                              {index + 1}: {cc.name}
                            </div>
                          );
                        })}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        <div className="fixed bottom-0 left-0 w-full bg-white border-t-2 px-4 py-2">
          <div className="flex gap-2 items-center text-gray-700">
            <div className="text-sm w-48 text-right">
              Selected Units ({selectedUnitIds.length}):
            </div>
            <textarea
              rows="1"
              value={selectedUnitIds.join(", ")}
              readOnly
              className="w-full border rounded p-1 text-xs"
            ></textarea>
            <div>
              <button
                className="text-blue-600 rounded hover:bg-blue-100 px-2 py-1 text-sm overflow-x-auto"
                type="button"
                onClick={() => {
                  this.setState({ selectedUnitIds: [] });
                }}
              >
                Clear
              </button>
            </div>
            <div className="w-24">
              <ClickToCopyWrapper
                copyText={selectedUnitIds.join(", ")}
              ></ClickToCopyWrapper>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UnitsCheckSummaryTable;
