import React from "react";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import { GAM_COUNTRY } from "../../constants/GAMCountry";
import { GAM_OS } from "../../constants/GAMOS";
import { GAM_DEVICE_CATEGORY } from "../../constants/GAMDeviceCategory";
import { INVENTORY_REGISTRY } from "constants/InventoryRegistry";

const SegmentTargeting = ({ target, targetKey }) => {
  if (!target) {
    return "-";
  }
  if (targetKey === "custom" || targetKey === "customs") {
    const tstring = JSON.stringify(target);
    return (
      <>
        <span
          data-tip
          data-for={tstring}
          className="whitespace-no-wrap text-xs italic text-gray-800"
        >
          (hover to view)
        </span>

        <ReactTooltip id={tstring} type="dark" effect="solid" place={"top"}>
          {tstring}
        </ReactTooltip>
      </>
    );
  }

  if (targetKey === "upr_segment_id") {
    return (
      <div className="flex items-center justify-center">{target || "-"}</div>
    );
  }

  const hasIncludeString =
    _.has(target, "includes") || _.has(target, "excludes");
  let includeString = "";
  let targetValues = [];

  if (hasIncludeString) {
    includeString = target.includes ? "Includes" : "Excludes";
    targetValues = target.includes ? target.includes : target.excludes;
  } else {
    if (targetKey === "sizes") {
      // sizes -> [[970, 90], ...]
      includeString = "Includes";
      targetValues = _.map(target, (sizeSet) => {
        return _.isArray(sizeSet) ? sizeSet.join("x") : sizeSet;
      });
    } else if (targetKey === "relevant_requested_sizes") {
      // "relevant_requested_sizes": [
      //   [[300, 250]]
      // ],
      includeString = "Includes";
      targetValues = _.map(target, (sets) => {
        return _.map(sets, (sizeSet) => {
          return _.isArray(sizeSet) ? sizeSet.join("x") : sizeSet;
        }).join(", ");
      });
    } else {
      includeString = "Includes";
      targetValues = target;
    }
  }

  // target values: "country", "os", "device_category", "hour", "sizes", "custom", "customs"
  if (targetKey === "country") {
    targetValues = _.map(targetValues, (v) => {
      return GAM_COUNTRY[v] || v;
    });
  } else if (targetKey === "os") {
    targetValues = _.map(targetValues, (v) => {
      return GAM_OS[v] || v;
    });
  } else if (targetKey === "device_category") {
    targetValues = _.map(targetValues, (v) => {
      return GAM_DEVICE_CATEGORY[v] || v;
    });
  } else if (targetKey === "inventory_registry") {
    const ir = _.keyBy(INVENTORY_REGISTRY, "id");
    targetValues = _.map(targetValues, (v) => {
      return _.get(ir, [v, "label"], v);
    });
  }

  return (
    <div>
      <span className={target.includes ? `text-green-600` : `text-red-600`}>
        {includeString}
      </span>
      <div>
        {targetValues.map((value, i) => {
          return (
            <React.Fragment key={value}>
              <span className="whitespace-no-wrap mr-2 bg-gray-300 p-1 text-sm">
                {value}
              </span>
              {i % 2 !== 0 && <div className="mb-2"></div>}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default SegmentTargeting;
