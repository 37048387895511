import React from "react";
import { FiExternalLink } from "react-icons/fi";

class GoToInsightButton extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  handleGoToUnitInsight(unitId) {
    window.open(
      `${window.location.origin}/#/unit-insight/${unitId}${this.props.qp || ""}`
    );
  }

  render() {
    const { unitId, showIconOnly } = this.props;
    return (
      <button
        type="button"
        className="border border-blue-300 rounded px-1 flex hover:shadow text-xs text-blue-700 bg-gray-100"
        onClick={() => this.handleGoToUnitInsight(unitId)}
      >
        {showIconOnly ? (
          <span className="text-base p-1">
            <FiExternalLink></FiExternalLink>
          </span>
        ) : (
          <span className="flex items-center">
            Insight
            <span className="ml-1">
              <FiExternalLink></FiExternalLink>
            </span>
          </span>
        )}
      </button>
    );
  }
}

export default GoToInsightButton;
