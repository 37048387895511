import React from "react";
import { FiExternalLink } from "react-icons/fi";

class AutoPilotConfigEditButton extends React.PureComponent {
  render() {
    const { goToConfigControlPage, buttonText } = this.props;

    return (
      <>
        <button
          type="button"
          // className="bg-gray-200 hover:bg-gray-400 border border-gray-400 hover:border-gray-600 rounded hover:shadow px-2 text-sm flex items-center"
          className="border border-blue-300 rounded px-1 hover:shadow text-xs flex items-center text-blue-500 bg-white"
          onClick={() => goToConfigControlPage()}
        >
          {buttonText || "Edit"}
          <span className="ml-1">
            <FiExternalLink></FiExternalLink>
          </span>
        </button>
      </>
    );
  }
}

export default AutoPilotConfigEditButton;
