import React from "react";
import _ from "lodash";
import TableRequestByGroupType from "./TableRequestByGroupType";
import TableRequestSizesByGroupType from "./TableRequestSizesByGroupType";

class RequestReportTables extends React.Component {
  constructor(props) {
    super(props);

    const dates = _(props.overallReports)
      .map("date")
      .uniq()
      .sort()
      .value();

    const d1 = _.get(dates, dates.length - 2);

    this.state = {
      selectedDate: d1
    };

    this.handleSelectDate = this.handleSelectDate.bind(this);
  }

  handleSelectDate(date) {
    this.setState({ selectedDate: date });

    const element = document.getElementById(`ad-sizes-distribution-table`);
    window.scrollTo({
      behavior: "smooth",
      top: element ? element.offsetTop - 140 : 0
    });
  }

  render() {
    const { overallReports, sizesReports } = this.props;
    const { selectedDate } = this.state;

    return (
      <div>
        {overallReports && sizesReports && (
          <>
            <div className="bg-white rounded-b shadow-md p-4">
              <TableRequestByGroupType
                reports={overallReports}
                selectedDate={selectedDate}
                handleSelectDate={this.handleSelectDate}
              ></TableRequestByGroupType>
            </div>

            <div className="py-4">
              <TableRequestSizesByGroupType
                sizesReports={sizesReports}
                selectedDate={selectedDate}
                handleSelectDate={this.handleSelectDate}
              ></TableRequestSizesByGroupType>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default RequestReportTables;
