import React from "react";
import _ from "lodash";

import { notify } from "react-notify-toast";

import { PublisherAPI } from "apis";
import GeoCountrySelector from "../GeoCountrySelector";

class CreateReseller extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errMsg: "",
      isSaving: false,

      name: "",
      userEmail: "",
      geoCountryId: null,
      pricingCurrency: "",
    };

    this.onInputChanged = this.onInputChanged.bind(this);
    this.handleCreateReseller = this.handleCreateReseller.bind(this);
  }

  onInputChanged(value, key) {
    this.setState({
      [key]: value,
    });
  }

  async handleCreateReseller() {
    //
    try {
      const { name, userEmail, geoCountryId, pricingCurrency } = this.state;
      if (!name || name === "") {
        throw "Missing Reseller Name";
      }
      if (!userEmail || userEmail === "") {
        throw "Missing User Email";
      }
      if (!geoCountryId) {
        throw "Missing Country";
      }
      if (!pricingCurrency || pricingCurrency === "") {
        throw "Missing Pricing Currency";
      }

      let params = {
        name,
        userEmail,
        geoCountryId,
        pricingCurrency,
      };

      this.setState({
        isSaving: true,
      });

      const result = await PublisherAPI.createReseller(params);
      console.log(result);

      notify.show(
        "Reseller created! Redirecting to previous page...",
        "success"
      );

      this.setState({
        isSaving: false,
        errMsg: "",
      });

      setTimeout(() => {
        // window.location.reload(false);
        this.props.history.push("/ops-mgmt/account/publishers");
      }, 3000);
    } catch (err) {
      console.log(err);
      this.setState({
        errMsg: err,
        isSaving: false,
      });
    }
  }

  render() {
    const { errMsg, isSaving, name, userEmail, geoCountryId, pricingCurrency } =
      this.state;

    const inputClass =
      "bg-white flex w-full border-2 border-gray-300 rounded py-1 px-4 appearance-none leading-normal focus:outline-none focus:border-blue-400 focus:shadow-inner hover:shadow hover:border-blue-300";

    return (
      <div>
        <div className="bg-white px-12">
          <h1 className="pt-8 pb-4 text-4xl font-extrabold text-gray-900">
            Create Reseller
          </h1>
        </div>

        <div className="min-h-screen bg-gray-200 px-12 py-8">
          <div className="flex">
            <div className="w-1/2">
              <div className="mb-4">
                <label className="font-semibold text-gray-800">
                  Reseller Name
                </label>
                <div>
                  <input
                    type="text"
                    className={inputClass}
                    value={name}
                    onChange={(e) =>
                      this.onInputChanged(e.target.value, "name")
                    }
                  ></input>
                </div>
              </div>

              <div className="mb-4">
                <label className="font-semibold text-gray-800">
                  User Email
                </label>
                <div>
                  <input
                    type="email"
                    className={inputClass}
                    value={userEmail}
                    placeholder="user@company.com"
                    onChange={(e) =>
                      this.onInputChanged(e.target.value, "userEmail")
                    }
                  ></input>
                </div>
              </div>

              <div className="mb-4">
                <label className="font-semibold text-gray-800">Country</label>
                <div>
                  <GeoCountrySelector
                    handleOnChange={(value) =>
                      this.onInputChanged(value, "geoCountryId")
                    }
                  ></GeoCountrySelector>
                </div>
              </div>

              <div className="mb-4">
                <label className="font-semibold text-gray-800">
                  Pricing Currency{" "}
                  <a
                    className="ml-1 text-sm text-blue-600"
                    href="https://en.wikipedia.org/wiki/ISO_4217"
                    target="_blank"
                  >
                    (ISO Currency Code List)
                  </a>
                </label>
                <div>
                  <input
                    type="text"
                    className={inputClass}
                    value={pricingCurrency}
                    placeholder="ISO Currency Code ex. TWD"
                    onChange={(e) =>
                      this.onInputChanged(e.target.value, "pricingCurrency")
                    }
                  ></input>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4">
            {errMsg !== "" && (
              <div className="rounded mb-4 bg-red-100 p-4 font-semibold text-red-700">
                {errMsg}
              </div>
            )}

            <div className="flex justify-center">
              <button
                type="button"
                className="rounded mr-4 bg-teal-500 px-6 py-2 font-semibold text-white"
                onClick={this.props.history.goBack}
              >
                Back
              </button>

              <button
                type="button"
                className="rounded bg-blue-500 px-6 py-2 font-semibold text-white"
                onClick={this.handleCreateReseller}
              >
                Create Reseller
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateReseller;
