import _ from "lodash";

// let srcUrl = `https://securepubads.g.doubleclick.net/gampad/ads?gdfp_req=1&pvsid=968532920689985&correlator=4131140122665953&output=ldjh&impl=fifs&adsid=ChEI8LLj-QUQytWw8ab64Ke-ARJBACDrt13YI-Q9jr4sYeJErN86JxV6pMHmS_Rao4q7Edh4Z-upj8TIi9D0TI_8rbQU2OEer9fdFksm2z1lhoXXtkM&eid=21066031%2C21066799%2C21066393%2C21066752&vrg=2020071601&tfcd=0&guci=2.2.0.0.2.2.0.0&sc=0&sfv=1-0-37&ecs=20200817&iu_parts=71024502%2CIN2W_OPT%2Cwinter_cpbl_m_all_300250_12&enc_prev_ius=%2F0%2F1%2F2&prev_iu_szs=300x250&prev_scp=in2w_key4%3Ddebug&cookie=ID%3D7a18bcaa1cc8d77d%3AT%3D1570698598%3AS%3DALNI_MbUonOoiMGxSskk8qcrURyoqtEQOg&bc=23&abxe=1&lmt=1597525318&dt=1597641041937&dlt=1597641041648&idt=265&frm=20&biw=411&bih=731&oid=3&adxs=56&adys=61&adks=311191330&ucis=1&ifi=1&u_tz=480&u_his=2&u_h=731&u_w=411&u_ah=731&u_aw=411&u_cd=30&u_sd=2.625&flash=0&url=http%3A%2F%2Fts.cpbl.com.tw%2F&dssz=7&icsg=42&std=0&csl=73&vis=1&scr_x=0&scr_y=0&psz=395x302&msz=395x250&ga_vid=2080128969.1570698584&ga_sid=1597641042&ga_hid=1612447364&ga_fc=true&fws=0&ohw=0`;
// let dstUrl = `https://securepubads.g.doubleclick.net/gampad/ads?gdfp_req=1&pvsid=1666043633865193&correlator=3207067895012884&output=ldjh&impl=fif&eid=21066799%2C21066753&vrg=2020071601&tfcd=0&guci=2.2.0.0.2.2.0.0&sc=0&sfv=1-0-37&ecs=20200817&iu_parts=71024502%2CIN2W_OPT%2Cwinter_cpbl_m_all_300250_12&enc_prev_ius=%2F0%2F1%2F2&prev_iu_szs=300x250&prev_scp=in2w_key4%3D-1m-tk%26in2w_key%3D5%26in2w_key2%3Dnope%2Coptimization%26in2w_key3%3Dadx15%26in2w_key5%3Doptimization%26in2w_key6%3D-1mhtkqgz%26in2w_key7%3D15%26in2w_key8%3D5%252C6%26in2w_key9%3Doptimization_request%26in2w_key12%3Doptimization&eri=6&cookie=ID%3D7a18bcaa1cc8d77d%3AT%3D1570698598%3AS%3DALNI_MbUonOoiMGxSskk8qcrURyoqtEQOg&cdm=ts.cpbl.com.tw&bc=23&abxe=1&lmt=1597641042&dt=1597641042361&dlt=1597641042087&idt=246&ea=0&frm=23&biw=411&bih=731&isw=300&ish=250&oid=3&adxs=56&adys=61&adks=2441545565&ucis=29p648ukykv3&ifi=1&ifk=3792240173&u_tz=480&u_his=2&u_h=731&u_w=411&u_ah=731&u_aw=411&u_cd=30&u_sd=2.625&flash=0&nhd=1&iag=3&url=http%3A%2F%2Fts.cpbl.com.tw%2F&top=ts.cpbl.com.tw&dssz=6&icsg=10&std=0&vis=1&scr_x=0&scr_y=0&psz=300x250&msz=300x250&ga_vid=2080128969.1570698584&ga_sid=1597641042&ga_hid=1355783761&ga_fc=true&fws=256&ohw=0&btvi=0`;

function params(url) {
  const params = url.searchParams;
  const ret = {};
  for (let [k, v] of params.entries()) {
    ret[k] = v;
  }
  return ret;
}
function parseUnits(params) {
  let { iu_parts: iuParts, enc_prev_ius: encPrevIus } = params;
  // They encode ',' as ':' so we need to revert it
  let parts = iuParts.split(",").map((part) => part.replace(/:/g, ","));
  let units = encPrevIus.split(",");
  for (let idx in units) {
    units[idx] = units[idx]
      .split("/")
      .map((comp) => {
        return parts[parseInt(comp, 10)];
      })
      .join("/");
  }
  return units;
}
function getValue(key, value, unitIndex) {
  if (!value) {
    return;
  }
  // For multiple value key, we should extract it value by unitIndex.
  let isMultiKey = false;
  let delimiter = ",";
  switch (key) {
    case "adks":
    case "adxs":
    case "adys":
    case "fluid":
    case "fws":
    case "ohw":
    case "prev_iu_szs":
    case "rcs": {
      isMultiKey = true;
      break;
    }

    case "psz":
    case "msz":
    case "prev_scp":
    case "ucis": {
      delimiter = "|";
      isMultiKey = true;
      break;
    }

    case "ris": {
      delimiter = "~";
      isMultiKey = true;
      break;
    }

    default:
  }
  if (isMultiKey) {
    const r = value.split(delimiter)[unitIndex];
    // console.log(value, key, delimiter);
    // console.log(r, value, value.split(delimiter), unitIndex);
    return r;
  } else {
    return value;
  }
}
function diff(srcUrl, dstUrl) {
  let src = new URL(srcUrl);
  let dst = new URL(dstUrl);
  let srcParams = params(src);
  let dstParams = params(dst);
  let srcUnits = parseUnits(srcParams);
  let dstUnits = parseUnits(dstParams);
  //console.log(srcUnits);
  //console.log(dstUnits);
  const targetUnit = dstUnits[0];
  const unitIdxInSrc = srcUnits.indexOf(targetUnit);
  if (unitIdxInSrc === -1) {
    throw Error("Cannot find any matched unit between these two gpt requests!");
  }
  // Start diff now
  let allParams = _.uniq(_.keys(srcParams).concat(_.keys(dstParams)));
  //console.log(allParams);
  const diff = [];
  for (let key of allParams) {
    // Skip unit path fields
    //
    if (key === "iu_parts" || key === "prev_iu_szs" || key === "enc_prev_ius") {
      continue;
    }
    let srcValue = getValue(key, srcParams[key], unitIdxInSrc);
    let dstValue = dstParams[key];
    if (srcValue === dstValue) {
      continue;
    }
    diff.push({
      key,
      src: srcValue,
      dst: dstValue,
    });
  }
  return {
    unit: targetUnit,
    diff,
  };
}

export default {
  diff,
};
