import React from "react";
import _ from "lodash";
import NumberFormat from "../../common/NumberFormat";
import { FiArrowDown } from "react-icons/fi";

class NetworkRevTable extends React.Component {
  constructor(props) {
    super(props);

    const { networkReports } = this.props;
    const currentSortMetricKey = "mtdRevenue";
    const orderedReports = _.orderBy(
      networkReports,
      (r) => r[currentSortMetricKey],
      ["desc"]
    );

    this.state = {
      orderedReports,
      currentSortMetricKey,
      highlightNetworkId: null,
    };

    this.handleOrderByChanged = this.handleOrderByChanged.bind(this);
    this.handleNetworkClicked = this.handleNetworkClicked.bind(this);
  }

  handleOrderByChanged(metricKey) {
    const orderedReports = _.orderBy(
      this.props.networkReports,
      (r) => r[metricKey],
      ["desc"]
    );

    this.setState({ orderedReports, currentSortMetricKey: metricKey });
  }

  handleNetworkClicked(networkId) {
    // highlightNetworkId
    this.setState({
      highlightNetworkId: networkId,
    });
  }

  render() {
    const { orderedReports, currentSortMetricKey, highlightNetworkId } =
      this.state;
    const { yesterdayTotalReports, mtdTotalReports } = this.props;

    if (!yesterdayTotalReports) return "";
    return (
      <>
        <table className="table-auto w-full">
          <thead className="text-sm text-gray-800 bg-gray-300 align-bottom">
            <tr>
              <th className="px-4 py-2">#</th>
              <th className="px-4 py-2 text-left" style={{ width: "30%" }}>
                Network
              </th>
              <th
                className="px-4 py-2 hover:bg-gray-400 cursor-pointer"
                style={{ width: "15%" }}
                onClick={() => this.handleOrderByChanged("yesNetIncreasedRev")}
              >
                <div className="flex justify-end">
                  <div className="flex items-center px-4">
                    {currentSortMetricKey === "yesNetIncreasedRev" ? (
                      <FiArrowDown></FiArrowDown>
                    ) : (
                      <div className={"text-gray-300"}>
                        <FiArrowDown></FiArrowDown>
                      </div>
                    )}
                  </div>
                  <div className="text-right">
                    {yesterdayTotalReports.date}
                    <br />
                    Net Incr Rev
                  </div>
                </div>
              </th>
              <th
                className="px-4 py-2 hover:bg-gray-400 cursor-pointer"
                style={{ width: "15%" }}
                onClick={() => this.handleOrderByChanged("yesRevenue")}
              >
                <div className="flex justify-end">
                  <div className="flex items-center px-4">
                    {currentSortMetricKey === "yesRevenue" ? (
                      <FiArrowDown></FiArrowDown>
                    ) : (
                      <div className={"text-gray-300"}>
                        <FiArrowDown></FiArrowDown>
                      </div>
                    )}
                  </div>
                  <div className="text-right">
                    {yesterdayTotalReports.date}
                    <br />
                    Revenue
                  </div>
                </div>
              </th>
              <th
                className="px-4 py-2 hover:bg-gray-400 cursor-pointer"
                style={{ width: "15%" }}
                onClick={() => this.handleOrderByChanged("mtdNetIncreasedRev")}
              >
                <div className="flex justify-end">
                  <div className="flex items-center px-4">
                    {currentSortMetricKey === "mtdNetIncreasedRev" ? (
                      <FiArrowDown></FiArrowDown>
                    ) : (
                      <div className={"text-gray-300"}>
                        <FiArrowDown></FiArrowDown>
                      </div>
                    )}
                  </div>
                  <div className="text-right">
                    {mtdTotalReports.month}
                    MTD
                    <br />
                    Net Incr Rev
                  </div>
                </div>
              </th>
              <th
                className="px-4 py-2 hover:bg-gray-400 cursor-pointer"
                style={{ width: "15%" }}
                onClick={() => this.handleOrderByChanged("mtdRevenue")}
              >
                <div className="flex justify-end">
                  <div className="flex items-center px-4">
                    {currentSortMetricKey === "mtdRevenue" ? (
                      <FiArrowDown></FiArrowDown>
                    ) : (
                      <div className={"text-gray-300"}>
                        <FiArrowDown></FiArrowDown>
                      </div>
                    )}
                  </div>
                  <div className="text-right">
                    {mtdTotalReports.month}
                    MTD
                    <br />
                    Revenue
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="text-gray-900">
            {orderedReports.map((d, i) => {
              // const yesNetIncrRevPerc = _.round(
              //   (d.yesNetIncreasedRev / yesterdayTotalReports.netIncreasedRev) *
              //     100
              // );
              // const yesRevenuePerc = _.round(
              //   (d.yesRevenue / yesterdayTotalReports.revenue) * 100
              // );
              // const mtdNetIncrRevPerc = _.round(
              //   (d.mtdNetIncreasedRev / mtdTotalReports.netIncreasedRev) * 100
              // );
              // const mtdRevenuePerc = _.round(
              //   (d.mtdRevenue / mtdTotalReports.revenue) * 100
              // );

              return (
                <tr
                  key={d.networkId}
                  className={`cursor-pointer ${
                    highlightNetworkId === d.networkId
                      ? "bg-teal-100"
                      : "hover:bg-gray-100 "
                  }`}
                  onClick={() => this.handleNetworkClicked(d.networkId)}
                >
                  <td className="border px-4 py-2 text-center">{i + 1}</td>

                  <td className="border px-4 py-2">
                    <span>
                      <div>
                        <span className="pr-2">{d.networkId}</span>
                        <span className="font-semibold">{d.networkName}</span>
                      </div>
                    </span>
                    <div className="text-xs text-gray-600">
                      Publisher {d.publisherId} {d.publisherName}
                    </div>
                  </td>

                  <td
                    className={`border px-4 py-2 text-right font-mono text-gray-800 ${
                      currentSortMetricKey === "yesNetIncreasedRev"
                        ? "font-semibold"
                        : "font-normal"
                    }`}
                  >
                    <NumberFormat
                      value={d.yesNetIncreasedRev}
                      format="$0,0"
                    ></NumberFormat>
                    {/* <div className="text-sm text-gray-600 font-normal">
                      <NumberFormat
                        value={yesNetIncrRevPerc}
                        format="0,0"
                      ></NumberFormat>
                      %
                    </div> */}
                  </td>

                  <td
                    className={`border px-4 py-2 text-right font-mono text-gray-800 ${
                      currentSortMetricKey === "yesRevenue"
                        ? "font-semibold"
                        : "font-normal"
                    }`}
                  >
                    <NumberFormat
                      value={d.yesRevenue}
                      format="$0,0"
                    ></NumberFormat>
                    {d.yesPotentialRev > 0 && (
                      <div className="text-blue-600 text-sm font-normal">
                        <span className="text-gray-500 text-xs">
                          Billable:{" "}
                        </span>
                        <NumberFormat
                          value={d.yesBillableRev}
                          format="$0,0"
                        ></NumberFormat>
                      </div>
                    )}
                    {/* <div>{d.yesPotentialRev}</div> */}
                    {/* ({_.round((d.yesRevenue / d.yesNetIncreasedRev) * 100)}) */}
                    {/* <div className="text-sm text-gray-600 font-normal">
                      <NumberFormat
                        value={yesRevenuePerc}
                        format="0,0"
                      ></NumberFormat>
                      %
                    </div> */}
                  </td>

                  <td
                    className={`border px-4 py-2 text-right font-mono text-gray-800 ${
                      currentSortMetricKey === "mtdNetIncreasedRev"
                        ? "font-semibold"
                        : "font-normal"
                    }`}
                  >
                    <NumberFormat
                      value={d.mtdNetIncreasedRev}
                      format="$0,0"
                    ></NumberFormat>
                    {/* 
                    <div className="text-sm text-gray-600 font-normal">
                      <NumberFormat
                        value={mtdNetIncrRevPerc}
                        format="0,0"
                      ></NumberFormat>
                      %
                    </div> */}
                  </td>

                  <td
                    className={`border px-4 py-2 text-right font-mono text-gray-800 ${
                      currentSortMetricKey === "mtdRevenue"
                        ? "font-semibold"
                        : "font-normal"
                    }`}
                  >
                    <NumberFormat
                      value={d.mtdRevenue}
                      format="$0,0"
                    ></NumberFormat>
                    {d.mtdPotentialRev > 0 && (
                      <div className="text-blue-600 text-sm font-normal">
                        <span className="text-gray-500 text-xs">
                          Billable:{" "}
                        </span>
                        <NumberFormat
                          value={d.mtdBillableRev}
                          format="$0,0"
                        ></NumberFormat>
                      </div>
                    )}
                    {/* <div>{d.mtdPotentialRev}</div> */}
                    {/* ({_.round((d.mtdRevenue / d.mtdNetIncreasedRev) * 100)}) */}
                    {/* <div className="text-sm text-gray-600 font-normal">
                      <NumberFormat
                        value={mtdRevenuePerc}
                        format="0,0"
                      ></NumberFormat>
                      %
                    </div> */}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }
}

export default NetworkRevTable;
