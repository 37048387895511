import React from "react";
import _ from "lodash";
import YbMockupHomeInventorySection from "./InventorySection";
import SummarySection from "./SummarySection";
import TrendSection from "./TrendSection";
import YbMockupHomeAlgorithmSection from "./AlgorithmSection";

class HomeDashboardBody extends React.Component {
  render() {
    const {
      updatedTime,
      selectedPubId,
      selectedNetworkId,
      selectedYieldSetIds,
      inventoryData,
      summaryData,
      algoData = {},
      startDate,
      endDate,
      distributionData,
      trendData,
      selectedCurrency,
    } = this.props;

    return (
      <>
        <div>
          <YbMockupHomeInventorySection
            updatedTime={updatedTime}
            inventoryData={inventoryData}
            selectedYieldSetIds={selectedYieldSetIds}
          ></YbMockupHomeInventorySection>
        </div>

        <div className="mt-8 mb-12">
          <YbMockupHomeAlgorithmSection
            inventoryData={inventoryData}
            selectedYieldSetIds={selectedYieldSetIds}
          ></YbMockupHomeAlgorithmSection>
        </div>

        <div className="my-8">
          <SummarySection
            selectedPubId={selectedPubId}
            selectedNetworkId={selectedNetworkId}
            selectedYieldSetIds={selectedYieldSetIds}
            startDate={startDate}
            endDate={endDate}
            summaryData={summaryData}
            distributionData={distributionData}
            selectedCurrency={selectedCurrency}
          ></SummarySection>
        </div>
        <div>
          <TrendSection
            selectedPubId={selectedPubId}
            selectedNetworkId={selectedNetworkId}
            selectedYieldSetIds={selectedYieldSetIds}
            startDate={startDate}
            endDate={endDate}
            reports={trendData}
            summaryData={summaryData}
            selectedCurrency={selectedCurrency}
          ></TrendSection>
        </div>
      </>
    );
  }
}

export default HomeDashboardBody;
