import React from "react";
import _ from "lodash";

import Checkbox from "components/common/Checkbox";
import { INVENTORY_REGISTRY } from "constants/InventoryRegistry";
import InventoryRegistrySelectorLocal from "components/common/InventoryRegistrySelectorLocal";

const titleClass = "block text-gray-800 text-sm font-bold"; //"font-semibold";

const inputClass =
  "bg-white flex border border-gray-400 rounded py-1 px-3 appearance-none leading-normal focus:outline-none focus:border-blue-400 focus:shadow-inner hover:border-gray-500";

const OVERRIDE_SCHEMA = [
  {
    key: "time_granularity",
    title: "Time Granularity",
    valueType: "integer_enum",
    enum: [1, 3, 6],
  },
  {
    key: "traffic_ratio_option_index",
    title: "Traffic Ratio Option Index",
    valueType: "integer",
    min: 0,
    max: 99999,
    step: 1,
  },
  {
    key: "upr_protection_mode",
    title: "Publisher UPR Protection Mode",
    valueType: "string_enum",
    enum: ["NONE", "DETECTOR", "DETECTOR_AND_X", "ALL"],
  },
];

const REGISTRY_MAP = _.keyBy(INVENTORY_REGISTRY, "id");

class OverrideConfig extends React.Component {
  constructor(props) {
    super(props);

    const currentRId = _.get(props.currentOverride, "registry_id", null);
    let registryOptions = props.registryOptions;
    if (currentRId) {
      registryOptions.push({
        registry_id: currentRId,
        registry_name: REGISTRY_MAP[currentRId].label,
      });
    }

    this.state = {
      currentOverride: props.currentOverride || {},
      registryOptions,
      isEdit: currentRId !== null,
    };

    this.handleRegistryChanged = this.handleRegistryChanged.bind(this);
    this.handleChanged = this.handleChanged.bind(this);
  }

  handleRegistryChanged(registryId) {
    let newOverride = { ...this.state.currentOverride };
    newOverride.registry_id = _.parseInt(registryId);
    this.setState({ currentOverride: newOverride });
    this.props.handleOverrideChanged(newOverride);
  }

  // action: ADD, REMOVE
  handleChanged({ key, value, action, valueType }) {
    let newOverride = { ...this.state.currentOverride };
    if (action === "ADD") {
      newOverride[key] = valueType === "integer" ? _.parseInt(value) : value;
    } else {
      newOverride = _.omit(newOverride, key);
    }
    this.setState({ currentOverride: newOverride });
    this.props.handleOverrideChanged(newOverride);
  }

  render() {
    // const { registryOptions } = this.props;
    const { currentOverride, registryOptions, isEdit } = this.state;

    return (
      <div className="px-1">
        <div className="mb-4">
          <label className={titleClass}>
            Registry:
            <InventoryRegistrySelectorLocal
              registries={registryOptions}
              isDisabled={isEdit}
              selectedItems={currentOverride.registry_id}
              handleChanged={this.handleRegistryChanged}
            ></InventoryRegistrySelectorLocal>
          </label>
        </div>

        {OVERRIDE_SCHEMA.map((schema) => {
          return (
            <div key={schema.key} className="mb-4">
              <ConfigInputElement
                registryId={currentOverride.registry_id}
                schema={schema}
                config={currentOverride}
                isChecked={_.has(currentOverride, schema.key)}
                handleChanged={this.handleChanged}
              ></ConfigInputElement>
            </div>
          );
        })}
      </div>
    );
  }
}

class ConfigInputElement extends React.Component {
  constructor(props) {
    super(props);

    const { config, isChecked = false } = this.props;

    this.state = {
      config,
      isChecked,
    };

    this.handleChanged = this.handleChanged.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
  }

  handleCheck(key) {
    const isChecked = !this.state.isChecked;
    this.setState({ isChecked });

    if (!isChecked) {
      // remove from config
      let newConfig = _.clone(this.state.config);
      newConfig = _.omit(newConfig, key);
      this.setState({ config: newConfig });

      this.props.handleChanged({ key, action: "REMOVE" });
    }
  }

  handleChanged({ key, value, valueType }) {
    const { config } = this.state;

    let newConfig = _.clone(config);
    newConfig[key] = value;
    this.setState({ config: newConfig });

    this.props.handleChanged({ key, value, action: "ADD", valueType });
  }

  render() {
    const { schema, registryId } = this.props;
    const { config, isChecked } = this.state;

    let configInputElement = "";

    if (schema.valueType === "boolean") {
      configInputElement = (
        <div className="flex gap-4">
          <label
            htmlFor={`${registryId}_${schema.key}_radio_true`}
            className="cursor-pointer"
          >
            <input
              id={`${registryId}_${schema.key}_radio_true`}
              type="radio"
              value="true"
              checked={config[schema.key] === true}
              onChange={() =>
                this.handleChanged({
                  key: schema.key,
                  value: true,
                })
              }
              required
            />{" "}
            True
          </label>

          <label
            htmlFor={`${registryId}_${schema.key}_radio_false`}
            className="cursor-pointer"
          >
            <input
              id={`${registryId}_${schema.key}_radio_false`}
              type="radio"
              value="false"
              checked={config[schema.key] === false}
              onChange={() =>
                this.handleChanged({
                  key: schema.key,
                  value: false,
                })
              }
              required
            />{" "}
            False
          </label>
        </div>
      );
    } else if (schema.valueType === "integer_enum") {
      configInputElement = (
        <div className="flex gap-4">
          {schema.enum.map((option) => {
            return (
              <label
                key={option}
                htmlFor={`${registryId}_${schema.key}_radio_${option}`}
                className="cursor-pointer"
              >
                <input
                  id={`${registryId}_${schema.key}_radio_${option}`}
                  type="radio"
                  value="true"
                  checked={config[schema.key] === option}
                  onChange={() =>
                    this.handleChanged({
                      key: schema.key,
                      value: option,
                    })
                  }
                />{" "}
                {option}
              </label>
            );
          })}
        </div>
      );
    } else if (schema.valueType === "array") {
      configInputElement = (
        <div className="flex gap-4">
          {schema.arrayOptions.map((option) => {
            const isChecked = _.indexOf(config[schema.key], option) !== -1;
            return (
              <div key={option}>
                <label className="flex cursor-pointer items-center">
                  <Checkbox
                    isChecked={isChecked}
                    onChange={() => {
                      let value = config[schema.key] || [];
                      if (isChecked) {
                        value = _.without(value, option);
                      } else {
                        value.push(option);
                      }
                      return this.handleChanged({
                        key: schema.key,
                        value,
                      });
                    }}
                  ></Checkbox>
                  <span className="ml-1 align-middle text-sm font-medium text-gray-800 hover:text-gray-700">
                    {option}
                  </span>
                </label>
              </div>
            );
          })}
        </div>
      );
    } else if (schema.valueType === "integer") {
      const valueString = config[schema.key] + "";
      configInputElement = (
        <input
          className={inputClass}
          type="number"
          value={valueString}
          onChange={(e) =>
            this.handleChanged({
              key: schema.key,
              value: e.target.value,
              valueType: schema.valueType, // to make sure to parseInt
            })
          }
          min={schema.min}
          max={schema.max}
          step={schema.step}
        ></input>
      );
    } else if (schema.valueType === "string_enum") {
      configInputElement = (
        <div className="flex gap-4">
          {schema.enum.map((option) => {
            return (
              <label
                key={option}
                htmlFor={`${registryId}_${schema.key}_radio_${option}`}
                className="cursor-pointer"
              >
                <input
                  id={`${registryId}_${schema.key}_radio_${option}`}
                  type="radio"
                  value="true"
                  checked={config[schema.key] === option}
                  onChange={() =>
                    this.handleChanged({
                      key: schema.key,
                      value: option,
                    })
                  }
                />{" "}
                {option}
              </label>
            );
          })}
        </div>
      );
    }

    return (
      <div>
        <label
          className={`${titleClass} flex cursor-pointer items-center gap-1`}
        >
          <Checkbox
            isChecked={isChecked}
            onChange={() => this.handleCheck(schema.key)}
          ></Checkbox>
          {schema.key}
        </label>
        <div>{isChecked && configInputElement}</div>
      </div>
    );
  }
}

export default OverrideConfig;
