import React from "react";
import _ from "lodash";

import NetworkCustomUPRForm from "./NetworkCustomUPRForm";
import ModalWrapper from "../../../common/ModalWrapper";
import PreviewInJsonPopover from "../PreviewInJsonPopover";
import TargetingWithInExControl from "./TargetingWithInExControl";
import CustomRulesSelector from "./CustomRulesSelector";

const inputClass =
  "bg-white flex w-full border border-gray-400 rounded py-1 px-3 appearance-none leading-normal focus:outline-none focus:border-blue-400 focus:shadow-inner hover:border-gray-500";
const inputClassDisabled =
  "bg-gray-300 flex w-full border border-gray-400 rounded py-1 px-3 appearance-none leading-normal";

const targetingBlockClass = "border p-2 my-2 bg-white";
const targetingTitleClass = "block text-gray-800 font-bold mb-1"; //"font-semibold";

// Important! not snake case!!!!!!! snake case is in apconfig
const TARGETING_TYPE = {
  OS: "os",
  DC: "deviceCategory",
  COUNTRY: "country",
  BROWSER: "browser",
  CUSTOMS: "customs",
  INVENTORY_TYPES: "inventoryTypes",
};

class BatchUpdateRulesModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRuleIds: [],
      targetingType: TARGETING_TYPE.COUNTRY,
      newTargetingSection: {},
    };

    this.handleRulesChanged = this.handleRulesChanged.bind(this);
    this.handleBatchUpdate = this.handleBatchUpdate.bind(this);
  }

  handleRulesChanged(rules) {
    if (_.isEmpty(this.state.newTargetingSection)) {
      const newTargetingSection = _.get(
        _.find(this.props.customRules, {
          id: _.first(rules),
        }),
        "targetingSection"
      );
      this.setState({
        newTargetingSection,
      });
    }
    this.setState({ selectedRuleIds: rules });
  }

  handleTargetingChanged(targeting, key) {
    // console.log(targeting, key);
    let { newTargetingSection } = this.state;
    newTargetingSection[key] = targeting;
    if (!targeting) {
      newTargetingSection = _.omit(newTargetingSection, key);
    }

    this.setState({
      newTargetingSection,
    });

    // let newRule = this.props.uprSetting;
    // newRule.targetingSection = targetingSection;
    // this.props.handleCurrentRuleChanged(newRule);
  }

  handleBatchUpdate() {
    const { selectedRuleIds, newTargetingSection, targetingType } = this.state;

    const userConfirm = window.confirm(
      `Batch update these rules: ${selectedRuleIds.join(", ")}?`
    );
    if (!userConfirm) return;

    this.props.handleBatchUpdateRules(
      selectedRuleIds,
      newTargetingSection,
      targetingType
    );
  }

  render() {
    const {
      isModalOpen,
      formErrMsg,
      isEditing,

      isSaving,

      handleCloseModal,
      customRules,
    } = this.props;
    const { selectedRuleIds, newTargetingSection } = this.state;

    return (
      <ModalWrapper
        isOpen={isModalOpen}
        showCloseFooter={false}
        handleClose={handleCloseModal}
        width="80%"
        height="90%"
      >
        <div className="flex h-full flex-col justify-between">
          <div className="mb-2 text-xl font-bold text-gray-700">
            Batch update rules
          </div>
          <div
            className="border h-full overflow-y-auto"
            // style={{ maxHeight: "620px" }}
          >
            <div className="rounded mb-4 bg-white px-8 pt-6 pb-8">
              <div>
                <div className="mb-4">
                  <label
                    className="block text-lg font-bold text-gray-800"
                    htmlFor="rules"
                  >
                    Select rules to update
                  </label>
                  <CustomRulesSelector
                    rules={customRules}
                    selectedItems={selectedRuleIds}
                    handleChanged={this.handleRulesChanged}
                  ></CustomRulesSelector>
                </div>

                {!_.isEmpty(selectedRuleIds) && (
                  <div className="border-t rounded mb-4 bg-gray-300 px-4 py-2">
                    <div className="border-b mt-2 text-lg font-bold">
                      Targeting
                    </div>

                    <div className={targetingBlockClass}>
                      <div className={targetingTitleClass}>Country</div>
                      <TargetingWithInExControl
                        targetingType={TARGETING_TYPE.COUNTRY}
                        targeting={newTargetingSection[TARGETING_TYPE.COUNTRY]}
                        handleTargetingChanged={(t) =>
                          this.handleTargetingChanged(t, TARGETING_TYPE.COUNTRY)
                        }
                      ></TargetingWithInExControl>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex w-full pt-4">
            <div className="w-1/3"> </div>
            <div className="flex w-1/3 items-center justify-center"></div>
            <div className="flex w-1/3 items-center justify-end">
              <div className="text-red-600">{formErrMsg}</div>
              <button
                type="button"
                disabled={isSaving}
                className={`px-4 py-2 text-blue-700 ${
                  isSaving ? "cursor-not-allowed" : ""
                }`}
                onClick={handleCloseModal}
              >
                Cancel
              </button>
              <button
                type="button"
                className={`px-4 py-2 ${
                  isEditing || isSaving
                    ? "bg-gray-400 text-gray-800"
                    : "bg-blue-500 text-white hover:bg-blue-700"
                }  rounded shadow font-semibold`}
                onClick={this.handleBatchUpdate}
                disabled={isSaving}
              >
                {isSaving ? "Saving..." : "Update rules"}
              </button>
            </div>
          </div>
        </div>
      </ModalWrapper>
    );
  }
}

export default BatchUpdateRulesModal;
