import React from "react";
import _ from "lodash";

const inputClass =
  "bg-white flex w-full border border-gray-300 rounded py-1 px-4 appearance-none leading-normal focus:outline-none focus:border-blue-400 focus:shadow-inner hover:shadow";

const labelClass = "font-semibold text-gray-800";
const radioClass = "font-semibold text-sm text-gray-900 cursor-pointer";

class InvoiceSettingSectionForm extends React.PureComponent {
  constructor(props) {
    super(props);

    // setting.invoiceSetting
    const { setting } = this.props;
    const invoiceSetting = setting.invoiceSetting || {};
    const {
      category = "",
      printFlag = "",
      taxType = "",
      ubn = "",
      carrierType = "",
      carrierNum = "",
      loveCode = "",
      customsClearance = "",
    } = invoiceSetting;

    this.state = {
      category,
      printFlag,
      taxType,
      ubn,
      carrierType,
      carrierNum,
      loveCode,
      customsClearance,

      // errorObj[key] = errMsg
      errorObj: {},
    };
  }

  onInputChanged(value, key) {
    this.setState({ [key]: value });

    // check valid
    const { isValid, errMsg } = this.checkKeyValid(key, value);
    if (!isValid) {
      const newErrorObj = { ...this.state.errorObj, [key]: errMsg };
      this.setState({ errorObj: newErrorObj });
      return;
    } else {
      let newErrorObj = this.state.errorObj;
      newErrorObj = _.omit(newErrorObj, key);
      this.setState({ errorObj: newErrorObj });
    }

    // transform to valid type
    const { setting } = this.props;
    let newValue = value;
    let newKey = key;
    switch (key) {
      default: {
        //
      }
    }

    let changedSetting = setting;
    changedSetting.invoiceSetting = {
      ...setting.invoiceSetting,
      [newKey]: newValue,
    };
    this.props.onSettingChanged(changedSetting);
  }

  checkKeyValid(key, value) {
    let isValid = true;
    let errMsg = null;

    // errorObj[key] = errMsg
    switch (key) {
      // case "phoneNumber":
      // case "phoneCountryCode": {
      //   const newValue = _.parseInt(value);
      //   if (_.isNaN(newValue)) {
      //     isValid = false;
      //     errMsg = `${key} should be a number`;
      //     break;
      //   }
      // }

      default: {
        //
      }
    }

    return { isValid, errMsg };
  }

  checkIsSectionValid(setting) {
    // check the whole section
    const { invoiceSetting = {} } = setting;
    let isValid = true;
    const requiredKeys = ["category", "printFlag", "taxType"];
    for (let key of requiredKeys) {
      if (
        invoiceSetting[key] === "" ||
        invoiceSetting[key] === undefined ||
        invoiceSetting[key] === null
      ) {
        isValid = false;
        this.setState({ errorObj: { [key]: `${key} is required` } });
        break;
      }
    }

    return isValid;
  }

  render() {
    const {
      category,
      printFlag,
      taxType,
      ubn,
      carrierType,
      carrierNum,
      loveCode,
      customsClearance,

      errorObj,
    } = this.state;

    return (
      <div className="border shadow rounded mb-4 bg-gray-200 p-4">
        <div className="border-b mb-6 mt-2 border-gray-400 text-xl font-bold text-gray-900">
          TW Invoice Setting
        </div>

        {/* <div>{JSON.stringify(this.state, null, 4)}</div> */}

        <div className="mb-4 flex">
          <div className="w-2/3">
            <label className={labelClass}>Category*</label>

            <div className="flex items-center gap-4 py-1">
              <label htmlFor={`is_cat_b2b`} className={radioClass}>
                <input
                  id={`is_cat_b2b`}
                  type="radio"
                  name={`is_cat`}
                  value={"B2B"}
                  checked={category === "B2B"}
                  onChange={(e) => this.onInputChanged("B2B", "category")}
                  required
                />{" "}
                B2B
              </label>

              <label htmlFor={`is_cat_b2c`} className={radioClass}>
                <input
                  id={`is_cat_b2c`}
                  type="radio"
                  name={`is_cat`}
                  value={"B2C"}
                  checked={category === "B2C"}
                  onChange={(e) => this.onInputChanged("B2C", "category")}
                  required
                />{" "}
                B2C
              </label>
            </div>
          </div>

          <div className="flex w-1/3 items-end pb-1">
            {errorObj.category && (
              <div className="w-full bg-red-100 px-2 py-1 text-sm text-red-800">
                {errorObj.category}
              </div>
            )}
          </div>
        </div>

        <div className="mb-4 flex">
          <div className="w-2/3">
            <label className={labelClass}>Print Flag*</label>

            <div className="flex items-center gap-4 py-1">
              <label htmlFor={`is_pf_n`} className={radioClass}>
                <input
                  id={`is_pf_n`}
                  type="radio"
                  name={`is_pf`}
                  value={"NECESSARY"}
                  checked={printFlag === "NECESSARY"}
                  onChange={(e) =>
                    this.onInputChanged("NECESSARY", "printFlag")
                  }
                  required
                />{" "}
                NECESSARY
              </label>

              <label htmlFor={`is_pf_un`} className={radioClass}>
                <input
                  id={`is_pf_un`}
                  type="radio"
                  name={`is_pf`}
                  value={"UNNECESSARY"}
                  checked={printFlag === "UNNECESSARY"}
                  onChange={(e) =>
                    this.onInputChanged("UNNECESSARY", "printFlag")
                  }
                  required
                />{" "}
                UNNECESSARY
              </label>
            </div>
          </div>

          <div className="flex w-1/3 items-end pb-1">
            {errorObj.printFlag && (
              <div className="w-full bg-red-100 px-2 py-1 text-sm text-red-800">
                {errorObj.printFlag}
              </div>
            )}
          </div>
        </div>

        <div className="mb-4 flex">
          <div className="w-2/3">
            <label className={labelClass}>Tax Type*</label>

            <div className="flex items-center gap-4 py-1">
              <label htmlFor={`is_tt_n`} className={radioClass}>
                <input
                  id={`is_tt_n`}
                  type="radio"
                  name={`is_tt`}
                  value={"NECESSARY"}
                  checked={taxType === "NECESSARY"}
                  onChange={(e) => this.onInputChanged("NECESSARY", "taxType")}
                  required
                />{" "}
                NECESSARY
              </label>

              <label htmlFor={`is_tt_zero`} className={radioClass}>
                <input
                  id={`is_tt_zero`}
                  type="radio"
                  name={`is_tt`}
                  value={"ZERO"}
                  checked={taxType === "ZERO"}
                  onChange={(e) => this.onInputChanged("ZERO", "taxType")}
                  required
                />{" "}
                ZERO
              </label>

              <label htmlFor={`is_tt_free`} className={radioClass}>
                <input
                  id={`is_tt_free`}
                  type="radio"
                  name={`is_tt`}
                  value={"FREE"}
                  checked={taxType === "FREE"}
                  onChange={(e) => this.onInputChanged("FREE", "taxType")}
                  required
                />{" "}
                FREE
              </label>

              <label htmlFor={`is_tt_mixed`} className={radioClass}>
                <input
                  id={`is_tt_mixed`}
                  type="radio"
                  name={`is_tt`}
                  value={"MIXED"}
                  checked={taxType === "MIXED"}
                  onChange={(e) => this.onInputChanged("MIXED", "taxType")}
                  required
                />{" "}
                MIXED
              </label>
            </div>
          </div>

          <div className="flex w-1/3 items-end pb-1">
            {errorObj.taxType && (
              <div className="w-full bg-red-100 px-2 py-1 text-sm text-red-800">
                {errorObj.taxType}
              </div>
            )}
          </div>
        </div>

        {category === "B2B" && (
          <div className="mb-4 flex">
            <div className="w-2/3">
              <label className={labelClass}>
                UBN{category === "B2B" && "*"}
                {/* <span className="text-sm text-gray-700">
                  {" "}
                  (Tax ID. Required for B2B)
                </span> */}
              </label>
              <input
                type="text"
                className={inputClass}
                value={ubn}
                onChange={(e) => this.onInputChanged(e.target.value, "ubn")}
                required={category === "B2B"}
              ></input>
            </div>
            <div className="flex w-1/3 items-end pb-1">
              {errorObj.ubn && (
                <div className="w-full bg-red-100 px-2 py-1 text-sm text-red-800">
                  {errorObj.ubn}
                </div>
              )}
            </div>
          </div>
        )}

        {category === "B2C" && (
          <div className="mb-4 flex">
            <div className="w-2/3">
              <label className={labelClass}>
                Carrier Type{category === "B2C" && !loveCode && "*"}
                {/* <span className="text-sm text-gray-700">
                  {" "}
                  (Required for B2C and loveCode is null)
                </span> */}
              </label>

              <div className="flex items-center gap-4 py-1">
                <label htmlFor={`is_ct_mobile`} className={radioClass}>
                  <input
                    id={`is_ct_mobile`}
                    type="radio"
                    name={`is_ct`}
                    value={"MOBILE"}
                    checked={carrierType === "MOBILE"}
                    onChange={(e) =>
                      this.onInputChanged("MOBILE", "carrierType")
                    }
                    required={category === "B2C" && !loveCode}
                  />{" "}
                  MOBILE
                </label>

                <label htmlFor={`is_ct_np`} className={radioClass}>
                  <input
                    id={`is_ct_np`}
                    type="radio"
                    name={`is_ct`}
                    value={"NATURAL_PERSON"}
                    checked={carrierType === "NATURAL_PERSON"}
                    onChange={(e) =>
                      this.onInputChanged("NATURAL_PERSON", "carrierType")
                    }
                    required={category === "B2C" && !loveCode}
                  />{" "}
                  NATURAL_PERSON
                </label>

                <label htmlFor={`is_ct_ez`} className={radioClass}>
                  <input
                    id={`is_ct_ez`}
                    type="radio"
                    name={`is_ct`}
                    value={"EZPAY"}
                    checked={carrierType === "EZPAY"}
                    onChange={(e) =>
                      this.onInputChanged("EZPAY", "carrierType")
                    }
                    required={category === "B2C" && !loveCode}
                  />{" "}
                  EZPAY
                </label>
              </div>
            </div>
            <div className="flex w-1/3 items-end pb-1">
              {errorObj.carrierType && (
                <div className="w-full bg-red-100 px-2 py-1 text-sm text-red-800">
                  {errorObj.carrierType}
                </div>
              )}
            </div>
          </div>
        )}

        {category === "B2C" && carrierType && (
          <div className="mb-4 flex">
            <div className="w-2/3">
              <label className={labelClass}>
                Carrier Number{carrierType && "*"}{" "}
                {/* <span className="text-sm text-gray-700">
                  {" "}
                  (required if carrierType is not null)
                </span> */}
              </label>
              <input
                type="text"
                className={inputClass}
                value={carrierNum}
                onChange={(e) =>
                  this.onInputChanged(e.target.value, "carrierNum")
                }
                required={carrierType}
              ></input>
            </div>
            <div className="flex w-1/3 items-end pb-1">
              {errorObj.carrierNum && (
                <div className="w-full bg-red-100 px-2 py-1 text-sm text-red-800">
                  {errorObj.carrierNum}
                </div>
              )}
            </div>
          </div>
        )}

        {category === "B2C" && (
          <div className="mb-4 flex">
            <div className="w-2/3">
              <label className={labelClass}>
                Love Code{" "}
                {/* <span className="text-sm text-gray-700"> (B2C only)</span> */}
              </label>
              <input
                type="text"
                className={inputClass}
                value={loveCode || ""}
                onChange={(e) =>
                  this.onInputChanged(e.target.value, "loveCode")
                }
              ></input>
            </div>
            <div className="flex w-1/3 items-end pb-1">
              {errorObj.loveCode && (
                <div className="w-full bg-red-100 px-2 py-1 text-sm text-red-800">
                  {errorObj.loveCode}
                </div>
              )}
            </div>
          </div>
        )}

        {taxType === "ZERO" && (
          <div className="mb-4 flex">
            <div className="w-2/3">
              <label className={labelClass}>
                Custom Clearance{taxType === "ZERO" && "*"}
                {/* <span className="text-sm text-gray-700">
                  {" "}
                  (required when taxType is ZERO)
                </span> */}
              </label>

              <div className="flex items-center gap-4 py-1">
                <label htmlFor={`is_cc_n`} className={radioClass}>
                  <input
                    id={`is_cc_n`}
                    type="radio"
                    name={`is_cc`}
                    value={"NECESSARY"}
                    checked={customsClearance === "NECESSARY"}
                    onChange={(e) =>
                      this.onInputChanged("NECESSARY", "customsClearance")
                    }
                    required={taxType === "ZERO"}
                  />{" "}
                  NECESSARY
                </label>

                <label htmlFor={`is_cc_un`} className={radioClass}>
                  <input
                    id={`is_cc_un`}
                    type="radio"
                    name={`is_cc`}
                    value={"UNNECESSARY"}
                    checked={customsClearance === "UNNECESSARY"}
                    onChange={(e) =>
                      this.onInputChanged("UNNECESSARY", "customsClearance")
                    }
                    required={taxType === "ZERO"}
                  />{" "}
                  UNNECESSARY
                </label>
              </div>
            </div>
            <div className="flex w-1/3 items-end pb-1">
              {errorObj.customsClearance && (
                <div className="w-full bg-red-100 px-2 py-1 text-sm text-red-800">
                  {errorObj.customsClearance}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default InvoiceSettingSectionForm;
