import React from "react";

class ScrollToTop extends React.PureComponent {
  render() {
    function scrollToTop() {
      window.scrollTo({ top: 0 });
    }

    return (
      <div style={{ height: "80px" }}>
        <div
          className="fixed bottom-0 right-0 bg-gray-800 text-white text-2xl p-4 shadow rounded-tl cursor-pointer hover:bg-gray-900"
          onClick={scrollToTop}
        >
          ⤒
        </div>
      </div>
    );
  }
}

export default ScrollToTop;
