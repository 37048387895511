import React from "react";
import ReactDOM from "react-dom";

import "./styles/index.css";
import "./styles/output.css";
import "./styles/loader.css";
import "./styles/default-table.css";
import "./styles/segment-table.css";
import "./styles/password-eye.css";

// for react date range library
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

// for react date picker library
import "react-datepicker/dist/react-datepicker.css";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
