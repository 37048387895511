import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import { FaRegArrowAltCircleUp } from "react-icons/fa";
import { isWeekend } from "../../../helpers/DateTransform";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { formatMoneyWithCurrency } from "./ReportsHelper";
import FadeInWrapper from "./FadeInWrapper";
import PopoverForTrendDescription from "./PopoverForTrendDescription";

Highcharts.setOptions({
  lang: {
    decimalPoint: ".",
    thousandsSep: ",",
  },
  credits: {
    enabled: false,
  },
});

const CHART_TITLE = {
  REV: "Revenue",
  REQ_PROTECTION_INDEX: "Request Ratio",
  REQ_FUNNEL: "Request Funnel",
  REV_LIFT: "Revenue Lift",
  OPTIMIZED_RRPM: "Optimized Request RPM",
};

function _hideAllTooltips(trendCharts) {
  trendCharts.forEach((chart) => {
    for (let i = 0; i < chart.series.length; i++) {
      let points = chart.series[i].points;
      for (let j = 0; j < points.length; j++) {
        try {
          points[j].onMouseOut();
        } catch (err) {
          //console.log(err);
        }
      }
    }

    chart.tooltip.hide();
    chart.xAxis[0].hideCrosshair();
  });
}

function _initChartEventListeners(trendCharts) {
  let divIds = _.map(CHART_TITLE, (title) => {
    return `chart-id-${title}`;
  });

  // console.log(Highcharts.charts);

  let lastPointIndex = undefined;
  for (let i = 0; i < divIds.length; ++i) {
    (function (i) {
      let divId = divIds[i];
      let divEle = document.getElementById(divId);
      if (!divEle) return;
      let chartRectEle = divEle.getElementsByClassName(
        "highcharts-plot-background"
      )[0];

      divEle.addEventListener(
        "mousemove",
        _.debounce(function (e) {
          let box = chartRectEle.getBoundingClientRect();
          let x = e.clientX;
          let y = e.clientY;
          if (
            box.left >= x ||
            box.right <= x ||
            box.top >= y ||
            box.bottom <= y
          ) {
            if (lastPointIndex) {
              _hideAllTooltips(trendCharts);
            }
            lastPointIndex = undefined;
            return;
          }

          let chart = trendCharts[i];
          let event = chart.pointer.normalize(e);
          let point = chart.series[0].searchPoint(event, true);
          if (point) {
            let pointIndex = point.index;
            if (pointIndex === lastPointIndex) return;

            for (let j = 0; j < divIds.length; ++j) {
              if (trendCharts[(i + j + 1) % divIds.length]) {
                // console.log(
                //   trendCharts[(i + j + 1) % divIds.length],
                //   (i + j + 1) % divIds.length
                // );
                trendCharts[(i + j + 1) % divIds.length].series[0].points[
                  pointIndex
                ].highlight(e);
              }
            }
            lastPointIndex = pointIndex;
          }
        }, 0)
      );
    })(i);
  }
}

function _createAllChartOptions({ reports, selectedCurrency }) {
  // Revenue Chart
  const revChartOptions = _createRevChartOptions({
    reports,
    selectedCurrency,
  });

  // Request Protection Index Chart
  const rpiChartOptions = _createRpiChartOptions({
    reports,
    selectedCurrency,
  });

  // Request Funnel Chart
  const reqFunnelChartOptions = _createReqFunnelChartOptions({
    reports,
    selectedCurrency,
  });

  // Revenue Lift Chart
  const revLiftChartOptions = _createRevLiftChartOptions({
    reports,
    selectedCurrency,
  });

  // Secured Request RPM Chart
  const sgrrpmChartOptions = _createSgrrpmChartOptions({
    reports,
    selectedCurrency,
  });

  return {
    revChartOptions,
    rpiChartOptions,
    reqFunnelChartOptions,
    revLiftChartOptions,
    sgrrpmChartOptions,
  };
}

function _filterCharts(charts) {
  const chartTitles = _.values(CHART_TITLE);
  return _.filter(charts, (c) => {
    if (!c) {
      return false;
    }

    const title = _.get(c, "title.textStr");
    return _.indexOf(chartTitles, title) !== -1;
  });
}

function _calculateTotalRevData({ reports }) {
  // totalRevWithYB
  // totalRevWithoutYB
  // cost
  // netIncreasedRev: get data from summaryData
  // securedLift: get data from summaryData

  const totalRevWithYB = _.sumBy(reports, "revenueWithYB");
  const totalRevWithoutYB = _.sumBy(reports, "revenueWithoutYB");
  const totalCost = _.sumBy(reports, "cost");

  return {
    totalRevWithYB,
    totalRevWithoutYB,
    totalCost,
  };
}

class TrendCombinedChartForCst extends React.Component {
  constructor(props) {
    super(props);

    const { reports, selectedCurrency, summaryData } = props;

    const {
      revChartOptions,
      sgrrpmChartOptions,
      reqFunnelChartOptions,
      rpiChartOptions,
      revLiftChartOptions,
    } = _createAllChartOptions({ reports, selectedCurrency });

    const { netIncreasedRevenue, securedLift } = summaryData;
    const { totalRevWithYB, totalRevWithoutYB, totalCost } =
      _calculateTotalRevData({
        reports,
      });

    this.state = {
      revChartOptions,
      sgrrpmChartOptions,
      reqFunnelChartOptions,
      rpiChartOptions,
      revLiftChartOptions,

      // totalRevenue,
      // originalRevenue,
      // additionalCost,

      totalRevWithYB,
      totalRevWithoutYB,
      totalCost,

      netIncreasedRevenue,
      securedLift,
    };
  }

  componentDidMount() {
    const trendCharts = _filterCharts(Highcharts.charts);
    _initChartEventListeners(trendCharts);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate ||
      prevProps.selectedPubId !== this.props.selectedPubId ||
      prevProps.selectedNetworkId !== this.props.selectedNetworkId ||
      prevProps.selectedYieldSetIds !== this.props.selectedYieldSetIds ||
      prevProps.selectedCurrency !== this.props.selectedCurrency
    ) {
      const { reports, selectedCurrency } = this.props;
      const {
        revChartOptions,
        sgrrpmChartOptions,
        reqFunnelChartOptions,
        rpiChartOptions,
        revLiftChartOptions,
      } = _createAllChartOptions({ reports, selectedCurrency });

      const { netIncreasedRevenue, securedLift } = this.props.summaryData;
      const { totalRevWithYB, totalRevWithoutYB, totalCost } =
        _calculateTotalRevData({
          reports,
        });

      this.setState({
        revChartOptions,
        sgrrpmChartOptions,
        reqFunnelChartOptions,
        rpiChartOptions,
        revLiftChartOptions,

        totalRevWithYB,
        totalRevWithoutYB,
        totalCost,

        netIncreasedRevenue,
        securedLift,
      });
    }
  }

  render() {
    const {
      revChartOptions,
      sgrrpmChartOptions,
      reqFunnelChartOptions,
      rpiChartOptions,
      revLiftChartOptions,

      totalRevWithYB,
      totalRevWithoutYB,
      totalCost,

      netIncreasedRevenue,
      securedLift,
    } = this.state;
    const { selectedCurrency } = this.props;

    const WrapDataThing = ({ isEnd }) => {
      // const defaultClass = "text-3xl text-gray-400 font-light";
      return "";
      // <span className={`${defaultClass} ${isEnd ? "pl-2" : "pr-2"}`}>
      //   {isEnd ? ")" : "("}
      // </span>
    };

    const MathThing = ({ mathSign }) => {
      return (
        <div className="self-center px-2 pt-4 text-3xl font-light text-gray-400">
          {mathSign}
        </div>
      );
    };

    const RevDataBlock = () => {
      return (
        <div className="rounded my-1 flex items-end justify-between bg-gray-100">
          <div className="px-4 py-2">
            <div className="text-xs font-medium text-gray-600">
              Total Revenue <b>with</b> Intowow
            </div>
            <div className="flex items-center justify-center">
              <FadeInWrapper key={totalRevWithYB}>
                <WrapDataThing></WrapDataThing>
                <div className="pt-1">
                  {formatMoneyWithCurrency({
                    currency: selectedCurrency,
                    value: totalRevWithYB,
                  })}
                </div>
                <WrapDataThing isEnd={true}></WrapDataThing>
              </FadeInWrapper>
            </div>
          </div>

          <MathThing mathSign="-"></MathThing>

          <div className="px-4 py-2">
            <div className="text-xs font-medium text-gray-600">
              Total Revenue <b>without</b> Intowow
            </div>

            <div className="flex items-center justify-center">
              <FadeInWrapper key={totalRevWithoutYB}>
                <WrapDataThing></WrapDataThing>
                <div className="pt-1">
                  {formatMoneyWithCurrency({
                    currency: selectedCurrency,
                    value: totalRevWithoutYB,
                  })}
                </div>
                <WrapDataThing isEnd={true}></WrapDataThing>
              </FadeInWrapper>
            </div>
          </div>

          <MathThing mathSign="-"></MathThing>

          <div className="px-4 py-2">
            <div className="text-xs font-medium text-gray-600">
              Extra Ad Serving Cost
            </div>
            <div className="flex items-center justify-center">
              <FadeInWrapper key={totalCost}>
                <WrapDataThing></WrapDataThing>
                <div className="pt-1">
                  {formatMoneyWithCurrency({
                    currency: selectedCurrency,
                    value: totalCost,
                  })}
                </div>
                <WrapDataThing isEnd={true}></WrapDataThing>
              </FadeInWrapper>
            </div>
          </div>

          <MathThing mathSign="="></MathThing>

          <div className="px-4 py-2">
            <div className="text-xs font-medium text-gray-600">
              Net Increased Revenue (Optimized Lift)
            </div>
            <div className="flex items-center justify-center">
              <FadeInWrapper key={netIncreasedRevenue}>
                <div className="flex items-center">
                  <WrapDataThing></WrapDataThing>
                  <div className="pt-1">
                    {formatMoneyWithCurrency({
                      currency: selectedCurrency,
                      value: netIncreasedRevenue,
                    })}
                  </div>
                  <WrapDataThing isEnd={true}></WrapDataThing>
                </div>

                <div className="pl-2 text-sm">
                  <div
                    className={`flex items-center pt-1 font-bold`}
                    style={{ color: "#ff993f" }}
                  >
                    {securedLift >= 0 ? (
                      <div className="pr-1 font-bold">
                        <FaRegArrowAltCircleUp></FaRegArrowAltCircleUp>
                      </div>
                    ) : (
                      ""
                    )}
                    {securedLift >= 0 ? "+" : ""}
                    {securedLift}%
                  </div>
                </div>
              </FadeInWrapper>
            </div>
          </div>
        </div>
      );
    };

    return (
      <div id="container">
        <div
          id={`chart-id-${CHART_TITLE.REV}`}
          className="border rounded mb-4 p-4"
        >
          <div
            className="text-lg font-semibold text-gray-800"
            style={{ marginLeft: "54px", marginRight: "76px" }}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                {CHART_TITLE.REV}
                <PopoverForTrendDescription metricKey="TREND_GOOGLE_REVENUE_CST"></PopoverForTrendDescription>
                {/* <InfoDescriptionPopover descKey="TREND_GOOGLE_REV"></InfoDescriptionPopover> */}
              </div>
              <div className="text-xs font-normal text-gray-600">
                * Current day's data is incomplete
              </div>
            </div>
            <RevDataBlock></RevDataBlock>
          </div>

          <HighchartsReact highcharts={Highcharts} options={revChartOptions} />
        </div>

        <div className="flex">
          <div className="mr-2 w-1/2">
            <div className="border rounded">
              <div
                id={`chart-id-${CHART_TITLE.REQ_PROTECTION_INDEX}`}
                className="border-b p-2"
              >
                <div
                  className="pt-2 text-lg font-semibold text-gray-800"
                  style={{ marginLeft: "54px" }}
                >
                  <div className="flex items-center">
                    {CHART_TITLE.REQ_PROTECTION_INDEX}
                    <PopoverForTrendDescription metricKey="TREND_REQUEST_PROTECTION_INDEX_CST"></PopoverForTrendDescription>
                  </div>
                </div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={rpiChartOptions}
                />
              </div>
              <div id={`chart-id-${CHART_TITLE.REQ_FUNNEL}`} className="p-2">
                <div
                  className="pt-2 text-lg font-semibold text-gray-800"
                  style={{ marginLeft: "54px" }}
                >
                  <div className="flex items-center">
                    {CHART_TITLE.REQ_FUNNEL}
                    <PopoverForTrendDescription metricKey="TREND_REQUEST_FUNNEL_CST"></PopoverForTrendDescription>
                    {/* <InfoDescriptionPopover descKey="TREND_REQ"></InfoDescriptionPopover> */}
                  </div>
                </div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={reqFunnelChartOptions}
                />
              </div>
            </div>
          </div>

          <div className="w-1/2">
            <div className="border rounded ml-2">
              <div
                id={`chart-id-${CHART_TITLE.REV_LIFT}`}
                className="border-b p-2"
              >
                <div
                  className="pt-2 text-lg font-semibold text-gray-800"
                  style={{ marginLeft: "54px" }}
                >
                  <div className="flex items-center">
                    {CHART_TITLE.REV_LIFT}
                    <PopoverForTrendDescription metricKey="TREND_REVENUE_LIFT_CST"></PopoverForTrendDescription>
                    {/* <InfoDescriptionPopover descKey="TREND_LIFT"></InfoDescriptionPopover> */}
                  </div>
                </div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={revLiftChartOptions}
                />
              </div>

              <div
                id={`chart-id-${CHART_TITLE.OPTIMIZED_RRPM}`}
                className="p-2"
              >
                <div
                  className="pt-2 text-lg font-semibold text-gray-800"
                  style={{ marginLeft: "54px" }}
                >
                  <div className="flex items-center">
                    {CHART_TITLE.OPTIMIZED_RRPM}
                    <PopoverForTrendDescription metricKey="TREND_OPTIMIZED_RRPM"></PopoverForTrendDescription>

                    {/* <InfoDescriptionPopover descKey="TREND_RRPM"></InfoDescriptionPopover> */}
                  </div>
                </div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={sgrrpmChartOptions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function _createRevChartOptions({ reports, selectedCurrency }) {
  let options = {
    ..._getDefaultChartOptions(selectedCurrency),
  };

  // height of chart
  options.chart.height = 320;
  // to make sure all charts are aligned (with the same margin)
  options.chart.marginLeft = 64;

  options.title = {
    text: CHART_TITLE.REV,
    // title is important when identifing graphs to synch highlight
    style: {
      display: "none",
    },
  };

  const { seriesData } = _getRevTrendData(reports);

  options.series = seriesData;

  options.yAxis = [
    {
      startOnTick: false,
      title: {
        text: "",
      },
      // a thicker line across 0
      plotLines: [
        {
          color: "#C5C5C5",
          width: 1,
          value: 0,
          zIndex: 5,
        },
      ],
      lineWidth: 2,
      offset: 10,
      tickWidth: 1,
      gridLineWidth: 0,
    },
    {
      startOnTick: false,
      title: {
        text: "Requests",
      },
      opposite: true,
      softMin: 0,
      lineWidth: 2,
      offset: 10,
      tickWidth: 1,
      gridLineWidth: 0,
    },
  ];

  return options;
}

function _createRpiChartOptions({ reports, selectedCurrency }) {
  let options = {
    ..._getDefaultChartOptions(selectedCurrency),
  };

  // height of chart
  options.chart.height = 250;

  // to make sure all charts are aligned (with the same margin)
  options.chart.marginLeft = 64;
  options.chart.marginRight = 24;

  options.title = {
    text: CHART_TITLE.REQ_PROTECTION_INDEX,
    // title is important when identifing graphs to synch highlight
    style: {
      display: "none",
    },
  };

  const { seriesData } = _getRpiData(reports);

  options.series = seriesData;

  options.yAxis = [
    {
      title: {
        text: "",
      },
      lineWidth: 2,
      offset: 10,
      tickWidth: 1,
      gridLineWidth: 0,
      min: 0,
      max: 100,
    },
  ];

  return options;
}

function _createRevLiftChartOptions({ reports, selectedCurrency }) {
  let options = {
    ..._getDefaultChartOptions(selectedCurrency),
  };

  options.title = {
    text: CHART_TITLE.REV_LIFT,
    // title is important when identifing graphs to synch highlight
    style: {
      display: "none",
    },
  };

  // height of chart
  options.chart.height = 250;

  // to make sure all charts are aligned (with the same margin)
  options.chart.marginLeft = 64;
  options.chart.marginRight = 24;

  const { seriesData } = _getRevLiftTrendData(reports);
  options.series = seriesData;

  options.yAxis = [
    {
      title: {
        text: "",
      },
      lineWidth: 2,
      offset: 10,
      tickWidth: 1,
      gridLineWidth: 0,
    },
  ];

  return options;
}

function _createSgrrpmChartOptions({ reports, selectedCurrency }) {
  let options = {
    ..._getDefaultChartOptions(selectedCurrency),
  };

  options.title = {
    text: CHART_TITLE.OPTIMIZED_RRPM,
    // title is important when identifing graphs to synch highlight
    style: {
      display: "none",
    },
  };

  // height of chart
  options.chart.height = 250;

  // to make sure all charts are aligned (with the same margin)
  options.chart.marginLeft = 64;
  options.chart.marginRight = 24;

  const { seriesData } = _getSecuredGoogleRRpmTrendData(reports);
  options.series = seriesData;

  options.yAxis = [
    {
      title: {
        text: "",
      },
      lineWidth: 2,
      offset: 10,
      tickWidth: 1,
      gridLineWidth: 0,
    },
  ];

  return options;
}

function _createReqFunnelChartOptions({ reports, selectedCurrency }) {
  let options = {
    ..._getDefaultChartOptions(selectedCurrency),
  };

  // height of chart
  options.chart.height = 250;

  // to make sure all charts are aligned (with the same margin)
  options.chart.marginLeft = 64;
  options.chart.marginRight = 24;

  options.title = {
    text: CHART_TITLE.REQ_FUNNEL,
    // title is important when identifing graphs to synch highlight
    style: {
      display: "none",
    },
  };

  const { seriesData } = _getReqFunnelTrendData(reports);

  options.series = seriesData;

  options.yAxis = [
    {
      title: {
        text: "",
      },
      lineWidth: 2,
      offset: 10,
      tickWidth: 1,
      gridLineWidth: 0,
    },
  ];

  return options;
}

// --- Revenue Trend ---
// Increased Revenue: increasedRevenue
// Base Revenue: originalRevenue
// Benchmark Revenue: protectedOriginalRevenue - originalRevenue
// Unmanaged Revenue: notProtectedRevenue
// Extra Ad Serving Cost: cost
// Total Requests: totalRequest
function _getRevTrendData(reports) {
  let increasedData = [];
  let baseData = [];
  let benchData = [];
  let costData = [];
  let unmangedData = [];
  let requestData = [];

  // get min of revenue lift
  let min, newMin;

  _.forEach(reports, (r) => {
    const date = new Date(r.date).getTime();

    // newMin = r.grossRevenueLift;
    // min = min ? (newMin < min ? newMin : min) : newMin;

    // Increased Revenue: increasedRevenue
    increasedData.push({
      x: date,
      y: r.increasedRevenue,
      needCurrency: true,
    });
    // Base Revenue: originalRevenue
    baseData.push({
      x: date,
      y: r.originalRevenue,
      needCurrency: true,
    });
    // Benchmark Revenue: protectedOriginalRevenue - originalRevenue
    benchData.push({
      x: date,
      y: r.protectedOriginalRevenue - r.originalRevenue,
      needCurrency: true,
    });
    // Unmanaged Revenue: notProtectedRevenue
    unmangedData.push({
      x: date,
      y: r.notProtectedRevenue,
      needCurrency: true,
    });
    costData.push({
      x: date,
      y: r.cost * -1,
      needCurrency: true,
      isAbsolute: true, // no negative sign in tooltip
    });
    requestData.push({
      x: date,
      y: r.totalRequests,
    });
  });

  const seriesData = [
    {
      type: "column",
      stacking: "normal",
      name: "Increased Revenue",
      data: increasedData,
      yAxis: 0,
      color: "#ff993f",
      borderWidth: 0,
      zIndex: 5,
    },
    {
      type: "column",
      stacking: "normal",
      name: "Base Revenue",
      data: baseData,
      yAxis: 0,
      color: "#2E5FB0",
      borderWidth: 0,
      zIndex: 5,
    },
    {
      type: "column",
      stacking: "normal",
      name: "Benchmark Revenue",
      data: benchData,
      yAxis: 0,
      color: "#7DABF1",
      borderWidth: 0,
      zIndex: 5,
    },
    {
      type: "column",
      stacking: "normal",
      name: "Unmanaged Revenue",
      data: unmangedData,
      yAxis: 0,
      borderWidth: 0,
      color: "#C6DCFF",
      zIndex: 5,
    },
    {
      type: "column",
      stacking: "normal",
      name: "Extra Ad Serving Cost",
      data: costData,
      yAxis: 0,
      borderWidth: 0,
      color: "#C5C5C5",
      zIndex: 5,
    },
    {
      name: "Total Requests",
      type: "line",
      data: requestData,
      yAxis: 1,
      color: "#434348",
      shadow: true,
      marker: {
        radius: 0,
      },
      zIndex: 7,

      // show data label of highest value
      // dataLabels: {
      //   enabled: true,
      //   formatter: function () {
      //     let dataMax = this.series.dataMax;
      //     if (dataMax > 0 && this.y === dataMax) {
      //       return `${this.y}%`;
      //     }
      //   },
      // },
    },
  ];

  return {
    seriesData,
  };
}

// --- Request Protection Index ---
// For "A/B Testing Tagged Ratio": 100 * protectedRequests / onboardedRequests
// For "Optimized Ratio": 100 * boostingRequests / protectedRequests
function _getRpiData(reports) {
  let ps = [];
  let ds = [];

  _.forEach(reports, (r) => {
    const date = new Date(r.date).getTime();
    const protectedScore =
      r.onboardedRequests > 0
        ? _.round((100 * r.protectedRequests) / r.onboardedRequests, 2)
        : 0;
    const defendedScore =
      r.protectedRequests > 0
        ? _.round((100 * r.boostingRequests) / r.protectedRequests, 2)
        : 0;

    ps.push({
      x: date,
      y: protectedScore,
      isPercentage: true,
    });

    ds.push({
      x: date,
      y: defendedScore,
      isPercentage: true,
    });
  });

  const seriesData = [
    {
      name: "A/B Testing Tagged Ratio",
      type: "line",
      data: ps,
      color: "#434348",
      shadow: true,
      marker: {
        enabled: false,
      },
    },
    {
      name: "Optimized Ratio",
      type: "line",
      data: ds,
      // color: "#345aef", /// dark blue
      color: "#ff993f",
      shadow: true,
      marker: {
        enabled: false,
      },
    },
  ];

  return { seriesData };
}

// --- Request Funnel ---
// For "Compatible Requests": totalRequests
// For "Protected Requests": protectedRequests
// For "Defended Requests": boostingRequests
function _getReqFunnelTrendData(reports) {
  let cReq = [];
  let pReq = [];
  let dReq = [];

  _.forEach(reports, (r) => {
    const date = new Date(r.date).getTime();

    cReq.push({
      x: date,
      y: r.onboardedRequests,
    });
    pReq.push({
      x: date,
      y: r.protectedRequests,
    });
    dReq.push({
      x: date,
      y: r.boostingRequests,
    });
  });

  const seriesData = [
    {
      name: "Onboarded Requests",
      type: "area",
      data: cReq,
      color: "#87c7f2",
      yAxis: 0,
      lineWidth: 0,
      marker: {
        enabled: false,
      },
    },
    {
      name: "A/B Testing Tagged Requests",
      type: "area",
      data: pReq,
      color: "#1D85FF", // blue
      yAxis: 0,
      lineWidth: 0,
      marker: {
        enabled: false,
      },
    },
    {
      name: "Optimized Requests",
      type: "area",
      data: dReq,
      color: "#ff993f",
      yAxis: 0,
      lineWidth: 0,
      marker: {
        enabled: false,
      },
    },
  ];
  return { seriesData };
}

// --- Revenue Lift ---
// For "Optimized Lift": 100 * (increasedRevenue - cost) / originalRevenue
function _getRevLiftTrendData(reports) {
  let sLifts = [];

  _.forEach(reports, (r) => {
    const date = new Date(r.date).getTime();
    const netIncreasedRev = r.increasedRevenue - r.cost;
    const securedLift =
      r.originalRevenue > 0
        ? _.round((100 * netIncreasedRev) / r.originalRevenue, 2)
        : 0;

    sLifts.push({
      x: date,
      y: securedLift,
      isPercentage: true,
    });
  });

  const seriesData = [
    {
      name: "Optimized Lift",
      type: "line",
      data: sLifts,
      color: "#ff993f", // orange
      marker: {
        enabled: false,
      },
    },
  ];

  return {
    seriesData,
  };
}

// --- Secured Request RPM ---
// For "Secured Request RPM": (increasedRevenue + originalRevenue) / (boostingRequests / 1000)
// For "Benchmark Request RPM": originalRevenue / (boostingRequests / 1000)
function _getSecuredGoogleRRpmTrendData(reports) {
  let sRrpm = [];
  let bRrpm = [];

  _.forEach(reports, (r) => {
    const date = new Date(r.date).getTime();
    const securedRRPM =
      r.boostingRequests > 0
        ? _.round(
            (r.increasedRevenue + r.originalRevenue) /
              (r.boostingRequests / 1000),
            2
          )
        : 0;
    const benchRRPM =
      r.boostingRequests > 0
        ? _.round(r.originalRevenue / (r.boostingRequests / 1000), 2)
        : 0;

    sRrpm.push({
      x: date,
      y: securedRRPM,
      needCurrency: true,
    });
    bRrpm.push({
      x: date,
      y: benchRRPM,
      needCurrency: true,
    });
  });

  const seriesData = [
    {
      name: "Optimized Request RPM",
      type: "line",
      data: sRrpm,
      color: "#ff993f", // orange
      marker: {
        enabled: false,
      },
    },
    {
      name: "Benchmark Request RPM",
      type: "line",
      data: bRrpm,
      color: "#1D85FF", // blue
      marker: {
        enabled: false,
      },
    },
  ];

  return {
    seriesData,
  };
}

function _getDefaultChartOptions(selectedCurrency) {
  const options = {
    title: {
      text: "",
    },

    chart: {
      // backgroundColor: "rgba(0,0,0,0)", // transparent
    },

    tooltip: {
      // zoomType: "x",
      backgroundColor: "rgba(255,255,255,1)",
      shared: true,
      useHTML: true,
      formatter: function () {
        return _createTooltipBodyFormat(this, selectedCurrency);
      },
    },
  };

  options.xAxis = {
    ...getXAxisConfigDateTime(),
  };

  options.legend = {
    layout: "horizontal",
    align: "center",
    verticalAlign: "bottom",

    // show square instead of circle
    symbolHeight: 12,
    symbolWidth: 12,
    symbolRadius: 0,

    itemStyle: {
      fontSize: 14,
    },
  };

  return options;
}

const currentDate = moment.utc().startOf("day").valueOf();
const blackSquareUnicode = "\u25A0";
const horizontalBarUnicode = "\u2015";
function _createTooltipBodyFormat(tooltip, selectedCurrency) {
  const headFormat = `<div style="font-size:14px; font-weight: bold; text-align: center;">${moment
    .utc(tooltip.x)
    .format("YYYY-MM-DD ddd")}${tooltip.x === currentDate ? "*" : ""}</div>`;

  let revBody = "";
  for (let point of tooltip.points) {
    revBody += `
      <tr style="font-size: larger;">
        <td style="padding-top: 4px; font-weight: bold; text-align: right;padding-right: 4px;">
          <span style="color:${point.color}">${
      point.series.userOptions.type === "line"
        ? horizontalBarUnicode
        : blackSquareUnicode
    }</span> ${point.series.name}: 
        </td>
        <td style="padding-top: 4px; text-align: right;">
          <b>
            ${
              point.point.needCurrency
                ? formatMoneyWithCurrency({
                    currency: selectedCurrency,
                    value: point.point.isAbsolute ? Math.abs(point.y) : point.y,
                  })
                : point.point.isPercentage
                ? `${Highcharts.numberFormat(point.y, 2)}%`
                : Highcharts.numberFormat(point.y, 0, ".", ",")
            }
          
          </b>
        </td>
      </tr>
    `;
  }

  const revSection = `
    <table style="font-size: 12px;">
      ${revBody}
    </table>
  `;

  return `${headFormat}${revSection}`;
}

function getXAxisConfigDateTime() {
  return {
    type: "datetime",
    labels: {
      formatter: function () {
        const numOfTicks = this.axis.tickPositions.length;

        const m = moment.utc(this.value);
        const d = m.format("DD");
        // const d = m.format("MM/DD");
        const wd = `${numOfTicks > 12 ? "" : m.format("ddd")}`;

        let dateLabel = d;
        // highlight current day
        if (this.value === currentDate) {
          dateLabel = `<span style="font-weight: bold; color: #b21111;">${d}*</span>`;
        } else if (isWeekend(this.value)) {
          dateLabel = `<span style="font-weight: bold; color: black;">${d}</span>`;
        }

        let labelHtml = `${dateLabel} <br/> ${wd}`;

        if (d === "01" || this.isFirst) {
          return `${labelHtml} <br/> <span style="color: #3182ce;	letter-spacing: 0.05em;">${m
            .format("MMM")
            .toUpperCase()}</span>`;
        }

        return labelHtml;
      },
    },
    tickInterval: 24 * 60 * 60 * 1000, // 1 day
    crosshair: {
      color: "#5C5C61", // dark gray
    },
    gridLineWidth: 1,
  };
}

(function (H) {
  /**
   * Override the reset function, we don't need to hide the tooltips and crosshairs.
   */
  H.Pointer.prototype.reset = function () {
    return undefined;
  };

  /**
   * Highlight a point by showing tooltip, setting hover state and draw crosshair
   */
  H.Point.prototype.highlight = function (event) {
    // event = this.series.chart.pointer.normalize(event);
    try {
      this.onMouseOver(); // Show the hover marker
      //this.series.chart.tooltip.refresh(this); // Show the tooltip
      this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
    } catch (err) {
      // console.log(this.series.chart);
      console.log(err);
    }
  };

  /**
   * Synchronize zooming through the setExtremes event handler.
   */
  // H.syncExtremes = function (e) {
  //   let thisChart = this.chart;

  //   if (e.trigger !== "syncExtremes") {
  //     // Prevent feedback loop
  //     Highcharts.each(Highcharts.charts, function (chart) {
  //       if (chart && chart !== thisChart) {
  //         if (chart.xAxis[0].setExtremes) {
  //           // It is null while updating
  //           chart.xAxis[0].setExtremes(e.min, e.max, undefined, false, {
  //             trigger: "syncExtremes",
  //           });
  //         }
  //       }
  //     });
  //   }
  // };
})(Highcharts);

export default TrendCombinedChartForCst;
