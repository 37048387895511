import React from "react";
import _ from "lodash";
import PriceTargetingSelector from "./PriceTargetingSelector";

const TARGETING_TYPE = {
  SIZES: "sizes",
};

const TARGETING_OP = {
  NONE: "NONE",
  INCLUDES: "includes",
  EXCLUDES: "excludes",
};

// type: country, os, device_category, hour, sizes
class PriceTargetingControl extends React.PureComponent {
  constructor(props) {
    super(props);
    // targetingType
    // targeting.includes
    // handleTargetingChanged

    const { targeting } = props;

    let radioValue = TARGETING_OP.NONE;
    let selectedItems = [];
    if (targeting) {
      if (targeting.includes) {
        radioValue = TARGETING_OP.INCLUDES;
        selectedItems = targeting.includes;
      } else {
        radioValue = TARGETING_OP.EXCLUDES;
        selectedItems = targeting.excludes;
      }
    }

    this.state = {
      radioValue,
      selectedItems,
    };

    this.handleChangeRadio = this.handleChangeRadio.bind(this);
    this.handleItemsChanged = this.handleItemsChanged.bind(this);
  }

  handleChangeRadio(value) {
    this.setState({
      radioValue: value,
    });

    if (value === TARGETING_OP.NONE) {
      this.props.handleTargetingChanged(null);
      this.setState({ selectedItems: [] });
    } else {
      const items = this.state.selectedItems;
      if (_.isEmpty(items)) {
        this.props.handleTargetingChanged(null);
      } else {
        let tt = {
          [value]: items,
        };
        this.props.handleTargetingChanged(tt);
      }
    }
  }

  handleItemsChanged(items) {
    const { radioValue } = this.state;
    // console.log("items changed", items, radioValue);
    if (radioValue !== TARGETING_OP.NONE) {
      if (_.isEmpty(items)) {
        this.props.handleTargetingChanged(null);
      } else {
        let tt = {
          [radioValue]: items,
        };
        this.props.handleTargetingChanged(tt);
      }
    }
  }

  render() {
    // type: country, os, device_category, hour
    const { targetingType, networkId } = this.props;
    const { radioValue, selectedItems } = this.state;

    let TargetingSelector = "";
    switch (targetingType) {
      case TARGETING_TYPE.SIZES: {
        TargetingSelector = (
          <PriceTargetingSelector
            networkId={networkId}
            // selectedItems={sizes}
            // handleChanged={this.handlePriceTargetingChanged}
            selectedItems={selectedItems}
            handleChanged={this.handleItemsChanged}
          ></PriceTargetingSelector>
        );
        break;
      }

      // case TARGETING_TYPE.BROWSER: {
      //   TargetingSelector = (
      //     <GamBrowserSelector
      //       selectedItems={selectedItems}
      //       handleChanged={this.handleItemsChanged}
      //     ></GamBrowserSelector>
      //   );
      //   break;
      // }

      default: {
        TargetingSelector = "Selector not implemented";
      }
    }

    return (
      <>
        <div className="flex items-center gap-4">
          <label
            htmlFor={`${targetingType}_radio_none`}
            className="cursor-pointer"
          >
            <input
              id={`${targetingType}_radio_none`}
              type="radio"
              name={`${targetingType}_radio`}
              value={TARGETING_OP.NONE}
              checked={radioValue === TARGETING_OP.NONE}
              onChange={() => this.handleChangeRadio(TARGETING_OP.NONE)}
            />{" "}
            None
          </label>

          <label
            htmlFor={`${targetingType}_radio_include`}
            className="cursor-pointer"
          >
            <input
              id={`${targetingType}_radio_include`}
              type="radio"
              name={`${targetingType}_radio`}
              value={TARGETING_OP.INCLUDES}
              checked={radioValue === TARGETING_OP.INCLUDES}
              onChange={() => this.handleChangeRadio(TARGETING_OP.INCLUDES)}
            />{" "}
            Includes
          </label>

          <label
            htmlFor={`${targetingType}_radio_exclude`}
            className="cursor-pointer"
          >
            <input
              id={`${targetingType}_radio_exclude`}
              type="radio"
              name={`${targetingType}_radio`}
              value={TARGETING_OP.EXCLUDES}
              checked={radioValue === TARGETING_OP.EXCLUDES}
              onChange={() => this.handleChangeRadio(TARGETING_OP.EXCLUDES)}
            />{" "}
            Excludes
          </label>
        </div>

        {radioValue !== TARGETING_OP.NONE && TargetingSelector}
      </>
    );
  }
}

export default PriceTargetingControl;
