import _ from "lodash";
import React from "react";
import { AutoPilotAPI, NetworkAPI } from "apis";
import BatchOfSegmentsFormatter from "./BatchOfSegmentsFormatter";
import NewSegmentControl from "./NewSegmentControl";
import { notify } from "react-notify-toast";

class BatchOfSegmentsControl extends React.PureComponent {
  constructor(props) {
    super(props);

    // inputValue: { batch_key, segments[] }
    // segments[]: [{ segment_key, targeting[] }]
    const { batch_key = "", segments = [] } = props.inputValue;
    const isEditBatch = !_.isEmpty(batch_key);
    this.state = {
      isEditBatch,
      originalBatchKey: batch_key, // to check if unique
      batchKey: batch_key,
      segments,
      currentSegment: {},

      isEditCardOpen: false,
    };

    this.handleClearBatch = this.handleClearBatch.bind(this);

    this.handleCancelAddNewSet = this.handleCancelAddNewSet.bind(this);
    this.handleAddNewSet = this.handleAddNewSet.bind(this);
    this.handleDuplicateSegment = this.handleDuplicateSegment.bind(this);
    this.handleArchiveSegment = this.handleArchiveSegment.bind(this);
    this.handleUnarchiveSegment = this.handleUnarchiveSegment.bind(this);
    this.handleEditSegment = this.handleEditSegment.bind(this);
    this.handleRemoveSegment = this.handleRemoveSegment.bind(this);

    this.handleOpenEditCard = this.handleOpenEditCard.bind(this);
    this.handleCloseEditCard = this.handleCloseEditCard.bind(this);

    this.handleBatchKeyChanged = this.handleBatchKeyChanged.bind(this);

    this.handleImportSegments = this.handleImportSegments.bind(this);
    this.handleImportUprSegments = this.handleImportUprSegments.bind(this);
  }

  async handleImportUprSegments() {
    const { unitId } = this.props;
    const r = await NetworkAPI.getNetworkUprSegmentationByUnitId({ unitId });
    if (_.isEmpty(r)) {
      // has no upr segments
      console.log("no upr segments");
      notify.show("No network UPR segments to import", "warning");
      return;
    }
    const segs = r.config;

    const segments = _.map(segs, (s) => {
      let targeting = {};
      // Important! add upr_segment_id!
      targeting.upr_segment_id = s.id;
      const { country, device_category, os, inventory_registry } = s.targeting;
      if (country) targeting.country = country;
      if (device_category) targeting.device_category = device_category;
      if (os) targeting.os = os;
      if (inventory_registry) targeting.inventory_registry = inventory_registry;
      return {
        segment_key: s.name || s.id.toString(),
        targeting,
        isEditable: true,
      };
    });

    this.setState({
      segments,
    });

    this.props.handleNewValueChanged({
      batch_key: this.state.batchKey,
      segments,
    });
  }

  async handleImportSegments() {
    const { unitId } = this.props;
    const segs = await AutoPilotAPI.getSegmentsByUnit({ unitId });

    const segments = _.map(segs, (s) => {
      return {
        segment_key: s.intention || s.segmentId.toString(),
        targeting: s.targeting,
        isEditable: true,
      };
    });

    this.setState({
      segments,
    });

    this.props.handleNewValueChanged({
      batch_key: this.state.batchKey,
      segments,
    });
  }

  handleClearBatch() {
    this.setState({
      batchKey: "",
      segments: [],
      isEditBatch: false,
      currentSegment: {},
      isEditCardOpen: false,
    });
  }

  handleOpenEditCard() {
    this.setState({
      isEditCardOpen: true,
    });
  }

  handleCloseEditCard() {
    this.setState({
      isEditCardOpen: false,
    });
  }

  handleBatchKeyChanged(e) {
    const batchKey = e.target.value;
    this.setState({ batchKey });
    this.props.handleNewValueChanged({
      batch_key: batchKey,
      segments: this.state.segments,
    });
  }

  handleCancelAddNewSet() {
    this.setState({
      currentSegment: [],
      isEditCardOpen: false,
    });
  }

  handleAddNewSet(newSet) {
    console.log("Add new SEt", newSet);
    newSet.isEditable = true;
    const setsOfSegments = [...this.state.segments, newSet];

    this.setState({
      currentSegment: [],
      segments: setsOfSegments,
      isEditCardOpen: false,
    });

    this.props.handleNewValueChanged({
      batch_key: this.state.batchKey,
      segments: setsOfSegments,
    });
  }

  handleDuplicateSegment(segmentKey) {
    console.log("duplicate set", segmentKey);
    const seg = _.find(this.state.segments, { segment_key: segmentKey });
    this.setState({
      currentSegment: { ...seg, segment_key: seg.segment_key + "__COPY" },
      isEditCardOpen: true,
    });
  }

  handleArchiveSegment(segmentKey) {
    const segs = _.map(this.state.segments, (s) => {
      if (segmentKey === s.segment_key) {
        s.deprecated = true;
      }
      return s;
    });

    this.setState({ segments: segs });

    this.props.handleNewValueChanged({
      batch_key: this.state.batchKey,
      segments: segs,
    });
  }

  handleUnarchiveSegment(segmentKey) {
    const segs = _.map(this.state.segments, (s) => {
      if (segmentKey === s.segment_key) {
        s.deprecated = false;
      }
      return s;
    });

    this.setState({ segments: segs });

    this.props.handleNewValueChanged({
      batch_key: this.state.batchKey,
      segments: segs,
    });
  }

  handleEditSegment(segmentKey) {
    console.log("edit set", segmentKey);
    const seg = _.find(this.state.segments, { segment_key: segmentKey });
    this.setState({
      currentSegment: { ...seg },
      isEditCardOpen: true,
    });

    this.handleRemoveSegment(segmentKey);
  }

  handleRemoveSegment(segmentKey) {
    const segs = _.filter(this.state.segments, (s) => {
      return s.segment_key !== segmentKey;
    });

    this.setState({ segments: segs });

    this.props.handleNewValueChanged({
      batch_key: this.state.batchKey,
      segments: segs,
    });
  }

  render() {
    const {
      isEditBatch,
      batchKey,
      segments,
      originalBatchKey,

      currentSegment,
      isEditCardOpen,
    } = this.state;
    const { networkId } = this.props;

    const activeSegments = _.filter(segments, (s) => {
      return !s.deprecated;
    });

    return (
      <div className="ml-4 text-sm">
        <div className="border border-gray-600 p-1">
          <div className="mb-4">
            <div>
              <div className="font-semibold">Batch Key:</div>
              {isEditBatch ? (
                <div>{batchKey}</div>
              ) : (
                <div>
                  {originalBatchKey && batchKey === originalBatchKey && (
                    <div className="text-red-600">
                      Cannot be the same as the previous batch key
                    </div>
                  )}
                  <input
                    type="text"
                    value={batchKey}
                    onChange={this.handleBatchKeyChanged}
                    required
                  ></input>
                </div>
              )}
            </div>

            <div className="mt-2">
              <div className="font-semibold">
                Segments ({activeSegments.length}):
              </div>
              {segments.length === 0 ? (
                "None"
              ) : (
                <BatchOfSegmentsFormatter
                  includeBatchKey={false}
                  batchSegments={{ segments }}
                  showActionButtons={true}
                  isEditCardOpen={isEditCardOpen}
                  handleDuplicateSegment={this.handleDuplicateSegment}
                  handleArchiveSegment={this.handleArchiveSegment}
                  handleUnarchiveSegment={this.handleUnarchiveSegment}
                  handleEditSegment={this.handleEditSegment}
                  handleRemoveSegment={this.handleRemoveSegment}
                ></BatchOfSegmentsFormatter>
              )}
            </div>

            <div>
              {!isEditBatch && segments.length === 0 && (
                <button
                  type="button"
                  className="rounded shadow bg-blue-100 px-2 py-1 font-semibold text-blue-600 hover:bg-blue-200"
                  onClick={this.handleImportSegments}
                >
                  Import from existing segments
                </button>
              )}

              {!isEditBatch && segments.length === 0 && (
                <button
                  type="button"
                  className="rounded shadow bg-blue-100 px-2 py-1 font-semibold text-blue-600 hover:bg-blue-200"
                  onClick={this.handleImportUprSegments}
                >
                  Import from UPR segments
                </button>
              )}

              <button
                type="button"
                className="rounded shadow bg-blue-100 px-2 py-1 font-semibold text-blue-600 hover:bg-blue-200"
                onClick={this.handleOpenEditCard}
              >
                Create new segment
              </button>
            </div>
          </div>

          {isEditCardOpen && (
            <NewSegmentControl
              networkId={networkId}
              currentSegment={currentSegment}
              handleAddNewSet={this.handleAddNewSet}
              handleCancelAddNewSet={this.handleCancelAddNewSet}
              segments={segments}
            ></NewSegmentControl>
          )}
        </div>
        <div>
          <button
            type="button"
            className="rounded shadow bg-red-100 px-2 py-1 text-red-600 hover:bg-red-200"
            onClick={this.handleClearBatch}
          >
            Recreate new batch
          </button>
        </div>
      </div>
    );
  }
}

export default BatchOfSegmentsControl;
