export const inputClass =
  "bg-white flex w-full border border-gray-400 rounded py-1 px-3 appearance-none leading-normal focus:outline-none focus:border-blue-400 focus:shadow-inner hover:border-gray-500";
export const inputClassDisabled =
  "bg-gray-300 flex w-full border border-gray-400 rounded py-1 px-3 appearance-none leading-normal";

export const titleClass = "block text-gray-800 font-bold mb-1"; //"font-semibold";

export const buttonConfirmClass =
  "my-2 text-blue-900 rounded shadow font-semibold bg-blue-200 hover:bg-blue-300 px-4 py-2 text-sm overflow-x-auto";

export const buttonActionClass =
  "px-2 py-2 mr-1 my-1 bg-blue-400 text-xs rounded shadow hover:bg-blue-600 text-white font-semibold";

export const buttonCancelClass = "mb-2 px-4 py-1 text-blue-700 hover:underline";

export const buttonActionRedClass =
  "px-2 py-2 mr-1 my-1 bg-red-400 text-xs rounded shadow hover:bg-red-600 text-white font-semibold";

export const thClass =
  "px-4 py-2 text-gray-600 text-left text-xs tracking-wider uppercase";
export const tdClass = "px-4 py-2 border";
