import React from "react";
import _ from "lodash";
import DomainSpecificManagedRatioModal from "./DomainSpecificManagedRatioModal";
import ManagedRatioChangedAlert from "./ManagedRatioChangedAlert";

const titleClass = "block text-gray-800 text-sm font-bold"; //"font-semibold";
const inputClass =
  "bg-white flex border border-gray-400 rounded py-1 px-3 appearance-none leading-normal focus:outline-none focus:border-blue-400 focus:shadow-inner hover:border-gray-500";

class AmpRtcSection extends React.PureComponent {
  constructor(props) {
    super(props);

    const {
      enabled,
      managedRatio,
      subdomainName,
      domainSpecificManagedRatios,
    } = this.props;
    this.state = {
      enabled,
      managedRatio: _.get(this.props, "managedRatio", 0.5), // could be 0!
      subdomainName: subdomainName || "",
      domainSpecificManagedRatios: domainSpecificManagedRatios || [],

      managedRatioErr: null,
      subdomainNameErr: null,
      domainSpecificManagedRatiosErr: null,

      // to alert change at midnight
      isManagedRatioDirty: false,

      editIndex: null,
      isModalOpen: false,
      currentDomainSetting: {},
    };

    this.handleChanged = this.handleChanged.bind(this);
    this.handleSaveDomainSetting = this.handleSaveDomainSetting.bind(this);

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);

    this.handleOpenEdit = this.handleOpenEdit.bind(this);
    this.handleOpenDuplicate = this.handleOpenDuplicate.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleMoveUp = this.handleMoveUp.bind(this);
    this.handleMoveDown = this.handleMoveDown.bind(this);
  }

  handleOpenEdit(setting, index) {
    this.setState({
      isModalOpen: true,
      currentDomainSetting: setting,
      editIndex: index,
    });
  }

  handleOpenDuplicate(setting) {
    this.setState({ isModalOpen: true, currentDomainSetting: setting });
  }

  handleRemove(index) {
    const {
      enabled,
      managedRatio,
      subdomainName,
      domainSpecificManagedRatios,
    } = this.state;

    let newSettings = _.cloneDeep(domainSpecificManagedRatios) || [];
    newSettings.splice(index, 1);
    let ampRtc = {
      enabled,
      managedRatio,
      subdomainName,
    };
    ampRtc["domainSpecificManagedRatios"] = newSettings;
    this.props.handleAmpRtcChanged(ampRtc);
    this.setState({ domainSpecificManagedRatios: newSettings });
  }

  handleMoveUp(index) {
    if (index === 0) return;
    const {
      enabled,
      managedRatio,
      subdomainName,
      domainSpecificManagedRatios,
    } = this.state;

    const from = index;
    const to = index - 1;
    let newSettings = _.cloneDeep(domainSpecificManagedRatios) || [];
    let f = newSettings.splice(from, 1)[0];
    newSettings.splice(to, 0, f);

    let ampRtc = {
      enabled,
      managedRatio,
      subdomainName,
    };
    ampRtc["domainSpecificManagedRatios"] = newSettings;
    this.props.handleAmpRtcChanged(ampRtc);
    this.setState({ domainSpecificManagedRatios: newSettings });
  }

  handleMoveDown(index) {
    const {
      enabled,
      managedRatio,
      subdomainName,
      domainSpecificManagedRatios,
    } = this.state;
    if (index === domainSpecificManagedRatios.length) return;

    const from = index;
    const to = index + 1;
    let newSettings = _.cloneDeep(domainSpecificManagedRatios) || [];
    let f = newSettings.splice(from, 1)[0];
    newSettings.splice(to, 0, f);

    let ampRtc = {
      enabled,
      managedRatio,
      subdomainName,
    };
    ampRtc["domainSpecificManagedRatios"] = newSettings;
    this.props.handleAmpRtcChanged(ampRtc);
    this.setState({ domainSpecificManagedRatios: newSettings });
  }

  handleOpenModal() {
    this.setState({ isModalOpen: true });
  }

  handleCloseModal() {
    this.setState({
      isModalOpen: false,
      editIndex: null,
      currentDomainSetting: {},
    });
  }

  checkIsInvalid({ key, value }) {
    let isInvalid = false;
    if (key === "managedRatio") {
      if (value > 1) {
        isInvalid = true;
        this.setState({ managedRatioErr: "Cannot be larger than 1" });
      }
      if (value < 0) {
        isInvalid = true;
        this.setState({ managedRatioErr: "Cannot be less than 0" });
      }
    }

    if (key === "subdomainName") {
      if (value === "") {
        isInvalid = true;
        this.setState({ subdomainNameErr: "Cannot be empty" });
      }
    }

    // TODO
    if (key === "domainSpecificManagedRatios") {
      if (value === "") {
        isInvalid = true;
        this.setState({ subdomainNameErr: "Cannot be empty" });
      }
    }

    if (isInvalid === false) {
      this.setState({ managedRatioErr: null, subdomainNameErr: null });
    }

    return isInvalid;
  }

  handleChanged({ key, value }) {
    this.checkIsInvalid({ key, value });

    if (key === "managedRatio") {
      this.setState({
        isManagedRatioDirty: true,
      });
    }

    if (key === "enabled" && value === false) {
      // clear the other key values
      let ampRtc = {
        enabled: false,
        managedRatio: "",
        subdomainName: "",
        domainSpecificManagedRatios: [],
      };

      this.setState(ampRtc);
      this.props.handleAmpRtcChanged(ampRtc);
    } else {
      this.setState({
        [key]: value,
      });

      const {
        enabled,
        managedRatio,
        subdomainName,
        domainSpecificManagedRatios,
      } = this.state;
      let ampRtc = {
        enabled,
        managedRatio,
        subdomainName,
        domainSpecificManagedRatios,
      };
      ampRtc[key] = value;
      this.props.handleAmpRtcChanged(ampRtc);
    }
  }

  // add (duplicate), edit: index
  handleSaveDomainSetting(newSetting) {
    // console.log("handleSaveDomainSetting", newSetting);

    const {
      enabled,
      managedRatio,
      subdomainName,
      domainSpecificManagedRatios,
      editIndex,
    } = this.state;

    let newSettings = _.cloneDeep(domainSpecificManagedRatios) || [];
    if (editIndex !== null) {
      newSettings[editIndex] = newSetting;
    } else {
      newSettings.push(newSetting);
    }
    let ampRtc = {
      enabled,
      managedRatio,
      subdomainName,
    };
    ampRtc["domainSpecificManagedRatios"] = newSettings;
    // console.log(ampRtc);
    this.props.handleAmpRtcChanged(ampRtc);
    this.setState({ domainSpecificManagedRatios: newSettings });
    this.handleCloseModal();
  }

  render() {
    const { networkInfo } = this.props;
    const {
      enabled,
      managedRatio,
      subdomainName,
      domainSpecificManagedRatios,
      isManagedRatioDirty,
      managedRatioErr,
      subdomainNameErr,
      domainSpecificManagedRatiosErr,
      isModalOpen,
      currentDomainSetting,
      editIndex,
    } = this.state;

    return (
      <>
        <div className="pt-4 text-xl font-bold text-gray-800">AMP RTC</div>
        <div className="mb-4">
          <label className={titleClass}>Enabled: </label>
          <div className="flex gap-4">
            <label htmlFor="general_amp_rtc_radio_" className="cursor-pointer">
              <input
                id="general_amp_rtc_radio_"
                type="radio"
                value="true"
                checked={enabled === true}
                onChange={() =>
                  this.handleChanged({
                    key: "enabled",
                    value: true,
                  })
                }
                required
              />{" "}
              True
            </label>

            <label
              htmlFor="general_amp_rtc_radio_false"
              className="cursor-pointer"
            >
              <input
                id="general_amp_rtc_radio_false"
                type="radio"
                value="false"
                checked={enabled === false}
                onChange={() =>
                  this.handleChanged({
                    key: "enabled",
                    value: false,
                  })
                }
                required
              />{" "}
              False
            </label>
          </div>
        </div>

        {enabled && (
          <>
            <div className="mb-4">
              <label className={titleClass}>Default Managed Ratio: </label>

              <input
                className={inputClass + " w-1/4"}
                type="number"
                value={managedRatio}
                onChange={(e) => {
                  return this.handleChanged({
                    key: "managedRatio",
                    value: !_.isEmpty(e.target.value)
                      ? parseFloat(e.target.value)
                      : e.target.value,
                  });
                }}
                min={0}
                max={1}
                step={0.1}
              ></input>

              {isManagedRatioDirty && (
                <ManagedRatioChangedAlert
                  networkInfo={networkInfo}
                ></ManagedRatioChangedAlert>
              )}

              <div className="my-1 bg-red-100 text-red-700">
                {managedRatioErr}
              </div>
            </div>

            <div className="mb-4">
              <label className={titleClass}>Subdomain Name:</label>
              <div className="text-sm text-gray-600">
                {`${
                  subdomainName ? subdomainName : "{subdomain_name}"
                }.yieldbooster.io`}
              </div>

              <input
                className={inputClass + " w-1/2"}
                type="text"
                value={subdomainName}
                onChange={(e) =>
                  this.handleChanged({
                    key: "subdomainName",
                    value: e.target.value,
                  })
                }
              ></input>

              <div className="my-1 bg-red-100 text-red-700">
                {subdomainNameErr}
              </div>
            </div>

            <hr></hr>

            <div className="mb-4">
              <div className="mt-4">
                <button
                  type="button"
                  className="rounded shadow mb-2 bg-blue-100 px-2 py-1 text-sm text-blue-600 hover:bg-blue-200"
                  onClick={this.handleOpenModal}
                >
                  Add Domain Specific Managed Ratio
                </button>

                {isModalOpen && (
                  <DomainSpecificManagedRatioModal
                    isModalOpen={isModalOpen}
                    networkInfo={networkInfo}
                    handleCloseModal={this.handleCloseModal}
                    handleSave={this.handleSaveDomainSetting}
                    domainSpecificSetting={currentDomainSetting}
                    allDomainSettings={domainSpecificManagedRatios}
                    isEdit={editIndex !== null}
                  ></DomainSpecificManagedRatioModal>
                )}
              </div>

              {domainSpecificManagedRatios &&
                domainSpecificManagedRatios.length > 0 && (
                  <>
                    <label className={titleClass}>
                      Domain Specific Managed Ratios:
                    </label>

                    <DomainSpecificManagedRatioTable
                      items={domainSpecificManagedRatios}
                      handleOpenEdit={this.handleOpenEdit}
                      handleOpenDuplicate={this.handleOpenDuplicate}
                      handleRemove={this.handleRemove}
                      handleMoveUp={this.handleMoveUp}
                      handleMoveDown={this.handleMoveDown}
                    ></DomainSpecificManagedRatioTable>
                  </>
                )}
            </div>
          </>
        )}
      </>
    );
  }
}

const MATCH_TYPE_NAME = {
  str: "Full Match",
  regex: "Regular Expression",
};

function DomainSpecificManagedRatioTable({
  items,
  handleOpenEdit,
  handleOpenDuplicate,
  handleRemove,
  handleMoveUp,
  handleMoveDown,
}) {
  return (
    <>
      <table className="border shadow table w-full text-sm">
        <thead className="border-b bg-gray-200 text-xs text-blue-800">
          <tr>
            <th className="border py-1 px-2 text-right">Priority</th>
            <th className="border py-1 px-2 text-right">Match Type</th>
            <th className="border py-1 px-2 text-right ">Domain</th>
            <th className="border py-1 px-2 text-right ">Manage Ratio</th>
            <th className="border py-1 px-2 text-right ">Actions</th>
          </tr>
        </thead>
        <tbody className="bg-white  text-gray-900">
          {items.map((item, index) => {
            return (
              <tr key={index} className="border-b hover:bg-gray-100">
                <td className="border py-1 px-2 text-right">{index + 1}</td>

                <td className="border py-1 px-2 text-right">
                  {MATCH_TYPE_NAME[item.match_type]}
                </td>

                <td className="border py-1 px-2 text-right font-medium">
                  {item.domain}
                </td>

                <td className="border py-1 px-2 text-right">
                  {item.managed_ratio}
                </td>

                <td className="whitespace-no-wrap border py-1 px-2 text-right">
                  <div className="flex items-center justify-end gap-4">
                    <button
                      type="button"
                      className="text-blue-600 hover:underline"
                      onClick={() => handleOpenEdit(item, index)}
                    >
                      Edit
                    </button>
                    <button
                      type="button"
                      className="text-blue-600 hover:underline"
                      onClick={() => handleOpenDuplicate(item)}
                    >
                      Duplicate
                    </button>
                    <button
                      type="button"
                      className="text-blue-600 hover:underline"
                      onClick={() => handleRemove(index)}
                    >
                      Remove
                    </button>
                    <button
                      type="button"
                      className={`hover:underline ${
                        index === 0
                          ? "cursor-not-allowed text-blue-100"
                          : "text-blue-600"
                      }`}
                      onClick={() => handleMoveUp(index)}
                      disabled={index === 0}
                    >
                      Move up
                    </button>
                    <button
                      type="button"
                      // className="text-blue-600 hover:underline"
                      className={`hover:underline ${
                        index === items.length - 1
                          ? "cursor-not-allowed text-blue-100"
                          : "text-blue-600"
                      } `}
                      onClick={() => handleMoveDown(index)}
                      disabled={index === items.length - 1}
                    >
                      Move down
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default AmpRtcSection;
