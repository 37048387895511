import React from "react";
import _ from "lodash";
import NumberFormat from "../../common/NumberFormat";
import { getWeekdayAbbr } from "../../../helpers/DateTransform";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { demandTypeIndex } from "../../../constants/DemandType";

class TableRequestSizesByGroupType extends React.Component {
  constructor(props) {
    super(props);

    const { sizesReports, selectedDate } = props;

    const dates = _(sizesReports)
      .map("date")
      .uniq()
      .sort()
      .value();

    const reports = this.filterReports(selectedDate);

    this.state = {
      dates,

      selectedDate: selectedDate,
      nextDate: getNextDate(dates, selectedDate),
      prevDate: getPreviousDate(dates, selectedDate),
      reports
    };

    this.handleSelectPreviousDate = this.handleSelectPreviousDate.bind(this);
    this.handleSelectNextDate = this.handleSelectNextDate.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedDate !== this.props.selectedDate) {
      this.selectDiffDate(this.props.selectedDate);
    }
  }

  filterReports(date) {
    return _(this.props.sizesReports)
      .filter(r => {
        return r.date === date;
      })
      .orderBy(
        ["request", "requestedSizes", "groupType"],
        ["desc", "asc", "asc"]
      )
      .value();
  }

  selectDiffDate(diffDate) {
    this.props.handleSelectDate(diffDate);

    this.setState({
      selectedDate: diffDate,
      nextDate: getNextDate(this.state.dates, diffDate),
      prevDate: getPreviousDate(this.state.dates, diffDate),
      reports: this.filterReports(diffDate)
    });
  }

  handleSelectPreviousDate() {
    const diffDate = this.state.prevDate;
    this.selectDiffDate(diffDate);
  }

  handleSelectNextDate() {
    const diffDate = this.state.nextDate;
    this.selectDiffDate(diffDate);
  }

  render() {
    const { reports, prevDate, nextDate, selectedDate } = this.state;

    const weekendAbbr = getWeekdayAbbr(selectedDate);

    return (
      <>
        {selectedDate ? (
          <div id="ad-sizes-distribution-table" className="min-h-screen">
            <div className="py-2 border-b border-gray-400">
              <div className="flex justify-between items-center">
                <div className="font-bold text-gray-700 text-xl mr-2">
                  {selectedDate} {weekendAbbr}
                </div>

                <div className="flex">
                  <button
                    type="button"
                    disabled={!!!prevDate}
                    className={`px-6 py-2 rounded shadow mr-1 ${
                      !!prevDate
                        ? "bg-indigo-300 hover:bg-indigo-400 cursor-pointer"
                        : "bg-gray-400 cursor-not-allowed"
                    }`}
                    onClick={this.handleSelectPreviousDate}
                  >
                    <FiChevronLeft></FiChevronLeft>
                  </button>
                  <button
                    type="button"
                    disabled={!!!nextDate}
                    className={`px-6 py-2 rounded shadow ${
                      !!nextDate
                        ? "bg-indigo-300 hover:bg-indigo-400 cursor-pointer"
                        : "bg-gray-400 cursor-not-allowed"
                    }`}
                    onClick={this.handleSelectNextDate}
                  >
                    <FiChevronRight></FiChevronRight>
                  </button>
                </div>
              </div>
            </div>

            <TableGroupByRequestSizes
              reports={reports}
            ></TableGroupByRequestSizes>
          </div>
        ) : (
          <div>Select a date from the table above</div>
        )}
      </>
    );
  }
}

class TableGroupByRequestSizes extends React.PureComponent {
  render() {
    const { reports, hoveredSize, handleHighlightRow } = this.props;

    const reportsGroupBySizes = _.groupBy(reports, "requestedSizes");
    const sizes = _.keys(reportsGroupBySizes);

    return (
      <>
        <div className="text-sm text-gray-700 py-2">
          {sizes.length} groups of different request sizes
        </div>

        {sizes.map(size => {
          const gr = reportsGroupBySizes[size];
          const totalRequestRatio = _.sumBy(gr, "requestRatio");

          return (
            <div key={size} className="flex mb-2">
              <div className="p-4 text-right" style={{ width: "12%" }}>
                <span className="block uppercase text-xs text-gray-600 font-semibold">
                  Request Ratio
                </span>
                <div className="font-semibold text-gray-800">
                  {_.isNumber(totalRequestRatio) ? (
                    <NumberFormat
                      value={totalRequestRatio}
                      format="0,0.00%"
                    ></NumberFormat>
                  ) : (
                    "-"
                  )}
                </div>
              </div>

              <div
                className="border-b p-4 bg-white rounded shadow-md"
                style={{ width: "88%" }}
              >
                <div className="">
                  <span className="block uppercase text-xs text-gray-600 font-semibold">
                    Requested Sizes
                  </span>
                  <div className="font-semibold text-gray-800">{size}</div>
                </div>

                <div className="mt-2">
                  <div className="text-sm text-gray-700">Total:</div>
                  <RequestSizesTable
                    reports={gr}
                    hoveredSize={hoveredSize}
                    handleHighlightRow={handleHighlightRow}
                    type="TOTAL"
                  ></RequestSizesTable>
                  <br></br>

                  <div className="text-sm text-gray-700">Demand Types:</div>
                  <RequestSizesTable
                    reports={gr}
                    hoveredSize={hoveredSize}
                    handleHighlightRow={handleHighlightRow}
                    type="DEMAND_TYPES"
                  ></RequestSizesTable>
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  }
}

class RequestSizesTable extends React.PureComponent {
  render() {
    let { reports, type } = this.props;
    reports = _.sortBy(reports, "groupType");
    const demandTypes = getDemandTypes(reports);

    if (type === "TOTAL") {
      return (
        <div className="w-full overflow-x-auto">
          <table
            className="w-full table-fixed text-sm"
            style={{ width: "100%" }}
          >
            <thead className="bg-gray-200 text-blue-800 text-xs border-b">
              <tr>
                <th
                  rowSpan="2"
                  className="text-center border-r-2 border-gray-400"
                  style={{ width: "60px" }}
                >
                  Group
                </th>
                <th rowSpan="2" className="text-right py-1 px-2">
                  Request
                </th>

                <th className="text-right py-1 px-2">STR*</th>
                <th className="text-right py-1 px-2">eCPM*</th>
                <th className="text-right py-1 px-2">rRPM*</th>
                <th className="text-right py-1 px-2">Imp*</th>
                <th className="text-right py-1 px-2">Rev*</th>
                <th className="text-right py-1 px-2 border-r-2 border-gray-400 last:border-r-0">
                  AdX View%
                </th>
              </tr>
            </thead>
            <tbody className="text-gray-900 bg-white">
              {reports.map((r, i) => {
                const rowStyle = getGroupTypeRowStyle(r.groupType);
                return (
                  <tr
                    key={i}
                    className="border-b hover:bg-gray-100"
                    style={rowStyle}
                  >
                    <td className="text-center py-1 px-2 border-r-2 border-gray-400">
                      <GroupTypeAbbr groupType={r.groupType}></GroupTypeAbbr>
                    </td>
                    <td className="text-right font-mono text-gray-800 py-1 px-2">
                      {_.isNumber(r.request) ? (
                        <NumberFormat
                          value={r.request}
                          format="0,0"
                        ></NumberFormat>
                      ) : (
                        "-"
                      )}
                    </td>

                    <td className="text-right font-bold font-mono text-gray-800 py-1 px-2">
                      {_.isNumber(r.eligibleStr) ? (
                        <NumberFormat
                          value={r.eligibleStr}
                          format="0,0.00%"
                        ></NumberFormat>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="text-right font-bold font-mono text-gray-800 py-1 px-2">
                      {_.isNumber(r.eligibleEcpm) ? (
                        <NumberFormat
                          value={r.eligibleEcpm}
                          format="$0,0.00"
                        ></NumberFormat>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="text-right font-bold font-mono text-gray-800 py-1 px-2">
                      {_.isNumber(r.eligibleRpm) ? (
                        <NumberFormat
                          value={r.eligibleRpm}
                          format="$0,0.00"
                        ></NumberFormat>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="text-right font-mono text-gray-800 py-1 px-2">
                      {_.isNumber(r.eligibleImpression) ? (
                        <NumberFormat
                          value={r.eligibleImpression}
                          format="0,0"
                        ></NumberFormat>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="text-right font-mono text-gray-800 py-1 px-2">
                      {_.isNumber(r.eligibleRevenue) ? (
                        <NumberFormat
                          value={r.eligibleRevenue}
                          format="$0,0.00"
                        ></NumberFormat>
                      ) : (
                        "-"
                      )}
                    </td>

                    <td className="text-right font-mono text-gray-800 py-1 px-2 border-r-2 border-gray-400 last:border-r-0">
                      {_.isNumber(r.adxViewability) ? (
                        <NumberFormat
                          value={r.adxViewability}
                          format="0,0.00%"
                        ></NumberFormat>
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    }

    if (type === "DEMAND_TYPES") {
      return (
        <div className="w-full overflow-x-auto">
          <table className="text-sm">
            <thead className="bg-gray-200 text-blue-800 text-xs border-b">
              <tr>
                <th
                  rowSpan="2"
                  className="text-center border-r-2 border-gray-400"
                  style={{ width: "60px" }}
                >
                  Group
                </th>

                {demandTypes.map(type => {
                  return (
                    <th
                      key={type}
                      colSpan="2"
                      // colSpan="3"
                      className="text-center py-1 px-4 border-r-2 border-gray-400 last:border-r-0 bg-indigo-100"
                      // style={{ width: "260px" }}
                      // style={{ width: "10%" }}
                    >
                      {type}
                    </th>
                  );
                })}
              </tr>
              <tr>
                {demandTypes.map(type => {
                  return (
                    <React.Fragment key={type}>
                      <th className="text-right py-1 px-4 bg-indigo-100">
                        Imp
                      </th>
                      <th className="text-right py-1 px-4 border-r-2 border-gray-400 last:border-r-0 bg-indigo-100">
                        eCPM
                      </th>
                    </React.Fragment>
                  );
                })}
              </tr>
            </thead>
            <tbody className="text-gray-900 bg-white">
              {reports.map((r, i) => {
                const rowStyle = getGroupTypeRowStyle(r.groupType);

                return (
                  <tr
                    key={i}
                    className="border-b hover:bg-gray-100"
                    style={rowStyle}
                  >
                    <td className="text-center py-1 px-2 border-r-2 border-gray-400">
                      <GroupTypeAbbr groupType={r.groupType}></GroupTypeAbbr>
                    </td>

                    {demandTypes.map(type => {
                      const dtr = _.get(r.demandTypes, type, {});

                      return (
                        <React.Fragment key={type}>
                          <td className="text-right font-mono py-1 px-2">
                            <div className="text-gray-600 flex justify-between">
                              <div className="w-1/2 mr-2">
                                (
                                {_.isNumber(dtr.impressionRatio) ? (
                                  <NumberFormat
                                    value={dtr.impressionRatio}
                                    format="0,0%"
                                  ></NumberFormat>
                                ) : (
                                  "-"
                                )}
                                )
                              </div>
                              <div className="w-1/2">
                                {_.isNumber(dtr.impression) ? (
                                  <NumberFormat
                                    value={dtr.impression}
                                    format="0,0"
                                  ></NumberFormat>
                                ) : (
                                  "-"
                                )}
                              </div>
                            </div>
                          </td>
                          <td className="text-right font-mono py-1 px-4 border-r-2 border-gray-400 last:border-r-0">
                            {_.isNumber(dtr.ecpm) ? (
                              <NumberFormat
                                value={dtr.ecpm}
                                format="$0,0.00"
                              ></NumberFormat>
                            ) : (
                              "-"
                            )}
                          </td>
                        </React.Fragment>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    }
  }
}

function getDemandTypes(reports) {
  let demandTypes = [];
  _.forEach(reports, report => {
    demandTypes.push(_.keys(report.demandTypes));
  });
  demandTypes = _(demandTypes)
    .flatten()
    .uniq()
    .sortBy(dt => {
      return demandTypeIndex[_.camelCase(dt)];
    })
    .value();
  return demandTypes;
}

class GroupTypeAbbr extends React.PureComponent {
  render() {
    const { groupType } = this.props;
    const abbr = _.upperCase(_.first(groupType));
    // const groupTypeStyle = getGroupTypeStyle(groupType);

    return <span className="rounded px-2 font-mono font-bold">{abbr}</span>;
  }
}

// function getGroupTypeStyle(groupType) {
//   switch (groupType) {
//     case "benchmark": {
//       return { backgroundColor: "#d4d3ff" };
//     }
//     case "optimization": {
//       return { backgroundColor: "#d4d3ff" };
//     }
//     case "unmanaged": {
//       return { backgroundColor: "#EDF2F7" };
//     }
//     default: {
//       nothin;
//     }
//   }
// }

function getGroupTypeRowStyle(groupType) {
  switch (groupType) {
    case "benchmark": {
      return { backgroundColor: "#e7e7ff" };
    }
    case "optimization": {
      return { backgroundColor: "#d7f5e5" };
    }
    case "unmanaged": {
      return { backgroundColor: "#F7FAFC" };
    }
    default: {
      // nothin
    }
  }
}

function getNextDate(dates, targetDate) {
  const index = _.indexOf(dates, targetDate);

  // has no next date
  if (index === dates.length - 1) {
    return null;
  } else {
    return dates[index + 1];
  }
}

function getPreviousDate(dates, targetDate) {
  const index = _.indexOf(dates, targetDate);

  // has no previous date
  if (index === 0) {
    return null;
  } else {
    return dates[index - 1];
  }
}

export default TableRequestSizesByGroupType;
