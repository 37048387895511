import React, { useState } from "react";
import _ from "lodash";
import { AiFillCaretRight } from "react-icons/ai";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import BetterPopupWrapper from "../common/BetterPopupWrapper";
import NumberFormat from "../common/NumberFormat";
import ScrollToTop from "components/common/ScrollToTop";

const TABS = {
  BM: "BM",
  OPT_W: "OPT_W", // Weighted
  OPT_NW: "OPT_NW", // None Weighted
  INVALID_REF_GROUPS: "INVALID_REF_GROUPS", // Invalid reference groups
};

class MicroExpView extends React.Component {
  constructor(props) {
    super(props);

    const { new_opt_groups, ref_groups } = props.exp;
    // Important! Sort groups by weight
    // 1. Group into withWeight, withoutWeight
    // 2. Sort groups by weight
    let groupsWithWeight = _.filter(new_opt_groups, (g) => g.weight > 0);
    groupsWithWeight = _.sortBy(groupsWithWeight, [
      function (g) {
        return g.weight === null ? 0 : -g.weight;
      },
    ]);
    const groupsWithoutWeight = _.filter(
      new_opt_groups,
      (g) => g.weight == null
    );
    const groupsInvalidRefs = _.filter(ref_groups.groups, (g) => {
      return !_.isEmpty(g.invalid_reasons);
    });

    this.state = {
      currentTab: TABS.OPT_W,
      groupsWithWeight,
      groupsWithoutWeight,
      groupsInvalidRefs,
    };

    this.onTabChanged = this.onTabChanged.bind(this);
  }

  onTabChanged(tab) {
    this.setState({ currentTab: tab });
  }

  render() {
    const { exp } = this.props;
    const {
      ref_groups,
      new_bm_groups,
      // new_opt_groups,
    } = exp;
    const {
      currentTab,
      groupsWithWeight,
      groupsWithoutWeight,
      groupsInvalidRefs,
    } = this.state;

    return (
      <>
        <div className="flex justify-between items-center px-4 mb-8 border-b border-gray-400">
          <div className="text-xl font-semibold text-gray-700">
            Algo version: {exp.algo_version}
          </div>
          <div>
            <MetadataPopup metadata={exp.metadata}></MetadataPopup>
          </div>
        </div>

        <div className="flex justify-start px-4">
          <div
            className={`font-bold text-purple-700 rounded rounded-b-none px-8 py-2 ${
              currentTab === TABS.BM ? "bg-white" : "cursor-pointer"
            }`}
            onClick={() => this.onTabChanged(TABS.BM)}
          >
            New BM Groups ({new_bm_groups.length})
          </div>

          <div
            className={`font-bold text-teal-700 rounded rounded-b-none px-8 py-2 ${
              currentTab === TABS.OPT_W ? "bg-white" : "cursor-pointer"
            }`}
            onClick={() => this.onTabChanged(TABS.OPT_W)}
          >
            New OPT Groups - Weighted ({groupsWithWeight.length})
          </div>

          <div
            className={`font-bold text-teal-700 rounded rounded-b-none px-8 py-2 ${
              currentTab === TABS.OPT_NW ? "bg-white" : "cursor-pointer"
            }`}
            onClick={() => this.onTabChanged(TABS.OPT_NW)}
          >
            New OPT Groups - None Weighted ({groupsWithoutWeight.length})
          </div>

          <div
            className={`font-bold text-gray-700 rounded rounded-b-none px-8 py-2 ${
              currentTab === TABS.INVALID_REF_GROUPS
                ? "bg-white"
                : "cursor-pointer"
            }`}
            onClick={() => this.onTabChanged(TABS.INVALID_REF_GROUPS)}
          >
            Invalid Reference Groups ({groupsInvalidRefs.length})
          </div>
        </div>

        <div className="px-4">
          <div className="bg-white shadow p-4">
            {currentTab === TABS.BM && (
              <NewBmGroupsTabView
                new_bm_groups={new_bm_groups}
                ref_groups={ref_groups}
              ></NewBmGroupsTabView>
            )}

            {currentTab === TABS.OPT_W && (
              <NewOptGroupsTabView
                new_opt_groups={groupsWithWeight}
                ref_groups={ref_groups}
              ></NewOptGroupsTabView>
            )}

            {currentTab === TABS.OPT_NW && (
              <NewOptGroupsTabView
                new_opt_groups={groupsWithoutWeight}
                ref_groups={ref_groups}
              ></NewOptGroupsTabView>
            )}

            {currentTab === TABS.INVALID_REF_GROUPS && (
              <InvalidRefGroupsTabView
                groups={groupsInvalidRefs}
                ref_groups={ref_groups}
              ></InvalidRefGroupsTabView>
            )}

            <ScrollToTop></ScrollToTop>
          </div>
        </div>
      </>
    );
  }
}

function InvalidRefGroupsTabView({ groups, ref_groups }) {
  return (
    <div>
      {groups.map((group, groupNum) => {
        return (
          <div key={group.id} className="my-4 pl-4 border-l-4 border-gray-600">
            <RefGroupView
              group={group}
              pHeaders={ref_groups.performance_data_header}
              spHeaders={ref_groups.size_performance_data_header}
            ></RefGroupView>
          </div>
        );
      })}
    </div>
  );
}

function NewBmGroupsTabView({ new_bm_groups, ref_groups }) {
  return (
    <div>
      {new_bm_groups.map((group, groupNum) => {
        return (
          <BmGroupView
            key={groupNum}
            group={group}
            groupNum={groupNum + 1}
            ref_groups={ref_groups}
          ></BmGroupView>
        );
      })}
    </div>
  );
  return (
    <div>
      {new_bm_groups.map((group, groupNum) => {
        const keys = _.keys(group);

        return (
          <div
            key={groupNum}
            className="my-4 pl-4 border-l-4 border-purple-600"
          >
            <div className="font-bold text-purple-700">{groupNum + 1}</div>
            {keys.map((key) => {
              const value = group[key];

              if (key === "metadata") {
                return (
                  <MetadataPopup key={key} metadata={value}></MetadataPopup>
                );
              } else {
                return (
                  <div key={key}>
                    <b>{key} </b>
                    {JSON.stringify(value, null, 4)}
                  </div>
                );
              }
            })}
          </div>
        );
      })}
    </div>
  );
}

function BmGroupView({ group, groupNum, ref_groups }) {
  const {
    metadata,
    weight,
    backlinks,
    traffic_distributioin,
    traffic_distribution,
  } = group;

  // backend bug
  // const selection_results = _.isArray(group.selection_results)
  //   ? group.selection_results
  //   : [group.selection_results];
  const [backlinkGroup, setBacklinkGroup] = useState(undefined);
  const [selectedGroupId, setSelectedGroupId] = useState(null);

  function handleBacklink(group_id) {
    const backlink = _.find(ref_groups.groups, { id: group_id });
    if (backlink) {
      setBacklinkGroup(backlink);
      setSelectedGroupId(group_id);
    }
  }

  return (
    <div className="mb-8 pl-4 border-l-4 border-purple-600">
      <div className="font-bold text-purple-700 text-sm">{groupNum}</div>
      <div className="py-2">
        <div className="flex gap-8 items-center mb-4">
          <div>
            <div className="uppercase text-xs font-semibold text-gray-600 leading-none">
              Weight:
            </div>
            <div className="text-gray-900 text-lg font-bold">
              {weight || "-"}
            </div>
          </div>

          <div>
            <div className="uppercase text-xs font-semibold text-gray-600 leading-none">
              Traffic Distribution:
            </div>
            <div className="text-gray-900 text-lg font-bold">
              {traffic_distributioin || traffic_distribution}
            </div>
          </div>

          <div>
            <div className="uppercase text-xs font-semibold text-gray-600 leading-none">
              Label:
            </div>
            <div className="text-gray-900 text-lg font-bold">
              {_.get(metadata, ["customProperties", "label"], "UNKNOWN")}
            </div>
          </div>

          <div>
            <MetadataPopup metadata={metadata}></MetadataPopup>
          </div>
        </div>

        <div>
          {/* <div className="border-b mb-2">Backlinks ({backlinks.length})</div> */}

          <div className="uppercase text-xs font-semibold text-gray-600">
            Backlinks ({backlinks.length}):
          </div>

          <div className="flex">
            <div className="w-1/2">
              <div>
                {backlinks.map((backlink, backlinkIndex) => (
                  <BacklinkView
                    key={backlinkIndex}
                    backlink={backlink}
                    handleBacklink={handleBacklink}
                    selectedGroupId={selectedGroupId}
                  ></BacklinkView>
                ))}
              </div>
            </div>
            <div className="w-1/2">
              {backlinkGroup && (
                <RefGroupView
                  group={backlinkGroup}
                  pHeaders={ref_groups.performance_data_header}
                  spHeaders={ref_groups.size_performance_data_header}
                ></RefGroupView>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function NewOptGroupsTabView({ new_opt_groups, ref_groups }) {
  return (
    <div>
      {new_opt_groups.map((group, groupNum) => {
        return (
          <OptGroupView
            key={groupNum}
            group={group}
            groupNum={groupNum + 1}
            ref_groups={ref_groups}
          ></OptGroupView>
        );
      })}
    </div>
  );
}

function OptGroupView({ group, groupNum, ref_groups }) {
  const {
    metadata,
    weight,
    backlinks,
    // selection_results
  } = group;

  // backend bug
  const selection_results = _.isArray(group.selection_results)
    ? group.selection_results
    : [group.selection_results];
  const [backlinkGroup, setBacklinkGroup] = useState(undefined);
  const [selectedGroupId, setSelectedGroupId] = useState(null);

  function handleBacklink(group_id) {
    const backlink = _.find(ref_groups.groups, { id: group_id });
    if (backlink) {
      setBacklinkGroup(backlink);
      setSelectedGroupId(group_id);
    }
  }

  return (
    <div className="mb-8 pl-4 border-l-4 border-teal-600">
      <div className="font-bold text-teal-700 text-sm">{groupNum}</div>
      <div className="py-2">
        <div className="flex gap-8 items-center mb-4">
          <div>
            <div className="uppercase text-xs font-semibold text-gray-600 leading-none">
              Weight:
            </div>
            <div className="text-gray-900 text-lg font-bold">
              {weight || "-"}
            </div>
          </div>

          <div>
            <div className="uppercase text-xs font-semibold text-gray-600 leading-none">
              Selection Results:
            </div>
            <div className="text-gray-900 text-lg font-bold">
              {selection_results && selection_results.length > 0
                ? selection_results.join(", ")
                : "-"}
            </div>
          </div>

          <div>
            <div className="uppercase text-xs font-semibold text-gray-600 leading-none">
              Label:
            </div>
            <div className="text-gray-900 text-lg font-bold">
              {_.get(metadata, ["customProperties", "label"], "UNKNOWN")}
            </div>
          </div>

          <div>
            <MetadataPopup metadata={metadata}></MetadataPopup>
          </div>
        </div>

        <div>
          {/* <div className="border-b mb-2">Backlinks ({backlinks.length})</div> */}

          <div className="uppercase text-xs font-semibold text-gray-600">
            Backlinks ({backlinks.length}):
          </div>

          <div className="flex">
            <div className="w-1/2">
              <div>
                {backlinks.map((backlink, backlinkIndex) => (
                  <BacklinkView
                    key={backlinkIndex}
                    backlink={backlink}
                    handleBacklink={handleBacklink}
                    selectedGroupId={selectedGroupId}
                  ></BacklinkView>
                ))}
              </div>
            </div>
            <div className="w-1/2">
              {backlinkGroup && (
                <RefGroupView
                  group={backlinkGroup}
                  pHeaders={ref_groups.performance_data_header}
                  spHeaders={ref_groups.size_performance_data_header}
                ></RefGroupView>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function RefGroupView({ group, pHeaders, spHeaders }) {
  const {
    id,
    types,
    metadata,
    invalid_reasons,
    performance,
    // size_performance,
  } = group;

  const size_performance = group.size_performance;

  // performance could be empty
  const request = _.get(performance, [0], 0);
  const eligible_revenue = _.get(performance, [3], 0);
  const eligible_rpm = request > 0 ? (eligible_revenue * 1000) / request : 0;

  return (
    <div className="border border-blue-600 h-full px-4">
      <span className="bg-blue-700 text-white font-semibold py-1 px-2 -ml-4">
        Ref
      </span>
      <div>
        <span className="text-sm text-gray-700">Group ID: </span>
        <span className="text-gray-900 font-mono">{group.id}</span>
        <div className="float-right mr-4">
          <MetadataPopup metadata={metadata}></MetadataPopup>
        </div>
      </div>
      <div>
        <span className="text-sm text-gray-700">Types: </span>
        <span className="text-gray-900">{types.join(", ")}</span>
      </div>

      <div>
        <span className="text-sm text-gray-700">Invalid Reasons: </span>
        <span className="text-gray-900">
          {JSON.stringify(invalid_reasons, null, 4)}
        </span>
      </div>

      <div>
        <span className="text-sm text-gray-700">Eligible RPM: </span>
        <span className="text-gray-900 font-medium">
          <NumberFormat
            value={eligible_rpm / 1000000}
            format={"$0,0.0000"}
          ></NumberFormat>
        </span>
      </div>

      <div>
        <div className="text-sm text-gray-700">Performance:</div>
        {performance ? (
          <div className="w-full overflow-y-auto pb-4">
            <table className="border">
              <tbody>
                {pHeaders.map((h, i) => {
                  let v = performance[i];
                  let format = "0,0";
                  if (h.indexOf("revenue") !== -1 || h.indexOf("cost") !== -1) {
                    v = v / 1000000;
                    format = "$0,0.0000";
                  }
                  return (
                    <tr key={h}>
                      <td className="text-xs text-gray-800 bg-gray-100 font-semibold border px-1">
                        {h}
                      </td>

                      <td className="border text-gray-800 text-xs text-right px-1">
                        <NumberFormat value={v} format={format}></NumberFormat>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          "-"
        )}
      </div>

      <div>
        <div className="text-sm text-gray-700">Size Performance:</div>
        {size_performance ? (
          <div className="w-full overflow-y-auto pb-4">
            <table className="border">
              <thead className="border">
                <tr>
                  {spHeaders.map((h) => {
                    return (
                      <th
                        key={h}
                        className="text-xs text-gray-800 bg-gray-100 font-semibold border px-1"
                      >
                        {h}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {size_performance &&
                  size_performance.map((row, i) => {
                    let format = "0,0";

                    return (
                      <tr key={i}>
                        {row.map((r, j) => {
                          let v = r;
                          let h = spHeaders[j];
                          if (typeof v === "string") {
                            //
                          } else {
                            if (
                              h.indexOf("revenue") !== -1 ||
                              h.indexOf("cost") !== -1
                            ) {
                              v = v / 1000000;
                              format = "$0,0.0000";
                            }
                          }

                          return (
                            <td
                              key={v}
                              className="border text-gray-800 text-xs text-right px-1"
                            >
                              {typeof v === "string" ? (
                                v
                              ) : (
                                <NumberFormat
                                  value={v}
                                  format={format}
                                ></NumberFormat>
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        ) : (
          "-"
        )}
      </div>

      {/* {keys.map((key) => {
        const value = group[key];

        if (key === "id") {
          return "";
        } else if (key === "metadata") {
          return <MetadataPopup key={key} metadata={value}></MetadataPopup>;
        } else if (key === "performance") {
          return (
            <div className="flex" key={key}>
              <b>{key} </b>
              <div>
                {value &&
                  value.map((v, index) => {
                    const tipId = `${id}-p-${index}`;

                    return (
                      <>
                        <span data-tip data-for={tipId} className="ml-1">
                          {v}
                          {`${", "}`}
                        </span>
                        <ReactTooltip
                          id={tipId}
                          type="dark"
                          effect="solid"
                          place="top"
                        >
                          {getDataHeader({ type: "performance", index })}
                        </ReactTooltip>
                      </>
                    );
                  })}
              </div>
            </div>
          );
        } else {
          return (
            <div key={key}>
              <b>{key} </b>
              {JSON.stringify(value, null, 4)}
            </div>
          );
        }
      })} */}
    </div>
  );
}

function BacklinkView({ backlink, handleBacklink, selectedGroupId }) {
  const {
    group_id,
    rpm,
    // scoring_metadata
  } = backlink;
  const scoring_metadata = _.isArray(backlink.scoring_metadata)
    ? backlink.scoring_metadata[0]
    : backlink.scoring_metadata;

  return (
    <div className="flex items-center mb-2">
      <div
        className={`border flex-grow ${
          group_id && selectedGroupId === group_id
            ? "border-blue-600"
            : "cursor-pointer"
        } p-2 hover:border-blue-600 hover:shadow`}
        onClick={() => handleBacklink(group_id)}
      >
        <div className="flex justify-between items-center text-gray-800">
          <div>
            <span className="text-sm">Group ID: </span>
            <span className="text-gray-900 font-mono">{group_id}</span>
          </div>

          <div>
            <span className="text-sm">RPM: </span>
            <span>${rpm}</span>
          </div>
        </div>

        {/* <div>Scoring Metadata: {JSON.stringify(scoring_metadata, null, 4)}</div> */}
        {/* <MetadataAccordian metadata={scoring_metadata}></MetadataAccordian> */}

        <div className="bg-gray-200 rounded px-2">
          {scoring_metadata && (
            <>
              <div>
                <span className="text-sm text-gray-700">tag: </span>
                <span>{scoring_metadata.tag}</span>
              </div>
              <div>
                <span className="text-sm text-gray-700">
                  process_priority:{" "}
                </span>
                <span>{scoring_metadata.process_priority}</span>
              </div>
              <div>
                <span className="text-sm text-gray-700">
                  claim_residual_priority:{" "}
                </span>
                <span>{scoring_metadata.claim_residual_priority}</span>
              </div>
              <div>
                <span className="text-sm text-gray-700">rank: </span>
                <span>{scoring_metadata.rank}</span>
              </div>
            </>
          )}
        </div>
      </div>
      <div
        // className="text-blue-600"
        style={{ marginLeft: "-5px" }}
        className={`${
          group_id && selectedGroupId === group_id
            ? "text-blue-600"
            : "text-white"
        }`}
      >
        <AiFillCaretRight></AiFillCaretRight>
      </div>
    </div>
  );
}

function MetadataAccordian({ metadata }) {
  const [isOpen, setIsOpen] = useState(false);

  function toggleOpen() {
    setIsOpen(!isOpen);
  }

  return (
    <div className="bg-gray-200 rounded">
      <div
        className="hover:bg-gray-300 cursor-pointer flex items-center"
        onClick={toggleOpen}
      >
        Metadata
        {isOpen ? <FiChevronUp></FiChevronUp> : <FiChevronDown></FiChevronDown>}
      </div>

      {isOpen && <div>{JSON.stringify(metadata, null, 4)}</div>}
    </div>
  );
}

function MetadataPopup({ metadata }) {
  const [isOpen, setIsOpen] = useState(false);

  function toggleOpen() {
    setIsOpen(!isOpen);
  }

  return (
    <BetterPopupWrapper
      // ref={this.child}
      position={"bottom"}
      triggerType="click"
      keepInside={true}
      triggerElement={
        <button
          type="button"
          className="text-blue-400 font-semibold hover:underline"
        >
          Metadata
        </button>
      }
      contentElement={
        <div style={{ width: "300px" }} className="p-1">
          {/* this dummy input so that textarea does not auto focus */}
          <input style={{ display: "none" }}></input>
          <textarea
            // style={{ width: "100%", fontSize: "14px" }}
            className="bg-gray-300 text-sm w-full"
            rows={20}
            defaultValue={JSON.stringify(metadata, null, 4)}
            readOnly
          ></textarea>
        </div>
      }
    ></BetterPopupWrapper>
  );

  return (
    <div className="bg-gray-200 rounded">
      <div
        className="hover:bg-gray-300 cursor-pointer flex items-center justify-center"
        onClick={toggleOpen}
      >
        Metadata
        {isOpen ? <FiChevronUp></FiChevronUp> : <FiChevronDown></FiChevronDown>}
      </div>

      {isOpen && <div>{JSON.stringify(metadata, null, 4)}</div>}
    </div>
  );
}

export default MicroExpView;
