import React from "react";
import _ from "lodash";
import { FiArrowDown } from "react-icons/fi";
// import NumberFormat from "../../common/NumberFormat";

const CHECK_STATUS = [
  "PASS",
  "FAIL",
  "NEED_MORE_DATA",
  "UNSTABLE",
  "WEAK_PASS",
  "WEAK_FAIL",
  "WEAK_UNSTABLE",
];
class HealthCheckView extends React.PureComponent {
  constructor(props) {
    super(props);
    const { reports } = props;
    console.log(reports);

    const sortedCheckResults = reports
      ? _.sortBy(reports.checkResults, "name")
      : [];

    this.state = {
      checkResults: sortedCheckResults,
      selectedCheck: _.get(sortedCheckResults, [[0], "checkId"]), // auto select the first check
    };

    this.handleSelectCheck = this.handleSelectCheck.bind(this);
  }

  handleSelectCheck(checkId) {
    this.setState({
      selectedCheck: checkId,
    });
  }

  render() {
    const { reports } = this.props;
    const { checkResults, selectedCheck } = this.state;

    const targetCheck = _.find(checkResults, { checkId: selectedCheck });
    const selectedCheckResult = _.get(targetCheck, "result");
    const checkControl = _.get(reports, ["detail", selectedCheck, "control"]);
    const checkReports = _.get(reports, ["detail", selectedCheck, "reports"]);

    const summary = _.countBy(checkResults, "result");

    if (!reports) {
      return (
        <div className="py-8 px-4 bg-gray-300 min-h-screen">
          <div className="bg-white rounded-t shadow-md p-2">
            No Data (Paused and Suspended units will not run health checks)
          </div>
        </div>
      );
    }

    return (
      <>
        <div className="py-8 px-4 bg-gray-300 min-h-screen">
          <div className="bg-white rounded-t shadow-md p-2">
            <div className="flex justify-end text-sm text-gray-700 mb-4">
              Check last updated at: {reports && reports.updatedAt}
            </div>

            {reports && (
              <div className="flex mb-4 float-right">
                {CHECK_STATUS.map((cs) => {
                  return (
                    <div
                      key={cs}
                      className="text-center border"
                      style={{ width: "150px" }}
                    >
                      <div className="text-gray-700 text-sm tracking-wide border-b">
                        {cs}
                      </div>
                      <div className="text-xl font-bold text-center text-gray-800 py-1">
                        {summary[cs] || 0}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            {reports && (
              <table className="table text-sm border">
                <thead className="bg-gray-200 text-blue-800 text-xs border-b">
                  <tr>
                    <th className="text-right py-1 px-3 flex gap-1 justify-end items-center">
                      <FiArrowDown></FiArrowDown>
                      Check
                    </th>
                    <th className="border text-right py-1 px-3">Result</th>
                  </tr>
                </thead>
                <tbody className="text-gray-900 font-mono bg-white">
                  {checkResults.map((r) => {
                    return (
                      <tr
                        key={r.name}
                        className={`${
                          selectedCheck === r.checkId
                            ? "text-blue-800 font-semibold bg-blue-100"
                            : "text-gray-800 bg-white hover:bg-gray-100"
                        } border-b cursor-pointer`}
                        onClick={() => this.handleSelectCheck(r.checkId)}
                      >
                        <td className="text-right py-1 px-3 whitespace-no-wrap border">
                          {r.name}
                        </td>

                        <td className="text-right py-1 px-3 whitespace-no-wrap border">
                          <span
                            className={
                              r.result === "FAIL" ? "text-red-600" : ""
                            }
                          >
                            {r.result}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>

          <div className="bg-white rounded-t shadow-md p-2">
            <div className="border border-blue-500 p-4">
              <div className="text-xl font-bold border-b">
                {selectedCheck} {targetCheck.name}: {selectedCheckResult}
              </div>

              <div className="py-4 border-b">
                <div className="font-bold uppercase text-gray-600 py-2">
                  Control
                </div>
                <div>
                  <table className="text-sm">
                    <thead>
                      <tr>
                        <th className="border px-2 py-1">Key</th>
                        <th className="border px-2 py-1">Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.keys(checkControl).map((key) => {
                        return (
                          <tr key={key}>
                            <td className="border px-2 py-1">{key}</td>
                            <td className="border px-2 py-1">
                              {JSON.stringify(checkControl[key])}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>

              <div>
                <div className="font-bold uppercase text-gray-600 py-2">
                  Reports
                </div>
                <div>
                  {_.keys(checkReports).map((reportName, i) => {
                    const detailReport = checkReports[reportName];
                    return (
                      <div key={i} className="mb-2">
                        <div className="font-bold">{reportName}</div>
                        {/* <div>{JSON.stringify(detailReport, null, 4)}</div> */}
                        <div>
                          <table className="text-sm font-mono">
                            <thead>
                              <tr>
                                {detailReport.columns.map((col) => {
                                  return (
                                    <th
                                      key={col}
                                      className="border px-2 py-1 text-right"
                                    >
                                      {col}
                                    </th>
                                  );
                                })}
                              </tr>
                            </thead>

                            <tbody>
                              {detailReport.rows.map((row, j) => {
                                return (
                                  <tr key={j}>
                                    {row.map((rc, k) => {
                                      return (
                                        <td
                                          key={k}
                                          className="border px-2 py-1 text-right"
                                        >
                                          {rc}
                                        </td>
                                      );
                                    })}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default HealthCheckView;
