import React from "react";
import _ from "lodash";
import Select from "react-select";

class CurrencySelector extends React.PureComponent {
  constructor(props) {
    super(props);

    const options = _.map(props.currencies, (c) => {
      return { value: c, label: c };
    });

    const selectedValue = _.find(options, { value: props.selectedCurrency });

    this.state = {
      options,
      selectedValue,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(option) {
    this.setState({
      selectedValue: option,
    });

    this.props.handleCurrencyChanged(option.value);
  }

  render() {
    const { options, selectedValue } = this.state;

    return (
      <>
        {/* <div className="inline-flex items-center px-4 py-2 border border-gray-400 rounded hover:shadow text-sm text-gray-700 bg-white cursor-pointer focus:outline-none">
            <span className="mr-2">USD</span>
            <IoIosArrowDown></IoIosArrowDown>
          </div> */}
        <Select
          className="w-24 z-50"
          defaultValue={selectedValue}
          value={selectedValue}
          onChange={this.handleChange}
          options={options}
          isSearchable={false}
          // styles={{
          //   control: () => ({
          //     paddingLeft: 3,
          //   }),
          // }}
        />
      </>
    );
  }
}

export default CurrencySelector;
