import React from "react";

const DATE_RANGES = [3, 7, 14, 30];

class DateRangeSelector extends React.Component {
  render() {
    const {
      selectedDateRange,
      onDateRangeChange,
      dateRanges = DATE_RANGES // optional
    } = this.props;

    return (
      <div className="inline-block">
        Date Range:{" "}
        {dateRanges.map(dr => {
          return (
            <button
              key={dr}
              type="button"
              disabled={selectedDateRange === dr}
              onClick={() => onDateRangeChange(dr)}
              className={`${
                selectedDateRange === dr
                  ? "bg-gray-200 text-gray-800 cursor-default"
                  : "text-gray-800 bg-gray-500 hover:bg-gray-200"
              } font-bold py-1 w-10 border-r border-gray-800 last:border-r-0 first:rounded-l last:rounded-r`}
            >
              {dr}
            </button>
          );
        })}
      </div>
    );
  }
}

export default DateRangeSelector;
