import React from "react";
import _ from "lodash";
import NumberFormat from "components/common/NumberFormat";
import { formatMoneyWithCurrency } from "components/intowow-business/platform-index/PlatformIndexReportsHelper";
import { IoIosArrowDown } from "react-icons/io";

const buttonActionClass =
  "px-2 py-1 bg-gray-200 text-xs rounded border border-gray-400 shadow hover:bg-gray-300 text-gray-900 font-semibold";

class AnatomyCstSegmentsTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedSegmentId: null,
    };

    this.handleSelectSegment = this.handleSelectSegment.bind(this);
  }

  handleSelectSegment(selectedSegmentId) {
    this.setState({
      selectedSegmentId,
    });
    this.props.handleGetSegmentReport(selectedSegmentId);
  }

  render() {
    const { selectedSegmentId } = this.state;
    const { data, items, segmentTotalReport, selectedDateRange, currency } =
      this.props;

    if (items.length === 0) {
      return <div>No Segments</div>;
    }

    return (
      <div>
        <div className="pt-4 font-medium">{items.length} segments</div>

        <table className="border shadow table w-full text-sm">
          <thead className="border-b bg-gray-200 text-xs text-blue-800">
            <tr>
              <th className="border py-1 px-2 text-center" rowSpan={2}>
                UPR Segment ID
              </th>

              <th className="border p-2 text-center" colSpan={6}>
                Past {selectedDateRange} days performance
              </th>
              <th className="border py-1 px-2 text-center" rowSpan={2}>
                Optimization
              </th>
              <th className="border py-1 px-2 text-center" rowSpan={2}>
                Traffic Scope
              </th>
              <th className="border py-1 px-2 text-left" rowSpan={2}>
                Prices
              </th>
              <th className="border py-1 px-2 text-center" rowSpan={2}>
                Action
              </th>
            </tr>

            <tr>
              <th className="border p-2 text-right font-semibold text-gray-900">
                Gross Incr Lift
              </th>
              <th className="border p-2 text-right font-semibold text-gray-900">
                Net Incr Lift
              </th>
              <th className="border flex justify-end p-2 font-semibold text-gray-900">
                <div className="flex items-center gap-2">
                  Total Rev
                  <IoIosArrowDown></IoIosArrowDown>
                </div>
              </th>
              <th className="border p-2 text-right font-semibold text-gray-900">
                Original Rev
              </th>
              <th className="border p-2 text-right font-semibold text-gray-900">
                Incr Rev
              </th>
              <th className="border p-2 text-right font-semibold text-gray-900">
                Cost
              </th>
            </tr>
          </thead>
          <tbody className="bg-white text-gray-800">
            {segmentTotalReport.map((item) => {
              const shouldHighlight = item.isImportant; //item.total_rev_ratio > 1; // at least 1%
              return (
                <tr
                  key={item.upr_segment_id}
                  className={`border-b hover:bg-gray-100 ${
                    selectedSegmentId === item.upr_segment_id
                      ? "bg-blue-100 hover:bg-blue-100"
                      : "bg-white"
                  }`}
                >
                  <td
                    className={`border py-1 px-2 text-center ${
                      shouldHighlight && "font-semibold"
                    }`}
                  >
                    {item.upr_segment_id}
                    <div>{item.name}</div>
                    <div>Segmentation ID: {item.upr_segmentation_id}</div>
                  </td>

                  <td
                    className={`border py-1 px-2 text-right ${
                      shouldHighlight && "font-semibold"
                    }`}
                  >
                    <span
                      className={
                        shouldHighlight &&
                        (item.gross_lift >= 0
                          ? "text-green-600"
                          : "text-red-600")
                      }
                    >
                      {item.gross_lift > 0 && "+"}
                      <NumberFormat
                        value={item.gross_lift}
                        format="0,0.00%"
                      ></NumberFormat>
                    </span>
                  </td>
                  <td
                    className={`border py-1 px-2 text-right ${
                      shouldHighlight && "font-semibold"
                    }`}
                  >
                    <span
                      className={
                        shouldHighlight &&
                        (item.net_lift >= 0 ? "text-green-600" : "text-red-600")
                      }
                    >
                      {item.net_lift > 0 && "+"}
                      <NumberFormat
                        value={item.net_lift}
                        format="0,0.00%"
                      ></NumberFormat>
                    </span>
                  </td>
                  <td
                    className={`border py-1 px-2 text-right ${
                      shouldHighlight && "font-semibold"
                    }`}
                  >
                    {formatMoneyWithCurrency({
                      currency,
                      value: item.total_rev || 0,
                    })}
                    <br></br>(
                    <NumberFormat
                      value={item.total_rev_ratio}
                      format="0,0.00"
                      postfix="%"
                    ></NumberFormat>
                    )
                  </td>
                  <td
                    className={`border py-1 px-2 text-right ${
                      shouldHighlight && "font-semibold"
                    }`}
                  >
                    {formatMoneyWithCurrency({
                      currency,
                      value: item.original_rev || 0,
                    })}
                  </td>

                  <td
                    className={`border py-1 px-2 text-right ${
                      shouldHighlight && "font-semibold"
                    }`}
                  >
                    <span
                      className={
                        shouldHighlight &&
                        (item.increased_rev >= 0
                          ? "text-green-600"
                          : "text-red-600")
                      }
                    >
                      {formatMoneyWithCurrency({
                        currency,
                        value: item.increased_rev || 0,
                      })}
                    </span>
                  </td>
                  <td
                    className={`border py-1 px-2 text-right ${
                      shouldHighlight && "font-semibold"
                    }`}
                  >
                    {formatMoneyWithCurrency({
                      currency,
                      value: item.cost || 0,
                    })}
                  </td>

                  <td className="border py-1 px-2 text-center font-semibold">
                    <span
                      className={
                        _.startsWith(item.optimization, "cstv2")
                          ? "text-teal-600"
                          : "text-gray-600"
                      }
                    >
                      {item.optimization}
                    </span>
                  </td>

                  <td className="border py-1 px-2 text-center font-semibold">
                    <span
                      className={
                        item.traffic_scope === "NO_PREBID"
                          ? "text-purple-600"
                          : "text-gray-600"
                      }
                    >
                      {item.traffic_scope}
                    </span>
                  </td>

                  <td className="border py-1 px-1 text-left text-xs">
                    <div className="border-b mb-1">
                      <span className="font-semibold text-gray-800">
                        Prices:
                        {item.optimization !== "dt" && (
                          <span>({item.prices.length})</span>
                        )}
                      </span>{" "}
                      <br></br>
                      {item.optimization !== "dt" ? (
                        <div>{item.prices.join(", ")}</div>
                      ) : (
                        "-"
                      )}
                    </div>

                    <div className="border-b mb-1">
                      <span className="font-semibold text-gray-800">
                        Detector Prices: ({item.detector_prices.length})
                      </span>
                      <br></br> {item.detector_prices.join(", ")}
                    </div>

                    <div className="border-b mb-1">
                      <span className="font-semibold text-gray-800">
                        Catchall Prices:{" "}
                        {item.catchall_prices && (
                          <>({item.catchall_prices.length})</>
                        )}
                      </span>
                      <br></br>{" "}
                      {item.catchall_prices
                        ? item.catchall_prices.join(", ")
                        : "-"}
                    </div>

                    <div>
                      <span className="font-semibold text-gray-800">
                        X Prices:{" "}
                        {item.x_prices && <>({item.x_prices.length})</>}
                      </span>
                      <br></br> {item.x_prices ? item.x_prices.join(", ") : "-"}
                    </div>
                  </td>

                  <td className="whitespace-no-wrap border py-1 px-2 text-right">
                    <div className="flex items-center justify-end gap-4">
                      <button
                        type="button"
                        className={buttonActionClass}
                        onClick={() =>
                          this.handleSelectSegment(item.upr_segment_id)
                        }
                      >
                        Get report
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default AnatomyCstSegmentsTable;
