import React from "react";
import _ from "lodash";
import TextAreaWithJsonCheck from "components/common/TextAreaWithJsonCheck";
import ClickToCopyWrapper from "components/common/ClickToCopyWrapper";

const DEFAULT_UPR_PRICES = [
  {
    price: 0.01,
    weight: 20,
  },
];

class UprPricesControl extends React.PureComponent {
  constructor(props) {
    super(props);

    let items = this.props.inputValue;
    let errorMsg = null;

    if (typeof items === "object" && !_.isEmpty(items)) {
      items = JSON.stringify(items);
    }

    this.state = {
      items,
      errorMsg,
    };

    this.handleItemsChanged = this.handleItemsChanged.bind(this);
  }

  handleItemsChanged(items) {
    this.setState({ items, errorMsg: null });

    if (_.isEmpty(items)) {
      this.setState({
        errorMsg: "Cannot be empty",
      });
      this.props.handleHasError(true);
      return;
    }

    // example: [{"price": 0.01, "weight": 20}]
    try {
      const value = JSON.parse(items);

      if (!_.isArray(value)) {
        this.setState({
          errorMsg: `Should be an array (ex. [{"price": 0.01, "weight": 20}])`,
        });
        this.props.handleHasError(true);
        return;
      }

      if (_.isArray(value)) {
        if (_.isEmpty(value)) {
          this.setState({
            errorMsg: `Should be an array of objects (ex. [{"price": 0.01, "weight": 20}])`,
          });
          this.props.handleHasError(true);
          return;
        }

        for (const p of value) {
          if (!_.has(p, "price") || !_.has(p, "weight")) {
            this.setState({
              errorMsg: `Should be an array of objects (ex. [{"price": 0.01, "weight": 20}])`,
            });
            this.props.handleHasError(true);
            return;
          }

          if (p.price === "" || _.isNil(p.price) || !_.isNumber(p.price)) {
            this.setState({
              errorMsg: `Price should be a float number`,
            });
            this.props.handleHasError(true);
            return;
          }

          if (p.weight === "" || _.isNil(p.weight) || !_.isNumber(p.weight)) {
            this.setState({
              errorMsg: `Weight should be an integer`,
            });
            this.props.handleHasError(true);
            return;
          }
        }
      }
    } catch (err) {
      this.setState({
        errorMsg: `Not a valid json`,
      });
      this.props.handleHasError(true);
      return;
    }

    const value = JSON.parse(items);
    this.props.handleHasError(false);
    this.props.handleNewValueChanged(value);
  }

  render() {
    const { valueSchema } = this.props;

    const { items, errorMsg } = this.state;
    const defaultValue = JSON.stringify(DEFAULT_UPR_PRICES);

    return (
      <div className="border rounded ml-4 bg-white p-2 text-sm text-gray-700">
        <>
          <div className="flex items-center">
            ex. {defaultValue}{" "}
            <ClickToCopyWrapper copyText={defaultValue}></ClickToCopyWrapper>
          </div>
          <TextAreaWithJsonCheck
            inputValue={items}
            handleInputChanged={this.handleItemsChanged}
          ></TextAreaWithJsonCheck>

          <div className="text-red-600">{errorMsg}</div>
        </>
      </div>
    );
  }
}

export default UprPricesControl;
