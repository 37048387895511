import React from "react";
import _ from "lodash";
import Select from "react-select";
import { GAM_INVENTORY_FORMATS } from "./Constants";

// can be multiselect
class GAMInventoryFormatsSelector extends React.PureComponent {
  constructor(props) {
    super(props);
    // props:
    // selectedItems
    // handleChanged

    const { selectedItems } = props;
    const keys = _.keys(GAM_INVENTORY_FORMATS);

    const options = _.map(keys, (key) => {
      return { value: GAM_INVENTORY_FORMATS[key], label: key };
    });

    const selectedValue = _.filter(options, (option) => {
      return _.indexOf(selectedItems, option.value) !== -1;
    });

    this.state = {
      options,
      selectedValue,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(options) {
    this.setState({
      selectedValue: options,
    });

    this.props.handleChanged(_.map(options, "value"));
  }

  render() {
    const { options, selectedValue } = this.state;

    return (
      <>
        <Select
          // className="w-24 z-50"
          defaultValue={selectedValue}
          value={selectedValue}
          onChange={this.handleChange}
          options={options}
          isSearchable={true}
          isMulti={true}
          // styles={{
          //   control: () => ({
          //     paddingLeft: 3,
          //   }),
          // }}
        />
      </>
    );
  }
}

export default GAMInventoryFormatsSelector;
