import React from "react";
import _ from "lodash";

import UnitsSelector from "../../../common/UnitsSelector";
import { NetworkAPI } from "apis";
import { notify } from "react-notify-toast";

const inputClass =
  "bg-white flex w-full border border-gray-400 rounded py-1 px-3 appearance-none leading-normal focus:outline-none focus:border-blue-400 focus:shadow-inner hover:border-gray-500";
const inputClassDisabled =
  "bg-gray-300 flex w-full border border-gray-400 rounded py-1 px-3 appearance-none leading-normal";

const targetingTitleClass = "block text-gray-800 font-bold mb-1"; //"font-semibold";

class CreateOrUpdateVirtualPlacement extends React.Component {
  constructor(props) {
    super(props);

    const { virtualPlacement } = props;
    const {
      virtualPlacementId = null,
      name = "",
      gamUnitIds = [],
    } = virtualPlacement || {};

    this.state = {
      isSaving: false,
      saveErrMsg: null,

      virtualPlacement,

      virtualPlacementId,
      name,
      gamUnitIds,
    };

    this.handleBack = this.handleBack.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUnitsChanged = this.handleUnitsChanged.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  componentDidMount() {
    const { virtualPlacement } = this.props;
    if (!virtualPlacement) {
      this.props.history.goBack();
    }
  }

  handleChange(e, key) {
    const value = e.target.value;
    this.setState({
      [key]: value,
    });
  }

  handleUnitsChanged(unitIds) {
    this.setState({ gamUnitIds: unitIds });
  }

  handleBack() {
    this.props.history.goBack();
  }

  async handleCreate() {
    this.setState({ isSaving: true });
    try {
      const { networkId } = this.props;
      const { name, gamUnitIds } = this.state;
      const params = {
        name,
        gamUnitIds,
        networkId,
      };

      const r = await NetworkAPI.createNetworkVirtualPlacement(params);
      console.log(r);

      notify.show(
        "Virtual Placement created! Redirecting back to list page...",
        "success"
      );

      this.setState({
        isSaving: false,
        saveErrMsg: null,
      });

      setTimeout(() => {
        this.handleBack();
      }, 1000);
    } catch (err) {
      this.setState({
        saveErrMsg: `Failed to create virtual placement. ${err}`,
      });
    }

    this.setState({ isSaving: false });
  }

  async handleUpdate() {
    this.setState({ isSaving: true });
    try {
      const { networkId } = this.props;
      const { name, gamUnitIds, virtualPlacementId } = this.state;
      const params = {
        virtualPlacementId,
        name,
        gamUnitIds,
        networkId,
      };

      const r = await NetworkAPI.updateNetworkVirtualPlacement(params);
      console.log(r);

      notify.show(
        "Virtual Placement updated! Redirecting back to list page...",
        "success"
      );

      this.setState({
        isSaving: false,
        saveErrMsg: null,
      });

      setTimeout(() => {
        this.handleBack();
      }, 1000);
    } catch (err) {
      this.setState({
        saveErrMsg: `Failed to update virtual placement. ${err}`,
      });
    }

    this.setState({ isSaving: false });
  }

  render() {
    const { networkId } = this.props;
    const {
      virtualPlacement,
      virtualPlacementId,
      name,
      gamUnitIds,
      isSaving,
      saveErrMsg,
    } = this.state;

    const formType = virtualPlacementId ? "UPDATE" : "ADD";

    return (
      <>
        {virtualPlacement && (
          <div>
            <div className="rounded border mb-4  bg-white px-8 pt-6 pb-8">
              {virtualPlacementId && (
                <div className="mb-4">
                  <label
                    className="block font-bold text-gray-800"
                    htmlFor="vpId"
                  >
                    ID
                  </label>
                  <input
                    className={inputClassDisabled}
                    id="vpId"
                    type="number"
                    defaultValue={virtualPlacementId}
                    disabled={true}
                  />
                </div>
              )}

              <div className="mb-4">
                <label className="block font-bold text-gray-800" htmlFor="name">
                  Name
                </label>
                <input
                  className={inputClass}
                  id="name"
                  type="text"
                  value={name}
                  onChange={(e) => this.handleChange(e, "name")}
                />
              </div>

              <div className="mb-4">
                <div className={targetingTitleClass}>
                  Unit IDs:{" "}
                  <span className="text-sm font-normal text-gray-700">
                    (you can also paste a list of "," separated <i>unitIds</i>{" "}
                    or <i>extGamUnitIds</i>)
                  </span>
                </div>
                <UnitsSelector
                  networkId={networkId}
                  handleChanged={this.handleUnitsChanged}
                  selectedItems={gamUnitIds}
                  selectMetric="unitId"
                ></UnitsSelector>
                <div className="text-xs text-gray-600">
                  {(gamUnitIds && gamUnitIds.length) || 0} unitIds
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="mt-4 flex flex-row-reverse items-center">
          <div>
            <button
              type="button"
              className={`px-4 py-2 ${
                isSaving
                  ? "bg-gray-400 text-gray-800"
                  : "bg-blue-500 text-white hover:bg-blue-700"
              }  rounded shadow font-semibold`}
              onClick={
                formType === "ADD" ? this.handleCreate : this.handleUpdate
              }
              disabled={isSaving}
            >
              {isSaving
                ? "Saving..."
                : formType === "ADD"
                ? "Create"
                : "Save edit"}
            </button>
          </div>
          <div>
            <button
              type="button"
              disabled={isSaving}
              className={`px-4 py-2 text-blue-700 ${
                isSaving ? "cursor-not-allowed" : ""
              }`}
              onClick={this.props.history.goBack}
            >
              Cancel
            </button>
          </div>
          <div className="text-red-600">{saveErrMsg}</div>
        </div>
      </>
    );
  }
}

export default CreateOrUpdateVirtualPlacement;
