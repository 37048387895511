import React from "react";
import _ from "lodash";

class PublisherFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: "",
      defaultValue: this.props.defaultValue,
    };

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  componentDidMount() {
    this.filterData = _.debounce((event) => {
      this.props.handleSearch(event.target.value);
    }, 300);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isInSearchingMode !== this.props.isInSearchingMode) {
      if (!this.props.isInSearchingMode) {
        this.setState({ inputValue: "" });
      }
    }
  }

  handleOnChange(event) {
    event.persist();
    this.filterData(event);
    this.setState({
      inputValue: event.target.value,
      defaultValue: "",
    });
  }

  render() {
    const { isInSearchingMode, placeholder } = this.props;
    const { inputValue, defaultValue } = this.state;

    return (
      <input
        type="text"
        className={`bg-white flex w-full border-2 border-gray-300 rounded py-1 px-4 appearance-none leading-normal ${
          isInSearchingMode
            ? "border-blue-600"
            : "focus:outline-none focus:border-blue-400 focus:shadow-inner hover:shadow hover:border-blue-300"
        }`}
        placeholder={placeholder}
        onChange={this.handleOnChange}
        value={inputValue || defaultValue}
      ></input>
    );
  }
}

export default PublisherFilter;
