import React from "react";
import _ from "lodash";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import { NetworkAPI } from "apis";

let CACHE_UNITS = {};

// For segmentation country selection
// can be multiselect
class UnitsSelector extends React.PureComponent {
  constructor(props) {
    super(props);
    // props:
    // selectedItems
    // handleChanged

    // const { selectedItems } = props;

    // const selectedValue = _.filter(options, (option) => {
    //   return _.indexOf(selectedItems, option.value) !== -1;
    // });

    this.state = {
      // options,
      // selectedValue,

      isLoading: false,
      errMsg: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCreateOption = this.handleCreateOption.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      // selectMetric: extGamUnitId, unitId,
      const {
        networkId,
        selectedItems,
        selectMetric = "extGamUnitId",
        isCreatable = false,
      } = this.props;
      if (!networkId) {
        throw new Error("Missing Network ID");
      }

      let units = [];
      const cachedUnits = CACHE_UNITS[networkId];
      if (cachedUnits && cachedUnits.length > 0) {
        units = cachedUnits;
      } else {
        units = await NetworkAPI.getUnitsByNetworkId({ networkId });
        CACHE_UNITS[networkId] = units;
        if (!units) {
          throw new Error("Failed to find units");
        }
      }

      const options = _.map(units, (unit) => {
        return {
          unitId: unit.unitId + "",
          value: unit[selectMetric],
          label: `${unit.unitId} ${unit.name} (${unit.extGamUnitId})`,
        };
      });

      const { errMsg, selectedValue } = this._fillSelectedOptions({
        selectedItems,
        options,
        isCreatable,
      });

      this.setState({
        options,
        selectedValue, // array of options
        isLoading: false,
        errMsg,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        errMsg: err.toString(),
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedItems !== this.props.selectedItems) {
      const { options } = this.state;
      const { selectedItems, isCreatable } = this.props;

      const { errMsg, selectedValue } = this._fillSelectedOptions({
        selectedItems,
        options,
        isCreatable,
      });

      this.setState({
        selectedValue,
        errMsg,
      });
    }
  }

  _fillSelectedOptions({ selectedItems, options, isCreatable = false }) {
    let selectedValue = [];
    if (selectedItems) {
      selectedValue = _.filter(options, (option) => {
        return _.indexOf(selectedItems, option.value) !== -1;
      });
      const invalidIds = _.difference(
        selectedItems,
        _.map(selectedValue, "value")
      );

      if (invalidIds.length > 0) {
        if (isCreatable) {
          const newItems = _.map(invalidIds, (id) => {
            return {
              // unitId: "-1",
              value: id,
              label: `${id}`,
              __isNew__: true,
            };
          });
          selectedValue = [...selectedValue, ...newItems];
        } else {
          const errMsg = `Failed to find these units: ${invalidIds.join(", ")}`;
          this.props.handleError && this.props.handleError(errMsg);
          return { errMsg, selectedValue };
        }
      }
    }
    return { errMsg: null, selectedValue };
  }

  handleChange(options) {
    this.setState({
      selectedValue: options,
    });

    this.props.handleChanged(_.map(options, "value"));
  }

  // only when metric is extGamUnitId
  handleCreateOption(input) {
    // console.log("handleCreateOption", input);
    if (_.isEmpty(input)) return;
    const newSelectedValue = [
      {
        // unitId: "-1",
        value: input,
        label: `${input}`,
        __isNew__: true,
      },
    ];
    const newValues = _.uniqBy(
      [...(this.state.selectedValue || []), ...newSelectedValue],
      "value"
    );
    this.handleChange(newValues);

    return input;
  }

  handleInputChange(input) {
    // console.log("handleInputChange", input);
    // unitIds
    // extGamUnitIds
    // unitIds + extGamUnitIds

    if (_.isEmpty(input)) return;
    // Check if there is seperator
    // user can paste a list of unit ids
    let newSelectedValue = [];
    if (input.includes(",")) {
      let parts = input.split(",");
      parts = _.map(_.compact(parts), _.trim);
      if (parts.length > 0) {
        // check if this is a list of unitIds
        const selectedValue = _.filter(this.state.options, (option) => {
          return _.indexOf(parts, option.unitId) !== -1;
        });
        // otherwise could be extGamUnitId
        const selectedValue2 = _.filter(this.state.options, (option) => {
          return _.indexOf(parts, option.value) !== -1;
        });
        newSelectedValue = [...selectedValue, ...selectedValue2];

        // remove found units from parts
        const selectedVs = _.map(newSelectedValue, "value");
        const selectedUIds = _.map(newSelectedValue, "unitId");
        _.remove(parts, (p) => {
          return (
            _.indexOf(selectedVs, p) !== -1 || _.indexOf(selectedUIds, p) !== -1
          );
        });

        const invalidIds = _.difference(
          parts,
          _.map(newSelectedValue, "value")
        );
        if (invalidIds.length > 0) {
          if (this.props.isCreatable) {
            const newItems = _.map(invalidIds, (id) => {
              return {
                // unitId: "-1",
                value: id,
                label: `${id}`,
                __isNew__: true,
              };
            });
            newSelectedValue = [...newSelectedValue, ...newItems];
          } else {
            const errMsg = `Failed to find these units: ${invalidIds.join(
              ", "
            )}`;
            this.props.handleError && this.props.handleError(errMsg);
            return { errMsg, selectedValue: newSelectedValue };
          }
        }

        if (newSelectedValue.length > 0) {
          const newValues = _.uniqBy(
            [...(this.state.selectedValue || []), ...newSelectedValue],
            "value"
          );
          this.handleChange(newValues);
          return "";
        }
      }
    }

    return input;
  }

  render() {
    const { options, selectedValue, isLoading, errMsg } = this.state;
    const { disabled = false } = this.props;

    return (
      <>
        <CreatableSelect
          defaultValue={selectedValue}
          value={selectedValue}
          onChange={this.handleChange}
          onInputChange={this.handleInputChange}
          onCreateOption={this.handleCreateOption}
          options={options}
          isSearchable={true}
          isMulti={true}
          isDisabled={disabled}
          isLoading={isLoading}
          styles={{
            control: (baseStyles, state) => ({
              // state.isFocused
              ...baseStyles,
              borderColor: errMsg ? "red" : "lightgray",
            }),
          }}
        />

        {errMsg && <div className="text-xs text-red-600">{errMsg}</div>}
      </>
    );
  }
}

export default UnitsSelector;
