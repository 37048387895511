import React from "react";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import { FiInfo } from "react-icons/fi";
import { MetricDescription } from "./MetricDescription";

class InfoDescriptionTooltip extends React.PureComponent {
  render() {
    const { metricKey, place } = this.props;

    // tooltipKey="total-unit-description-tt"
    // tooltipMessage={MetricDescription.TOTAL_UNITS}

    if (!metricKey) {
      return "";
    }

    const tooltipKey = "-desc-tt-" + metricKey;
    const m = MetricDescription[metricKey];

    if (!m) {
      return "";
    }

    const { title, desc, desc2, desc3 } = m;

    // flex items-center to parent div
    return (
      <>
        <span
          data-tip
          data-for={tooltipKey}
          className="ml-1 text-blue-400 text-sm -mr-4"
        >
          <FiInfo></FiInfo>
        </span>

        <ReactTooltip
          id={tooltipKey}
          type="dark"
          effect="solid"
          place={place || "top"}
          getContent={() => {
            return (
              <div className="text-left" style={{ maxWidth: "250px" }}>
                <div className="font-bold">{title}:</div>
                <div>{desc}</div>
                {desc2 && <div style={{ marginTop: "8px" }}>{desc2}</div>}
                {desc3 && <div style={{ marginTop: "8px" }}>{desc3}</div>}
              </div>
            );
          }}
        ></ReactTooltip>
      </>
    );
  }
}

export default InfoDescriptionTooltip;
