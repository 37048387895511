import React from "react";
import _ from "lodash";
import ModalWrapper from "components/common/ModalWrapper";
import DemandTypesSelector from "./DemandTypesSelector";
import RequestTypesSelector from "./RequestTypesSelector";
import { NetworkAPI } from "apis";
import { notify } from "react-notify-toast";
import InventoryFormatsSelector from "./InventoryFormatsSelector";

const buttonActionClass =
  "px-2 py-1 bg-gray-200 text-xs rounded border border-gray-400 shadow hover:bg-gray-300 text-gray-900 font-semibold";

class UpdateNetworkBillableTypes extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
    };

    this.handleOpenEditModal = this.handleOpenEditModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleCloseModal() {
    this.setState({ isModalOpen: false });
  }

  handleOpenEditModal() {
    this.setState({ isModalOpen: true });
  }

  render() {
    const {
      billableDemandTypes,
      billableRequestTypes,
      billableInventoryFormats,
      networkId,
    } = this.props;
    const { isModalOpen } = this.state;

    return (
      <>
        <button
          type="button"
          className={buttonActionClass}
          onClick={this.handleOpenEditModal}
        >
          Edit Props
        </button>

        {isModalOpen && (
          <NetworkBillableTypesEditModal
            isModalOpen={isModalOpen}
            handleClose={this.handleCloseModal}
            billableDemandTypes={billableDemandTypes}
            billableRequestTypes={billableRequestTypes}
            billableInventoryFormats={billableInventoryFormats}
            networkId={networkId}
          ></NetworkBillableTypesEditModal>
        )}
      </>
    );
  }
}

class NetworkBillableTypesEditModal extends React.PureComponent {
  constructor(props) {
    super(props);

    const {
      billableDemandTypes,
      billableRequestTypes,
      billableInventoryFormats,
    } = props;

    this.state = {
      billableDemandTypes,
      billableRequestTypes,
      billableInventoryFormats,
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleCancel() {
    // document.body.style.overflow = "auto";
    const {
      billableDemandTypes,
      billableRequestTypes,
      billableInventoryFormats,
    } = this.props;
    this.setState({
      billableDemandTypes,
      billableRequestTypes,
      billableInventoryFormats,
      errMessage: null,
    });
    this.props.handleClose();
  }

  handleChange({ key, value }) {
    this.setState({ [key]: value });
  }

  async handleSave() {
    const { networkId } = this.props;
    const {
      billableDemandTypes,
      billableRequestTypes,
      billableInventoryFormats,
    } = this.state;

    try {
      const params = {
        networkId,
        billableDemandTypes,
        billableRequestTypes,
        billableInventoryFormats,
      };
      const r = await NetworkAPI.updateNetworkBillableTypes({ params });
      console.log(r);

      notify.show("Network saved! Automatically reloading page...", "success");

      setTimeout(() => {
        window.location.reload(false);
      }, 2000);

      // Close immediately
      // this.handleCancel();
    } catch (err) {
      console.log(err);
      this.setState({
        errMessage: `Something wrong happened! ${err}`,
      });
    }
  }

  render() {
    const {
      billableDemandTypes,
      billableRequestTypes,
      billableInventoryFormats,
      isSaving,
      errMessage,
    } = this.state;

    return (
      <ModalWrapper width="50%" height="420px" isOpen={this.props.isModalOpen}>
        <div className="border-b -mx-6 mb-4 px-6 pb-2 text-lg font-bold">
          Edit Network Billable Types
        </div>

        <div className="text-sm">
          <div className="my-2">
            <label
              className="mb-1 block pr-4 font-semibold text-gray-800"
              htmlFor="network-daily-goal"
            >
              Billable Demand Types ({billableDemandTypes.length}):
            </label>
            <DemandTypesSelector
              isMulti={true}
              selectedValue={billableDemandTypes}
              handleOnChange={(value) =>
                this.handleChange({ key: "billableDemandTypes", value })
              }
            ></DemandTypesSelector>
          </div>

          <div className="my-2">
            <label
              className="mb-1 block pr-4 font-semibold text-gray-800"
              htmlFor="network-daily-goal"
            >
              Billable Request Types:
            </label>
            <RequestTypesSelector
              isMulti={true}
              selectedValue={billableRequestTypes}
              handleOnChange={(value) =>
                this.handleChange({ key: "billableRequestTypes", value })
              }
            ></RequestTypesSelector>
          </div>

          <div className="my-2">
            <label
              className="mb-1 block pr-4 font-semibold text-gray-800"
              htmlFor="network-daily-goal"
            >
              Billable Inventory Formats:
            </label>
            <InventoryFormatsSelector
              isMulti={true}
              selectedValue={billableInventoryFormats}
              handleOnChange={(value) =>
                this.handleChange({ key: "billableInventoryFormats", value })
              }
            ></InventoryFormatsSelector>
          </div>

          {errMessage && (
            <div className="text-right text-lg font-semibold text-red-600">
              {errMessage}
            </div>
          )}
        </div>
        <div className="absolute bottom-0 right-0 p-4">
          <button
            className="rounded flex-shrink-0 border-4 border-transparent py-2 px-4 font-semibold text-blue-500 hover:text-blue-800"
            type="button"
            onClick={this.handleCancel}
          >
            Cancel
          </button>

          <button
            className="rounded flex-shrink-0 bg-blue-500 py-2 px-4 font-semibold text-white  hover:bg-blue-600"
            type="button"
            onClick={this.handleSave}
            disabled={isSaving}
          >
            {isSaving ? "Saving..." : "Save"}
          </button>
        </div>
      </ModalWrapper>
    );
  }
}

export default UpdateNetworkBillableTypes;
