import _ from "lodash";
import { transformUnitMoneyMetrics } from "./Snapshot";
// import moment from "moment-timezone";

// calculate the count for each filter in health check page
export function calculateUnitsFilterCountSummary(units) {
  const r = {
    ALL: 0,
    INSP_ONLY: 0,
    EXCLUDE_INSP: 0,
    HAS_GPT: 0,
    HAS_AMP: 0,
    NEW_ONLY: 0,
    EXCLUDE_NEW: 0,
    SUS_ONLY: 0,
    EXCLUDE_SUS: 0,
    DEBUG_ONLY: 0,
    EXCLUDE_DEBUG: 0,
  };

  _.forEach(units, (u) => {
    if (u.isInsp) r.INSP_ONLY++;
    if (!u.isInsp) r.EXCLUDE_INSP++;
    if (u.hasGpt) r.HAS_GPT++;
    if (u.hasAmp) r.HAS_AMP++;
    if (u.isNewlyOnboarded) r.NEW_ONLY++;
    if (!u.isNewlyOnboarded) r.EXCLUDE_NEW++;
    if (u.mode === 3) r.SUS_ONLY++;
    if (u.mode !== 3) r.EXCLUDE_SUS++;
    if (u.isDebug) r.DEBUG_ONLY++;
    if (!u.isDebug) r.EXCLUDE_DEBUG++;

    r.ALL++;
  });
  return r;
}

// export function transformHCUnits(reports, snapshots, includeEmpty = false) {
export function transformHCUnits(
  { reports, snapshots, checks },
  includeEmpty = false
) {
  if (snapshots.length === 0) return reports;

  // loop reports (health check reports)
  // unitId, unitName, ysId, ysName, summary, checkResults
  // add extra info from snapshots
  // add check name to check_results
  const snapMapByUnitId = _.keyBy(snapshots, "gam_unit_id");
  const checksById = _.keyBy(checks, "id");
  let units = [];

  const now = new Date().getTime();
  let noSnapUnitIds = [];
  // console.log(reports.length, snapshots.length);
  _.forEach(reports, (r) => {
    const snap = snapMapByUnitId[r.unit_id];

    if (!snap) {
      // console.log(`${r.unit_id} ${r.unit_name} does not have snapshot!`);
      noSnapUnitIds.push(r.unit_id);
      if (includeEmpty) {
        units.push(r);
      }
      return;
    }

    // all the extra infos
    let unit = r;
    unit.isInsp = _.get(snap, ["autopilot_brief", "force_inspection"]) === true;
    unit.isDebug =
      _.get(snap, ["autopilot_brief", "enable_dispatcher_debugging"]) === true;
    // unit.isAbuseBench =
    //   _.get(snap, ["autopilot_brief", "benchmark_performance_multiplier"]) < 1;

    unit.hasGpt = _.includes(snap.request_types, "Google Publisher Tag");
    unit.hasAmp = _.includes(snap.request_types, "AMP Ad Tag");

    unit.stat = snap.last_day_metrics;
    unit.isNewlyOnboarded =
      now - new Date(snap.created_at).getTime() < 7 * 24 * 60 * 60 * 1000;
    // unit.isRecentlyChanged =
    //   now - snap.last_changed_at < 14 * 24 * 60 * 60 * 1000;

    unit.mode = snap.mode;

    unit.ysId = snap.ys_id;
    unit.ysName = snap.ys_name;

    // add check name to check_results
    if (unit.check_results) {
      unit.check_results = _.map(unit.check_results, (cr) => {
        cr.name = _.get(checksById, [cr.check_id, "name"]);
        return cr;
      });
    }

    units.push(unit);
  });

  console.log(noSnapUnitIds.length, "units have no snapshot:", noSnapUnitIds);
  return units;
}

export function transformSnapshotMoneyMetrics(snapshots) {
  const isInTwd = true;
  const units = _.map(snapshots, (unit) =>
    transformUnitMoneyMetrics(unit, isInTwd)
  );
  return units;
}
