import React from "react";
import _ from "lodash";

// Used in Features
class RequestSignalAugmentationControl extends React.PureComponent {
  constructor(props) {
    super(props);

    const { enabled = undefined, reportableType = undefined } =
      this.props.inputValue;
    let errorMsg = null;

    this.state = {
      enabled,
      reportableType,
      errorMsg,
    };

    this.handleEnabledChanged = this.handleEnabledChanged.bind(this);
    this.handleReportableTypeChanged =
      this.handleReportableTypeChanged.bind(this);
  }

  handleEnabledChanged(e) {
    const enabled = e.target.value === "true" ? true : false;
    this.setState({ enabled });

    const rsaObject = {
      enabled,
      reportableType: this.state.reportableType,
    };

    this.props.handleNewValueChanged(rsaObject);
  }

  handleReportableTypeChanged(e) {
    const reportableType = e.target.value;
    this.setState({ reportableType });
    const rsaObject = {
      enabled: this.state.enabled,
      reportableType,
    };

    this.props.handleNewValueChanged(rsaObject);
  }

  render() {
    const { enabled, reportableType, errorMsg } = this.state;

    return (
      <div className="border rounded ml-4 bg-white p-2 text-sm text-gray-700">
        <>
          <div className="font-semibold">Enabled:</div>

          <div className="flex gap-4">
            <label htmlFor={`rsa_enabled_true`} className="cursor-pointer">
              <input
                id={`rsa_enabled_true`}
                type="radio"
                value="true"
                // value={true}
                checked={enabled}
                onChange={this.handleEnabledChanged}
                required
              />{" "}
              True
            </label>

            <label htmlFor={`rsa_enabled_false`} className="cursor-pointer">
              <input
                id={`rsa_enabled_false`}
                type="radio"
                value="false"
                // value={false}
                checked={!enabled}
                onChange={this.handleEnabledChanged}
                required
              />{" "}
              False
            </label>
          </div>

          <div className="mt-2 font-semibold">Reportable Type:</div>
          <div className="my-1">
            <RsaReportableTypeControl
              value={reportableType}
              handleChange={this.handleReportableTypeChanged}
            ></RsaReportableTypeControl>
          </div>

          <div className="text-red-600">{errorMsg}</div>
        </>
      </div>
    );
  }
}

const RSA_REPORTABLE_TYPES = [
  {
    value: "OFF",
    label: "OFF: Make this key not reportable",
  },
  {
    value: "ON",
    label: "ON: Make this key reportable",
  },
  {
    value: "CUSTOM_DIMENSION",
    label: "CUSTOM_DIMENSION: Make this key custom dimension",
  },
];

function RsaReportableTypeControl({ value, handleChange }) {
  const options = RSA_REPORTABLE_TYPES;

  return (
    <div>
      <select
        className="border rounded px-1"
        value={value}
        onChange={handleChange}
      >
        {options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export default RequestSignalAugmentationControl;
