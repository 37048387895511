import React from "react";
import _ from "lodash";
import TimeOfDayProgressBar from "../../common/TimeOfDayProgressBar";
import { NetworkAPI } from "apis";

class NetworkInfoHeader extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      networkInfo: props.networkInfo,
      isLoading: false,
      errMsg: null,
    };
  }

  async componentDidMount() {
    if (!this.props.networkInfo) {
      this.setState({ isLoading: true });
      try {
        const networkInfo = await NetworkAPI.getNetworkInfo({
          networkId: this.props.networkId,
        });
        if (!networkInfo) {
          throw new Error("Invalid Network");
        }
        this.setState({
          isLoading: false,
          errMsg: null,
          networkInfo,
        });
      } catch (err) {
        console.log(err);
        this.setState({
          isLoading: false,
          errMsg: typeof err === "string" ? err : err.toString(),
        });
      }
    }
  }

  render() {
    const { networkInfo, isLoading, errMsg } = this.state;

    return (
      <div className="shadow border-b sticky top-0 bg-white px-12 py-2">
        {isLoading && "Loading Network Info"}

        {errMsg && <div className="text-red-600">{errMsg}</div>}

        {networkInfo && (
          <div className="flex items-end justify-between">
            <div>
              <div className="text-2xl font-bold leading-none text-gray-900">
                [{networkInfo.networkId}] {networkInfo && networkInfo.name}
              </div>
            </div>
            <div>
              <div className="flex items-center text-base font-semibold">
                <div className="flex items-center">
                  <div className="mr-2">
                    {networkInfo.timezone.replace("_", " ")}
                  </div>
                  <div className="text-xs" style={{ marginTop: "2px" }}>
                    <TimeOfDayProgressBar
                      timezone={networkInfo.timezone}
                    ></TimeOfDayProgressBar>
                  </div>
                </div>
                <span className="mx-2 font-hairline text-gray-500">|</span>
                {networkInfo.currency}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default NetworkInfoHeader;
