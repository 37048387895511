import React from "react";
import _ from "lodash";
import Select from "react-select";
import { NetworkAPI } from "apis";

let CACHE_ITEMS = {};

// For segmentation country selection
// can be multiselect
class VirtualPlacementSelector extends React.PureComponent {
  constructor(props) {
    super(props);
    // props:
    // selectedItems
    // handleChanged

    // const { selectedItems } = props;

    // const selectedValue = _.filter(options, (option) => {
    //   return _.indexOf(selectedItems, option.value) !== -1;
    // });

    this.state = {
      // options,
      // selectedValue,

      isLoading: false,
      errMsg: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      const {
        networkId,
        selectedItems,
        selectMetric = "virtualPlacementId",
      } = this.props;
      if (!networkId) {
        throw new Error("Missing Network ID");
      }

      let items = [];
      const cachedItems = CACHE_ITEMS[networkId];
      if (cachedItems && cachedItems.length > 0) {
        items = cachedItems;
      } else {
        items = await NetworkAPI.getNetworkVirtualPlacements({ networkId });
        CACHE_ITEMS[networkId] = items;
        if (!items) {
          throw new Error("Failed to find virtual placements");
        }
      }

      const options = _.map(items, (item) => {
        return {
          id: item.virtualPlacementId,
          value: item[selectMetric],
          label: `[${item.virtualPlacementId}] ${item.name} (${item.gamUnitIds.length} units)`,
        };
      });

      const { errMsg, selectedValue } = this._fillSelectedOptions({
        selectedItems,
        options,
      });

      this.setState({
        options,
        selectedValue, // array of options
        isLoading: false,
        errMsg,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        errMsg: err.toString(),
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedItems !== this.props.selectedItems) {
      const { options } = this.state;
      const { selectedItems } = this.props;

      const { errMsg, selectedValue } = this._fillSelectedOptions({
        selectedItems,
        options,
      });

      this.setState({
        selectedValue,
        errMsg,
      });
    }
  }

  _fillSelectedOptions({ selectedItems, options }) {
    let selectedValue = [];
    if (selectedItems) {
      selectedValue = _.filter(options, (option) => {
        return _.indexOf(selectedItems, option.value) !== -1;
      });

      if (selectedValue.length !== selectedItems.length) {
        const invalidIds = _.difference(
          selectedItems,
          _.map(selectedValue, "value")
        );
        const errMsg = `Failed to find these items: ${invalidIds.join(", ")}`;
        this.props.handleError && this.props.handleError(errMsg);
        return { errMsg, selectedValue };
      }
    }
    return { errMsg: null, selectedValue };
  }

  handleChange(options) {
    this.setState({
      selectedValue: options,
    });

    this.props.handleChanged(_.map(options, "value"));
  }

  handleInputChange(input) {
    if (_.isEmpty(input)) return;
    // Check if there is seperator
    // user can paste a list of ids
    if (input.includes(",")) {
      let parts = input.split(",");
      parts = _.map(_.compact(parts), _.trim);
      if (parts.length > 0) {
        const selectedValue = _.filter(this.state.options, (option) => {
          return _.indexOf(parts, option.id) !== -1;
        });

        if (selectedValue.length > 0) {
          const newValues = _.uniqBy(
            [...(this.state.selectedValue || []), ...selectedValue],
            "id"
          );
          this.handleChange(newValues);
          return "";
        }
      }
    }

    return input;
  }

  render() {
    const { options, selectedValue, isLoading, errMsg } = this.state;
    const { disabled = false } = this.props;

    return (
      <>
        <Select
          defaultValue={selectedValue}
          value={selectedValue}
          onChange={this.handleChange}
          onInputChange={this.handleInputChange}
          options={options}
          isSearchable={true}
          isMulti={true}
          isDisabled={disabled}
          isLoading={isLoading}
          styles={{
            control: (baseStyles, state) => ({
              // state.isFocused
              ...baseStyles,
              borderColor: errMsg ? "red" : "lightgray",
            }),
          }}
        />

        {errMsg && <div className="text-xs text-red-600">{errMsg}</div>}
      </>
    );
  }
}

export default VirtualPlacementSelector;
