import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
// import NumberFormat from "../../common/NumberFormat";
import DateTimeFormatter from "../../common/DateTimeFormatter";
import SegmentsDetailPopover from "./SegmentsDetailPopover";
import ReactTooltip from "react-tooltip";
import ToggleResearchConfigButton from "./ToggleResearchConfigButton";
import { FiArrowDown } from "react-icons/fi";

class ConfigListTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hoveredConfigId: null,
    };

    this.handleHover = this.handleHover.bind(this);
    this.handleMouseout = this.handleMouseout.bind(this);
  }

  handleHover(configId) {
    this.setState({ hoveredConfigId: configId });
  }

  handleMouseout() {
    this.setState({ hoveredConfigId: null });
  }

  render() {
    let { items, handlePauseConfig, handleResumeConfig } = this.props;

    const thClass =
      "px-4 py-2 text-gray-600 border text-left text-xs uppercase tracking-wide";
    const tdClass = "px-4 py-2 border";

    items = _.orderBy(
      items,
      ["yield_set_id", "unit_id", "segment_id"],
      ["desc", "desc", "desc"]
    );

    let lastUnitId = items[0].unit_id;
    let bgColorIndex = 1;
    items = _.map(items, (item) => {
      if (item.unit_id !== lastUnitId) {
        ++bgColorIndex;
        item.shouldAddBorder = true;
      }
      item.bgColorIndex = bgColorIndex;
      lastUnitId = item.unit_id;
      return item;
    });

    return (
      <div>
        <table
          className="shadow divide-y table-fixed"
          // style={{ width: "1280px" }}
        >
          <thead className="bg-gray-100">
            <tr>
              <th className={thClass} style={{ width: "200px" }}>
                YieldSet
              </th>
              <th className={thClass}>
                <div className="flex items-center justify-between gap-2">
                  Unit ID <FiArrowDown></FiArrowDown>
                </div>
              </th>
              <th className={thClass}>Segment</th>
              <th className={thClass}>Traffic Ratio</th>
              <th className={thClass}>Created At</th>
              <th className={thClass}>Action</th>
              <th className={thClass}>Link</th>
            </tr>
          </thead>
          <tbody
            className="text-gray-800 bg-white"
            onMouseOut={this.handleMouseout}
          >
            {items.map((item) => {
              return (
                <tr
                  key={item.config_id}
                  className={`${
                    item.shouldAddBorder && " border-t-2 border-indigo-200"
                  }`}
                  onMouseOver={() => this.handleHover(item.config_id)}
                >
                  <td className={tdClass}>
                    {item.yield_set_id}{" "}
                    <span className="text-gray-700 text-sm">
                      {item.yield_set_name}
                    </span>
                  </td>
                  <td className={tdClass}>
                    <span data-tip data-for={`${item.config_id}-unitname`}>
                      {item.unit_id}
                    </span>

                    <ReactTooltip
                      id={`${item.config_id}-unitname`}
                      type="dark"
                      effect="solid"
                      place={"top"}
                    >
                      {item.unit_name}
                    </ReactTooltip>
                  </td>
                  <td className={tdClass}>
                    <SegmentsDetailPopover
                      triggerElement={
                        <div className="underline">{item.segment_id}</div>
                      }
                      segments={[
                        {
                          segmentId: item.segment_id,
                          mode: item.segment_mode,
                          targeting: item.targeting,
                        },
                      ]}
                    ></SegmentsDetailPopover>
                  </td>
                  <td className={tdClass}>
                    <div className="flex justify-between items-center">
                      <span className="font-semibold">
                        {item.traffic_ratio}
                      </span>

                      <ToggleResearchConfigButton
                        configId={item.config_id}
                        trafficRatio={item.traffic_ratio}
                        unitId={item.unit_id}
                        handlePauseConfig={handlePauseConfig}
                        handleResumeConfig={handleResumeConfig}
                      ></ToggleResearchConfigButton>
                    </div>
                  </td>
                  <td className={tdClass}>
                    <DateTimeFormatter
                      datetime={item.created_at}
                    ></DateTimeFormatter>
                  </td>
                  <td className={tdClass}>
                    <div className="flex items-center justify-between">
                      <Link
                        to={`/segment-research/config/${item.config_id}/update`}
                        className="px-2 py-2 mr-1 text-sm bg-blue-400 rounded shadow hover:bg-blue-600 text-white font-semibold"
                      >
                        Config
                      </Link>
                    </div>
                  </td>
                  <td className={tdClass}>
                    <div className="flex gap-1">
                      <button
                        type="button"
                        className="px-2 py-2 mr-1 text-sm text-blue-700 underline rounded hover:bg-blue-100"
                        onClick={() =>
                          window.open(
                            `${window.location.origin}/#/anatomy/${item.unit_id}?segmentId=${item.segment_id}`
                          )
                        }
                      >
                        Anatomy
                      </button>
                      <button
                        type="button"
                        className="px-2 py-2 mr-1 text-sm text-blue-700 underline rounded hover:bg-blue-100"
                        onClick={() =>
                          window.open(
                            `${window.location.origin}/#/unit-insight/${item.unit_id}`
                          )
                        }
                      >
                        Insight
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ConfigListTable;
