import React from "react";
import ClickToCopyWrapper from "../common/ClickToCopyWrapper";
import GoToAPConfigButton from "../common/GoToAPConfigButton";
import GoToInsightButton from "../common/GoToInsightButton";

class ObserverHeader extends React.PureComponent {
  render() {
    const data = this.props.data;
    const extUnitParentPath = data.unitInfo.extUnitParentPath;
    const unitPath = `${extUnitParentPath}${
      extUnitParentPath === "/" ? "" : "/"
    }${data.unitInfo.code}`;

    return (
      <>
        <div
          className="px-4 py-4 text-gray-100 text-2xl font-semibold w-full shadow-md"
          style={{ backgroundColor: "#0f202f" }}
        >
          <div className="flex justify-between">
            <div>
              <div className="text-base pr-2">
                {data.networkSetting.networkId}{" "}
                {data.networkSetting.networkName}
              </div>

              <div className="leading-snug flex items-center">
                <div className="text-base font-bold p-1 bg-orange-500 text-white rounded mr-2">
                  Unit {data.unitId}
                </div>{" "}
                {data.unitInfo.name}
              </div>

              <div className="flex items-center text-sm text-gray-100 font-normal">
                <div className="flex items-center pr-3" title="Ext Unit ID">
                  {data.unitInfo.extUnitId}{" "}
                  <ClickToCopyWrapper
                    copyText={data.unitInfo.extUnitId}
                  ></ClickToCopyWrapper>
                </div>
                <div className="flex items-center" title="Ext Unit Path">
                  {unitPath}{" "}
                  <ClickToCopyWrapper copyText={unitPath}></ClickToCopyWrapper>
                </div>
              </div>
            </div>
            <div className="text-lg text-right">
              <div>
                CostCPM:{" "}
                <span style={{ fontSize: "smaller" }} className="text-gray-400">
                  $
                </span>
                {data.networkSetting.costCPM}{" "}
                <span
                  style={{ fontSize: "smaller" }}
                  className="text-gray-400 font-mono"
                >
                  USD
                </span>
              </div>
              {data.networkSetting.currency !== "USD" && (
                <div className="text-right text-gray-300">
                  ~{" "}
                  <span
                    style={{ fontSize: "smaller" }}
                    className="text-gray-400"
                  >
                    $
                  </span>
                  {data.costCPMInCurrency}{" "}
                  <span
                    style={{ fontSize: "smaller" }}
                    className="text-gray-400 font-mono"
                  >
                    {data.networkSetting.currency}
                  </span>
                </div>
              )}
              <div className="flex gap-4 justify-end mt-1">
                <GoToInsightButton unitId={data.unitId}></GoToInsightButton>
                <GoToAPConfigButton unitId={data.unitId}></GoToAPConfigButton>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ObserverHeader;
