import React from "react";
import _ from "lodash";
import RecipeBox from "./recipe/RecipeBox";
import { Link } from "react-router-dom";
import ModalWrapper from "../common/ModalWrapper";
import { RECIPE_STATUS } from "../../constants/Recipes";
import { VseAPI } from "apis";
import { notify } from "react-notify-toast";

class RecipesView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,

      recipes: this.props.recipes, // original all items
      filteredItems: [], // show these in ui

      searchText: "",
      statusFilter: -1,

      selectedRecipe: null, // for change status
      isModalOpen: false,
      isSaving: false,
      saveErrMsg: null,
    };

    this.handleStatusFilterChanged = this.handleStatusFilterChanged.bind(this);
    this.handleSearchChanged = this.handleSearchChanged.bind(this);

    this.changeStatusClicked = this.changeStatusClicked.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleUpdateRecipeStatus = this.handleUpdateRecipeStatus.bind(this);
  }

  componentDidMount() {
    document.title = `Recipes | VSE`;
    this.handleStatusFilterChanged(1);
  }

  handleStatusFilterChanged(statusFilter) {
    const { recipes } = this.state;

    const filteredItems = this._filterRecipes(
      recipes,
      this.state.searchText,
      statusFilter
    );
    this.setState({
      statusFilter,
      filteredItems,
    });
  }

  handleSearchChanged(event) {
    const searchText = event.target.value;
    const { recipes } = this.state;

    const filteredItems = this._filterRecipes(
      recipes,
      searchText,
      this.state.statusFilter
    );
    this.setState({
      searchText,
      filteredItems,
    });
  }

  _filterRecipes(allItems, searchText, statusFilter) {
    const search = searchText.toLowerCase();
    const filteredItems = _.filter(allItems, (item) => {
      return (
        (statusFilter === -1 ? true : item.status === statusFilter) &&
        // _.includes(item.recipeId.toString(), search)
        (search == item.recipeId || _.includes(item.name.toLowerCase(), search))
      );
    });
    return filteredItems;
  }

  changeStatusClicked(selectedRecipe) {
    this.setState({
      isModalOpen: true,
      selectedRecipe,
    });
  }

  handleModalClose() {
    this.setState({
      isModalOpen: false,
      selectedRecipe: null,
      saveErrMsg: null,
    });
  }

  async handleUpdateRecipeStatus({ recipeId, status }) {
    this.setState({ isSaving: true });
    try {
      console.log("update status", recipeId, status);
      const { result } = await VseAPI.updateRecipeStatus({ recipeId, status });
      if (result.op === "ok") {
        notify.show("Status changed! Reloading page...", "success");
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      } else {
        throw `Failed response: ${JSON.stringify(result)}`;
      }
    } catch (err) {
      this.setState({ saveErrMsg: err });
    }
    this.setState({ isSaving: false });
  }

  render() {
    const {
      recipes,
      filteredItems,
      searchText,
      statusFilter,
      selectedRecipe,
      isModalOpen,
      isSaving,
      saveErrMsg,
    } = this.state;

    // recipeStatus: 1: Running, 2: Paused, 3: Archived
    return (
      <>
        <div className="mb-4 bg-white p-4 border rounded">
          <div className="mb-3">
            <button
              type="button"
              className={`px-3 py-1 mr-1 font-semibold rounded ${
                statusFilter === -1
                  ? "bg-indigo-100 text-indigo-800"
                  : "text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
              }`}
              onClick={() => this.handleStatusFilterChanged(-1)}
            >
              All
            </button>
            <button
              type="button"
              className={`px-3 py-1 mr-1 font-semibold rounded ${
                statusFilter === 1
                  ? "bg-indigo-100 text-indigo-800"
                  : "text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
              }`}
              onClick={() => this.handleStatusFilterChanged(1)}
            >
              Running
            </button>
            <button
              type="button"
              className={`px-3 py-1 mr-1 font-semibold rounded ${
                statusFilter === 2
                  ? "bg-indigo-100 text-indigo-800"
                  : "text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
              }`}
              onClick={() => this.handleStatusFilterChanged(2)}
            >
              Paused
            </button>
            <button
              type="button"
              className={`px-3 py-1 mr-1 font-semibold rounded ${
                statusFilter === 3
                  ? "bg-indigo-100 text-indigo-800"
                  : "text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
              }`}
              onClick={() => this.handleStatusFilterChanged(3)}
            >
              Archived
            </button>
          </div>
          <input
            type="text"
            className="bg-white flex w-full border-2 border-gray-300 rounded py-1 px-4 appearance-none leading-normal focus:outline-none focus:border-blue-400 focus:shadow-inner hover:shadow hover:border-blue-300"
            placeholder="Search by recipe id or name"
            onChange={this.handleSearchChanged}
            value={searchText}
          ></input>
        </div>

        <div>
          <div className="flex justify-between items-center mb-4">
            <div className="text-gray-700">
              {recipes ? (
                <span>
                  Showing {filteredItems.length} out of {recipes.length} recipes
                </span>
              ) : (
                "No recipes"
              )}
            </div>

            <Link
              to={`/vse/recipe/create`}
              className="px-4 py-2 mr-1 my-1 bg-blue-400 rounded shadow hover:bg-blue-600 text-white font-semibold"
            >
              Create Recipe
            </Link>
          </div>

          {recipes && (
            <div className="flex flex-col gap-4">
              {recipes &&
                filteredItems.map((recipe) => {
                  return (
                    <div className="flex" key={recipe.id}>
                      <div className="w-2/3">
                        <RecipeBox key={recipe.id} item={recipe}></RecipeBox>
                      </div>
                      <div className="w-1/3 px-2 flex flex-col gap-4">
                        <div className="mt-1">
                          <Link
                            to={`/vse/recipe/${recipe.id}/update`}
                            className="px-4 py-2 font-semibold text-gray-700 rounded bg-white shadow hover:bg-gray-300"
                          >
                            Update
                          </Link>
                        </div>
                        <div>
                          <button
                            type="button"
                            className="px-4 py-2 font-semibold text-gray-700 rounded bg-white shadow hover:bg-gray-300"
                            onClick={() => this.changeStatusClicked(recipe)}
                          >
                            Change status
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}

          {selectedRecipe && (
            <RecipeChangeStatusModal
              recipe={selectedRecipe}
              isModalOpen={isModalOpen}
              isSaving={isSaving}
              saveErrMsg={saveErrMsg}
              handleModalClose={this.handleModalClose}
              handleSave={this.handleUpdateRecipeStatus}
            ></RecipeChangeStatusModal>
          )}
        </div>
      </>
    );
  }
}

class RecipeChangeStatusModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      status: props.recipe.status,
    };

    this.handleStatusChanged = this.handleStatusChanged.bind(this);
    this.handleSaveClicked = this.handleSaveClicked.bind(this);
  }

  handleStatusChanged(e) {
    this.setState({ status: _.parseInt(e.target.value) });
  }

  handleSaveClicked() {
    const recipeId = this.props.recipe.id;
    const status = this.state.status;
    this.props.handleSave({ recipeId, status });
  }

  render() {
    const { recipe, isModalOpen, isSaving, saveErrMsg, handleModalClose } =
      this.props;
    const { status } = this.state;

    return (
      <ModalWrapper
        isOpen={isModalOpen}
        handleClose={handleModalClose}
        width="60%"
        showCloseFooter={false}
      >
        <div>
          <div className="font-bold text-xl text-gray-700 mb-2">
            Change Status:
          </div>

          <div style={{ height: "500px", overflow: "scroll" }}>
            <RecipeBox item={recipe}></RecipeBox>

            <div className="mb-4">
              <div className="font-semibold">Status</div>
              <div className="w-1/2">
                <div className="flex flex-row gap-4 text-gray-900">
                  <label htmlFor="radio_running" className="cursor-pointer">
                    <input
                      id="radio_running"
                      type="radio"
                      value={RECIPE_STATUS.RUNNING}
                      checked={status === RECIPE_STATUS.RUNNING}
                      onChange={this.handleStatusChanged}
                    />{" "}
                    Running
                  </label>

                  <label htmlFor="radio_pause" className="cursor-pointer">
                    <input
                      id="radio_pause"
                      type="radio"
                      value={RECIPE_STATUS.PAUSE}
                      checked={status === RECIPE_STATUS.PAUSE}
                      onChange={this.handleStatusChanged}
                    />{" "}
                    Pause
                  </label>

                  <label htmlFor="radio_archive" className="cursor-pointer">
                    <input
                      id="radio_archive"
                      type="radio"
                      value={RECIPE_STATUS.ARCHIVE}
                      checked={status === RECIPE_STATUS.ARCHIVE}
                      onChange={this.handleStatusChanged}
                    />{" "}
                    Archive
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row-reverse mt-4 items-center">
            <div>
              <button
                type="button"
                className="px-4 py-2 bg-blue-500 rounded shadow hover:bg-blue-700 text-white font-semibold"
                onClick={this.handleSaveClicked}
              >
                {isSaving ? "Saving..." : "Save"}
              </button>
            </div>
            <div>
              <button
                type="button"
                disabled={isSaving}
                className={`px-4 py-2 text-blue-700 ${
                  isSaving ? "cursor-not-allowed" : ""
                }`}
                onClick={handleModalClose}
              >
                Cancel
              </button>
            </div>
            <div className="text-red-600">{saveErrMsg}</div>
          </div>
        </div>
      </ModalWrapper>
    );
  }
}

export default RecipesView;
