import React from "react";
import _ from "lodash";

import ModalWrapper from "../../../common/ModalWrapper";
import {
  getCatchallPricesLimit,
  getDetectorPricesLimit,
  getPricesLimit,
  getXPricesLimit,
  isPricesInvalid,
  validateUnitSettings,
} from "./UPRSegmentationHelper";
import UnitSettingInputTextArea from "./UnitSettingInputTextArea";
import UnitsSelector from "components/common/UnitsSelector";
import ClickToCopyWrapper from "components/common/ClickToCopyWrapper";

class UnitSettingModal extends React.PureComponent {
  constructor(props) {
    super(props);

    const { currentRule } = this.props;
    const inputSettingsString = JSON.stringify(
      currentRule.unit_segmented_price_settings,
      null,
      4
    );

    this.state = {
      inputSettingsString,

      previewSettings: null, // should be valid

      isValid: false,
      isValidated: false,
      errors: null,
    };

    this.handleInputChanged = this.handleInputChanged.bind(this);
    this.handleValidateAndPreview = this.handleValidateAndPreview.bind(this);
    this.handleUnitsError = this.handleUnitsError.bind(this);
  }

  componentDidMount() {
    if (
      this.state.inputSettingsString &&
      !_.isEmpty(this.state.inputSettingsString)
    ) {
      this.handleValidateAndPreview();
    }
  }

  handleInputChanged(input) {
    this.setState({ inputSettingsString: input, isValidated: false });
  }

  handleValidateAndPreview() {
    const { inputSettingsString } = this.state;

    const settings = JSON.parse(inputSettingsString);
    const errors = validateUnitSettings(settings);

    if (errors) {
      this.setState({
        isValid: false,
        isValidated: true,
        errors,
        previewSettings: null,
      });
    } else {
      // all good! -> Preview!
      this.setState({
        isValid: true,
        isValidated: true,
        errors: null,
        previewSettings: settings,
      });
    }
  }

  handleUnitsError(error) {
    console.log("handleUnitsError", error);
    const errors = this.state.errors ? [...this.state.errors, error] : [error];
    this.setState({
      isValid: false,
      errors,
      // previewSettings: null,
    });
  }

  render() {
    const {
      networkInfo,
      formErrMsg,
      isUnitSettingModalOpen,
      currentRule,
      priceSuggestions,
      isSaving,
      handleSaveUnitSettings,
      handleRemoveUnitSettings,
      handleCloseModal,
    } = this.props;
    const {
      inputSettingsString,
      previewSettings,
      isValid,
      isValidated,
      errors,
    } = this.state;

    return (
      <ModalWrapper
        isOpen={isUnitSettingModalOpen}
        showCloseFooter={false}
        handleClose={handleCloseModal}
        width="90%"
      >
        <div>
          <div className="border-b -mx-4 px-4 pb-2 text-xl font-bold text-gray-700">
            Unit Price Settings for{" "}
            <span className="font-semibold">
              {currentRule.id} {currentRule.name}
            </span>
            <table className="border table w-full text-sm font-normal">
              <thead className="border-b bg-gray-200 text-xs text-blue-800">
                <tr>
                  <th className="border py-1 px-2 text-right">ID</th>
                  <th className="border py-1 px-2 text-left ">Name</th>
                  <th className="border py-1 px-2 text-left ">Optimization</th>
                  <th className="border py-1 px-2 text-left ">Prices</th>
                </tr>
              </thead>
              <tbody className="bg-white  text-gray-900">
                <tr className="border-b">
                  <td className="border py-1 px-2 text-right">
                    {currentRule.id}
                  </td>

                  <td className="border py-1 px-2 text-left font-medium">
                    {currentRule.name}
                  </td>

                  <td className="border py-1 px-2 text-left">
                    {currentRule.optimization}
                  </td>

                  <td className="border py-1 px-1 text-left text-xs">
                    <div>
                      <div className="border-b mb-1">
                        <span className="font-semibold text-gray-800">
                          Prices:
                          {currentRule.optimization !== "dt" && (
                            <span>({currentRule.prices.length})</span>
                          )}
                        </span>{" "}
                        <br></br>
                        {currentRule.optimization !== "dt" ? (
                          <div>{currentRule.prices.join(", ")}</div>
                        ) : (
                          "-"
                        )}
                      </div>
                      <div className="border-b mb-1">
                        <span className="font-semibold text-gray-800">
                          Detector Prices: ({currentRule.detector_prices.length}
                          )
                        </span>
                        <br></br> {currentRule.detector_prices.join(", ")}
                      </div>
                      <div className="border-b mb-1">
                        <span className="font-semibold text-gray-800">
                          Catchall Prices:{" "}
                          {currentRule.catchall_prices && (
                            <>({currentRule.catchall_prices.length})</>
                          )}
                        </span>
                        <br></br>{" "}
                        {currentRule.catchall_prices
                          ? currentRule.catchall_prices.join(", ")
                          : "-"}
                      </div>
                      <div>
                        <span className="font-semibold text-gray-800">
                          X Prices:{" "}
                          {currentRule.x_prices && (
                            <>({currentRule.x_prices.length})</>
                          )}
                        </span>
                        <br></br>{" "}
                        {currentRule.x_prices
                          ? currentRule.x_prices.join(", ")
                          : "-"}
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="my-2 overflow-y-auto" style={{ height: "420px" }}>
            <div className="flex gap-1">
              <div className="w-1/2">
                <div className="font-bold text-gray-700">Input:</div>
                <UnitSettingInputTextArea
                  inputValue={inputSettingsString}
                  handleInputChanged={this.handleInputChanged}
                  handleValidateAndPreview={this.handleValidateAndPreview}
                  errors={errors}
                  isSettingsValid={isValid}
                  isSettingsValidated={isValidated}
                ></UnitSettingInputTextArea>
              </div>

              <div className="w-1/2">
                <div className="font-bold text-gray-700">
                  Preview: {previewSettings && `(${previewSettings.length})`}
                </div>
                <div>
                  {errors &&
                    errors.map((errMsg) => {
                      return (
                        <div
                          key={errMsg}
                          className="mb-1 bg-red-100 px-4 py-2 text-red-600"
                        >
                          {errMsg}
                        </div>
                      );
                    })}
                </div>
                <div style={{ height: "360px", overflow: "auto" }}>
                  {previewSettings && (
                    <UnitSettingsView
                      settings={previewSettings}
                      currentRule={currentRule}
                      priceSuggestions={priceSuggestions}
                      networkInfo={networkInfo}
                      optimization={currentRule.optimization}
                      handleError={this.handleUnitsError}
                    ></UnitSettingsView>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="border-t -mx-6 px-6 pt-4">
            <div className="flex items-center justify-end">
              <div className="flex flex-row-reverse gap-4">
                <button
                  type="button"
                  className={`px-4 py-2 ${
                    isSaving || !isValid
                      ? "cursor-not-allowed bg-gray-400 text-gray-800"
                      : "bg-blue-500 text-white hover:bg-blue-700"
                  }  rounded shadow font-semibold`}
                  onClick={() => handleSaveUnitSettings(previewSettings)}
                  disabled={isSaving || !isValid}
                >
                  {isSaving ? "Saving..." : "Save Unit Settings"}
                </button>

                <button
                  type="button"
                  className={`px-4 py-2 ${
                    isSaving
                      ? "cursor-not-allowed bg-gray-400 text-gray-800"
                      : "bg-red-500 text-white hover:bg-red-700"
                  }  rounded shadow font-semibold`}
                  onClick={handleRemoveUnitSettings}
                  disabled={isSaving || !isValid}
                >
                  Remove Unit Settings
                </button>

                <button
                  type="button"
                  disabled={isSaving}
                  className={`px-4 py-2 text-blue-700 ${
                    isSaving ? "cursor-not-allowed" : ""
                  }`}
                  onClick={handleCloseModal}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="text-red-600">{formErrMsg}</div>
          </div>
        </div>
      </ModalWrapper>
    );
  }
}

class UnitSettingsView extends React.Component {
  render() {
    const {
      currentRule,
      settings,
      priceSuggestions,
      networkInfo,
      optimization,
      handleError,
    } = this.props;

    return (
      <div className="flex flex-col gap-4">
        {settings.map((rule) => {
          const unitPriceSuggestions = _.get(priceSuggestions, [
            "suggestionsBySegment",
            currentRule.id,
            "pricesByUnit",
            rule.id,
          ]);
          return (
            <div
              key={rule.id}
              className="rounded bg-gray-100 p-2 text-sm text-gray-800"
            >
              {/* <div>{JSON.stringify(rule)}</div> */}

              <div className="mb-2 flex gap-2">
                <div>
                  <span className="font-semibold">ID: </span>
                  {rule.id}
                </div>
                <div>
                  <span className="font-semibold">Name: </span> {rule.name}
                </div>
              </div>

              <div className="mb-2">
                <div className="font-semibold">
                  Units: ({rule.units.length})
                </div>
                <UnitsSelector
                  networkId={networkInfo.networkId}
                  selectedItems={rule.units}
                  selectMetric="unitId"
                  disabled={true}
                  handleError={handleError}
                ></UnitsSelector>
              </div>

              <div>
                <AllThePricesView
                  optimization={optimization}
                  prices={rule.prices}
                  unitPriceSuggestions={unitPriceSuggestions}
                  detector_prices={rule.detector_prices}
                  catchall_prices={rule.catchall_prices}
                  x_prices={rule.x_prices || []}
                  handleError={handleError}
                ></AllThePricesView>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

class PricesValidView extends React.PureComponent {
  constructor(props) {
    super(props);

    const { prices, limit, type } = this.props;
    const { isInvalid, errMsg } = isPricesInvalid({ prices, limit });

    if (isInvalid) {
      this.props.handleError && this.props.handleError(`${type}: ${errMsg}`);
    }

    this.state = { isInvalid, errMsg };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.prices !== this.props.prices) {
      const { prices, limit, type } = this.props;
      const { isInvalid, errMsg } = isPricesInvalid({ prices, limit });

      if (isInvalid) {
        this.props.handleError && this.props.handleError(`${type}: ${errMsg}`);
      }

      this.setState({ isInvalid, errMsg });
    }
  }

  render() {
    const { isInvalid, errMsg } = this.state;

    if (isInvalid) {
      return <div className="text-red-600">{errMsg}</div>;
    }

    return "";
  }
}

class AllThePricesView extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      optimization,
      prices,
      unitPriceSuggestions,
      detector_prices,
      catchall_prices,
      x_prices,
      handleError,
    } = this.props;

    const pLimit = getPricesLimit({ optimization });
    const dLimit = getDetectorPricesLimit({ optimization });
    const cLimit = getCatchallPricesLimit({ optimization });
    const xLimit = getXPricesLimit({ optimization });

    return (
      <div>
        <div className="border-b mb-1">
          <span className="font-semibold text-gray-800">
            Prices:
            {optimization !== "dt" && <span>({prices.length})</span>}
          </span>{" "}
          <br></br>
          {optimization !== "dt" ? <div>{prices.join(", ")}</div> : "-"}
          <PricesValidView
            type="prices"
            prices={prices}
            limit={pLimit}
            handleError={handleError}
          ></PricesValidView>
          {unitPriceSuggestions && (
            <div className=" text-orange-700">
              <span className="font-semibold">
                Suggestion:(
                {unitPriceSuggestions && unitPriceSuggestions.length})
              </span>{" "}
              <br></br>
              <div className="flex items-center gap-1">
                {unitPriceSuggestions ? unitPriceSuggestions.join(", ") : "-"}
                <ClickToCopyWrapper
                  copyText={unitPriceSuggestions.join(", ")}
                ></ClickToCopyWrapper>
              </div>
            </div>
          )}
        </div>

        <div className="border-b mb-1">
          <span className="font-semibold text-gray-800">
            Detector Prices: ({detector_prices.length})
          </span>
          <br></br> {detector_prices.join(", ")}
          <PricesValidView
            type="detector_prices"
            prices={detector_prices}
            limit={dLimit}
            handleError={handleError}
          ></PricesValidView>
        </div>

        <div className="border-b mb-1">
          <span className="font-semibold text-gray-800">
            Catchall Prices:{" "}
            {catchall_prices && <>({catchall_prices.length})</>}
          </span>
          <br></br> {catchall_prices ? catchall_prices.join(", ") : "-"}
          <PricesValidView
            type="catchall_prices"
            prices={catchall_prices}
            limit={cLimit}
            handleError={handleError}
          ></PricesValidView>
        </div>

        <div>
          <span className="font-semibold text-gray-800">
            X Prices: {x_prices && <>({x_prices.length})</>}
          </span>
          <br></br> {x_prices ? x_prices.join(", ") : "-"}
          <PricesValidView
            type="x_prices"
            prices={x_prices}
            limit={xLimit}
            handleError={handleError}
          ></PricesValidView>
        </div>
      </div>
    );
  }
}

export default UnitSettingModal;
