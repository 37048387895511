import React from "react";
import _ from "lodash";

import CustomTargetingKeysSelector from "./CustomTargetingKeysSelector";
import CustomTargetingValuesSelector from "./CustomTargetingValuesSelector";
import AmpRtcSection from "./AmpRtcSection";
import ClickToCopyWrapper from "components/common/ClickToCopyWrapper";

const titleClass = "block text-gray-800 text-sm font-bold"; //"font-semibold";

class CSTGeneralControl extends React.Component {
  constructor(props) {
    super(props);

    const { config, handleConfigChanged } = this.props;

    const keyId = _.get(config, ["key", "id"]);
    let managedValues = _.get(config, ["managedValues"]);
    const benchmarkValues = _.get(config, ["benchmarkValues"]);
    let managedValueIds =
      managedValues && managedValues.length > 0 && _.map(managedValues, "id");

    const benchmarkValueIds =
      benchmarkValues &&
      benchmarkValues.length > 0 &&
      _.map(benchmarkValues, "id");

    const ampRtc = _.get(config, ["amp_rtc"], {});

    this.state = {
      newConfig: _.cloneDeep(config),

      keyId,
      isCustomDimensionDisabled: !config["customDimensionEnabled"],
      customDimensionEnabled: config["customDimensionEnabled"],
      managedValueIds,
      benchmarkValueIds,

      amp_rtc: ampRtc,

      errMsg: null,
    };

    this.handleTargetingKeyChanged = this.handleTargetingKeyChanged.bind(this);
    this.handleCustomDimensionEnabledChanged =
      this.handleCustomDimensionEnabledChanged.bind(this);
    this.handleManagedValuesChanged =
      this.handleManagedValuesChanged.bind(this);
    this.handleBenchValuesChanged = this.handleBenchValuesChanged.bind(this);
    this.handleAmpRtcChanged = this.handleAmpRtcChanged.bind(this);
  }

  handleTargetingKeyChanged(option) {
    // console.log("tk chanaged", option);

    if (!option) {
      let { newConfig } = this.state;
      newConfig["key"] = {
        id: "",
        name: "",
      };
      this.handleCustomDimensionEnabledChanged(false);

      this.setState({ newConfig, keyId: null });
      this.props.handleConfigChanged(newConfig);
    } else {
      let { newConfig } = this.state;
      newConfig["key"] = {
        id: option.id,
        name: option.name,
      };

      this.setState({ newConfig, keyId: option.id });

      if (!_.has(newConfig, "customDimensionEnabled")) {
        if (option.reportableType === "CUSTOM_DIMENSION") {
          this.handleCustomDimensionEnabledChanged(true);
        } else if (option.reportableType === "ON") {
          this.handleCustomDimensionEnabledChanged(false);
        } else {
          this.handleCustomDimensionEnabledChanged(false);
        }
      }
      this.setState({
        isCustomDimensionDisabled: option.reportableType === "ON",
      });

      this.props.handleConfigChanged(newConfig);
    }
  }

  handleCustomDimensionEnabledChanged(isEnabled) {
    let { newConfig } = this.state;
    newConfig["customDimensionEnabled"] = isEnabled;
    this.setState({ customDimensionEnabled: isEnabled, newConfig });

    this.props.handleConfigChanged(newConfig);
  }

  handleManagedValuesChanged(options) {
    // console.log("managed values chanaged", options);
    if (!options || _.isEmpty(options)) {
      let { newConfig } = this.state;
      newConfig["managedValues"] = [];
      const managedValueIds = null;

      this.setState({ newConfig, managedValueIds });

      this.props.handleConfigChanged(newConfig);
    } else {
      let { newConfig } = this.state;
      newConfig["managedValues"] = _.map(options, (o) => {
        return { id: o.id, name: o.name };
      });
      const managedValueIds = _.map(newConfig["managedValues"], "id");

      this.setState({ newConfig, managedValueIds });
      this.props.handleConfigChanged(newConfig);
    }
    this.checkIsConfigInvalid();
  }

  handleBenchValuesChanged(options) {
    // console.log("benched values chanaged", options);
    if (!options || _.isEmpty(options)) {
      let { newConfig } = this.state;
      newConfig["benchmarkValues"] = [];
      const benchmarkValueIds = null;

      this.setState({ newConfig, benchmarkValueIds });
      this.props.handleConfigChanged(newConfig);
    } else {
      let { newConfig } = this.state;
      newConfig["benchmarkValues"] = _.map(options, (o) => {
        return { id: o.id, name: o.name };
      });
      const benchmarkValueIds = _.map(newConfig["benchmarkValues"], "id");

      this.setState({ newConfig, benchmarkValueIds });
      this.props.handleConfigChanged(newConfig);
    }
    this.checkIsConfigInvalid();
  }

  handleAmpRtcChanged(ampRtc) {
    let { newConfig } = this.state;
    if (!ampRtc.enabled) {
      const newAmpRtc = {
        enabled: false,
      };
      newConfig.amp_rtc = newAmpRtc;
      this.setState({ amp_rtc: newAmpRtc, newConfig });

      this.props.handleConfigChanged(newConfig);
    } else {
      let newAmpRtc = {
        enabled: ampRtc.enabled,
        managed_ratio: ampRtc.managedRatio,
        subdomain_name: ampRtc.subdomainName,
      };
      if (
        ampRtc.domainSpecificManagedRatios &&
        ampRtc.domainSpecificManagedRatios.length > 0
      ) {
        newAmpRtc.domain_specific_managed_ratios =
          ampRtc.domainSpecificManagedRatios;
      }
      newConfig.amp_rtc = newAmpRtc;
      this.setState({ amp_rtc: newAmpRtc, newConfig });

      this.props.handleConfigChanged(newConfig);
    }
  }

  checkIsConfigInvalid() {
    const { newConfig } = this.state;
    const { managedValues, benchmarkValues } = newConfig;
    const mIds = _.map(managedValues, "id");
    const bIds = _.map(benchmarkValues, "id");
    const commonIds = _.intersection(mIds, bIds);
    const isInvalid = commonIds.length > 0;

    this.props.handleConfigIsInvalid(isInvalid);
    if (isInvalid) {
      this.setState({
        errMsg: `${commonIds.join(
          ", "
        )} cannot be in both Managed Values and Benchmark Values`,
      });
    } else {
      this.setState({ errMsg: null });
    }
  }

  render() {
    const { networkId, networkInfo, handleConfigIsInvalid } = this.props;
    const {
      keyId,
      newConfig,
      isCustomDimensionDisabled,
      customDimensionEnabled,
      errMsg,
      managedValueIds,
      benchmarkValueIds,
      amp_rtc,

      showRawManagedValues,
      showRawBenchmarkValues,
    } = this.state;

    return (
      <>
        <div className="bg-white p-4">
          <div className="mb-4">
            <label className={titleClass}>
              <div className="flex items-center">
                Key:
                {keyId && (
                  <ClickToCopyWrapper
                    copyText={keyId}
                    tooltipMessage="Copy Key ID"
                  ></ClickToCopyWrapper>
                )}
              </div>
            </label>
            <CustomTargetingKeysSelector
              networkId={networkId}
              keyId={keyId}
              handleOnChange={this.handleTargetingKeyChanged}
              removeReportableOffOption={true}
            ></CustomTargetingKeysSelector>
          </div>

          <div className="mb-4">
            <label className={titleClass}>Handle Billing Data Using: </label>
            <div className="flex gap-4">
              <label
                htmlFor="custom_dimension_use_cd"
                className="cursor-pointer"
              >
                <input
                  id="custom_dimension_use_cd"
                  type="radio"
                  value="true"
                  checked={customDimensionEnabled === true}
                  onChange={() =>
                    this.handleCustomDimensionEnabledChanged(true)
                  }
                  required
                  disabled={isCustomDimensionDisabled}
                />{" "}
                Custom Dimension
              </label>

              <label
                htmlFor="custom_dimension_use_reportable"
                className="cursor-pointer"
              >
                <input
                  id="custom_dimension_use_reportable"
                  type="radio"
                  value="false"
                  checked={customDimensionEnabled === false}
                  onChange={() =>
                    this.handleCustomDimensionEnabledChanged(false)
                  }
                  required
                />{" "}
                Reportable
              </label>
            </div>
          </div>

          <div className="mb-4">
            <div className="flex items-center justify-between">
              <label className={titleClass}>
                <div className="flex items-center">
                  Managed Values:
                  {managedValueIds && managedValueIds.length > 0 && (
                    <ClickToCopyWrapper
                      copyText={managedValueIds.toString()}
                      tooltipMessage="Copy Value IDs"
                    ></ClickToCopyWrapper>
                  )}
                </div>
              </label>
              {managedValueIds && managedValueIds.length > 0 && (
                <button
                  className="text-xs text-blue-600"
                  onClick={() => {
                    this.setState({
                      showRawManagedValues: !this.state.showRawManagedValues,
                    });
                  }}
                >
                  {showRawManagedValues ? "Hide" : "Show"} raw values
                </button>
              )}
            </div>
            {managedValueIds &&
              managedValueIds.length > 0 &&
              showRawManagedValues && (
                <div>
                  <textarea
                    className="border rounded w-full text-xs text-gray-700"
                    value={JSON.stringify(newConfig.managedValues)}
                    disabled={true}
                  ></textarea>
                </div>
              )}
            {keyId ? (
              <CustomTargetingValuesSelector
                networkId={networkId}
                keyId={keyId}
                managedValueIds={managedValueIds}
                handleOnChange={this.handleManagedValuesChanged}
                isMulti
              ></CustomTargetingValuesSelector>
            ) : (
              <div className="text-sm text-gray-700">Select Key first</div>
            )}
          </div>

          <div className="mb-4">
            <div className="flex items-center justify-between">
              <label className={titleClass}>
                <div className="flex items-center">
                  Benchmark Values:
                  {benchmarkValueIds && benchmarkValueIds.length > 0 && (
                    <ClickToCopyWrapper
                      copyText={benchmarkValueIds.toString()}
                      tooltipMessage="Copy Value IDs"
                    ></ClickToCopyWrapper>
                  )}
                </div>
              </label>
              {benchmarkValueIds && benchmarkValueIds.length > 0 && (
                <button
                  className="text-xs text-blue-600"
                  onClick={() => {
                    this.setState({
                      showRawBenchmarkValues:
                        !this.state.showRawBenchmarkValues,
                    });
                  }}
                >
                  {showRawBenchmarkValues ? "Hide" : "Show"} raw values
                </button>
              )}
            </div>
            {benchmarkValueIds &&
              benchmarkValueIds.length > 0 &&
              showRawBenchmarkValues && (
                <div>
                  <textarea
                    className="border rounded w-full text-xs text-gray-700"
                    value={JSON.stringify(newConfig.benchmarkValues)}
                    disabled={true}
                  ></textarea>
                </div>
              )}

            {keyId ? (
              <CustomTargetingValuesSelector
                networkId={networkId}
                keyId={keyId}
                benchmarkValueIds={benchmarkValueIds}
                handleOnChange={this.handleBenchValuesChanged}
                isMulti
              ></CustomTargetingValuesSelector>
            ) : (
              <div className="text-sm text-gray-700">Select Key first</div>
            )}
          </div>

          {errMsg && (
            <div className="bg-red-100 px-2 py-1 font-semibold text-red-800">
              {errMsg}
            </div>
          )}

          <AmpRtcSection
            networkInfo={networkInfo}
            enabled={amp_rtc.enabled}
            managedRatio={amp_rtc.managed_ratio}
            subdomainName={amp_rtc.subdomain_name}
            domainSpecificManagedRatios={amp_rtc.domain_specific_managed_ratios}
            handleAmpRtcChanged={this.handleAmpRtcChanged}
          ></AmpRtcSection>
        </div>
      </>
    );
  }
}

export default CSTGeneralControl;
