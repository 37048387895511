import React from "react";
import _ from "lodash";
import NumberFormat from "../../common/NumberFormat";
import EstimateRevInfoPopover from "./EstimateRevInfoPopover";

class EstimateRevSection extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { estimateReport } = this.props;

    const mtdDiff =
      estimateReport.revCurrentDateThisMonth -
      estimateReport.compareCurrentDateLastMonthRev;
    return (
      <>
        <div>
          <div className="flex">
            <div className="p-4 bg-blue-100">
              <div className="text-gray-800">
                Month-to-date (MTD) Accumulated Billable Revenue:
              </div>
              <div className="text-2xl font-semibold text-gray-800">
                <NumberFormat
                  value={estimateReport.revCurrentDateThisMonth}
                  format={"$0,0"}
                ></NumberFormat>
              </div>
              {estimateReport.compareCurrentDateLastMonthRev && (
                <div className="text-xs">
                  <span
                    className={`px-2 py-1 rounded font-medium ${
                      mtdDiff >= 0
                        ? "bg-green-200 text-green-900"
                        : "bg-red-200 text-red-900"
                    }`}
                  >
                    {mtdDiff > 0 ? "+" : ""}
                    <NumberFormat
                      value={mtdDiff}
                      format={"$0,0"}
                    ></NumberFormat>{" "}
                    than last month
                  </span>
                </div>
              )}
            </div>
            <div className=" ml-8 p-4 bg-blue-100">
              <div className="text-gray-800">
                Estimated Billable Revenue at the <i>end of month</i>*:
              </div>
              <div className="text-2xl font-semibold text-gray-800">
                <NumberFormat
                  value={estimateReport.estimateRev}
                  format={"$0,0"}
                ></NumberFormat>
              </div>
              <div className="text-xs text-gray-700 flex items-center">
                * Calculated using last month's data
                <EstimateRevInfoPopover></EstimateRevInfoPopover>
              </div>
            </div>
          </div>
          {/* <div>
            {_.keys(estimateReport).map((key) => {
              const value = estimateReport[key];
              return (
                <div key={key}>
                  <div>{key}: </div>
                  <NumberFormat value={value} format={"$0,0"}></NumberFormat>
                </div>
              );
            })}
          </div> */}
        </div>
      </>
    );
  }
}

export default EstimateRevSection;
