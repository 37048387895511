import React from "react";
import _ from "lodash";

import NetworkCustomUPRForm from "./NetworkCustomUPRForm";
import ModalWrapper from "../../../common/ModalWrapper";
import PreviewInJsonPopover from "../PreviewInJsonPopover";

class CustomUPRSettingModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      networkId,
      networkInfo,

      importId,

      currentRule,
      isModalOpen,
      modalType,
      formErrMsg,
      isEditing,

      isSaving,

      handleCloseModal,
      handleIsEditing,
      handleCurrentRuleChanged,
      handleAddRule,
      handleEditRule,
    } = this.props;

    let modalAction = modalType === "ADD" ? "Add" : "Save edit";

    return (
      <ModalWrapper
        isOpen={isModalOpen}
        showCloseFooter={false}
        handleClose={handleCloseModal}
        width="80%"
        height="90%"
      >
        <div className="flex h-full flex-col justify-between">
          <div className="mb-2 text-xl font-bold text-gray-700">
            {modalAction} Rule
          </div>
          <div
            className="border overflow-y-auto"
            // style={{ maxHeight: "620px" }}
          >
            <NetworkCustomUPRForm
              importId={importId}
              networkId={networkId}
              networkInfo={networkInfo}
              uprSetting={_.cloneDeep(currentRule)}
              modalType={modalType} // "ADD", "EDIT"
              handleIsEditing={handleIsEditing}
              handleCurrentRuleChanged={handleCurrentRuleChanged}
            ></NetworkCustomUPRForm>
          </div>
          <div className="flex w-full pt-4">
            <div className="w-1/3"> </div>
            <div className="flex w-1/3 items-center justify-center">
              <PreviewInJsonPopover
                place={"top"}
                popoverWidth={"400px"}
                json={JSON.stringify(currentRule, null, 4)}
                triggerElement={
                  <button type="button" className="px-4 py-2 text-blue-700">
                    Preview in JSON
                  </button>
                }
              ></PreviewInJsonPopover>
            </div>
            <div className="flex w-1/3 items-center justify-end">
              <div className="text-red-600">{formErrMsg}</div>
              <button
                type="button"
                disabled={isSaving}
                className={`px-4 py-2 text-blue-700 ${
                  isSaving ? "cursor-not-allowed" : ""
                }`}
                onClick={handleCloseModal}
              >
                Cancel
              </button>
              <button
                type="button"
                className={`px-4 py-2 ${
                  isEditing || isSaving
                    ? "cursor-not-allowed bg-gray-400 text-gray-800"
                    : "bg-blue-500 text-white hover:bg-blue-700"
                }  rounded shadow font-semibold`}
                // onClick={() => onRemoveHint(state.confirmingHint)}
                onClick={modalType === "ADD" ? handleAddRule : handleEditRule}
                disabled={isSaving}
              >
                {isSaving ? "Saving..." : modalAction}
              </button>
            </div>
          </div>
        </div>
        {/* <div>{JSON.stringify(currentRule, null, 4)}</div> */}
      </ModalWrapper>
    );
  }
}

export default CustomUPRSettingModal;
