import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import HighchartChart from "components/common/HighchartChart";
import { getWeekdayAbbr } from "helpers/DateTransform";
import NumberFormat from "components/common/NumberFormat";

const groupTypeMap = {
  benchmark: "#9593FF",
  optimization: "#BBE8D0",
  unmanaged: "#8e8e8e",
  managed: "#02a8e0",
};
class IvtInsightView extends React.PureComponent {
  constructor(props) {
    super(props);
    const { reports } = props;

    this.state = {};
  }

  render() {
    const { reports } = this.props;

    if (!reports) {
      return (
        <div className="min-h-screen bg-gray-300 py-8 px-4">
          <div className="rounded-t bg-white p-2 shadow-md">No IVT Reports</div>
        </div>
      );
    }

    return (
      <>
        <div className="min-h-screen bg-gray-300 py-8 px-4">
          <div className="mb-8">
            <div className="mb-2 text-lg font-semibold text-gray-700">
              IVT Trend by Group Type:
            </div>

            <div className="rounded shadow bg-white px-4 py-6">
              <IvtInsightChart reports={reports}></IvtInsightChart>
            </div>

            <IvtTable reports={reports}></IvtTable>
          </div>
        </div>
      </>
    );
  }
}

function IvtTable({ reports }) {
  reports = _.orderBy(reports, ["date"], ["desc"]);
  const reportsGroupByDate = _.groupBy(reports, "date");

  function _getRowColorByGroupType(groupType) {
    switch (groupType) {
      case "b": {
        return { color: "#413eff" };
      }
      case "o": {
        return { color: "#0ec800" };
      }
      case "m": {
        return { color: "#008cbb" };
      }
      case "um": {
        return { color: "#767676" };
      }
      default: {
        // nothin
      }
    }
  }

  return (
    <table className="border shadow table w-full text-sm">
      <thead className="border-b bg-gray-200 text-xs text-blue-800">
        <tr>
          <th className="border py-1 px-4 text-right">Date</th>
          <th className="border py-1 px-4 text-right">Group Type</th>
          <th className="border py-1 px-4 text-right">Ad Server Imp</th>
          <th className="border py-1 px-4 text-right">
            Ad Server Unfiltered Imp
          </th>
          <th className="border py-1 px-4 text-right">IVT Ratio</th>
        </tr>
      </thead>
      <tbody className="bg-white font-mono text-gray-900">
        {reports.map((r, i) => {
          const weekdayAbbr = getWeekdayAbbr(r.date);
          const numOfRowsPerDate = reportsGroupByDate[r.date].length;
          const lastDate = _.get(reports, [i - 1, "date"]);
          const style = _getRowColorByGroupType(r.group_type);

          return (
            <tr key={i} className="border-b hover:bg-gray-100">
              {r.date !== lastDate && (
                <td
                  className="whitespace-no-wrap border py-1 px-4 text-right"
                  rowSpan={numOfRowsPerDate}
                >
                  {i === 0 && <span className="font-bold">*</span>}
                  {r.date} {weekdayAbbr}
                </td>
              )}

              <td style={style} className="border py-1 px-4 text-right">
                {r.group_type}
              </td>

              <td style={style} className="border py-1 px-4 text-right">
                {_.isNumber(r.ad_server_impression) ? (
                  <NumberFormat
                    value={r.ad_server_impression}
                    format="0,0"
                  ></NumberFormat>
                ) : (
                  "-"
                )}
              </td>

              <td style={style} className="border py-1 px-4 text-right">
                {_.isNumber(r.ad_server_unfiltered_impression) ? (
                  <NumberFormat
                    value={r.ad_server_unfiltered_impression}
                    format="0,0"
                  ></NumberFormat>
                ) : (
                  "-"
                )}
              </td>

              <td style={style} className="border py-1 px-4 text-right">
                {_.isNumber(r.ivt_ratio) ? (
                  <NumberFormat
                    value={r.ivt_ratio}
                    format="0,0.00"
                    postfix="%"
                  ></NumberFormat>
                ) : (
                  "-"
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

class IvtInsightChart extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      chartOptions: createChartOptions(props.reports),
    };
  }
  render() {
    const { chartOptions } = this.state;
    return <HighchartChart options={chartOptions}></HighchartChart>;
  }
}

function createChartOptions(reports) {
  const metrics = {
    um: {
      text: "Unmanaged IVT",
      key: "ivt_ratio",
      // tooltipExtra: {
      //   text: "Unmanaged Eligible Rev",
      //   key: "unmanagedEligibleRevenue",
      // },
    },
    b: {
      text: "Benchmark IVT",
      key: "ivt_ratio",
      // tooltipExtra: {
      //   text: "Benchmark Eligible Rev",
      //   key: "benchmarkEligibleRevenue",
      // },
    },
    m: {
      text: "Managed IVT",
      key: "ivt_ratio",
      // tooltipExtra: {
      //   text: "Total Managed Eligible Rev",
      //   key: "totalManagedRevenue",
      // },
    },
    unknown: {
      text: "IVT (Unknown)",
      key: "ivt_ratio",
      // tooltipExtra: {
      //   text: "Total Managed Eligible Rev",
      //   key: "totalManagedRevenue",
      // },
    },
  };

  let minY = 0;
  let maxY = 100;

  const reportsByGroupType = _.groupBy(reports, "group_type");
  const groupTypes = _.uniq(_.keys(reportsByGroupType));

  const series = _.map(groupTypes, (gt) => {
    const gtReports = _.orderBy(reportsByGroupType[gt], ["date"], ["asc"]);
    const m = metrics[gt];

    return {
      name: m.text,
      color: _getSeriesColor(gt),
      data: _.map(gtReports, (r) => {
        const y = r[m.key];
        minY = y < minY ? y : minY;
        maxY = y > maxY ? y : maxY;
        return {
          x: new Date(r.date).getTime(),
          y,
          // tooltipExtra: {
          //   name: m.tooltipExtra.text,
          //   data: r[m.tooltipExtra.key],
          // },
        };
      }),
    };
  });

  const options = {
    title: {
      text: null,
    },
    chart: {
      type: "line",
      zoomType: "x",
      height: 260,
      backgroundColor: "rgba(0,0,0,0)",
    },
    // TODO: beautify tooltip & Use bm / unmanaged color!!
    tooltip: {
      xDateFormat: "%Y/%m/%d %a",
      headerFormat:
        '<div style="font-size:12px; font-weight: bold; text-align: center;">{point.key}</div><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0;font-weight: bold; text-align: right;padding-right: 4px;">{series.name}: </td>' +
        '<td style="padding:0; text-align: right;"><b>{point.y:,.2f}%</b></td></tr>',
      // +
      // '<tr><td style="color:{series.color};padding:0;font-weight: bold; text-align: right;padding-right: 4px;">{point.tooltipExtra.name}: </td>' +
      // '<td style="padding:0; text-align: right;"><b>${point.tooltipExtra.data:,.4f}</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
      crosshairs: true,
    },
    xAxis: getXAxisConfigDateTime(),
    yAxis: {
      title: {
        text: "%",
      },
      // min: minY,
      // max: maxY
    },
    series,
  };

  return options;
}

function getXAxisConfigDateTime() {
  return {
    type: "datetime",
    labels: {
      formatter: function () {
        const m = moment(this.value);
        const d = m.format("MM/DD");
        const wd = m.format("ddd");
        const label = `${d} <br/> ${wd}`;
        // if (isWeekend(this.value)) {
        //   return `<span style="color: #dd6b20;">${label}</span>`;
        // }
        return label;
      },
    },
    tickInterval: 24 * 60 * 60 * 1000, // 1 day
    crosshair: true,
  };
}

function _getSeriesColor(groupType) {
  if (_.startsWith(groupType, "b")) {
    return groupTypeMap.benchmark;
  }

  if (_.startsWith(groupType, "o")) {
    return groupTypeMap.optimization;
  }

  if (_.startsWith(groupType, "um")) {
    return groupTypeMap.unmanaged;
  }

  if (_.startsWith(groupType, "m")) {
    return groupTypeMap.managed;
  }

  return "";
}

export default IvtInsightView;
