import React from "react";
import _ from "lodash";

import { notify } from "react-notify-toast";
import { BillingAPI } from "apis";
import GeneralSectionForm from "./GeneralSectionForm";
import InvoiceSettingSectionForm from "./InvoiceSettingSectionForm";
import PublisherAuditSectionForm from "./PublisherAuditSectionForm";
import OverdueSettingSectionForm from "./OverdueSettingSectionForm";
import BillingProfileSectionForm from "./BillingProfileSectionForm";

const sidebarBtnClass =
  "border-b cursor-pointer px-4 py-2 font-semibold text-gray-700 hover:text-blue-900 hover:bg-blue-100";
const sidebarBtnStyle = { scrollMarginTop: "64px" };

class BillingSettingForm extends React.Component {
  constructor(props) {
    super(props);

    this.generalRef = React.createRef();
    this.invoiceRef = React.createRef();
    this.pubAuditRef = React.createRef();
    this.overdueRef = React.createRef();
    this.billingProfileRef = React.createRef();

    const { setting } = this.props;

    this.state = {
      newSetting: _.cloneDeep(setting),
      isSaving: false,
      saveErrMsg: null,
    };

    this.scrollTo = this.scrollTo.bind(this);
    this.onSettingChanged = this.onSettingChanged.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  onSettingChanged(changedSetting) {
    this.setState({ newSetting: changedSetting });
  }

  scrollTo(id) {
    document.getElementById(id).scrollIntoView({ block: "start" });
  }

  async onSave(e) {
    e.preventDefault();
    console.log("save billingSetting", this.state.newSetting);

    const isGeneralValid = this.generalRef.current.checkIsSectionValid(
      this.state.newSetting
    );
    const isInvoiceSettingValid = this.invoiceRef.current.checkIsSectionValid(
      this.state.newSetting
    );
    const isPublisherAuditValid = this.pubAuditRef.current.checkIsSectionValid(
      this.state.newSetting
    );
    const isOverdueSettingValid = this.overdueRef.current.checkIsSectionValid(
      this.state.newSetting
    );
    const isBillingProfileValid =
      this.billingProfileRef.current.checkIsSectionValid(this.state.newSetting);
    if (!isGeneralValid) return this.scrollTo("_general_section");
    if (!isInvoiceSettingValid)
      return this.scrollTo("_invoice_setting_section");
    if (!isPublisherAuditValid)
      return this.scrollTo("_publisher_audit_section");
    if (!isOverdueSettingValid)
      return this.scrollTo("_overdue_setting_section");
    if (!isBillingProfileValid)
      return this.scrollTo("_billing_profile_section");

    await this.handleSaveBillingSetting(this.state.newSetting);
  }

  async handleSaveBillingSetting(newSetting) {
    this.setState({ isSaving: true });

    try {
      const { publisherId } = this.props;
      // const params = { ...newSetting };
      const {
        firstName,
        lastName,
        email,
        phoneNumber,
        phoneCountryCode,
        companyName,
        companyAddress,
        taxId,
        billingRecipients,
        currency,
        invoiceSetting,
        geoCountryId,
        paymentTerm,
        paymentTermStartDate,
        billingProfileId,
        tax,
        taxInclusive,
        publisherAuditRequired,
        publisherAuditRecipients,
        overdueSetting,
      } = newSetting;

      let newInvoiceSetting = {
        category: invoiceSetting.category,
        printFlag: invoiceSetting.printFlag,
        taxType: invoiceSetting.taxType,

        customsClearance: invoiceSetting.customsClearance,
      };
      if (invoiceSetting.category === "B2B") {
        newInvoiceSetting.ubn = invoiceSetting.ubn;
      } else {
        newInvoiceSetting.carrierType = invoiceSetting.carrierType;
        newInvoiceSetting.carrierNum = invoiceSetting.carrierNum;
        if (invoiceSetting.loveCode) {
          newInvoiceSetting.loveCode = invoiceSetting.loveCode;
        }
      }

      // overdueSetting = {} if nothing inside is set
      let newOverdueSetting = {};
      if (
        overdueSetting &&
        overdueSetting.recipient &&
        overdueSetting.recipient.firstName
      ) {
        newOverdueSetting = overdueSetting;
      }

      const params = {
        firstName,
        lastName,
        email,
        phoneNumber,
        phoneCountryCode,
        companyName,
        companyAddress,
        taxId,
        billingRecipients,
        currency,
        invoiceSetting: newInvoiceSetting,
        geoCountryId,
        paymentTerm,
        paymentTermStartDate,
        billingProfileId,
        tax,
        taxInclusive,
        publisherAuditRequired,
        publisherAuditRecipients,
        overdueSetting: newOverdueSetting,
      };

      const { result } = await BillingAPI.createOrUpdateBillingSetting({
        publisherId,
        params,
      });
      console.log("createOrUpdateBillingSetting", result);
      if (result === "ok") {
        notify.show("Billing Setting Saved!", "success");
        this.setState({
          isSaving: false,
          saveErrMsg: null,
        });

        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      }
    } catch (err) {
      console.log(err);
      this.setState({
        isSaving: false,
        saveErrMsg: err.toString(),
      });
    }
  }

  render() {
    const { publisherId } = this.props;
    const { newSetting, isSaving, saveErrMsg } = this.state;

    return (
      <div className="flex gap-6">
        <div className="w-1/4">
          <div
            className="rounded border sticky flex flex-col"
            style={{ position: "sticky", top: "64px" }}
          >
            <div
              className={sidebarBtnClass}
              onClick={() => this.scrollTo("_general_section")}
            >
              General
            </div>
            <div
              className={sidebarBtnClass}
              onClick={() => this.scrollTo("_invoice_setting_section")}
            >
              TW Invoice Setting
            </div>
            <div
              className={sidebarBtnClass}
              onClick={() => this.scrollTo("_publisher_audit_section")}
            >
              Publisher Audit
            </div>
            <div
              className={sidebarBtnClass}
              onClick={() => this.scrollTo("_overdue_setting_section")}
            >
              Overdue Setting
            </div>
            <div
              className={sidebarBtnClass}
              onClick={() => this.scrollTo("_billing_profile_section")}
            >
              Billing Profile
            </div>
          </div>
        </div>

        <div className="w-3/4">
          <form>
            <div id="_general_section" style={sidebarBtnStyle}>
              <GeneralSectionForm
                ref={this.generalRef}
                setting={newSetting}
                onSettingChanged={this.onSettingChanged}
              ></GeneralSectionForm>
            </div>

            <div id="_invoice_setting_section" style={sidebarBtnStyle}>
              <InvoiceSettingSectionForm
                ref={this.invoiceRef}
                setting={newSetting}
                // invoiceSetting={newSetting.invoiceSetting}
                onSettingChanged={this.onSettingChanged}
              ></InvoiceSettingSectionForm>
            </div>

            <div id="_publisher_audit_section" style={sidebarBtnStyle}>
              <PublisherAuditSectionForm
                ref={this.pubAuditRef}
                setting={newSetting}
                onSettingChanged={this.onSettingChanged}
              ></PublisherAuditSectionForm>
            </div>

            <div id="_overdue_setting_section" style={sidebarBtnStyle}>
              <OverdueSettingSectionForm
                ref={this.overdueRef}
                setting={newSetting}
                onSettingChanged={this.onSettingChanged}
              ></OverdueSettingSectionForm>
            </div>

            <div id="_billing_profile_section" style={sidebarBtnStyle}>
              <BillingProfileSectionForm
                billingProfile={newSetting.activeBillingProfile}
                publisherId={publisherId}
                ref={this.billingProfileRef}
                setting={newSetting}
                onSettingChanged={this.onSettingChanged}
              ></BillingProfileSectionForm>
            </div>

            <div>
              {saveErrMsg && (
                <div className="bg-red-100 p-4 font-semibold text-red-800">
                  {saveErrMsg}
                </div>
              )}

              <button
                type="submit"
                className="rounded bg-blue-500 px-6 py-2 font-semibold text-white"
                onClick={this.onSave}
              >
                Save Billing Setting
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default BillingSettingForm;
