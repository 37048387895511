import React from "react";
import _ from "lodash";
import ReactModal from "react-modal";
import ModalWrapper from "../common/ModalWrapper";
import { decrypt } from "./VaultHelper";
import PasswordEyeInput from "../common/PasswordEyeInput";

const inputClass =
  "bg-white flex w-full border border-gray-400 rounded py-1 px-3 appearance-none leading-normal focus:outline-none focus:border-blue-400 focus:shadow-inner hover:border-gray-500";
const inputClassDisabled =
  "bg-gray-300 flex w-full border border-gray-400 rounded py-1 px-3 appearance-none leading-normal";

ReactModal.setAppElement("body");

class VaultAccountChangePasswordModal extends React.Component {
  constructor(props) {
    super(props);

    const { account } = props;
    if (account) {
      account.password = decrypt(account.secret);
      account.recoveryEmail = decrypt(account.recovery_email);
    }

    this.state = {
      account,

      oldPassword: "",
      newPassword: "",
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOldPWChanged = this.handleOldPWChanged.bind(this);
    this.handleNewPWChanged = this.handleNewPWChanged.bind(this);
  }

  handleCancel() {
    this.setState({
      account: null,

      oldPassword: "",
      newPassword: "",
    });
    this.props.handleClose();
  }

  handleSubmit() {
    const { newPassword, oldPassword } = this.state;
    this.props.handleSave({ oldPassword, newPassword });
  }

  handleOldPWChanged(e) {
    this.setState({ oldPassword: e.target.value });
  }

  handleNewPWChanged(e) {
    this.setState({ newPassword: e.target.value });
  }

  render() {
    const {
      account,
      isOpenModal,
      // handleClose,
      isSaving,
      saveErrorMsg,
    } = this.props;
    const { oldPassword, newPassword } = this.state;

    return (
      <ModalWrapper
        isOpen={isOpenModal}
        showCloseFooter={false}
        // handleClose={handleClose}
        width="50%"
      >
        <div>
          <div className="mb-4 text-lg font-bold border-b pb-2 px-6 -mx-6">
            Change password:
          </div>
          <div>
            <form
              autoComplete="false"
              onSubmit={(e) => {
                e.preventDefault();
                return this.handleSubmit();
              }}
            >
              <div>
                <div className="my-2">
                  <label
                    className="block text-gray-800 font-semibold mb-1 pr-4 leading-none"
                    htmlFor="account-email"
                  >
                    Email
                  </label>
                  <div className="w-2/3">
                    <input
                      className={inputClassDisabled}
                      id="account-email"
                      type="email"
                      defaultValue={account.email}
                      disabled={true}
                    ></input>
                  </div>
                </div>

                <div className="my-2">
                  <label
                    className="block text-gray-800 font-semibold mb-1 pr-4 leading-none"
                    htmlFor="i2w-vault-account-old-password"
                  >
                    Old Password
                  </label>
                  <div className="w-2/3">
                    {/* <input
                      className={inputClass}
                      id="i2w-vault-account-old-password"
                      type="password"
                      value={oldPassword}
                      onChange={this.handleOldPWChanged}
                      required
                    ></input> */}
                    <PasswordEyeInput
                      id="i2w-vault-account-old-password"
                      value={oldPassword}
                      onChange={this.handleOldPWChanged}
                      required={true}
                    ></PasswordEyeInput>
                  </div>
                </div>

                <div className="my-2">
                  <label
                    className="block text-gray-800 font-semibold mb-1 pr-4 leading-none"
                    htmlFor="i2w-vault-account-new-password"
                  >
                    New Password
                  </label>
                  <div className="w-2/3">
                    {/* <input
                      className={inputClass}
                      id="i2w-vault-account-new-password"
                      type="password"
                      value={newPassword}
                      onChange={this.handleNewPWChanged}
                      required
                    ></input> */}
                    <PasswordEyeInput
                      id="i2w-vault-account-new-password"
                      value={newPassword}
                      onChange={this.handleNewPWChanged}
                      required={true}
                    ></PasswordEyeInput>
                  </div>
                </div>
              </div>

              <div className="flex justify-end items-center mt-8">
                <div className="text-red-700">{saveErrorMsg}</div>
                <button
                  className="flex-shrink-0 border-transparent border-4 text-blue-500 hover:text-blue-800 text-sm py-1 px-2 rounded"
                  type="button"
                  onClick={this.handleCancel}
                >
                  Cancel
                </button>

                <button
                  className="flex-shrink-0 bg-blue-500 hover:bg-blue-800 border-blue-500 hover:border-blue-700 text-sm border-4 text-white py-1 px-2 rounded font-semibold"
                  type="submit"
                  disabled={isSaving}
                >
                  {isSaving ? "Saving..." : "Change password"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </ModalWrapper>
    );
  }
}

export default VaultAccountChangePasswordModal;
