import React from "react";
import ReactTooltip from "react-tooltip";
import moment from "moment-timezone";
import SegmentTargeting from "../observer/SegmentTargeting";
import SegmentModeIndicator from "./SegmentModeIndicator";

class SegmentBasicTable extends React.PureComponent {
  render() {
    const { segments } = this.props;

    return (
      <>
        <div
          className="overflow-y-auto bg-gray-100"
          style={{ maxHeight: "360px" }}
        >
          <table className="border table w-full text-sm">
            <thead className="bg-gray-200 text-gray-800">
              <tr>
                <th rowSpan="2" className="border p-2 text-center">
                  Mode
                </th>
                <th rowSpan="2" className="border p-2 text-center">
                  Segment
                  <br />
                  ID
                </th>
                <th colSpan="10" className="border p-2 text-center">
                  Targeting
                </th>
              </tr>
              <tr>
                <th className="border p-2 text-center font-semibold text-gray-900">
                  UPR Segment ID
                </th>
                <th className="border p-2 text-center font-semibold text-gray-900">
                  Inventory Registry
                </th>
                <th className="border p-2 text-center font-semibold text-gray-900">
                  Country
                </th>
                <th className="border p-2 text-center font-semibold text-gray-900">
                  OS
                </th>
                <th className="border p-2 text-center font-semibold text-gray-900">
                  Device Category
                </th>
                <th className="border p-2 text-center font-semibold text-gray-900">
                  Hour
                </th>
                <th className="border p-2 text-center font-semibold text-gray-900">
                  Browser
                </th>
                <th className="border p-2 text-center font-semibold text-gray-900">
                  Sizes
                </th>
                <th className="border p-2 text-center font-semibold text-gray-900">
                  Relevant Requested Sizes
                </th>
                <th className="border p-2 text-center font-semibold text-gray-900">
                  Customs
                </th>
              </tr>
            </thead>
            <tbody>
              {segments.map((segment) => {
                const targeting = segment.targeting || {};

                return (
                  <tr key={segment.segmentId}>
                    <td className="border p-2 font-bold">
                      <div className="flex items-center justify-center">
                        <SegmentModeIndicator
                          mode={segment.mode}
                        ></SegmentModeIndicator>
                        <div className="ml-1">{segment.mode}</div>
                      </div>
                    </td>
                    <td className="border whitespace-no-wrap p-2 text-center font-bold text-gray-800">
                      {segment.segmentId === -1
                        ? "No segment"
                        : segment.segmentId}
                      <div className="font-normal">{segment.intention}</div>
                      {segment.srConfig && (
                        <div
                          className="cursor-pointer"
                          onClick={() =>
                            window.open(
                              `${window.location.origin}/#/segment-research/config/${segment.srConfig.id}/update`
                            )
                          }
                        >
                          <span
                            data-tip
                            data-for={`src-${segment.srConfig.id}`}
                            className="rounded bg-purple-500 px-1 text-pink-100"
                            style={{ fontSize: "12px" }}
                          >
                            SR
                          </span>
                          <ReactTooltip
                            id={`src-${segment.srConfig.id}`}
                            type="dark"
                            effect="solid"
                            place="top"
                          >
                            <div>
                              Traffic Ratio: {segment.srConfig.trafficRatio}%
                            </div>
                            <div>
                              Created At:{" "}
                              {moment(segment.srConfig.createdAt).format(
                                "YYYY-MM-DD HH:mm"
                              )}
                            </div>
                          </ReactTooltip>
                        </div>
                      )}
                    </td>
                    <td className="border align-center break-words p-2 text-left">
                      <SegmentTargeting
                        targetKey="upr_segment_id"
                        target={targeting.upr_segment_id}
                      ></SegmentTargeting>
                    </td>
                    <td className="border break-words p-2 text-left align-top">
                      <SegmentTargeting
                        targetKey="inventory_registry"
                        target={targeting.inventory_registry}
                      ></SegmentTargeting>
                    </td>
                    <td className="border break-words p-2 text-left align-top">
                      <SegmentTargeting
                        targetKey="country"
                        target={targeting.country}
                      ></SegmentTargeting>
                    </td>
                    <td className="border break-words p-2 text-left align-top">
                      <SegmentTargeting
                        targetKey="os"
                        target={targeting.os}
                      ></SegmentTargeting>
                    </td>
                    <td className="border break-words p-2 text-left align-top">
                      <SegmentTargeting
                        targetKey="device_category"
                        target={targeting.device_category}
                      ></SegmentTargeting>
                    </td>
                    <td className="border break-words p-2 text-left align-top">
                      <SegmentTargeting
                        targetKey="hour"
                        target={targeting.hour}
                      ></SegmentTargeting>
                    </td>
                    <td className="border break-words p-2 text-left align-top">
                      <SegmentTargeting
                        targetKey="browser"
                        target={targeting.browser}
                      ></SegmentTargeting>
                    </td>
                    <td className="border break-words p-2 text-left align-top">
                      <SegmentTargeting
                        targetKey="sizes"
                        target={targeting.sizes}
                      ></SegmentTargeting>
                    </td>
                    <td className="border break-words p-2 text-left align-top">
                      <SegmentTargeting
                        targetKey="relevant_requested_sizes"
                        target={targeting.relevant_requested_sizes}
                      ></SegmentTargeting>
                    </td>
                    <td className="border break-words p-2 text-left align-top">
                      {targeting.customs ? (
                        <SegmentTargeting
                          targetKey="customs"
                          target={targeting.customs}
                        ></SegmentTargeting>
                      ) : (
                        <SegmentTargeting
                          targetKey="custom"
                          target={targeting.custom}
                        ></SegmentTargeting>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default SegmentBasicTable;
