import React from "react";
import _ from "lodash";
import ModalWrapper from "../common/ModalWrapper";
import { generateUserMasterKey, _decrypt } from "./VaultHelper";
import VaultAccountView from "./VaultAccountView";
import UserMasterPasswordForm from "./UserMasterPasswordForm";
import { VaultAPI } from "apis";
import LoadingUI from "../common/LoadingUI";
import ClickToCopyWrapper from "../common/ClickToCopyWrapper";
import { FiExternalLink } from "react-icons/fi";
import ReactTooltip from "react-tooltip";

// ex. https://admanager.google.com/57931037
const GAM_CONSOLE_URL = "https://admanager.google.com";

class VaultAccountViewByNetworkModal extends React.PureComponent {
  constructor(props) {
    super(props);

    // Important!
    // 1. enter user's password (not master password)
    // 2. get account info
    this.state = {
      account: null,
      isLoading: false,
      errorMsg: null,

      mpErrorMsg: null,
      isVaultOpen: false,
    };

    this.handleSubmitUserMP = this.handleSubmitUserMP.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.getAccountByNetwork = this.getAccountByNetwork.bind(this);
    this.handleGoToGAMConsole = this.handleGoToGAMConsole.bind(this);
  }

  handleCloseModal() {
    this.setState({
      account: null,
      isLoading: false,
      errorMsg: null,

      mpErrorMsg: null,
      isVaultOpen: false,
    });
    this.props.handleClose();
  }

  async getAccountByNetwork(enKey) {
    this.setState({ isLoading: true });
    try {
      const { code, networkId } = this.props.network;
      const account = code
        ? await VaultAPI.getAccountByNetworkCode({ networkCode: code })
        : await VaultAPI.getAccountByNetworkId({ networkId });

      account.password = _decrypt(enKey, account.secret);
      account.recoveryEmail = _decrypt(enKey, account.recovery_email);

      this.setState({ account, errorMsg: null });
    } catch (err) {
      console.log("Failed to get account by network code", err);

      if (err.toString() === `{"error":"Not found."}`) {
        this.setState({ errorMsg: "Has no vault account" });
      } else {
        this.setState({ errorMsg: err.toString() });
      }
    }

    this.setState({ isLoading: false });
  }

  async handleSubmitUserMP(userMasterPassword) {
    this.setState({ isLoading: true });
    try {
      const user = await VaultAPI.getCurrentUser();

      // Use user password to extract gam account secret
      const userMkey = await generateUserMasterKey(userMasterPassword);
      const decryptSecret = _decrypt(userMkey, user.protected_secret);
      const enKey = Buffer.from(decryptSecret, "hex");

      this.setState({ mpErrorMsg: null, isVaultOpen: true });

      this.getAccountByNetwork(enKey);
    } catch (err) {
      console.log("wrong password", err);
      this.setState({ mpErrorMsg: "Wrong Password" });
    }

    this.setState({ isLoading: false });
  }

  handleGoToGAMConsole(networkCode) {
    window.open(`${GAM_CONSOLE_URL}/${networkCode}`);
  }

  render() {
    const { isOpenModal, network } = this.props;
    const { isLoading, account, errorMsg, mpErrorMsg, isVaultOpen } =
      this.state;

    const gamLink = `${GAM_CONSOLE_URL}/${network.code}`;

    return (
      <ModalWrapper
        isOpen={isOpenModal}
        showCloseFooter={true}
        handleClose={this.handleCloseModal}
        width="50%"
      >
        <div className="mb-4">
          <div className="mb-4 text-lg font-bold border-b pb-2 px-6 -mx-6">
            <div className="flex mt-4">
              {network.networkId} - {network.name}
              <div className="flex items-center">
                <div className="ml-2 text-blue-700 text-xs">
                  <ClickToCopyWrapper
                    copyText={gamLink}
                    tooltipMessage="Copy GAM console link"
                  ></ClickToCopyWrapper>
                </div>

                <div>
                  <button
                    type="button"
                    className="rounded px-1 hover:shadow text-xs flex items-center text-blue-700 bg-gray-100"
                    onClick={() => this.handleGoToGAMConsole(network.code)}
                    data-tip
                    data-for={`${network.code}-external-link`}
                  >
                    <FiExternalLink></FiExternalLink>
                  </button>
                  <ReactTooltip
                    id={`${network.code}-external-link`}
                    type="dark"
                    effect="solid"
                  >
                    Open this network's GAM console
                  </ReactTooltip>
                </div>
              </div>
            </div>
          </div>

          <div className="text-red-700">
            {errorMsg && (
              <div>
                {errorMsg}

                <div>
                  Go to{" "}
                  <button
                    className="underline"
                    type="button"
                    onClick={() => {
                      window.open(`${window.location.origin}/#/vault-accounts`);
                    }}
                  >
                    Vault Account Settings
                  </button>{" "}
                  to bind account.
                </div>
              </div>
            )}
          </div>

          {isVaultOpen ? (
            <div>
              {isLoading ? (
                <LoadingUI></LoadingUI>
              ) : (
                account && (
                  <VaultAccountView account={account}></VaultAccountView>
                )
              )}
            </div>
          ) : (
            <UserMasterPasswordForm
              handleSubmitUserMP={this.handleSubmitUserMP}
              mpErrorMsg={mpErrorMsg}
              isLoading={isLoading}
            ></UserMasterPasswordForm>
          )}
        </div>
      </ModalWrapper>
    );
  }
}

export default VaultAccountViewByNetworkModal;
