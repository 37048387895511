import React from "react";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";

const inputClass =
  "bg-white flex w-full border border-gray-400 rounded py-1 px-3 appearance-none leading-normal focus:outline-none focus:border-blue-400 focus:shadow-inner hover:border-gray-500";

const inputClassDisabled =
  "bg-gray-300 flex w-full border border-gray-400 rounded py-1 px-3 appearance-none leading-normal";

class PasswordEyeInput extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      inputType: "password",
    };

    this.handleEyeClicked = this.handleEyeClicked.bind(this);
  }

  handleEyeClicked() {
    const newType = this.state.inputType === "password" ? "text" : "password";
    this.setState({ inputType: newType });
  }

  render() {
    const {
      value,
      onChange,

      // optional
      id,
      defaultValue,
      autoFocus,
      required,
      disabled,
      customClass,
    } = this.props;
    const { inputType } = this.state;
    return (
      <>
        <div className="password-eye-container">
          <input
            type={inputType}
            id={id}
            className={
              customClass || (disabled ? inputClassDisabled : inputClass)
            }
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            autoFocus={autoFocus}
            required={required}
            disabled={disabled}
            autoComplete="false"
          ></input>
          <span className="eye-icon" onClick={this.handleEyeClicked}>
            {inputType === "password" ? (
              <IoMdEye></IoMdEye>
            ) : (
              <IoMdEyeOff></IoMdEyeOff>
            )}
          </span>
        </div>
      </>
    );
  }
}

export default PasswordEyeInput;
