import React from "react";
import _ from "lodash";
import { AutoPilotAPI } from "apis";
import LoadingUI from "../../common/LoadingUI";
import ConfigListTable from "./ConfigListTable";
import { notify } from "react-notify-toast";

class ListConfigsViewer extends React.Component {
  constructor(props) {
    super(props);

    // const unitId = _.get(props, "match.params.unitId");
    // const networkId = _.get(props, "match.params.networkId");

    this.state = {
      items: null,
      itemsGrouped: null,

      isLoading: false,
      errMsg: null,
    };

    this.handlePauseConfig = this.handlePauseConfig.bind(this);
    this.handleResumeConfig = this.handleResumeConfig.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });

    try {
      document.title = `Active Segment Research Groups`;

      const items = await AutoPilotAPI.getActiveSegmentResearchConfigs();
      let itemsGrouped = _.groupBy(items, "network_id");
      itemsGrouped = _.values(itemsGrouped);

      // console.log(itemsGrouped);
      this.setState({ items, itemsGrouped });
    } catch (err) {
      console.log(
        "Error querying auto pilot segment research group configs",
        err
      );
      this.setState({ errMsg: typeof err === "object" ? err.toString() : err });
    }

    this.setState({ isLoading: false });
  }

  async handlePauseConfig({ unitId, configId }) {
    const msg = `Pause config? (Traffic Ratio will be set to 0)`;
    const isConfirmed = window.confirm(msg);
    if (!isConfirmed) return;

    this.setState({ isLoading: true, items: null });
    try {
      const r = AutoPilotAPI.pauseSegmentConfig({ configId, unitId });
      notify.show("Config paused!", "success");

      setTimeout(async () => {
        const items = await AutoPilotAPI.getActiveSegmentResearchConfigs();
        let itemsGrouped = _.groupBy(items, "network_id");
        itemsGrouped = _.values(itemsGrouped);

        console.log(itemsGrouped);
        this.setState({ items, itemsGrouped });
      }, 500);
    } catch (err) {
      console.log(err);
      notify.show("Failed to pause config!", "error");
    }

    this.setState({ isLoading: false });
  }

  async handleResumeConfig({ unitId, configId }) {
    const msg = `Resume running config?`;
    const isConfirmed = window.confirm(msg);
    if (!isConfirmed) return;

    this.setState({ isLoading: true, items: null });
    try {
      const r = AutoPilotAPI.resumeSegmentConfig({ configId, unitId });
      notify.show("Config resumed!", "success");

      setTimeout(async () => {
        const items = await AutoPilotAPI.getActiveSegmentResearchConfigs();
        let itemsGrouped = _.groupBy(items, "network_id");
        itemsGrouped = _.values(itemsGrouped);

        console.log(itemsGrouped);
        this.setState({ items, itemsGrouped });
      }, 500);
    } catch (err) {
      console.log(err);
      notify.show("Failed to resume config!", "error");
    }

    this.setState({ isLoading: false });
  }

  render() {
    const { items, itemsGrouped, isLoading, errMsg } = this.state;

    // if (isLoading) {
    //   return <LoadingUI></LoadingUI>;
    // }

    // if (errMsg) {
    //   return <div className="text-red-600">{errMsg}</div>;
    // }

    // if (items && items.length === 0) {
    //   return <div>No active segment research groups</div>;
    // }

    return (
      <>
        <div className="bg-white px-12">
          <div className="flex justify-between pt-8 mb-4 ">
            <div className="font-extrabold text-gray-900 text-4xl">
              Auto Pilot Segment Research Groups
            </div>

            <div className="flex gap-4">
              <div>
                <button
                  type="button"
                  className="px-4 py-2 bg-blue-400 rounded shadow hover:bg-blue-600 text-white font-semibold"
                  onClick={() =>
                    this.props.history.push("/segment-research/config/create")
                  }
                >
                  Create New Config
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-gray-200 px-12 py-8 min-h-full">
          {isLoading && <LoadingUI></LoadingUI>}

          {errMsg && errMsg}

          {itemsGrouped && (
            <>
              <div>
                {itemsGrouped.map((items) => {
                  const first = items[0];
                  const groupTitle = `${first.network_id} ${first.network_name}`;

                  return (
                    <div key={first.network_id} className="mb-8">
                      <div>
                        <div className="text-sm font-semibold uppercase text-gray-600 leading-none">
                          Network
                        </div>
                        <div className="text-xl font-semibold text-gray-800">
                          {groupTitle}
                        </div>
                      </div>

                      <div>
                        <ConfigListTable
                          items={items}
                          handlePauseConfig={this.handlePauseConfig}
                          handleResumeConfig={this.handleResumeConfig}
                        ></ConfigListTable>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

export default ListConfigsViewer;
