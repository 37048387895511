import React, { Suspense } from "react";
import _ from "lodash";
import { IoMdRefresh } from "react-icons/io";
import ReactTooltip from "react-tooltip";
import queryString from "query-string";

import { UnitInsightAPI } from "apis";
import LoadingUI from "../common/LoadingUI";
import UnitInsightHeader from "./UnitInsightHeader";
import TimeOfDayProgressBar from "../common/TimeOfDayProgressBar";
import UnitInsightTabs from "./UnitInsightTabs";
import BenchmarkValidationView from "./benchmark-validation/BenchmarkValidationView";
import InventoryView from "./inventory/InventoryView";
import OverviewView from "./overview/OverviewView";
import UprValidationView from "./upr-validation/UprValidationView";
// import ConfigView from "./exp-config/ConfigView";
import DateRangeSelector from "../common/DateRangeSelector";
import HealthCheckView from "./health-check/HealthCheckView";
import IvtInsightView from "./ivt/IvtInsightView";

const DEFAULT_DATE_RANGE = 7;

class UnitInsightViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: null,

      // selectedTab: "INVENTORY",
      // selectedTab: "BENCHMARK_VALIDATION",
      selectedTab: "OVERVIEW",
      // selectedTab: "HEALTH_CHECK",
      // selectedTab: "UPR_VALIDATION",
      // selectedTab: "AUTO_PILOT_CONFIG",

      unitId: _.get(props, "match.params.unitId"),
      selectedDateRange: DEFAULT_DATE_RANGE,
    };

    this.handleSelectTab = this.handleSelectTab.bind(this);
    this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
    this.handleForceRefresh = this.handleForceRefresh.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    const unitId = _.get(this.props, "match.params.unitId");
    document.title = `I:${unitId} | Unit Insight`;

    const urlQueryParams = queryString.parse(this.props.location.search);
    const tab = urlQueryParams.tab;

    console.log(tab);
    if (tab) {
      this.setState({ selectedTab: tab });
    }

    try {
      const data = await UnitInsightAPI.getUnitInsights(
        unitId,
        this.state.dateRange
      );
      // console.log(data);
      this.setState({ data, unitId });
    } catch (err) {
      console.log("Error querying snapshot detail", err);
    }

    this.setState({ isLoading: false });
  }

  async handleForceRefresh() {
    this.setState({ isLoading: true, data: null });

    try {
      const isForceRefresh = true;
      const data = await UnitInsightAPI.getUnitInsights(
        this.state.unitId,
        this.state.dateRange,
        isForceRefresh
      );
      this.setState({ data });
    } catch (err) {
      console.log("Error querying snapshot detail", err);
    }

    this.setState({ isLoading: false });
  }

  handleSelectTab(tabKey) {
    if (tabKey === "AUTO_PILOT_CONFIG") {
      const unitId = this.state.unitId;

      window.open(
        `${window.location.origin}/#/control-center/unit/${unitId}/auto-pilot/config`
      );

      return;
    }

    this.setState({
      selectedTab: tabKey,
    });
  }

  async handleDateRangeChange(dateRange) {
    if (dateRange === this.state.selectedDateRange) {
      return;
    }

    if (dateRange === 30) {
      const msg =
        "Be warned! Calculating 30 days worth of data could take minutes or hours of querying and could potentially damage the system, are you sure you would like to proceed?";
      const isConfirmed = window.confirm(msg);

      if (!isConfirmed) {
        return;
      }
    }

    this.setState({ isLoading: true, data: null });
    try {
      const data = await UnitInsightAPI.getUnitInsights(
        this.state.unitId,
        dateRange
      );
      this.setState({ selectedDateRange: dateRange });

      if (data) {
        this.setState({ data });
      } else {
        this.setState({ errorMsg: "No Data" });
      }
    } catch (err) {
      this.setState({
        errorMsg: typeof err === "object" ? err.toString() : err,
      });
    }

    this.setState({ isLoading: false });
  }

  render() {
    const { unitId, data, selectedTab, selectedDateRange } = this.state;

    return (
      <>
        {this.state.isLoading && (
          <div className="text-white">
            Querying unit reports to create insight...
          </div>
        )}

        <Suspense fallback={<LoadingUI></LoadingUI>}>
          {data && (
            <div>
              <div className="sticky top-0 z-50 w-full">
                <UnitInsightHeaderAndTabs
                  data={data}
                  selectedTab={selectedTab}
                  handleSelectTab={this.handleSelectTab}
                ></UnitInsightHeaderAndTabs>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <div>
                    {selectedTab !== "AUTO_PILOT_CONFIG" &&
                      selectedTab !== "UPR_VALIDATION" &&
                      selectedTab !== "HEALTH_CHECK" && (
                        <div className="m-4 text-sm text-white">
                          <DateRangeSelector
                            dateRanges={[7, 14, 21, 30]}
                            selectedDateRange={selectedDateRange}
                            onDateRangeChange={this.handleDateRangeChange}
                          ></DateRangeSelector>
                        </div>
                      )}
                  </div>

                  <div className="my-4">
                    <button
                      type="button"
                      className="rounded mr-4 flex items-center bg-gray-400 px-2 py-1 font-medium hover:bg-white"
                      data-tip
                      data-for="unit-insight-force-update-tooltip"
                      onClick={this.handleForceRefresh}
                    >
                      <IoMdRefresh></IoMdRefresh>
                      <span className="pl-1 text-sm">Force Update</span>
                    </button>

                    <ReactTooltip
                      id="unit-insight-force-update-tooltip"
                      type="dark"
                      effect="solid"
                    >
                      Click to update data immediately
                    </ReactTooltip>
                  </div>
                </div>

                <TabView
                  selectedTab={selectedTab}
                  data={data}
                  unitId={unitId}
                  handleSelectTab={this.handleSelectTab}
                ></TabView>
              </div>
            </div>
          )}
        </Suspense>
      </>
    );
  }
}

class TabView extends React.PureComponent {
  render() {
    const {
      selectedTab,
      data,
      //unitId,
      handleSelectTab,
    } = this.props;
    return (
      <>
        {selectedTab === "OVERVIEW" && (
          <OverviewView
            revenue={data.reports.revenue}
            experimentParams={data.reports.experimentParams}
            rpms={data.reports.rpms}
            trafficLoss={data.reports.trafficLoss}
            handleSelectTab={handleSelectTab}
            networkTimezone={data.props.gamNetwork.timezone}
          ></OverviewView>
        )}

        {selectedTab === "BENCHMARK_VALIDATION" && (
          <BenchmarkValidationView
            reports={data.reports.benchmarkValidation}
            iedReports={data.reports.iedReports}
          ></BenchmarkValidationView>
        )}

        {selectedTab === "INVENTORY" && (
          <InventoryView reports={data.reports.inventory}></InventoryView>
        )}

        {selectedTab === "UPR_VALIDATION" && (
          <UprValidationView
            reports={data.reports.uprValidation}
            reportsByRules={data.reports.pricingRuleReport}
          ></UprValidationView>
        )}

        {selectedTab === "HEALTH_CHECK" && (
          <HealthCheckView
            reports={data.reports.operationCheck}
          ></HealthCheckView>
        )}

        {selectedTab === "IVT" && (
          <IvtInsightView reports={data.reports.ivt}></IvtInsightView>
        )}
      </>
    );
  }
}

class UnitInsightHeaderAndTabs extends React.PureComponent {
  render() {
    const { data, selectedTab, handleSelectTab } = this.props;

    return (
      <div className="z-50 bg-gray-100 shadow-md">
        <UnitInsightHeader
          unit={data.props}
          config={data.autoPilotBrief}
          segments={data.segments}
          updatedAt={data.updatedAt}
        ></UnitInsightHeader>

        <div className="flex items-center justify-between pb-1">
          <UnitInsightTabs
            data={data}
            selectedTab={selectedTab}
            handleSelectTab={handleSelectTab}
          ></UnitInsightTabs>

          <div className="flex items-center px-4 font-semibold">
            <div className="flex items-center">
              <div className="mr-2">
                {data.props.gamNetwork.timezone.replace("_", " ")}
              </div>
              <div className="text-xs" style={{ marginTop: "2px" }}>
                <TimeOfDayProgressBar
                  timezone={data.props.gamNetwork.timezone}
                ></TimeOfDayProgressBar>
              </div>
            </div>
            <span className="mx-2 font-hairline text-gray-500">|</span>
            {data.props.gamNetwork.currency}
          </div>
        </div>
      </div>
    );
  }
}

export default UnitInsightViewer;
