import React from "react";
import _ from "lodash";
import PopupWrapper from "../../../common/PopupWrapper";
import TargetingViewTable from "../../../common/TargetingViewTable";

class TargetingPopover extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { triggerElement, targetings } = this.props;

    return (
      <PopupWrapper
        place="bottom left"
        triggerType="click"
        hideArrow={false}
        triggerElement={triggerElement}
        popupElement={
          <div className="shadow p-4 border" style={{ width: "600px" }}>
            {targetings ? (
              <TargetingViewTable targeting={targetings}></TargetingViewTable>
            ) : (
              "No targetings"
            )}
          </div>
        }
      ></PopupWrapper>
    );
  }
}

export default TargetingPopover;
