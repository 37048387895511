import React from "react";
import _ from "lodash";

import CountrySelector from "../../../common/CountrySelector";
import OSSelector from "../../../common/OSSelector";
import DeviceCategorySelector from "../../../common/DeviceCategorySelector";
import GamBrowserSelector from "components/common/GamBrowserSelector";
import GAMInventoryTypeSelector from "./GAMInventoryTypeSelector";
import GAMVideoPositionsSelector from "./GAMVideoPositionsSelector";
import GAMInventoryFormatsSelector from "./GAMInventoryFormatsSelector";

// Important! not snake case!!!!!!! snake case is in apconfig
const TARGETING_TYPE = {
  OS: "os",
  DC: "deviceCategory",
  COUNTRY: "country",
  BROWSER: "browser",
  CUSTOMS: "customs",
  INVENTORY_TYPES: "inventoryTypes",
  INVENTORY_URL: "inventoryUrl",
  VIDEO_POSITIONS: "videoPositions",
  INVENTORY_FORMAT: "inventoryFormat",
};

const TARGETING_OP = {
  NONE: "NONE",
  INCLUDES: "includes",
  EXCLUDES: "excludes",
};

// type: country, os, device_category, hour
class TargetingWithInExControl extends React.PureComponent {
  constructor(props) {
    super(props);
    // targetingType
    // targeting.includes
    // handleTargetingChanged

    const { targeting, onlyIncludes } = props;

    let radioValue = TARGETING_OP.NONE;
    let selectedItems = [];
    if (targeting) {
      if (targeting.includes) {
        radioValue = TARGETING_OP.INCLUDES;
        selectedItems = targeting.includes;
      } else if (targeting.excludes) {
        radioValue = TARGETING_OP.EXCLUDES;
        selectedItems = targeting.excludes;
      }

      if (onlyIncludes) {
        radioValue = TARGETING_OP.INCLUDES;
        selectedItems = targeting;
      }
    }

    this.state = {
      radioValue,
      selectedItems,
    };

    this.handleChangeRadio = this.handleChangeRadio.bind(this);
    this.handleItemsChanged = this.handleItemsChanged.bind(this);
  }

  handleChangeRadio(value) {
    this.setState({
      radioValue: value,
    });

    if (value === TARGETING_OP.NONE) {
      this.props.handleTargetingChanged(null);
      this.setState({ selectedItems: [] });
    } else {
      const items = this.state.selectedItems;
      if (_.isEmpty(items)) {
        this.props.handleTargetingChanged(null);
      } else {
        let tt = this.props.onlyIncludes
          ? items
          : {
              [value]: items,
            };
        this.props.handleTargetingChanged(tt);
      }
    }
  }

  handleItemsChanged(items) {
    const { radioValue } = this.state;
    // console.log("items changed", items, radioValue);
    if (radioValue !== TARGETING_OP.NONE) {
      if (_.isEmpty(items)) {
        this.props.handleTargetingChanged(null);
      } else {
        let tt = this.props.onlyIncludes
          ? items
          : {
              [radioValue]: items,
            };
        this.props.handleTargetingChanged(tt);
      }
    }
  }

  render() {
    // type: country, os, device_category, hour
    const { targetingType, onlyIncludes = false } = this.props;
    const { radioValue, selectedItems } = this.state;

    let TargetingSelector = "";
    switch (targetingType) {
      case TARGETING_TYPE.DC: {
        TargetingSelector = (
          <DeviceCategorySelector
            selectedItems={selectedItems}
            handleChanged={this.handleItemsChanged}
          ></DeviceCategorySelector>
        );
        break;
      }

      case TARGETING_TYPE.OS: {
        TargetingSelector = (
          <OSSelector
            selectedItems={selectedItems}
            handleChanged={this.handleItemsChanged}
          ></OSSelector>
        );
        break;
      }

      case TARGETING_TYPE.COUNTRY: {
        TargetingSelector = (
          <CountrySelector
            selectedItems={selectedItems}
            handleChanged={this.handleItemsChanged}
          ></CountrySelector>
        );
        break;
      }

      case TARGETING_TYPE.BROWSER: {
        TargetingSelector = (
          <GamBrowserSelector
            selectedItems={selectedItems}
            handleChanged={this.handleItemsChanged}
          ></GamBrowserSelector>
        );
        break;
      }

      case TARGETING_TYPE.INVENTORY_TYPES: {
        TargetingSelector = (
          <GAMInventoryTypeSelector
            selectedItems={selectedItems}
            handleChanged={this.handleItemsChanged}
          ></GAMInventoryTypeSelector>
        );
        break;
      }

      case TARGETING_TYPE.VIDEO_POSITIONS: {
        TargetingSelector = (
          <GAMVideoPositionsSelector
            selectedItems={selectedItems}
            handleChanged={this.handleItemsChanged}
          ></GAMVideoPositionsSelector>
        );
        break;
      }

      case TARGETING_TYPE.INVENTORY_FORMAT: {
        TargetingSelector = (
          <GAMInventoryFormatsSelector
            selectedItems={selectedItems}
            handleChanged={this.handleItemsChanged}
          ></GAMInventoryFormatsSelector>
        );
        break;
      }

      // case TARGETING_TYPE.INVENTORY_URL: {
      //   TargetingSelector = (
      //   <GAMInventoryFormatsSelector
      //     selectedItems={selectedItems}
      //     handleChanged={this.handleItemsChanged}
      //   ></GAMInventoryFormatsSelector>
      // );
      // break;
      // }

      default: {
        TargetingSelector = "Selector not implemented";
      }
    }

    return (
      <>
        <div className="flex items-center gap-4">
          <label
            htmlFor={`${targetingType}_radio_none`}
            className="cursor-pointer"
          >
            <input
              id={`${targetingType}_radio_none`}
              type="radio"
              name={`${targetingType}_radio`}
              value={TARGETING_OP.NONE}
              checked={radioValue === TARGETING_OP.NONE}
              onChange={() => this.handleChangeRadio(TARGETING_OP.NONE)}
            />{" "}
            None
          </label>

          <label
            htmlFor={`${targetingType}_radio_include`}
            className="cursor-pointer"
          >
            <input
              id={`${targetingType}_radio_include`}
              type="radio"
              name={`${targetingType}_radio`}
              value={TARGETING_OP.INCLUDES}
              checked={radioValue === TARGETING_OP.INCLUDES}
              onChange={() => this.handleChangeRadio(TARGETING_OP.INCLUDES)}
            />{" "}
            Includes
          </label>

          {!onlyIncludes && (
            <label
              htmlFor={`${targetingType}_radio_exclude`}
              className="cursor-pointer"
            >
              <input
                id={`${targetingType}_radio_exclude`}
                type="radio"
                name={`${targetingType}_radio`}
                value={TARGETING_OP.EXCLUDES}
                checked={radioValue === TARGETING_OP.EXCLUDES}
                onChange={() => this.handleChangeRadio(TARGETING_OP.EXCLUDES)}
              />{" "}
              Excludes
            </label>
          )}
        </div>

        {radioValue !== TARGETING_OP.NONE && TargetingSelector}
      </>
    );
  }
}

export default TargetingWithInExControl;
