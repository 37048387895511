import React from "react";
import _ from "lodash";
import ReactModal from "react-modal";
import ModalWrapper from "../common/ModalWrapper";
import NetworkStatusIndicator from "../common/NetworkStatusIndicator";
import NetworksSelector from "../ops-mgmt/account/NetworksSelector";

ReactModal.setAppElement("body");

const buttonConfirmClass =
  "my-2 text-blue-900 rounded shadow font-semibold bg-blue-200 hover:bg-blue-300 px-4 py-2 text-sm overflow-x-auto";

const buttonActionRedClass =
  "px-2 py-2 mr-1 my-1 bg-red-400 text-xs rounded shadow hover:bg-red-600 text-white font-semibold";

const thClass =
  "px-4 py-2 text-gray-600 text-left text-xs tracking-wider uppercase";
const tdClass = "px-4 py-2 border";
const inputClass =
  "bg-white flex w-full border border-gray-400 rounded py-1 px-3 appearance-none leading-normal focus:outline-none focus:border-blue-400 focus:shadow-inner hover:border-gray-500";

class VaultAccountBindNetworkModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedNetwork: null,

      // can also choose to use network code
      networkCode: "",
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleBind = this.handleBind.bind(this);
    this.handleBindWithCode = this.handleBindWithCode.bind(this);
    this.handleForceBind = this.handleForceBind.bind(this);
    this.onSelectNetwork = this.onSelectNetwork.bind(this);

    this.handleCodeChanged = this.handleCodeChanged.bind(this);
  }

  handleCancel() {
    this.setState({
      selectedNetwork: null,
    });
    this.props.handleClose();
  }

  handleRemove(network) {
    const userConfirm = window.confirm(
      `Remove this network binding ${network.name} (${network.code})?`
    );
    if (!userConfirm) return;

    this.props.handleRemoveBindNetwork(network.code);
  }

  handleBind() {
    const { selectedNetwork } = this.state;
    if (!selectedNetwork) return;

    const { account } = this.props;
    this.props.handleBindNetwork({
      networkCode: selectedNetwork.code,
      accountId: account.id,
    });
  }

  handleBindWithCode() {
    const { networkCode } = this.state;
    if (!networkCode) return;

    const { account } = this.props;
    this.props.handleBindNetwork({
      networkCode,
      accountId: account.id,
    });
  }

  handleForceBind() {
    const { selectedNetwork } = this.state;
    if (!selectedNetwork) return;

    const { account } = this.props;
    this.props.handleBindNetwork({
      networkCode: selectedNetwork.code,
      accountId: account.id,
      isForceBind: true,
    });
  }

  onSelectNetwork(network) {
    const { selectedNetwork } = this.state;
    if (selectedNetwork && network.networkId !== selectedNetwork.networkId) {
      this.props.handleClearSaveErrorMsg();
    }
    this.setState({ selectedNetwork: network });
  }

  handleCodeChanged(e) {
    this.setState({ networkCode: e.target.value });
  }

  render() {
    const {
      isOpenModal,
      handleClose,
      isSaving,
      saveErrorMsg,
      isRemoving,
      account,
    } = this.props;
    const { selectedNetwork, networkCode } = this.state;

    const hasNetworkBinding = account.networks.length > 0;
    return (
      <ModalWrapper
        isOpen={isOpenModal}
        showCloseFooter={true}
        handleClose={handleClose}
        width="60%"
      >
        <div>
          <div className="mb-4 text-lg font-bold border-b pb-2 px-6 -mx-6">
            Bind networks to {account.email}
          </div>

          {hasNetworkBinding && (
            <div className="border-b mb-6 pb-6 px-8 -mx-8">
              <div>
                <table className="min-w-full shadow divide-y">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className={thClass}>id</th>
                      <th className={thClass}>name</th>
                      <th className={thClass}>code</th>
                      <th className={thClass + " text-right"}>Action</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y text-sm">
                    {account.networks.map((network) => {
                      return (
                        <tr
                          key={network.networkId}
                          className="hover:bg-gray-100"
                        >
                          <td className={tdClass}>{network.networkId}</td>
                          <td className={tdClass}>
                            <div className="flex items-end">
                              <div className="flex items-center mr-1">
                                <NetworkStatusIndicator
                                  status={network.status}
                                ></NetworkStatusIndicator>
                                <span className="ml-1">
                                  {network.networkId}
                                </span>
                              </div>

                              <div className="font-medium">{network.name}</div>
                            </div>
                          </td>
                          <td className={tdClass}>{network.code}</td>

                          <td className={tdClass + " text-right"}>
                            <button
                              type="button"
                              className={buttonActionRedClass}
                              onClick={() => this.handleRemove(network)}
                              disabled={isRemoving}
                            >
                              {isRemoving ? "Removing..." : "Remove bind"}
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          <div className="font-semibold">Add Network Binding:</div>
          <div style={{ minHeight: "200px" }}>
            <NetworksSelector
              valueKey="code"
              isMulti={false}
              selectedNetwork={selectedNetwork}
              handleOnChange={this.onSelectNetwork}
            ></NetworksSelector>

            <button
              type="button"
              className={buttonConfirmClass}
              onClick={this.handleBind}
              disabled={!selectedNetwork || isSaving}
            >
              {isSaving ? "Binding..." : "Bind network"}
            </button>

            <div className="font-semibold text-gray-700 my-2 flex justify-center">
              OR
            </div>

            <div>
              <div className="font-semibold">
                Add Binding with Network Code:
              </div>
              <input
                className={inputClass}
                id="network-code"
                type="text"
                value={networkCode}
                onChange={this.handleCodeChanged}
              ></input>
              <button
                type="button"
                className={buttonConfirmClass}
                onClick={this.handleBindWithCode}
                disabled={isSaving}
              >
                {isSaving ? "Binding..." : "Bind with network code"}
              </button>
            </div>

            <div className="text-red-700">{saveErrorMsg}</div>

            {saveErrorMsg &&
              saveErrorMsg.indexOf("Network is bound to another account") !==
                -1 && (
                <button
                  type="button"
                  className={buttonConfirmClass}
                  onClick={this.handleForceBind}
                  disabled={!selectedNetwork || isSaving}
                >
                  {isSaving ? "Binding..." : "Bind to this account instead"}
                </button>
              )}
          </div>
        </div>
      </ModalWrapper>
    );
  }
}

export default VaultAccountBindNetworkModal;
