import React from "react";
import _ from "lodash";

class PublisherFormBasic extends React.Component {
  constructor(props) {
    super(props);

    const { name } = props;
    this.state = {
      name,

      errorMsg: "",
    };

    this.onInputChanged = this.onInputChanged.bind(this);
    this.handleNextStep = this.handleNextStep.bind(this);
  }

  onInputChanged(value, key) {
    this.setState({
      [key]: value,
    });
  }

  handleNextStep() {
    // 1. validate (move inside child component)
    //  - if pass -> save to state, go to step 2
    //  - if failed -> show error message (currentErrorMsg)

    const { name } = this.state;
    let finalForm = {};

    if (!name || name === "") {
      this.setState({
        errorMsg:
          "Please fill in the 'Publisher Name' (Usually it's the company name)",
      });
      return;
    }
    finalForm.name = name;

    this.props.handleSubmit(finalForm);
  }

  render() {
    const { handleSubmit, showLastStep } = this.props;
    const { name, errorMsg } = this.state;

    const inputClass =
      "bg-white flex w-full border-2 border-gray-300 rounded py-1 px-4 appearance-none leading-normal focus:outline-none focus:border-blue-400 focus:shadow-inner hover:shadow hover:border-blue-300";

    return (
      <div>
        <div className="text-2xl text-gray-700 font-bold mb-6 mt-12 border-b border-gray-400">
          Publisher Info
        </div>
        <div className="mb-4">
          <label className="font-semibold text-gray-800">Publisher Name</label>
          <div>
            <input
              type="text"
              className={inputClass}
              value={name}
              onChange={(e) => this.onInputChanged(e.target.value, "name")}
            ></input>
          </div>
        </div>

        <div className="text-red-600 mb-6 font-semibold">{errorMsg}</div>

        <div className="flex justify-end">
          <button
            type="button"
            className="bg-blue-500 rounded font-semibold text-white px-6 py-2"
            onClick={this.handleNextStep}
          >
            Next Step
          </button>
        </div>
      </div>
    );
  }
}

export default PublisherFormBasic;
