import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import PreconditionsEditor from "./recipe/PreconditionsEditor";
import CriteriasEditor from "./recipe/CriteriasEditor";
import ActionsEditor from "./recipe/ActionsEditor";
import ModalWrapper from "../common/ModalWrapper";
import RecipeBox from "./recipe/RecipeBox";
import { VseAPI } from "apis";
import { RECIPE_STATUS } from "../../constants/Recipes";

const basicInputClass =
  "bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded py-1 px-4 block w-full appearance-none leading-normal";

const inputDisabled =
  "bg-gray-400 cursor-not-allowed focus:outline-none focus:shadow-outline border border-gray-300 rounded py-1 px-4 block w-full appearance-none leading-normal";

class RecipeFormWrapper extends React.Component {
  constructor(props) {
    super(props);

    const {
      id,
      version,
      name,
      status,
      schedule,
      preconditions,
      checkCriterias,
      actionTemplates,
    } = props.recipe;

    this.state = {
      isLoading: false,
      checks: null,
      errMsg: null,

      version,
      id,
      name,
      status,
      schedule,
      preconditions, // { property, op, value }
      checkCriterias, // { check_id, accepted_results }
      actionTemplates, // { cat, value }

      finalRecipe: null,

      isModalOpen: false,
      isSaving: false,
      saveErrMsg: null,
    };

    this.handleIdChanged = this.handleIdChanged.bind(this);
    this.handleNameChanged = this.handleNameChanged.bind(this);
    this.handleStatusChanged = this.handleStatusChanged.bind(this);
    this.handleScheduleChanged = this.handleScheduleChanged.bind(this);

    this.handleAddPrecondition = this.handleAddPrecondition.bind(this);
    this.handleRemovePrecondition = this.handleRemovePrecondition.bind(this);

    this.handleAddCriteria = this.handleAddCriteria.bind(this);
    this.handleRemoveCriteria = this.handleRemoveCriteria.bind(this);

    this.handleAddAction = this.handleAddAction.bind(this);
    this.handleRemoveAction = this.handleRemoveAction.bind(this);

    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleCreateClicked = this.handleCreateClicked.bind(this);
    this.handleCreateRecipe = this.handleCreateRecipe.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      const { checks } = await VseAPI.getAllChecks();
      this.setState({ checks, errMsg: null });
    } catch (err) {
      this.setState({ errMsg: "Error loading checks" });
    }

    this.setState({ isLoading: false });
  }

  handleIdChanged(e) {
    this.setState({ id: e.target.value });
  }

  handleNameChanged(e) {
    this.setState({ name: e.target.value });
  }

  handleStatusChanged(e) {
    this.setState({ status: _.parseInt(e.target.value) });
  }

  handleScheduleChanged(e) {
    // TODO: check crontab
    this.setState({ schedule: e.target.value });
  }

  handleAddPrecondition(item) {
    this.setState({
      preconditions: [...this.state.preconditions, item],
    });
  }

  handleRemovePrecondition(item) {
    const preconditions = _.filter(this.state.preconditions, (i) => {
      return item.property !== i.property;
    });
    this.setState({ preconditions });
  }

  handleAddCriteria(item) {
    this.setState({
      checkCriterias: [...this.state.checkCriterias, item],
    });
  }

  handleRemoveCriteria(item) {
    const checkCriterias = _.filter(this.state.checkCriterias, (i) => {
      return item.check_id !== i.check_id;
    });
    this.setState({ checkCriterias });
  }

  handleAddAction(item) {
    this.setState({
      actionTemplates: [...this.state.actionTemplates, item],
    });
  }

  handleRemoveAction(item) {
    const actionTemplates = _.filter(this.state.actionTemplates, (i) => {
      return item.cat !== i.cat;
    });
    this.setState({ actionTemplates });
  }

  handleModalClose() {
    this.setState({ isModalOpen: false, finalRecipe: null, saveErrMsg: null });
  }

  handleCreateClicked() {
    const {
      checks,
      id,
      name,
      status,
      schedule,
      preconditions,
      checkCriterias,
      actionTemplates,
    } = this.state;

    // to show check names
    const criteria = _.map(checkCriterias, (c) => {
      const check = _.find(checks, { id: c.check_id });
      c.check_name = check.name;
      return c;
    });

    const finalRecipe = {
      id,
      name,
      status,
      schedule,
      preconditions,
      action_templates: actionTemplates,
      criteria,
    };
    this.setState({ isModalOpen: true, finalRecipe });
  }

  async handleCreateRecipe() {
    const { finalRecipe } = this.state;
    this.setState({ isSaving: true });
    try {
      await this.props.handleSubmit(finalRecipe);
    } catch (err) {
      this.setState({ saveErrMsg: err });
    }
    this.setState({ isSaving: false });
  }

  render() {
    const {
      isLoading,
      errMsg,
      checks,

      id,
      version,
      name,
      status,
      schedule,
      preconditions,
      actionTemplates,
      checkCriterias,
      finalRecipe,

      isModalOpen,
      isSaving,
      saveErrMsg,
    } = this.state;
    const { formType } = this.props;

    return (
      <div>
        <div className="pb-64 min-h-screen">
          <div className="mb-3">
            <div className="text-3xl text-gray-800 font-extrabold leading-tight">
              {formType === "CREATE" ? "Create" : "Update"} Recipe
            </div>
          </div>

          {/* 
          <div>{JSON.stringify(preconditions, null, 4)}</div>
          <div>{JSON.stringify(checkCriterias, null, 4)}</div>
          <div>{JSON.stringify(actionTemplates, null, 4)}</div> */}

          <div>
            <div className="mb-4">
              <div className="font-semibold">ID</div>
              <div className="w-1/2">
                <input
                  type="number"
                  value={id}
                  className={
                    formType === "UPDATE" ? inputDisabled : basicInputClass
                  }
                  autoFocus
                  onChange={this.handleIdChanged}
                  disabled={formType === "UPDATE"}
                ></input>
              </div>
            </div>

            {formType === "UPDATE" && (
              <div className="mb-4">
                <div className="font-semibold">Version</div>
                <div className="w-1/2">
                  <input
                    type="number"
                    value={version}
                    className={inputDisabled}
                    disabled={true}
                  ></input>
                </div>
              </div>
            )}

            <div className="mb-4">
              <div className="font-semibold">Name</div>
              <div className="w-1/2">
                <input
                  type="text"
                  value={name}
                  className={basicInputClass}
                  onChange={this.handleNameChanged}
                ></input>
              </div>
            </div>

            <div className="mb-4">
              <div className="font-semibold">Status</div>
              <div className="w-1/2">
                <div className="flex flex-row gap-4 text-gray-900">
                  <label htmlFor="radio_running" className="cursor-pointer">
                    <input
                      id="radio_running"
                      type="radio"
                      value={RECIPE_STATUS.RUNNING}
                      checked={status === RECIPE_STATUS.RUNNING}
                      onChange={this.handleStatusChanged}
                    />{" "}
                    Running
                  </label>

                  <label htmlFor="radio_pause" className="cursor-pointer">
                    <input
                      id="radio_pause"
                      type="radio"
                      value={RECIPE_STATUS.PAUSE}
                      checked={status === RECIPE_STATUS.PAUSE}
                      onChange={this.handleStatusChanged}
                    />{" "}
                    Pause
                  </label>
                </div>
              </div>
            </div>

            <div className="mb-4">
              <div className="font-semibold">Schedule</div>
              <div className="w-1/2">
                <input
                  type="text"
                  value={schedule}
                  className={basicInputClass}
                  onChange={this.handleScheduleChanged}
                ></input>
              </div>
            </div>

            <div className="p-4 rounded mb-4 bg-white">
              <div className="font-semibold text-lg border-b">
                Preconditions
              </div>
              <div className="my-4">
                <PreconditionsEditor
                  preconditions={preconditions}
                  handleAdd={this.handleAddPrecondition}
                  handleRemove={this.handleRemovePrecondition}
                ></PreconditionsEditor>
              </div>
            </div>

            <div className="p-4 rounded mb-4 bg-white">
              <div className="font-semibold text-lg border-b">
                Check Criteria
              </div>
              <div className="my-4">
                {isLoading && "Loading checks"}

                {errMsg && "Error loading checks"}

                {checks && (
                  <CriteriasEditor
                    checks={checks}
                    checkCriterias={checkCriterias}
                    handleAdd={this.handleAddCriteria}
                    handleRemove={this.handleRemoveCriteria}
                  ></CriteriasEditor>
                )}
              </div>
            </div>

            <div className="p-4 rounded mb-4 bg-white">
              <div className="font-semibold text-lg border-b">
                Action Templates
              </div>
              <div className="my-4">
                <ActionsEditor
                  actionTemplates={actionTemplates}
                  handleAdd={this.handleAddAction}
                  handleRemove={this.handleRemoveAction}
                ></ActionsEditor>
              </div>
            </div>
          </div>

          <div className="flex gap-4 flex-row justify-start">
            <button
              type="button"
              className="px-4 py-2 bg-blue-500 shadow rounded hover:bg-blue-700 text-white font-semibold"
              onClick={this.handleCreateClicked}
            >
              Preview and {formType === "CREATE" ? "create" : "update"} recipe
            </button>

            <Link
              to={`/vse/recipes`}
              className="px-4 py-2 text-blue-700 font-semibold hover:underline"
            >
              Back
            </Link>
          </div>

          {finalRecipe && (
            <RecipeConfirmModal
              formType={formType}
              recipe={finalRecipe}
              isModalOpen={isModalOpen}
              isSaving={isSaving}
              saveErrMsg={saveErrMsg}
              handleModalClose={this.handleModalClose}
              handleSave={this.handleCreateRecipe}
            ></RecipeConfirmModal>
          )}
        </div>
      </div>
    );
  }
}

class RecipeConfirmModal extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      formType,
      recipe,
      isModalOpen,
      isSaving,
      saveErrMsg,
      handleModalClose,
      handleSave,
    } = this.props;

    return (
      <ModalWrapper
        isOpen={isModalOpen}
        handleClose={handleModalClose}
        width="60%"
        showCloseFooter={false}
      >
        <div>
          <div className="font-bold text-xl text-gray-700 mb-2">
            {formType === "CREATE" ? "Create" : "Update"} new recipe:
          </div>

          <div style={{ height: "500px", overflow: "scroll" }}>
            <RecipeBox item={recipe}></RecipeBox>
          </div>

          <div className="flex flex-row-reverse mt-4 items-center">
            <div>
              <button
                type="button"
                className="px-4 py-2 bg-blue-500 rounded shadow hover:bg-blue-700 text-white font-semibold"
                onClick={handleSave}
              >
                {isSaving
                  ? "Saving..."
                  : formType === "CREATE"
                  ? "Create"
                  : "Update"}
              </button>
            </div>
            <div>
              <button
                type="button"
                disabled={isSaving}
                className={`px-4 py-2 text-blue-700 ${
                  isSaving ? "cursor-not-allowed" : ""
                }`}
                onClick={handleModalClose}
              >
                Cancel
              </button>
            </div>
            <div className="text-red-600">{saveErrMsg}</div>
          </div>
        </div>
      </ModalWrapper>
    );
  }
}

export default RecipeFormWrapper;
