import React from "react";
import _ from "lodash";
import AutoPilotConfigItem from "./AutoPilotConfigItem";
import { notify } from "react-notify-toast";

const configSections = [
  {
    sectionKey: "system",
    sectionConfigKey: "", // the outer-most layer of config
    title: "System",
  },
  {
    sectionKey: "forerunner",
    sectionConfigKey: "forerunner",
    title: "Forerunner",
  },
  {
    sectionKey: "segmentation",
    sectionConfigKey: "segmentation",
    title: "Segmentation",
  },
  {
    sectionKey: "prophet",
    sectionConfigKey: "prophet",
    title: "Prophet",
  },
];

class AutoPilotConfig extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      // activeTab: configSections[0].sectionKey, // SYSTEM
      activeTab: configSections[3].sectionKey, // PROPHET
    };

    this.onTabSelected = this.onTabSelected.bind(this);
  }

  onTabSelected(tab) {
    if (this.state.activeTab === tab) return;
    this.setState({ activeTab: tab });
  }

  render() {
    const {
      unitId,
      networkId,
      schemasGroupedBySection,
      currentEditingKey,
      handleStartEditMode,
      handleCancelEditMode,
      handleApplyChanges,
      newConfig,
      isUnitConfig,
      isHintConfig,
    } = this.props;
    const { activeTab } = this.state;

    if (!schemasGroupedBySection) {
      return "";
    }

    let sections = configSections;
    if (isHintConfig) {
      sections = _.filter(configSections, { sectionKey: "prophet" });
    }

    return (
      <>
        <div className="flex px-4">
          {/* {isHintConfig && (
            <div className="bg-orange-200 px-6 py-3 rounded mx-4 -ml-2 my-4">
              <div className="font-bold text-lg text-gray-700">
                Basic Settings
              </div>
              <div className="text-gray-800 text-sm">
                If you can't find what you're looking for, switch to{" "}
                <span
                  className="cursor-pointer font-bold text-blue-500 hover:underline"
                  onClick={handleShowAdvancedSettings}
                >
                  advanced settings
                </span>{" "}
                for more detailed configurations.
              </div>
            </div>
          )} */}

          {!isHintConfig &&
            sections.map((s) => {
              const isActive = activeTab === s.sectionKey;

              return (
                <div
                  key={s.sectionKey}
                  className={`px-4 py-2 font-semibold ${
                    isActive ? "border-b-2 border-blue-400" : "text-gray-600"
                  } ${
                    currentEditingKey
                      ? isActive
                        ? ""
                        : "cursor-not-allowed"
                      : "cursor-pointer hover:text-black"
                  }`}
                  onClick={() => {
                    if (currentEditingKey) {
                      notify.show(
                        "Apply changes before moving on to the next section.",
                        "warning",
                        3000
                      );
                      return;
                    }
                    return this.onTabSelected(s.sectionKey);
                  }}
                >
                  {s.title}
                </div>
              );
            })}
        </div>

        <div className="p-4">
          {sections.map((s) => {
            const isCurrentTab = activeTab === s.sectionKey;
            const configSchemas = _.get(schemasGroupedBySection, [
              s.sectionKey,
              "configSchemas",
            ]);

            if (isCurrentTab) {
              return (
                <AutoPilotConfigItem
                  key={s.sectionKey}
                  unitId={unitId}
                  networkId={networkId}
                  sectionTitle={s.title}
                  // sectionKey={s.sectionKey}
                  sectionConfigKey={s.sectionConfigKey}
                  configSchemas={configSchemas}
                  currentEditingKey={currentEditingKey}
                  handleStartEditMode={handleStartEditMode}
                  handleCancelEditMode={handleCancelEditMode}
                  handleApplyChanges={handleApplyChanges}
                  newConfig={newConfig}
                  isUnitConfig={isUnitConfig}
                  isHintConfig={isHintConfig}
                ></AutoPilotConfigItem>
              );
            } else {
              return "";
            }
          })}
        </div>
      </>
    );
  }
}

export default AutoPilotConfig;
