import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import Highcharts from "highcharts";
import HighchartChart from "../common/HighchartChart";
// import { isWeekend } from "../../helpers/DateTransform";
// Highcharts.theme = {
//   colors: [
//     "#ffd700",
//     "#ffb14e",
//     "#fa8775",
//     "#ea5f94",
//     "#cd34b5",
//     "#9d02d7",
//     "#0000ff",
//   ],
// };
// // Apply the theme
// Highcharts.setOptions(Highcharts.theme);

class BizHighchartStackedView extends React.PureComponent {
  render() {
    const options = _createChartOptions(
      this.props.reports,
      this.props.topNData,
      this.props.handleFilterByDateRange
    );

    return <HighchartChart options={options}></HighchartChart>;
  }
}

function _createChartOptions(reports, topNData, handleFilterByDateRange) {
  const seriesData = _createSeriesData(reports, topNData);

  const options = {
    title: {
      text: "",
    },
    chart: {
      zoomType: "x",
      ..._createSelectionEvents(handleFilterByDateRange),
    },
    tooltip: {
      ..._getTooltipConfig(),
    },
    xAxis: {
      ..._getXAxisConfigDateTime(),
      ..._createPlotBands(reports),
      ..._createPlotLines(reports),
    },
    yAxis: {
      title: {
        text: "TWD $",
      },
      min: 0,
    },
    series: seriesData,
  };

  return options;
}

function _createSeriesData(reports, topNData) {
  let totalNetIncreasedRevByDate = {};

  let seriesData = [];
  _.forEach(topNData, (networkData) => {
    let seriesName;

    _.forEach(networkData.data, (r) => {
      if (!totalNetIncreasedRevByDate[r.date]) {
        totalNetIncreasedRevByDate[r.date] = 0;
      }
      totalNetIncreasedRevByDate[r.date] += r.netIncreasedRevTwd;
    });

    let sdd = _.map(networkData.data, (r) => {
      seriesName = networkData.index + ": " + r.networkName;
      return {
        x: new Date(r.date).getTime(),
        y: r.netIncreasedRevTwd,
      };
    });

    let sd = {
      type: "column",
      stacking: "normal",
      name: seriesName,
      data: sdd,
    };
    seriesData.push(sd);
  });

  return seriesData;
}

function _getTooltipConfig() {
  return {
    backgroundColor: "rgba(255,255,255,1)",
    formatter() {
      const headFormat = `<div style="font-size:12px; font-weight: bold; text-align: center;">${moment(
        this.x
      ).format("YYYY-MM-DD ddd")}</div>`;

      let dataRowsFormat = "";
      for (let point of this.points) {
        const p = Highcharts.numberFormat(point.y, 0);
        let pointFormat = `<b>$ ${p}</b>`;

        const pp = Highcharts.numberFormat(point.percentage, 0);

        const rowFormat = `
          <tr>
            <td style="padding-top: 4px;font-weight: bold; text-align: right;padding-right: 4px;">
              <span style="color:${point.color}">\u25CF</span> ${point.series.name}: 
            </td>
            <td style="padding-top: 4px; text-align: right;">
              ${pointFormat}
            </td>

            <td style="padding-top: 4px; text-align: right;">
              (${pp}%)
            </td>
          </tr>
        `;
        dataRowsFormat += rowFormat;
      }

      let format = `
        ${headFormat}
        <table style="font-size: 12px;">
          ${dataRowsFormat}
        </table>
        `;

      return format;
    },
    shared: true,
    useHTML: true,
    crosshairs: true,
  };
}

function _getXAxisConfigDateTime() {
  return {
    type: "datetime",
    dateTimeLabelFormats: {
      day: "%m/%e",
    },
    // labels: {
    //   formatter: function () {
    //     const m = moment(this.value);
    //     const d = m.format("DD");
    //     // const d = m.format("MM/DD");
    //     const wd = m.format("ddd");
    //     const label = `${d} <br/> ${wd}`;
    //     if (d === "01" || this.isFirst) {
    //       return `${d} <br/> ${wd} <br/> <span style="color: #3182ce;	letter-spacing: 0.05em;">${m
    //         .format("MMM")
    //         .toUpperCase()}</span>`;
    //     }
    //     if (isWeekend(this.value)) {
    //       return `<span style="font-weight: bold; color: black;">${label}</span>`;
    //     }
    //     return label;
    //   },
    // },
    tickInterval: 24 * 60 * 60 * 1000, // 1 day
    crosshair: true,
  };
}

function _createPlotBands(reports) {
  const yesterdayData = _.find(reports, "isYesterday");
  const todayData = _.find(reports, "isToday");
  if (!yesterdayData || !todayData) {
    return {};
  }

  // To highlight yesterday
  const halfDay = 43200 * 1000;
  const from = new Date(yesterdayData.date).getTime() - halfDay;
  const to = new Date(todayData.date).getTime() - halfDay;

  // highlight months
  // 1. get months in reports
  // 2. highlight

  return {
    plotBands: [
      {
        from,
        to,
      },
    ],
  };
}

function _createPlotLines(reports) {
  const halfDay = 43200 * 1000;
  const dates = _.reduce(
    reports,
    (result, r) => {
      const date = new Date(r.date);
      if (date.getDate() === 1) {
        result.push({
          monthString: date.toLocaleString("en", { month: "short" }),
          value: date.getTime() - halfDay,
        });
      }
      return result;
    },
    []
  );

  const plotLines = _.map(dates, (d) => {
    return {
      color: "#fbd38d",
      width: 1,
      value: d.value,
      label: {
        text: d.monthString,
        verticalAlign: "top",
        textAlign: "right",
        y: 24, // lower label from the top
        style: {
          color: "#f29c09",
          fontWeight: "bold",
        },
      },
    };
  });

  return {
    plotLines,
  };
}

function _createSelectionEvents(handleFilterByDateRange) {
  return {
    events: {
      selection: function (event) {
        // console.log(event);

        if (event.resetSelection) {
          handleFilterByDateRange({ startTime: null, endTime: null });
          return;
        }

        // log the min and max of the primary, datetime x-axis
        // console.log(
        //   Highcharts.dateFormat("%Y-%m-%d %H:%M:%S", event.xAxis[0].min),
        //   Highcharts.dateFormat("%Y-%m-%d %H:%M:%S", event.xAxis[0].max)
        // );

        const startTime = new Date(event.xAxis[0].min);
        const endTime = new Date(event.xAxis[0].max);

        handleFilterByDateRange({ startTime, endTime });
      },
    },
  };
}

export default BizHighchartStackedView;
