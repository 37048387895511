import React from "react";
import _ from "lodash";
import Checkbox from "components/common/Checkbox";
import CstPredictScheduleFormatter from "./CstPredictScheduleFormatter";
// import CstScheduleTimePointsSelector from "./CstScheduleTimePointsSelector";

class CstScheduleControl extends React.PureComponent {
  constructor(props) {
    super(props);

    const { time_scale = "day", time_points = [] } = this.props.inputValue;
    let errorMsg = null;

    if (_.isEmpty(time_points)) {
      errorMsg = "Please fill in at least one time point";
      this.props.handleHasError(true);
    }

    this.state = {
      timeScale: time_scale, // day, week
      timePoints: time_points, // []
      timePointsStr: time_points.join(","),
      errorMsg,
    };

    this.handleItemsChanged = this.handleItemsChanged.bind(this);
    this.handleTimeScaleChanged = this.handleTimeScaleChanged.bind(this);
    this.handleImportTimePoints = this.handleImportTimePoints.bind(this);
  }

  handleItemsChanged({ items, itemsString }) {
    this.setState({
      timePoints: items,
      timePointsStr: itemsString,
      errorMsg: null,
    });

    if (_.isEmpty(items)) {
      this.setState({
        errorMsg: "Please fill in at least one item",
      });
      this.props.handleHasError(true);
      return;
    }

    this.props.handleHasError(false);
    // this.props.handleNewValueChanged(items);
    this._updateValue();
  }

  handleTimeScaleChanged(scaleType) {
    this.setState({
      timeScale: scaleType,
      timePoints: [],
      timePointsStr: "",
      errorMsg: null,
    });
    this.props.handleHasError(false);
    this._updateValue();
  }

  handleImportTimePoints(newTimepoints) {
    const { timePoints } = this.state;
    let newHours = _.uniq([...timePoints, ...newTimepoints]);
    newHours = newHours.sort((a, b) => a - b);
    this.setState({
      timePoints: newHours,
      timePointsStr: newHours.join(","),
      errorMsg: null,
    });
    this.props.handleHasError(false);
    this._updateValue();
  }

  _updateValue() {
    setTimeout(() => {
      const newValue = {
        time_scale: this.state.timeScale,
        time_points: this.state.timePoints,
      };
      this.props.handleNewValueChanged(newValue);
    });
  }

  render() {
    const { valueSchema } = this.props;

    const { timeScale, timePoints, timePointsStr, errorMsg } = this.state;

    return (
      <div className="border rounded ml-4 bg-white p-2 text-sm text-gray-700">
        <>
          <div className="font-semibold">Time Scale:</div>
          <div className="flex gap-2">
            {["day", "week"].map((scaleType) => {
              return (
                <label
                  htmlFor={`radio_${scaleType}`}
                  className="flex cursor-pointer items-center gap-1"
                  key={scaleType}
                >
                  <input
                    id={`radio_${scaleType}`}
                    type="radio"
                    value={scaleType}
                    checked={timeScale === scaleType}
                    onChange={() => this.handleTimeScaleChanged(scaleType)}
                  />{" "}
                  {scaleType}
                </label>
              );
            })}
          </div>

          <div className="mt-2 font-semibold">Time Points (Hours):</div>
          <div className="text-xs text-gray-600">
            {timeScale === "day"
              ? "Everyday at hours 0,12: 0,12"
              : "Every Monday at hours 0,10: 30,34"}
          </div>
          <div className="my-1">
            <TimePointsHelper
              timeScale={timeScale}
              handleImport={this.handleImportTimePoints}
            ></TimePointsHelper>
          </div>

          {/* SELECTOR NOT DONE */}
          {/* <CstScheduleTimePointsSelector
            timeScale={timeScale}
            timePoints={timePoints}
          ></CstScheduleTimePointsSelector> */}

          <TextAreaControl
            inputValue={timePointsStr}
            handleItemsChanged={this.handleItemsChanged}
          ></TextAreaControl>

          <div className="border rounded bg-gray-200 p-2">
            <CstPredictScheduleFormatter
              configValue={{
                time_scale: timeScale,
                time_points: timePoints,
              }}
              isTextOnlyFormatter={false}
            ></CstPredictScheduleFormatter>
          </div>

          <div className="text-red-600">{errorMsg}</div>
        </>
      </div>
    );
  }
}

const WEEKDAY_NAMES = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const HOURS_1 = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
const HOURS_2 = [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

class TimePointsHelper extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentWeekday: 0,
      currentHours: [],
      convertHours: [], // based on weekday
    };
    this.handleChanged = this.handleChanged.bind(this);
    this.handleHoursChecked = this.handleHoursChecked.bind(this);
    this.handleImportTimePoints = this.handleImportTimePoints.bind(this);
    this.handleSelect24Hours = this.handleSelect24Hours.bind(this);
  }

  // convert hours based on weekday
  // Sunday: 0 - 23
  // Monday: 24 - 47
  _convertHoursByWeekday(hours, weekday) {
    return _.map(hours, (hr) => {
      return hr + weekday * 24;
    });
  }

  handleChanged(e) {
    const { currentHours } = this.state;
    const currentWeekday = e.target.value;
    const convertHours = this._convertHoursByWeekday(
      currentHours,
      currentWeekday
    );
    this.setState({ currentWeekday, convertHours });
  }

  handleHoursChecked(hour) {
    const { currentHours, currentWeekday } = this.state;
    let newHours = [...currentHours];
    const currentIndex = _.indexOf(currentHours, hour);
    if (currentIndex !== -1) {
      // remove
      newHours.splice(currentIndex, 1);
    } else {
      // add
      newHours.push(hour);
    }
    newHours = newHours.sort((a, b) => a - b);

    const convertHours = this._convertHoursByWeekday(newHours, currentWeekday);

    this.setState({ currentHours: newHours, convertHours });
  }

  handleImportTimePoints() {
    const { convertHours } = this.state;
    this.props.handleImport(convertHours);
  }

  handleSelect24Hours() {
    const { currentHours, currentWeekday } = this.state;
    let newHours = [...HOURS_1, ...HOURS_2];

    const convertHours = this._convertHoursByWeekday(newHours, currentWeekday);

    this.setState({ currentHours: newHours, convertHours });
  }

  render() {
    const { timeScale } = this.props;
    const { currentWeekday, currentHours, convertHours } = this.state;

    return (
      <div className="border bg-gray-300 p-2">
        <div className="font-bold">Time Points Select Helper:</div>
        <div>
          Every{" "}
          {timeScale === "day" ? (
            "day"
          ) : (
            <select
              value={currentWeekday}
              onChange={this.handleChanged}
              className="border rounded ml-1 border-gray-400 bg-white leading-tight hover:border-gray-500"
            >
              {WEEKDAY_NAMES.map((name, weekday) => {
                return (
                  <option key={name} value={weekday}>
                    {name}
                  </option>
                );
              })}
            </select>
          )}
        </div>

        <div>
          <div>
            {HOURS_1.map((hr) => {
              return (
                <div key={hr} className="inline-block w-10">
                  <label className="flex cursor-pointer items-center gap-1">
                    <Checkbox
                      isChecked={_.indexOf(currentHours, hr) !== -1}
                      onChange={() => this.handleHoursChecked(hr)}
                    ></Checkbox>
                    <span className="align-middle text-sm font-semibold text-gray-700 hover:text-gray-800">
                      {hr}
                    </span>
                  </label>
                </div>
              );
            })}
          </div>
          <div>
            {HOURS_2.map((hr) => {
              return (
                <div key={hr} className="inline-block w-10">
                  <label className="flex cursor-pointer items-center gap-1">
                    <Checkbox
                      isChecked={_.indexOf(currentHours, hr) !== -1}
                      onChange={() => this.handleHoursChecked(hr)}
                    ></Checkbox>
                    <span className="align-middle text-sm font-semibold text-gray-700 hover:text-gray-800">
                      {hr}
                    </span>
                  </label>
                </div>
              );
            })}
          </div>
          <div>
            <div>
              Time points:{" "}
              <button
                type="button"
                className="border rounded hover:shadow bg-gray-100 px-1"
                onClick={this.handleSelect24Hours}
              >
                Select all
              </button>
            </div>
            <div>
              {convertHours.join(",")}{" "}
              {convertHours.length > 0 && (
                <button
                  type="button"
                  className="px-1 text-blue-600 hover:text-blue-700"
                  onClick={this.handleImportTimePoints}
                >
                  import
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const basicInputClass =
  "bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded py-1 px-2 block appearance-none leading-normal";

class TextAreaControl extends React.PureComponent {
  constructor(props) {
    super(props);

    const { inputValue } = this.props;

    this.state = {
      items: inputValue,
      itemsString: inputValue,
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.inputValue !== this.props.inputValue) {
      this.setState({
        items: this.props.inputValue,
        itemsString: this.props.inputValue,
      });
    }
  }

  onChange(e) {
    const itemsString = e.target.value;
    const values = _.sortBy(
      _.uniq(_.compact(_.map(itemsString.split(","), _.parseInt)))
    );

    this.setState({ itemsString, items: values });
    this.props.handleItemsChanged({ itemsString, items: values });
  }

  render() {
    const { itemsString } = this.state;

    return (
      <>
        <textarea
          rows={6}
          className={basicInputClass + " w-full"}
          value={itemsString}
          onChange={this.onChange}
        ></textarea>
        <div className="text-xs">Separate by ","</div>
      </>
    );
  }
}

export default CstScheduleControl;
