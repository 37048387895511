import React from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { notify } from "react-notify-toast";
import { Link } from "react-router-dom";
import { FiChevronLeft } from "react-icons/fi";
import { VseAPI } from "apis";
import { RECIPE_STATUS } from "../../constants/Recipes";
import RecipeFormWrapper from "./RecipeFormWrapper";

class RecipeCreateViewer extends React.Component {
  constructor(props) {
    super(props);

    const defaultNewRecipe = {
      id: "",
      name: "",
      status: RECIPE_STATUS.PAUSE,
      schedule: "0 8 * * *",

      preconditions: [], // { property, op, value }
      checkCriterias: [], // { check_id, accepted_results }
      actionTemplates: [], // { cat, value }
    };

    this.state = {
      recipe: defaultNewRecipe,
    };

    this.handleCreateRecipe = this.handleCreateRecipe.bind(this);
  }

  async componentDidMount() {
    document.title = `Create Recipe | VSE`;
  }

  async handleCreateRecipe(finalRecipe) {
    console.log("create recipe", finalRecipe);
    const params = {
      recipe: finalRecipe,
    };
    // try {
    const { result } = await VseAPI.createRecipe(params);
    if (result.op === "ok") {
      notify.show("Recipe created! Reloading page...", "success");
      setTimeout(() => {
        // window.location.reload(false);
        this.props.history.push("/vse/recipes");
      }, 3000);
    } else {
      throw `Failed response: ${JSON.stringify(result)}`;
    }
    // } catch (err) {
    //   throw err;
    // }
  }

  render() {
    const { recipe } = this.state;
    return (
      <div>
        <div className="-mt-4 mb-4">
          <Link to="/vse/recipes">
            <div className="flex">
              <div className="px-2 -ml-2 py-2 font-semibold text-gray-800 flex gap-2 items-center justify-self-start underline hover:bg-gray-300 rounded">
                <FiChevronLeft></FiChevronLeft> Recipes
              </div>
            </div>
          </Link>
        </div>

        <RecipeFormWrapper
          recipe={recipe}
          formType="CREATE"
          handleSubmit={this.handleCreateRecipe}
        ></RecipeFormWrapper>
      </div>
    );
  }
}

export default withRouter(RecipeCreateViewer);
