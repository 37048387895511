import React from "react";
import _ from "lodash";

import ModalWrapper from "../../../common/ModalWrapper";
import { NetworkAPI } from "apis";

class NotesModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      errMsg: null,
      notes: props.build.notes || "",
    };

    this.handleNotesChanged = this.handleNotesChanged.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleNotesChanged(e) {
    this.setState({ notes: e.target.value });
  }

  async handleSave() {
    // put: builds/:buildId/notes
    this.setState({ isSaving: true, errMsg: null });
    try {
      const params = {
        networkId: this.props.networkId,
        buildId: this.props.build.buildId,
        notes: this.state.notes,
      };
      const r = await NetworkAPI.updateBuildNotes(params);
      await this.props.handleRefreshAfterBuild();
    } catch (err) {
      this.setState({
        errMsg: err,
      });
    }
    this.setState({ isSaving: false });
  }

  handleCancel() {
    this.props.handleClose();
  }

  render() {
    const { isSaving, notes } = this.state;
    const { isOpenViewModal, handleClose, buildId, build } = this.props;

    return (
      <ModalWrapper
        isOpen={isOpenViewModal}
        showCloseFooter={false}
        handleClose={handleClose}
      >
        <>
          <div className="border-b -mx-6 mb-4 px-6 pb-2 text-lg font-bold">
            {buildId}{" "}
            <span className="font-normal">
              ({build.packageName} version {build.packageVersion})
            </span>
          </div>

          <div className="text-lg font-semibold">Notes:</div>
          <div className="overflow-y-auto" style={{ height: "360px" }}>
            <textarea
              className="rounded border w-full bg-gray-100 p-2 text-sm"
              rows={12}
              value={notes}
              onChange={this.handleNotesChanged}
              autoFocus
            ></textarea>
          </div>
          <div className="absolute bottom-0 right-0 p-4">
            <button
              className="rounded flex-shrink-0 border-4 border-transparent py-2 px-4 font-semibold text-blue-500 hover:text-blue-800"
              type="button"
              onClick={this.handleCancel}
            >
              Cancel
            </button>

            <button
              className="rounded flex-shrink-0 bg-blue-500 py-2 px-4 font-semibold text-white  hover:bg-blue-600"
              type="button"
              onClick={this.handleSave}
              disabled={isSaving}
            >
              {isSaving ? "Saving..." : "Save"}
            </button>
          </div>
        </>
      </ModalWrapper>
    );
  }
}

export default NotesModal;
