import React from "react";
import _ from "lodash";
import AdSizesControl from "./AdSizesControl";

class NewSetOfAdSizesControl extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      newSet: props.currentSet,
      errorMsg: "",
    };

    this.handleAddNewAdSizes = this.handleAddNewAdSizes.bind(this);
    this.handleAddSetClicked = this.handleAddSetClicked.bind(this);
  }

  handleAddNewAdSizes(adSizes) {
    this.setState({ newSet: adSizes });
  }

  handleAddSetClicked() {
    if (!this.state.newSet || _.isEmpty(this.state.newSet)) {
      this.setState({
        errorMsg: "Add ad sizes first",
      });
      return;
    }

    this.setState({
      newSet: [],
      errorMsg: "",
    });

    this.props.handleAddNewSet(this.state.newSet);
  }

  render() {
    const { newSet, errorMsg } = this.state;

    return (
      <div className="bg-white rounded shadow">
        <div className="border-b px-2 pt-2">New Set:</div>
        <div className="px-2 mb-1">
          <AdSizesControl
            key={newSet}
            inputValue={newSet}
            allowFluidSize={true}
            handleNewValueChanged={this.handleAddNewAdSizes}
          ></AdSizesControl>
        </div>
        <div className="flex justify-end items-center py-2 px-2 border-t mt-1">
          <div className="text-red-600 mr-2">{errorMsg}</div>
          <button
            type="button"
            className={`text-blue-800 bg-blue-200 font-semibold rounded px-2 py-1`}
            onClick={this.handleAddSetClicked}
          >
            Add set
          </button>
        </div>
      </div>
    );
  }
}

export default NewSetOfAdSizesControl;
