import React from "react";
import _ from "lodash";
import NumberFormat from "../../common/NumberFormat";
import { getWeekdayAbbr } from "../../../helpers/DateTransform";

class TableRequestByRequestType extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mousedOverRow: null
    };

    this.handleMouseOverRow = this.handleMouseOverRow.bind(this);
  }

  handleMouseOverRow(rowKey) {
    this.setState({ mousedOverRow: rowKey });
  }

  // shouldComponentUpdate() {
  //   return false;
  // }

  render() {
    const { reports, selectedDate, handleSelectDate } = this.props;

    const requestTypes = _(reports)
      .groupBy("requestType")
      .keys()
      .uniq()
      .orderBy(["desc"])
      .value();

    const rs = _(reports)
      .groupBy("date")
      .map((reports, date) => {
        const row = {
          date
        };

        _.forEach(reports, r => {
          _.forEach(requestTypes, gt => {
            if (gt === r.requestType) {
              row[gt] = r;
            }
          });
        });

        return row;
      })
      .orderBy(["date"], ["desc"])
      .value();

    return (
      <>
        <div className="py-1 font-bold text-gray-700">
          Scroll down to see <b>Ad Sizes Distribution</b> of each day.
        </div>

        <div className="text-xs text-gray-600">
          <span className="font-bold font-sm">*</span>: Need to wait until the
          end of today for the data to be complete.
        </div>
        <table className="w-full table text-sm border-2 shadow">
          <thead className=" text-blue-800 text-xs border-b">
            <tr>
              <th className="text-right py-1 px-4">Date</th>
              <th className="text-center py-1 px-4">Request Type</th>

              <th className="text-right py-1 px-4">STR</th>

              <th className="text-right py-1 px-4">Req</th>
              <th className="text-right py-1 px-4">Imp</th>
            </tr>
          </thead>
          <tbody className="text-gray-900 font-mono bg-white">
            {rs.map((r, i) => {
              const weekdayAbbr = getWeekdayAbbr(r.date);
              const rowKey = r.date;

              return (
                <React.Fragment key={i}>
                  {requestTypes.map((type, j) => {
                    const gtr = _.get(r, type, {});
                    const isMouseOver = this.state.mousedOverRow === rowKey;
                    const isSelected = selectedDate === rowKey;
                    const shouldHighlight = isSelected;
                    const isLastGroupRow = j === requestTypes.length - 1;

                    const dateColClass = shouldHighlight
                      ? "bg-indigo-400 text-white"
                      : "bg-white text-gray-800";

                    let rowClass = isMouseOver
                      ? "border-l-2 border-r-2 border-indigo-600"
                      : "";

                    if (j === requestTypes.length - 1) {
                      rowClass = [rowClass, "border-b border-indigo-600"].join(
                        " "
                      );
                    }

                    const rowStyle = getTypeRowStyle(type, shouldHighlight);
                    const colClass = `text-gray-800 py-1 px-4 ${
                      isLastGroupRow ? "border-b border-gray-400" : ""
                    }`;

                    return (
                      <tr
                        key={type}
                        className={`cursor-pointer ${rowClass}`}
                        onMouseOver={() => this.handleMouseOverRow(rowKey)}
                        onMouseOut={() => this.handleMouseOverRow(null)}
                        onClick={() => handleSelectDate(r.date)}
                        style={rowStyle}
                      >
                        {j === 0 && (
                          <td
                            rowSpan={requestTypes.length}
                            className={`text-right py-1 px-4 border-b border-gray-400 ${dateColClass}`}
                          >
                            {i === 0 && "*"}
                            {r.date} {weekdayAbbr}
                          </td>
                        )}

                        <td className={`text-center ${colClass}`}>
                          <RequestTypeAbbr requestType={type}></RequestTypeAbbr>
                        </td>

                        <td
                          className={`text-right font-mono font-bold ${colClass}`}
                        >
                          {_.isNumber(gtr.str) ? (
                            <NumberFormat
                              value={gtr.str}
                              format="0,0.00%"
                            ></NumberFormat>
                          ) : (
                            "-"
                          )}
                        </td>

                        <td className={`text-right font-mono ${colClass}`}>
                          {_.isNumber(gtr.request) ? (
                            <NumberFormat
                              value={gtr.request}
                              format="0,0"
                            ></NumberFormat>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className={`text-right font-mono ${colClass}`}>
                          {_.isNumber(gtr.impression) ? (
                            <NumberFormat
                              value={gtr.impression}
                              format="0,0"
                            ></NumberFormat>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }
}

class RequestTypeAbbr extends React.PureComponent {
  render() {
    const { requestType } = this.props;
    let abbr = requestType;

    return <span className="rounded font-mono font-bold px-2">{abbr}</span>;
  }
}

function getTypeRowStyle(type, shouldHighlight) {
  if (shouldHighlight) {
    switch (type) {
      case "GPT": {
        return { backgroundColor: "#c9c9ff" };
      }
      case "AMP": {
        return { backgroundColor: "#d7f5e5" };
      }
      // case "unmanaged": {
      //   return { backgroundColor: "#afb5c7" };
      // }
      default: {
        // nothin
      }
    }
  } else {
    switch (type) {
      case "GPT": {
        return { backgroundColor: "#e7e7ff" };
      }
      case "AMP": {
        return { backgroundColor: "#d7f5e5" };
      }
      // case "unmanaged": {
      //   return { backgroundColor: "#F7FAFC" };
      // }
      default: {
        // nothin
      }
    }
  }
}

export default TableRequestByRequestType;
