import React from "react";
import _ from "lodash";

const inputClass =
  "bg-white flex w-full border border-gray-400 rounded py-1 px-3 appearance-none leading-normal focus:outline-none focus:border-blue-400 focus:shadow-inner hover:border-gray-500";

const SCORING_METHOD = {
  request_volume: "request_volume",
  billable_revenue: "billable_revenue",
};

class SuggestionPricesSection extends React.Component {
  constructor(props) {
    super(props);

    // params{}:
    // price_scoring_method: string, // optional, default 'request_volume'
    // upr_segment_scoring_method: string, // optional, default 'request_volume'
    // total_upr_slot: integer, // temporarily, default 20
    // time_span: integer, // default 12 (hours)

    const DEFAULT = {
      totalUprSlot: 20,
      priceMethod: SCORING_METHOD.request_volume,
      segmentMethod: SCORING_METHOD.request_volume,
      timeSpan: 12,
    };

    const { totalUprSlot, priceMethod, segmentMethod, timeSpan } = this.props;

    this.state = {
      totalUprSlot: totalUprSlot || DEFAULT.totalUprSlot,
      priceMethod: priceMethod || DEFAULT.priceMethod,
      segmentMethod: segmentMethod || DEFAULT.segmentMethod,
      timeSpan: timeSpan || DEFAULT.timeSpan,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleGetSuggestion = this.handleGetSuggestion.bind(this);
  }

  handleChange(value, id) {
    this.setState({
      [id]: value,
    });
  }

  handleGetSuggestion() {
    const { totalUprSlot, priceMethod, segmentMethod, timeSpan } = this.state;
    const params = {
      total_upr_slot: parseInt(totalUprSlot),
      time_span: parseInt(timeSpan),
      price_scoring_method: priceMethod,
      upr_segment_scoring_method: segmentMethod,

      proposer_version: "v3", // v2 does not have unit suggestions
    };
    this.props.handleGetSuggestion({ params });
  }

  render() {
    const { totalUprSlot, priceMethod, segmentMethod, timeSpan } = this.state;

    return (
      <div>
        <div className="border-b mt-2 text-base font-bold text-gray-700">
          Prices:
        </div>

        <div className="flex flex-col gap-3 py-2">
          <div>
            <label
              className="block font-bold text-gray-700"
              htmlFor="totalUprSlot"
            >
              Price Scoring Method:
            </label>

            <div className="flex items-center gap-4">
              <label
                htmlFor={`price_scoring_method__request_volume`}
                className="cursor-pointer"
              >
                <input
                  id={`price_scoring_method__request_volume`}
                  type="radio"
                  name={`price_scoring_method`}
                  value={priceMethod}
                  checked={priceMethod === SCORING_METHOD.request_volume}
                  onChange={() =>
                    this.handleChange(
                      SCORING_METHOD.request_volume,
                      "priceMethod"
                    )
                  }
                />{" "}
                {SCORING_METHOD.request_volume}
              </label>
              <label
                htmlFor={`price_scoring_method__billable_revenue`}
                className="cursor-pointer"
              >
                <input
                  id={`price_scoring_method__billable_revenue`}
                  type="radio"
                  name={`price_scoring_method`}
                  value={priceMethod}
                  checked={priceMethod === SCORING_METHOD.billable_revenue}
                  onChange={() =>
                    this.handleChange(
                      SCORING_METHOD.billable_revenue,
                      "priceMethod"
                    )
                  }
                />{" "}
                {SCORING_METHOD.billable_revenue}
              </label>
            </div>
          </div>

          <div>
            <label
              className="block font-bold text-gray-700"
              htmlFor="totalUprSlot"
            >
              UPR Segment Scoring Method:
            </label>

            <div className="flex items-center gap-4">
              <label
                htmlFor={`segment_scoring_method__request_volume`}
                className="cursor-pointer"
              >
                <input
                  id={`segment_scoring_method__request_volume`}
                  type="radio"
                  name={`segment_scoring_method`}
                  value={segmentMethod}
                  checked={segmentMethod === SCORING_METHOD.request_volume}
                  onChange={() =>
                    this.handleChange(
                      SCORING_METHOD.request_volume,
                      "segmentMethod"
                    )
                  }
                />{" "}
                {SCORING_METHOD.request_volume}
              </label>
              <label
                htmlFor={`segment_scoring_method__billable_revenue`}
                className="cursor-pointer"
              >
                <input
                  id={`segment_scoring_method__billable_revenue`}
                  type="radio"
                  name={`segment_scoring_method`}
                  value={segmentMethod}
                  checked={segmentMethod === SCORING_METHOD.billable_revenue}
                  onChange={() =>
                    this.handleChange(
                      SCORING_METHOD.billable_revenue,
                      "segmentMethod"
                    )
                  }
                />{" "}
                {SCORING_METHOD.billable_revenue}
              </label>
            </div>
          </div>

          <div>
            <label
              className="block font-bold text-gray-700"
              htmlFor="totalUprSlot"
            >
              UPR Slots:
            </label>
            <input
              className={inputClass}
              style={{ width: "130px" }}
              id="totalUprSlot"
              type="number"
              value={totalUprSlot}
              onChange={(e) =>
                this.handleChange(e.target.value, "totalUprSlot")
              }
              max={200}
              step={1}
            />
          </div>

          <div>
            <label
              className="block font-bold text-gray-700"
              htmlFor="totalUprSlot"
            >
              Time Span (hours):
            </label>
            <div className="text-xs leading-tight text-gray-700">
              Number of hours to look back (from now) to get data for propose
              new prices.
            </div>

            <input
              className={inputClass}
              style={{ width: "130px" }}
              id="timeSpan"
              type="number"
              value={timeSpan}
              onChange={(e) => this.handleChange(e.target.value, "timeSpan")}
              step={1}
            />
          </div>

          <button
            type="button"
            onClick={this.handleGetSuggestion}
            className="rounded mt-2 bg-blue-200 py-1 px-2 font-medium text-blue-900 hover:bg-blue-300"
            style={{ marginBottom: "1px" }}
          >
            Get Price Suggestions
          </button>
        </div>
      </div>
    );
  }
}

export default SuggestionPricesSection;
