import React from "react";
import _ from "lodash";

import Swal from "sweetalert2";

import { OnboardingAPI } from "apis";
import NetworksSelector from "./NetworksSelector";
import YieldSetsSelector from "./YieldSetsSelector";
import Checkbox from "components/common/Checkbox";

class OnboardAdUnits extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedNetwork: null,
      selectedYieldSet: null,

      onboardAdUnitsCsvString: "",
      extUnitIds: [],

      skipParentChildrenOnboardConflictionCheck: false,

      errMsg: "",
      isSaving: false,

      validateResult: null,
      onboardedUnits: null,
    };

    this.onSelectNetwork = this.onSelectNetwork.bind(this);
    this.onSelectYieldSet = this.onSelectYieldSet.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleSkipChanged = this.handleSkipChanged.bind(this);

    this.handleValidateClicked = this.handleValidateClicked.bind(this);
    this.handleOnboard = this.handleOnboard.bind(this);
  }

  componentDidMount() {
    document.title = `Onboard Ad Units`;
  }

  onSelectNetwork(network) {
    this.setState({
      selectedNetwork: network,
      selectedYieldSet: null,
    });
  }

  onSelectYieldSet(ys) {
    this.setState({
      selectedYieldSet: ys,
    });
  }

  onChange(e) {
    this.setState({
      onboardAdUnitsCsvString: e.target.value,
      extUnitIds: this.transformCsvToArray(e.target.value),
    });
  }

  handleSkipChanged() {
    this.setState({
      skipParentChildrenOnboardConflictionCheck:
        !this.state.skipParentChildrenOnboardConflictionCheck,
    });
  }

  transformCsvToArray(csv) {
    let string = this._transformFn(csv);
    let items = _.split(string, ",");
    items = _.uniq(_.compact(items));
    return items;
  }

  _transformFn(content) {
    const charArr = content.split("");
    let validContent = [];
    for (let c of charArr) {
      const lastC = _.last(validContent);
      switch (c) {
        case "\r": {
          continue;
        }

        case "\n":
        case " ": {
          // Ignore if we see continuous seperator
          if (lastC === undefined || lastC === ",") {
            continue;
          }

          c = ",";
          validContent.push(c);
          break;
        }

        default: {
          if (c >= "0" && c <= "9") {
            validContent.push(c);
          } else if (lastC && lastC != ",") {
            validContent.push(",");
          }
          break;
        }
      }
    }

    return validContent.join("");
  }

  async handleValidateClicked() {
    let saveSwal = Swal;

    try {
      const {
        selectedNetwork,
        selectedYieldSet,
        onboardAdUnitsCsvString,
        extUnitIds,
        skipParentChildrenOnboardConflictionCheck,
      } = this.state;
      if (!selectedNetwork) {
        throw "Please select a network";
      }

      if (!selectedYieldSet) {
        throw "Please select a Yield Set";
      }

      if (onboardAdUnitsCsvString === "") {
        throw "Please input ext unit ids that you would like to onboard";
      }

      let params = {
        networkId: selectedNetwork.networkId,
        networkCode: selectedNetwork.code,
        extUnitIds,
        skipParentChildrenOnboardConflictionCheck,
      };

      this.setState({
        isSaving: true,
      });

      this._showSavingPopup(saveSwal, "Validating...");

      const result = await OnboardingAPI.validateOnboardGamAdUnits(params);
      console.log(result);

      this.setState({
        validateResult: result,
        isSaving: false,
        errMsg: "",
      });
    } catch (err) {
      console.log(err);
      this.setState({
        errMsg: err,
        isSaving: false,
      });
    }

    setTimeout(() => {
      // window.location.reload(false);
      // this.props.history.goBack();
      this._hideSavingPopup(saveSwal);
    }, 500);
  }

  _showSavingPopup(saveSwal, title) {
    saveSwal.fire({
      title: title || "Saving...",
      timerProgressBar: true,
      showConfirmButton: true,
      didOpen: () => {
        Swal.showLoading();
      },
      allowOutsideClick: () => !Swal.isLoading(),
      backdrop: true,
    });
  }

  _hideSavingPopup(saveSwal) {
    saveSwal.close();
  }

  async handleOnboard() {
    let saveSwal = Swal;

    try {
      const {
        selectedNetwork,
        selectedYieldSet,
        onboardAdUnitsCsvString,
        extUnitIds,
        skipParentChildrenOnboardConflictionCheck,
      } = this.state;
      if (!selectedNetwork) {
        throw "Please select a network";
      }

      if (!selectedYieldSet) {
        throw "Please select a Yield Set";
      }

      if (onboardAdUnitsCsvString === "") {
        throw "Please input ext unit ids that you would like to onboard";
      }

      let params = {
        yieldSetId: selectedYieldSet.yieldSetId,
        extUnitIds,
        skipParentChildrenOnboardConflictionCheck,
      };

      this.setState({
        isSaving: true,
      });

      this._showSavingPopup(saveSwal, "Onboarding...");

      const result = await OnboardingAPI.onboardGamAdUnits(params);
      console.log(result);

      this.setState({
        isSaving: false,
        errMsg: "",

        onboardedUnits: result.gamAdUnits,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        errMsg: err,
        isSaving: false,
        onboardedUnits: null,
      });
    }

    setTimeout(() => {
      // window.location.reload(false);
      // this.props.history.goBack();
      this._hideSavingPopup(saveSwal);
    }, 500);
  }

  render() {
    const {
      selectedNetwork,
      selectedYieldSet,
      onboardAdUnitsCsvString,
      extUnitIds,
      errMsg,
      validateResult,
      onboardedUnits,
      skipParentChildrenOnboardConflictionCheck,
    } = this.state;

    return (
      <div>
        <div className="bg-white px-12">
          <h1 className="pt-8 pb-4 text-4xl font-extrabold text-gray-900">
            Onboard Ad Units
          </h1>
        </div>

        <div className="min-h-screen bg-gray-200 px-12 py-8">
          <div className="flex">
            <div className="w-1/2">
              <div className="mb-4">
                1. Select Network:
                <div>
                  <NetworksSelector
                    selectedNetwork={selectedNetwork}
                    handleOnChange={this.onSelectNetwork}
                  ></NetworksSelector>
                </div>
              </div>

              <div className="mb-4">
                2. Select Yield Set:
                <div>
                  {selectedNetwork ? (
                    <YieldSetsSelector
                      selectedNetwork={selectedNetwork}
                      selectedYieldSet={selectedYieldSet}
                      networkId={selectedNetwork.networkId}
                      handleOnChange={this.onSelectYieldSet}
                    ></YieldSetsSelector>
                  ) : (
                    <div className="text-sm text-gray-700">
                      Please select a network first
                    </div>
                  )}
                </div>
              </div>

              <div className="mb-4">
                3. Ext Unit Ids (Use <b>comma</b>, <b>whitespace</b> or{" "}
                <b>line break</b> to separate ids){" "}
                <div className="text-sm text-gray-600">ex. 423,235,246</div>
                <div>
                  <textarea
                    className="border rounded w-full border-gray-400 p-2"
                    value={onboardAdUnitsCsvString}
                    onChange={this.onChange}
                    rows={5}
                  ></textarea>
                  <div className="text-sm leading-none text-gray-700">
                    {extUnitIds.length} units
                  </div>
                </div>
              </div>
            </div>

            <div className="w-1/2">
              <div
                className={`border ${
                  validateResult && "shadow border-blue-400"
                } rounded mx-4 p-4`}
              >
                <div className="mb-2 font-semibold text-gray-700">
                  Validate Results:
                </div>
                {validateResult ? (
                  <div>
                    <div className="mb-2">Total: {validateResult.total}</div>
                    <div className="mb-2">
                      Success: {validateResult.success}{" "}
                    </div>
                    <div className="mb-2">Failed: {validateResult.fail}</div>
                    <div className="mb-2">
                      Detail Url:{" "}
                      <a
                        className="text-blue-600"
                        href={validateResult.detailUrl}
                      >
                        Click to download
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className="text-sm text-gray-600">
                    Click the validate button to see validate results
                  </div>
                )}
              </div>

              {onboardedUnits && (
                <div
                  className={`border ${
                    onboardedUnits && "shadow border-blue-400"
                  } rounded mx-4 mt-4 p-4`}
                >
                  <div className="mb-2 font-semibold text-gray-700">
                    Onboarded Ad Units List: ({onboardedUnits.length} units)
                  </div>
                  {onboardedUnits ? (
                    <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                      <ul>
                        {onboardedUnits.map((unit) => {
                          return (
                            <li key={unit.adUnitId} className="text-gray-900">
                              {unit.adUnitId} - {unit.name}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : (
                    <div className="text-sm text-gray-600">
                      Shows the list of units that were onboarded
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="mt-4">
            {errMsg !== "" && (
              <div className="rounded mb-4 bg-red-100 p-4 font-semibold text-red-700">
                {errMsg}
              </div>
            )}

            <div className="rounded mb-2 bg-blue-200 p-4">
              <label className="flex cursor-pointer items-center">
                <Checkbox
                  isChecked={skipParentChildrenOnboardConflictionCheck}
                  onChange={this.handleSkipChanged}
                ></Checkbox>
                <span className="ml-1 align-middle text-sm font-semibold text-gray-700 hover:text-gray-700">
                  Skip parent children onboard confliction check
                </span>
              </label>
            </div>

            <div className="flex justify-center">
              <button
                type="button"
                className="rounded mr-4 bg-teal-500 px-6 py-2 font-semibold text-white"
                onClick={this.handleValidateClicked}
              >
                Validate
              </button>

              <button
                type="button"
                className="rounded bg-blue-500 px-6 py-2 font-semibold text-white"
                onClick={this.handleOnboard}
              >
                Onboard
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OnboardAdUnits;
