import _ from "lodash";
import { api, fetchRespJson, postData } from "./base";
import {
  transformHCUnits,
  transformSnapshotMoneyMetrics,
} from "helpers/Health-Check-Unit";

export default {
  // vse
  getHealthChecksByNetwork,
  getVSEPendingActionsByNetwork,
  getAllRecipes,
  getVSEExecutionsByNetwork,
  resetVSEExecutions,
  executeVSEPendingActions,
  getAllChecks,
  createRecipe,
  updateRecipe,
  updateRecipeStatus,
};

async function updateRecipeStatus({ recipeId, status }) {
  const apiUrl = `${api}/vse/recipe/${recipeId}/update-recipe-status`;
  return await postData(apiUrl, { status });
}

async function updateRecipe({ recipeId, currentVersion, recipe }) {
  const apiUrl = `${api}/vse/recipe/${recipeId}/update-recipe`;
  return await postData(apiUrl, { currentVersion, recipe });
}

async function createRecipe({ recipe }) {
  const apiUrl = `${api}/vse/create-recipe`;
  return await postData(apiUrl, { recipe });
}

async function getAllChecks() {
  let apiUrl = `${api}/vse/checks`;
  const { checks } = await fetchRespJson(apiUrl);
  return { checks };
}

async function executeVSEPendingActions({ unitIds }) {
  const apiUrl = `${api}/vse/pending-actions/executions`;
  return await postData(apiUrl, { unitIds });
}

async function resetVSEExecutions({ networkId, recipeIds }) {
  const apiUrl = `${api}/vse/network/${networkId}/reset-executions`;
  return await postData(apiUrl, { networkId, recipeIds });
}

async function getAllRecipes() {
  let apiUrl = `${api}/vse/recipes`;
  const { recipes } = await fetchRespJson(apiUrl);
  return { recipes };
}

async function getVSEExecutionsByNetwork({ networkId }) {
  let apiUrl = `${api}/vse/executions/network/${networkId}`;
  const { executions, networkInfo } = await fetchRespJson(apiUrl);
  return { executions, networkInfo };
}

async function getVSEPendingActionsByNetwork({ networkId }) {
  let apiUrl = `${api}/vse/pending-actions/network/${networkId}`;
  const r = await fetchRespJson(apiUrl);
  let snapshots = [];
  if (r.snapshot) {
    const response = await fetch(r.snapshot.signedUrl);
    snapshots = await response.json();
    snapshots = transformSnapshotMoneyMetrics(snapshots);
  }

  const includeEmpty = true; // include those with no snapshots
  r.pendingActions = transformHCUnits(
    {
      reports: r.pendingActions,
      snapshots,
      checks: r.checks,
    },
    includeEmpty
  );
  r.snapshotsByUnitId = _.keyBy(snapshots, "gam_unit_id");

  return r;
}

async function getHealthChecksByNetwork({ networkId }) {
  let apiUrl = `${api}/health-check/network/${networkId}`;
  const r = await fetchRespJson(apiUrl);
  let snapshots = [];
  let snapshotUpdatedAt = null;
  if (r.snapshot) {
    snapshotUpdatedAt = r.snapshot.updatedAt;
    const response = await fetch(r.snapshot.signedUrl);
    snapshots = await response.json();
    snapshots = transformSnapshotMoneyMetrics(snapshots);
  }

  const units = transformHCUnits({
    reports: r.reports,
    snapshots,
    checks: r.checks,
  });
  // return all snapshots (to draw unit profile card)
  return {
    networkInfo: r.networkInfo,
    updatedAt: r.updatedAt,
    snapshotUpdatedAt,
    units,
    snapshotsByUnitId: _.keyBy(snapshots, "gam_unit_id"),
  };
}
