import React from "react";
// import _ from "lodash";
import { FiX } from "react-icons/fi";

class AdSizesFormatter extends React.PureComponent {
  render() {
    const { adSizes, showRemoveButton, handleRemoveAdSize } = this.props;

    if (!adSizes) {
      return "None";
    }

    if (typeof adSizes === "string") {
      return adSizes;
    }

    // if (typeof adSizes === "number") {
    //   return adSizes;
    // }
    // console.log(adSizes, typeof adSizes);

    return (
      <div className="flex flex-wrap">
        {adSizes.map((size, i) => {
          const sizeString = typeof size === "string" ? size : size.join("x");

          return (
            <div
              key={i}
              className="px-2 bg-gray-400 mr-2 flex items-center mb-1"
            >
              <div className="font-mono">{sizeString}</div>
              {showRemoveButton && (
                <button
                  type="button"
                  className="text-blue-600 hover:text-blue-800 ml-2"
                  onClick={() => handleRemoveAdSize(size)}
                >
                  <FiX></FiX>
                </button>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

export default AdSizesFormatter;
