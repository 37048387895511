import React from "react";
import _ from "lodash";

import ModalWrapper from "../../../common/ModalWrapper";
import OverrideConfig from "./OverrideConfig";

class OverrideModal extends React.Component {
  constructor(props) {
    super(props);

    const existingRegistries = _.map(
      props.riConfig.overrides || [],
      "registry_id"
    );
    let { registryOptions, currentOverride } = props;
    registryOptions = _.filter(registryOptions, (option) => {
      const id = _.parseInt(option.registry_id);
      return _.indexOf(existingRegistries, id) === -1;
    });

    this.state = {
      registryOptions,
      currentOverride,
      isInvalid: false,
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.handleOverrideChanged = this.handleOverrideChanged.bind(this);

    this.handleIsInvalid = this.handleIsInvalid.bind(this);
  }

  componentDidMount() {
    this.handleIsInvalid(this.props.currentOverride);
  }

  handleSave() {
    let { intConfig, integration, riConfig, ri } = this.props;
    const { currentOverride } = this.state;
    let newRiConfig = { ...riConfig };
    // remove old override if exists
    newRiConfig.overrides = _.filter(newRiConfig.overrides, (o) => {
      return o.registry_id !== currentOverride.registry_id;
    });
    newRiConfig.overrides = [...newRiConfig.overrides, currentOverride];
    intConfig["registered_inventory"][ri] = newRiConfig;

    this.props.handleSave(intConfig);
  }

  handleCancel() {
    this.setState({ newIntConfig: null });
    this.props.handleCloseModal();
  }

  handleOverrideChanged(newOverride) {
    this.setState({ currentOverride: newOverride });
    this.handleIsInvalid(newOverride);
  }

  handleIsInvalid(currentOverride) {
    let isInvalid = false;
    // if only has registry_id -> invalid
    if (_.keys(currentOverride).length <= 1) {
      isInvalid = true;
    }
    this.setState({ isInvalid });
  }

  render() {
    const { isModalOpen, ri, riConfig } = this.props;
    const { isInvalid, currentOverride, registryOptions } = this.state;

    return (
      <ModalWrapper
        isOpen={isModalOpen}
        showCloseFooter={false}
        handleClose={this.handleCloseModal}
        width="60%"
      >
        <div className="border-b -mx-6 mb-4 -mt-5 bg-blue-100 py-4 px-6 text-xl font-bold text-blue-800">
          Override: {ri}
        </div>

        <div className="overflow-y-auto" style={{ height: "430px" }}>
          {riConfig && (
            <OverrideConfig
              overrides={riConfig.overrides || []}
              currentOverride={currentOverride}
              registryOptions={registryOptions}
              handleOverrideChanged={this.handleOverrideChanged}
            ></OverrideConfig>
          )}
        </div>

        <div className="mt-4 flex flex-row-reverse items-center">
          <div>
            <button
              type="button"
              className={`px-4 py-2 ${
                isInvalid
                  ? "cursor-not-allowed bg-gray-400 text-gray-800"
                  : "bg-blue-500 text-white hover:bg-blue-700"
              }  rounded shadow font-semibold`}
              onClick={this.handleSave}
              disabled={isInvalid}
            >
              Save
            </button>
          </div>
          <div>
            <button
              type="button"
              disabled={false}
              className="px-4 py-2 text-blue-700"
              onClick={this.handleCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </ModalWrapper>
    );
  }
}

export default OverrideModal;
