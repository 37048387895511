import React from "react";
import _ from "lodash";
import PopupWrapper from "../../../common/PopupWrapper";
import UprTargetingsView from "./UprTargetingsView";

class UprTargetingPopover extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      targetingSection = {},
      triggerElement,
      units,
      virtualPlacements,
      networkId,
    } = this.props;

    return (
      <PopupWrapper
        place="bottom"
        triggerType="click"
        hideArrow={false}
        triggerElement={triggerElement}
        popupElement={
          <div
            className="shadow border p-4"
            style={{ width: "600px", maxHeight: "600px", overflow: "auto" }}
          >
            <UprTargetingsView
              networkId={networkId}
              targetingSection={targetingSection}
              units={units}
              virtualPlacements={virtualPlacements}
            ></UprTargetingsView>
          </div>
        }
      ></PopupWrapper>
    );
  }
}

export default UprTargetingPopover;
