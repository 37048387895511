import React from "react";
import _ from "lodash";
import Checkbox from "../../../common/Checkbox";

class DeviceCategoriesControl extends React.PureComponent {
  constructor(props) {
    super(props);

    const categories = this.props.inputValue;
    let errorMsg = null;

    if (_.isEmpty(categories)) {
      errorMsg = "Please select at least one category";
      this.props.handleHasError(true);
    }

    this.state = {
      categories,
      // radioValue: this.props.inputValue ? "CUSTOM" : "NONE", // NONE or CUSTOM
      errorMsg,
    };

    // this.handleChangeRadio = this.handleChangeRadio.bind(this);
    this.handleCategoriesChanged = this.handleCategoriesChanged.bind(this);
  }

  // handleChangeRadio(radioValue) {
  //   this.setState({ radioValue, errorMsg: null });

  //   if (radioValue === "NONE") {
  //     this.props.handleNewValueChanged(null);
  //   }
  // }

  handleCategoriesChanged(categories) {
    this.setState({ categories, errorMsg: null });

    if (_.isEmpty(categories)) {
      this.setState({
        errorMsg: "Please select at least one category",
      });
      this.props.handleHasError(true);
      return;
    }

    this.props.handleHasError(false);
    this.props.handleNewValueChanged(categories);
  }

  render() {
    const { valueSchema } = this.props;
    // const configKey = valueSchema.key;

    const {
      // radioValue,
      categories,
      errorMsg,
    } = this.state;

    return (
      <div className="text-sm text-gray-700 ml-4 border rounded p-2 bg-white">
        <>
          <DeviceCategoriesSelection
            inputValue={categories}
            options={valueSchema.arrayOptions}
            handleCategoriesChanged={this.handleCategoriesChanged}
          ></DeviceCategoriesSelection>

          <div className="text-red-600">{errorMsg}</div>
        </>
        {/* <div className="mb-1">
          <label htmlFor="radio_none" className="cursor-pointer">
            <input
              id="radio_none"
              type="radio"
              name={`${configKey}_radio_none`}
              value="NONE"
              checked={radioValue === "NONE"}
              onChange={() => this.handleChangeRadio("NONE")}
            />{" "}
            None
          </label>
        </div>

        <div>
          <label htmlFor="radio_select_devices" className="cursor-pointer">
            <input
              id="radio_select_devices"
              type="radio"
              name={`${configKey}_radio_select_devices`}
              value="CUSTOM"
              checked={radioValue === "CUSTOM"}
              onChange={() => this.handleChangeRadio("CUSTOM")}
            />{" "}
            Select categories:
          </label>

          {radioValue === "CUSTOM" && (
            <div className="ml-4">
              <DeviceCategoriesSelection
                inputValue={categories}
                options={valueSchema.arrayOptions}
                handleCategoriesChanged={this.handleCategoriesChanged}
              ></DeviceCategoriesSelection>

              <div className="text-red-600">{errorMsg}</div>
            </div>
          )}
        </div> */}
      </div>
    );
  }
}

class DeviceCategoriesSelection extends React.PureComponent {
  constructor(props) {
    super(props);

    const { inputValue } = this.props;

    this.state = {
      valuesMap: _.reduce(
        inputValue,
        (result, v) => {
          result[v] = true;
          return result;
        },
        {}
      ),
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(option) {
    let valuesMap = { ...this.state.valuesMap };
    valuesMap[option] = !this.state.valuesMap[option];

    const values = _.reduce(
      valuesMap,
      (result, v, k) => {
        if (v) {
          result.push(k);
        }
        return result;
      },
      []
    );

    this.setState({ valuesMap, values });
    this.props.handleCategoriesChanged(values);
  }

  render() {
    const { options } = this.props;
    const { valuesMap } = this.state;

    return (
      <>
        {options.map((option) => {
          return (
            <div key={option}>
              <label className="cursor-pointer">
                <Checkbox
                  isChecked={valuesMap[option] || false}
                  onChange={() => this.onChange(option)}
                ></Checkbox>
                <span className="ml-1 align-middle text-gray-600 hover:text-gray-700 font-semibold text-sm">
                  {option}
                </span>
              </label>
            </div>
          );
        })}
      </>
    );
  }
}

export default DeviceCategoriesControl;
