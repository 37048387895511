import React from "react";
import moment from "moment-timezone";
import _ from "lodash";
import { DashboardAPI } from "apis";
import LoadingUI from "../../common/LoadingUI";
import NumberFormat from "../../common/NumberFormat";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";

class RevenueCompareViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errMsg: null,

      isLoading: false,

      reports: null,
      // totalReport,
      // networkReports,

      compareKey: "MTD_M_0__VS__MTD_M_1",
      compareMetricKey: "earnableRev",
    };

    this.handleCompareKeyChanged = this.handleCompareKeyChanged.bind(this);
    this.handleCompareMetricChanged =
      this.handleCompareMetricChanged.bind(this);
  }

  async componentDidMount() {
    document.title = `Business Revenue | YB Observer`;

    this.setState({ isLoading: true });
    await this._queryAndUpdateUI();
    this.setState({ isLoading: false });
  }

  handleCompareKeyChanged(compareKey) {
    // totalReport, networkReports
    const { compareMap, compareMetricKey } = this.state;

    // totalReport = { from, to, diff };
    const totalReport = this._getTotalReport(
      compareMap,
      compareKey,
      compareMetricKey
    );
    const networkReports = this._getNetworkReports(
      compareMap,
      compareKey,
      compareMetricKey
    );

    const { m0, m1, m2 } = this.state;
    const headerFrom = `${
      compareKey === "MTD_M_0__VS__MTD_M_1" ? `MTD ${m1}` : m2
    }`;
    const headerTo = `${
      compareKey === "MTD_M_0__VS__MTD_M_1" ? `MTD ${m0}` : m1
    }`;
    // MTD_M_0__VS__MTD_M_1, TOTAL_M_1__VS__TOTAL_M_2
    this.setState({
      compareKey,
      headerFrom,
      headerTo,
      totalReport,
      networkReports,
    });
  }

  handleCompareMetricChanged(compareMetricKey) {
    // totalReport, networkReports
    const { compareMap, compareKey } = this.state;

    // totalReport = { from, to, diff };
    const totalReport = this._getTotalReport(
      compareMap,
      compareKey,
      compareMetricKey
    );
    const networkReports = this._getNetworkReports(
      compareMap,
      compareKey,
      compareMetricKey
    );

    this.setState({
      compareMetricKey,
      totalReport,
      networkReports,
    });
  }

  _getNetworkReports(compareMap, compareKey, compareMetricKey) {
    let networkReports = [];

    _.forIn(compareMap, (r, key) => {
      if (key === "total") return;

      const nr = { ...r.network };
      // console.log(r);

      // MTD_M_0__VS__MTD_M_1, TOTAL_M_1__VS__TOTAL_M_2
      if (compareKey === "MTD_M_0__VS__MTD_M_1") {
        nr.from = _.get(r, ["m_1", "mtd", compareMetricKey], 0);
        nr.to = _.get(r, ["m_0", "mtd", compareMetricKey], 0);
      }
      if (compareKey === "TOTAL_M_1__VS__TOTAL_M_2") {
        nr.from = _.get(r, ["m_2", "total", compareMetricKey], 0);
        nr.to = _.get(r, ["m_1", "total", compareMetricKey], 0);
      }
      nr.diff = nr.to - nr.from;

      networkReports.push(nr);
    });

    return networkReports;
  }

  _getTotalReport(compareMap, compareKey, compareMetricKey) {
    let r = compareMap.total;
    let totalReport = {};
    // MTD_M_0__VS__MTD_M_1, TOTAL_M_1__VS__TOTAL_M_2
    if (compareKey === "MTD_M_0__VS__MTD_M_1") {
      totalReport.from = _.get(r, ["m_1", "mtd", compareMetricKey], 0);
      totalReport.to = _.get(r, ["m_0", "mtd", compareMetricKey], 0);
    }
    if (compareKey === "TOTAL_M_1__VS__TOTAL_M_2") {
      totalReport.from = _.get(r, ["m_2", "total", compareMetricKey], 0);
      totalReport.to = _.get(r, ["m_1", "total", compareMetricKey], 0);
    }
    totalReport.diff = totalReport.to - totalReport.from;

    return totalReport;
  }

  async _queryAndUpdateUI() {
    try {
      await this._queryData();
    } catch (err) {
      console.log("Error querying business revenue report", err);
    }
  }

  async _queryData() {
    const numOfMonths = 3;
    const { data, updatedAt } =
      await DashboardAPI.getBusinessRevenueByNetworkReports({
        numOfMonths,
      });
    const { reports, networkInfo } = data;

    const compareMap = this._transformToCompareMap(reports, networkInfo);
    // console.log(compareMap);
    this.setState({ reports, compareMap });

    const { compareKey } = this.state;
    this.handleCompareKeyChanged(compareKey);
  }

  _transformToCompareMap(reports, networkInfo) {
    let compareMap = {};
    const reportsByNetwork = _.groupBy(reports, "networkId");

    const today = moment().utc();
    const yesterday = today.clone().subtract(1, "day");
    const yesterdayDate = yesterday.format("YYYY-MM-DD");
    const mtdDateOfMonth = _.parseInt(yesterdayDate.substr(-2)); // ex. 13

    const thisMonth = yesterday.format("YYYY-MM");
    const lastMonth = yesterday.clone().subtract(1, "month").format("YYYY-MM");
    const last2Month = yesterday.clone().subtract(2, "month").format("YYYY-MM");

    // show in ui
    this.setState({
      m0: thisMonth,
      m1: lastMonth,
      m2: last2Month,
    });

    // total (all networks)
    // m_0, m_1, m_2
    // mtd, total (whole month)

    const m_0_reports = this._filterReportsByMonth(reports, thisMonth);
    const m_1_reports = this._filterReportsByMonth(reports, lastMonth);
    const m_2_reports = this._filterReportsByMonth(reports, last2Month);
    compareMap.total = {
      m_0: this._calculateReportMetrics(m_0_reports, mtdDateOfMonth),
      m_1: this._calculateReportMetrics(m_1_reports, mtdDateOfMonth),
      m_2: this._calculateReportMetrics(m_2_reports, mtdDateOfMonth),
    };

    _.forIn(reportsByNetwork, (networkReports, networkId) => {
      if (
        networkInfo[networkId] &&
        networkInfo[networkId].networkStatus !== 0
      ) {
        return;
      }
      compareMap[networkId] = {
        network: networkInfo[networkId],
        m_0: this._calculateReportMetrics(
          this._filterReportsByNetworkId(m_0_reports, networkId),
          mtdDateOfMonth
        ),
        m_1: this._calculateReportMetrics(
          this._filterReportsByNetworkId(m_1_reports, networkId),
          mtdDateOfMonth
        ),
        m_2: this._calculateReportMetrics(
          this._filterReportsByNetworkId(m_2_reports, networkId),
          mtdDateOfMonth
        ),
      };
    });

    return compareMap;
  }

  _filterReportsByNetworkId(reports, networkId) {
    return _.filter(reports, (r) => r.networkId == networkId);
  }

  // monthFormatted: "2022-08"
  _filterReportsByMonth(reports, monthFormatted) {
    const filteredItems = _.filter(reports, (r) => {
      return _.startsWith(r.date, monthFormatted);
    });
    return filteredItems;
  }

  // mtd, total
  // mtdDateOfMonth = 17 (date of month)
  _calculateReportMetrics(reports, mtdDateOfMonth) {
    let total = {
      netIncrRev: 0,
      billableRev: 0,
      potentialRev: 0,
      earnableRev: 0,
    };
    let mtd = {
      netIncrRev: 0,
      billableRev: 0,
      potentialRev: 0,
      earnableRev: 0,
    };
    _.forEach(reports, (r) => {
      total.netIncrRev += r.netIncreasedRev;
      total.billableRev += r.billableRev;
      total.potentialRev += r.potentialRev;
      total.earnableRev += r.billableRev + r.potentialRev;

      const d = _.parseInt(r.date.substr(-2));
      if (d <= mtdDateOfMonth) {
        mtd.netIncrRev += r.netIncreasedRev;
        mtd.billableRev += r.billableRev;
        mtd.potentialRev += r.potentialRev;
        mtd.earnableRev += r.billableRev + r.potentialRev;
      }
    });

    return { total, mtd };
  }

  render() {
    const {
      isLoading,
      reports,

      totalReport,
      networkReports,

      compareKey,
      compareMetricKey,
      m0, // 2022-08
      m1,
      m2,

      headerFrom,
      headerTo,
    } = this.state;

    return (
      <>
        {this.state.errMsg && <div>{this.state.errMsg}</div>}

        <div className="bg-gray-200 px-12 py-8 min-h-full">
          <h2 className="text-3xl font-bold mb-2">Network Revenue</h2>
          <div className="p-4 bg-white mb-4 min-h-screen">
            {isLoading && <LoadingUI></LoadingUI>}

            {reports && (
              <>
                <div>
                  <div className="border py-2 px-2 mb-2 bg-white">
                    <div className="font-semibold text-sm mb-2">
                      Compare Date Range:{" "}
                      <button
                        type="button"
                        className={`px-2 py-1 mr-1 rounded ${
                          compareKey === "MTD_M_0__VS__MTD_M_1"
                            ? "bg-indigo-100 text-indigo-800"
                            : "text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
                        }`}
                        onClick={() =>
                          this.handleCompareKeyChanged("MTD_M_0__VS__MTD_M_1")
                        }
                      >
                        <span className="font-bold">MTD {m1}</span>
                        <span> vs </span>
                        <span className="font-bold">MTD {m0}</span>
                      </button>
                      <button
                        type="button"
                        className={`px-2 py-1 mr-1 rounded ${
                          compareKey === "TOTAL_M_1__VS__TOTAL_M_2"
                            ? "bg-indigo-100 text-indigo-800"
                            : "text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
                        }`}
                        onClick={() =>
                          this.handleCompareKeyChanged(
                            "TOTAL_M_1__VS__TOTAL_M_2"
                          )
                        }
                      >
                        <span className="font-bold">{m2}</span>
                        <span> vs </span>
                        <span className="font-bold">{m1}</span>
                      </button>
                    </div>

                    <div className="font-semibold text-sm mb-2">
                      Compare Metric:{" "}
                      <button
                        type="button"
                        className={`px-2 py-1 mr-1 font-semibold rounded ${
                          compareMetricKey === "netIncrRev"
                            ? "bg-indigo-100 text-indigo-800"
                            : "text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
                        }`}
                        onClick={() =>
                          this.handleCompareMetricChanged("netIncrRev")
                        }
                      >
                        Net Increased Rev
                      </button>
                      <button
                        type="button"
                        className={`px-2 py-1 mr-1 font-semibold rounded ${
                          compareMetricKey === "earnableRev"
                            ? "bg-indigo-100 text-indigo-800"
                            : "text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
                        }`}
                        onClick={() =>
                          this.handleCompareMetricChanged("earnableRev")
                        }
                      >
                        Earnable Rev (Potential + Billable)
                      </button>
                      <button
                        type="button"
                        className={`px-2 py-1 mr-1 font-semibold rounded ${
                          compareMetricKey === "billableRev"
                            ? "bg-indigo-100 text-indigo-800"
                            : "text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
                        }`}
                        onClick={() =>
                          this.handleCompareMetricChanged("billableRev")
                        }
                      >
                        Billable Rev
                      </button>
                    </div>
                  </div>
                </div>

                <RevTable
                  totalReport={totalReport}
                  networkReports={networkReports}
                  headerFrom={headerFrom}
                  headerTo={headerTo}
                ></RevTable>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

class RevTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      sortColumn: "diff", // "from", "to", "diff"
      sortOrder: "asc", // "asc", "desc"
    };

    this.handleSortChanged = this.handleSortChanged.bind(this);
  }

  handleSortChanged(sortColumn) {
    if (sortColumn === this.state.sortColumn) {
      this.setState({
        sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc",
      });
      return;
    }

    this.setState({
      sortColumn,
    });
  }

  render() {
    const { totalReport, networkReports, headerFrom, headerTo } = this.props;
    const { sortColumn, sortOrder } = this.state;
    const sortedReports = _.orderBy(networkReports, [sortColumn], [sortOrder]);
    // console.log(sortedReports, sortColumn, sortOrder);

    return (
      <>
        <table className="table-auto w-full">
          {/* <thead className="text-sm text-gray-800 bg-gray-300 align-bottom"> */}
          <thead className="bg-gray-200 text-blue-800 border">
            <tr>
              <th className="px-4 py-2 border" style={{ width: "54px" }}>
                #
              </th>
              <th className="px-4 py-2 border" style={{ width: "30%" }}>
                Running Networks ({networkReports && networkReports.length})
              </th>
              <th
                className="px-4 py-2 border hover:bg-gray-400 cursor-pointer"
                onClick={() => this.handleSortChanged("from")}
              >
                <div className="flex items-center justify-end gap-4">
                  {sortColumn === "from" && (
                    <span>
                      {sortOrder === "asc" ? (
                        <FiArrowDown></FiArrowDown>
                      ) : (
                        <FiArrowUp></FiArrowUp>
                      )}
                    </span>
                  )}
                  {headerFrom}
                </div>
              </th>
              <th
                className="px-4 py-2 border hover:bg-gray-400 cursor-pointer"
                onClick={() => this.handleSortChanged("to")}
              >
                <div className="flex items-center justify-end gap-4">
                  {sortColumn === "to" && (
                    <span>
                      {sortOrder === "asc" ? (
                        <FiArrowDown></FiArrowDown>
                      ) : (
                        <FiArrowUp></FiArrowUp>
                      )}
                    </span>
                  )}
                  {headerTo}
                </div>
              </th>
              <th
                className="px-4 py-2 border hover:bg-gray-400 cursor-pointer"
                onClick={() => this.handleSortChanged("diff")}
              >
                <div className="flex items-center justify-end gap-4">
                  {sortColumn === "diff" && (
                    <span>
                      {sortOrder === "asc" ? (
                        <FiArrowDown></FiArrowDown>
                      ) : (
                        <FiArrowUp></FiArrowUp>
                      )}
                    </span>
                  )}
                  Diff
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="text-gray-900">
            {totalReport && (
              <tr
                className={`${
                  false // highlightNetworkId === r.networkId
                    ? "bg-teal-100"
                    : "bg-gray-500"
                }`}
              >
                <td className="border px-4 py-2 text-center"></td>

                <td className="border px-4 py-2">
                  <span className="font-semibold">Total</span>
                </td>
                <td
                  className={`border px-4 py-2 text-right font-mono text-gray-800 ${
                    false // sortMetricKey === "mtdNetIncreasedRev"
                      ? "font-semibold"
                      : "font-semibold"
                  }`}
                >
                  <NumberFormat
                    value={totalReport.from}
                    format="$0,0"
                  ></NumberFormat>
                </td>

                <td
                  className={`border px-4 py-2 text-right font-mono text-gray-800 ${
                    false // sortMetricKey === "mtdNetIncreasedRev"
                      ? "font-semibold"
                      : "font-semibold"
                  }`}
                >
                  <NumberFormat
                    value={totalReport.to}
                    format="$0,0"
                  ></NumberFormat>
                </td>

                <td
                  className={`border px-4 py-2 text-right font-mono text-gray-800 ${
                    false // sortMetricKey === "mtdRevenue"
                      ? "font-semibold"
                      : "font-semibold"
                  }`}
                >
                  <NumberFormat
                    value={totalReport.diff}
                    format="$0,0"
                  ></NumberFormat>
                </td>
              </tr>
            )}

            {networkReports &&
              sortedReports.map((r, i) => {
                return (
                  <tr
                    key={r.networkId}
                    className={`${
                      false // highlightNetworkId === r.networkId
                        ? "bg-teal-100"
                        : "hover:bg-gray-100 "
                    }`}
                    // onClick={() => this.handleNetworkClicked(r.networkId)}
                  >
                    <td className="border px-4 py-2 text-center">{i + 1}</td>

                    <td className="border px-4 py-2">
                      <span>
                        <div>
                          <span className="pr-2">{r.networkId}</span>
                          <span className="font-semibold">{r.networkName}</span>
                        </div>
                      </span>
                      <div className="text-xs text-gray-600">
                        Publisher {r.publisherId} {r.publisherName}
                      </div>
                    </td>
                    <td
                      className={`border px-4 py-2 text-right font-mono text-gray-800 ${
                        false // sortMetricKey === "mtdNetIncreasedRev"
                          ? "font-semibold"
                          : "font-normal"
                      }`}
                    >
                      <NumberFormat value={r.from} format="$0,0"></NumberFormat>
                    </td>

                    <td
                      className={`border px-4 py-2 text-right font-mono text-gray-800 ${
                        false // sortMetricKey === "mtdNetIncreasedRev"
                          ? "font-semibold"
                          : "font-normal"
                      }`}
                    >
                      <NumberFormat value={r.to} format="$0,0"></NumberFormat>
                    </td>

                    <td
                      className={`border px-4 py-2 text-right font-mono text-gray-800 ${
                        false // sortMetricKey === "mtdRevenue"
                          ? "font-semibold"
                          : "font-normal"
                      }`}
                    >
                      <NumberFormat value={r.diff} format="$0,0"></NumberFormat>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </>
    );
  }
}

export default RevenueCompareViewer;
