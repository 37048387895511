import React from "react";
import _ from "lodash";

import { FiEdit } from "react-icons/fi";
import CSTIntegrationControlView from "./CSTIntegrationControlView";
import CSTIntegrationControlEditModal from "./CSTIntegrationControlEditModal";
import OverrideModal from "./OverrideModal";
import { getInventoryRegForOverrides } from "./InventoryRegistryHelper";

class CSTIntegrationControl extends React.Component {
  constructor(props) {
    super(props);

    const { integration, config } = this.props;

    this.viewRef = React.createRef();

    this.state = {
      isInvalid: false,
      isModalOpen: false,
      isOverrideModalOpen: false,
      currentRi: null,
      currentRiConfig: null,
      currentOverride: null,
      registryOptions: [],

      newIntConfig: _.cloneDeep(config[integration]),
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleOpenOverrideModal = this.handleOpenOverrideModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleEditClicked = this.handleEditClicked.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleEditOverride = this.handleEditOverride.bind(this);
    this.handleRemoveOverride = this.handleRemoveOverride.bind(this);
    this.handleIsOverrideInvalid = this.handleIsOverrideInvalid.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.config !== this.props.config) {
      const { integration, config } = this.props;
      this.setState({
        newIntConfig: _.cloneDeep(config[integration]),
      });
    }
  }

  handleOpenModal() {
    this.setState({ isModalOpen: true });
  }

  handleOpenOverrideModal({ ri, riConfig, currentOverride }) {
    const { integration } = this.props;
    const registryOptions = getInventoryRegForOverrides({
      integration,
      ri,
      riConfig,
    });
    this.setState({
      isOverrideModalOpen: true,
      currentRi: ri,
      currentRiConfig: riConfig,
      currentOverride,
      registryOptions,
    });
  }

  handleCloseModal() {
    this.setState({
      isModalOpen: false,
      isOverrideModalOpen: false,
      currentRiConfig: null,
      currentOverride: null,
      registryOptions: [],
    });
  }

  handleEditClicked() {
    const { integration, config } = this.props;
    this.setState({
      newIntConfig: _.cloneDeep(config[integration]),
    });
    this.handleOpenModal();
  }

  handleSave(intConfig) {
    this.setState({
      newIntConfig: intConfig,
    });
    const { integration, config } = this.props;
    config[integration] = intConfig;
    this.props.handleConfigChanged(config);
    this.handleCloseModal();
    this.viewRef.current.forceUpdate();
  }

  handleEditOverride({ ri, riConfig, currentOverride }) {
    this.handleOpenOverrideModal({ ri, riConfig, currentOverride });
  }

  handleRemoveOverride({ ri, registryId }) {
    let { newIntConfig } = this.state;
    let newOverrides = newIntConfig["registered_inventory"][ri].overrides;
    newOverrides = _.filter(newOverrides, (o) => {
      return o.registry_id !== registryId;
    });
    if (newOverrides.length > 0) {
      newIntConfig["registered_inventory"][ri].overrides = newOverrides;
    } else {
      newIntConfig["registered_inventory"][ri] = _.omit(
        newIntConfig["registered_inventory"][ri],
        "overrides"
      );
    }
    this.handleSave(newIntConfig);
  }

  handleIsOverrideInvalid({ ri, riConfig, override }) {
    const { integration } = this.props;
    const validRegistries = getInventoryRegForOverrides({
      integration,
      ri,
      riConfig,
    });
    const regIds = _.map(validRegistries, "registry_id");
    if (_.indexOf(regIds, override.registry_id + "") !== -1) {
      return false;
    }
    return true;
  }

  render() {
    const { integration, config, isReadonly, networkId, configGeneral } =
      this.props;
    const {
      registryOptions,
      isModalOpen,
      isOverrideModalOpen,
      currentRiConfig,
      currentRi,
      currentOverride,
      newIntConfig,
    } = this.state;

    const isEnabled = newIntConfig["enable_optimization"];

    return (
      <div>
        <div
          className={`border mt-4 p-4 text-xl font-bold ${
            isEnabled
              ? "border-blue-200 bg-blue-100 text-blue-800"
              : "border-gray-400 bg-gray-100 text-gray-800"
          }`}
        >
          <div className="flex items-center justify-between">
            {integration}
            {!isReadonly && (
              <button
                type="button"
                className="shadow rounded bg-white p-4 text-base hover:bg-gray-100"
                onClick={this.handleEditClicked}
              >
                <FiEdit></FiEdit>
              </button>
            )}
          </div>
        </div>

        <div className="border bg-white p-4">
          <CSTIntegrationControlView
            ref={this.viewRef}
            integration={integration}
            intConfig={newIntConfig}
            handleOpenOverride={this.handleOpenOverrideModal}
            handleEditOverride={this.handleEditOverride}
            handleRemoveOverride={this.handleRemoveOverride}
            handleIsOverrideInvalid={this.handleIsOverrideInvalid}
          ></CSTIntegrationControlView>
        </div>

        {isModalOpen && (
          <CSTIntegrationControlEditModal
            isModalOpen={isModalOpen}
            integration={integration}
            config={config}
            intConfig={newIntConfig}
            networkId={networkId}
            configGeneral={configGeneral}
            handleCloseModal={this.handleCloseModal}
            handleSave={this.handleSave}
          ></CSTIntegrationControlEditModal>
        )}

        {isOverrideModalOpen && (
          <OverrideModal
            isModalOpen={isOverrideModalOpen}
            integration={integration}
            intConfig={newIntConfig}
            ri={currentRi}
            riConfig={currentRiConfig}
            currentOverride={currentOverride}
            handleCloseModal={this.handleCloseModal}
            handleSave={this.handleSave}
            registryOptions={registryOptions}
          ></OverrideModal>
        )}
      </div>
    );
  }
}

export default CSTIntegrationControl;
