import React from "react";
import _ from "lodash";
import PopupWrapper from "../../common/PopupWrapper";

class PreviewInJsonPopover extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      triggerElement,
      json,
      place = "bottom",
      popoverWidth = "600px",
    } = this.props;

    let style = {
      // width: popoverWidth,
      maxHeight: "460px",
      // overflowY: "auto",
    };
    if (popoverWidth) {
      style.width = popoverWidth;
    }

    return (
      <PopupWrapper
        place={place}
        triggerType="click"
        // hideArrow={false}
        triggerElement={triggerElement}
        popupElement={
          <div className="shadow border p-4" style={style}>
            <textarea
              className="w-full cursor-not-allowed bg-gray-200"
              readOnly={true}
              defaultValue={json}
              rows="8"
              cols="50"
            ></textarea>
          </div>
        }
      ></PopupWrapper>
    );
  }
}

export default PreviewInJsonPopover;
