import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import DateRangePopover from "components/playground/home-dashboard-v2/DateRangePopover";
import PopupWrapper from "components/common/PopupWrapper";

const MIN_DATE = moment().subtract(2, "years").startOf("month").toDate();

class CustomDateRangeButton extends React.Component {
  constructor(props) {
    super(props);

    // const { startDate, endDate } = props;
    const today = new Date();

    this.state = {
      // startDate,
      // endDate,
      startDate: today,
      endDate: today,
    };

    this.child = React.createRef();
    this.handleClosePopover = this.handleClosePopover.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleApply = this.handleApply.bind(this);
  }

  handleClosePopover() {
    this.child.current.handleClosePopup();
  }

  handleCancel() {
    this.child.current.handleClosePopup();
    this.props.handleDateRangeCanceled();
  }

  handleApply({ startDate, endDate }) {
    this.props.handleDateRangeChanged({ startDate, endDate });
  }

  render() {
    const { startDate, endDate } = this.state;
    const { customButton } = this.props;

    return (
      <>
        <PopupWrapper
          ref={this.child}
          place="bottom"
          triggerType="click"
          hideArrow={false}
          onOpen={this.props.onOpen}
          triggerElement={customButton}
          popupElement={
            <DateRangePopover
              startDate={startDate}
              endDate={endDate}
              minDate={MIN_DATE}
              hidePresets={true}
              handleCancel={this.handleCancel}
              handleClosePopover={this.handleClosePopover}
              handleDateRangeFilterChanged={this.handleApply}
            ></DateRangePopover>
          }
        ></PopupWrapper>
      </>
    );
  }
}

export default CustomDateRangeButton;
