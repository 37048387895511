export const CHECK_STATUS = [
  "PASS",
  "FAIL",
  "NEED_MORE_DATA",
  "UNSTABLE",
  "WEAK_PASS",
  "WEAK_FAIL",
  "WEAK_UNSTABLE",
];

export const EVAL_KEYS = {
  raw_revenue_ratio: {
    // main: "raw_revenue_ratio",
    sec: "raw_eligible_revenue_twd", // TODO, change this to _trans (when currency is changable)
  },
  raw_revenue_ratio_filtered: {
    // main: "raw_revenue_ratio",
    sec: "raw_eligible_revenue_twd",
  },
};

export const KEY_FORMAT = {
  raw_revenue_ratio: "0,0.00%",
  raw_revenue_ratio_filtered: "0,0.00%",
  raw_eligible_revenue_twd: "$0,0a",
};
