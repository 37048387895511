import _ from "lodash";
import moment from "moment-timezone";

const PRICE_TYPE_BY_NAME = {
  FLOOR: 0,
  CPM: 1,
  LGO: 2,
  BRAND_SETTING: -1,
};

// used in custom upr setting (when importing gam upr)
export function transformGamUprToUprConfig({ gamUpr }) {
  let priceSection = {};
  const setForEverythingSetting = _.find(gamUpr.priceSection.priceSettings, {
    isSetForEverything: true,
  });
  if (setForEverythingSetting) {
    priceSection.priceType = setForEverythingSetting.priceType;
    priceSection.price = setForEverythingSetting.price;
    priceSection.currency = setForEverythingSetting.currency;
  } else {
    priceSection.priceType = PRICE_TYPE_BY_NAME.BRAND_SETTING;
    priceSection.currency = _.get(gamUpr.priceSection.priceSettings, [
      0,
      "currency",
    ]);
    priceSection.brandedPriceSettings = gamUpr.priceSection.priceSettings;
  }

  let targetingSection = {};
  if (gamUpr.targetingSection.inventory) {
    if (!_isSectionEmpty(gamUpr.targetingSection.inventory.unitIds)) {
      if (
        !_isSectionEmpty(gamUpr.targetingSection.inventory.unitIds.includes)
      ) {
        _.set(
          targetingSection,
          ["inventory", "unitIds"],
          gamUpr.targetingSection.inventory.unitIds.includes
        );
      }
      if (
        !_isSectionEmpty(gamUpr.targetingSection.inventory.unitIds.excludes)
      ) {
        _.set(
          targetingSection,
          ["inventory", "excludedUnitIds"],
          gamUpr.targetingSection.inventory.unitIds.excludes
        );
      }
    }
    if (!_isSectionEmpty(gamUpr.targetingSection.inventory.placementIds)) {
      _.set(
        targetingSection,
        ["inventory", "placementIds"],
        gamUpr.targetingSection.inventory.placementIds
      );
    }
    if (!_isSectionEmpty(gamUpr.targetingSection.inventory.mcm)) {
      _.set(
        targetingSection,
        ["inventory", "mcm"],
        gamUpr.targetingSection.inventory.mcm
      );
    }
  }
  if (!_isSectionEmpty(gamUpr.targetingSection.inventoryTypes)) {
    targetingSection.inventoryTypes = gamUpr.targetingSection.inventoryTypes;
  }
  if (!_isSectionEmpty(gamUpr.targetingSection.browser)) {
    targetingSection.browser = gamUpr.targetingSection.browser;
  }
  if (!_isSectionEmpty(gamUpr.targetingSection.country)) {
    targetingSection.country = gamUpr.targetingSection.country;
  }
  if (!_isSectionEmpty(gamUpr.targetingSection.customs)) {
    targetingSection.customs = gamUpr.targetingSection.customs;
  }
  if (!_isSectionEmpty(gamUpr.targetingSection.deviceCategory)) {
    targetingSection.deviceCategory = gamUpr.targetingSection.deviceCategory;
  }
  if (!_isSectionEmpty(gamUpr.targetingSection.mobileApplication)) {
    targetingSection.mobileApplication =
      gamUpr.targetingSection.mobileApplication;
  }
  if (!_isSectionEmpty(gamUpr.targetingSection.os)) {
    targetingSection.os = gamUpr.targetingSection.os;
  }

  return {
    ...gamUpr,
    priceSection,
    targetingSection,
    // props: _.get(resourceById, [gamUpr.externalId, "props"], null),
  };
}

function _isSectionEmpty(section) {
  if (!section || _.isEmpty(section)) {
    return true;
  } else if (_.has(section, "includes") && _.has(section, "excludes")) {
    return _.isEmpty(section.includes) && _.isEmpty(section.excludes);
  } else if (_.has(section, "includes") && _.isEmpty(section.includes)) {
    return true;
  } else if (_.has(section, "excludes") && _.isEmpty(section.excludes)) {
    return true;
  } else {
    return false;
  }
}
