import PropTypes from "prop-types";
import React from "react";

import valueToPoint from "./dataProcessing/valueToPoint";

// IMPORTANT: Removed type functionality, only support custom value
export default class SparklinesReferenceLine extends React.Component {
  static propTypes = {
    // type: PropTypes.oneOf(["max", "min", "mean", "avg", "median", "custom"]),
    value: PropTypes.number,
    style: PropTypes.object
  };

  static defaultProps = {
    // type: "mean",
    style: { stroke: "red", strokeOpacity: 0.75, strokeDasharray: "2, 2" }
  };

  render() {
    const { points, margin, style } = this.props;

    const y = valueToPoint(this.props).y;

    return (
      <line
        x1={points[0].x}
        y1={y + margin}
        x2={points[points.length - 1].x}
        y2={y + margin}
        style={style}
      />
    );
  }
}
