const weekdayAbbreviations = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export function getWeekdayAbbr(date) {
  return weekdayAbbreviations[new Date(date).getDay()];
}

export function isWeekend(date) {
  const wd = new Date(date).getDay();
  return wd === 0 || wd === 6;
}
