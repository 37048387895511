import React from "react";
import _ from "lodash";

const PreconditionPretty = (props) => {
  const { item } = props;
  const { property, op, value } = item;

  let opString = _getOperationString(op);
  let finalValue = value;
  if (property === "unit_state") {
    if (_.isArray(value)) {
      finalValue = (
        <span>
          {value.map((v, i) => {
            return (
              <span className="mr-1" key={i}>
                <LabelTextRed value={_getUnitStateString(v)}></LabelTextRed>
                <span>{i + 1 < value.length && ","}</span>
              </span>
            );
          })}
        </span>
      );
    } else {
      finalValue = (
        <LabelTextRed value={_getUnitStateString(value)}></LabelTextRed>
      );
    }
  } else if (property === "unit_status") {
    if (_.isArray(value)) {
      finalValue = (
        <span>
          {value.map((v, i) => {
            return (
              <span className="mr-1" key={i}>
                <LabelTextRed value={_getUnitStatusString(v)}></LabelTextRed>
              </span>
            );
          })}
        </span>
      );
    } else {
      finalValue = (
        <LabelTextRed value={_getUnitStatusString(value)}></LabelTextRed>
      );
    }
  }

  return (
    <span>
      <span>{property}</span>
      <span className="mx-1">{opString}</span>
      <span>{finalValue}</span>
    </span>
  );
};

const LabelTextRed = (props) => {
  return (
    <span className="font-mono bg-gray-200 rounded px-2 text-red-600 text-sm">
      {props.value}
    </span>
  );
};

function _getUnitStateString(state) {
  const stateMap = {
    0: "Unknown",
    1: "Wait for initialization",
    2: "Inspection",
    3: "Normal optimization",
    4: "Suspicious optimization",
    5: "Pause optimization",
    6: "Hibernation",
    7: "Debug",
    8: "Detention",
    9: "Negative optimization",
  };
  return stateMap[state];
}

function _getUnitStatusString(status) {
  const statusMap = {
    0: "Running",
    1: "Paused",
  };
  return statusMap[status];
}

function _getOperationString(op) {
  let opString = "";
  switch (op) {
    case "eq": {
      opString = "=";
      break;
    }
    case "neq": {
      opString = "!=";
      break;
    }
    case "in": {
      opString = "in";
      break;
    }
    case "gt": {
      opString = ">";
      break;
    }
    case "gte": {
      opString = ">=";
      break;
    }
    case "lt": {
      opString = "<";
      break;
    }
    case "lte": {
      opString = "<=";
      break;
    }
    case "con": {
      opString = "contains";
      break;
    }
    case "ncon": {
      opString = "does not contain";
      break;
    }
    default: {
      opString = "Unknown";
    }
  }
  return opString;
}

export default PreconditionPretty;
