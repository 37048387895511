import React from "react";
import _ from "lodash";
// import PopupWrapper from "../common/PopupWrapper";
import Popup from "reactjs-popup";

class OpsLogTable extends React.PureComponent {
  render() {
    const { items } = this.props;
    const thClass =
      "px-6 py-2 text-gray-600 text-left text-sm tracking-wider uppercase";
    const tdClass = "px-6 py-2";

    return (
      <>
        <table className="min-w-full shadow divide-y">
          <thead className="bg-gray-100">
            <tr>
              <th className={thClass}>DateTime</th>
              <th className={thClass}>Publisher</th>
              <th className={thClass}>User</th>
              <th className={thClass}>Operation</th>
              <th className={thClass + " text-center"}>Operation Detail</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y text-sm">
            {items.map((item) => {
              return (
                <tr key={item.key} className="hover:bg-gray-100">
                  <td className={tdClass}>
                    {item.dateTimeStr}
                    <div className="text-xs text-gray-700">
                      {item.dateTimeFromNow}
                    </div>
                  </td>
                  <td className={tdClass}>
                    {item.caller.pubId} - {item.caller.pubName}
                  </td>
                  <td className={tdClass}>
                    <span
                      className={`${
                        item.isIntowowUser ? "text-purple-700" : ""
                      }`}
                    >
                      {item.caller.email}
                    </span>
                  </td>

                  <td className={tdClass}>
                    <div className="flex items-center justify-between">
                      <div className="font-semibold text-gray-800">
                        {item.op.name}
                      </div>

                      <div
                        className={`px-2 text-xs font-semibold rounded ${
                          item.op.result === "ok"
                            ? "bg-green-200 text-green-900"
                            : "bg-red-200 text-red-900"
                        }`}
                      >
                        {item.op.result}
                      </div>
                    </div>
                  </td>

                  <td className={tdClass + " flex justify-center items-center"}>
                    <SeeInputArgsButton item={item}></SeeInputArgsButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }
}

class SeeInputArgsButton extends React.PureComponent {
  render() {
    const { item } = this.props;

    return (
      <PopupWrapper
        ref={this.child}
        place="left center"
        triggerType="click"
        hideArrow={false}
        triggerElement={
          <button
            type="button"
            className="p-2 text-blue-600 hover:text-blue-800 font-medium"
          >
            See Input Args
          </button>
        }
        popupElement={
          <div className="p-2 shadow-xl">
            <div className="flex items-center justify-between">
              <div className="font-semibold text-gray-800">{item.op.name}</div>

              <div
                className={`px-2 text-xs font-semibold rounded ${
                  item.op.result === "ok"
                    ? "bg-green-200 text-green-900"
                    : "bg-red-200 text-red-900"
                }`}
              >
                {item.op.result}
              </div>
            </div>
            <div
              className="bg-gray-300 rounded text-sm shadow-inner overflow-y-auto"
              style={{ maxHeight: "600px" }}
            >
              <pre className="p-2">{JSON.stringify(item.op.args, null, 4)}</pre>
            </div>
          </div>
        }
      ></PopupWrapper>
    );
  }
}

class PopupWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();
    this.handleOpenPopup = this.handleOpenPopup.bind(this);
    this.handleClosePopup = this.handleClosePopup.bind(this);
  }

  handleOpenPopup() {
    this.ref.current.open();
  }

  handleClosePopup() {
    this.ref.current.close();
  }

  render() {
    const { triggerElement, popupElement, place, triggerType } = this.props;
    const position = place || "right center";
    const type = triggerType || "click";
    const isOpen = this.props.isOpen || false;
    const arrowStyle = {
      strokeWidth: "2px",
      stroke: "#9a9595",

      strokeDasharray: "30px",
      strokeDashoffset: "-54px",
    };

    return (
      <Popup
        ref={this.ref}
        contentStyle={{
          width: "auto",
          zIndex: 9999999999,
          padding: 0,
          margin: 0,
          backgroundColor: "#FFFFFF",
          border: "1px solid #9a9595",
        }}
        arrowStyle={arrowStyle}
        trigger={triggerElement}
        position={position}
        open={isOpen}
        on={type}
      >
        {popupElement}
      </Popup>
    );
  }
}

export default OpsLogTable;
