import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import Highcharts from "highcharts";
import HighchartChart from "../common/HighchartChart";
// import { isWeekend } from "../../helpers/DateTransform";

class BizHighchartView extends React.PureComponent {
  render() {
    const options = _createChartOptions(
      this.props.reports,
      this.props.handleFilterByDateRange
    );

    return <HighchartChart options={options}></HighchartChart>;
  }
}

function _createChartOptions(reports, handleFilterByDateRange) {
  const seriesData = _createSeriesData(reports);

  const options = {
    title: {
      text: "",
    },
    chart: {
      zoomType: "x",
      ..._createSelectionEvents(handleFilterByDateRange),
    },
    tooltip: {
      shared: true,
      ..._getTooltipConfig(),
    },
    xAxis: {
      ..._getXAxisConfigDateTime(),
      ..._createPlotBands(reports),
      ..._createPlotLines(reports),
    },
    yAxis: {
      title: {
        text: "TWD $",
      },
      // min: 0,
    },
    series: seriesData,
  };

  return options;
}

function _createSeriesData(reports) {
  const netIncRevSeries = _.map(reports, (r) => {
    return {
      x: new Date(r.date).getTime(),
      y: r.netIncreasedRev,
      // custom
      goalReached: r.dailyGoal ? r.goalReachedPercentage : null,
    };
  });

  const dailyGoalSeries = _.map(reports, (r) => {
    return {
      x: new Date(r.date).getTime(),
      y: r.dailyGoal || null,
    };
  });

  return [
    {
      name: "Net Increased Rev",
      data: netIncRevSeries,
      type: "column",
    },
    {
      name: "Revenue Goal",
      type: "line",
      // step: true,
      marker: {
        enabled: false,
      },
      data: dailyGoalSeries,
    },
  ];
}

function _getTooltipConfig() {
  return {
    backgroundColor: "rgba(255,255,255,1)",
    formatter() {
      const headFormat = `<div style="font-size:12px; font-weight: bold; text-align: center;">${moment(
        this.x
      ).format("YYYY-MM-DD ddd")}</div>`;

      let hasDailyGoal = false;
      let mainIndicatorLabel = "";
      let mainIndicatorPointFormat = "";

      let netIncRevLabel = "";
      let netIncRevPointFormat = "";

      let dailyGoalLabel = "";
      let dailyGoalPointFormat = "";

      for (let point of this.points) {
        switch (point.series.name) {
          case "Net Increased Rev": {
            if (point.point.goalReached) {
              hasDailyGoal = true;
              const title = "Goal Reached";
              mainIndicatorLabel = `${title}: `;
              mainIndicatorPointFormat = `<b>${point.point.goalReached} %</b>`;
            }

            netIncRevLabel = `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: `;
            const p = Highcharts.numberFormat(point.y, 0);
            netIncRevPointFormat = `<b>$ ${p}</b>`;

            break;
          }

          case "Revenue Goal": {
            dailyGoalLabel = `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: `;
            const p = Highcharts.numberFormat(point.y, 0);
            dailyGoalPointFormat = `<b>$ ${p}</b>`;
            break;
          }

          default: {
            // nothing
          }
        }
      }

      const format = `
        ${headFormat}
        <table style="font-size: 12px;">
        ${
          hasDailyGoal
            ? `
            <tr style="font-size: larger; border-bottom: 1px solid lightgray;">
              <td style="padding-top: 4px;font-weight: bold; text-align: right;padding-right: 4px;">
                ${mainIndicatorLabel}
              </td>
              <td style="padding-top: 4px; text-align: right;">
                ${mainIndicatorPointFormat}
              </td>
            </tr>
          `
            : ""
        }
          <tr>
            <td style="padding-top: 4px;font-weight: bold; text-align: right;padding-right: 4px;">
              ${netIncRevLabel}
            </td>
            <td style="padding-top: 4px; text-align: right;">
              ${netIncRevPointFormat}
            </td>
          </tr>
          <tr style="">
            <td style="padding-top: 4px;font-weight: bold; text-align: right;padding-right: 4px;">
              ${dailyGoalLabel}
            </td>
            <td style="padding-top: 4px; text-align: right;">
              ${dailyGoalPointFormat}
            </td>
          </tr>
        </table>
        `;

      return format;
    },
    shared: true,
    useHTML: true,
    crosshairs: true,
  };
}

function _getXAxisConfigDateTime() {
  return {
    type: "datetime",
    dateTimeLabelFormats: {
      day: "%m/%e",
    },
    // labels: {
    //   formatter: function () {
    //     const m = moment(this.value);
    //     const d = m.format("DD");
    //     // const d = m.format("MM/DD");
    //     const wd = m.format("ddd");
    //     const label = `${d} <br/> ${wd}`;
    //     if (d === "01" || this.isFirst) {
    //       return `${d} <br/> ${wd} <br/> <span style="color: #3182ce;	letter-spacing: 0.05em;">${m
    //         .format("MMM")
    //         .toUpperCase()}</span>`;
    //     }
    //     if (isWeekend(this.value)) {
    //       return `<span style="font-weight: bold; color: black;">${label}</span>`;
    //     }
    //     return label;
    //   },
    // },
    tickInterval: 24 * 60 * 60 * 1000, // 1 day
    crosshair: true,
  };
}

function _createPlotBands(reports) {
  const yesterdayData = _.find(reports, "isYesterday");
  const todayData = _.find(reports, "isToday");
  if (!yesterdayData || !todayData) {
    return {};
  }

  // To highlight yesterday
  const halfDay = 43200 * 1000;
  const from = new Date(yesterdayData.date).getTime() - halfDay;
  const to = new Date(todayData.date).getTime() - halfDay;

  // highlight months
  // 1. get months in reports
  // 2. highlight

  return {
    plotBands: [
      {
        from,
        to,
      },
    ],
  };
}

function _createPlotLines(reports) {
  const halfDay = 43200 * 1000;
  const dates = _.reduce(
    reports,
    (result, r) => {
      const date = new Date(r.date);
      if (date.getDate() === 1) {
        result.push({
          monthString: date.toLocaleString("en", { month: "short" }),
          value: date.getTime() - halfDay,
        });
      }
      return result;
    },
    []
  );

  const plotLines = _.map(dates, (d) => {
    return {
      color: "#fbd38d",
      width: 1,
      value: d.value,
      label: {
        text: d.monthString,
        verticalAlign: "top",
        textAlign: "right",
        y: 24, // lower label from the top
        style: {
          color: "#f29c09",
          fontWeight: "bold",
        },
      },
    };
  });

  return {
    plotLines,
  };
}

function _createSelectionEvents(handleFilterByDateRange) {
  return {
    events: {
      selection: function (event) {
        // console.log(event);

        if (event.resetSelection) {
          handleFilterByDateRange({ startTime: null, endTime: null });
          return;
        }

        // log the min and max of the primary, datetime x-axis
        // console.log(
        //   Highcharts.dateFormat("%Y-%m-%d %H:%M:%S", event.xAxis[0].min),
        //   Highcharts.dateFormat("%Y-%m-%d %H:%M:%S", event.xAxis[0].max)
        // );

        const startTime = new Date(event.xAxis[0].min);
        const endTime = new Date(event.xAxis[0].max);

        handleFilterByDateRange({ startTime, endTime });
      },
    },
  };
}

export default BizHighchartView;
