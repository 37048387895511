import React from "react";
import _ from "lodash";

import { notify } from "react-notify-toast";
import { PublisherAPI } from "apis";
import PublisherInfoHeader from "../../network-features/PublisherInfoHeader";
import BillingSettingForm from "./BillingSettingForm";

class PublisherBillingSettingViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,

      setting: null,
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });

    try {
      const { publisherId } = this.props.match.params;
      if (!publisherId) {
        throw new Error("Missing Publisher ID");
      }
      document.title = `Publisher ${publisherId} Billing Setting | YB Observer`;

      const r = await PublisherAPI.getBillingSetting({ publisherId });
      const { billingSettingV2Detail, isExisted } = r;
      console.log("billingSettingV2Detail", r);

      // transform
      const setting = {
        ...billingSettingV2Detail,
        geoCountryId: _.get(billingSettingV2Detail, [
          "geoCountry",
          "countryId",
        ]),
        billingProfileId: _.get(billingSettingV2Detail, [
          "activeBillingProfile",
          "profileId",
        ]),
      };

      this.setState({
        publisherId,
        setting: setting || {},

        isLoading: false,
        errMsg: null,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        errMsg: err.toString(),
        setting: null,
      });
    }
  }

  render() {
    const { errMsg, isLoading, setting, publisherId } = this.state;

    return (
      <div>
        {publisherId && (
          <PublisherInfoHeader pubId={publisherId}></PublisherInfoHeader>
        )}

        <div className="min-h-screen bg-white px-12 py-8">
          <div className="flex items-center justify-between">
            <PageTitle title="Publisher Billing Setting"></PageTitle>
          </div>
          <div className="mt-4">
            {setting && (
              <>
                <BillingSettingForm
                  setting={setting}
                  publisherId={publisherId}
                ></BillingSettingForm>
              </>
            )}

            {errMsg && (
              <div className="rounded mb-4 bg-red-100 p-4 font-semibold text-red-700">
                {errMsg}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function PageTitle({ title }) {
  return <div className="text-3xl font-extrabold">{title}</div>;
}

export default PublisherBillingSettingViewer;
