import _ from "lodash";

/**
 * A predefined hint in frontend should look like this:
 * {
 *    type: DC_SMARTPHONE,
 *    config: {...}
 * }
 *
 * Then when saving, convert it to:
 * {
 *    targetings: {"device_category":{"includes":["30000"]}},
 *    autopilot_config: {...}
 * }
 */

const DEVICE_TARGETING = {
  Desktop: "30000",
  Smartphone: "30001",
  Tablet: "30002",
};

const TYPE_TARGETING_MAP = {
  DC_DESKTOP: {
    id: "DC_DESKTOP",
    name: "Desktop",
    targetings: {
      device_category: {
        includes: [DEVICE_TARGETING["Desktop"]],
      },
    },
  },

  DC_TABLET: {
    id: "DC_TABLET",
    name: "Tablet",
    targetings: {
      device_category: {
        includes: [DEVICE_TARGETING["Tablet"]],
      },
    },
  },

  DC_SMARTPHONE: {
    id: "DC_SMARTPHONE",
    name: "Smartphone",
    targetings: {
      device_category: {
        includes: [DEVICE_TARGETING["Smartphone"]],
      },
    },
  },
};
const PREDEFINED_TYPES = _.keys(TYPE_TARGETING_MAP);

export function getTargetingsByHintType(hintType) {
  return _.get(TYPE_TARGETING_MAP, [hintType, "targetings"]);
}

export function getHintTypeFromUserHint(userHint) {
  const intention = _.get(userHint, "targetings.intention");
  if (intention) {
    return intention;
  } else {
    const h = _.find(TYPE_TARGETING_MAP, (type) => {
      return _.isEqual(userHint.targetings, type.targetings);
    });
    return _.get(h, "id");
  }
}

function _tranformHintConfig(config) {
  // fill in default config for hints!
  // Important! only the Prophet section!
  if (
    _.has(config, "prophet.benchmark_abuse_sizes") &&
    config.prophet.benchmark_abuse_sizes.length > 0
  ) {
    config.prophet.benchmark_abuse_sizes = _.first(
      config.prophet.benchmark_abuse_sizes
    );
  }

  return config;
}

const HINT_TYPE = {
  PREDEFINED: "PREDEFINED",
  CUSTOM: "CUSTOM",
  UNDEFINED: "UNDEFINED",
};

function _getPredefinedUserHints() {
  return _.map(PREDEFINED_TYPES, (pid) => {
    const p = TYPE_TARGETING_MAP[pid];
    return {
      id: p.id,
      type: HINT_TYPE.PREDEFINED,
      title: p.name,
      targetings: p.targetings,
      targetingsDetail: p.targetings,
      config: null,
      hasConfig: false,
    };
  });
}

function _getCustomHintsFromActiveSegments(activeSegments) {
  const intentionSegments = _.filter(activeSegments, "intention");
  if (intentionSegments.length === 0) {
    return [];
  }

  return _.map(intentionSegments, (seg) => {
    return {
      id: seg.intention,
      type: HINT_TYPE.CUSTOM,
      title: seg.intention,
      targetingsDetail: seg.targeting,
      targetings: { intention: seg.intention },
      config: null,
      hasConfig: false,
    };
  });
}

function _getCustomHintsFromConfig({ config }) {
  const segments = _.get(
    config,
    ["segmentation", "custom_segmentation", "segments"],
    []
  );
  // TODO: should we filter deprecated segments?
  const activeSegments = _.filter(segments, (s) => !s.deprecated);
  return _.map(activeSegments, (seg) => {
    return {
      id: seg.segment_key,
      type: HINT_TYPE.CUSTOM,
      title: seg.segment_key,
      targetingsDetail: seg.targeting,
      targetings: { intention: seg.segment_key },
      config: null,
      hasConfig: false,
    };
  });
}

export function getAllHints({ userHints, config, activeSegments }) {
  const predefinedHints = _getPredefinedUserHints();
  const customHints = _getCustomHintsFromConfig({ config });
  const segmentIntentionHints =
    _getCustomHintsFromActiveSegments(activeSegments);

  // transform
  const currentHints = _transformCurrentHints({ userHints });
  const currentHintsById = _.keyBy(currentHints, "id");

  let allHints = [];
  const gatheredHints = [
    ...predefinedHints,
    ...customHints,
    ...currentHints,
    ...segmentIntentionHints,
  ];
  _.forEach(gatheredHints, (hint) => {
    const currentHint = currentHintsById[hint.id];
    if (currentHint) {
      allHints.push(currentHint);
    } else {
      allHints.push(hint);
    }
  });
  allHints = _.uniqBy(allHints, "id");

  // transform config
  allHints = _.map(allHints, (h) => {
    h.config = _tranformHintConfig(h.config || {});
    return h;
  });

  return allHints;
}

export function createNewCustomHint() {
  const customIntention = "";
  return {
    id: customIntention,
    type: HINT_TYPE.CUSTOM,
    title: customIntention,
    // targetingsDetail: seg.targeting,
    targetings: { intention: customIntention },
    config: _tranformHintConfig({}),
    hasConfig: false,
  };
}

// current user hints
function _transformCurrentHints({ userHints }) {
  const hints = _.map(userHints, (userHint, i) => {
    // { targetings, autopilot_config}
    const intention = _.get(userHint, ["targetings", "intention"]);
    if (intention) {
      return {
        id: intention,
        type: HINT_TYPE.CUSTOM,
        title: intention,
        targetings: userHint.targetings,
        config: userHint.autopilot_config,
        hasConfig: true,
      };
    } else {
      const predefinedHintId = getHintTypeFromUserHint(userHint);
      if (predefinedHintId) {
        const h = TYPE_TARGETING_MAP[predefinedHintId];
        return {
          id: h.id,
          type: HINT_TYPE.PREDEFINED,
          title: h.name,
          targetings: userHint.targetings,
          config: userHint.autopilot_config,
          hasConfig: true,
        };
      } else {
        // unknown hint?
        return {
          id: `UNKNOWN_${i}`,
          type: HINT_TYPE.UNDEFINED,
          title: `Unknown ${i}`,
          targetings: userHint.targetings,
          config: userHint.autopilot_config,
          hasConfig: true,
        };
      }
    }
  });
  return hints;
}

export function resolveHintDefaultConfig({ unitConfig, defaultConfig }) {
  const uc = _.cloneDeep(unitConfig);
  const dc = _.cloneDeep(defaultConfig);
  return _.defaultsDeep(uc, dc);
}
