import React from "react";
import LoadingUI from "./LoadingUI";
import UnitStatus from "./UnitStatus";
import UnitMode from "./UnitMode";
import SegmentModeIndicator from "./SegmentModeIndicator";
import PopupWrapper from "./PopupWrapper";
import SegmentBasicTable from "./SegmentBasicTable";
import ClickToCopyWrapper from "./ClickToCopyWrapper";
import ReactTooltip from "react-tooltip";
import NumberFormat from "./NumberFormat";
import DateTimeFormatter from "./DateTimeFormatter";
import { UnitAPI } from "apis";
import TimeOfDayProgressBar from "./TimeOfDayProgressBar";
import CurrencyFormat from "./CurrencyFormat";

class UnitDetailHeader extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,

      unitInfo: null,
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      if (!this.props.unitId) {
        throw "Invalid unitId";
      }
      const unitInfo = await UnitAPI.getUnitDetail({
        unitId: this.props.unitId,
      });
      console.log(unitInfo);

      this.props.getUnitInfo(unitInfo);
      this.setState({ unitInfo, isLoading: false, errMsg: null });
    } catch (err) {
      this.setState({
        errMsg: typeof err === "object" ? err.toString() : err,
        isLoading: false,
      });
    }
  }

  render() {
    const { unitInfo, isLoading, errMsg } = this.state;
    // const { unit, config, segments, updatedAt } = this.props;
    const unit = unitInfo;
    const segments = unitInfo && unitInfo.segments;
    const config = unitInfo && unitInfo.autoPilotBrief;

    return (
      // sticky top-0
      <div className="z-50 bg-white shadow-md">
        {isLoading && <LoadingUI iconOnly={true}></LoadingUI>}

        {errMsg && <div className="text-red-800">{errMsg}</div>}

        {unitInfo && (
          <div>
            <div className="border-b px-4 py-2">
              <div className="mt-1 py-1">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div>
                      <div className="font-semibold text-blue-800">
                        <span className="mr-1 text-blue-700">
                          {unit.networkId}
                        </span>
                        {unit.networkName}
                      </div>
                      <div
                        className="text-xs leading-none text-gray-600"
                        style={{ maxWidth: "200px" }}
                      >
                        YS: {unit.yieldSetId} {unit.yieldSetName}
                      </div>
                    </div>

                    <div className="border-l ml-3 pl-3">
                      <div className="flex">
                        <div className="flex flex-col">
                          <div className="flex text-lg leading-none">
                            <div className="mr-2 font-semibold">
                              {unit.gamUnitId}
                            </div>
                            <div className="font-black">
                              {unit.name}
                              {unit.deletedAt && (
                                <span className="rounded mx-1 bg-red-300 px-1 text-xs font-bold uppercase text-red-800">
                                  Blacklist
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="mt-1 flex items-center text-xs leading-none text-gray-600">
                            <div
                              className="mr-4 flex items-center"
                              title="Ext Unit ID"
                            >
                              {unit.extUnitId}
                              <ClickToCopyWrapper
                                copyText={unit.extUnitId}
                              ></ClickToCopyWrapper>
                            </div>

                            <div
                              className="mr-4 flex items-center justify-start"
                              title="Ext Unit Path"
                            >
                              <span
                                className="whitespace-no-wrap overflow-x-auto"
                                style={{ maxWidth: "400px" }}
                              >
                                {unit.extUnitPath}
                              </span>
                              <ClickToCopyWrapper
                                copyText={unit.extUnitPath}
                              ></ClickToCopyWrapper>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div>
                      <div className="flex items-center justify-end gap-4 text-sm">
                        <UnitStatus unit={unit}></UnitStatus>
                        <UnitMode unit={unit}></UnitMode>
                      </div>
                    </div>

                    <div className="flex items-end">
                      <AdPodsSection
                        enableAdPods={config.enable_ad_pods}
                      ></AdPodsSection>

                      <InspectionSection
                        unitId={unit.gamUnitId}
                        forceInspection={config.force_inspection}
                      ></InspectionSection>

                      <DebugModeSection
                        enableDispatcherDebug={
                          config.enable_dispatcher_debugging
                        }
                      ></DebugModeSection>

                      <ForerunnerConfigSection
                        value={config.minimum_daily_request_volume}
                      ></ForerunnerConfigSection>

                      <BmSection
                        unitId={unit.gamUnitId}
                        bpm={config.benchmark_performance_multiplier}
                        enableAbuse={config.enable_benchmark_tfcd_abuse}
                        bmAbuseSizes={config.benchmark_abuse_sizes}
                      ></BmSection>

                      <MaxTrafficRatioSection
                        minTrafficRatio={config.minimum_traffic_ratio}
                        maxTrafficRatio={config.maximum_traffic_ratio}
                      ></MaxTrafficRatioSection>

                      <UnitChangeTimeSection
                        unit={unit}
                      ></UnitChangeTimeSection>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-2 flex justify-between">
                <div className="flex gap-4">
                  <div style={{ minWidth: "170px" }}>
                    <span className="block text-xs font-semibold uppercase leading-none text-gray-600">
                      Request Types ({unit.requestTypes.length})
                    </span>

                    <RequestTypesSection
                      requestTypes={unit.requestTypes}
                    ></RequestTypesSection>
                  </div>

                  <div style={{ minWidth: "170px" }}>
                    <span className="block text-xs font-semibold uppercase leading-none text-gray-600">
                      Demand Types ({unit.demandTypes.length})
                    </span>
                    <div
                      className="whitespace-no-wrap my-1 mr-1 flex overflow-x-auto"
                      style={{
                        maxWidth: "400px",
                      }}
                    >
                      <DemandTypesSection
                        demandTypes={unit.demandTypes}
                      ></DemandTypesSection>
                    </div>
                  </div>
                </div>

                <div className="text-sm">
                  <span className="block text-xs font-semibold uppercase leading-none text-gray-600">
                    Segments ({segments ? segments.length : 0})
                  </span>
                  {segments && segments.length > 0 && (
                    <PopupWrapper
                      place="bottom center"
                      triggerType="hover"
                      hideArrow={true}
                      triggerElement={
                        <div
                          className="flex overflow-x-auto"
                          style={{
                            maxWidth: "400px",
                          }}
                        >
                          <div className="rounded hover:shadow flex bg-gray-300">
                            {segments.map((s) => (
                              <div
                                key={s.segmentId}
                                className="rounded mx-1 flex items-center gap-1 p-1 font-mono"
                              >
                                <SegmentModeIndicator
                                  mode={s.mode}
                                ></SegmentModeIndicator>
                                <span
                                  className={`leading-none ${
                                    s.srConfig
                                      ? "border-b-2 border-purple-400"
                                      : ""
                                  }`}
                                >
                                  {s.segmentId}
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      }
                      popupElement={
                        <div style={{ maxWidth: "800px" }}>
                          <SegmentBasicTable
                            segments={segments}
                          ></SegmentBasicTable>
                        </div>
                      }
                    ></PopupWrapper>
                  )}
                </div>

                <div className="text-sm">
                  <div className="text-right">
                    CostCPM:{" "}
                    <span
                      style={{ fontSize: "smaller" }}
                      className="text-gray-700"
                    >
                      $
                    </span>
                    {unitInfo.costCPM}{" "}
                    <span
                      style={{ fontSize: "small" }}
                      className="font-mono text-gray-700"
                    >
                      USD
                    </span>
                  </div>

                  {unitInfo.currency !== "USD" && (
                    <div className="text-right text-gray-700">
                      ~{" "}
                      <span
                        style={{ fontSize: "smaller" }}
                        className="text-gray-700"
                      >
                        <CurrencyFormat
                          currency={unitInfo.currency}
                        ></CurrencyFormat>
                      </span>
                      {unitInfo.costCPMInCurrency}{" "}
                      <span
                        style={{ fontSize: "small" }}
                        className="font-mono text-gray-700"
                      >
                        {unitInfo.currency}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="flex items-center justify-end px-4 font-semibold">
              <div className="flex items-center">
                <div className="mr-2">{unit.timezone.replace("_", " ")}</div>
                <div className="text-xs" style={{ marginTop: "2px" }}>
                  <TimeOfDayProgressBar
                    timezone={unit.timezone}
                  ></TimeOfDayProgressBar>
                </div>
              </div>
              <span className="mx-2 font-hairline text-gray-500">|</span>

              <CurrencyFormat currency={unitInfo.currency}></CurrencyFormat>
              {unit.currency}
            </div>

            <div className="pb-1">
              <div className="flex items-center gap-2">
                <div className="mt-1 inline-block pl-4 text-xs font-semibold uppercase leading-none text-gray-600">
                  Tags ({unit.tags ? unit.tags.length : 0})
                </div>
                <div className="whitespace-no-wrap overflow-scroll">
                  {unit.tags &&
                    unit.tags.map((tag) => {
                      return (
                        <span
                          key={tag}
                          className="rounded mr-2 bg-orange-200 px-2 text-xs text-gray-700"
                        >
                          {tag}
                        </span>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

class UnitChangeTimeSection extends React.PureComponent {
  render() {
    const { unit } = this.props;

    return (
      <>
        <div
          data-tip
          data-for={`unit-changed-at-${unit.gamUnitId}`}
          className="whitespace-no-wrap border-l px-1 text-xs text-gray-700"
        >
          <>
            Changes made{" "}
            <span className="text-gray-900">
              <DateTimeFormatter
                fromNowOnly={true}
                datetime={unit.lastChangedAt}
              ></DateTimeFormatter>
            </span>
          </>
        </div>

        <ReactTooltip
          id={`unit-changed-at-${unit.gamUnitId}`}
          type="dark"
          effect="solid"
          // place="bottom"
        >
          <table>
            <tbody>
              <tr>
                <td className="pr-2 text-right">Created At</td>
                <td>
                  <b>
                    <DateTimeFormatter
                      format="YYYY-MM-DD HH:mm"
                      datetime={unit.createdAt}
                    ></DateTimeFormatter>
                  </b>
                </td>
              </tr>

              <tr>
                <td className="pr-2 text-right">Updated At</td>
                <td>
                  <b>
                    <DateTimeFormatter
                      format="YYYY-MM-DD HH:mm"
                      datetime={unit.updatedAt}
                    ></DateTimeFormatter>
                  </b>
                </td>
              </tr>

              <tr>
                <td className="pr-2 text-right">
                  Auto Pilot Config Updated At
                </td>
                <td>
                  <b>
                    <DateTimeFormatter
                      format="YYYY-MM-DD HH:mm"
                      datetime={unit.configUpdatedAt}
                    ></DateTimeFormatter>
                  </b>
                </td>
              </tr>
            </tbody>
          </table>
        </ReactTooltip>
      </>
    );
  }
}

class InspectionSection extends React.PureComponent {
  render() {
    const { forceInspection } = this.props;
    if (forceInspection) {
      return (
        <div className="border-l px-1 text-xs" title="Force Inspection">
          {forceInspection && (
            <span className="rounded bg-orange-400 px-2 font-bold text-orange-100">
              INSP
            </span>
          )}
        </div>
      );
    } else {
      return "";
    }
  }
}

class AdPodsSection extends React.PureComponent {
  render() {
    const { enableAdPods } = this.props;

    return (
      <>
        {enableAdPods ? (
          <div className="border-l px-1 text-xs">
            <span className="font-bold text-purple-600">ADPODS</span>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
}

class DebugModeSection extends React.PureComponent {
  render() {
    const { enableDispatcherDebug } = this.props;
    return enableDispatcherDebug ? (
      <div className="border-l px-1 text-xs">
        <span className="rounded bg-pink-500 px-2 font-bold text-pink-100">
          DEBUG
        </span>
      </div>
    ) : (
      ""
    );
  }
}

class MaxTrafficRatioSection extends React.PureComponent {
  render() {
    const { maxTrafficRatio, minTrafficRatio } = this.props;
    return (
      <div className="border-l px-1 text-xs">
        Traffic:{" "}
        <span className="text-gray-600">
          <NumberFormat value={minTrafficRatio} format="0,0%"></NumberFormat>
        </span>
        <span className="px-1">-</span>
        <span className="text-gray-600">
          <NumberFormat value={maxTrafficRatio} format="0,0%"></NumberFormat>
        </span>
      </div>
    );
  }
}

class ForerunnerConfigSection extends React.PureComponent {
  render() {
    const { value } = this.props;
    return (
      <div className="border-l px-1 text-xs">
        <span
          className="text-gray-700"
          data-tip
          data-for={`forerunner-config-tip`}
        >
          Min Req: <NumberFormat value={value} format="0,0"></NumberFormat>
        </span>
        <ReactTooltip
          id={`forerunner-config-tip`}
          type="dark"
          effect="solid"
          place="bottom"
        >
          Forerunner's Minimum Daily Request Volume
        </ReactTooltip>
      </div>
    );
  }
}

class BmSection extends React.PureComponent {
  render() {
    const { unitId, bpm, enableAbuse, bmAbuseSizes } = this.props;
    /*
    bmAbuseSizes=[
      [
        "fluid",
        [
          1,
          1
        ]
      ]
    ]
    */
    let abuseSizesString = "None";
    if (bmAbuseSizes) {
      let sizes = bmAbuseSizes[0];
      abuseSizesString = sizes
        .map((s) => {
          if (typeof s === "string") {
            return s;
          } else {
            return s.join("x");
          }
        })
        .join(", ");
    }

    return (
      <div
        className="border-l px-1 text-xs"
        // title="Benchmark Performance Multiplier"
      >
        BPM:{" "}
        {bpm < 1 ? (
          <>
            <span
              className="font-bold text-orange-600"
              data-tip
              data-for={`bpm-label-${unitId}`}
            >
              <NumberFormat value={bpm} format="0,0%"></NumberFormat>
            </span>
            <ReactTooltip
              id={`bpm-label-${unitId}`}
              type="dark"
              effect="solid"
              place="top"
            >
              Enable benchmark tfcd abuse:{" "}
              <b>{enableAbuse ? "true" : "false"}</b>
              <br></br>
              Benchmark Abuse Sizes: <b>{abuseSizesString}</b>
            </ReactTooltip>
          </>
        ) : (
          <span className="text-gray-600">
            <NumberFormat value={bpm} format="0,0%"></NumberFormat>
          </span>
        )}
      </div>
    );
  }
}

class RequestTypesSection extends React.PureComponent {
  render() {
    const { requestTypes } = this.props;
    return (
      <div className="my-1 flex">
        {requestTypes.map((d) => {
          if (d === "") return "";
          return (
            <div
              key={d}
              className="rounded mr-2 bg-gray-300 px-2 text-xs text-gray-700"
              title={d}
            >
              {d === "Google Publisher Tag" ? "GPT" : d}
            </div>
          );
        })}
      </div>
    );
  }
}
class DemandTypesSection extends React.PureComponent {
  render() {
    const { demandTypes } = this.props;

    return (
      <>
        {demandTypes.map((d) => {
          return (
            <div
              key={d}
              className="rounded mr-2 bg-gray-300 px-2 text-xs text-gray-700"
            >
              {d}
            </div>
          );
        })}
      </>
    );
  }
}
export default UnitDetailHeader;
