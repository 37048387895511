import moment from "moment-timezone";
import Highcharts from "highcharts"; // to get highchart functions

export const oColor = "#00b72a"; // green
export const bColor = "#434348"; // dark gray
export const xColor = "#e651f7"; //

const colors = [
  // "#10ff00",
  // "#15fa00",
  // "#2ede00",
  // "#36d600",
  // "#5fab00",
  // "#828500",
  // "#8f7800",
  // "#af5600",
  // "#cf3300",
  // "#ff0000",

  // bright colors (sequencial blue-red)
  // "#0045ff",
  // "#586efc",
  // "#7c95f8",
  // "#9abcf3",
  // "#bce2eb",
  // "#ffdccb",
  // "#ffb8b5",
  // "#ff919d",
  // "#ff6281",
  // "#ff0059",

  // dark colors (sequencial blue-red)
  "#00429d",
  "#3e67ae",
  "#618fbf",
  "#85b7ce",
  "#b1dfdb",
  "#ffcab9",
  "#fd9291",
  "#e75d6f",
  "#c52a52",
  "#93003a",

  /// all blue
  // "#0000b3",
  // "#0010d9",
  // "#0020ff",
  // "#0040ff",
  // "#0060ff",
  // "#0080ff",
  // "#009fff",
  // "#00bfff",
  // "#00ffff",

  // rainbow
  // "#ea5545",
  // "#f46a9b",
  // "#ef9b20",
  // "#edbf33",
  // "#ede15b",
  // "#bdcf32",
  // "#87bc45",
  // "#27aeef",
  // "#b33dc6",

  // seems good
  // "#e60049",
  // "#0bb4ff",
  // "#90ED7D",
  // "#e6d800",
  // "#9b19f5",
  // "#ffa300",
  // "#dc0ab4",
  // "#b3d4ff",
  // "#00bfa0",
  // "#ff80ff",
  //
];

export function getGroupTypeSeriesOptions(gt) {
  let dashStyle = "solid";
  let lineWidth = 1;
  let marker = { enabled: false };
  if (gt === "o") {
    dashStyle = "dash";
    lineWidth = 1;
    marker = {
      enabled: true,
      fillColor: "#FFFFFF",
      lineWidth: 2,
      lineColor: null, // inherit from series,
      radius: 3,
      symbol: "circle",
    };
  } else if (gt === "b") {
    dashStyle = "longdash";
  }

  let options = {
    dashStyle,
    lineWidth,
    marker,
  };
  return options;
}

// numOfDetectors: 5 or 10
export function getGroupTypeColor(groupType, numOfDetectors = 10) {
  const isTenLayers = numOfDetectors === 10; // otherwise 5

  if (groupType === "o") return oColor;
  if (groupType === "b") return bColor;
  if (groupType === "x") return xColor;

  if (groupType === "d1") return colors[0]; // 0
  if (groupType === "d2") return colors[isTenLayers ? 1 : 2]; // 2
  if (groupType === "d3") return colors[isTenLayers ? 2 : 5]; // 4,5
  if (groupType === "d4") return colors[isTenLayers ? 3 : 7]; // 7
  if (groupType === "d5") return colors[isTenLayers ? 4 : 9]; // 9

  if (groupType === "d6") return colors[5];
  if (groupType === "d7") return colors[6];
  if (groupType === "d8") return colors[7];
  if (groupType === "d9") return colors[8];
  if (groupType === "d10") return colors[9];
}

// Sort By Y Value!
export function tooltipFormatter({
  chart,
  timezone,
  isSortByYValue = false,
  tooltipHeading = "",
  valuePrefix = "",
  valuePostfix = "",
  valueDecimalPoint = 0,
}) {
  let sortedPoints = chart.points;
  if (isSortByYValue) {
    sortedPoints = chart.points.sort((pointA, pointB) => pointB.y - pointA.y);
  }

  return `
    <div style="text-align: center; font-size: 12px; margin-bottom: 2px;">
      ${moment.tz(chart.x, timezone).format("YYYY-MM-DD HH:mm")}
    </div>
    
    <div>
      <table style="margin: 0 auto;">
        <tbody>
          <tr>
            <td colspan="3" style="text-align: right;">
              ${tooltipHeading}
            </td>
          </tr>
          ${sortedPoints
            .map((point) => {
              const isOB =
                point.series.name === "b" ||
                point.series.name === "o" ||
                point.series.name === "x";
              return `
                <tr>
                  <td style="padding: 1px;">
                    <span style="color: ${point.color};">\u25CF</span>
                  </td>
                  <td style="padding: 1px; text-align: right; padding-right: 3px; ${
                    isOB && `font-weight: 600;`
                  }">
                    <span style="${isOB && "border-bottom: 1px solid black;"}">
                      ${point.series.name}
                    </span>:
                  </td>
                  <td style="padding: 1px; text-align: right; font-weight: 600;">
                    <span style="${isOB && "border-bottom: 1px solid black;"}">
                      <span style="font-weight: 400; color: 'light-gray';">${
                        point.point.prefix || valuePrefix
                      }</span>
                      ${Highcharts.numberFormat(point.y, valueDecimalPoint)}
                      <span style="font-weight: 400; color: 'light-gray';">${
                        point.point.postfix || valuePostfix
                      }</span>
                    </span>
                  </td>
                </tr>
              `;
            })
            .join("")}
        </tbody>
      </table>
    </div>
  `;
}
