import React from "react";
import TrendsRevenueSummary from "./TrendsRevenueSummary";
import RpmOverview from "./RpmOverview";
import TrafficLossChartSection from "./TrafficLossChartSection";
import RequestChartSection from "./RequestChartSection";

class OverviewView extends React.PureComponent {
  render() {
    const {
      // reports
      revenue,
      experimentParams,
      rpms,
      trafficLoss,

      networkTimezone,
    } = this.props;

    return (
      <div className="py-8 px-4 bg-gray-300 min-h-screen">
        <RpmOverview rpms={rpms}></RpmOverview>

        <RevenueSummarySection
          revenue={revenue}
          experimentParams={experimentParams}
          networkTimezone={networkTimezone}
        ></RevenueSummarySection>

        <TrafficLossSection
          trafficLoss={trafficLoss}
          experimentParams={experimentParams}
          networkTimezone={networkTimezone}
        ></TrafficLossSection>

        <RequestSection
          trafficLoss={trafficLoss}
          experimentParams={experimentParams}
          networkTimezone={networkTimezone}
        ></RequestSection>
      </div>
    );
  }
}

class RequestSection extends React.PureComponent {
  render() {
    return (
      <div className="mb-8">
        <div className="font-semibold text-lg text-gray-700 mb-2">
          Requests:
        </div>

        <div className="bg-white rounded shadow px-4 py-6">
          <RequestChartSection
            reports={this.props.trafficLoss}
            experimentParams={this.props.experimentParams}
            networkTimezone={this.props.networkTimezone}
          ></RequestChartSection>
        </div>
      </div>
    );
  }
}

class TrafficLossSection extends React.PureComponent {
  render() {
    return (
      <div className="mb-8">
        <div className="font-semibold text-lg text-gray-700 mb-2">
          Traffic Loss:
        </div>

        <div className="bg-white rounded shadow px-4 py-6">
          <TrafficLossChartSection
            reports={this.props.trafficLoss}
            experimentParams={this.props.experimentParams}
            networkTimezone={this.props.networkTimezone}
          ></TrafficLossChartSection>
        </div>
      </div>
    );
  }
}

class RevenueSummarySection extends React.PureComponent {
  render() {
    return (
      <div className="mb-8">
        <div className="font-semibold text-lg text-gray-700 mb-2">
          Revenue Summary:
        </div>

        <div className="bg-white rounded shadow px-4 py-6">
          <TrendsRevenueSummary
            revenue={this.props.revenue}
            experimentParams={this.props.experimentParams}
            networkTimezone={this.props.networkTimezone}
          ></TrendsRevenueSummary>
        </div>
      </div>
    );
  }
}

export default OverviewView;
