export const INVENTORY_REGISTRY = [
  {
    id: "1",
    label: "gpt + out_of_page + smartphone + ios",
  },
  {
    id: "2",
    label: "gpt + out_of_page + smartphone + android",
  },
  {
    id: "3",
    label: "gpt + out_of_page + tablet + ios",
  },
  {
    id: "4",
    label: "gpt + out_of_page + tablet + android",
  },
  {
    id: "5",
    label: "gpt + anchor + smartphone + ios",
  },
  {
    id: "6",
    label: "gpt + anchor + smartphone + android",
  },
  {
    id: "7",
    label: "gpt + anchor + tablet + ios",
  },
  {
    id: "8",
    label: "gpt + anchor + tablet + android",
  },
  {
    id: "9",
    label: "gpt + banner + smartphone + ios",
  },
  {
    id: "10",
    label: "gpt + banner + smartphone + android",
  },
  {
    id: "11",
    label: "gpt + banner + tablet + ios",
  },
  {
    id: "12",
    label: "gpt + banner + tablet + android",
  },
  {
    id: "13",
    label: "gpt + interstitial + smartphone + ios",
  },
  {
    id: "14",
    label: "gpt + interstitial + smartphone + android",
  },
  {
    id: "15",
    label: "gpt + interstitial + tablet + ios",
  },
  {
    id: "16",
    label: "gpt + interstitial + tablet + android",
  },
  {
    id: "17",
    label: "gpt + rewarded + smartphone + ios",
  },
  {
    id: "18",
    label: "gpt + rewarded + smartphone + android",
  },
  {
    id: "19",
    label: "gpt + rewarded + tablet + ios",
  },
  {
    id: "20",
    label: "gpt + rewarded + tablet + android",
  },
  {
    id: "21",
    label: "video + instream_audio + smartphone + ios",
  },
  {
    id: "22",
    label: "video + instream_audio + smartphone + android",
  },
  {
    id: "23",
    label: "video + instream_audio + tablet + ios",
  },
  {
    id: "24",
    label: "video + instream_audio + tablet + android",
  },
  {
    id: "25",
    label: "video + instream_video + smartphone + ios",
  },
  {
    id: "26",
    label: "video + instream_video + smartphone + android",
  },
  {
    id: "27",
    label: "video + instream_video + tablet + ios",
  },
  {
    id: "28",
    label: "video + instream_video + tablet + android",
  },
  {
    id: "29",
    label: "mobile + app_open + smartphone + ios",
  },
  {
    id: "30",
    label: "mobile + app_open + smartphone + android",
  },
  {
    id: "31",
    label: "mobile + app_open + tablet + ios",
  },
  {
    id: "32",
    label: "mobile + app_open + tablet + android",
  },
  {
    id: "33",
    label: "mobile + banner + smartphone + ios",
  },
  {
    id: "34",
    label: "mobile + banner + smartphone + android",
  },
  {
    id: "35",
    label: "mobile + banner + tablet + ios",
  },
  {
    id: "36",
    label: "mobile + banner + tablet + android",
  },
  {
    id: "37",
    label: "mobile + interstitial + smartphone + ios",
  },
  {
    id: "38",
    label: "mobile + interstitial + smartphone + android",
  },
  {
    id: "39",
    label: "mobile + interstitial + tablet + ios",
  },
  {
    id: "40",
    label: "mobile + interstitial + tablet + android",
  },
  {
    id: "41",
    label: "mobile + rewarded + smartphone + ios",
  },
  {
    id: "42",
    label: "mobile + rewarded + smartphone + android",
  },
  {
    id: "43",
    label: "mobile + rewarded + tablet + ios",
  },
  {
    id: "44",
    label: "mobile + rewarded + tablet + android",
  },
  {
    id: "45",
    label: "gpt + out_of_page + desktop + chrome",
  },
  {
    id: "46",
    label: "gpt + out_of_page + desktop + safari",
  },
  {
    id: "47",
    label: "gpt + out_of_page + desktop + edge",
  },
  {
    id: "48",
    label: "gpt + out_of_page + desktop + others",
  },
  {
    id: "49",
    label: "gpt + out_of_page + connecttv + chrome",
  },
  {
    id: "50",
    label: "gpt + out_of_page + connecttv + safari",
  },
  {
    id: "51",
    label: "gpt + out_of_page + connecttv + edge",
  },
  {
    id: "52",
    label: "gpt + out_of_page + connecttv + others",
  },
  {
    id: "53",
    label: "gpt + anchor + desktop + chrome",
  },
  {
    id: "54",
    label: "gpt + anchor + desktop + safari",
  },
  {
    id: "55",
    label: "gpt + anchor + desktop + edge",
  },
  {
    id: "56",
    label: "gpt + anchor + desktop + others",
  },
  {
    id: "57",
    label: "gpt + anchor + connecttv + chrome",
  },
  {
    id: "58",
    label: "gpt + anchor + connecttv + safari",
  },
  {
    id: "59",
    label: "gpt + anchor + connecttv + edge",
  },
  {
    id: "60",
    label: "gpt + anchor + connecttv + others",
  },
  {
    id: "61",
    label: "gpt + banner + desktop + chrome",
  },
  {
    id: "62",
    label: "gpt + banner + desktop + safari",
  },
  {
    id: "63",
    label: "gpt + banner + desktop + edge",
  },
  {
    id: "64",
    label: "gpt + banner + desktop + others",
  },
  {
    id: "65",
    label: "gpt + banner + connecttv + chrome",
  },
  {
    id: "66",
    label: "gpt + banner + connecttv + safari",
  },
  {
    id: "67",
    label: "gpt + banner + connecttv + edge",
  },
  {
    id: "68",
    label: "gpt + banner + connecttv + others",
  },
  {
    id: "69",
    label: "gpt + interstitial + desktop + chrome",
  },
  {
    id: "70",
    label: "gpt + interstitial + desktop + safari",
  },
  {
    id: "71",
    label: "gpt + interstitial + desktop + edge",
  },
  {
    id: "72",
    label: "gpt + interstitial + desktop + others",
  },
  {
    id: "73",
    label: "gpt + interstitial + connecttv + chrome",
  },
  {
    id: "74",
    label: "gpt + interstitial + connecttv + safari",
  },
  {
    id: "75",
    label: "gpt + interstitial + connecttv + edge",
  },
  {
    id: "76",
    label: "gpt + interstitial + connecttv + others",
  },
  {
    id: "77",
    label: "gpt + rewarded + desktop + chrome",
  },
  {
    id: "78",
    label: "gpt + rewarded + desktop + safari",
  },
  {
    id: "79",
    label: "gpt + rewarded + desktop + edge",
  },
  {
    id: "80",
    label: "gpt + rewarded + desktop + others",
  },
  {
    id: "81",
    label: "gpt + rewarded + connecttv + chrome",
  },
  {
    id: "82",
    label: "gpt + rewarded + connecttv + safari",
  },
  {
    id: "83",
    label: "gpt + rewarded + connecttv + edge",
  },
  {
    id: "84",
    label: "gpt + rewarded + connecttv + others",
  },
  {
    id: "85",
    label: "video + instream_audio + desktop + chrome",
  },
  {
    id: "86",
    label: "video + instream_audio + desktop + safari",
  },
  {
    id: "87",
    label: "video + instream_audio + desktop + edge",
  },
  {
    id: "88",
    label: "video + instream_audio + desktop + others",
  },
  {
    id: "89",
    label: "video + instream_audio + connecttv + chrome",
  },
  {
    id: "90",
    label: "video + instream_audio + connecttv + safari",
  },
  {
    id: "91",
    label: "video + instream_audio + connecttv + edge",
  },
  {
    id: "92",
    label: "video + instream_audio + connecttv + others",
  },
  {
    id: "93",
    label: "video + instream_video + desktop + chrome",
  },
  {
    id: "94",
    label: "video + instream_video + desktop + safari",
  },
  {
    id: "95",
    label: "video + instream_video + desktop + edge",
  },
  {
    id: "96",
    label: "video + instream_video + desktop + others",
  },
  {
    id: "97",
    label: "video + instream_video + connecttv + chrome",
  },
  {
    id: "98",
    label: "video + instream_video + connecttv + safari",
  },
  {
    id: "99",
    label: "video + instream_video + connecttv + edge",
  },
  {
    id: "100",
    label: "video + instream_video + connecttv + others",
  },
  {
    id: "101",
    label: "gpt + smartphone + ios",
  },
  {
    id: "102",
    label: "gpt + smartphone + android",
  },
  {
    id: "103",
    label: "gpt + tablet + ios",
  },
  {
    id: "104",
    label: "gpt + tablet + android",
  },
  {
    id: "105",
    label: "video + smartphone + ios",
  },
  {
    id: "106",
    label: "video + smartphone + android",
  },
  {
    id: "107",
    label: "video + tablet + ios",
  },
  {
    id: "108",
    label: "video + tablet + android",
  },
  {
    id: "109",
    label: "mobile + smartphone + ios",
  },
  {
    id: "110",
    label: "mobile + smartphone + android",
  },
  {
    id: "111",
    label: "mobile + tablet + ios",
  },
  {
    id: "112",
    label: "mobile + tablet + android",
  },
  {
    id: "113",
    label: "gpt + desktop + chrome",
  },
  {
    id: "114",
    label: "gpt + desktop + safari",
  },
  {
    id: "115",
    label: "gpt + desktop + edge",
  },
  {
    id: "116",
    label: "gpt + desktop + others",
  },
  {
    id: "117",
    label: "gpt + connecttv + chrome",
  },
  {
    id: "118",
    label: "gpt + connecttv + safari",
  },
  {
    id: "119",
    label: "gpt + connecttv + edge",
  },
  {
    id: "120",
    label: "gpt + connecttv + others",
  },
  {
    id: "121",
    label: "video + desktop + chrome",
  },
  {
    id: "122",
    label: "video + desktop + safari",
  },
  {
    id: "123",
    label: "video + desktop + edge",
  },
  {
    id: "124",
    label: "video + desktop + others",
  },
  {
    id: "125",
    label: "video + connecttv + chrome",
  },
  {
    id: "126",
    label: "video + connecttv + safari",
  },
  {
    id: "127",
    label: "video + connecttv + edge",
  },
  {
    id: "128",
    label: "video + connecttv + others",
  },
  {
    id: "129",
    label: "gpt + others + smartphone + ios",
  },
  {
    id: "130",
    label: "gpt + others + smartphone + android",
  },
  {
    id: "131",
    label: "gpt + others + tablet + ios",
  },
  {
    id: "132",
    label: "gpt + others + tablet + android",
  },
  {
    id: "133",
    label: "video + others + smartphone + ios",
  },
  {
    id: "134",
    label: "video + others + smartphone + android",
  },
  {
    id: "135",
    label: "video + others + tablet + ios",
  },
  {
    id: "136",
    label: "video + others + tablet + android",
  },
  {
    id: "137",
    label: "mobile + others + smartphone + ios",
  },
  {
    id: "138",
    label: "mobile + others + smartphone + android",
  },
  {
    id: "139",
    label: "mobile + others + tablet + ios",
  },
  {
    id: "140",
    label: "mobile + others + tablet + android",
  },
  {
    id: "141",
    label: "gpt + others + desktop + chrome",
  },
  {
    id: "142",
    label: "gpt + others + desktop + safari",
  },
  {
    id: "143",
    label: "gpt + others + desktop + edge",
  },
  {
    id: "144",
    label: "gpt + others + desktop + others",
  },
  {
    id: "145",
    label: "gpt + others + connecttv + chrome",
  },
  {
    id: "146",
    label: "gpt + others + connecttv + safari",
  },
  {
    id: "147",
    label: "gpt + others + connecttv + edge",
  },
  {
    id: "148",
    label: "gpt + others + connecttv + others",
  },
  {
    id: "149",
    label: "video + others + desktop + chrome",
  },
  {
    id: "150",
    label: "video + others + desktop + safari",
  },
  {
    id: "151",
    label: "video + others + desktop + edge",
  },
  {
    id: "152",
    label: "video + others + desktop + others",
  },
  {
    id: "153",
    label: "video + others + connecttv + chrome",
  },
  {
    id: "154",
    label: "video + others + connecttv + safari",
  },
  {
    id: "155",
    label: "video + others + connecttv + edge",
  },
  {
    id: "156",
    label: "video + others + connecttv + others",
  },
  {
    id: "157",
    label: "amp + banner + smartphone + ios",
  },
  {
    id: "158",
    label: "amp + banner + smartphone + android",
  },
  {
    id: "159",
    label: "amp + banner + tablet + ios",
  },
  {
    id: "160",
    label: "amp + banner + tablet + android",
  },
  {
    id: "161",
    label: "amp + banner + desktop + chrome",
  },
  {
    id: "162",
    label: "amp + banner + desktop + safari",
  },
  {
    id: "163",
    label: "amp + banner + desktop + edge",
  },
  {
    id: "164",
    label: "amp + banner + desktop + others",
  },
  {
    id: "165",
    label: "amp + banner + connecttv + chrome",
  },
  {
    id: "166",
    label: "amp + banner + connecttv + safari",
  },
  {
    id: "167",
    label: "amp + banner + connecttv + edge",
  },
  {
    id: "168",
    label: "amp + banner + connecttv + others",
  },
  {
    id: "173",
    label: "mobile + native_advance + smartphone + ios",
  },
  {
    id: "174",
    label: "mobile + native_advance + smartphone + android",
  },
  {
    id: "175",
    label: "mobile + native_advance + tablet + ios",
  },
  {
    id: "176",
    label: "mobile + native_advance + tablet + android",
  },
  {
    id: "177",
    label: "mobile + app_open + connecttv + ios",
  },
  {
    id: "178",
    label: "mobile + app_open + connecttv + android",
  },
  {
    id: "179",
    label: "mobile + banner + connecttv + ios",
  },
  {
    id: "180",
    label: "mobile + banner + connecttv + android",
  },
  {
    id: "181",
    label: "mobile + interstitial + connecttv + ios",
  },
  {
    id: "182",
    label: "mobile + interstitial + connecttv + android",
  },
  {
    id: "183",
    label: "mobile + native_advance + connecttv + ios",
  },
  {
    id: "184",
    label: "mobile + native_advance + connecttv + android",
  },
  {
    id: "185",
    label: "mobile + rewarded + connecttv + ios",
  },
  {
    id: "186",
    label: "mobile + rewarded + connecttv + android",
  },
  {
    id: "187",
    label: "mobile + others + connecttv + ios",
  },
  {
    id: "188",
    label: "mobile + others + connecttv + android",
  },
];
