import React from "react";
import _ from "lodash";

import { NetworkAPI } from "apis";
import LoadingUI from "../../../common/LoadingUI";
import NetworkInfoHeader from "../../common/NetworkInfoHeader";
import DateTimeFormatter from "components/common/DateTimeFormatter";
import BuildDetailsModal from "./BuildDetailsModal";
import { buttonConfirmClass } from "helpers/StyleClass";
import CreateBuildModal from "./CreateBuildModal";
import SyncResultModal from "./SyncResultModal";
import TempBuildModal from "./TempBuildModal";
import ClickToCopyWrapper from "components/common/ClickToCopyWrapper";
import { MdEdit } from "react-icons/md";
import NotesModal from "./NotesModal";
import UpdateStatusModal from "./UpdateStatusModal";
import BuildReleaseStatusLabel from "./BuildReleaseStatusLabel";
import CompareBuildModal from "./CompareBuildModal";
import ReactTooltip from "react-tooltip";
import { FiBookmark } from "react-icons/fi";

class CodeSnippetViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,

      items: null,
      buildsByPackage: null,
      packageBuilds: null,
      buildComparison: null,

      isDetailModalOpen: false,
      currentBuildId: null,
      currentBuild: null,

      isAddModalOpen: false,

      isSyncModalOpen: false,
      isSyncing: false,
      syncResult: null,

      isTempBuildModalOpen: false,

      isNotesModalOpen: false,
      isStatusModalOpen: false,
      isCompareModalOpen: false,
    };

    this.handleOpenDetails = this.handleOpenDetails.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);

    this.handleOpenAddModal = this.handleOpenAddModal.bind(this);
    this.handleCreateBuild = this.handleCreateBuild.bind(this);

    this.handleOpenTempBuildModal = this.handleOpenTempBuildModal.bind(this);
    this.handleCreateTempBuild = this.handleCreateTempBuild.bind(this);

    this.handleOpenNotesModal = this.handleOpenNotesModal.bind(this);
    this.handleOpenStatusModal = this.handleOpenStatusModal.bind(this);
    this.handleOpenCompareModal = this.handleOpenCompareModal.bind(this);

    this.handleRefreshAfterBuild = this.handleRefreshAfterBuild.bind(this);
    this.handleSyncCloudflare = this.handleSyncCloudflare.bind(this);

    this.handleTabChanged = this.handleTabChanged.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      const { networkId } = this.props.match.params;
      if (!networkId) {
        throw new Error("Missing Network ID");
      }
      const networkInfo = await NetworkAPI.getNetworkInfo({ networkId });
      if (!networkInfo) {
        throw new Error("Invalid Network");
      }

      document.title = `${networkId} Network Code Snippet | YB Observer`;

      await this.queryBuilds(networkId);

      this.setState({
        networkId,
        networkInfo,
        isLoading: false,
        errMsg: null,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        errMsg: err.toString(),
      });
    }
  }

  handleTabChanged(tab) {
    this.setState({ currentTab: tab });
  }

  async createBuildComparison({
    networkId,
    packageName,
    baseBuildId,
    comparisonBuildId,
  }) {
    const r = await NetworkAPI.createBuildComparison({
      networkId,
      packageName,
      baseBuildId,
      comparisonBuildId,
    });
  }

  async queryBuilds(networkId) {
    const { buildHistory } = await NetworkAPI.getCodeSnippetBuilds({
      networkId,
    });

    const { packages } = await NetworkAPI.getBuildPackages();
    const packagesByName = _.keyBy(packages, "name");

    const buildsByPackage = _.groupBy(buildHistory, "packageName");
    const PACKAGE_INDEX = {
      web_js: 1,
      mobile_android: 2,
      mobile_ios: 3,
    };
    let packageBuilds = [];
    _.forIn(buildsByPackage, (builds, p) => {
      packageBuilds.push({
        packageName: p,
        builds,
        index: PACKAGE_INDEX[p],
      });
    });
    packageBuilds = _.orderBy(packageBuilds, ["index"], ["asc"]);

    const buildsOverview = _.reduce(
      buildsByPackage,
      (result, builds, packageName) => {
        const current = _.maxBy(builds, "packageVersion");
        const latest = _.maxBy(packagesByName[packageName].versions, "version");
        result.push({
          packageName,
          count: builds.length,
          currentVersion: current.packageVersion,
          currentCreatedAt: current.createdAt,
          current,
          latest,
          hasNewVersion: current.packageVersion !== latest.version,
        });
        return result;
      },
      []
    );

    this.setState({
      items: _.orderBy(buildHistory, ["createdAt"], ["desc"]),
      packageBuilds,
      buildsByPackage,
      buildsOverview,

      currentTab:
        this.state.currentTab || _.get(_.first(packageBuilds), "packageName"),
    });
  }

  handleModalClose() {
    this.setState({
      isDetailModalOpen: false,
      currentBuild: null,
      currentBuildId: null,
      isAddModalOpen: false,
      isSyncModalOpen: false,
      syncResult: null,
      isTempBuildModalOpen: false,

      isNotesModalOpen: false,
      isStatusModalOpen: false,
      isCompareModalOpen: false,
    });
  }

  handleOpenDetails(item) {
    this.setState({
      isDetailModalOpen: true,
      currentBuildId: item.buildId,
      currentBuild: item,
    });
  }

  handleOpenAddModal() {
    this.setState({
      isAddModalOpen: true,
    });
  }

  handleOpenTempBuildModal() {
    this.setState({
      isTempBuildModalOpen: true,
    });
  }

  handleOpenNotesModal(item) {
    this.setState({
      isNotesModalOpen: true,
      currentBuildId: item.buildId,
      currentBuild: item,
    });
  }

  handleOpenStatusModal(item) {
    this.setState({
      isStatusModalOpen: true,
      currentBuildId: item.buildId,
      currentBuild: item,
    });
  }

  handleOpenCompareModal(item) {
    this.setState({
      isCompareModalOpen: true,
      currentBuildId: item.buildId,
      currentBuild: item,
    });
  }

  async handleCreateBuild({ packageName, packageVersion }) {
    const { networkId } = this.state;
    return await NetworkAPI.createNewBuild({
      networkId,
      packageName,
      packageVersion,
    });
  }

  async handleCreateTempBuild({
    packageName,
    packageVersion,
    overriddenInput,
  }) {
    const { networkId } = this.state;
    return await NetworkAPI.createTempBuild({
      networkId,
      packageName,
      packageVersion,
      overriddenInput,
    });
  }

  async handleRefreshAfterBuild() {
    this.handleModalClose();
    await this.queryBuilds(this.state.networkId);
  }

  async handleSyncCloudflare() {
    const userConfirm = window.confirm(`Sync Cloudflare AMP RTC?`);
    if (!userConfirm) return;

    this.setState({
      isSyncModalOpen: true,
      isSyncing: true,
    });
    try {
      const { networkId } = this.state;
      const results = await NetworkAPI.syncCloudFlareAmpRTC({ networkId });

      this.setState({
        isSyncing: false,
        syncResult: JSON.stringify(results, null, 2),
      });
    } catch (err) {
      this.setState({
        isSyncing: false,
        syncResult: JSON.stringify(err, null, 2),
      });
    }
  }

  render() {
    const {
      networkId,
      networkInfo,

      items,
      buildsByPackage,
      packageBuilds,
      buildsOverview,

      isLoading,
      errMsg,

      isDetailModalOpen,
      currentBuildId,
      currentBuild,

      isAddModalOpen,

      isSyncModalOpen,
      isSyncing,
      syncResult,

      isTempBuildModalOpen,
      isNotesModalOpen,
      isCompareModalOpen,
      isStatusModalOpen,

      currentTab,
    } = this.state;

    return (
      <div>
        {isLoading && <LoadingUI></LoadingUI>}
        {errMsg && <div className="text-red-800">{errMsg}</div>}

        {networkInfo && (
          <div>
            <NetworkInfoHeader networkInfo={networkInfo}></NetworkInfoHeader>

            <div className="min-h-screen bg-white px-12 py-8">
              <div className="mb-2 flex items-center justify-between">
                <PageTitle title="Network Code Snippet Builds"></PageTitle>
              </div>

              {buildsOverview && !_.isEmpty(buildsOverview) && (
                <>
                  <div className="rounded bg-gray-200 p-4">
                    Packages Overview
                    <div>
                      {buildsOverview.map((b) => {
                        return (
                          <div key={b.packageName} className="flex gap-2">
                            {b.packageName}: current version {b.currentVersion}{" "}
                            (Created:{" "}
                            <DateTimeFormatter
                              datetime={b.currentCreatedAt}
                              fromNowOnly={true}
                            ></DateTimeFormatter>
                            )
                            {b.hasNewVersion && (
                              <div className="flex items-center gap-1 text-red-600">
                                Latest version: {b.latest.version}
                                {b.latest.releaseNotes && (
                                  <>
                                    <FiBookmark
                                      data-tip
                                      data-for={`${b.packageName}_${b.currentVersion}`}
                                    ></FiBookmark>
                                    <ReactTooltip
                                      id={`${b.packageName}_${b.currentVersion}`}
                                      type="dark"
                                      effect="solid"
                                    >
                                      {b.latest.releaseNotes}
                                    </ReactTooltip>
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="mt-2 flex items-center gap-2">
                    Packages:
                    {packageBuilds.map((p) => {
                      return (
                        <div
                          key={p.packageName}
                          className={`rounded mr-1 cursor-pointer px-2 py-1 font-semibold ${
                            p.packageName === currentTab
                              ? "bg-indigo-200 text-indigo-900"
                              : "bg-gray-100 text-gray-700 hover:bg-gray-200 hover:text-indigo-800"
                          }`}
                          onClick={() => this.handleTabChanged(p.packageName)}
                        >
                          {p.packageName} ({p.builds.length})
                        </div>
                      );
                    })}
                  </div>
                </>
              )}

              <div className="mb-2 flex items-center justify-end gap-4">
                <button
                  type="button"
                  className={buttonConfirmClass}
                  onClick={this.handleSyncCloudflare}
                >
                  Sync Cloudflare
                </button>

                <button
                  type="button"
                  className={buttonConfirmClass}
                  onClick={this.handleOpenTempBuildModal}
                >
                  Create temp build
                </button>

                <button
                  type="button"
                  className={buttonConfirmClass}
                  onClick={this.handleOpenAddModal}
                >
                  Create new build
                </button>
              </div>

              <div>
                {items.length > 0 ? (
                  <BuildsTable
                    items={buildsByPackage[currentTab]}
                    handleOpenDetails={this.handleOpenDetails}
                    handleOpenNotesModal={this.handleOpenNotesModal}
                    handleOpenCompareModal={this.handleOpenCompareModal}
                    handleOpenStatusModal={this.handleOpenStatusModal}
                  ></BuildsTable>
                ) : (
                  "No builds yet"
                )}

                {isNotesModalOpen && (
                  <NotesModal
                    isOpenViewModal={isNotesModalOpen}
                    handleClose={this.handleModalClose}
                    networkId={networkId}
                    buildId={currentBuildId}
                    build={currentBuild}
                    handleRefreshAfterBuild={this.handleRefreshAfterBuild}
                  ></NotesModal>
                )}

                {isStatusModalOpen && (
                  <UpdateStatusModal
                    isOpenViewModal={isStatusModalOpen}
                    handleClose={this.handleModalClose}
                    networkId={networkId}
                    buildId={currentBuildId}
                    build={currentBuild}
                    handleRefreshAfterBuild={this.handleRefreshAfterBuild}
                  ></UpdateStatusModal>
                )}

                {isCompareModalOpen && (
                  <CompareBuildModal
                    isOpenViewModal={isCompareModalOpen}
                    handleClose={this.handleModalClose}
                    networkId={networkId}
                    buildId={currentBuildId}
                    build={currentBuild}
                    builds={buildsByPackage[currentTab]}
                  ></CompareBuildModal>
                )}

                {isDetailModalOpen && (
                  <BuildDetailsModal
                    isOpenViewModal={isDetailModalOpen}
                    handleClose={this.handleModalClose}
                    networkId={networkId}
                    buildId={currentBuildId}
                    build={currentBuild}
                  ></BuildDetailsModal>
                )}

                {isAddModalOpen && (
                  <CreateBuildModal
                    isOpenViewModal={isAddModalOpen}
                    handleClose={this.handleModalClose}
                    networkId={networkId}
                    handleCreateBuild={this.handleCreateBuild}
                    handleRefreshAfterBuild={this.handleRefreshAfterBuild}
                  ></CreateBuildModal>
                )}

                {isSyncModalOpen && (
                  <SyncResultModal
                    isOpenViewModal={isSyncModalOpen}
                    handleClose={this.handleModalClose}
                    isLoading={isSyncing}
                    syncResult={syncResult}
                  ></SyncResultModal>
                )}

                {isTempBuildModalOpen && (
                  <TempBuildModal
                    isOpenViewModal={isTempBuildModalOpen}
                    handleClose={this.handleModalClose}
                    networkId={networkId}
                    handleCreateTempBuild={this.handleCreateTempBuild}
                  ></TempBuildModal>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function PageTitle({ title }) {
  return <div className="text-3xl font-extrabold">{title}</div>;
}

const buttonActionClass =
  "px-2 py-1 bg-gray-200 text-xs rounded border border-gray-400 shadow hover:bg-gray-300 text-gray-900";

const BuildsTable = (props) => {
  const {
    items,
    handleOpenDetails,
    handleOpenCompareModal,
    handleOpenNotesModal,
    handleOpenStatusModal,
  } = props;

  return (
    <div>
      <table
        className="border shadow table w-full text-sm"
        style={{ marginBottom: "500px" }}
      >
        <thead className="border-b bg-gray-200 text-xs text-blue-800">
          <tr>
            <th className="border py-1 px-2">Build ID</th>
            <th className="border py-1 px-2">Package Name</th>
            <th className="border py-1 px-2">Version</th>
            <th className="border py-1 px-2">Release Status</th>
            <th className="border py-1 px-2">Notes</th>
            <th className="border py-1 px-2">Created At</th>
            <th className="border py-1 px-2">Actions</th>
          </tr>
        </thead>
        <tbody className="bg-white font-mono text-gray-900">
          {items &&
            _.orderBy(items, ["createdAt"], ["desc"]).map((r) => {
              return (
                <tr key={r.buildId} className="border-b hover:bg-gray-100">
                  <td className="whitespace-no-wrap border py-1 px-3 text-right">
                    <div className="flex items-center gap-1">
                      <div className="truncate" style={{ width: "100px" }}>
                        {r.buildId}
                      </div>
                      <ClickToCopyWrapper
                        copyText={r.buildId}
                      ></ClickToCopyWrapper>
                    </div>
                  </td>

                  <td className="whitespace-no-wrap border py-1 px-3 text-right">
                    {r.packageName}
                  </td>

                  <td className="whitespace-no-wrap border py-1 px-3 text-right">
                    {r.packageVersion}
                  </td>

                  <td className="whitespace-no-wrap border py-1 px-3 text-right">
                    <BuildReleaseStatusLabel
                      buildId={r.buildId}
                      integrationReleasedAt={r.integrationReleasedAt}
                      productionReleasedAt={r.productionReleasedAt}
                    ></BuildReleaseStatusLabel>
                  </td>

                  <td className="whitespace-no-wrap border py-1 px-3 text-right">
                    {r.notes}
                    <button
                      type="button"
                      className={buttonActionClass}
                      onClick={() => handleOpenNotesModal(r)}
                    >
                      <MdEdit></MdEdit>
                    </button>
                  </td>

                  <td className="whitespace-no-wrap border py-1 px-3 text-right">
                    <DateTimeFormatter
                      datetime={r.createdAt}
                    ></DateTimeFormatter>
                  </td>

                  <td className="whitespace-no-wrap border py-1 px-3 text-right font-sans">
                    <button
                      type="button"
                      className={buttonActionClass}
                      onClick={() => handleOpenDetails(r)}
                    >
                      Details
                    </button>{" "}
                    <button
                      type="button"
                      className={buttonActionClass}
                      onClick={() => handleOpenStatusModal(r)}
                    >
                      Update Status
                    </button>{" "}
                    <button
                      type="button"
                      className={buttonActionClass}
                      onClick={() => handleOpenCompareModal(r)}
                    >
                      Compare Build
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default CodeSnippetViewer;
