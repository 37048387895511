import React from "react";
import _ from "lodash";
import ReactModal from "react-modal";
import ModalWrapper from "../common/ModalWrapper";
import { decrypt } from "./VaultHelper";
import PasswordEyeInput from "../common/PasswordEyeInput";
import LoadingUI from "components/common/LoadingUI";

const inputClass =
  "bg-white flex w-full border border-gray-400 rounded py-1 px-3 appearance-none leading-normal focus:outline-none focus:border-blue-400 focus:shadow-inner hover:border-gray-500";
const inputClassDisabled =
  "bg-gray-300 flex w-full border border-gray-400 rounded py-1 px-3 appearance-none leading-normal";

ReactModal.setAppElement("body");

class VaultAccountFormModal extends React.Component {
  constructor(props) {
    super(props);

    const { account } = props;
    if (props.modalType === "EDIT") {
      account.password = decrypt(account.secret);
      account.recoveryEmail = decrypt(account.recovery_email);
    }
    const { email = "", password = "", recoveryEmail = "" } = account;

    this.state = {
      email,
      password,
      recoveryEmail,
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEmailChanged = this.handleEmailChanged.bind(this);
    this.handlePasswordChanged = this.handlePasswordChanged.bind(this);
    this.handleRecoveryEmailChanged =
      this.handleRecoveryEmailChanged.bind(this);
  }

  handleCancel() {
    this.setState({
      email: "",
      password: "",
      recoveryEmail: "",
    });
    this.props.handleClose();
  }

  handleSubmit() {
    const { email, password, recoveryEmail } = this.state;
    const account = {
      email,
      password,
      recoveryEmail,
    };
    this.props.handleSave(account);
  }

  handleEmailChanged(e) {
    this.setState({ email: e.target.value });
  }

  handlePasswordChanged(e) {
    this.setState({ password: e.target.value });
  }

  handleRecoveryEmailChanged(e) {
    this.setState({ recoveryEmail: e.target.value });
  }

  render() {
    const {
      isOpenModal,
      // handleClose,
      modalType, // ADD, EDIT
      isSaving,
      saveErrorMsg,
      isLoadingModal,
    } = this.props;
    const { email, password, recoveryEmail } = this.state;

    return (
      <ModalWrapper
        isOpen={isOpenModal}
        showCloseFooter={false}
        // handleClose={handleClose}
        width="50%"
      >
        <div>
          <div className="mb-4 text-lg font-bold border-b pb-2 px-6 -mx-6">
            {modalType === "ADD" ? "Add account" : "Edit account"}
          </div>
          <div>
            {isLoadingModal ? (
              <LoadingUI></LoadingUI>
            ) : (
              <form
                autoComplete="false"
                onSubmit={(e) => {
                  e.preventDefault();
                  return this.handleSubmit();
                }}
              >
                <div>
                  <div className="my-2">
                    <label
                      className="block text-gray-800 font-semibold mb-1 pr-4 leading-none"
                      htmlFor="account-email"
                    >
                      Email
                    </label>
                    <div className="w-2/3">
                      <input
                        className={
                          modalType === "ADD" ? inputClass : inputClassDisabled
                        }
                        id="account-email"
                        type="email"
                        value={email}
                        autoFocus
                        onChange={this.handleEmailChanged}
                        required
                        disabled={modalType !== "ADD"}
                      ></input>
                    </div>

                    {modalType === "ADD" && (
                      <div className="my-2">
                        <label
                          className="block text-gray-800 font-semibold mb-1 pr-4 leading-none"
                          htmlFor="i2w-vault-account-password"
                        >
                          Password
                        </label>
                        <div className="w-2/3">
                          {/* <input
                              className={inputClass}
                              id="i2w-vault-account-password"
                              type="text"
                              value={password}
                              onChange={this.handlePasswordChanged}
                              required
                            ></input> */}
                          <PasswordEyeInput
                            id="i2w-vault-account-password"
                            value={password}
                            onChange={this.handlePasswordChanged}
                            required={true}
                          ></PasswordEyeInput>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="my-2">
                  <label
                    className="block text-gray-800 font-semibold mb-1 pr-4 leading-none"
                    htmlFor="account-recov-email"
                  >
                    Recovery email
                  </label>
                  <div className="w-2/3">
                    <input
                      className={inputClass}
                      id="account-recov-email"
                      type="email"
                      value={recoveryEmail}
                      onChange={this.handleRecoveryEmailChanged}
                      required
                    ></input>
                  </div>
                </div>

                <div className="flex justify-end items-center mt-8">
                  <div className="text-red-700">{saveErrorMsg}</div>
                  <button
                    className="flex-shrink-0 border-transparent border-4 text-blue-500 hover:text-blue-800 text-sm py-1 px-2 rounded"
                    type="button"
                    onClick={this.handleCancel}
                  >
                    Cancel
                  </button>

                  <button
                    className="flex-shrink-0 bg-blue-500 hover:bg-blue-800 border-blue-500 hover:border-blue-700 text-sm border-4 text-white py-1 px-2 rounded font-semibold"
                    type="submit"
                    disabled={isSaving}
                  >
                    {isSaving ? "Saving..." : "Save account"}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </ModalWrapper>
    );
  }
}

export default VaultAccountFormModal;
