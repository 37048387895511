import React from "react";
import _ from "lodash";
import BankAccountSelector from "../../BankAccountSelector";
import BillingProfileEdit from "./BillingProfileEdit";
import BillingProfileView from "./BillingProfileView";

const inputClass =
  "bg-white flex w-full border border-gray-300 rounded py-1 px-4 appearance-none leading-normal focus:outline-none focus:border-blue-400 focus:shadow-inner hover:shadow";

const labelClass = "font-semibold text-gray-800 text-sm text-right";

class BillingProfileSectionForm extends React.PureComponent {
  constructor(props) {
    super(props);

    const { billingProfile = {} } = this.props;
    const billingProfileId = billingProfile.profileId;

    this.state = {
      billingProfileId,
      billingProfile,
      isEditMode: false,
    };

    this.onInputChanged = this.onInputChanged.bind(this);
  }

  onInputChanged(billingProfileId) {
    this.setState({ billingProfileId });
    console.log(billingProfileId);

    // transform to valid type
    const { setting } = this.props;
    const changedSetting = { ...setting, billingProfileId };
    this.props.onSettingChanged(changedSetting);
  }

  checkKeyValid(key, value) {
    let isValid = true;
    let errMsg = null;

    // errorObj[key] = errMsg
    switch (key) {
      // case "phoneNumber":
      // case "phoneCountryCode": {
      //   const newValue = _.parseInt(value);
      //   if (_.isNaN(newValue)) {
      //     isValid = false;
      //     errMsg = `${key} should be a number`;
      //     break;
      //   }
      // }

      default: {
        //
      }
    }

    return { isValid, errMsg };
  }

  checkIsSectionValid(setting) {
    // check the whole section
    const { invoiceSetting = {} } = setting;
    let isValid = true;
    const requiredKeys = ["category", "printFlag", "taxType"];
    for (let key of requiredKeys) {
      if (
        invoiceSetting[key] === "" ||
        invoiceSetting[key] === undefined ||
        invoiceSetting[key] === null
      ) {
        isValid = false;
        this.setState({ errorObj: { [key]: `${key} is required` } });
        break;
      }
    }

    return isValid;
  }

  render() {
    const { publisherId } = this.props;
    const { billingProfile, billingProfileId, isEditMode } = this.state;

    return (
      <div className="border shadow rounded mb-4 bg-gray-200 p-4">
        <div className="border-b mb-6 mt-2 border-gray-400 text-xl font-bold text-gray-900">
          Billing Profile
        </div>

        <div className="rounded bg-white p-4">
          <BillingProfileEdit
            publisherId={publisherId}
            billingProfileId={billingProfileId}
            onBillingProfileIdChanged={this.onInputChanged}
          ></BillingProfileEdit>
          {/* {billingProfile.profileId ? (
            <BillingProfileView
              billingProfile={billingProfile}
            ></BillingProfileView>
          ) : (
            <BillingProfileEdit
              publisherId={publisherId}
              billingProfileId={billingProfileId}
            ></BillingProfileEdit>
          )} */}
        </div>
        {/* <div className="my-2">
          <button
            type="button"
            className="border rounded cursor-pointer bg-blue-200 px-4 py-2 text-sm font-semibold text-blue-900 hover:bg-blue-300"
          >
            Change Billing Profile
          </button>
        </div> */}
      </div>
    );
  }
}

export default BillingProfileSectionForm;
