import React from "react";
import { Switch, Route } from "react-router-dom";
import BillingViewer from "./billing/BillingViewer";
import AccountViewer from "./account/AccountViewer";

class OpsManagementViewer extends React.Component {
  componentDidMount() {
    document.body.style.backgroundColor = "#fcfcfc";
  }

  render() {
    return (
      <div>
        {/* <div className="bg-blue-900 px-2">
          <div className="text-blue-100 text-sm font-bold">
            YB Ops Management
          </div>
        </div> */}

        <>
          <Switch>
            <Route path="/ops-mgmt/billing" component={BillingViewer}></Route>
          </Switch>

          <Switch>
            <Route path="/ops-mgmt/account" component={AccountViewer}></Route>
          </Switch>
        </>
      </div>
    );
  }
}

export default OpsManagementViewer;
