import React from "react";
import _ from "lodash";
import ExperimentSection from "./ExperimentSection";
import ScrollToTop from "../common/ScrollToTop";

const NUM_PER_PAGE = 8;

class SegmentExperiments extends React.Component {
  constructor(props) {
    super(props);

    const { yieldLifts } = props;

    this.state = {
      yieldLifts,
      paginatedData: this.repaginateData(yieldLifts),
      isLoadingMoreData: false,
      deeplinkExpGroupId: null,
      highlightExpGroupId: null,
    };

    this.scrollToExp = this.scrollToExp.bind(this);
    this.scrollToExpGroup = this.scrollToExpGroup.bind(this);
    this.handleLoadMore = this.handleLoadMore.bind(this);
  }

  scrollToExpGroup(expGroupId, isSmoothScroll, isDeeplink) {
    if (!expGroupId) return;
    const element = document.getElementById(`exp-group-anchor-${expGroupId}`);

    if (element) {
      // Important! window.scrollTo does not work if target element is in <table/>
      // so use scrollIntoView instead
      element.scrollIntoView({ behavior: isSmoothScroll ? "smooth" : "auto" });
      if (isDeeplink) {
        this.setState({
          deeplinkExpGroupId: expGroupId,
        });
      } else {
        this.setState({
          highlightExpGroupId: expGroupId,
        });
      }
    } else {
      this.handleLoadMore();
      setTimeout(() => {
        console.log("loadmore...");
        this.scrollToExpGroup(expGroupId, isSmoothScroll);
      }, 1000);
    }
  }

  scrollToExp(expId, isSmoothScroll) {
    if (!expId) return;
    const element = document.getElementById(`#expbox-${expId}`);

    if (element) {
      window.scrollTo({
        behavior: isSmoothScroll ? "smooth" : "auto",
        top: element.offsetTop - 76,
      });
    } else {
      this.handleLoadMore();
      setTimeout(() => {
        console.log("loadmore...");
        this.scrollToExp(expId, isSmoothScroll);
      }, 1000);
    }
  }

  repaginateData(data) {
    const nth = NUM_PER_PAGE;
    const paginatedData = _.slice(data, 0, nth);
    return paginatedData;
  }

  handleLoadMore() {
    if (this.state.isLoadingMoreData) {
      return false;
    }

    this.setState({ isLoadingMoreData: true });

    setTimeout(() => {
      const nth = this.state.paginatedData.length + NUM_PER_PAGE;
      const paginatedData = _.slice(this.state.yieldLifts, 0, nth);

      this.setState({
        paginatedData,
        isLoadingMoreData: false,
      });
    });
  }

  render() {
    const {
      segmentId,
      dateRange,
      timezone,
      expInfo,
      expLink,
      segmentInfo,
      groupInfo,
    } = this.props;

    const {
      paginatedData,
      yieldLifts,
      isLoadingMoreData,
      highlightExpGroupId,
      deeplinkExpGroupId,
    } = this.state;

    return (
      <div className="-mt-8">
        {segmentId &&
          paginatedData &&
          paginatedData.length > 0 &&
          paginatedData.map((exp) => {
            return (
              <ExperimentSection
                key={exp.expId}
                data={exp}
                expInfo={expInfo}
                segmentInfo={segmentInfo}
                groupInfo={groupInfo}
                expLink={expLink}
                dateRange={dateRange}
                timezone={timezone}
                scrollToExp={this.scrollToExp}
                scrollToExpGroup={this.scrollToExpGroup}
                highlightExpGroupId={highlightExpGroupId}
                deeplinkExpGroupId={deeplinkExpGroupId}
              ></ExperimentSection>
            );
          })}

        {paginatedData.length === yieldLifts.length ? (
          ""
        ) : (
          <button
            id="loadmoreexps"
            type="button"
            disabled={isLoadingMoreData}
            className="w-full p-4 font-bold bg-indigo-500 text-white hover:bg-indigo-600"
            onClick={this.handleLoadMore}
          >
            {isLoadingMoreData ? "Loading..." : "Load more"}
          </button>
        )}

        <ScrollToTop></ScrollToTop>
      </div>
    );
  }
}

export default SegmentExperiments;
