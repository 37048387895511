import React from "react";
import _ from "lodash";
import ClickToCopyWrapper from "components/common/ClickToCopyWrapper";

class ArrayTextAreaControl extends React.PureComponent {
  constructor(props) {
    super(props);

    const items = this.props.inputValue;
    let errorMsg = null;

    if (_.isEmpty(items)) {
      errorMsg = "Please fill in at least one item";
      this.props.handleHasError(true);
    }

    this.state = {
      items,
      errorMsg,
    };

    this.handleItemsChanged = this.handleItemsChanged.bind(this);
  }

  handleItemsChanged(items) {
    this.setState({ items, errorMsg: null });

    if (_.isEmpty(items)) {
      this.setState({
        errorMsg: "Please fill in at least one item",
      });
      this.props.handleHasError(true);
      return;
    }

    const { valueSchema } = this.props;
    if (valueSchema.valueType === "array_integer") {
      const hasNonInteger = _.some(items, (item) => {
        return _.isNaN(parseInt(item));
      });

      if (hasNonInteger) {
        this.setState({
          errorMsg: "Items should be numbers",
        });
        this.props.handleHasError(true);
        return;
      }
    }

    if (valueSchema.valueType === "array_float") {
      const hasNonInteger = _.some(items, (item) => {
        return _.isNaN(parseFloat(item));
      });

      if (hasNonInteger) {
        this.setState({
          errorMsg: "Items should be numbers",
        });
        this.props.handleHasError(true);
        return;
      }
    }

    if (valueSchema.minItems && items.length < valueSchema.minItems) {
      this.setState({
        errorMsg: `There cannot be less than ${valueSchema.minItems} items.`,
      });
      this.props.handleHasError(true);
      return;
    }

    if (valueSchema.maxItems && items.length > valueSchema.maxItems) {
      this.setState({
        errorMsg: `There cannot be more than ${valueSchema.maxItems} items.`,
      });
      this.props.handleHasError(true);
      return;
    }

    this.props.handleHasError(false);
    this.props.handleNewValueChanged(items);
  }

  render() {
    const { valueSchema } = this.props;

    const { items, errorMsg } = this.state;

    return (
      <div className="border rounded ml-4 bg-white p-2 text-sm text-gray-700">
        <>
          {valueSchema.note && (
            <div className="mb-2 flex items-center text-xs font-semibold italic">
              Note: {valueSchema.note}
            </div>
          )}

          {valueSchema.sample && (
            <div className="flex items-center">
              ex. {valueSchema.sample}{" "}
              <ClickToCopyWrapper
                copyText={valueSchema.sample}
              ></ClickToCopyWrapper>
            </div>
          )}

          <TextAreaControl
            inputValue={items}
            handleItemsChanged={this.handleItemsChanged}
          ></TextAreaControl>

          <div className="text-red-600">{errorMsg}</div>
        </>
      </div>
    );
  }
}

const basicInputClass =
  "bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded py-1 px-2 block appearance-none leading-normal";

class TextAreaControl extends React.PureComponent {
  constructor(props) {
    super(props);

    const { inputValue } = this.props;

    this.state = {
      items: inputValue,
      itemsString: inputValue,
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const itemsString = e.target.value;
    const values = _.compact(itemsString.split(","));

    this.setState({ itemsString, items: values });
    this.props.handleItemsChanged(values);
  }

  render() {
    const { itemsString, items } = this.state;

    return (
      <>
        <textarea
          rows={6}
          className={basicInputClass + " w-full"}
          value={itemsString}
          onChange={this.onChange}
        ></textarea>
        <div className="text-sm">Separate by ","</div>
        <div className="text-sm">{items.length} items</div>
      </>
    );
  }
}

export default ArrayTextAreaControl;
