import React from "react";
import _ from "lodash";
import { NetworkAPI } from "apis";
import ModalWrapper from "components/common/ModalWrapper";
import LoadingUI from "components/common/LoadingUI";

const buttonActionClass =
  "px-2 py-1 bg-gray-200 text-xs rounded border border-gray-400 shadow hover:bg-gray-300 text-gray-900 font-semibold";

class NetworkEnsureResourcesButton extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      isLoading: false,
      resultMsg: null,
    };

    this.handleEnsureResources = this.handleEnsureResources.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  async handleEnsureResources() {
    const { networkId } = this.props;
    if (!networkId) return;
    const userConfirm = window.confirm(
      `Ensure resources for network ${networkId}?`
    );
    if (!userConfirm) return;

    this.setState({ isLoading: true, isModalOpen: true });
    try {
      const { networkId } = this.props;

      const r = await NetworkAPI.triggerEnsureResources({ networkId });

      this.setState({
        resultMsg: JSON.stringify(r, null, 2),
        isLoading: false,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        resultMsg: err,
        isLoading: false,
      });
    }
  }

  handleModalClose() {
    this.setState({
      isModalOpen: false,
      isLoading: false,
      resultMsg: null,
    });
  }

  render() {
    const { isLoading, isModalOpen, resultMsg } = this.state;
    return (
      <>
        <button
          type="button"
          className={buttonActionClass}
          onClick={this.handleEnsureResources}
        >
          Ensure Resources
        </button>
        {isModalOpen && (
          <SyncResultModal
            isLoading={isLoading}
            syncResult={resultMsg}
            isOpenViewModal={isModalOpen}
            handleClose={this.handleModalClose}
          ></SyncResultModal>
        )}
      </>
    );
  }
}

class SyncResultModal extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { isLoading, syncResult, isOpenViewModal, handleClose } = this.props;

    return (
      <ModalWrapper
        isOpen={isOpenViewModal}
        showCloseFooter={true}
        handleClose={handleClose}
      >
        <div className="mb-2 text-lg font-semibold">Ensure Resources:</div>
        <div className="overflow-y-auto" style={{ maxHeight: "300px" }}>
          {isLoading ? (
            <LoadingUI iconOnly={true}></LoadingUI>
          ) : (
            <textarea
              className="w-full bg-gray-200"
              rows={10}
              defaultValue={syncResult}
              readOnly
            ></textarea>
          )}
        </div>
      </ModalWrapper>
    );
  }
}

export default NetworkEnsureResourcesButton;
