import React from "react";
import { Switch, Route, Link } from "react-router-dom";
import InvoicesViewer from "./InvoicesViewer";
import ReceivePaymentView from "./ReceivePaymentView";

class BillingViewer extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <div>
        <div className="bg-blue-900 px-2">
          <div className="text-blue-100 text-sm font-bold">
            Billing Services:
            <span className="mx-2 text-blue-100">
              <Link to="/ops-mgmt/billing/invoices/wait-for-i2w-approval">
                Invoices
              </Link>
            </span>
          </div>
        </div>

        <>
          <Switch>
            <Route
              path="/ops-mgmt/billing/invoices"
              component={InvoicesViewer}
            ></Route>

            <Route
              path="/ops-mgmt/billing/payment/:paymentId/receive"
              component={ReceivePaymentView}
            ></Route>
          </Switch>
        </>
      </div>
    );
  }
}

export default BillingViewer;
