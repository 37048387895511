import _ from "lodash";

export function getUnitStateChangeSummary(items) {
  // key: ${from}_${to}
  // from, to, unitIds
  let summary = {};

  _.forEach(items, (item) => {
    const from = item.unit_state;
    let to = null;

    const executionPlanActions = _.get(item, ["actions", "execution_plan"], []);

    let hasStateChangeAction = false;

    _.forEach(executionPlanActions, (action) => {
      if (action.cat === "UNIT_STATE") {
        hasStateChangeAction = true;
        to = action.value;

        // should alert if there's another action with different state change
        if (!to && to === action.value) {
          console.log("State change conflict!", item);
        }
      }
    });

    if (hasStateChangeAction) {
      const key = `${from}_${to}`;
      if (summary[key]) {
        summary[key].unitIds.push(item.unit_id);
      } else {
        summary[key] = { from, to, unitIds: [item.unit_id] };
      }
    }
  });

  // console.log(summary);
  return summary;
}
