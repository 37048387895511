import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
// import NumberFormat from "../../common/NumberFormat";
import DateTimeFormatter from "../../common/DateTimeFormatter";
import SegmentsDetailPopover from "./SegmentsDetailPopover";
import ReactTooltip from "react-tooltip";
import { IoMdPause, IoMdPlay } from "react-icons/io";

class ToggleResearchConfigButton extends React.Component {
  constructor(props) {
    super(props);

    this.handleResume = this.handleResume.bind(this);
    this.handlePause = this.handlePause.bind(this);
  }

  handlePause() {
    const { unitId, configId } = this.props;
    this.props.handlePauseConfig({ unitId, configId });
  }

  handleResume() {
    const { unitId, configId } = this.props;
    this.props.handleResumeConfig({ unitId, configId });
  }

  render() {
    const { configId, unitId, trafficRatio } = this.props;

    if (trafficRatio === 0) {
      return (
        <>
          <button
            type="button"
            className="px-2 py-2 mr-1 text-sm bg-green-200 rounded shadow hover:bg-green-300 text-green-700 font-semibold"
            onClick={this.handleResume}
            data-tip
            data-for={`resume-${configId}`}
          >
            <IoMdPlay></IoMdPlay>
          </button>

          <ReactTooltip id={`resume-${configId}`} type="dark" effect="solid">
            Resume Config
          </ReactTooltip>
        </>
      );
    } else {
      return (
        <>
          <button
            type="button"
            className="px-2 py-2 mr-1 text-sm bg-red-200 rounded shadow hover:bg-red-300 text-red-700 font-semibold"
            data-tip
            data-for={`pause-${configId}`}
            onClick={this.handlePause}
          >
            <IoMdPause></IoMdPause>
          </button>

          <ReactTooltip id={`pause-${configId}`} type="dark" effect="solid">
            Pause Config
          </ReactTooltip>
        </>
      );
    }
  }
}

export default ToggleResearchConfigButton;
