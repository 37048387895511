import React from "react";
import _ from "lodash";
// import moment from "moment-timezone";
import LoadingUI from "../../common/LoadingUI";
import PreconditionPretty from "./PreconditionPretty";
import CriteriaPretty from "./CriteriaPretty";
import ActionPretty from "./ActionPretty";

const RecipeBox = (props) => {
  const { item } = props;
  // console.log(item);
  // TODO
  // version
  // preconditions
  // actions

  return (
    <div className="p-2 rounded border bg-white">
      <div className="flex justify-between items-center bg-gray-100 px-2 -mx-2 py-2 -mt-2 mb-2 border-b">
        <div>
          {item.id && <span className="px-1 font-bold">{item.id}</span>}

          {item.version && (
            <span className="px-2 mx-2 bg-gray-300 py-3 -my-3 font-mono">
              {`v${item.version}`}
            </span>
          )}

          <span className="px-1">{item.name}</span>
        </div>
        <RecipeStatus status={item.status}></RecipeStatus>
      </div>

      <div className="font-mono">
        <span className="bg-gray-300 rounded px-3 text-gray-900">
          {item.schedule}
        </span>
      </div>
      <div className="mb-2">
        Preconditions:
        <ul className="list-disc list-inside pl-4">
          {item.preconditions.map((p, i) => {
            return (
              <li key={i}>
                <PreconditionPretty item={p}></PreconditionPretty>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="mb-2">
        Check Criteria:
        <ul className="list-disc list-inside pl-4">
          {item.criteria.map((cc, i) => {
            return (
              <li key={i}>
                <CriteriaPretty item={cc}></CriteriaPretty>
              </li>
            );
          })}
        </ul>
      </div>
      <div>
        Actions:
        <ul className="list-disc list-inside pl-4">
          {item.action_templates.map((at, i) => {
            return (
              <li key={i}>
                <ActionPretty item={at}></ActionPretty>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

// recipeStatus: 1: Running, 2: Paused, 3: Archived
const RecipeStatus = (props) => {
  const { status } = props;

  let statusString = "";
  let statusClass = "";
  switch (status) {
    case 1: {
      statusString = "Running";
      statusClass = "text-green-900 bg-green-200";
      break;
    }
    case 2: {
      statusString = "Paused";
      statusClass = "text-orange-900 bg-orange-200";
      break;
    }
    case 3: {
      statusString = "Archived";
      statusClass = "text-gray-900 bg-gray-200";
      break;
    }
    default: {
      statusString = "Unknown";
    }
  }

  return (
    <span className={statusClass + " rounded px-2 font-semibold"}>
      {statusString}
    </span>
  );
};

export default RecipeBox;
