import React from "react";
import _ from "lodash";
import Select from "react-select";
import { VseAPI } from "apis";

let isMounted = false;
class ChecksSelector extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      checks: [],

      items: [],
      options: [],
      selectedValue: null,
      defaultValue: null,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    isMounted = true;
    const { checks } = await VseAPI.getAllChecks();

    let excludeCheckIds = [];
    if (this.props.excludeChecks) {
      excludeCheckIds = _.map(this.props.excludeChecks, "check_id");
    }

    const items = _.filter(checks, (c) => {
      return _.indexOf(excludeCheckIds, c.id) === -1;
    });
    let options = _.map(items, (r) => {
      return {
        value: r.id,
        label: r.id + ": " + r.name,
      };
    });

    let defaultItem;
    if (this.props.defaultValue) {
      defaultItem = _.find(options, (o) => {
        return o.value == this.props.defaultValue;
      });
      // console.log(this.props.defaultValue, defaultItem);
    }

    if (isMounted) {
      this.setState({
        checks,
        items,
        options,
        selectedValue: defaultItem,
      });
    }
  }
  componentWillUnmount() {
    isMounted = false;
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.selectedCheck !== this.props.selectedCheck) {
      if (this.props.selectedCheck === null) {
        this.setState({
          selectedValue: null,
        });
      }
    }

    // console.log(prevProps.excludeChecks, this.props.excludeChecks);
    if (prevProps.excludeChecks !== this.props.excludeChecks) {
      const { checks } = this.state;
      const excludeCheckIds = _.map(this.props.excludeChecks, "check_id");
      const items = _.filter(checks, (c) => {
        return _.indexOf(excludeCheckIds, c.id) === -1;
      });
      let options = _.map(items, (r) => {
        return {
          value: r.id,
          label: r.id + ": " + r.name,
        };
      });

      this.setState({
        options,
      });
    }
  }

  handleChange(option) {
    this.setState({
      selectedValue: option,
    });

    const item = _.find(this.state.items, { id: option.value });
    this.props.handleOnChange(item);
  }

  render() {
    const { options, selectedValue } = this.state;
    return (
      <>
        <Select
          defaultValue={selectedValue}
          value={selectedValue}
          onChange={this.handleChange}
          options={options}
        />
      </>
    );
  }
}

export default ChecksSelector;
