import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import { DashboardAPI } from "apis";
import LoadingUI from "../common/LoadingUI";
import BizHighchartView from "./BizHighchartView";
import BizNetworkTable from "./BizNetworkTable";
import ChartHeaderWithSelectors from "./ChartHeaderWithSelectors";
import BizHighchartStackedView from "./BizHighchartStackedView";

const DATE_FORMAT = "YYYY-MM-DD";
const DATE_RANGE_TYPE = {
  THIS_MONTH: "THIS_MONTH", // default
  LAST_MONTH: "LAST_MONTH",
  PAST_3_MONTHS: "PAST_3_MONTHS",
};
const CHART_TYPE = {
  VS_REV_GOAL: "Vs Rev Goal",
  TOP_STACKED: "Top 5 Stacked",
};

class BizViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,

      networkId: null,

      data: null,
      filteredDateRange: null, // for chart dragging filtering

      networkInfo: null,

      // should save on first query, no overwrite when date range changes
      todayData: null,
      yesterdayData: null,

      dateRangeType: DATE_RANGE_TYPE.THIS_MONTH,
      startDate: null,
      endDate: null,

      isNewDataLoading: false,

      currentChartType: CHART_TYPE.VS_REV_GOAL,
    };

    this.onDateRangeTypeChanged = this.onDateRangeTypeChanged.bind(this);
    this.queryAndUpdateUI = this.queryAndUpdateUI.bind(this);
    this.queryBizData = this.queryBizData.bind(this);

    this.handleFilterByDateRange = this.handleFilterByDateRange.bind(this);
    this.handleChartTypeChange = this.handleChartTypeChange.bind(this);
  }

  async componentDidMount() {
    const networkId = _.get(this.props, "match.params.networkId");

    if (networkId) {
      document.title = `${networkId} Network Biz Overview | YB Observer`;
    } else {
      document.title = `Biz Overview | YB Observer`;
    }

    this.setState({ isLoading: true, networkId });
    const { startDate, endDate } = _getDateRangeFromDateRangeType(
      this.state.dateRangeType
    );
    await this.queryAndUpdateUI({ startDate, endDate, networkId });
    this.setState({ isLoading: false, startDate, endDate });
  }

  async onDateRangeTypeChanged(dateRangeType) {
    this.setState({ isNewDataLoading: true, dateRangeType });
    const { startDate, endDate } =
      _getDateRangeFromDateRangeType(dateRangeType);
    const params = { startDate, endDate, networkId: this.state.networkId };
    await this.queryAndUpdateUI(params);
    this.setState({ isNewDataLoading: false, startDate, endDate });
  }

  async queryAndUpdateUI(params) {
    try {
      const {
        data,
        networkInfo,
        topNData,
        overviewData,
        todayData,
        yesterdayData,
        totalData,
      } = await this.queryBizData(params);

      if (_.isEmpty(data)) {
        this.setState({ errMsg: "No data found" });
      }

      this.setState({
        data,
        networkInfo,
        overviewData,
        topNData,
        totalData,
      });

      if (this.state.dateRangeType === DATE_RANGE_TYPE.THIS_MONTH) {
        this.setState({
          todayData,
          yesterdayData,
        });
      }
    } catch (err) {
      console.log("Error querying biz overview", err);
    }
  }

  async queryBizData({ startDate, endDate, networkId }) {
    if (!startDate) return;
    let params = { startDate };
    if (endDate) {
      params.endDate = endDate;
    }
    if (networkId) {
      params.networkId = networkId;
    }

    let reports = null;
    if (networkId) {
      const { data } = await DashboardAPI.getBizByNetwork(params);
      reports = data;
    } else {
      const { data } = await DashboardAPI.getBizOverview(params);
      reports = data;
    }

    const networkInfo = _getNetworkInfoFromReports(networkId, reports);
    const { overviewData, totalDailyGoal, totalNetIncreasedRev } =
      _calculatePlatformOverviewData({ data: reports });
    const topNData = _calculatePlatformStackedData({ data: reports });
    const totalGoalReached = _.round(
      (totalNetIncreasedRev / totalDailyGoal) * 100,
      2
    );

    let todayData = null;
    let yesterdayData = null;
    if (this.state.dateRangeType === DATE_RANGE_TYPE.THIS_MONTH) {
      todayData = _.find(overviewData, "isToday");
      yesterdayData = _.find(overviewData, "isYesterday");
    }

    return {
      data: reports,
      topNData,
      overviewData,
      todayData,
      yesterdayData,
      networkInfo,
      totalData: {
        totalDailyGoal,
        totalNetIncreasedRev,
        totalGoalReached,
      },
    };
  }

  handleFilterByDateRange({ startTime, endTime }) {
    this.setState({
      filteredDateRange: {
        startTime,
        endTime,
      },
    });
  }

  handleChartTypeChange(chartType) {
    if (chartType === this.state.currentChartType) return;
    this.setState({ currentChartType: chartType });
  }

  render() {
    const {
      networkId,
      networkInfo,
      data,
      topNData,
      filteredDateRange,
      overviewData,
      todayData,
      yesterdayData,
      dateRangeType,
      isNewDataLoading,
      startDate,
      endDate,
      totalData,
      currentChartType,
    } = this.state;

    return (
      <>
        {this.state.isLoading ? (
          <LoadingUI></LoadingUI>
        ) : (
          <>
            {this.state.errMsg ? (
              <div>{this.state.errMsg}</div>
            ) : (
              <div className="bg-white px-12">
                <h1 className="font-extrabold text-gray-900 text-4xl pt-8 pb-4">
                  {networkId ? (
                    <>
                      <div className="text-base leading-none font-bold text-gray-800">
                        Business Overview
                      </div>
                      [{networkId}] {networkInfo && networkInfo.networkName}
                    </>
                  ) : (
                    "Platform Business Overview"
                  )}
                </h1>
              </div>
            )}

            {data && (
              <>
                <div className="bg-gray-200 px-12 py-8 min-h-full">
                  <div className="p-4 bg-white mb-4">
                    <ChartHeaderWithSelectors
                      todayData={todayData}
                      yesterdayData={yesterdayData}
                      totalData={totalData}
                      dateRangeType={dateRangeType}
                      handleDateRangeTypeChanged={this.onDateRangeTypeChanged}
                      startDate={startDate}
                      endDate={endDate}
                    ></ChartHeaderWithSelectors>
                  </div>

                  {isNewDataLoading ? (
                    <div className="h-screen">
                      <LoadingUI></LoadingUI>
                    </div>
                  ) : (
                    <>
                      <div className="p-4 bg-white mb-4">
                        {!networkId && (
                          <div className="flex justify-end items-center mb-4">
                            <div className="font-semibold text-indigo-900 pr-1">
                              Switch Charts:
                            </div>
                            {_.values(CHART_TYPE).map((chartType) => {
                              return (
                                <button
                                  key={chartType}
                                  type="button"
                                  className={`px-4 py-2 mx-1 font-semibold rounded ${
                                    currentChartType === chartType
                                      ? "bg-indigo-100 text-indigo-800"
                                      : "text-gray-700 hover:text-indigo-800 hover:bg-gray-100 hover:shadow"
                                  }`}
                                  onClick={() =>
                                    this.handleChartTypeChange(chartType)
                                  }
                                >
                                  {chartType}
                                </button>
                              );
                            })}
                          </div>
                        )}

                        {currentChartType === CHART_TYPE.VS_REV_GOAL && (
                          <BizHighchartView
                            reports={overviewData}
                            handleFilterByDateRange={
                              this.handleFilterByDateRange
                            }
                          ></BizHighchartView>
                        )}

                        {currentChartType === CHART_TYPE.TOP_STACKED && (
                          <BizHighchartStackedView
                            topNData={topNData}
                            reports={overviewData}
                            handleFilterByDateRange={
                              this.handleFilterByDateRange
                            }
                          ></BizHighchartStackedView>
                        )}
                      </div>

                      <div className="p-4 bg-white">
                        <BizNetworkTable
                          reports={data}
                          totalData={totalData}
                          filteredDateRange={filteredDateRange}
                          isLinkable={networkId ? false : true}
                        ></BizNetworkTable>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  }
}

function _calculatePlatformStackedData({ data }) {
  // 1. data of the top 10 or 5 networks
  //    - date, network,
  const TOP_NUM = 5;
  let orderedData = _calculateDataByNetwork(data);
  let topNetworkIds = _.map(_.take(orderedData, TOP_NUM), "networkId");

  // an array of top N network's data + others summed data
  let topNetworksDataSeries = [];
  _.forEach(topNetworkIds, (id, i) => {
    // topNetworksDataSeries.push(data[id]);
    topNetworksDataSeries.push({ networkId: id, index: i + 1, data: data[id] });
  });

  let othersData = _.filter(data, (networkData, networkId) => {
    return _.indexOf(topNetworkIds, _.parseInt(networkId)) === -1;
  });

  let allOthersData = [];
  _.forEach(othersData, (networkData) => {
    _.forEach(networkData, (dateData) => {
      allOthersData.push(dateData);
    });
  });

  othersData = _(allOthersData)
    .groupBy("date")
    .mapValues((ds, date) => {
      const netIncreasedRevTwd = _.sumBy(ds, "netIncreasedRevTwd");
      const dailyGoalTwd = _.sumBy(ds, "dailyGoalTwd");
      return {
        date,
        netIncreasedRevTwd,
        dailyGoalTwd,
        networkId: -1,
        networkName: "Others",
        publisherId: -1,
        publisherName: "Others",
      };
    })
    .value();

  topNetworksDataSeries.push({
    networkId: -1,
    index: topNetworksDataSeries.length + 1,
    data: othersData,
  });

  return topNetworksDataSeries;
}

function _calculateDataByNetwork(reports) {
  let data = _.mapValues(reports, (rs) => {
    const rows = _.values(rs);
    const first = _.first(rows);
    const { networkId, networkName, publisherId, publisherName } = first;

    const netIncreasedRev = _.sumBy(rows, "netIncreasedRevTwd");
    const dailyGoal = _.sumBy(rows, "dailyGoalTwd");

    const goalReachedPercentage = _.round(
      (netIncreasedRev / dailyGoal) * 100,
      2
    );

    return {
      networkId,
      networkName,
      publisherId,
      publisherName,
      netIncreasedRev,
      dailyGoal,
      goalReachedPercentage: _.isFinite(goalReachedPercentage)
        ? goalReachedPercentage
        : 0,
    };
  });
  data = _.values(data);
  data = _.orderBy(data, ["netIncreasedRev"], ["desc"]);

  return data;
}

function _calculatePlatformOverviewData({ data }) {
  // 1. flatten everything
  // 1. group by date
  // 2. sum up metrics
  // date, netIncRevTwd, dailyGoalTwd
  let allData = [];
  _.forEach(data, (networkData) => {
    _.forEach(networkData, (dateData) => {
      allData.push(dateData);
    });
  });

  const today = moment().utc().format(DATE_FORMAT);
  const yesterday = moment().utc().subtract(1, "day").format(DATE_FORMAT);
  let totalNetIncreasedRev = 0;
  let totalDailyGoal = 0;
  const overviewData = _(allData)
    .groupBy("date")
    .mapValues((ds, date) => {
      const netIncreasedRev = _.sumBy(ds, "netIncreasedRevTwd");
      const dailyGoal = _.sumBy(ds, "dailyGoalTwd");
      totalNetIncreasedRev += netIncreasedRev;
      totalDailyGoal += dailyGoal;
      const goalReachedPercentage = _.round(
        (netIncreasedRev / dailyGoal) * 100,
        2
      );
      const isToday = date === today;
      const isYesterday = date === yesterday;
      return {
        date,
        isToday,
        isYesterday,
        netIncreasedRev,
        dailyGoal,
        goalReachedPercentage: _.isFinite(goalReachedPercentage)
          ? goalReachedPercentage
          : 0,
      };
    })
    .values()
    .value();

  return { overviewData, totalNetIncreasedRev, totalDailyGoal };
}

function _getDateRangeFromDateRangeType(dateRangeType) {
  let startDate = null;
  let endDate = null; // no need to use endDate for now

  switch (dateRangeType) {
    case DATE_RANGE_TYPE.THIS_MONTH: {
      // Show last month if currently on the first week
      const today = moment();
      const dayOfMonth = today.date();
      if (dayOfMonth < 7) {
        startDate = today
          .clone()
          .subtract(1, "month")
          .startOf("month")
          .format(DATE_FORMAT);
      } else {
        startDate = today.clone().startOf("month").format(DATE_FORMAT);
      }

      break;
    }

    case DATE_RANGE_TYPE.LAST_MONTH: {
      // Show last month if currently on the first week
      const today = moment();
      startDate = today
        .clone()
        .subtract(1, "month")
        .startOf("month")
        .format(DATE_FORMAT);
      endDate = today.clone().startOf("month").format(DATE_FORMAT);

      break;
    }

    case DATE_RANGE_TYPE.PAST_3_MONTHS: {
      const today = moment();
      startDate = today
        .clone()
        .subtract(3, "month")
        .startOf("month")
        .format(DATE_FORMAT);

      break;
    }

    default: {
      // do nothing
    }
  }

  return {
    startDate,
    endDate,
  };
}

function _getNetworkInfoFromReports(networkId, reports) {
  if (!networkId) return;
  const rows = _.toArray(reports[networkId]);
  const row = _.first(rows);
  const { networkName, publisherId, publisherName } = row;
  return { networkId, networkName, publisherId, publisherName };
}

export default BizViewer;
