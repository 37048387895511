import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import { DashboardAPI } from "apis";
import LoadingUI from "../../common/LoadingUI";
import NetworkRevTable from "./NetworkRevTable";

class NetworkRevWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,

      networkReports: null,
      yesterdayTotalReports: null,
      mtdTotalReports: null,
    };

    this.queryAndUpdateUI = this.queryAndUpdateUI.bind(this);
    this.queryData = this.queryData.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    await this.queryAndUpdateUI();
    this.setState({ isLoading: false });
  }

  async queryAndUpdateUI(params) {
    try {
      const { networkReports, yesterdayTotalReports, mtdTotalReports } =
        await this.queryData(params);

      if (_.isEmpty(networkReports)) {
        this.setState({ errMsg: "No data found" });
      }

      this.setState({
        networkReports,
        yesterdayTotalReports,
        mtdTotalReports,
      });
    } catch (err) {
      console.log("Error querying business network revenue report", err);
    }
  }

  transformToNetworkData(reports, networkInfo) {
    const reportsByNetwork = _.groupBy(reports, "networkId");
    // loop through network
    // 1. get yesterday's data
    // 2. get month to date data

    const yesterdayDate = moment()
      .utc()
      .subtract(1, "day")
      .format("YYYY-MM-DD");
    const currentMonth = yesterdayDate.substr(0, 7);

    let finalReports = [];
    let yesterdayTotalReports = {
      date: yesterdayDate,
      revenue: 0,
      netIncreasedRev: 0,
    };
    let mtdTotalReports = {
      month: currentMonth,
      revenue: 0,
      netIncreasedRev: 0,
    };
    _.forEach(networkInfo, (network, networkId) => {
      let finalReport = { ...network };
      let yesterdayData = {
        billableRev: 0,
        potentialRev: 0,
        revenue: 0,
        netIncreasedRev: 0,
      };
      let mtdData = {
        billableRev: 0,
        potentialRev: 0,
        revenue: 0,
        netIncreasedRev: 0,
      };

      const networkReports = reportsByNetwork[networkId];

      const yd = _.find(networkReports, { date: yesterdayDate });
      if (yd) {
        yesterdayData.billableRev = yd.billableRev;
        yesterdayData.potentialRev = yd.potentialRev;
        yesterdayData.revenue = yd.billableRev + yd.potentialRev;
        yesterdayData.netIncreasedRev = yd.netIncreasedRev;

        yesterdayTotalReports.revenue += yesterdayData.revenue;
        yesterdayTotalReports.netIncreasedRev += yesterdayData.netIncreasedRev;
      }

      _.forEach(networkReports, (r) => {
        if (r.date.substr(0, 7) === currentMonth) {
          mtdData.billableRev += r.billableRev;
          mtdData.potentialRev += r.potentialRev;
          mtdData.netIncreasedRev += r.netIncreasedRev;
          mtdData.revenue += r.billableRev + r.potentialRev;

          mtdTotalReports.revenue += r.billableRev + r.potentialRev;
          mtdTotalReports.netIncreasedRev += r.netIncreasedRev;
        }
      });

      finalReport.yesBillableRev = yesterdayData.billableRev;
      finalReport.yesPotentialRev = yesterdayData.potentialRev;
      finalReport.yesRevenue = yesterdayData.revenue;
      finalReport.yesNetIncreasedRev = yesterdayData.netIncreasedRev;

      finalReport.mtdBillableRev = mtdData.billableRev;
      finalReport.mtdPotentialRev = mtdData.potentialRev;
      finalReport.mtdRevenue = mtdData.revenue;
      finalReport.mtdNetIncreasedRev = mtdData.netIncreasedRev;

      finalReports.push(finalReport);
    });

    console.log(finalReports, yesterdayTotalReports, mtdTotalReports);
    return {
      networkReports: finalReports,
      yesterdayTotalReports,
      mtdTotalReports,
    };
  }

  async queryData(params) {
    const { networkInfo, reports } =
      await DashboardAPI.getBusinessNetworkRevenue(params);
    const { networkReports, yesterdayTotalReports, mtdTotalReports } =
      this.transformToNetworkData(reports, networkInfo);
    return {
      networkReports,
      yesterdayTotalReports,
      mtdTotalReports,
    };
  }

  render() {
    const { networkReports, yesterdayTotalReports, mtdTotalReports } =
      this.state;

    return (
      <>
        {this.state.isLoading ? (
          <LoadingUI></LoadingUI>
        ) : (
          <>
            {this.state.errMsg ? (
              <div>{this.state.errMsg}</div>
            ) : (
              <NetworkRevTable
                networkReports={networkReports}
                yesterdayTotalReports={yesterdayTotalReports}
                mtdTotalReports={mtdTotalReports}
              ></NetworkRevTable>
            )}
          </>
        )}
      </>
    );
  }
}

export default NetworkRevWrapper;
