import _ from "lodash";

import { api, fetchRespJson } from "apis/base";

export default {
  // common
  getGeoCountries,
  getCountryPresets,
};

async function getGeoCountries() {
  try {
    let apiUrl = `${api}/geo-countries`;
    return await fetchRespJson(apiUrl);
  } catch (err) {
    console.log(err);
    throw err;
  }
}

async function getCountryPresets() {
  try {
    let apiUrl = `${api}/country-presets`;
    return await fetchRespJson(apiUrl);
  } catch (err) {
    console.log(err);
    throw err;
  }
}
