import React from "react";
import _ from "lodash";
import moment from "moment-timezone";

function ManagedRatioChangedAlert({ networkInfo }) {
  // best be changed sometime around network timezone's midnight

  const { timezone } = networkInfo;
  const currentTime = moment().tz(timezone);

  const mDate = moment(currentTime);
  // const date = mDate.format("YYYY-MM-DD");
  const time = mDate.format("HH:mm");
  const hour = mDate.hour();
  // const weekday = mDate.format("ddd");

  const hoursLeft = 24 - hour - 1;

  return (
    <div className="my-1 bg-red-200 p-2 text-sm text-red-900">
      Managed ratio is best changed around network timezone's midnight. (It is
      currently {time}, about {hoursLeft} hours until midnight)
    </div>
  );
}

export default ManagedRatioChangedAlert;
