import _ from "lodash";
import { api, fetchRespJson, postData } from "./base";

export default {
  // cst
  getCstReport,
  getGodviewConfidenceReport,
};

// networkId, unitId, uprSegmentId
async function getGodviewConfidenceReport(queryParams) {
  let queryString = "";
  const qs = _.map(queryParams, (value, key) => {
    return `${key}=${value}`;
  });
  queryString = `?${qs.join("&")}`;
  const apiUrl = `${api}/cst/godview-confidence-report${queryString}`;

  return await fetchRespJson(apiUrl);
}

async function getCstReport(params) {
  const apiUrl = `${api}/cst/insight-report`;
  console.log(params);
  return await postData(apiUrl, params);
}
