export const UNIT_STATE = {
  0: "Unknown",
  1: "Wait for initialization",
  2: "Inspection",
  3: "Normal optimization",
  4: "Suspicious optimization",
  5: "Pause optimization",
  6: "Hibernation",
  7: "Debug",
  8: "Detention",
  9: "Negative optimization",
};
