import React from "react";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import { FiInfo, FiChevronDown } from "react-icons/fi";
import ConfigValueControl from "components/control-center/auto-pilot/config/ConfigValueControl";

class FeaturesConfigItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      shouldShowAdvanced: false,
    };

    this.showAdvanced = this.showAdvanced.bind(this);
  }

  showAdvanced() {
    this.setState({
      shouldShowAdvanced: true,
    });
  }

  render() {
    const {
      // sectionKey,
      unitId,
      configSchemas,
      sectionTitle,
      sectionConfigKey,
      currentEditingKey,
      handleStartEditMode,
      handleCancelEditMode,
      handleApplyChanges,
      newConfig,
    } = this.props;

    if (!configSchemas) {
      return "";
    }

    // GROUP BY CATEGORY
    let groupedCategorySchemas = _.groupBy(configSchemas, (s) => {
      return s.groupCategory;
    });

    return (
      <>
        <div>
          {/* <div className="mb-3">
            <div className="text-2xl text-gray-800 font-extrabold leading-tight">
              {sectionTitle}
            </div>
          </div> */}

          <div className="mb-4 flex flex-wrap items-center text-sm">
            <div className="mr-1 font-semibold text-gray-700">Jump to: </div>
            {_.keys(groupedCategorySchemas).map((groupCategory) => {
              return (
                <button
                  key={groupCategory}
                  type="button"
                  className="border rounded mb-1 mr-1 border-gray-200 px-2 py-1 text-gray-800 hover:border-blue-400"
                  onClick={() => {
                    document.getElementById(groupCategory).scrollIntoView();
                  }}
                >
                  {groupCategory}
                </button>
              );
            })}
          </div>

          {_.keys(groupedCategorySchemas).map((category) => {
            const configSchemas = groupedCategorySchemas[category];

            return (
              <div key={category} id={category} className="mb-12">
                {category !== "undefined" && (
                  <div className="border -ml-8 border-blue-200 bg-blue-100 pt-4 pb-2 pl-8 text-xl font-semibold text-blue-800">
                    {`${category} Settings`}
                  </div>
                )}

                <div className="border -ml-8 bg-white pl-8">
                  {configSchemas.map((s) => {
                    // sectionConfigKey could be ""
                    // use s.prefix!! (different than autopilot)
                    const sectionConfigKey = s.prefix;
                    const configValue = sectionConfigKey
                      ? _.get(newConfig, [sectionConfigKey, s.key])
                      : _.get(newConfig, [s.key]);
                    const configKeyTitle = _.startCase(s.key);

                    return (
                      <div
                        key={s.key}
                        className="border-b -ml-8 flex border-gray-400 py-6 pl-8"
                      >
                        <div className="w-1/3">
                          <div
                            className={`break-words pr-8 text-sm font-semibold ${
                              s.isDeprecated ? "text-orange-700" : ""
                            }`}
                          >
                            {s.title ? s.title : configKeyTitle}:
                          </div>
                          <div className="text-xs text-gray-600">
                            {s.isUnitOnly ? "(Unit level only)" : ""}
                          </div>
                          <div className="mt-2 text-xs text-blue-600">
                            <FiInfo
                              data-tip
                              data-for={`apconfig-description-${s.key}`}
                            ></FiInfo>
                          </div>

                          <ReactTooltip
                            id={`apconfig-description-${s.key}`}
                            type="dark"
                            effect="solid"
                            place="right"
                          >
                            <div style={{ maxWidth: "300px" }}>
                              <div>
                                <b>{configKeyTitle}:</b>
                              </div>
                              {s.description}
                            </div>
                          </ReactTooltip>
                        </div>

                        <div
                          className={`-my-6 w-2/3 bg-gray-200 py-6 ${
                            currentEditingKey === s.key
                              ? "border-2 border-blue-600"
                              : ""
                          }`}
                        >
                          <ConfigValueControl
                            // unitId={unitId}
                            sectionConfigKey={sectionConfigKey}
                            configValue={configValue}
                            valueSchema={s}
                            currentEditingKey={currentEditingKey}
                            handleStartEditMode={handleStartEditMode}
                            handleCancelEditMode={handleCancelEditMode}
                            handleApplyChanges={handleApplyChanges}
                          ></ConfigValueControl>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );

            // return "";
          })}
        </div>
      </>
    );
  }
}

export default FeaturesConfigItem;
