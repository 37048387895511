import React from "react";
import _ from "lodash";
import Select from "react-select";
import { NetworkAPI } from "apis";

class PriceTargetingSelector extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      // options,
      // selectedValue,

      isLoading: false,
      errMsg: null,
    };

    this.handleChange = this.handleChange.bind(this);
    // this.handleInputChange = this.handleInputChange.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      const { networkId, selectedItems } = this.props;
      if (!networkId) {
        throw new Error("Missing Network ID");
      }

      const { sizes } = await NetworkAPI.getExtGamUprPriceTargetingCandidates({
        networkId,
      });

      const options = _.map(sizes, (size) => {
        return {
          value: size,
          label: size,
          isNotInOptions: false,
        };
      });

      const { errMsg, selectedValue } = this._fillSelectedOptions({
        selectedItems,
        options,
      });

      this.setState({
        options,
        selectedValue, // array of options
        isLoading: false,
        errMsg,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        errMsg: err.toString(),
      });
    }
  }

  _fillSelectedOptions({ selectedItems, options }) {
    let selectedValue = [];
    if (selectedItems) {
      selectedValue = _.filter(options, (option) => {
        return _.indexOf(selectedItems, option.value) !== -1;
      });

      if (selectedValue.length !== selectedItems.length) {
        const invalidIds = _.difference(
          selectedItems,
          _.map(selectedValue, "value")
        );
        const notSuggestedItems = _.map(invalidIds, (id) => {
          return {
            value: id,
            label: id,
            isNotInOptions: true,
          };
        });
        selectedValue = _.concat(notSuggestedItems, selectedValue);
        // const errMsg = `Failed to find these items: ${invalidIds.join(", ")}`;
        // this.props.handleError && this.props.handleError(errMsg);
        return { errMsg: null, selectedValue };
      } else {
        return { errMsg: null, selectedValue };
      }
    }
  }

  // handleInputChange(input) {
  //   if (_.isEmpty(input)) return;
  //   // Check if there is seperator
  //   // user can paste a list of unit ids
  //   if (input.includes(",")) {
  //     let parts = input.split(",");
  //     parts = _.map(_.compact(parts), _.trim);
  //     if (parts.length > 0) {
  //       // check if this is a list of unitIds
  //       const selectedValue = _.filter(this.state.options, (option) => {
  //         return _.indexOf(parts, option.unitId) !== -1;
  //       });

  //       if (selectedValue.length > 0) {
  //         const newValues = _.uniqBy(
  //           [...(this.state.selectedValue || []), ...selectedValue],
  //           "unitId"
  //         );
  //         this.handleChange(newValues);
  //         return "";
  //       }

  //       // otherwise could be extGamUnitId
  //       const selectedValue2 = _.filter(this.state.options, (option) => {
  //         return _.indexOf(parts, option.value) !== -1;
  //       });

  //       if (selectedValue2.length > 0) {
  //         const newValues2 = _.uniqBy(
  //           [...this.state.selectedValue, ...selectedValue2],
  //           "value"
  //         );
  //         this.handleChange(newValues2);
  //         return "";
  //       }
  //     }
  //   }

  //   return input;
  // }

  handleChange(options) {
    this.setState({
      selectedValue: options,
    });

    this.props.handleChanged(_.map(options, "value"));
  }

  render() {
    const { options, selectedValue, isLoading, errMsg } = this.state;
    const { disabled = false } = this.props;

    const styles = {
      multiValue: (base, state) => {
        return state.data.isNotInOptions
          ? { ...base, backgroundColor: "gray" }
          : base;
      },
      multiValueLabel: (base, state) => {
        return state.data.isNotInOptions
          ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
          : base;
      },
    };

    return (
      <>
        <Select
          defaultValue={selectedValue}
          value={selectedValue}
          onChange={this.handleChange}
          // onInputChange={this.handleInputChange}
          options={options}
          isSearchable={true}
          isMulti={true}
          isDisabled={disabled}
          isLoading={isLoading}
          styles={{
            control: (baseStyles, state) => ({
              // state.isFocused
              ...baseStyles,
              borderColor: errMsg ? "red" : "lightgray",
            }),
            ...styles,
          }}
        />

        {errMsg && <div className="text-xs text-red-600">{errMsg}</div>}
      </>
    );
  }
}

export default PriceTargetingSelector;
