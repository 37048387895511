import React from "react";
import _ from "lodash";
import Select from "react-select";

import {
  PRESETS,
  DEMAND_TYPES,
  getGoogleEligibleDemandTypes,
  getPrebidDemandTypes,
  getAllDemandTypes,
  getGuarenteedDemandTypes,
} from "constants/DemandType";

class AnatomyDemandTypeFilter extends React.Component {
  constructor(props) {
    super(props);

    const { demandTypePreset, demandTypes } = this.props;

    this.state = {
      selectedPreset: demandTypePreset,
      demandTypes,
    };

    this.handlePresetSelected = this.handlePresetSelected.bind(this);
    this.handleDemandTypesChanged = this.handleDemandTypesChanged.bind(this);
    this.handleRecalculate = this.handleRecalculate.bind(this);
  }

  handlePresetSelected(preset) {
    if (preset === PRESETS.CUSTOM) {
      this.setState({ selectedPreset: preset });
      // this.props.handleDemandTypesChanged(preset);
      return;
    }

    let demandTypes = [];
    if (preset === PRESETS.GOOGLE_ELIGIBLE) {
      demandTypes = getGoogleEligibleDemandTypes();
    } else if (preset === PRESETS.NETWORK_BILLABLE) {
      demandTypes = this.props.billableDemandTypes;
    } else if (preset === PRESETS.PREBID) {
      demandTypes = getPrebidDemandTypes();
    } else if (preset === PRESETS.BILLABLE_HOUSE_ONLY) {
      demandTypes = ["Billable House"];
    } else if (preset === PRESETS.PREBID_WITHOUT_BILLABLE_HOUSE) {
      demandTypes = _.filter(getPrebidDemandTypes(), (t) => {
        return t !== "Billable House";
      });
    } else if (preset === PRESETS.GUARENTEED) {
      demandTypes = getGuarenteedDemandTypes();
    } else if (preset === PRESETS.ALL) {
      demandTypes = getAllDemandTypes();
    }

    this.setState({ selectedPreset: preset, demandTypes });
    this.props.handleDemandTypesChanged(preset, demandTypes);
  }

  handleDemandTypesChanged(demandTypes) {
    this.setState({ demandTypes });
  }

  handleRecalculate() {
    const preset = this.state.selectedPreset;
    const demandTypes = this.state.demandTypes;
    this.props.handleDemandTypesChanged(preset, demandTypes);
  }

  render() {
    const { billableDemandTypes } = this.props;
    const { selectedPreset, demandTypes } = this.state;

    return (
      <div className="p-4">
        <div className="mb-1 font-semibold text-gray-700">Demand Types:</div>
        <div className="mb-2 flex items-center justify-start text-sm">
          <div>
            {_.values(PRESETS).map((preset) => {
              // return <div key={preset}>{preset}</div>;

              return (
                <div key={preset} className="inline-block">
                  <button
                    disabled={selectedPreset === preset}
                    type="button"
                    className={`rounded mr-1 px-4 py-1 font-semibold text-gray-700 ${
                      selectedPreset === preset
                        ? "bg-blue-200"
                        : "bg-gray-100 hover:bg-gray-200 hover:text-gray-800"
                    }`}
                    onClick={() => this.handlePresetSelected(preset)}
                    data-tip
                    data-for={`"preset-${preset}`}
                  >
                    {preset}
                  </button>

                  {/* {preset === PRESETS.NETWORK_BILLABLE && (
                    <ReactTooltip
                      id={`"preset-${PRESETS.NETWORK_BILLABLE}`}
                      type="dark"
                      effect="solid"
                      place="top"
                    >
                      {billableDemandTypes && billableDemandTypes.join(", ")}
                    </ReactTooltip>
                  )} */}
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex items-center gap-4">
          <div className="w-3/4">
            <DemandTypesSelector
              selectedPreset={selectedPreset}
              selectedDemandTypes={demandTypes}
              isDisabled={selectedPreset !== PRESETS.CUSTOM}
              handleDemandTypesChanged={this.handleDemandTypesChanged}
            ></DemandTypesSelector>
          </div>
          <div className="w-1/3">
            {selectedPreset === PRESETS.CUSTOM && (
              <button
                type="button"
                onClick={this.handleRecalculate}
                className="rounded mr-1 bg-gray-300 px-4 py-2 font-semibold text-gray-900 hover:bg-gray-400 hover:text-gray-800"
              >
                Recalculate
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

class DemandTypesSelector extends React.Component {
  constructor(props) {
    super(props);

    const typeOptions = _.values(DEMAND_TYPES);
    const options = _.map(typeOptions, (c) => {
      return { value: c, label: c };
    });

    const selectedValue = _.filter(options, (option) => {
      return _.indexOf(props.selectedDemandTypes, option.value) !== -1;
    });

    this.state = {
      options,
      selectedValue, // multi
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedPreset !== this.props.selectedPreset) {
      const selectedValue = _.filter(this.state.options, (option) => {
        return _.indexOf(this.props.selectedDemandTypes, option.value) !== -1;
      });

      this.setState({ selectedValue });
    }
  }

  handleChange(option) {
    this.setState({
      selectedValue: option,
    });

    const types = _.map(option, (o) => {
      return o.value;
    });
    this.props.handleDemandTypesChanged(types);
  }

  render() {
    const { options, selectedValue } = this.state;

    return (
      <>
        {/* <div className="inline-flex items-center px-4 py-2 border border-gray-400 rounded hover:shadow text-sm text-gray-700 bg-white cursor-pointer focus:outline-none">
            <span className="mr-2">USD</span>
            <IoIosArrowDown></IoIosArrowDown>
          </div> */}
        {/* {JSON.stringify(selectedValue)} */}
        <Select
          // className="w-24 z-50"
          // defaultValue={selectedValue}
          value={selectedValue}
          onChange={this.handleChange}
          options={options}
          // isSearchable={false}
          isMulti={true}
          isDisabled={this.props.isDisabled}
          isClearable={!this.props.isDisabled}
        />
      </>
    );
  }
}

export default AnatomyDemandTypeFilter;
