import React from "react";
import _ from "lodash";
import CurrencySelector from "components/common/CurrencySelector";

const labelClass = "font-semibold text-gray-800";
const radioClass = "font-semibold text-sm text-gray-900 cursor-pointer";

const inputClass =
  "bg-white flex border border-gray-400 rounded py-1 px-3 appearance-none leading-normal focus:outline-none focus:border-blue-400 focus:shadow-inner hover:border-gray-500";
const inputClassFakeDisabled =
  "bg-gray-200 flex border border-gray-400 rounded py-1 px-3 appearance-none leading-normal";

const PRICING_TYPE_NAME = {
  TIERED: 0,
  FLAT: 1,
  REV_SHARE: 2,
};

const PRICING_TYPE = {
  0: "Tiered Rev Share",
  1: "Flat Fee",
  2: "Rev Share with Cost Compensation",
};

const RATE_TYPE = {
  FIXED: "fixed",
  TIERED: "tiered",
};

class PricingDefaultConfigForm extends React.Component {
  constructor(props) {
    super(props);

    const { name = "", pricingType = 0, config = {} } = this.props.config || {};

    const {
      currency = "",
      // optional
      rate_type = "", // pricingType 0: tiered
      pricing_tiers = [], // pricingType 0: tiered
      refund_rate = "", // pricingType 0: tiered, optional
      fee = "", // 1: flat fee
      rev_share_rate = "", // 2: revShare
      cost_share_rate = "", // 2: revShare
    } = config;

    this.state = {
      name,
      pricingType,
      currency,
      rate_type,
      pricing_tiers,
      refund_rate,
      fee,
      rev_share_rate,
      cost_share_rate,

      isInvalid: false,
    };

    this.onInputChanged = this.onInputChanged.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleTiersChanged = this.handleTiersChanged.bind(this);

    this.handleBack = this.handleBack.bind(this);
    this.handelCancel = this.handleCancel.bind(this);
  }

  handleBack() {
    this.props.handlePrevStep();
  }

  handleCancel() {
    this.props.handleCancel();
  }

  onInputChanged(value, key) {
    this.setState({ [key]: value });
  }

  handleTiersChanged(newTiers) {
    this._validateTiers(newTiers);
  }

  _validateTiers(tiers) {
    let newTiers = _.clone(tiers);
    let isInvalid = false;

    _.forEach(newTiers, (tier, index) => {
      let lastTier = newTiers[index - 1];
      tier._error = null; // always revalidate all errors

      const isFirst = index === 0;
      const isLast = index === newTiers.length - 1;

      if (tier.lowerBound < 0) {
        _.set(
          tier,
          ["_error", "lowerBound"],
          "Lower bound should be larger than 0"
        );
        isInvalid = true;
        // return false;
      }

      if (tier.upperBound < 0) {
        _.set(
          tier,
          ["_error", "upperBound"],
          "Upper bound should be larger than 0"
        );
        isInvalid = true;
        // return false;
      }

      if (!isLast && tier.upperBound < tier.lowerBound) {
        _.set(
          tier,
          ["_error", "upperBound"],
          "Upper bound should be larger than lower bound"
        );
        isInvalid = true;
        // return false;
      }

      if (tier.rate < 0) {
        _.set(tier, ["_error", "rate"], "Rate should be larger or equal to 0");
        isInvalid = true;
      }

      if (isFirst) {
        if (tier.lowerBound === "" || tier.lowerBound === null) {
        } else {
          _.set(tier, ["_error", "lowerBound"], "Lower bound should be empty");
          isInvalid = true;
          // return false;
        }
      }

      if (isLast) {
        if (tier.upperBound === "" || tier.upperBound === null) {
        } else {
          _.set(tier, ["_error", "upperBound"], "Upper bound should be empty");
          isInvalid = true;
          // return false;
        }
      }

      if (lastTier) {
        if (tier.lowerBound === lastTier.upperBound) {
        } else {
          _.set(
            tier,
            ["_error", "lowerBound"],
            `Lower bound should be the same as last tier's upper bound: ${lastTier.upperBound}`
          );
          isInvalid = true;
          // return false;
        }
      }
    });

    this.setState({ pricing_tiers: newTiers, isInvalid });
  }

  async handleSave() {
    const {
      name,
      pricingType,
      currency,
      rate_type,
      pricing_tiers,
      refund_rate,
      fee,
      rev_share_rate,
      cost_share_rate,
    } = this.state;

    let config = {};
    config.currency = currency;

    // validate
    if (name === "") {
      this.setState({ errMsg: "Name is required" });
      return;
    }
    if (currency === "" || currency === null || currency === undefined) {
      this.setState({ errMsg: "Currency is required" });
      return;
    }

    if (pricingType === PRICING_TYPE_NAME.TIERED) {
      if (rate_type === "" || rate_type === null || rate_type === undefined) {
        this.setState({ errMsg: "Rate Type is required" });
        return;
      }

      if (
        pricing_tiers === "" ||
        pricing_tiers === null ||
        pricing_tiers === undefined ||
        pricing_tiers.length === 0
      ) {
        this.setState({ errMsg: "Pricing Tiers is required" });
        return;
      }

      config.rate_type = rate_type;
      if (
        refund_rate === null ||
        refund_rate === undefined ||
        refund_rate === ""
      ) {
        //
      } else {
        config.refund_rate = parseFloat(refund_rate);
      }

      config.pricing_tiers = pricing_tiers; //
    }

    if (pricingType === PRICING_TYPE_NAME.FLAT) {
      if (fee === "" || fee === null || fee === undefined) {
        this.setState({ errMsg: "Fee is required" });
        return;
      }

      config.fee = parseFloat(fee);
    }

    if (pricingType === PRICING_TYPE_NAME.REV_SHARE) {
      if (
        rev_share_rate === "" ||
        rev_share_rate === null ||
        rev_share_rate === undefined
      ) {
        this.setState({ errMsg: "Rev Share Rate is required" });
        return;
      }

      if (
        cost_share_rate === "" ||
        cost_share_rate === null ||
        cost_share_rate === undefined
      ) {
        this.setState({ errMsg: "Cost Share Rate is required" });
        return;
      }
      config.rev_share_rate = parseFloat(rev_share_rate);
      config.cost_share_rate = parseFloat(cost_share_rate);
    }

    config.pricing_tiers = _.map(pricing_tiers, (tier) => {
      if (tier.upperBound === "") tier.upperBound = null;
      if (tier.lowerBound === "") tier.lowerBound = null;
      return _.omit(tier, "_error");
    });

    this.setState({ errMsg: null });
    const params = {
      pricingType,
      name,
      config,
    };

    console.log("saving", params);
    this.props.handleCreatePricingConfig(params);
  }

  render() {
    const {
      name,
      pricingType,
      currency,
      rate_type,
      pricing_tiers,
      refund_rate,
      fee,
      rev_share_rate,
      cost_share_rate,

      //
      errMsg,
      isInvalid,
    } = this.state;
    const { apiErrorMsg } = this.props;

    return (
      <div className="border shadow rounded mb-4 bg-gray-100 p-4">
        <div>
          <div className="mb-4">
            <label className={labelClass}>Name*</label>
            <input
              type="text"
              className={inputClass + " w-full"}
              value={name}
              onChange={(e) => this.onInputChanged(e.target.value, "name")}
              required
            ></input>
          </div>

          <div className="mb-4">
            <label className={labelClass}>Pricing Type*</label>

            <div className="flex items-center gap-4 py-1">
              {_.keys(PRICING_TYPE).map((t) => {
                const name = PRICING_TYPE[t];
                const type = _.parseInt(t);
                return (
                  <label
                    key={type}
                    htmlFor={`pricing_type_${type}`}
                    className={radioClass}
                  >
                    <input
                      id={`pricing_type_${type}`}
                      type="radio"
                      name={`pricing_type`}
                      value={type}
                      checked={pricingType === type}
                      onChange={(e) => this.onInputChanged(type, "pricingType")}
                    />{" "}
                    {name}
                  </label>
                );
              })}
            </div>
          </div>

          <div className="mb-4">
            <label className={labelClass}>Currency*</label>
            <CurrencySelector
              selectedCurrency={currency}
              handleCurrencyChanged={(c) => this.onInputChanged(c, "currency")}
            ></CurrencySelector>
          </div>

          {pricingType === PRICING_TYPE_NAME.TIERED && (
            <>
              <div className="mb-4">
                <label className={labelClass}>Rate Type*</label>

                <div className="flex items-center gap-4 py-1">
                  <label htmlFor={`rate_type_fixed`} className={radioClass}>
                    <input
                      id={`rate_type_fixed`}
                      type="radio"
                      name={`rate_type`}
                      value={RATE_TYPE.FIXED}
                      checked={rate_type === RATE_TYPE.FIXED}
                      onChange={(e) =>
                        this.onInputChanged(RATE_TYPE.FIXED, "rate_type")
                      }
                    />{" "}
                    Fixed
                  </label>
                  <label htmlFor={`rate_type_tiered`} className={radioClass}>
                    <input
                      id={`rate_type_tiered`}
                      type="radio"
                      name={`rate_type`}
                      value={RATE_TYPE.TIERED}
                      checked={rate_type === RATE_TYPE.TIERED}
                      onChange={(e) =>
                        this.onInputChanged(RATE_TYPE.TIERED, "rate_type")
                      }
                    />{" "}
                    Tiered
                  </label>
                </div>
              </div>

              <div className="mb-4">
                <label className={labelClass}>Refund Rate (%)</label>
                <input
                  type="number"
                  className={inputClass + " w-1/4"}
                  value={refund_rate}
                  onChange={(e) =>
                    this.onInputChanged(e.target.value, "refund_rate")
                  }
                  required
                ></input>
              </div>

              <div className="mb-4">
                <label className={labelClass}>Pricing Tiers</label>
                <PricingTiersControl
                  tiers={pricing_tiers}
                  handleTiersChanged={this.handleTiersChanged}
                ></PricingTiersControl>
              </div>
            </>
          )}

          {pricingType === PRICING_TYPE_NAME.FLAT && (
            <div className="mb-4">
              <label className={labelClass}>Fee*</label>
              <input
                type="number"
                className={inputClass + " w-1/4"}
                value={fee}
                onChange={(e) => this.onInputChanged(e.target.value, "fee")}
                required
              ></input>
            </div>
          )}

          {pricingType === PRICING_TYPE_NAME.REV_SHARE && (
            <>
              <div className="mb-4">
                <label className={labelClass}>Rev Share Rate (%)*</label>
                <input
                  type="number"
                  className={inputClass + " w-1/4"}
                  value={rev_share_rate}
                  onChange={(e) =>
                    this.onInputChanged(e.target.value, "rev_share_rate")
                  }
                  required
                ></input>
              </div>
              <div className="mb-4">
                <label className={labelClass}>Cost Share Rate (%)*</label>
                <input
                  type="number"
                  className={inputClass + " w-1/4"}
                  value={cost_share_rate}
                  onChange={(e) =>
                    this.onInputChanged(e.target.value, "cost_share_rate")
                  }
                  required
                ></input>
              </div>
            </>
          )}
        </div>
        {errMsg && (
          <div className="my-1 bg-red-200 px-4 py-2 font-semibold text-red-700">
            {errMsg}
          </div>
        )}
        {apiErrorMsg && (
          <div className="my-1 bg-red-200 px-4 py-2 font-semibold text-red-700">
            {apiErrorMsg}
          </div>
        )}
        <div className="mt-4 flex flex-row items-center justify-end gap-2">
          <button
            type="button"
            disabled={false}
            className="px-4 py-2 text-blue-700"
            onClick={this.props.handleCancel}
          >
            Cancel
          </button>
          <button
            type="button"
            disabled={false}
            className="rounded shadow bg-blue-500 px-4 py-2 font-semibold text-white hover:bg-blue-700"
            onClick={this.handleBack}
          >
            Back
          </button>
          <div>
            <button
              type="button"
              className={`px-4 py-2 ${
                isInvalid
                  ? "bg-gray-400 text-gray-800"
                  : "bg-blue-500 text-white hover:bg-blue-700"
              }  rounded shadow font-semibold`}
              onClick={this.handleSave}
              disabled={isInvalid}
            >
              Create pricing config
            </button>
          </div>
        </div>
      </div>
    );
  }
}

class PricingTiersControl extends React.PureComponent {
  constructor(props) {
    super(props);

    const { tiers = [] } = props;

    this.state = {
      tiers,
    };

    this.handleTierChanged = this.handleTierChanged.bind(this);
    this.handleRemoveTier = this.handleRemoveTier.bind(this);
    this.handleAddTier = this.handleAddTier.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tiers !== this.props.tiers) {
      this.setState({ tiers: this.props.tiers });
    }
  }

  handleTierChanged(newTier, index) {
    const { tiers } = this.state;
    let newTiers = _.clone(tiers);
    newTiers[index] = newTier;
    this.setState({ tiers: newTiers });

    this.props.handleTiersChanged(newTiers);
  }

  handleRemoveTier(index) {
    const { tiers } = this.state;
    let newTiers = _.clone(tiers);
    newTiers.splice(index, 1);
    this.setState({ tiers: newTiers });

    this.props.handleTiersChanged(newTiers);
  }

  handleAddTier() {
    // add new empty tier at the end
    const { tiers } = this.state;
    let newTiers = [
      ...tiers,
      {
        lowerBound: "",
        upperBound: "",
        rate: "",
      },
    ];
    this.setState({ tiers: newTiers });

    this.props.handleTiersChanged(newTiers);
  }

  render() {
    const { tiers } = this.state;

    return (
      <div>
        <div className="bg-white">
          {tiers.map((t, i) => {
            let { lowerBound = "", upperBound = "", rate = "", _error } = t;
            const isFirst = i === 0;
            const isLast = i === tiers.length - 1;
            if (lowerBound === null || lowerBound === undefined)
              lowerBound = "";
            if (upperBound === null || upperBound === undefined)
              upperBound = "";
            if (rate === null || rate === undefined) rate = "";

            return (
              <PricingTierControl
                key={i}
                index={i}
                isFirst={isFirst}
                isLast={isLast}
                lowerBound={lowerBound}
                upperBound={upperBound}
                rate={rate}
                _error={_error}
                handleTierChanged={(tier) => {
                  this.handleTierChanged(tier, i);
                }}
                handleRemoveTier={this.handleRemoveTier}
              ></PricingTierControl>
            );
          })}
        </div>
        <button
          type="button"
          className="rounded shadow my-2 bg-blue-100 px-4 py-1 text-sm font-semibold text-blue-600 hover:bg-blue-200"
          onClick={this.handleAddTier}
        >
          Add Tier
        </button>
      </div>
    );
  }
}

class PricingTierControl extends React.PureComponent {
  constructor(props) {
    super(props);

    let { lowerBound = "", upperBound = "", rate = "" } = this.props;

    this.state = {
      lowerBound,
      upperBound,
      rate,
    };

    this.onInputChanged = this.onInputChanged.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.index !== this.props.index ||
      prevProps.lowerBound !== this.props.lowerBound ||
      prevProps.upperBound !== this.props.upperBound ||
      prevProps.rate !== this.props.rate
    ) {
      const { lowerBound = "", upperBound = "", rate = "" } = this.props;
      this.setState({
        lowerBound,
        upperBound,
        rate,
      });
    }
  }

  onInputChanged(value, key) {
    this.setState({ [key]: value });

    setTimeout(() => {
      const { lowerBound, upperBound, rate } = this.state;
      const lower = parseInt(lowerBound);
      const upper = parseInt(upperBound);
      const r = parseFloat(rate);
      let tier = {
        lowerBound: _.isNaN(lower) ? "" : lower,
        upperBound: _.isNaN(upper) ? "" : upper,
        rate: _.isNaN(r) ? "" : r,
      };
      this.props.handleTierChanged(tier);
    });
  }

  handleRemove() {
    this.props.handleRemoveTier(this.props.index);
  }

  render() {
    const { lowerBound, upperBound, rate } = this.state;
    const { isFirst, isLast, _error } = this.props;
    return (
      <div>
        <div className="border flex w-full bg-white px-4 py-2 text-sm">
          <div className="w-1/4">
            <label className={labelClass}>Lower Bound {"(>)"}</label>
            <input
              type="number"
              className={isFirst ? inputClassFakeDisabled : inputClass}
              value={lowerBound}
              onChange={(e) =>
                this.onInputChanged(e.target.value, "lowerBound")
              }
              // disabled={isFirst}
            ></input>
            {_error && _error.lowerBound && (
              <div className="text-xs text-red-700">{_error.lowerBound}</div>
            )}
          </div>
          <div className="w-1/4">
            <label className={labelClass}>Upper Bound {"(<=)"}</label>
            <input
              type="number"
              className={isLast ? inputClassFakeDisabled : inputClass}
              value={upperBound}
              onChange={(e) =>
                this.onInputChanged(e.target.value, "upperBound")
              }
              // disabled={isLast}
            ></input>
            {_error && _error.upperBound && (
              <div className="text-xs text-red-700">{_error.upperBound}</div>
            )}
          </div>
          <div className="w-1/4">
            <label className={labelClass}>Rate %</label>
            <input
              type="number"
              className={inputClass}
              value={rate}
              onChange={(e) => this.onInputChanged(e.target.value, "rate")}
            ></input>

            {_error && _error.rate && (
              <div className="text-xs text-red-700">{_error.rate}</div>
            )}
          </div>
          <div className="flex w-1/4 items-center justify-end">
            <button
              type="button"
              disabled={false}
              className="px-4 py-2 font-medium text-blue-700 hover:text-blue-800"
              onClick={this.handleRemove}
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default PricingDefaultConfigForm;
