import React from "react";

class Checkbox extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange(event) {
    this.props.onChange(event.target.checked);
  }

  render() {
    const { isChecked, isDisabled } = this.props;
    return (
      <>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={this.handleOnChange}
          disabled={isDisabled || false}
        ></input>
      </>
    );
  }
}

export default Checkbox;
