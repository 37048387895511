import React from "react";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
// import MetricTooltip from "../common/MetricTooltip";
import MetricView from "../common/MetricView";
import PopupWrapper from "../common/PopupWrapper";
import DemandReportTable from "./DemandReportTable";
import DemandLayerHighchart from "./DemandLayerHighchart";
import AdSizesReportTable from "./AdSizesReportTable";
import ClickToCopyWrapper from "../common/ClickToCopyWrapper";
import LayerSizesView from "../common/LayerSizesView";
import ExperimentGroupAnchor from "./ExperimentGroupAnchor";

const catColors = {
  B: "#d4d3ff",
  O: "#D7F5E5",
  C: "#F4F1C6",
  DX: "#E8E8E8",
};

class ExperimentGroups extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // DX used to be unimportant but it seems to be back now for some reason
      // so... always show DX for now
      // 2020/05/14
      // shouldShowDx: false,
      maxLayers: 0,
    };
  }

  componentDidMount() {
    this.setState({ maxLayers: this.getMaxLayers() });
  }

  getMaxLayers() {
    // get the maximum amount of layers other than the 10 layers of "ADX_DETECTOR"

    const { groupInfo } = this.props;
    let countMaxLayer = 0;

    _.forEach(groupInfo, (g) => {
      // update: 2020/12/01 g.type === "ADX_DETECTOR" --> now means "CUSTOM_GROUP", so remove this filter
      // if (g.type === "ADX_DETECTOR") {
      // ignore
      // } else {
      let groupMaxLayers = _.keys(g.layers).length;

      if (groupMaxLayers > countMaxLayer) {
        countMaxLayer = groupMaxLayers;
      }
      // }
    });

    return countMaxLayer;
  }

  render() {
    const {
      reports,
      groupInfo,
      expId,
      data,
      scrollToExpGroup,
      segmentId,
      highlightExpGroupId,
      deeplinkExpGroupId,
    } = this.props;
    const totalWeight = _.sumBy(reports, (r) => {
      return groupInfo[r.expGroupId].weight;
    });

    return (
      <>
        <table className="text-sm font-medium overflow-x-scroll block w-full">
          <thead>
            <tr>
              <td className="border border-gray-700 text-center">Cat</td>
              <td className="border border-gray-700 text-center">
                Exp Group ID
              </td>
              <td className="border border-gray-700 text-center">Request</td>
              <td className="border border-gray-700 text-center">eCPM</td>
              <td className="border border-gray-700 text-center">NetRPM</td>
              <td className="border border-gray-700 text-center">Revenue</td>
              <td className="border border-gray-700 text-center">Cost</td>
              <td className="border border-gray-700 text-center">
                GroupParams
              </td>

              {this.state.maxLayers
                ? _.range(0, this.state.maxLayers).map((i) => {
                    return (
                      <td
                        key={i}
                        className="border border-gray-700 text-center"
                      >
                        L{i + 1}
                      </td>
                    );
                  })
                : null}
            </tr>
          </thead>
          <tbody>
            {data.hasMultiBench && (
              <tr style={{ backgroundColor: catColors["B"] }}>
                <td className="border border-gray-700 text-center">
                  <b>B</b>
                </td>

                <td className="border border-gray-700 text-center text-gray-800">
                  Final benchmark
                  {data.bm && (
                    <div className="text-gray-700">multiplier: {data.bm}</div>
                  )}
                </td>

                <td className="border border-gray-700 text-right">
                  <MetricView
                    mTitle="Req"
                    mValue={data.benchSumReq}
                    shouldFormatNumber={true}
                  ></MetricView>
                </td>

                <td className="border border-gray-700 text-right">
                  <MetricView
                    mValue={data.benchECPM}
                    shouldFormatNumber={true}
                    prefix="$"
                  ></MetricView>
                </td>

                <td className="border border-gray-700 text-right">
                  <span
                    className="font-bold"
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    <MetricView
                      mValue={data.benchNetRPM}
                      shouldFormatNumber={true}
                      prefix="$"
                    ></MetricView>
                  </span>
                  {data.bm && (
                    <>
                      <br></br>

                      <span className="text-gray-700">
                        expected:
                        <MetricView
                          mValue={data.benchExpNetRPM}
                          shouldFormatNumber={true}
                          prefix="$"
                        ></MetricView>
                      </span>
                    </>
                  )}
                </td>

                <td className="border border-gray-700 text-right">
                  <MetricView
                    mTitle="Rev"
                    mValue={data.benchSumRev}
                    shouldFormatNumber={true}
                    prefix="$"
                  ></MetricView>

                  <br />

                  <MetricView
                    mTitle="Imp"
                    mValue={data.benchSumImp}
                    shouldFormatNumber={true}
                  ></MetricView>
                </td>

                <td className="border border-gray-700 text-right">
                  <MetricView
                    mValue={data.benchSumCost}
                    shouldFormatNumber={true}
                    prefix="$"
                  ></MetricView>
                </td>

                <td className="border border-gray-700 text-right w-full"></td>
                <td
                  colSpan={this.state.maxLayers}
                  className="border border-gray-700 text-right w-full"
                ></td>
              </tr>
            )}

            {reports.map((r) => {
              const layerReports = r.reports;

              const currentUrl = window.location.href;
              const url = _.first(currentUrl.split("?"));
              const deepLinkUrl = `${url}?segmentId=${segmentId}&expGroupId=${r.expGroupId}`;

              let highlightClass = "";
              if (deeplinkExpGroupId == r.expGroupId) {
                highlightClass = "border-4 border-blue-400";
              } else if (highlightExpGroupId == r.expGroupId) {
                highlightClass = "border-4 border-orange-400";
              }
              return (
                <tr
                  key={r.expGroupId}
                  style={{ backgroundColor: catColors[r.type] }}
                  className={highlightClass}
                >
                  <td className="border border-gray-700 text-center">
                    <div
                      className={
                        deeplinkExpGroupId == r.expGroupId
                          ? "text-blue-800"
                          : ""
                      }
                    >
                      <b>{r.type}</b>

                      <ClickToCopyWrapper
                        copyText={deepLinkUrl}
                        tooltipMessage="Click to copy deep link URL"
                        iconType="LINK"
                      ></ClickToCopyWrapper>
                    </div>
                  </td>

                  <td className="border border-gray-700 text-right">
                    <div className="flex justify-center px-2">
                      {r.expGroupId}
                      <ClickToCopyWrapper
                        copyText={`${r.expGroupId}`}
                      ></ClickToCopyWrapper>
                    </div>

                    <div className="flex justify-center text-gray-800">
                      ({groupInfo[r.expGroupId].name})
                    </div>

                    <br></br>
                    <div className="flex justify-center">
                      <PopupWrapper
                        place="bottom left"
                        triggerElement={
                          <button
                            type="button"
                            className="border-b border-gray-600"
                          >
                            AdX AdSizes
                          </button>
                        }
                        popupElement={
                          <AdSizesReportTable
                            reports={r.adSizesReports}
                          ></AdSizesReportTable>
                        }
                      ></PopupWrapper>
                    </div>
                  </td>

                  <td className="border border-gray-700 text-right">
                    <MetricView
                      mTitle="Req"
                      mValue={r.firstLayerRequest}
                      shouldFormatNumber={true}
                    ></MetricView>

                    <br />

                    <MetricView
                      mTitle="Weight"
                      mValue={groupInfo[r.expGroupId].weight}
                    ></MetricView>

                    <br />

                    <MetricView
                      mTitle="Traffic Ratio"
                      mValue={_.round(100 * r.weight, 2)}
                      postfix="%"
                    ></MetricView>

                    <br />

                    <MetricView
                      mTitle="Expected Traffic Ratio"
                      mValue={_.round(
                        100 * (groupInfo[r.expGroupId].weight / totalWeight),
                        2
                      )}
                      postfix="%"
                    ></MetricView>
                  </td>

                  <td className="border border-gray-700 text-right">
                    <MetricView
                      mValue={r.ecpm}
                      prefix="$"
                      shouldFormatNumber={true}
                    ></MetricView>
                  </td>

                  <td className="border border-gray-700 text-right">
                    <span
                      className="font-bold"
                      style={{
                        // color: r.rpm > 0 ? "#1165f3" : "red",
                        fontSize: "16px",
                      }}
                    >
                      <MetricView
                        mValue={r.rpm}
                        shouldFormatNumber={true}
                        prefix="$"
                      ></MetricView>
                    </span>
                    <div className="flex gap-2 justify-end text-gray-700 leading-none">
                      {r.diffsWithBench &&
                        r.diffsWithBench.map((d, i) => {
                          return (
                            <span key={i}>
                              <span
                                className="text-sm"
                                data-tip
                                data-for={`${r.expGroupId}-${i}-diff-tip`}
                                style={{
                                  color: d.diff > 0 ? "#008600" : "#b30505",
                                }}
                              >
                                {d.diff > 0 && "+"}
                                {d.diff}
                              </span>

                              <ReactTooltip
                                id={`${r.expGroupId}-${i}-diff-tip`}
                                type="dark"
                                effect="solid"
                                place="bottom"
                              >
                                {d.name}${_.round(d.bench, 2)}
                              </ReactTooltip>
                            </span>
                          );
                        })}
                    </div>
                    <br />

                    <MetricView
                      mTitle="STR"
                      mValue={r.sellThroughRate * 100}
                      shouldFormatNumber={true}
                      postfix="%"
                    ></MetricView>

                    <br />

                    <MetricView
                      mTitle="MScore"
                      mValue={r.managedScore * 100}
                      shouldFormatNumber={true}
                      postfix="%"
                    ></MetricView>

                    <br />

                    <MetricView
                      mTitle="LayerLoss"
                      mValue={r.layerLoss * 100}
                      shouldFormatNumber={true}
                      postfix="%"
                    ></MetricView>
                  </td>

                  <td className="border border-gray-700 text-right">
                    <PopupWrapper
                      triggerElement={
                        <button
                          type="button"
                          className="border-b border-gray-600"
                        >
                          <MetricView
                            mTitle="Rev"
                            // mValue={r.revenue}
                            mValue={r.targetRevenue}
                            shouldFormatNumber={true}
                            prefix="$"
                          ></MetricView>
                        </button>
                      }
                      popupElement={
                        <DemandReportTable report={r}></DemandReportTable>
                      }
                    ></PopupWrapper>

                    <br />
                    <span className="whitespace-no-wrap">
                      <MetricView
                        mTitle="Imp"
                        // mValue={r.impression}
                        mValue={r.targetImpression}
                        shouldFormatNumber={true}
                      ></MetricView>
                    </span>
                    <br />

                    <PopupWrapper
                      place="top center"
                      triggerElement={
                        <button
                          type="button"
                          className="border-b border-gray-700 text-gray-800 text-xs"
                        >
                          (Demand Detail)
                        </button>
                      }
                      popupElement={
                        <DemandLayerHighchart
                          layerReports={layerReports}
                        ></DemandLayerHighchart>
                      }
                    ></PopupWrapper>
                    <br />

                    <ReactTooltip
                      id={`${r.expGroupId}-adx-view`}
                      type="dark"
                      effect="solid"
                      place="top"
                    >
                      AdX AV Viewable Imp: {r.adxAvViewableImp}
                      <br></br>
                      AdX AV Measurable Imp: {r.adxAvMeasuredImp}
                    </ReactTooltip>

                    <span data-tip data-for={`${r.expGroupId}-adx-view`}>
                      <MetricView
                        mTitle="AdX Viewability"
                        mValue={_.round(r.adxViewability, 2)}
                        shouldFormatNumber={true}
                        postfix="%"
                      ></MetricView>
                    </span>
                    <br />

                    <ReactTooltip
                      id={`${r.expGroupId}-total-view`}
                      type="dark"
                      effect="solid"
                      place="top"
                    >
                      Total AV Viewable Imp: {r.totalAvViewableImp}
                      <br></br>
                      Total AV Measurable Imp: {r.totalAvMeasuredImp}
                    </ReactTooltip>

                    <span data-tip data-for={`${r.expGroupId}-total-view`}>
                      <MetricView
                        mTitle="Total Viewability"
                        mValue={_.round(r.totalViewability, 2)}
                        shouldFormatNumber={true}
                        postfix="%"
                      ></MetricView>
                    </span>

                    {r.type !== "B" && (
                      <div className="mt-2 flex gap-2 justify-end">
                        <button
                          type="button"
                          disabled={!groupInfo[r.expGroupId].prevGroupId}
                          onClick={() =>
                            scrollToExpGroup(
                              groupInfo[r.expGroupId].prevGroupId,
                              true
                            )
                          }
                          className={`px-1 ${
                            groupInfo[r.expGroupId].prevGroupId
                              ? "text-blue-700 hover:bg-blue-100"
                              : "text-gray-300 cursor-default"
                          }`}
                        >
                          Prev
                        </button>

                        <button
                          type="button"
                          disabled={!groupInfo[r.expGroupId].nextGroupId}
                          onClick={() =>
                            scrollToExpGroup(
                              groupInfo[r.expGroupId].nextGroupId,
                              true
                            )
                          }
                          className={`px-1 ${
                            groupInfo[r.expGroupId].nextGroupId
                              ? "text-blue-700 hover:bg-blue-100"
                              : "text-gray-300 cursor-default"
                          }`}
                        >
                          Next
                        </button>
                      </div>
                    )}
                  </td>

                  <td className="border border-gray-700 text-right">
                    <MetricView
                      mValue={r.cost}
                      shouldFormatNumber={true}
                      prefix="$"
                    ></MetricView>
                  </td>

                  <td
                    className="border border-gray-700 text-right w-full"
                    // style={{ maxWidth: "220px" }}
                  >
                    <ExperimentGroupAnchor
                      expGroupId={r.expGroupId}
                    ></ExperimentGroupAnchor>
                    {groupInfo[r.expGroupId].groupParams &&
                      _.keys(groupInfo[r.expGroupId].groupParams).map((key) => {
                        const value = groupInfo[r.expGroupId].groupParams[key];
                        return (
                          <div key={key}>
                            {key === "layer_sizes" ? (
                              <div className="text-right">
                                <div className="flex justify-end">
                                  <span className="text-gray-600">
                                    layer_sizes:{" "}
                                  </span>
                                  <ClickToCopyWrapper
                                    copyText={JSON.stringify(value)}
                                  ></ClickToCopyWrapper>
                                </div>
                                <LayerSizesView
                                  layerSizes={value}
                                ></LayerSizesView>
                              </div>
                            ) : (
                              <MetricView
                                mTitle={key}
                                mValue={JSON.stringify(value)}
                                whitespaceNormal={true}
                              ></MetricView>
                            )}
                          </div>
                        );
                      })}
                  </td>

                  {/* Layers */}
                  {this.state.maxLayers
                    ? _.range(0, this.state.maxLayers).map((i) => {
                        const layer = layerReports[i] || {};
                        const layerNumber = i + 1;
                        const customValueMapping = _.get(
                          groupInfo[r.expGroupId],
                          ["layers", layerNumber, "customValue"]
                        );
                        // {date, keyId}
                        let cvMap = {};
                        _.forEach(customValueMapping, (cv) => {
                          if (cvMap[cv.keyId]) {
                            cvMap[cv.keyId].push(cv.date);
                          } else {
                            cvMap[cv.keyId] = [cv.date];
                          }
                        });
                        const customValue = _.map(cvMap, (dates, keyId) => {
                          return { keyId, dates };
                        });

                        // return <div>{JSON.stringify(layer)}</div>;
                        return (
                          <td
                            key={layerNumber}
                            className="border border-gray-700 text-right"
                          >
                            {layer.layer && (
                              <>
                                <div className="font-bold border-gray-700 border-b text-center">
                                  <MetricView
                                    mValue={layer.price || 0}
                                    shouldFormatNumber={true}
                                    prefix="$"
                                  ></MetricView>
                                </div>

                                <div className="flex">
                                  <div className="flex-1">
                                    <MetricView
                                      mTitle="Req"
                                      mValue={layer.request}
                                      shouldFormatNumber={true}
                                    ></MetricView>
                                    <br />
                                    <MetricView
                                      mTitle="Imp"
                                      // mValue={layer.impression}
                                      mValue={layer.targetImpression}
                                      shouldFormatNumber={true}
                                    ></MetricView>
                                    <br />

                                    <PopupWrapper
                                      triggerElement={
                                        <button
                                          type="button"
                                          className="border-b border-gray-600"
                                        >
                                          <MetricView
                                            mTitle="Rev"
                                            // mValue={layer.revenue}
                                            mValue={layer.targetRevenue}
                                            shouldFormatNumber={true}
                                            prefix="$"
                                          ></MetricView>
                                        </button>
                                      }
                                      popupElement={
                                        <DemandReportTable
                                          report={layer}
                                        ></DemandReportTable>
                                      }
                                    ></PopupWrapper>

                                    <br />

                                    <MetricView
                                      mTitle="Cost"
                                      mValue={layer.cost}
                                      shouldFormatNumber={true}
                                      prefix="$"
                                    ></MetricView>
                                    <br />

                                    <MetricView
                                      mTitle="eCPM"
                                      mValue={layer.ecpm}
                                      shouldFormatNumber={true}
                                      prefix="$"
                                    ></MetricView>

                                    <br />
                                    {/* 
                                    <MetricView
                                      mTitle="Viewable Imp"
                                      mValue={layer.totalAvViewableImp}
                                      shouldFormatNumber={true}
                                    ></MetricView>
                                    <br /> */}

                                    <ReactTooltip
                                      id={`${r.expGroupId}-layer-${i}-adx-view`}
                                      type="dark"
                                      effect="solid"
                                      place="top"
                                    >
                                      AdX AV Viewable Imp: {r.adxAvViewableImp}
                                      <br></br>
                                      AdX AV Measurable Imp:{" "}
                                      {r.adxAvMeasuredImp}
                                    </ReactTooltip>

                                    <span
                                      data-tip
                                      data-for={`${r.expGroupId}-layer-${i}-adx-view`}
                                    >
                                      <MetricView
                                        mTitle="AdX Viewability"
                                        mValue={_.round(
                                          layer.adxViewability,
                                          2
                                        )}
                                        shouldFormatNumber={true}
                                        postfix="%"
                                      ></MetricView>
                                    </span>

                                    <br></br>

                                    <PopupWrapper
                                      place="bottom center"
                                      triggerElement={
                                        <button
                                          type="button"
                                          className="border-b border-gray-600"
                                        >
                                          AdX AdSizes
                                        </button>
                                      }
                                      popupElement={
                                        <AdSizesReportTable
                                          reports={layer.adSizesReports}
                                        ></AdSizesReportTable>
                                      }
                                    ></PopupWrapper>
                                  </div>
                                  <div className="flex-1">
                                    <MetricView
                                      mTitle="STR"
                                      mValue={100 * layer.sellThroughRate}
                                      shouldFormatNumber={true}
                                      postfix="%"
                                    ></MetricView>
                                    <br />
                                    <MetricView
                                      mTitle="MScore"
                                      mValue={100 * layer.managedScore}
                                      shouldFormatNumber={true}
                                      postfix="%"
                                    ></MetricView>
                                    <br />
                                    <MetricView
                                      mTitle="RevRatio"
                                      mValue={
                                        r.targetRevenue > 0
                                          ? (100 * layer.targetRevenue) /
                                            r.targetRevenue
                                          : 100
                                      }
                                      shouldFormatNumber={true}
                                      postfix="%"
                                    ></MetricView>
                                    <br />
                                    <MetricView
                                      mTitle="CostRatio"
                                      mValue={
                                        r.cost > 0
                                          ? (100 * layer.cost) / r.cost
                                          : 100
                                      }
                                      shouldFormatNumber={true}
                                      postfix="%"
                                    ></MetricView>
                                    <br />
                                    <MetricView
                                      mTitle="RPM"
                                      mValue={layer.rpm}
                                      shouldFormatNumber={true}
                                      prefix="$"
                                    ></MetricView>
                                    <br />
                                    <ReactTooltip
                                      id={`${r.expGroupId}-layer-${i}-total-view`}
                                      type="dark"
                                      effect="solid"
                                      place="top"
                                    >
                                      Total AV Viewable Imp:{" "}
                                      {r.totalAvViewableImp}
                                      <br></br>
                                      Total AV Measurable Imp:{" "}
                                      {r.totalAvMeasuredImp}
                                    </ReactTooltip>
                                    <span
                                      data-tip
                                      data-for={`${r.expGroupId}-layer-${i}-total-view`}
                                    >
                                      <MetricView
                                        mTitle="Total Viewability"
                                        mValue={_.round(
                                          layer.totalViewability,
                                          2
                                        )}
                                        shouldFormatNumber={true}
                                        postfix="%"
                                      ></MetricView>
                                    </span>

                                    {customValue && (
                                      <div>
                                        <span className="text-gray-600">
                                          Custom Value:
                                        </span>
                                        {customValue.map((cv, i) => {
                                          // cv: {"keyId":"155","dates":["2022-06-16"]}
                                          return (
                                            <div key={i}>
                                              <div className="flex items-center justify-end leading-none">
                                                <span
                                                  data-tip
                                                  data-for={`${r.expGroupId}-${layerNumber}-${cv.keyId}-i2wkey-dates`}
                                                >{`in2w_key=${cv.keyId}`}</span>

                                                <ClickToCopyWrapper
                                                  copyText={`in2w_key=${cv.keyId}`}
                                                ></ClickToCopyWrapper>
                                              </div>

                                              <ReactTooltip
                                                id={`${r.expGroupId}-${layerNumber}-${cv.keyId}-i2wkey-dates`}
                                                type="dark"
                                                effect="solid"
                                                place="top"
                                              >
                                                Dates: {cv.dates.join(", ")}
                                              </ReactTooltip>
                                              {/* <div>{JSON.stringify(cv)}</div> */}
                                            </div>
                                          );
                                        })}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                          </td>
                        );
                      })
                    : null}
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }
}

export default ExperimentGroups;
