import React from "react";
import _ from "lodash";
import { FiInfo } from "react-icons/fi";
import { MetricDescription } from "./MetricDescription";
import PopupWrapper from "../../common/PopupWrapper";

class InfoDescriptionPopover extends React.PureComponent {
  constructor(props) {
    super(props);

    this.child = React.createRef();
    this.handleClosePopover = this.handleClosePopover.bind(this);
  }

  handleClosePopover() {
    this.child.current.handleClosePopup();
  }

  render() {
    const { descKey } = this.props;

    if (!MetricDescription[descKey]) return "";

    return (
      <PopupWrapper
        ref={this.child}
        place="right bottom"
        triggerType="hover"
        hideArrow={false}
        triggerElement={
          <div className="ml-1 text-blue-400">
            <FiInfo></FiInfo>
          </div>
        }
        popupElement={
          <div
            className="p-4 shadow-xl bg-gray-300"
            style={{
              width: "800px",
              height: MetricDescription[descKey].imgHeight,
            }}
          >
            <img src={MetricDescription[descKey].img}></img>
          </div>
        }
      ></PopupWrapper>
    );
  }
}

export default InfoDescriptionPopover;
