import React, { useState, useEffect } from "react";
import _ from "lodash";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import MainRouter from "./MainRouter";
import { isUserAuthenticated } from "./helpers/Auth";
import LoginButton from "./components/common/LoginButton";
import ObserverViewer from "./components/observer/ObserverViewer";
import Notifications from "react-notify-toast";

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [isRecognizedUser, setIsRecognizedUser] = useState();

  useEffect(() => {
    checkIsRecognizedUser();

    async function checkIsRecognizedUser() {
      setIsLoading(true);
      try {
        const isRecognized = await isUserAuthenticated();
        console.log(isRecognized);
        setIsRecognizedUser(isRecognized);
      } catch (err) {
        console.log(err);
        setIsRecognizedUser(false);
      }

      setIsLoading(false);
    }
  }, []);

  return (
    <>
      <Router>
        <Switch>
          <Route
            path="/result/:resultKey"
            render={(props) => {
              const resultKey = _.get(props, "match.params.resultKey");
              return <ObserverViewer resultKey={resultKey}></ObserverViewer>;
            }}
          ></Route>

          <Route
            path="/"
            render={() => {
              return (
                <>
                  {isLoading && <div>Loading...</div>}

                  {isRecognizedUser === false && <LoginCard></LoginCard>}

                  {isRecognizedUser && <MainRouter></MainRouter>}
                </>
              );
            }}
          ></Route>
        </Switch>
      </Router>

      <Notifications />
    </>
  );
}

function LoginCard() {
  return (
    <div className="border bg-gray-100 rounded mx-auto w-64 p-8">
      <div className="flex justify-center">
        <LoginButton></LoginButton>
      </div>
    </div>
  );
}

export default App;
