import React from "react";
import moment from "moment-timezone";
import _ from "lodash";
import ReactModal from "react-modal";
import ReactTooltip from "react-tooltip";

import "../../styles/exp-table.css";

import ScrollToTop from "../common/ScrollToTop";
import PopupWrapper from "../common/PopupWrapper";
import NumberFormat from "../common/NumberFormat";

import DemandReportTable from "./DemandReportTable";
import DemandLayerHighchart from "./DemandLayerHighchart";
import ExperimentDetail from "./ExperimentDetail";
import AdSizesReportTable from "./AdSizesReportTable";
// import CompareWrapper from "./CompareWrapper";

ReactModal.setAppElement("body");

const NUM_PER_PAGE = 8;

class LayerDataTable extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    let layer = this.props.layer;
    let r = this.props.group;

    // layer.sellThroughRate =
    //   layer.request > 0 ? (100 * layer.impression) / layer.request : 0;
    // layer.revRatio = r.request > 0 ? (100 * layer.revenue) / r.revenue : 100;
    // layer.costRatio = r.cost > 0 ? (100 * layer.cost) / r.cost : 100;

    // layer.sellThroughRate =
    //   layer.request > 0 ? (100 * layer.targetImpression) / layer.request : 0;
    layer.revRatio =
      r.request > 0 ? (100 * layer.targetRevenue) / r.targetRevenue : 100;
    layer.costRatio = r.cost > 0 ? (100 * layer.cost) / r.cost : 100;

    return (
      <div className="p-4">
        <div className="font-bold text-center">Layer: {layer.layer}</div>
        <table className="mb-2">
          <thead>
            <tr>
              <th className="text-center px-2 py-1 border bg-gray-200">Req</th>
              <th className="text-center px-2 py-1 border bg-gray-200">Imp</th>
              <th className="text-center px-2 py-1 border bg-gray-200">Rev</th>
              <th className="text-center px-2 py-1 border bg-gray-200">Cost</th>
              <th className="text-center px-2 py-1 border bg-gray-200">eCPM</th>
              <th className="text-center px-2 py-1 border bg-gray-200">STR</th>
              <th className="text-center px-2 py-1 border bg-gray-200">
                MScore
              </th>
              <th className="text-center px-2 py-1 border bg-gray-200">RPM</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-right px-2 py-1 border bg-gray-100">
                <NumberFormat value={layer.request} format="0,0"></NumberFormat>
              </td>
              <td className="text-right px-2 py-1 border bg-gray-100">
                <NumberFormat
                  value={layer.impression}
                  format="0,0"
                ></NumberFormat>
              </td>
              <td className="text-right px-2 py-1 border bg-gray-100">
                <NumberFormat value={layer.revenue} prefix="$"></NumberFormat>
              </td>
              <td className="text-right px-2 py-1 border bg-gray-100">
                <NumberFormat value={layer.cost} prefix="$"></NumberFormat>
              </td>
              <td className="text-right px-2 py-1 border bg-gray-100">
                <NumberFormat value={layer.ecpm} prefix="$"></NumberFormat>
              </td>
              <td className="text-right px-2 py-1 border bg-gray-100">
                <NumberFormat
                  value={layer.sellThroughRate}
                  postfix="%"
                ></NumberFormat>
              </td>
              <td className="text-right px-2 py-1 border bg-gray-100">
                <NumberFormat
                  value={layer.managedScore * 100}
                  postfix="%"
                ></NumberFormat>
              </td>
              <td className="text-right px-2 py-1 border bg-gray-100">
                <NumberFormat value={layer.rpm} prefix="$"></NumberFormat>
              </td>
            </tr>
            <tr>
              <td className="text-right px-2 py-1 border bg-gray-100 text-gray-600 font-normal"></td>
              <td className="text-right px-2 py-1 border bg-gray-100 text-gray-600 font-normal"></td>
              <td className="text-right px-2 py-1 border bg-gray-100 text-gray-600 font-normal">
                <span data-tip data-for="rev-ratio">
                  <NumberFormat
                    value={layer.revRatio}
                    postfix="%"
                  ></NumberFormat>
                </span>

                <ReactTooltip
                  id="rev-ratio"
                  type="dark"
                  effect="solid"
                  place="top"
                >
                  Revenue Ratio
                </ReactTooltip>
              </td>
              <td className="text-right px-2 py-1 border bg-gray-100 text-gray-600 font-normal">
                <span data-tip data-for="cost-ratio">
                  <NumberFormat
                    value={layer.costRatio}
                    postfix="%"
                  ></NumberFormat>
                </span>

                <ReactTooltip
                  id="cost-ratio"
                  type="dark"
                  effect="solid"
                  place="top"
                >
                  Cost Ratio
                </ReactTooltip>
              </td>
              <td className="text-right px-2 py-1 border bg-gray-100 text-gray-600 font-normal"></td>
              <td className="text-right px-2 py-1 border bg-gray-100 text-gray-600 font-normal"></td>
              <td className="text-right px-2 py-1 border bg-gray-100 text-gray-600 font-normal"></td>
              <td className="text-right px-2 py-1 border bg-gray-100 text-gray-600 font-normal"></td>
            </tr>
          </tbody>
        </table>

        <DemandReportTable report={layer}></DemandReportTable>
      </div>
    );
  }
}

class ExpGroupsTable extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { expGroups, groupInfo } = this.props;

    let orderedGroups = _.sortBy(expGroups, (d) => {
      return d.expGroupId;
    });

    const candidates = _.filter(expGroups, (d) => {
      if (d.type === "B") {
        return false;
      } else if (d.type === "O") {
        return false;
      } else {
        return true;
      }
    });
    const topCandidateId = _.get(_.maxBy(candidates, "rpm"), "expGroupId");

    orderedGroups = orderedGroups.map((group) => {
      const groupStyle =
        group.type === "B"
          ? "exp-table-b"
          : group.type === "O"
          ? "exp-table-o"
          : group.expGroupId === topCandidateId
          ? "exp-table-tc"
          : "";

      group.groupStyle = groupStyle;
      group.numOfLayers = group.reports.length;
      return group;
    });

    const maxNumOfLayers = _.maxBy(orderedGroups, "numOfLayers").numOfLayers;

    return (
      <>
        <table className="table w-full text-sm text-gray-900 font-mono exp-table">
          <thead>
            <tr className="text-xs text-gray-700">
              <td className="text-right px-2 font-bold">Group</td>
              <td className="text-right px-2 font-bold">Req</td>
              <td className="text-right px-2 font-bold">NetRPM</td>
              <td className="text-right px-2 font-bold">eCPM</td>
              <td className="text-right px-2 font-bold">STR</td>
              <td className="text-right px-2 font-bold">MScore</td>
              <td className="text-right px-2 font-bold">Traffic</td>
              <td className="text-right px-2 font-bold leading-none">
                Layer
                <br />
                Loss
              </td>
              <td className="text-right px-2 font-bold">Revenue</td>
              <td className="text-right px-2 font-bold">
                AdX View-<br></br>ability
              </td>
              <td className="text-right px-2 font-bold">
                Total View-<br></br>ability
              </td>
              {maxNumOfLayers > 0 ? (
                _.range(1, maxNumOfLayers + 1).map((num) => {
                  return (
                    <td
                      key={num}
                      className="text-center px-2 font-bold"
                      colSpan="4"
                    >
                      L{num}
                    </td>
                  );
                })
              ) : (
                <td className="text-right px-2 font-bold">Layers</td>
              )}
            </tr>
          </thead>
          <tbody>
            {orderedGroups.map((group) => {
              const groupParams = _.get(
                groupInfo,
                [group.expGroupId, "groupParams"],
                {}
              );
              let sizes = _.get(groupParams, "sizes");
              sizes = typeof sizes === "string" ? [sizes] : sizes;
              const orderedSizes = _.sortBy(sizes, [0, 1]);
              const layerSizes = _.get(groupParams, "layer_sizes");
              // console.log(layerSizes);
              const layers = _.map(group.reports, (layer) => {
                if (layerSizes) {
                  layer.sizes = _.sortBy(
                    _.get(layerSizes, [layer.layer - 1], null),
                    [0, 1]
                  );
                } else {
                  layer.sizes = orderedSizes;
                }
                return layer;
              });

              return (
                <tr
                  key={group.expGroupId}
                  className="bg-gray-100 hover:bg-gray-300 exp-table-row"
                >
                  <td
                    className={`text-right px-2 py-1 border ${group.groupStyle}`}
                  >
                    <PopupWrapper
                      place="right center"
                      triggerType="hover"
                      triggerElement={
                        <div style={{ width: "48px" }} className="truncate">
                          {groupInfo[group.expGroupId].typeId > 199 && (
                            <div className="w-2 h-2 rounded-full bg-pink-500 inline-block mr-1"></div>
                          )}
                          {group.type
                            ? group.type
                            : groupInfo[group.expGroupId].name}
                        </div>
                      }
                      popupElement={
                        <div className="p-2">
                          [{group.expGroupId}]{" "}
                          {groupInfo[group.expGroupId].name}{" "}
                          {groupInfo[group.expGroupId].typeId > 199 && (
                            <span className="text-pink-600">
                              (Not Promotable)
                            </span>
                          )}
                        </div>
                      }
                    ></PopupWrapper>
                  </td>
                  <td className={`text-right border ${group.groupStyle}`}>
                    <div className="px-2 py-1">
                      <NumberFormat
                        value={group.firstLayerRequest}
                        format="0,0"
                      ></NumberFormat>
                    </div>
                  </td>
                  <td
                    className={`text-right border font-bold ${group.groupStyle}`}
                  >
                    <div className="px-2 py-1">
                      <span className="text-red-700">
                        <NumberFormat
                          value={group.rpm}
                          prefix="$"
                        ></NumberFormat>
                      </span>
                    </div>
                  </td>
                  <td
                    className={`text-right px-2 py-1 border ${group.groupStyle}`}
                  >
                    {/* <NumberFormat value={group.ecpm} prefix="$"></NumberFormat> */}

                    <PopupWrapper
                      place="bottom left"
                      triggerElement={
                        <button
                          type="button"
                          className="border-b border-gray-600"
                        >
                          <NumberFormat
                            value={group.ecpm}
                            prefix="$"
                          ></NumberFormat>
                        </button>
                      }
                      popupElement={
                        <AdSizesReportTable
                          reports={group.adSizesReports}
                        ></AdSizesReportTable>
                      }
                    ></PopupWrapper>
                  </td>
                  <td
                    className={`text-right px-2 py-1 border ${group.groupStyle}`}
                  >
                    <NumberFormat
                      value={group.sellThroughRate * 100}
                      postfix="%"
                    ></NumberFormat>
                  </td>
                  <td
                    className={`text-right px-2 py-1 border ${group.groupStyle}`}
                  >
                    <NumberFormat
                      value={group.managedScore * 100}
                      postfix="%"
                    ></NumberFormat>
                  </td>
                  <td
                    className={`text-right px-2 py-1 border ${group.groupStyle}`}
                  >
                    <PopupWrapper
                      place="top center"
                      triggerType="hover"
                      triggerElement={
                        <span
                          className="hover:font-bold"
                          style={{ cursor: "help" }}
                        >
                          <NumberFormat
                            value={group.weight * 100}
                            postfix="%"
                          ></NumberFormat>
                        </span>
                      }
                      popupElement={
                        <div className="p-2">
                          Weight: {groupInfo[group.expGroupId].weight}
                        </div>
                      }
                    ></PopupWrapper>
                  </td>
                  <td
                    className={`text-right px-2 py-1 border ${group.groupStyle}`}
                  >
                    <PopupWrapper
                      place="top center"
                      triggerElement={
                        <span className="cursor-pointer hover:font-bold">
                          <NumberFormat
                            value={group.layerLoss * 100}
                            postfix="%"
                          ></NumberFormat>
                        </span>
                      }
                      popupElement={
                        <DemandLayerHighchart
                          layerReports={group.reports}
                        ></DemandLayerHighchart>
                      }
                    ></PopupWrapper>
                  </td>
                  <td
                    className={`text-right px-2 py-1 border ${group.groupStyle}`}
                  >
                    <PopupWrapper
                      place="top center"
                      triggerElement={
                        <span className="cursor-pointer hover:font-bold">
                          <NumberFormat
                            value={group.revenue}
                            prefix="$"
                          ></NumberFormat>
                        </span>
                      }
                      popupElement={
                        <DemandReportTable report={group}></DemandReportTable>
                      }
                    ></PopupWrapper>
                  </td>
                  <td
                    className={`text-right px-2 py-1 border ${group.groupStyle}`}
                  >
                    <>
                      <ReactTooltip
                        id={`${group.expGroupId}-view-adx`}
                        type="dark"
                        effect="solid"
                        place="top"
                      >
                        AdX AV Viewable Imp: {group.adxAvViewableImp}
                        <br></br>
                        AdX AV Measured Imp: {group.adxAvMeasuredImp}
                      </ReactTooltip>

                      <span data-tip data-for={`${group.expGroupId}-view-adx`}>
                        <NumberFormat
                          value={group.adxViewability}
                          postfix="%"
                        ></NumberFormat>
                      </span>
                    </>
                  </td>
                  <td
                    className={`text-right px-2 py-1 border ${group.groupStyle}`}
                  >
                    <>
                      <ReactTooltip
                        id={`${group.expGroupId}-view`}
                        type="dark"
                        effect="solid"
                        place="top"
                      >
                        Total AV Viewable Imp: {group.totalAvViewableImp}
                        <br></br>
                        Total AV Measured Imp: {group.totalAvMeasuredImp}
                      </ReactTooltip>

                      <span data-tip data-for={`${group.expGroupId}-view`}>
                        <NumberFormat
                          value={group.totalViewability}
                          postfix="%"
                        ></NumberFormat>
                      </span>
                    </>
                  </td>
                  {layers ? (
                    layers.map((layer, i) => {
                      return (
                        <React.Fragment key={i}>
                          <td
                            className={`text-right px-2 py-1 border-b ${group.groupStyle}`}
                          >
                            {layer.layer === 1 &&
                              groupParams.url_trunc_dispatcher && (
                                <>
                                  <ReactTooltip
                                    id={`layer-${layer.layer}-${group.expGroupId}`}
                                    type="dark"
                                    effect="solid"
                                    place="top"
                                  >
                                    Truncate URL Dispatcher
                                  </ReactTooltip>

                                  <span
                                    className="text-pink-400"
                                    data-tip
                                    data-for={`layer-${layer.layer}-${group.expGroupId}`}
                                  >
                                    T
                                  </span>
                                </>
                              )}
                            {layer.layer !== 1 &&
                              groupParams.url_trunc_smart_tag && (
                                <>
                                  <ReactTooltip
                                    id={`layer-${layer.layer}-${group.expGroupId}`}
                                    type="dark"
                                    effect="solid"
                                    place="top"
                                  >
                                    Truncate URL SMART
                                  </ReactTooltip>

                                  <span
                                    className="text-pink-400"
                                    data-tip
                                    data-for={`layer-${layer.layer}-${group.expGroupId}`}
                                  >
                                    T
                                  </span>
                                </>
                              )}
                            {groupParams.url_cache_busting && (
                              <>
                                <ReactTooltip
                                  id={`layer-cb-${layer.layer}-${group.expGroupId}`}
                                  type="dark"
                                  effect="solid"
                                  place="top"
                                >
                                  Cache Busting
                                </ReactTooltip>

                                <span
                                  className="text-purple-600"
                                  data-tip
                                  data-for={`layer-cb-${layer.layer}-${group.expGroupId}`}
                                >
                                  CB
                                </span>
                              </>
                            )}
                          </td>

                          <td
                            className={`text-right px-2 py-1 font-bold border-b ${group.groupStyle}`}
                          >
                            <PopupWrapper
                              place="top center"
                              triggerElement={
                                <span className="cursor-pointer hover:font-bold">
                                  <NumberFormat
                                    value={layer.price}
                                    prefix="$"
                                  ></NumberFormat>
                                </span>
                              }
                              popupElement={
                                <LayerDataTable
                                  layer={layer}
                                  group={group}
                                ></LayerDataTable>
                              }
                            ></PopupWrapper>
                          </td>

                          <td
                            className={`text-left px-2 py-1 border-b leading-none ${group.groupStyle}`}
                          >
                            {/* {JSON.stringify(layer.sizes)} */}
                            {/* <PopupWrapper
                              place="bottom right"
                              triggerElement={
                                <button
                                  type="button"
                                  className="border-b border-gray-600"
                                >
                                  {layer.sizes && layer.sizes.length > 0
                                    ? _.chunk(layer.sizes, 2).map(
                                        (chunk, i) => {
                                          return (
                                            <div
                                              key={i}
                                              className="text-left flex items-center"
                                              style={{ paddingTop: "2px" }}
                                            >
                                              {chunk.map((size) => {
                                                return (
                                                  <div
                                                    key={size}
                                                    className="pr-2 w-1/2"
                                                    style={{ fontSize: "10px" }}
                                                  >
                                                    {typeof size === "object"
                                                      ? `${size[0]}x${size[1]}`
                                                      : size}
                                                  </div>
                                                );
                                              })}
                                            </div>
                                          );
                                        }
                                      )
                                    : "-"}
                                </button>
                              }
                              popupElement={
                                <AdSizesReportTable
                                  reports={layer.adSizesReports}
                                ></AdSizesReportTable>
                              }
                            ></PopupWrapper> */}
                          </td>

                          <td
                            className={`text-right px-2 py-1 text-gray-600 border-r border-b ${group.groupStyle}`}
                          >
                            <>
                              <ReactTooltip
                                id={`layer-${layer.layer}-${group.expGroupId}-view`}
                                type="dark"
                                effect="solid"
                                place="top"
                              >
                                AdX AV Viewable Imp: {layer.adxAvViewableImp}
                                <br></br>
                                AdX AV Measured Imp: {layer.adxAvMeasuredImp}
                                <br></br>
                                AdX Viewability:{" "}
                                {_.round(layer.adxViewability, 2)}%<hr></hr>
                                Total AV Viewable Imp:{" "}
                                {layer.totalAvViewableImp}
                                <br></br>
                                Total AV Measured Imp:{" "}
                                {layer.totalAvMeasuredImp}
                                <br></br>
                                Total Viewability:{" "}
                                {_.round(layer.totalViewability, 2)}%
                              </ReactTooltip>

                              <span
                                data-tip
                                data-for={`layer-${layer.layer}-${group.expGroupId}-view`}
                              >
                                <NumberFormat
                                  value={layer.adxViewability}
                                  postfix="%"
                                ></NumberFormat>
                              </span>
                            </>
                          </td>
                        </React.Fragment>
                      );
                    })
                  ) : (
                    <td
                      className={`text-right px-2 py-1 border ${group.groupStyle}`}
                    >
                      -
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }
}

class ExperimentCard extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const expInfo = this.props.expInfo;
    const exp = this.props.exp;
    const expGroups = exp.reports;

    const expStartDate = moment(exp.startDate).tz("Asia/Taipei");
    const dateRangeStartDate = moment(this.props.dateRange.startDate);
    const isDataIncomplete = expStartDate.isBefore(dateRangeStartDate);

    return (
      <>
        <div
          key={exp.expId}
          className="border-b-2 border-gray-400 bg-gray-200"
          // style={{ backgroundColor: "#2E2E2E" }}
        >
          <div className="flex justify-between items-center w-full px-4 py-2 bg-white rounded-t-lg">
            <div className="font-mono text-gray-700 w-1/5 overflow-hidden whitespace-no-wrap">
              <div className="flex">
                <span
                  className="border-b border-dashed border-gray-600 truncate"
                  data-tip
                  data-for={`parameters-${exp.expId}`}
                  style={{ cursor: "help" }}
                >
                  [{exp.expId}]{expInfo[exp.expId].name}
                </span>
                <ReactTooltip
                  id={`parameters-${exp.expId}`}
                  type="dark"
                  effect="solid"
                  place="right"
                >
                  <pre>
                    {JSON.stringify(expInfo[exp.expId].parameters, null, 2)}
                  </pre>
                </ReactTooltip>
              </div>
            </div>
            <div className="font-bold text-lg">
              <span role="img" aria-label="start date">
                🕒
              </span>{" "}
              {exp.startDateString}
              <div className="text-xs text-right font-hairline text-gray-700 pl-2">
                {exp.startDateAgo}
              </div>
            </div>
            <div className="px-2">
              <div>GrossLift: </div>
              <span
                className={`font-black text-xl text-${
                  exp.grossLift > 0 ? "green" : "red"
                }-500`}
              >
                <NumberFormat value={exp.grossLift} postfix="%"></NumberFormat>
              </span>
            </div>
            <div className="px-2">
              <div>NetLift: </div>
              <span
                className={`font-black text-xl text-${
                  exp.netLift > 0 ? "green" : "red"
                }-500`}
              >
                <NumberFormat value={exp.netLift} postfix="%"></NumberFormat>
              </span>
            </div>
            <div>
              <div title="AV Viewable Impression / AV Measurable Impression">
                AdX Viewability:
              </div>
              <span
                className="text-xl"
                title={`Total Viewability: ${_.round(
                  exp.totalViewability,
                  2
                )}%`}
              >
                <NumberFormat
                  value={exp.adxViewability}
                  postfix="%"
                ></NumberFormat>
              </span>
            </div>
            <div
              className={`px-2 rounded ${
                expInfo[exp.expId].mode === 1
                  ? "bg-green-300 text-green-900"
                  : expInfo[exp.expId].mode === 2
                  ? "bg-orange-300 text-orange-900"
                  : "bg-gray-300 text-gray-900"
              }`}
            >
              Mode <span className="font-bold">{expInfo[exp.expId].mode}</span>
            </div>
            <div>
              <button
                type="button"
                onClick={() => this.props.showExpDetail(exp.expId)}
                className="font-bold py-2 px-4 rounded bg-blue-600 shadow text-sm hover:bg-blue-700 text-white"
              >
                Detail
              </button>
            </div>
          </div>

          {isDataIncomplete && (
            <div className="m-2 py-2 px-4 bg-red-200 border border-red-400 text-red-800 rounded text-xs">
              Data is incomplete. Report is queried from{" "}
              {dateRangeStartDate.format("YYYY-MM-DD HH:mm")}, where this
              experiment starts from {expStartDate.format("YYYY-MM-DD HH:mm")}.
              <p className="font-bold">
                Try a larger date range to show the full report for this
                experiment.
              </p>
            </div>
          )}

          <div className="w-full py-4">
            <div className="mx-1 overflow-x-auto">
              <ExpGroupsTable
                expGroups={expGroups}
                groupInfo={this.props.groupInfo}
              ></ExpGroupsTable>
            </div>
          </div>
        </div>
      </>
    );
  }
}

class SegmentList extends React.Component {
  constructor(props) {
    super(props);

    const { data } = this.props;

    const { groupInfo, expInfo } = data;

    // Build segment family
    const expLink = _(expInfo)
      .groupBy(({ segmentId }) => `${segmentId || "unit"}`)
      .mapValues((exps) => {
        // Order by expId desc
        exps.sort((l, r) => r.id - l.id);

        return _.map(exps, ({ id }, index, c) => {
          const next = index === 0 ? undefined : c[index - 1].id;
          const prev = index === c.length - 1 ? undefined : c[index + 1].id;
          return { id, next, prev };
        });
      })
      .values()
      .flatten()
      .keyBy("id")
      .value();

    const yieldLifts = _.filter(data.yieldLifts, (r) => {
      return r.segmentId === this.props.segmentId;
    });

    this.state = {
      showModal: false,
      expId: null,

      yieldLifts,
      paginatedData: this.repaginateData(yieldLifts),
      isLoadingMoreData: false,

      expLink,
      groupInfo,
      expInfo,
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleShowExpDetail = this.handleShowExpDetail.bind(this);
    this.handleLoadMore = this.handleLoadMore.bind(this);
  }

  handleOpenModal() {
    this.setState({ showModal: true });
    document.body.style.overflow = "hidden";

    console.log("yooooooo");
    //
    const offset = document.getElementById(
      "top-of-segment-experiences"
    ).offsetTop;
    console.log(document.getElementById("top-of-segment-experiences"), offset);
  }

  handleCloseModal() {
    this.setState({ showModal: false });
    document.body.style.overflow = "auto";
  }

  handleShowExpDetail(expId) {
    if (this.state.showModal) {
      this.setState({ expId });
    } else {
      this.setState({
        showModal: true,
        expId,
      });
    }
  }

  repaginateData(data) {
    const nth = NUM_PER_PAGE;
    const paginatedData = _.slice(data, 0, nth);
    return paginatedData;
  }

  handleLoadMore() {
    if (this.state.isLoadingMoreData) {
      return false;
    }

    this.setState({ isLoadingMoreData: true });

    setTimeout(() => {
      const nth = this.state.paginatedData.length + NUM_PER_PAGE;
      const paginatedData = _.slice(this.state.yieldLifts, 0, nth);

      this.setState({
        paginatedData,
        isLoadingMoreData: false,
      });
    });
  }

  render() {
    const { data } = this.props;

    const {
      paginatedData,
      yieldLifts,
      isLoadingMoreData,
      expLink,
      expInfo,
      groupInfo,
    } = this.state;

    return (
      <>
        <ReactModal
          isOpen={this.state.showModal}
          contentLabel="Experiment Detail Modal"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.8)",
            },
            content: {
              marginTop: "48px",
              padding: 0,
            },
          }}
          onAfterOpen={() => {
            document.body.style.overflow = "hidden";
            const element = document.getElementById(
              "top-of-segment-experiences"
            );
            if (!getIsElementToTop(element)) {
              window.scrollTo({
                top: element.offsetTop,
              });
            }
          }}
          // if allow esc or overlay click hide modal
          // onRequestClose={() => {
          //   document.body.style.overflow = "auto";
          // }}
        >
          <ExperimentDetail
            data={data}
            expId={this.state.expId}
            expLink={expLink}
            goToExp={this.handleShowExpDetail}
            close={this.handleCloseModal}
          ></ExperimentDetail>
        </ReactModal>

        <div className="w-full p-4 mb-32">
          {/* <CompareWrapper groups={testCompareGroups}></CompareWrapper> */}

          {paginatedData &&
            paginatedData.map((exp) => {
              return (
                <ExperimentCard
                  key={exp.expId}
                  exp={exp}
                  groupInfo={groupInfo}
                  expInfo={expInfo}
                  showExpDetail={this.handleShowExpDetail}
                  dateRange={data.dateRange}
                ></ExperimentCard>
              );
            })}

          {paginatedData.length === yieldLifts.length ? (
            ""
          ) : (
            <button
              type="button"
              disabled={isLoadingMoreData}
              className="w-full p-4 font-bold bg-indigo-500 text-white hover:bg-indigo-600"
              onClick={this.handleLoadMore}
            >
              {isLoadingMoreData ? "Loading..." : "Load more"}
            </button>
          )}
        </div>

        <ScrollToTop></ScrollToTop>
      </>
    );
  }
}

function getIsElementToTop(e) {
  var offset = { x: 0, y: 0 };
  while (e) {
    offset.x += e.offsetLeft;
    offset.y += e.offsetTop;
    e = e.offsetParent;
  }

  if (
    document.documentElement &&
    (document.documentElement.scrollTop || document.documentElement.scrollLeft)
  ) {
    offset.x -= document.documentElement.scrollLeft;
    offset.y -= document.documentElement.scrollTop;
  } else if (
    document.body &&
    (document.body.scrollTop || document.body.scrollLeft)
  ) {
    offset.x -= document.body.scrollLeft;
    offset.y -= document.body.scrollTop;
  } else if (window.pageXOffset || window.pageYOffset) {
    offset.x -= window.pageXOffset;
    offset.y -= window.pageYOffset;
  }

  // console.log(offset.x + offset.y);
  return offset.y === 0;
}

export default SegmentList;
