import React from "react";
import _ from "lodash";
import Select from "react-select";
import { DEMAND_TYPES } from "constants/DemandType";

class DemandTypesSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      options: [],
      selectedValue: null,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const items = _.values(DEMAND_TYPES);

    let options = _.map(items, (item) => {
      return {
        value: item,
        label: item,
      };
    });

    const selectedValue = this.props.isMulti
      ? _.filter(options, (item) => {
          return _.indexOf(this.props.selectedValue, item.value) !== -1;
        })
      : _.find(options, { value: this.props.selectedValue });

    this.setState({
      items,
      options,
      selectedValue,
    });
  }

  handleChange(option) {
    this.setState({
      selectedValue: option,
    });

    // this.props.handleOnChange(option.value);

    if (this.props.isMulti) {
      const selectedValues = _.map(option, "value");
      const results = _.filter(this.state.items, (item) => {
        return _.indexOf(selectedValues, item) !== -1;
      });
      this.props.handleOnChange(results);
    } else {
      this.props.handleOnChange(option.value);
    }
  }

  render() {
    const { options, selectedValue } = this.state;

    return (
      <>
        <Select
          value={selectedValue}
          onChange={this.handleChange}
          options={options}
          isMulti={this.props.isMulti}
        />
      </>
    );
  }
}

export default DemandTypesSelector;
