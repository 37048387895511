import React, { useState } from "react";
import BetterPopupWrapper from "./BetterPopupWrapper";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

function MetadataPopover(props) {
  const { metadata, position = "bottom", triggerType = "click" } = props;
  const [isOpen, setIsOpen] = useState(false);

  function toggleOpen() {
    setIsOpen(!isOpen);
  }

  return (
    <BetterPopupWrapper
      // ref={this.child}
      position={position}
      triggerType={triggerType}
      keepInside={true}
      triggerElement={
        props.children || <DefaultTriggerElement></DefaultTriggerElement>
      }
      contentElement={
        <div style={{ width: "300px" }} className="p-1">
          {/* this dummy input so that textarea does not auto focus */}
          <input style={{ display: "none" }}></input>
          <textarea
            // style={{ width: "100%", fontSize: "14px" }}
            className="bg-gray-300 text-sm w-full"
            rows={20}
            defaultValue={JSON.stringify(metadata, null, 4)}
            readOnly
          ></textarea>
        </div>
      }
    ></BetterPopupWrapper>
  );
}

function DefaultTriggerElement() {
  return (
    <button
      type="button"
      className="text-blue-400 font-semibold hover:underline"
    >
      Metadata
    </button>
  );
}

export default MetadataPopover;
