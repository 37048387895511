import React from "react";
import _ from "lodash";

import { NetworkAPI } from "apis";
import LoadingUI from "../../../common/LoadingUI";
import MaxCurrentView from "components/snapshot/MaxCurrentView";

class UprUsageEstimation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,

      gamInfo: null,
      pricingRules: null,
    };
  }

  async componentDidMount() {
    await this.queryNetworkGamInfo();
  }

  async queryNetworkGamInfo() {
    this.setState({
      isLoading: true,
    });
    try {
      const { networkId } = this.props;
      if (!networkId) {
        throw new Error("Missing Network ID");
      }

      const gamInfo = await NetworkAPI.getNetworkGamInfo({ networkId });
      if (gamInfo.status !== "OK") {
        throw new Error("Failed to get GAM info");
      }
      console.log(gamInfo);

      // {max: xxx, current: xxx}
      const pricingRules = _.get(gamInfo, [
        "results",
        "limits",
        "inventoryAndKeyValues",
        "pricingRules",
      ]);

      this.setState({
        gamInfo: gamInfo.results,
        pricingRules,

        isLoading: false,
        errMsg: null,
      });
    } catch (err) {
      this.setState({
        isLoading: false,
        errMsg: err.toString(),
      });
    }
  }

  render() {
    const { isLoading, errMsg, pricingRules } = this.state;

    if (isLoading) return <LoadingUI></LoadingUI>;

    return (
      <div>
        <div>
          <div className="text-lg font-semibold text-gray-800">
            Current usage:
          </div>
          {errMsg ? (
            <div className="text-red-800">{errMsg}</div>
          ) : (
            <>
              <div>
                {pricingRules && (
                  <MaxCurrentView
                    title="Pricing Rules"
                    max={pricingRules.max}
                    current={pricingRules.current}
                    maxHint="200 is an approximate"
                  ></MaxCurrentView>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default UprUsageEstimation;
