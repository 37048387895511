import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import { calculateReportsForGodview } from "./AnatomyCstReportHelper";
import HighchartWrapper from "components/common/HighchartWrapper";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";

import {
  bColor,
  getGroupTypeSeriesOptions,
  oColor,
} from "./AnatomyHighchartHelper";

HighchartsMore(Highcharts);

const HOUR_OPTIONS = [
  {
    label: "1H",
    value: 1,
  },
  {
    label: "3H",
    value: 3,
  },
  {
    label: "4H",
    value: 4,
  },
  {
    label: "6H",
    value: 6,
  },
  {
    label: "8H",
    value: 8,
  },
  {
    label: "12H",
    value: 12,
  },
  {
    label: "Daily",
    value: 24,
  },
];

const LAYER_OPTIONS = ["all", "1", "2"];
const METRIC_OPTIONS = ["rrpm", "str", "ecpm"];

class GodviewConfidenceChart extends React.Component {
  constructor(props) {
    super(props);

    this.ref1 = React.createRef();

    this.state = {
      // chart
      options: null,

      // filter defaults
      aggregation: 24,
      layerNum: "all",
      metric: "rrpm",

      //
      currentConfidenceLevel: null,
      confidenceLevels: [],
    };

    this.handleFilterChanged = this.handleFilterChanged.bind(this);
  }

  componentDidMount() {
    const { aggregation, layerNum, metric } = this.state;
    const selectedFilter = {
      aggregation,
      layerNum,
      metric,
    };
    const { options, confidenceLevels } = this._getChartOptions(selectedFilter);
    this.setState({
      options,
      confidenceLevels,
      currentConfidenceLevel: confidenceLevels[0],
    });
  }

  _getChartOptions(selectedFilter) {
    const { data, segmentId, unitInfo, getFilterParams, confidenceReports } =
      this.props;
    const filterParams = getFilterParams();
    const filter = {
      ...filterParams,
      ...selectedFilter,
    };
    // console.log(data, filter, segmentId);

    // get confidence_levels from confidenceReports
    const tempReport = _.get(confidenceReports, [
      "network_billable",
      filter.layerNum === "all" ? "all" : `l${filter.layerNum}`,
      0,
      filter.metric,
    ]);
    const confidenceLevels = _.map(tempReport, "confidence_level");

    const reports = this._calculateReports({
      data,
      segmentId,
      filterParams: filter,
    });

    const seriesData = _createSeriesData({
      data: reports,
      unitInfo,
      filter,
      confidenceReports,
      currentConfidenceLevel:
        selectedFilter.confidenceLevel || confidenceLevels[0],
    });

    const options = _createChartOptions({
      seriesData,
      timezone: unitInfo.timezone,
      filter,
    });

    return {
      options,
      confidenceLevels,
    };
  }

  _calculateReports({ data, segmentId, filterParams }) {
    const {
      aggregation,
      demandTypes,
      costCpm,
      isIncludeGhostImp,
      includeToday,
      filterLatestDate,
    } = filterParams;
    return calculateReportsForGodview({
      data,
      segmentId,
      aggregation,
      demandTypes,
      costCpm,
      includeToday,
      filterLatestDate,
      isIncludeGhostImp,
    });
  }

  // type: "aggregation", "layerNum", "metric"
  handleFilterChanged({ type, value }) {
    const { layerNum, metric, aggregation, currentConfidenceLevel } =
      this.state;

    const selectedFilter = {
      aggregation: type === "aggregation" ? value : aggregation,
      layerNum: type === "layerNum" ? value : layerNum,
      metric: type === "metric" ? value : metric,
      confidenceLevel:
        type === "confidenceLevel" ? value : currentConfidenceLevel,
    };
    const { options } = this._getChartOptions(selectedFilter);
    this.setState({
      options,
      aggregation: type === "aggregation" ? value : aggregation,
      layerNum: type === "layerNum" ? value : layerNum,
      metric: type === "metric" ? value : metric,
      currentConfidenceLevel:
        type === "confidenceLevel" ? value : currentConfidenceLevel,
    });
  }

  render() {
    const {
      // chart
      options,
      confidenceLevels,

      // selected filter
      aggregation,
      layerNum,
      metric,
      currentConfidenceLevel,
    } = this.state;

    return (
      <div>
        {/* <div className="rounded bg-gray-200 p-2 text-sm text-gray-900">
          💡 <span className="font-semibold">O's shadow:</span> Calculated from
          the detectors that match the same floor price as o.
        </div> */}

        <div className="mt-2">
          <div className="border mb-2 py-1 px-2">
            <div className="flex items-center gap-2 text-sm font-semibold">
              Time Aggregation:{" "}
              <div>
                {HOUR_OPTIONS.map((option) => {
                  return (
                    <button
                      key={option.label}
                      type="button"
                      className={`rounded px-2 py-1 font-semibold ${
                        aggregation === option.value
                          ? "bg-indigo-100 text-indigo-800"
                          : "text-gray-700 hover:bg-gray-200 hover:text-indigo-800"
                      }`}
                      onClick={() =>
                        this.handleFilterChanged({
                          type: "aggregation",
                          value: option.value,
                        })
                      }
                    >
                      {option.label}
                    </button>
                  );
                })}
              </div>
            </div>

            <div className="border-t mt-1 flex items-center gap-2 pt-1 text-sm font-semibold">
              Layer:{" "}
              <div>
                {LAYER_OPTIONS.map((option) => {
                  return (
                    <button
                      key={option}
                      type="button"
                      className={`rounded px-2 py-1 font-semibold ${
                        layerNum === option
                          ? "bg-indigo-100 text-indigo-800"
                          : "text-gray-700 hover:bg-gray-200 hover:text-indigo-800"
                      }`}
                      onClick={() =>
                        this.handleFilterChanged({
                          type: "layerNum",
                          value: option,
                        })
                      }
                    >
                      {option}
                    </button>
                  );
                })}
              </div>
            </div>

            <div className="border-t mt-1 flex items-center gap-2 pt-1 text-sm font-semibold">
              Metric:{" "}
              <div>
                {METRIC_OPTIONS.map((option) => {
                  return (
                    <button
                      key={option}
                      type="button"
                      className={`rounded px-2 py-1 font-semibold ${
                        metric === option
                          ? "bg-pink-100 text-pink-800"
                          : "text-gray-700 hover:bg-gray-200 hover:text-pink-800"
                      }`}
                      onClick={() =>
                        this.handleFilterChanged({
                          type: "metric",
                          value: option,
                        })
                      }
                    >
                      {option}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div>
          {options && (
            <>
              <div className="mb-1 flex items-center gap-2 pt-1 text-xs font-semibold">
                Confidence Level:{" "}
                <div>
                  {confidenceLevels.map((option) => {
                    return (
                      <button
                        key={option}
                        type="button"
                        className={`rounded px-2 py-1 font-semibold ${
                          currentConfidenceLevel === option
                            ? "bg-teal-100 text-teal-800"
                            : "text-gray-700 hover:bg-gray-200 hover:text-teal-800"
                        }`}
                        onClick={() =>
                          this.handleFilterChanged({
                            type: "confidenceLevel",
                            value: option,
                          })
                        }
                      >
                        {option}
                      </button>
                    );
                  })}
                </div>
              </div>
              <HighchartWrapper
                ref={this.ref1}
                options={options}
              ></HighchartWrapper>
            </>
          )}
        </div>
      </div>
    );
  }
}

const prefixMetric = {
  rrpm: "$",
  str: "",
  ecpm: "$",
};
const postfixMetric = {
  str: "%",
};
const demandTypeKey = {
  "Network Billable": "network_billable",
  "Google Eligible": "google_eligible",
  Prebid: "prebid",
};
function _createSeriesData({
  data,
  unitInfo,
  filter,
  confidenceReports,
  currentConfidenceLevel,
}) {
  let series = [];
  let godviewData = _.map(data, (d) => {
    return {
      x: d._time * 1000,
      y: d[`_layer${filter.layerNum}_${filter.metric}`],
    };
  });
  // console.log(godviewData);
  series.push({
    name: "godview",
    data: godviewData,
    // color: "#b862ea",
  });

  let confidenceRanges = _.map(data, (d) => {
    const imp =
      d[`_layer${filter.layerNum === "all" ? "1" : filter.layerNum}_imp`];

    const metricValue = d[`_layer${filter.layerNum}_${filter.metric}`];
    const cReports = _.get(
      confidenceReports,
      [
        demandTypeKey[filter.demandTypePreset],
        filter.layerNum === "all" ? "all" : `l${filter.layerNum}`,
      ],
      []
    );

    const cReport =
      _.find(cReports, (c) => {
        return imp < c.imp_less_than;
      }) || _.last(cReports);

    if (!cReport) return;
    const ranges = _.find(cReport[filter.metric], {
      confidence_level: currentConfidenceLevel,
    }).interval;

    return {
      x: d._time * 1000,
      low: metricValue * ranges[0],
      high: metricValue * ranges[1],
    };
  });

  confidenceRanges = _.compact(confidenceRanges);
  if (confidenceRanges.length > 0) {
    series.push({
      name: "confidence range",
      data: confidenceRanges,
      type: "arearange",
      lineWidth: 0,
      linkedTo: ":previous",
      color: Highcharts.getOptions().colors[0],
      fillOpacity: 0.3,
      zIndex: 0,
      marker: {
        enabled: false,
      },
    });
  }

  return series;
}

function _createChartOptions({ seriesData, timezone, filter }) {
  const timezoneOffset = -moment.tz(timezone).utcOffset();
  const { metric, layerNum } = filter;

  let min, max;
  if (metric === "str") {
    min = 0;
    max = 100;
  }

  let valueDecimalPoint = 2;
  if (metric === "req") valueDecimalPoint = 0;
  if (metric === "rrpm_per") valueDecimalPoint = 1;
  if (metric === "rrpm" || metric === "rrpm_net" || metric === "ecpm")
    valueDecimalPoint = 3;

  const chartTitle = "";
  const options = {
    title: {
      text: chartTitle,
    },
    time: {
      timezoneOffset: timezoneOffset,
    },
    chart: {
      type: "line",
      height: 260,
      backgroundColor: "rgba(0,0,0,0)",
      zoomType: "x",
    },
    tooltip: {
      shared: true,
      useHTML: true,
      backgroundColor: "rgba(255,255,255,1)", // use full white to make looking at all those numbers easier to read
      valueDecimals: 3,
      valuePrefix: prefixMetric[metric],
      valuePostfix: postfixMetric[metric],

      // formatter() {
      //   return _tooltipFormatter({
      //     chart: this,
      //     timezone,
      //     // isSortByYValue: true,
      //     // valuePrefix: metric === "cost" ? "$" : "",
      //     valuePostfix:
      //       metric === "str" || metric === "rrpm_per" || metric === "cost"
      //         ? "%"
      //         : "",
      //     valueDecimalPoint,
      //   });
      // },
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false,
        },
      },
    },
    yAxis: [
      {
        title: {
          text: "",
        },
        min,
        max,
      },
    ],
    xAxis: {
      ..._getXAxisConfigDateTime({ timezone }),
    },
    series: seriesData,
  };

  return options;
}

export function _tooltipFormatter({
  chart,
  timezone,
  isSortByYValue = false,
  valuePrefix = "",
  valuePostfix = "",
  valueDecimalPoint = 0,
}) {
  // Sort in following order: o, shadow, b
  const orderIndex = {
    b: 1,
    o: 2,
    o_godview: 3,
  };
  let sortedPoints = _.sortBy(chart.points, (p) => orderIndex[p.series.name]);

  return `
    <div style="text-align: center; font-size: 12px; margin-bottom: 2px;">
      ${moment.tz(chart.x, timezone).format("YYYY-MM-DD HH:mm")}
    </div>

    <div>
      <table style="margin: 0 auto;">
        <tbody>
          ${sortedPoints
            .map((point) => {
              return `
                <tr>
                  <td>
                    <span style="color: ${point.color};">\u25CF</span>
                  </td>
                  <td style="text-align: right; padding-right: 3px;">
                    ${point.series.name} ${
                point.point.__group_type_of_god
                  ? `(${point.point.__group_type_of_god})`
                  : ""
              }:
                  </td>
                  
                  <td style="text-align: right; font-weight: 600;">
                    <span>
                      <span style="font-weight: 400; color: 'light-gray';">${
                        point.point.prefix || valuePrefix
                      }</span>
                      ${Highcharts.numberFormat(point.y, valueDecimalPoint)} 
                        <span style="font-weight: 400; color: 'light-gray';">${
                          point.point.postfix || valuePostfix
                        }</span>
                    </span>
                  </td>
                  <td style="text-align: right; color: gray; padding-left: 4px;">
                    ${
                      point.series.name === "b"
                        ? ""
                        : `<span>
                          ${
                            point.point.diff > 0 ? "+" : ""
                          }${Highcharts.numberFormat(point.point.diff, 3)}
                        </span>`
                    }
                  </td>
                  <td style="text-align: right; padding-left: 4px;">
                    Req:
                  </td>
                  <td style="text-align: right;">
                    <span>
                      ${Highcharts.numberFormat(point.point.req, 0)} 
                    </span>
                  </td>
                </tr>
              `;
            })
            .join("")}
        </tbody>
      </table>
    </div>
  `;
}

function _getXAxisConfigDateTime({ timezone }) {
  return {
    type: "datetime",
    labels: {
      formatter: function () {
        const m = moment(this.value).tz(timezone);
        const d = m.format("DD");
        // const d = m.format("MM/DD");
        const wd = m.format("ddd");
        const label = `${d} <br/> ${wd}`;
        if (d === "01" || this.isFirst) {
          return `${d} <br/> ${wd} <br/> ${m.format("M")}`;
        }
        // if (isWeekend(this.value)) {
        //   return `<span style="color: #dd6b20;">${label}</span>`;
        // }
        return label;
      },
    },
    tickInterval: 24 * 60 * 60 * 1000, // 1 day
    crosshair: true,
  };
}

export default GodviewConfidenceChart;
