import React from "react";
import _ from "lodash";
import RegisteredInventorySuggestionModal from "./RegisteredInventorySuggestionModal";

const titleClass = "block text-gray-800 text-sm font-bold"; //"font-semibold";

class RegisteredInventorySuggestion extends React.Component {
  constructor(props) {
    super(props);

    const { config } = props;
    this.state = {};

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  handleOpenModal() {
    this.setState({ isModalOpen: true });
  }

  handleCloseModal() {
    this.setState({ isModalOpen: false });
  }

  // merge with current config
  handleSave(intConfig) {
    // this.setState({
    //   newIntConfig: intConfig,
    // });
    // const { integration, config } = this.props;
    // // console.log(config, integration);
    // config[integration] = intConfig;
    // this.props.handleConfigChanged(config);
    this.props.handleMergeRIConfig(intConfig);
    this.handleCloseModal();
  }

  render() {
    const { isLoading, errMsg, suggestion, isModalOpen } = this.state;
    const {
      config,
      networkId,
      handleConfigIsInvalid,
      getRISuggestion,
      handleMergeRIConfig,
    } = this.props;

    return (
      <>
        <button
          type="button"
          onClick={this.handleOpenModal}
          className="border rounded shadow bg-white px-4 py-1 hover:bg-gray-100"
        >
          Get Suggestion
        </button>

        {isModalOpen && (
          <RegisteredInventorySuggestionModal
            isModalOpen={isModalOpen}
            handleCloseModal={this.handleCloseModal}
            handleSave={this.handleSave}
            getRISuggestion={getRISuggestion}
          ></RegisteredInventorySuggestionModal>
        )}
      </>
    );
  }
}

export default RegisteredInventorySuggestion;
