import React from "react";
import ReactTooltip from "react-tooltip";
import { FiCopy, FiLink } from "react-icons/fi";
import { CopyToClipboard } from "react-copy-to-clipboard";

class ClickToCopyButton extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isCopied: false,
    };
  }

  render() {
    const { isCopied } = this.state;
    const {
      copyText, // text to copy
      buttonText, // text on button
      tooltipMessage = "Click to copy",
    } = this.props;
    return (
      <>
        <div className="flex items-center">
          <CopyToClipboard
            text={copyText}
            onCopy={() => this.setState({ isCopied: true })}
          >
            <button
              type="button"
              className="hover:bg-blue-300 shadow rounded px-4 py-2 bg-blue-200 text-blue-900 font-semibold flex items-center"
              data-tip
              data-for={copyText}
              onMouseLeave={() => this.setState({ isCopied: false })}
            >
              <span className={buttonText ? "mr-2" : ""}>
                <FiCopy></FiCopy>
              </span>
              {buttonText}
            </button>
          </CopyToClipboard>
          <ReactTooltip id={copyText} type="dark" effect="solid">
            {isCopied ? "Copied!" : tooltipMessage}
          </ReactTooltip>
        </div>
      </>
    );
  }
}

export default ClickToCopyButton;
