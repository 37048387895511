import React from "react";
import _ from "lodash";
import { DashboardAPI } from "apis";
import LoadingUI from "../../common/LoadingUI";
import BillingChart from "./BillingChart";

class IntowowBillingViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errMsg: null,

      isLoading: false,
      reports: null,

      isNewDataLoading: false,
    };

    this.queryAndUpdateUI = this.queryAndUpdateUI.bind(this);
    this.queryBizData = this.queryBizData.bind(this);
  }

  async componentDidMount() {
    document.title = `Business Billing | YB Observer`;

    this.setState({ isLoading: true });
    await this.queryAndUpdateUI();
    this.setState({ isLoading: false });
  }

  async queryAndUpdateUI(params) {
    try {
      const { updatedAt, data } = await this.queryBizData(params);

      if (_.isEmpty(data)) {
        this.setState({ errMsg: "No data found" });
      }

      this.setState({
        reports: data,
      });
    } catch (err) {
      console.log("Error querying business revenue report", err);
    }
  }

  async queryBizData(params) {
    const { updatedAt, data } = await DashboardAPI.getBusinessBillingReports(
      params
    );
    const finalData = _.map(data, (d) => {
      d.date = `${d.month}-01`;
      return d;
    });
    return {
      updatedAt,
      data: finalData,
    };
  }

  render() {
    const { reports, isNewDataLoading, isLoading } = this.state;

    return (
      <>
        <div className="bg-gray-200 px-12 py-8 min-h-full">
          {/* <h2 className="text-3xl font-bold mb-2">Billings of the Past Year</h2> */}

          <div className="p-4 bg-white mb-4">
            {this.state.errMsg && <div>{this.state.errMsg}</div>}

            {isLoading ? (
              <div className="h-screen">
                <LoadingUI></LoadingUI>
              </div>
            ) : (
              <div>
                {reports && (
                  <>
                    <div className="flex justify-end text-gray-800 text-xs mb-1 mr-2">
                      <span style={{ color: "#67B7DD" }}> &#9679; </span>
                      <span>&nbsp;</span>
                      Received: Uses <span>&nbsp;</span>
                      <span className="font-semibold">Received Amount</span>
                    </div>

                    <div className="flex justify-end text-gray-800 text-xs mb-1 mr-2">
                      <span style={{ color: "#98AAB9" }}> &#9679; </span>
                      <span>&nbsp;</span>
                      Pending, <span>&nbsp;</span>
                      <span style={{ color: "#E04848" }}> &#9679; </span>
                      <span>&nbsp;</span>
                      Bad Debt: Uses <span>&nbsp;</span>
                      <span className="font-semibold">
                        Payment Amount (After Tax Amount)
                      </span>
                    </div>

                    <div className="flex justify-end text-gray-800 text-xs mb-4 mr-2">
                      Currency:<span>&nbsp;</span>
                      <span className="font-semibold">TWD</span>
                      <span>&nbsp;</span>$
                    </div>

                    <BillingChart reports={reports}></BillingChart>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default IntowowBillingViewer;
