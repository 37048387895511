import scrypt from "../../libraries/crypto/scrypt";
import { Buffer } from "safe-buffer";
import { createDecipheriv, createCipheriv } from "browserify-aes";
import randomBytes from "randombytes";
import { VaultAPI } from "apis";

// AGLO and KEYLEN are bundled. e.g. 32 bytes for aes256
const ALGO = "aes-256-cbc";
const KEYLEN = 32;
const IVLEN = 16;
const HASHLEN = 64;
const M_SALT = "__INTOWOW_VAULT__";

let masterKey, encryptionKey, masterHash;

export {
  _getVaultSecret,
  _generateMasterKey,
  _generateEncryptionKey,
  _generateMasterHash,
  _encrypt,
  _decrypt,
  getSecret,
  decrypt,
  encrypt,
  initVault,
  generateVaultHash,
  generateUserMasterKey,
};

async function initVault(masterPassword) {
  try {
    const secret = await _getVaultSecret();
    await _initialize(masterPassword, secret);
  } catch (err) {
    console.log("initVault failed", err);
    throw err;
  }
}

function decrypt(enData) {
  if (!encryptionKey) {
    console.log("missing encryptionKey");
  }
  return _decrypt(encryptionKey, enData);
}

function encrypt(data) {
  return _encrypt(encryptionKey, data);
}

function getSecret() {
  // console.log("get secret", masterKey, encryptionKey);
  return {
    masterKey,
    encryptionKey,
  };
}

// key: buffered master key
// enData: vault secret
function _decrypt(key, enData) {
  const enDataBuf = Buffer.from(enData, "hex");
  const iv = enDataBuf.slice(0, IVLEN);
  const encrypted = enDataBuf.slice(IVLEN);

  const decipher = createDecipheriv(ALGO, key, iv);
  let decrypted = decipher.update(encrypted, "binary", "utf8");
  decrypted += decipher.final("utf8");

  return decrypted;
}

function _encrypt(key, data) {
  const iv = randomBytes(IVLEN);
  const cipher = createCipheriv(ALGO, key, iv);

  let encrypted = cipher.update(data, "utf8", "hex");
  encrypted += cipher.final("hex");

  return iv.toString("hex") + encrypted;
}

async function _generateMasterHash(masterKey, encryptionKey) {
  const key = masterKey.toString("hex") + encryptionKey.toString("hex");
  await scrypt(
    key,
    M_SALT,
    {
      N: 16384, // CPU/memory cost parameter (must be power of two; alternatively, you can specify logN where N = 2^logN).
      r: 8, // block size parameter
      p: 1, // parallelization parameter (default is 1)
      dkLen: HASHLEN, // derived key length (default is 32)
      encoding: "hex",
    },
    function (derivedKey) {
      // console.log("masterHash", derivedKey);
      masterHash = derivedKey;
    }
  );

  return masterHash;
}

async function generateUserMasterKey(userPassword) {
  return _generateMasterKey(userPassword);
}

async function generateVaultHash() {
  return _generateMasterHash(masterKey, encryptionKey);
}

async function _getVaultSecret() {
  const { secret } = await VaultAPI.getVaultSetting();
  return secret;
}

async function _initialize(masterPassword, vaultSecret) {
  // get masterKey, encryptionKey
  try {
    masterKey = await _generateMasterKey(masterPassword);
    encryptionKey = _generateEncryptionKey(masterKey, vaultSecret);
    // console.log("init vault", masterKey, encryptionKey);
  } catch (err) {
    console.log("failed to initialize", err);
    throw err;
  }
}

function _generateEncryptionKey(masterKey, vaultSecret) {
  const bufferedEnKey = _decrypt(masterKey, vaultSecret);
  const hexKey = Buffer.from(bufferedEnKey, "hex");
  return hexKey;
}

async function _generateMasterKey(masterPassword) {
  let hexKey;
  await scrypt(
    masterPassword,
    M_SALT,
    {
      N: 16384, // CPU/memory cost parameter (must be power of two; alternatively, you can specify logN where N = 2^logN).
      r: 8, // block size parameter
      p: 1, // parallelization parameter (default is 1)
      dkLen: KEYLEN, // derived key length (default is 32)
      encoding: "hex",
    },
    function (derivedKey) {
      // Important! buffer master key so that both
      // master key and encryption key are buffered
      // and ready to encrypt or decrypt
      hexKey = Buffer.from(derivedKey, "hex");
    }
  );

  return hexKey;
}
