import React from "react";
import _ from "lodash";

const DEFAULT_VALUE = "Actions";

class GoToPageSelector extends React.PureComponent {
  constructor(props) {
    super(props);

    const { goToPaths } = this.props;

    this.state = {
      currentAction: undefined,
      goToPage: DEFAULT_VALUE,
      // paths example: {name: "UPR Segmentation", path: "/ops-mgmt/account/network/123/xxx"}
      paths: goToPaths,

      pathsByPath: _.keyBy(goToPaths, "path"),
    };

    this.handleChanged = this.handleChanged.bind(this);
  }

  handleChanged(e) {
    const path = e.target.value;
    if (path === DEFAULT_VALUE) {
      return;
    }

    window.open(`${window.location.origin}/#${path}`);
    this.setState({ currentAction: this.state.pathsByPath[path] });
  }

  render() {
    const { currentAction } = this.state;
    return (
      <>
        <select
          defaultValue={DEFAULT_VALUE}
          value={currentAction}
          onChange={this.handleChanged}
          className="border rounded shadow mb-2 cursor-pointer border-gray-400 bg-gray-200 px-1 py-1 text-xs font-semibold text-gray-900 hover:bg-gray-300"
        >
          <option value={DEFAULT_VALUE}>{DEFAULT_VALUE}</option>

          {this.state.paths.map((p) => {
            return (
              <option key={p.name} value={p.path} disabled={p.isDisabled}>
                {p.name}
              </option>
            );
          })}
        </select>
      </>
    );
  }
}

export default GoToPageSelector;
