import React from "react";
import _ from "lodash";
import CustomSetTargetingFormatter from "./CustomSetTargetingFormatter";
import CustomTargetingControl from "./CustomTargetingControl";

class CustomSetTargetingControl extends React.PureComponent {
  constructor(props) {
    super(props);
    // props:
    // targeting: [{keyId, valueIds}...]
    // handleValueChanged
    // const {
    //   keyId = "",
    //   valueIds = [],
    //   operator = OPERATOR.IS,
    // } = props.customValue;
    this.state = {
      setsOfCustom: props.targeting || [],
      currentSet: {},
      originalSet: {},

      isEditCardOpen: false,
    };

    this.handleAddNewSet = this.handleAddNewSet.bind(this);
    this.handleEditSet = this.handleEditSet.bind(this);
    this.handleRemoveSet = this.handleRemoveSet.bind(this);
    this.handleCancelNewSet = this.handleCancelNewSet.bind(this);

    this.handleOpenEditCard = this.handleOpenEditCard.bind(this);
    this.handleCloseEditCard = this.handleCloseEditCard.bind(this);
  }

  handleCancelNewSet() {
    // console.log("cancel", this.state.originalSet, this.state.setsOfCustom);
    const setsOfCustom = _.isEmpty(this.state.originalSet)
      ? this.state.setsOfCustom
      : [...this.state.setsOfCustom, this.state.originalSet];

    this.setState({
      currentSet: [],
      originalSet: [],
      setsOfCustom,
      isEditCardOpen: false,
    });

    this.props.handleIsEditing(false);
  }

  handleAddNewSet(newSet) {
    console.log("Add new SEt", newSet);

    const setsOfCustom = [...this.state.setsOfCustom, newSet];

    this.setState({
      currentSet: [],
      originalSet: [],
      setsOfCustom,
      isEditCardOpen: false,
    });

    this.props.handleValueChanged(setsOfCustom);

    this.props.handleIsEditing(false);
  }

  handleEditSet(index) {
    console.log("edit set", index);
    this.setState({
      currentSet: this.state.setsOfCustom[index],
      originalSet: this.state.setsOfCustom[index],
      isEditCardOpen: true,
    });

    // remove from sets of ad sizes
    this.handleRemoveSet(index, true);

    // tell parent is editing, so save button cannot be clicked
    this.props.handleIsEditing(true);
  }

  handleRemoveSet(index, isEdit) {
    console.log("removeing set", index);
    const sets = [...this.state.setsOfCustom];
    sets.splice(index, 1);
    this.setState({
      setsOfCustom: sets,
    });

    if (!isEdit) {
      this.props.handleValueChanged(sets);
    }
  }

  handleOpenEditCard() {
    this.setState({
      isEditCardOpen: true,
    });
    this.props.handleIsEditing(true);
  }

  handleCloseEditCard() {
    this.setState({
      isEditCardOpen: false,
    });
    this.props.handleIsEditing(false);
  }

  render() {
    const { setsOfCustom, currentSet, isEditCardOpen } = this.state;
    const { networkId } = this.props;

    return (
      <>
        <div className="ml-4 text-sm">
          <div className="mb-1">
            <div className="text-gray-800">
              Customs ({setsOfCustom.length}):
            </div>
            {setsOfCustom.length === 0 ? (
              "None"
            ) : (
              <CustomSetTargetingFormatter
                networkId={networkId}
                setsOfCustom={setsOfCustom}
                showActionButtons={true}
                handleRemoveSet={this.handleRemoveSet}
                handleEditSet={this.handleEditSet}
              ></CustomSetTargetingFormatter>
            )}

            <div>
              <button
                type="button"
                className="rounded shadow bg-blue-100 px-2 py-1 text-blue-600 hover:bg-blue-200"
                onClick={this.handleOpenEditCard}
              >
                Create new custom
              </button>
            </div>
          </div>

          {isEditCardOpen && (
            <CustomTargetingControl
              networkId={networkId}
              customValue={currentSet}
              handleAddNewSet={this.handleAddNewSet}
              handleCancelNewSet={this.handleCancelNewSet}
            ></CustomTargetingControl>
          )}
        </div>
      </>
    );
  }
}

export default CustomSetTargetingControl;
