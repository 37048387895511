import React from "react";
import _ from "lodash";
import { notify } from "react-notify-toast";
import { VseAPI } from "apis";
import VSEPageWrapper from "./VSEPageWrapper";
import DateTimeFormatter from "../common/DateTimeFormatter";
import PopupWrapper from "../common/PopupWrapper";
import RecipeBox from "./recipe/RecipeBox";
import ReactTooltip from "react-tooltip";
import ModalWrapper from "../common/ModalWrapper";
import VSECheckBox from "./VSECheckbox";

class ExecutionsViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,

      data: null,

      selectedItems: [],
      isModalOpen: false,
      isSaving: false,
      saveErrMsg: null,
    };

    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleSelectItem = this.handleSelectItem.bind(this);
    this.handleClearAll = this.handleClearAll.bind(this);
    this.handleResetClicked = this.handleResetClicked.bind(this);

    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleConfirmReset = this.handleConfirmReset.bind(this);
  }

  async componentDidMount() {
    const networkId = _.get(this.props, "networkId");

    if (networkId) {
      document.title = `E: ${networkId} | VSE Executions`;
    }

    await this._queryAndUpdateUI({ networkId: _.parseInt(networkId) });
  }

  async _queryAndUpdateUI(params) {
    this.setState({ isLoading: true });
    try {
      const { items, networkInfo } = await this.queryData(params);

      if (_.isEmpty(items)) {
        return this.setState({ errMsg: "No executions found" });
      }

      this.setState({
        data: items,
        networkInfo,
      });
    } catch (err) {
      console.log("Error querying vse executions", err);
    }
    this.setState({ isLoading: false });
  }

  async queryData(params) {
    const { executions, networkInfo } = await VseAPI.getVSEExecutionsByNetwork(
      params
    );
    const { recipes } = this.props;
    const recipesByIdVersion = _.keyBy(recipes, (r) => `${r.id}_${r.version}`);
    // console.log(executions);
    // console.log(recipes);

    const items = _.map(executions, (exe) => {
      exe.key = `${exe.recipe_id}_${exe.version}`;
      exe.recipe = recipesByIdVersion[exe.key];
      return exe;
    });

    return { items, networkInfo };
  }

  handleSelectAll() {
    const { selectedItems, data } = this.state;
    if (selectedItems.length === data.length) {
      this.setState({
        selectedItems: [],
      });
    } else {
      this.setState({ selectedItems: _.map(data, "recipe_id") });
    }
  }

  handleSelectItem(recipeId) {
    const { selectedItems } = this.state;

    if (_.indexOf(selectedItems, recipeId) === -1) {
      this.setState({
        selectedItems: [...selectedItems, recipeId],
      });
    } else {
      const newSelectedItems = selectedItems;
      _.pull(newSelectedItems, recipeId);
      this.setState({ selectedItems: newSelectedItems });
    }
  }

  handleClearAll() {
    this.setState({ selectedItems: [] });
  }

  handleResetClicked() {
    this.setState({ isModalOpen: true });
  }

  handleModalClose() {
    this.setState({ isModalOpen: false });
  }

  async handleConfirmReset() {
    const params = {
      networkId: this.props.networkId,
      recipeIds: this.state.selectedItems,
    };

    this.setState({ isSaving: true });

    try {
      const { result } = await VseAPI.resetVSEExecutions(params);
      if (result.op === "ok") {
        notify.show("Reset successful! Reloading page...", "success");
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      } else {
        throw `Failed response: ${JSON.stringify(result)}`;
      }
    } catch (err) {
      this.setState({ saveErrMsg: err });
    }

    this.setState({ isSaving: false });
  }

  render() {
    const {
      isLoading,
      errMsg,
      networkInfo,
      data,
      selectedItems,
      isModalOpen,
      isSaving,
      saveErrMsg,
    } = this.state;

    return (
      <VSEPageWrapper
        isLoading={isLoading}
        errMsg={errMsg}
        networkInfo={networkInfo}
      >
        {data && (
          <div>
            <div className="font-semibold text-lg">
              {data && data.length} Executions
            </div>
            <ExecutionsTable
              items={data}
              selectedItems={selectedItems}
              handleSelectAll={this.handleSelectAll}
              handleSelectItem={this.handleSelectItem}
            ></ExecutionsTable>

            <ActionFooter
              selectedItems={selectedItems}
              handleClearAll={this.handleClearAll}
              handleResetClicked={this.handleResetClicked}
            ></ActionFooter>

            <ModalWrapper
              isOpen={isModalOpen}
              handleClose={this.handleModalClose}
              width="60%"
              showCloseFooter={false}
            >
              <div>
                <div className="font-bold text-xl text-gray-700 mb-2">
                  Reset the following recipes ({selectedItems.length}):
                </div>

                <div>
                  <table className="w-full table text-sm border shadow">
                    <thead className="bg-gray-200 text-blue-800 text-xs border-b">
                      <tr>
                        <th className="border py-2 px-2">Recipes</th>
                      </tr>
                    </thead>
                    <tbody className="text-gray-900 font-mono bg-white">
                      {selectedItems &&
                        selectedItems.map((recipeId) => {
                          const r = _.find(data, { recipe_id: recipeId });
                          return (
                            <tr
                              key={recipeId}
                              className="border-b hover:bg-gray-100"
                            >
                              <td className="font-semibold py-2 px-2 whitespace-no-wrap border font-sans">
                                <RecipeView recipe={r.recipe}></RecipeView>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>

                <div className="flex flex-row-reverse mt-4 items-center">
                  <div>
                    <button
                      type="button"
                      className="px-4 py-2 bg-blue-500 rounded shadow hover:bg-blue-700 text-white font-semibold"
                      onClick={this.handleConfirmReset}
                    >
                      {isSaving ? "Saving..." : "Confirm reset"}
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      disabled={isSaving}
                      className={`px-4 py-2 text-blue-700 ${
                        isSaving ? "cursor-not-allowed" : ""
                      }`}
                      onClick={this.handleModalClose}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="text-red-600">{saveErrMsg}</div>
                </div>
              </div>
            </ModalWrapper>
          </div>
        )}
      </VSEPageWrapper>
    );
  }
}

const ExecutionsTable = (props) => {
  const { items, selectedItems, handleSelectAll, handleSelectItem } = props;

  const sortedItems = _sortItems(items);

  function _sortItems(items) {
    return _.orderBy(items, [_sort], ["desc"]);
  }

  function _sort(item) {
    return item.next_execution_at;
  }

  return (
    <div>
      <table
        className="w-full table text-sm border shadow"
        style={{ marginBottom: "500px" }}
      >
        <thead className="bg-gray-200 text-blue-800 text-xs border-b">
          <tr>
            <th>
              <div className="w-12">
                <VSECheckBox
                  isChecked={selectedItems.length === items.length}
                  handleClick={handleSelectAll}
                ></VSECheckBox>
              </div>
            </th>
            <th className="border py-2 px-2">Recipe</th>
            <th className="border py-2 px-2">Last execution at</th>
            <th className="border py-2 px-2">Next execution at</th>
          </tr>
        </thead>
        <tbody className="text-gray-900 font-mono bg-white">
          {items &&
            sortedItems.map((r) => {
              return (
                <tr key={r.key} className="border-b hover:bg-gray-100">
                  <td className="w-12 text-center">
                    <VSECheckBox
                      isChecked={selectedItems.indexOf(r.recipe_id) !== -1}
                      handleClick={() => handleSelectItem(r.recipe_id)}
                    ></VSECheckBox>
                  </td>
                  <td className="font-semibold py-2 px-2 whitespace-no-wrap border font-sans">
                    <RecipeView recipe={r.recipe}></RecipeView>
                  </td>
                  <td className="py-1 px-3 whitespace-no-wrap border text-left">
                    <DateTimeFormatter
                      datetime={r.last_execution_at}
                    ></DateTimeFormatter>
                  </td>
                  <td className="py-1 px-3 whitespace-no-wrap border text-left">
                    <DateTimeFormatter
                      datetime={r.next_execution_at}
                    ></DateTimeFormatter>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

const RecipeView = (props) => {
  const { recipe } = props;
  // id
  // version
  // name
  // ...

  return (
    <>
      <PopupWrapper
        place="bottom left"
        hideArrow={true}
        triggerElement={
          <div className="px-2 bg-gray-200 rounded border cursor-pointer">
            {recipe.id} - {recipe.name}
          </div>
        }
        popupElement={
          <div
            className="shadow-lg border-4 border-gray-600"
            style={{ marginTop: "-9px" }}
          >
            <RecipeBox item={recipe}></RecipeBox>
          </div>
        }
      ></PopupWrapper>
    </>
  );
};

const ActionFooter = (props) => {
  const { selectedItems, handleClearAll, handleResetClicked } = props;
  return (
    <div className="fixed bottom-0 left-0 w-full bg-white border-t-2 px-4 py-2">
      <div className="flex gap-2 items-center text-gray-700">
        <div className="text-sm w-48 text-right">
          Selected Recipes ({selectedItems.length}):
        </div>
        <textarea
          rows="1"
          value={selectedItems.join(", ")}
          readOnly
          className="w-full border rounded p-1 text-xs"
        ></textarea>
        <div className="flex items-center gap-4">
          <button
            className="text-blue-900 rounded font-semibold bg-blue-200 hover:bg-blue-300 px-2 py-1 text-sm overflow-x-auto"
            type="button"
            data-tip
            data-for="reset-recipes-button"
            onClick={handleResetClicked}
            disabled={selectedItems.length === 0}
          >
            Reset
          </button>
          <ReactTooltip id="reset-recipes-button" type="dark" effect="solid">
            See reset recipes in preview
          </ReactTooltip>
          <button
            className="text-blue-600 rounded hover:bg-blue-100 px-4 py-1 text-sm overflow-x-auto"
            type="button"
            onClick={handleClearAll}
          >
            Clear
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExecutionsViewer;
