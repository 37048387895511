import _ from "lodash";
import moment from "moment-timezone";

// to pretend if user is in this timezone
// moment.tz.setDefault("America/New_York");

export function formatMoneyWithCurrency({ currency, value }) {
  return Number(value).toLocaleString("en-US", {
    style: "currency",
    currency,
  });
}

export function transformPlatfromIndexData(originalData) {
  if (!originalData) return;
  let data = originalData;

  data.exchangeRates = _transformExchangeRates(data.exchangeRates);
  data.reports = _transformToJson(data.reports);

  // // convert to the selected currency
  // data.reports = convertReportsByCurrency({
  //   reports: data.reports,
  //   exchangeRates: data.exchangeRates,
  //   targetCurrency: "TWD",
  // });
  return data;
}

export function convertReportsByCurrency({
  reports,
  exchangeRates,
  targetCurrency,
}) {
  return _.map(reports, (r) => {
    // currency is different per month
    const month = r.date.substring(0, "2020-10".length);
    const currencyRate = exchangeRates[targetCurrency][month];

    r.ecpm = r.ecpm * currencyRate;
    r.request_rpm = r.request_rpm * currencyRate;
    return r;
  });
}

function _isDateBetween({ date, startDate, endDate }) {
  return moment(date).isBetween(startDate, endDate, undefined, "[]");
}

function _normalizeRevenue(revenue) {
  return revenue / 1000000;
}

function _transformExchangeRates(exchangeRates) {
  /*
  {
    USD: {
      "2020-09": 1
    },
    ...
  }
  */
  let currencyMap = {};
  _.forEach(exchangeRates, (e) => {
    currencyMap[e.currency] = _.reduce(
      e.rates,
      (result, r) => {
        const month = r[0];
        const rate = r[1];
        result[month] = rate;
        return result;
      },
      {}
    );
  });

  return currencyMap;
}

function _transformToJson(reports) {
  // transform reports to json format
  const { columns, records } = reports;

  return _.reduce(
    records,
    (result, row) => {
      let r = {};
      _.forEach(columns, (column, index) => {
        r[column] = row[index];
      });
      result.push(r);
      return result;
    },
    []
  );
}
