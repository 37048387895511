import React from "react";
import _ from "lodash";
import Select from "react-select";

const BROWSER_MAP = {
  "Android Browser (x.x)": "500105",
  "Firefox (x.x)": "500084",
  "Google Chrome (x.x)": "500072",
  "Microsoft Edge (x.x)": "500153",
  "Microsoft Internet Explorer (x.x)": "500078",
  "Mozilla (x.x)": "500085",
  "Netscape Navigator (x.x)": "500086",
  "Opera (x.x)": "500093",
  "Safari (iPad) (x.x)": "500103",
  "Safari (iPhone/iPod) (x.x)": "500104",
  "Safari (x.x)": "500102",
};

// can be multiselect
class GamBrowserSelector extends React.PureComponent {
  constructor(props) {
    super(props);
    // props:
    // selectedItems
    // handleChanged

    const { selectedItems } = props;
    const keys = _.keys(BROWSER_MAP);
    const allCategories = _.map(keys, (c) => {
      return {
        id: BROWSER_MAP[c],
        name: c,
      };
    });
    const options = _.map(allCategories, (c) => {
      return { value: c.id, label: c.name };
    });

    const selectedValue = _.filter(options, (option) => {
      return _.indexOf(selectedItems, option.value) !== -1;
    });

    this.state = {
      options,
      selectedValue,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(options) {
    this.setState({
      selectedValue: options,
    });

    this.props.handleChanged(_.map(options, "value"));
  }

  render() {
    const { options, selectedValue } = this.state;

    return (
      <>
        <Select
          // className="w-24 z-50"
          defaultValue={selectedValue}
          value={selectedValue}
          onChange={this.handleChange}
          options={options}
          isSearchable={true}
          isMulti={true}
          // styles={{
          //   control: () => ({
          //     paddingLeft: 3,
          //   }),
          // }}
        />
      </>
    );
  }
}

export default GamBrowserSelector;
