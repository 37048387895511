import React from "react";
import { FiInfo } from "react-icons/fi";
import { MetricDescription } from "./MetricDescription";
import BetterPopupWrapper from "../../common/BetterPopupWrapper";

class PopoverForTrendDescription extends React.PureComponent {
  render() {
    const { metricKey, position } = this.props;

    if (!metricKey) {
      return "";
    }

    const m = MetricDescription[metricKey];

    if (!m) {
      return "";
    }

    const { title, img, metrics } = m;

    const contentElement = () => {
      return (
        <>
          <div
            className="text-left text-gray-800"
            style={{
              maxWidth: img ? "800px" : "400px",
              padding: "8px 16px",
              fontSize: "0.85rem",
            }}
          >
            <div className="font-semibold text-lg mb-2">{title}:</div>
            <div className="flex items-center">
              {img && (
                <div className="mr-8">
                  <img src={img} alt={title}></img>
                </div>
              )}

              <div>
                {metrics.map((tm) => {
                  const {
                    title,
                    symbolShape,
                    symbolColor,
                    desc,
                    desc2,
                    desc3,
                  } = tm;
                  const Symbol = () => {
                    if (symbolShape === "square") {
                      return (
                        <div
                          style={{
                            width: "12px",
                            height: "12px",
                            backgroundColor: symbolColor,
                          }}
                        ></div>
                      );
                    }

                    if (symbolShape === "circle") {
                      return (
                        <div
                          style={{
                            width: "12px",
                            height: "12px",
                            backgroundColor: symbolColor,
                            borderRadius: 99,
                          }}
                        ></div>
                      );
                    }

                    if (symbolShape === "line") {
                      return (
                        <div
                          style={{
                            width: "12px",
                            height: "2px",
                            backgroundColor: symbolColor,
                          }}
                        ></div>
                      );
                    }

                    return "";
                  };

                  return (
                    <div className="mb-2" key={title}>
                      <div className="flex items-center">
                        <Symbol></Symbol>
                        <div
                          className="font-bold"
                          style={{ marginLeft: "8px" }}
                        >
                          {title}
                        </div>
                      </div>

                      <div
                        className="leading-snug"
                        style={{ marginLeft: "18px" }}
                      >
                        <div>{desc}</div>
                        {desc2 && (
                          <div>
                            <br></br>
                            {desc2}
                          </div>
                        )}
                        {desc3 && (
                          <div>
                            <br></br>
                            {desc3}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      );
    };

    return (
      <BetterPopupWrapper
        // ref={this.child}
        position={position || "top"}
        // position="left bottom"
        triggerType="hover"
        triggerElement={
          <div className="ml-1 text-blue-400 hover:text-blue-600 cursor-pointer">
            <FiInfo></FiInfo>
          </div>
        }
        contentElement={contentElement}
        keepInside={false}
      ></BetterPopupWrapper>
    );
  }
}

export default PopoverForTrendDescription;
