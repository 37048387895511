import React from "react";
import _ from "lodash";
import { DashboardAPI } from "apis";
import LoadingUI from "../../common/LoadingUI";
import BillablePotentialChart from "./BillablePotentialChart";
import NetworkRevWrapper from "./NetworkRevWrapper";
import EstimateRevSection from "./EstimateRevSection";

const DATE_RANGES = [3, 6, 9, 12, 18, 24, 36]; // months
function _formatDate(date) {
  let oldDate = new Date(date);
  const yyyy = oldDate.getFullYear().toString();
  const mm = (oldDate.getMonth() + 1).toString(); // getMonth() is zero-based
  const dd = oldDate.getDate().toString();

  const newDateString =
    yyyy + "-" + (mm[1] ? mm : "0" + mm[0]) + "-" + (dd[1] ? dd : "0" + dd[0]);
  return newDateString;
}

class IntowowRevenueViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errMsg: null,

      isLoading: false,
      reports: null,
      estimateReport: null,

      currentDateRange: 3, // Default: Last 3 months

      isNewDataLoading: false,
    };

    this.queryAndUpdateUI = this.queryAndUpdateUI.bind(this);
    this.queryBizData = this.queryBizData.bind(this);

    this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
  }

  async componentDidMount() {
    document.title = `Business Revenue | YB Observer`;

    this.setState({ isLoading: true });
    await this.queryAndUpdateUI({ numOfMonths: this.state.currentDateRange });
    this.setState({ isLoading: false });
  }

  async queryAndUpdateUI(params) {
    try {
      const { reports, estimateReport } = await this.queryBizData(params);

      if (_.isEmpty(reports)) {
        this.setState({ errMsg: "No data found" });
      }

      this.setState({
        reports,
        estimateReport,
      });
    } catch (err) {
      console.log("Error querying business revenue report", err);
    }
  }

  transformToDailyAccuData(reports) {
    let reportsByDate = {};

    _.forEach(reports, (report) => {
      const date = report.date;
      if (!reportsByDate[date]) {
        reportsByDate[date] = {
          date,
          potentialRev: report.potentialRev,
          billableRev: report.billableRev,
        };
      } else {
        reportsByDate[date].potentialRev += report.potentialRev;
        reportsByDate[date].billableRev += report.billableRev;
      }
    });

    const data = _.values(reportsByDate);

    let reportsSortedByDate = _.sortBy(data, "date");

    // accumulate per month
    let pAcc = 0;
    let bAcc = 0;
    let currentMonth = reportsSortedByDate[0].date.substr(5, 2); //get oldest month

    _.forEach(reportsSortedByDate, (r) => {
      const month = r.date.substr(5, 2);
      if (currentMonth !== month) {
        pAcc = 0;
        bAcc = 0;
      }
      r.potentialAccRev = pAcc + r.potentialRev;
      r.billableAccRev = bAcc + r.billableRev;

      pAcc = r.potentialAccRev;
      bAcc = r.billableAccRev;
      currentMonth = month;
    });

    return {
      reportsSortedByDate,
    };
  }

  calculateMoMData(reports) {
    // let finalReports = [];
    const reportsByDate = _.keyBy(reports, "date");
    const finalReports = _.map(reports, (report) => {
      // get last month current date
      // const lastMonthCurrentDate = this.getLastMonthCurrentDate(report.date);
      // const lastMonthReport = reportsByDate[lastMonthCurrentDate]; // || reportsByDate[lastDateLastMonth];

      // if (lastMonthReport) {
      //   report.lastMonthBillableAccRev = lastMonthReport.billableAccRev;
      // }

      // Important! find the closest month progress of last month
      const lastMonthComparisonDate = this.getLastMonthComparisonDate(
        report.date
      );
      const lastMonthBillableAccRev = _.get(
        reportsByDate[lastMonthComparisonDate],
        "billableAccRev"
      );
      report.lastMonthBillableAccRev = lastMonthBillableAccRev;
      report.lastMonthComparisonDate = lastMonthComparisonDate;

      return report;
    });
    return finalReports;
  }

  // currentDate = "2021-11-02"
  getMTDProgress(currentFullDate) {
    // get days of month
    // new Date("2021", "12", 0).getDate() => get days of Dec
    const parts = currentFullDate.split("-");
    const y = parseInt(parts[0]);
    const m = parseInt(parts[1]);
    const currentDate = parseInt(parts[2]);
    // new Date(year, month index, date)
    // Important! Setting day parameter to 0 means one day less than
    // first day of the month which is last day of the previous month.
    const daysOfCurrentMonth = new Date(y, m, 0).getDate();
    const daysOfLastMonth = new Date(y, m - 1, 0).getDate();

    let currentMtdProgress = daysOfCurrentMonth - currentDate;
    let lastMtdProgress = daysOfLastMonth - currentDate;

    const lastDateCurrentMonth = _formatDate(
      new Date(y, m - 1, daysOfCurrentMonth)
    );
    const lastDateLastMonth = _formatDate(new Date(y, m - 2, daysOfLastMonth));

    return {
      currentDate,
      daysOfCurrentMonth,
      daysOfLastMonth,
      lastDateCurrentMonth,
      lastDateLastMonth,
      currentMtdProgress,
      lastMtdProgress,
    };
  }

  // currentFullDate = "2021-11-02"
  getLastMonthComparisonDate(currentFullDate) {
    const { daysOfLastMonth, daysOfCurrentMonth, currentDate } =
      this.getMTDProgress(currentFullDate);
    const currentMonthProgressPercentage = currentDate / daysOfCurrentMonth;
    const lastMonthProgressAccordingToCurrentMonth = Math.round(
      daysOfLastMonth * currentMonthProgressPercentage
    );
    const lastMonthPDay = `${currentFullDate.substr(0, 7)}-${
      lastMonthProgressAccordingToCurrentMonth < 10 ? "0" : ""
    }${lastMonthProgressAccordingToCurrentMonth}`;
    const lastMonthProgressDate = this.getLastMonthCurrentDate(lastMonthPDay);
    return lastMonthProgressDate;
  }

  // currentDate = "2021-11-02"
  // Important! regardless of if that month has current date
  // so 2021-02-31 is a valid return value for this function
  getLastMonthCurrentDate(currentFullDate) {
    // 1. if current date = 01, last month current date = 01
    // 2. if current date = 31, last month current date = 30
    // 3. if current date = 20, last month current date = 20
    // *. if current month = 01, last month should be 12, year - 1
    // *. if current month = 02, month - 1
    const parts = currentFullDate.split("-");
    const year = parts[0];
    const month = parts[1];
    const date = parts[2];

    const lastMonthYear = month === "01" ? parseInt(year) - 1 : year;
    const lastMonthMonth = month === "01" ? "12" : parseInt(month) - 1;
    const lastMonthDate = date;

    return `${lastMonthYear}-${
      lastMonthMonth < 10 ? "0" + lastMonthMonth : lastMonthMonth
    }-${lastMonthDate}`;
  }

  // reports is sorted by date
  // reportsWithMoM to get last month's comparison data
  calculateEstimateReport(reports) {
    const reportsByDate = _.keyBy(reports, "date");
    // the last report will be the current date
    const currentReport = _.last(reports);
    // const currentReport = reportsByDate["2021-10-01"]; // for testing
    const currentDate = currentReport.date;

    let estimateReport = {};
    // only calculate if last month data exists
    // 1. get current date (ex. 2021-11-29, date = 29)
    // 2. get last month date (ex. 2021-10-29)

    // R($date) = Accumulated Billable Revenue of a certain date
    // CD-M   : current date of this month
    // LD-M   : last date of this month
    // CD-M-1 : current date of last month
    // LD-M-1 : last date of last month
    // LD-M - CD-M : current month progress
    // LD-M-1 - CD-M-1 : last month progress according to current date
    // R(CD-M) + ((LD-M - CD-M) * (R(LD-M-1) - R(CD-M-1)) / (LD-M-1 - CD-M-1))
    // R(CD-M) + ((LD-M - CD-M) * (R(LD-M-1)) / (LD-M-1))
    const {
      currentMtdProgress,
      lastMtdProgress,
      lastDateCurrentMonth,
      lastDateLastMonth,
      daysOfCurrentMonth,
      daysOfLastMonth,
    } = this.getMTDProgress(currentDate);

    const lastMonthCurrentDate = this.getLastMonthCurrentDate(currentDate);
    const lastMonthReport =
      reportsByDate[lastMonthCurrentDate] || reportsByDate[lastDateLastMonth];

    const revKey = "billableAccRev";
    let revCurrentDateThisMonth = currentReport[revKey];
    let revCurrentDateLastMonth = lastMonthReport[revKey];
    let lastDateCurrentMonthReport = reportsByDate[lastDateCurrentMonth] || {};
    let lastDateLastMonthReport = reportsByDate[lastDateLastMonth] || {};

    let revLastDateThisMonth = lastDateCurrentMonthReport[revKey] || 0;
    let revLastDateLastMonth = lastDateLastMonthReport[revKey] || 0;
    let lastMonthRevProgress =
      lastMtdProgress > 0
        ? revLastDateLastMonth - revCurrentDateLastMonth
        : revLastDateLastMonth;

    let estimateRev =
      revCurrentDateThisMonth +
      (currentMtdProgress * lastMonthRevProgress) /
        (lastMtdProgress > 0 ? lastMtdProgress : daysOfLastMonth);
    estimateReport.estimateRev = _.round(estimateRev, 2);
    //
    estimateReport.revCurrentDateThisMonth = revCurrentDateThisMonth;
    estimateReport.currentMtdProgress = currentMtdProgress;
    estimateReport.lastMonthRevProgress = lastMonthRevProgress;
    estimateReport.revLastDateLastMonth = revLastDateLastMonth;
    estimateReport.revCurrentDateLastMonth = revCurrentDateLastMonth;
    estimateReport.daysOfLastMonth = daysOfLastMonth;
    estimateReport.lastMtdProgress = lastMtdProgress;

    const lastMonthComparisonDate =
      this.getLastMonthComparisonDate(currentDate);

    if (reportsByDate[lastMonthComparisonDate]) {
      estimateReport.compareCurrentDateLastMonthRev =
        reportsByDate[lastMonthComparisonDate].billableAccRev;
    }

    return estimateReport;
  }

  async queryBizData(params) {
    const { updatedAt, data } = await DashboardAPI.getBusinessRevenueReports(
      params
    );
    const { reportsSortedByDate } = this.transformToDailyAccuData(data);
    // Important! Current day is not over, so don't use current day
    reportsSortedByDate.pop(); // remove current day
    const reportsWithMoM = this.calculateMoMData(reportsSortedByDate);
    const estimateReport = this.calculateEstimateReport(reportsSortedByDate);
    return {
      // reports: reportsSortedByDate,
      reports: reportsWithMoM,
      estimateReport,
    };
  }

  async handleDateRangeChange(numOfMonths) {
    if (numOfMonths === this.state.currentDateRange) return;
    this.setState({ currentDateRange: numOfMonths });

    this.setState({ isNewDataLoading: true });
    await this.queryAndUpdateUI({ numOfMonths });
    this.setState({ isNewDataLoading: false });
  }

  render() {
    const {
      reports,
      isNewDataLoading,
      isLoading,
      currentDateRange,
      estimateReport,
    } = this.state;

    return (
      <>
        {this.state.errMsg && <div>{this.state.errMsg}</div>}

        <div className="bg-gray-200 px-12 py-8">
          <h2 className="text-3xl font-bold mb-2">
            Monthly Accumulated Revenue
          </h2>
          <div className="p-4 bg-white mb-4">
            {isLoading ? (
              <div className="h-screen">
                <LoadingUI></LoadingUI>
              </div>
            ) : (
              <>
                <div className="p-4 mb-4">
                  <h2 className="text-2xl font-bold">Summary</h2>
                  <div>
                    {estimateReport && (
                      <EstimateRevSection
                        estimateReport={estimateReport}
                      ></EstimateRevSection>
                    )}
                  </div>
                </div>

                <div className="p-4 bg-white mb-4">
                  <h2 className="text-2xl font-bold mb-4">Revenue Trend</h2>
                  {isNewDataLoading ? (
                    <div className="h-screen">
                      <LoadingUI></LoadingUI>
                    </div>
                  ) : (
                    <>
                      <div className="font-semibold text-indigo-900">
                        Date Ranges:
                      </div>
                      <div className="flex justify-start items-center mb-8 text-sm">
                        {DATE_RANGES.map((dr) => {
                          return (
                            <button
                              key={dr}
                              type="button"
                              className={`px-4 py-2 mr-1 font-semibold rounded ${
                                currentDateRange === dr
                                  ? "bg-indigo-100 text-indigo-800"
                                  : "text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
                              }`}
                              onClick={() => this.handleDateRangeChange(dr)}
                            >
                              Last {dr} Months
                            </button>
                          );
                        })}
                      </div>

                      <BillablePotentialChart
                        reports={reports}
                      ></BillablePotentialChart>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>

        <div className="bg-gray-200 px-12 py-8 min-h-full">
          <h2 className="text-3xl font-bold mb-2">By Network Revenue</h2>
          <div className="p-4 bg-white mb-4">
            <NetworkRevWrapper></NetworkRevWrapper>
          </div>
        </div>
      </>
    );
  }
}

export default IntowowRevenueViewer;
