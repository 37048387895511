import React from "react";
import _ from "lodash";
import { AutoPilotAPI } from "apis";

import { notify } from "react-notify-toast";
import LoadingUI from "../../common/LoadingUI";
import ConfigGroupView from "./ConfigGroupView";
import ConfigGroupModal from "./ConfigGroupModal";
import DateTimeFormatter from "../../common/DateTimeFormatter";
import Checkbox from "../../common/Checkbox";
import SegmentBasicTable from "../../common/SegmentBasicTable";

class ConfigWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      configId: null,
      item: null, // original config

      segmentId: "",
      unitId: null,
      metadata: null, // segment metadata
      segmentSearchError: null,

      trafficRatio: "",
      config: {},
      createdAt: null,

      editingGroup: null,
      editingGroupIndex: null,
      isEditModal: false,

      isLoading: false,
      errMsg: null,

      shouldForceIterate: true,
      isSaving: false,
      saveError: null,

      isModalOpen: false,
    };

    this.searchSegment = this.searchSegment.bind(this);
    this.handleInputChanged = this.handleInputChanged.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleAddGroup = this.handleAddGroup.bind(this);
    this.handleEditGroup = this.handleEditGroup.bind(this);
    this.handleOpenEditModal = this.handleOpenEditModal.bind(this);
    this.handleRemoveGroup = this.handleRemoveGroup.bind(this);
    this.handleOpenDuplicateModal = this.handleOpenDuplicateModal.bind(this);
    this.handleOpenAddModal = this.handleOpenAddModal.bind(this);
    this.saveConfig = this.saveConfig.bind(this);
    this.toggleShouldForceIterate = this.toggleShouldForceIterate.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });

    try {
      const configId = _.get(this.props, "match.params.configId");
      console.log(configId);

      if (configId) {
        document.title = `Update Segment Research Group Config`;
        const item = await AutoPilotAPI.getSegmentResearchConfigById({
          configId,
        });
        console.log(item);

        const config = JSON.parse(item.config);

        this.setState({
          configId,
          segmentId: item.segment_id,
          item,
          metadata: item,
          unitId: item.unit_id,
          trafficRatio: item.traffic_ratio.toString(),
          config,
          createdAt: item.created_at,
        });
      } else {
        document.title = `Create Segment Research Group Config`;
      }
    } catch (err) {
      console.log(
        "Error querying auto pilot segment research group config",
        err
      );
      this.setState({ errMsg: typeof err === "object" ? err.toString() : err });
    }

    this.setState({ isLoading: false });
  }

  async saveConfig() {
    // check
    // saveError
    if (!this.state.segmentId || this.state.segmentId === "") {
      this.setState({ saveError: "Missing Segment ID" });
      return;
    }
    if (!this.state.unitId) {
      this.setState({
        saveError: "Search Segment to make sure Segment ID is valid",
      });
      return;
    }
    if (!this.state.trafficRatio || this.state.trafficRatio === "") {
      this.setState({ saveError: "Missing Traffic Ratio" });
      return;
    }
    if (this.state.trafficRatio > 60) {
      this.setState({ saveError: "Total Traffic Ratio should not be over 60" });
      return;
    }
    if (
      !this.state.config ||
      this.state.config === "" ||
      this.state.config === "{}" ||
      !this.state.config.groups
    ) {
      this.setState({ saveError: "Missing Config" });
      return;
    }
    this.setState({ isSaving: true, saveError: null });

    try {
      const config = JSON.stringify(this.state.config);

      const params = {
        segmentId: this.state.segmentId,
        trafficRatio: this.state.trafficRatio,
        config,
        commandOptions: {
          shouldForceIterate: this.state.shouldForceIterate,
        },
      };

      console.log(params);
      const r = await AutoPilotAPI.createSegmentResearchConfig(params);
      console.log(r);

      notify.show("Config Saved!", "success");

      setTimeout(() => {
        this.props.history.push("/segment-research/list");
      }, 3000);
    } catch (err) {
      console.log(err);
      notify.show("Failed to save config", "error");

      this.setState({
        saveError: err.toString(),
      });
    }
    this.setState({ isSaving: false });
  }

  async searchSegment() {
    if (!this.state.segmentId || this.state.segmentId === "") {
      return;
    }

    try {
      const segmentId = this.state.segmentId;
      const r = await AutoPilotAPI.getSegmentMetadata(segmentId);

      this.setState({
        metadata: r,
        unitId: r.unit_id,
        segmentSearchError: null,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        metadata: null,
        segmentSearchError: "Invalid segment ID",
      });
    }
  }

  handleInputChanged(key, value) {
    this.setState({
      [key]: value,
    });
  }

  handleOpenModal() {
    this.setState({ isModalOpen: true });
  }

  handleCloseModal() {
    this.setState({
      isModalOpen: false,
      editingGroup: null,
      editingGroupIndex: null,
    });
  }

  handleOpenAddModal() {
    this.setState({
      isEditModal: false,
    });
    this.handleOpenModal();
  }

  handleAddGroup(group) {
    const groups = this.state.config.groups || [];
    const newGroups = [...groups, group];
    // trafficRatio = the sum of all the groups' trafficRatios
    const trafficRatio = _.sumBy(newGroups, (g) => {
      return parseInt(g.traffic_ratio);
    });
    this.setState({
      trafficRatio,
      config: {
        groups: newGroups,
      },
    });

    this.handleCloseModal();
  }

  handleEditGroup(group) {
    console.log(group, this.state.editingGroupIndex);
    // const groups = this.state.config.groups;
    // groups.splice(this.state.editingGroupIndex, 1);
    // console.log(groups, newGroups);
    const groups = this.state.config.groups;
    groups[this.state.editingGroupIndex] = group;

    // trafficRatio = the sum of all the groups' trafficRatios
    const trafficRatio = _.sumBy(groups, (g) => {
      return parseInt(g.traffic_ratio);
    });

    this.setState({
      trafficRatio,
      config: {
        groups,
      },
      editingGroup: null,
      editingGroupIndex: null,
    });

    this.handleCloseModal();
  }

  handleOpenEditModal(group, index) {
    // 1. remove from groups
    // 2. open modal
    // 3. onsave
    // const groups = this.state.config.groups;
    // let newGroups = [...groups].splice(index, 1);
    this.setState({
      // config: {
      //   groups: newGroups,
      // },
      isEditModal: true,
      editingGroup: group,
      editingGroupIndex: index,
    });

    this.handleOpenModal();
    console.log("edit", group);
  }

  handleOpenDuplicateModal(group) {
    this.setState({
      editingGroup: group,
      isEditModal: false,
    });
    this.handleOpenModal();
  }

  handleRemoveGroup(group, index) {
    const groups = [...this.state.config.groups];
    groups.splice(index, 1);

    // trafficRatio = the sum of all the groups' trafficRatios
    const trafficRatio = _.sumBy(groups, (g) => {
      return parseInt(g.traffic_ratio);
    });

    this.setState({
      trafficRatio,
      config: {
        groups,
      },
    });
  }

  toggleShouldForceIterate() {
    this.setState({ shouldForceIterate: !this.state.shouldForceIterate });
  }

  render() {
    const {
      configId,
      unitId,
      metadata,
      segmentId,
      item,
      trafficRatio,
      config,
      createdAt,

      editingGroup,
      editingGroupIndex,

      isLoading,
      errMsg,

      isModalOpen,

      isSaving,
      shouldForceIterate,
    } = this.state;

    if (isLoading) {
      return <LoadingUI></LoadingUI>;
    }

    if (errMsg) {
      return <div className="text-red-600">{errMsg}</div>;
    }

    // if (items && items.length === 0) {
    //   return <div>No active segment research groups</div>;
    // }

    return (
      <>
        <div className="bg-white px-12">
          <div className="flex justify-between pt-8 mb-4 ">
            <div className="font-extrabold text-gray-900 text-4xl">
              Auto Pilot Segment Research Group Config
            </div>
          </div>
          <div>{/* <ConfigListTable items={[item]}></ConfigListTable> */}</div>
        </div>

        <div className="bg-gray-200 px-12 py-8 min-h-full">
          <div className="flex">
            <div className="w-2/3">
              {configId && (
                <div className="mb-4">
                  <div className="text-xl font-semibold">
                    Config ID: {item.config_id}
                  </div>
                </div>
              )}

              {!configId && (
                <div className="mb-4">
                  Segment ID
                  <div>
                    <input
                      type="number"
                      value={segmentId}
                      className="border border-gray-400 p-2 rounded"
                      onChange={(e) =>
                        this.handleInputChanged("segmentId", e.target.value)
                      }
                    ></input>
                    <button
                      type="button"
                      className="px-4 py-2 bg-white rounded shadow hover:bg-gray-100 text-gray-800"
                      onClick={this.searchSegment}
                    >
                      Search Segment
                    </button>
                  </div>
                  <div className="text-red-600">
                    {this.state.segmentSearchError}
                  </div>
                </div>
              )}

              {metadata && (
                <>
                  <div className="mb-4">
                    <div className="text-xl font-semibold">
                      Network: {metadata.network_id} {metadata.network_name}
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="text-xl font-semibold">
                      Unit: {metadata.unit_id} {metadata.unit_name}
                    </div>
                  </div>

                  <div className="mb-4">
                    <SegmentBasicTable
                      segments={[
                        {
                          segmentId: metadata.segment_id,
                          mode: metadata.segment_mode,
                          targeting: metadata.targeting,
                        },
                      ]}
                    ></SegmentBasicTable>
                  </div>
                </>
              )}

              <div className="mb-4">
                Traffic Ratio
                <span className="text-gray-700 text-sm">
                  {" "}
                  (sum of config groups' traffic ratio)
                </span>
                <div className="w-1/2">
                  <input
                    type="number"
                    readOnly
                    disabled
                    value={trafficRatio}
                    className="border border-gray-400 p-2 rounded w-1/2"
                    // onChange={(e) =>
                    //   this.handleInputChanged("trafficRatio", e.target.value)
                    // }
                  ></input>
                </div>
              </div>

              <div className="mb-4">
                Config:
                <div className="border rounded bg-white">
                  <div className="flex items-center justify-between border-b p-4">
                    <div className="font-xl font-semibold text-gray-800">
                      Groups
                    </div>
                    <div>
                      <button
                        type="button"
                        className="px-4 py-2 bg-white rounded shadow hover:bg-gray-100 text-gray-800"
                        onClick={this.handleOpenAddModal}
                      >
                        Add Group
                      </button>
                    </div>
                  </div>

                  <div className="p-4">
                    {config.groups &&
                      config.groups.map((group, i) => {
                        return (
                          <ConfigGroupView
                            key={i}
                            group={group}
                            handleEditClicked={() =>
                              this.handleOpenEditModal(group, i)
                            }
                            handleRemoveClicked={() =>
                              this.handleRemoveGroup(group, i)
                            }
                            handleDuplicateClicked={() =>
                              this.handleOpenDuplicateModal(group)
                            }
                          ></ConfigGroupView>
                        );
                      })}

                    <ConfigGroupModal
                      isEdit={this.state.isEditModal}
                      isModalOpen={isModalOpen}
                      handleClose={this.handleCloseModal}
                      handleEdit={this.handleEditGroup}
                      handleAdd={this.handleAddGroup}
                      expGroup={editingGroup}
                    ></ConfigGroupModal>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-1/3">
              {createdAt && (
                <div className="text-gray-600 text-sm">
                  Create time:
                  <br />
                  <DateTimeFormatter datetime={createdAt}></DateTimeFormatter>
                </div>
              )}

              {/* {JSON.stringify(config)} */}
            </div>
          </div>

          {this.state.saveError && (
            <div className="leading-none my-4 bg-red-100 py-3 px-2 rounded font-bold">
              <div className="text-red-700">{this.state.saveError}</div>
            </div>
          )}

          <div className="leading-none my-4 bg-orange-100 py-3 px-2 rounded">
            <div className="text-sm text-orange-800 mb-2">
              If there are urgent changes in the config that needs to be
              affected immediately, check the box below:
            </div>
            <label
              className={isSaving ? "cursor-not-allowed" : "cursor-pointer"}
            >
              <Checkbox
                isChecked={shouldForceIterate}
                isDisabled={isSaving}
                onChange={this.toggleShouldForceIterate}
              ></Checkbox>

              <span className="ml-2 align-middle text-gray-700 hover:text-gray-800 font-semibold text-sm">
                Force AutoPilot to iterate ASAP
              </span>
            </label>
          </div>

          <div className="flex justify-start">
            <button
              type="button"
              className="px-4 py-2 bg-blue-500 rounded shadow hover:bg-blue-700 text-white font-semibold"
              onClick={this.saveConfig}
            >
              {configId ? "Save Config" : "Create Config"}
            </button>

            <button
              type="button"
              disabled={isSaving}
              className={`px-4 py-2 text-blue-700 ${
                isSaving ? "cursor-not-allowed" : ""
              }`}
              onClick={() => this.props.history.push("/segment-research/list")}
            >
              Back
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default ConfigWrapper;
