import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import NetworksSelector from "../../ops-mgmt/account/NetworksSelector";
import CustomDateRangeButton from "./CustomDateRangeButton";
import { FiChevronDown } from "react-icons/fi";
import GeoCountrySelectorWithPresets from "./GeoCountrySelectorWithPresets";

function _convertDateToYMD(date) {
  const dd = new Date(date);
  let d = dd.getDate();
  let m = dd.getMonth() + 1; // Month from 0 to 11
  let y = dd.getFullYear();
  let finalString = `${y}-${m <= 9 ? "0" + m : m}-${d <= 9 ? "0" + d : d}`;
  return finalString;
}

const DATE_RANGES = [1, 3, 6, 12, 24]; // months
const DEFAULT_DATE_RANGE = 1;
class PlatformIndexFilter extends React.Component {
  constructor(props) {
    super(props);

    const { startDate, endDate } = this._getDateRangeDates(DEFAULT_DATE_RANGE);

    this.state = {
      networkIds: null,
      countryCodes: null,
      startDate,
      endDate,

      currentDateRange: DEFAULT_DATE_RANGE,
      isDateRangeCustom: false,
    };

    this.handleDateRangeChanged = this.handleDateRangeChanged.bind(this);
    this.handleCustomDateRangeChanged =
      this.handleCustomDateRangeChanged.bind(this);
    this.handleDateRangeCanceled = this.handleDateRangeCanceled.bind(this);

    this.handleNetworksChanged = this.handleNetworksChanged.bind(this);
    this.handleCountryCodesChanged = this.handleCountryCodesChanged.bind(this);
    this.handleFilter = this.handleFilter.bind(this);

    this.handlePresetSelected = this.handlePresetSelected.bind(this);
  }

  _getDateRangeDates(numOfMonths) {
    const today = moment();
    const dateFormat = "YYYY-MM-DD";
    const startDate = today
      .clone()
      .subtract(1, "day") // current day data is not sufficient
      .subtract(numOfMonths, "month")
      .startOf("month")
      .format(dateFormat);

    const endDate = today
      .clone()
      .startOf("month")
      .subtract(1, "day")
      .format(dateFormat);

    return { startDate, endDate };
  }

  handleDateRangeChanged(dr) {
    if (dr === "CUSTOM") {
      // this.setState({ currentDateRange: dr, startDate: null, endDate: null });
      this.setState({
        isDateRangeCustom: true,
        startDate: null,
        endDate: null,
      });
    } else {
      const { startDate, endDate } = this._getDateRangeDates(dr);
      this.setState({
        isDateRangeCustom: false,
        currentDateRange: dr,
        startDate,
        endDate,
      });
    }
  }

  // startDate, endDate are date objects
  handleCustomDateRangeChanged({ startDate, endDate }) {
    this.setState({
      isDateRangeCustom: true,
      startDate: _convertDateToYMD(startDate),
      endDate: _convertDateToYMD(endDate),
    });
  }

  handleDateRangeCanceled() {
    // currentDateRange is not changed when choosing custom date range
    // so we know the previous selected date range
    this.handleDateRangeChanged(this.state.currentDateRange);
    // this.setState({ isDateRangeCustom: false });
  }

  handleNetworksChanged(ids) {
    this.setState({ networkIds: ids });
  }

  handleCountryCodesChanged(items) {
    const codes = _.map(items, "numeric");
    this.setState({ countryCodes: codes });
  }

  handleFilter() {
    const { startDate, endDate, networkIds, countryCodes } = this.state;
    const params = {
      startDate,
      endDate,
      networkIds,
      countryCodes,
    };
    this.props.handleFilter(params);
  }

  handlePresetSelected(preset) {
    console.log(preset);
    // handleCountryCodesChanged
    this.setState({ countryCodes: preset.countryCodes });
  }

  render() {
    const { currentDateRange, startDate, endDate, isDateRangeCustom } =
      this.state;
    const isFilterDisabled = this.props.isLoading;

    return (
      <div className="mb-8">
        <div>
          <div className="font-semibold text-indigo-900">
            Date Range:{" "}
            {/* {isDateRangeCustom ? "custom" : "not custom"}
            {currentDateRange} */}
            (
            <span className="mx-1 text-sm text-gray-800">
              <span className="font-normal text-gray-600">From</span>{" "}
              {startDate} <span className="font-normal text-gray-600">to</span>{" "}
              {endDate}
            </span>
            )
          </div>

          <div className="flex justify-start items-center mb-4 text-sm">
            {DATE_RANGES.map((dr) => {
              return (
                <button
                  key={dr}
                  type="button"
                  className={`px-4 py-2 mr-1 font-semibold rounded ${
                    !isDateRangeCustom && currentDateRange === dr
                      ? "bg-indigo-100 text-indigo-800"
                      : "text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
                  }`}
                  onClick={() => this.handleDateRangeChanged(dr)}
                >
                  Last {dr > 1 && dr} Month{dr > 1 && "s"}
                </button>
              );
            })}
            <CustomDateRangeButton
              onOpen={() => this.handleDateRangeChanged("CUSTOM")}
              handleDateRangeChanged={this.handleCustomDateRangeChanged}
              handleDateRangeCanceled={this.handleDateRangeCanceled}
              customButton={
                <button
                  type="button"
                  className={`px-4 py-2 mr-1 font-semibold rounded ${
                    isDateRangeCustom
                      ? "bg-indigo-100 text-indigo-800"
                      : "text-gray-700 hover:text-indigo-800 hover:bg-gray-200"
                  }`}
                >
                  <div className="flex items-center gap-2">
                    {isDateRangeCustom && startDate && endDate ? (
                      <span>
                        Custom:
                        <span className="mx-1">
                          <span className="font-normal text-gray-600">
                            From
                          </span>{" "}
                          {startDate}{" "}
                          <span className="font-normal text-gray-600">to</span>{" "}
                          {endDate}
                        </span>
                      </span>
                    ) : (
                      "Custom "
                    )}
                    <FiChevronDown className="text-gray-500"></FiChevronDown>
                  </div>
                </button>
              }
            ></CustomDateRangeButton>
          </div>
        </div>

        <div className="mb-4">
          <div className="font-semibold text-indigo-900">Networks: </div>
          <NetworksSelector
            handleOnChange={this.handleNetworksChanged}
            isMulti={true}
          ></NetworksSelector>
        </div>

        <div className="mb-4">
          <div className="font-semibold text-indigo-900">Countries:</div>

          <GeoCountrySelectorWithPresets
            handleOnChange={this.handleCountryCodesChanged}
          ></GeoCountrySelectorWithPresets>
        </div>

        <div className="flex justify-end">
          <button
            type="button"
            onClick={this.handleFilter}
            className={`px-6 py-2 rounded text-white font-semibold ${
              isFilterDisabled
                ? "bg-blue-200"
                : "bg-blue-400 hover:shadow hover:bg-blue-500"
            }`}
            disabled={isFilterDisabled}
          >
            Filter
          </button>
        </div>
      </div>
    );
  }
}

export default PlatformIndexFilter;
