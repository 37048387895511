import React from "react";
import NumberFormat from "../../common/NumberFormat";
// import Gauge from "react-svg-gauge";
import ProgressBar from "./ProgressBar";

class SummaryRateGaugeChart extends React.PureComponent {
  render() {
    const progress = this.props.value / 100;
    // const barColor = "#FFEA82"
    //const trailColor =  "#eee"
    const trailColor = "#cbd5e0";

    const barColor = "#56ecf7";

    const options = {
      strokeWidth: 6,
      color: barColor,
      trailColor,
      trailWidth: 2,
      easing: "easeInOut",
      duration: 1400,
      from: { color: barColor },
      to: { color: "#ED6A5A" },
    };

    return (
      <div style={{ width: "74px", marginTop: "4px" }}>
        <ProgressBar.SemiCircle
          progress={progress <= 1 ? progress : 1}
          // text={progress}
          // options={{ strokeWidth: 4 }}
          options={options}
          initialAnimate={true}
        />
        <div className="text-gray-800 text-sm font-medium text-center -mt-4">
          <NumberFormat value={this.props.value} format="0,0.00"></NumberFormat>
          <span className="font-normal">%</span>
        </div>
      </div>
    );
  }
}

export default SummaryRateGaugeChart;
