import React from "react";
import _ from "lodash";
import Select from "react-select";

// can be multiselect
class InventoryRegistrySelectorLocal extends React.PureComponent {
  constructor(props) {
    super(props);
    // props:
    // registries  // options
    // selectedItems
    // handleChanged

    this.state = {
      options: null,
      selectedValue: null,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    try {
      const items = this.props.registries;

      const options = _.map(items, (c) => {
        return {
          value: c.registry_id,
          label: `${c.registry_id} (${c.registry_name})`,
        };
      });

      const { selectedItems } = this.props;
      let selectedValue = null;
      if (_.isArray(selectedItems)) {
        selectedValue = _.filter(options, (option) => {
          return _.indexOf(selectedItems, option.value) !== -1;
        });
      } else {
        selectedValue = _.find(options, (o) => {
          return o.value === selectedItems;
        });
      }

      this.setState({
        options,
        selectedValue,
      });
    } catch (err) {
      console.log("error querying inventory registry");
    }
  }

  handleChange(options) {
    this.setState({
      selectedValue: options,
    });

    let value = this.props.isMulti ? _.map(options, "value") : options.value;
    this.props.handleChanged(value);
  }

  render() {
    const { options, selectedValue } = this.state;
    const { isDisabled, isMulti = false } = this.props;

    return (
      <>
        {options && (
          <Select
            // className="w-24 z-50"
            defaultValue={selectedValue}
            value={selectedValue}
            onChange={this.handleChange}
            options={options}
            isSearchable={true}
            isMulti={isMulti}
            isDisabled={isDisabled}
            // styles={{
            //   control: () => ({
            //     paddingLeft: 3,
            //   }),
            // }}
          />
        )}
      </>
    );
  }
}

export default InventoryRegistrySelectorLocal;
