import React from "react";
import { FiExternalLink } from "react-icons/fi";

class GoToCSTAnatomyButton extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  handleGoToAnatomy(unitId) {
    window.open(`${window.location.origin}/#/anatomy/cst/${unitId}`);
  }

  render() {
    const { unitId } = this.props;
    return (
      <button
        type="button"
        className="border rounded hover:shadow flex items-center border-blue-300 bg-gray-100 px-1 text-xs text-blue-700"
        onClick={() => this.handleGoToAnatomy(unitId)}
      >
        CST Anatomy
        <span className="ml-1">
          <FiExternalLink></FiExternalLink>
        </span>
      </button>
    );
  }
}

export default GoToCSTAnatomyButton;
