import React from "react";
import _ from "lodash";

const OPTIONS = [
  {
    label: "All",
    value: "ALL",
  },
  {
    label: "No PB",
    value: "NO_PB",
  },
];

class AnatomyNoPbSelector extends React.Component {
  constructor(props) {
    super(props);

    const { type } = this.props;

    this.state = {
      options: OPTIONS,
      type,
    };

    this.handleChanged = this.handleChanged.bind(this);
  }

  handleChanged(type) {
    this.setState({
      type,
    });
    this.props.handleNoPbTypeChanged({ type });
  }

  render() {
    const { options, type } = this.state;

    return (
      <div className="p-4">
        <div className="mb-1 font-semibold text-gray-700">Data Type:</div>
        <div className="flex items-center justify-start text-sm">
          <div>
            {options.map((option) => {
              return (
                <div key={option.value} className="inline-block">
                  <button
                    disabled={type === option.value}
                    type="button"
                    className={`rounded mr-1 px-4 py-1 font-semibold text-gray-700 ${
                      type === option.value
                        ? "bg-blue-200"
                        : "bg-gray-100 hover:bg-gray-200 hover:text-gray-800"
                    }`}
                    onClick={() => this.handleChanged(option.value)}
                  >
                    {option.label}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default AnatomyNoPbSelector;
