import React from "react";
import HeaderTabs from "../common/HeaderTabs";

class UnitInsightTabs extends React.PureComponent {
  render() {
    const { handleSelectTab, selectedTab } = this.props;

    const tabs = [
      {
        title: "Overview",
        key: "OVERVIEW",
      },
      {
        title: "Benchmark",
        key: "BENCHMARK_VALIDATION",
      },
      {
        title: "Inventory",
        key: "INVENTORY",
      },
      {
        title: "UPR",
        key: "UPR_VALIDATION",
      },
      {
        title: "Health Check",
        key: "HEALTH_CHECK",
      },
      {
        title: "IVT",
        key: "IVT",
      },
    ];

    return (
      <HeaderTabs
        tabs={tabs}
        selectedTabKey={selectedTab}
        handleTabChange={handleSelectTab}
      ></HeaderTabs>
    );
  }
}

export default UnitInsightTabs;
