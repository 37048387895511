import React from "react";
import _ from "lodash";

import ModalWrapper from "../../../common/ModalWrapper";
import { NetworkAPI } from "apis";
import LoadingUI from "components/common/LoadingUI";

class CreateBuildModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      packages: null,

      packageName: undefined,
      packageVersion: undefined,
      versions: null,

      isSaving: false,
      saveErrMsg: null,
    };

    this.handleChangePackageName = this.handleChangePackageName.bind(this);
    this.handleChangePackageVersion =
      this.handleChangePackageVersion.bind(this);
    this.handleCreateBuild = this.handleCreateBuild.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      const { networkId } = this.props;
      const { packages } = await NetworkAPI.getBuildPackages();
      console.log(packages);

      this.setState({
        packages,

        // packageName,
        packageVersion: "latest",
        // versions: [], // get more from package on select
      });

      this.handleChangePackageName(_.get(_.first(packages), "name"));
    } catch (err) {
      console.log(err);
    }
    this.setState({ isLoading: false });
  }

  handleChangePackageName(packageName) {
    const p = _.find(this.state.packages, { name: packageName });
    const pVersions = _.map(_.get(p, "versions", []), "version");
    const versions = ["latest", ...pVersions];
    this.setState({
      packageName,
      versions,
    });
  }

  handleChangePackageVersion(packageVersion) {
    this.setState({
      packageVersion,
    });
  }

  async handleCreateBuild() {
    const { packageName, packageVersion } = this.state;

    this.setState({ isSaving: true });
    try {
      const r = await this.props.handleCreateBuild({
        packageName,
        packageVersion,
      });

      if (r.downloadUrl) {
        window.open(r.downloadUrl);
      }

      this.setState({
        saveErrMsg: null,
      });

      await this.props.handleRefreshAfterBuild();
    } catch (err) {
      this.setState({
        saveErrMsg: err.toString(),
      });
    }

    this.setState({ isSaving: false });
  }

  render() {
    const {
      isLoading,
      packages,
      packageName,
      versions,
      packageVersion,
      isSaving,
      saveErrMsg,
    } = this.state;
    const { isOpenViewModal, handleClose } = this.props;

    return (
      <ModalWrapper
        isOpen={isOpenViewModal}
        showCloseFooter={true}
        handleClose={handleClose}
      >
        <>
          <div className="border-b -mx-6 mb-4 px-6 pb-2 text-lg font-bold">
            Create a new build:
          </div>

          {isLoading ? (
            <div>
              <LoadingUI></LoadingUI>
            </div>
          ) : (
            <>
              <div className="mb-4">
                <div className="font-semibold">1. Select package:</div>

                <div className="flex items-center gap-4">
                  <div className="relative inline-block w-1/2">
                    <select
                      value={packageName}
                      onChange={(e) =>
                        this.handleChangePackageName(e.target.value)
                      }
                      className="border rounded shadow focus:outline-none block w-full appearance-none border-gray-400 bg-white px-4 py-2 pr-8 leading-tight hover:border-gray-500 focus:shadow-outline"
                    >
                      {packages &&
                        packages.map((p) => {
                          return (
                            <option key={p.name} value={p.name}>
                              {p.name}
                            </option>
                          );
                        })}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="h-4 w-4 fill-current"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-4">
                <div className="font-semibold">2. Select version:</div>

                <div className="flex items-center gap-4">
                  {versions &&
                    versions.map((version) => {
                      return (
                        <label
                          htmlFor={`${version}_radio`}
                          className="cursor-pointer"
                          key={version}
                        >
                          <input
                            id={`${version}_radio`}
                            type="radio"
                            name={`package_version_radio`}
                            value={version}
                            checked={packageVersion === version}
                            onChange={() =>
                              this.handleChangePackageVersion(version)
                            }
                          />{" "}
                          {version}
                        </label>
                      );
                    })}
                </div>
              </div>

              <div className="mb-2 flex gap-2 bg-orange-200 px-4 py-2 text-sm text-orange-900">
                If migrating from <b>DT</b> to <b>CSTv2</b>, use "Create temp
                build" to build code snippet for CSTv2.
              </div>

              <button
                type="button"
                className="shadow rounded mt-2 mb-4 flex items-center gap-1 bg-blue-200 px-10 py-2 font-semibold text-blue-900 hover:bg-blue-300"
                onClick={this.handleCreateBuild}
                disabled={isSaving}
              >
                {isSaving ? "Launching..." : "Launch a new build"}
              </button>
              <div className="my-1 text-red-600">{saveErrMsg}</div>
            </>
          )}
        </>
      </ModalWrapper>
    );
  }
}

export default CreateBuildModal;
