import React from "react";
import { FiExternalLink } from "react-icons/fi";

class GoToAPConfigButton extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  handleGoToUnitAPConfigPage(unitId) {
    window.open(
      `${window.location.origin}/#/control-center/unit/${unitId}/auto-pilot/config`
    );
  }

  render() {
    const { unitId } = this.props;
    return (
      <button
        type="button"
        className="border border-blue-300 rounded px-1 hover:shadow text-xs flex items-center text-blue-700 bg-gray-100"
        onClick={() => this.handleGoToUnitAPConfigPage(unitId)}
      >
        AutoPilot Config
        <span className="ml-1">
          <FiExternalLink></FiExternalLink>
        </span>
      </button>
    );
  }
}

export default GoToAPConfigButton;
