import React from "react";
import _ from "lodash";
import Select from "react-select";

import { PublisherAPI } from "apis";
import PricingDefaultConfigView from "./PricingDefaultConfigView";

const PRICING_TYPE = {
  0: "Tiered Rev Share",
  1: "Flat Fee",
  2: "Rev Share with Cost Compensation",
};

let DEFAULT_CONFIGS_CACHE = [];

class PricingDefaultConfigSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      options: [],
      selectedValue: null,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    let items = [];
    if (DEFAULT_CONFIGS_CACHE.length !== 0) {
      items = DEFAULT_CONFIGS_CACHE;
    } else {
      items = await PublisherAPI.getPricingDefaultConfigs();
      DEFAULT_CONFIGS_CACHE = items;
    }

    let options = _.map(items, (r) => {
      return {
        value: r.pricingDefaultConfigId,
        label: `[${r.pricingDefaultConfigId}] ${r.name} (${
          PRICING_TYPE[r.pricingType]
        })`,
        item: r,
      };
    });

    const selectedValue = _.find(options, { value: this.props.selectedValue });

    this.setState({
      items,
      options,
      selectedValue,
    });
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.selectedValue !== this.props.selectedValue) {
      let selectedValue = _.find(this.state.options, {
        value: this.props.selectedValue,
      });

      if (!selectedValue) {
        // query again to make sure we include the newly created items
        const items = await PublisherAPI.getPricingDefaultConfigs();
        DEFAULT_CONFIGS_CACHE = items;
        let options = _.map(items, (r) => {
          return {
            value: r.pricingDefaultConfigId,
            label: `[${r.pricingDefaultConfigId}] ${r.name} (${
              PRICING_TYPE[r.pricingType]
            })`,
            item: r,
          };
        });

        selectedValue = _.find(options, { value: this.props.selectedValue });
      }

      this.setState({
        selectedValue,
      });
    }
  }

  handleChange(option) {
    this.setState({
      selectedValue: option,
    });

    // this.props.handleOnChange(option.value);

    if (this.props.isMulti) {
      const selectedValues = _.map(option, "value");
      const results = _.filter(this.state.items, (item) => {
        return _.indexOf(selectedValues, item.pricingDefaultConfigId) !== -1;
      });
      this.props.handleOnChange(results);
    } else {
      this.props.handleOnChange(option.item);
    }
  }

  render() {
    const { options, selectedValue } = this.state;
    const { showConfigView = true } = this.props;

    return (
      <>
        <Select
          value={selectedValue}
          onChange={this.handleChange}
          options={options}
          isMulti={this.props.isMulti}
        />

        {showConfigView && (
          <>
            {selectedValue && (
              <div className="my-2">
                <PricingDefaultConfigView
                  item={selectedValue.item}
                ></PricingDefaultConfigView>
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

export default PricingDefaultConfigSelector;
