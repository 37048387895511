import React from "react";
import _ from "lodash";
import { AutoPilotAPI } from "apis";

import ModalWrapper from "../../common/ModalWrapper";
import ConfigGroupForm from "./ConfigGroupForm";

class ConfigGroupModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expGroup: this.props.expGroup,
      isEditing: false,
    };
    this.fillInExpGroup = this.fillInExpGroup.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleAddClicked = this.handleAddClicked.bind(this);
    this.handleEditClicked = this.handleEditClicked.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expGroup !== this.props.expGroup) {
      console.log("ho", prevProps.expGroup, this.props);
      this.setState({
        expGroup: this.props.expGroup,
        // isEditing: this.props.expGroup ? true : false,
        isEditing: this.props.isEdit,
      });
    }
    if (prevProps.isEdit !== this.props.isEdit) {
      this.setState({
        isEditing: this.props.isEdit,
      });
    }
  }

  fillInExpGroup(expGroup) {
    this.setState({ expGroup });
  }

  handleCloseModal() {
    this.setState({ expGroup: null });
    this.props.handleClose();
  }

  handleAddClicked(group) {
    this.setState({ expGroup: null });
    this.props.handleAdd(group);
  }

  handleEditClicked(group) {
    this.setState({ expGroup: null });
    this.props.handleEdit(group);
  }

  render() {
    const { expGroup, isEditing } = this.state;
    const { isModalOpen } = this.props;
    // const { onAddGroup } = this.props;

    return (
      <ModalWrapper
        isOpen={isModalOpen}
        showCloseFooter={false}
        customContentStyle={{ width: "50%", left: "25%" }}
        // handleClose={this.props.handleClose}
      >
        <div className="mb-4 text-lg font-bold border-b pb-2 px-6 -mx-6">
          {isEditing ? "Edit config:" : "Add a new config group:"}
        </div>

        {!isEditing && (
          <>
            <ConfigGroupSearchExp
              fillInExpGroup={this.fillInExpGroup}
            ></ConfigGroupSearchExp>

            <div className="my-4 flex justify-center">OR</div>
          </>
        )}

        <ConfigGroupForm
          isEditing={isEditing}
          expGroup={expGroup}
          onCancel={this.handleCloseModal}
          onAdd={this.handleAddClicked}
          onEdit={this.handleEditClicked}
        ></ConfigGroupForm>
      </ModalWrapper>
    );
  }
}

class ConfigGroupSearchExp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expGroupId: "",

      hasError: false,
      errMsg: null,
    };

    this.handleSearchExpGroup = this.handleSearchExpGroup.bind(this);
  }

  async handleSearchExpGroup() {
    try {
      const { expGroupId } = this.state;
      if (!expGroupId || expGroupId === "") {
        return;
      }
      const r = await AutoPilotAPI.getExpGroupById({ expGroupId });
      console.log(r);

      let group = {
        name: r.name,
        trafficRatio: r.traffic_ratio,
        layers: r.layers, //JSON.parse(r.layers),
        metadata: r.metadata, //JSON.parse(r.metadata),
      };

      this.props.fillInExpGroup(group);
    } catch (err) {
      console.log(err);
    }
  }

  handleInputChanged(key, value) {
    this.setState({
      [key]: value,
    });
  }

  render() {
    const { expGroupId, hasError, errMsg } = this.state;
    const { onAddGroup, fillInExpGroup } = this.props;

    return (
      <div className="p-4">
        <div className="mb-4">
          <div className="font-semibold text-gray-800">
            Experiment Group ID:
          </div>
          <input
            type="number"
            className="border border-gray-400 p-2 rounded w-2/3"
            value={expGroupId}
            onChange={(e) =>
              this.handleInputChanged("expGroupId", e.target.value)
            }
          ></input>
          <button
            type="button"
            className="px-4 py-2 ml-2 bg-white rounded shadow hover:bg-gray-100 text-gray-800"
            onClick={this.handleSearchExpGroup}
          >
            Search Exp Group
          </button>
        </div>
      </div>
    );
  }
}

export default ConfigGroupModal;
