import React from "react";
import _ from "lodash";
import CustomTargetingKeysSelector from "components/ops-mgmt/account/cst-config/CustomTargetingKeysSelector";
import CustomTargetingValuesSelector from "components/ops-mgmt/account/cst-config/CustomTargetingValuesSelector";

class CustomSetTargetingFormatter extends React.PureComponent {
  render() {
    const {
      networkId,
      setsOfCustom,
      showActionButtons,
      handleRemoveSet,
      handleEditSet,
    } = this.props;

    if (!setsOfCustom || !_.isArray(setsOfCustom)) {
      return "None";
    }

    return (
      <div>
        {setsOfCustom.map((custom, index) => {
          const { keyId, valueIds, operator } = custom;
          const valueIdsString = valueIds ? valueIds.join(", ") : "";

          return (
            <div
              key={keyId}
              className="border rounded bg-white p-1 text-gray-700"
            >
              <div className="float-right">
                Operator:{" "}
                <span
                  className={`rounded px-2 text-sm font-semibold text-white ${
                    operator === "IS" ? "bg-green-500" : "bg-red-500"
                  }`}
                >
                  {operator}
                </span>
              </div>

              <div>
                Key ID: <span className="font-semibold">{keyId}</span>
                <CustomTargetingKeysSelector
                  isReadonly={true}
                  networkId={networkId}
                  keyId={keyId}
                ></CustomTargetingKeysSelector>
              </div>
              <div>
                Value IDs ({valueIds ? valueIds.length : 0}):{" "}
                <div
                  className="font-semibold"
                  style={{ maxHeight: "55px", overflow: "scroll" }}
                >
                  {valueIdsString}
                </div>
                <CustomTargetingValuesSelector
                  isReadonly={true}
                  networkId={networkId}
                  keyId={keyId}
                  isMulti={true}
                  managedValueIds={valueIds}
                ></CustomTargetingValuesSelector>
              </div>

              {!showActionButtons ? (
                ""
              ) : (
                <div>
                  <button
                    type="button"
                    className="rounded mr-2 bg-blue-100 px-2 text-blue-600 hover:bg-blue-200"
                    onClick={() => handleEditSet(index)}
                  >
                    Edit
                  </button>
                  <button
                    type="button"
                    className="rounded bg-red-100 px-2 text-red-600 hover:bg-red-200"
                    onClick={() => handleRemoveSet(index)}
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

export default CustomSetTargetingFormatter;
