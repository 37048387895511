import _ from "lodash";
import pako from "pako";

import {
  api,
  fetchRespJson,
  postData,
  fetchRespJsonWithoutCredentials,
} from "apis/base";
import { transformSnapshotData } from "helpers/Snapshot";

const SNAPSHOT_BUCKET = "observer-prod-observerresultbucket-2kzubgw6sf46";
const USE_SNAPSHOT_SAMPLE = false;

// import sd from "../sample-data/snapshot-2020-12-01_00_45.json";
// import sd from "../sample-data/2020-11-30_23_45.json";
// import sd from "../sample-data/2020-12-01_03_45.json";
// import osd from "../sample-data/test-obserser-dev-sample.json";
// import revSample from "../sample-data/rev-sample.json";
// import revOverviewSample from "../sample-data/rev-overview-18.json";

export default {
  // force refresh snapshot
  createNetworkSnapshot,
  createYieldSetSnapshot,
  // snapshot
  getSnapshot,
  getYieldSetSnapshotUrl,
  getYieldSetSnapshot,
  getNetworkProfileUrl,
  updateNetworkDailyGoal,
  getNetworkDailyGoalHistory,
};

async function createNetworkSnapshot({ networkIds }) {
  const apiUrl = `${api}/update-network-snapshot`;
  let isStaging = false;
  return await postData(apiUrl, { networkIds, isStaging });
}

async function createYieldSetSnapshot({ networkIds }) {
  const apiUrl = `${api}/update-yield-set-snapshot`;
  let isStaging = false;
  return await postData(apiUrl, { networkIds, isStaging });
}

async function getSnapshot(snapshotKey) {
  if (!snapshotKey) {
    return null;
  }

  let key = null;
  if (snapshotKey === "latest") {
    key = await _getLatestSnapshotKey();
  } else {
    key = snapshotKey;
  }

  let apiUrl = `${api}/get-snapshot/${key}`;
  if (process.env.REACT_APP_ENV === "staging") {
    apiUrl = `${api}/staging-get-snapshot/${key}`;
  }

  let link;
  let reportCreateTime;
  let dailyGoalMap = {};
  if (USE_SNAPSHOT_SAMPLE) {
    link = `https://s3-ap-northeast-1.amazonaws.com/${SNAPSHOT_BUCKET}/__snapshot-sample.json`;
  } else {
    const data = await fetchRespJson(apiUrl);
    link = data.link;
    reportCreateTime = data.report_create_time;
  }
  // const snapshots = _.cloneDeep(osd.networkSnapshots);
  // const snapshots = require(link);
  const snapshots = await fetchRespJsonWithoutCredentials(link);
  const networkIds = _.map(snapshots, "gam_network_id");
  const dailyGoals = await _queryNetworkDailyGoals({ networkIds });
  dailyGoalMap = _.reduce(
    dailyGoals,
    (result, g) => {
      result[g.networkId] = g.ntdGoal;
      return result;
    },
    {}
  );

  const resultData = transformSnapshotData(snapshots, dailyGoalMap);
  // const resultData = transformSnapshotData(snapshots);

  return {
    snapshotKey: key,
    reportCreateTime,
    data: resultData,
    // data: [_.find(resultData, { gam_network_id: 40 })]
  };
}

async function getNetworkDailyGoalHistory({ networkId }) {
  if (!networkId) {
    throw new Error("Missing networkId");
  }

  const apiUrl = `${api}/get-network-daily-goal-history?networkId=${networkId}`;
  const resultData = await fetchRespJson(apiUrl);
  const { history } = resultData;
  return history;
}

async function updateNetworkDailyGoal({ networkId, ntdGoal, comment }) {
  const apiUrl = `${api}/update-network-daily-goal`;
  let updateParams = {
    networkId,
    ntdGoal,
    comment: comment || "",
  };
  return await postData(apiUrl, updateParams);
}

async function getNetworkProfileUrl({ networkId }) {
  const apiUrl = `${api}/get-network-profile-url?networkId=${networkId}`;
  const resultData = await fetchRespJson(apiUrl);
  const { url } = resultData;

  // const url =
  //   "https://docs.google.com/document/d/1PK2dBMKtbfr8EXhHS3N95cwW4URbLYCQyje5ZM97KP8/edit";
  return url;
}

async function getYieldSetSnapshotUrl({ networkId }) {
  let apiUrl = `${api}/network/${networkId}/yield-set-snapshot`;
  if (process.env.REACT_APP_ENV === "staging") {
    apiUrl += "?bucketEnv=staging";
  }
  const { signedUrl, range, reportTime } = await fetchRespJson(apiUrl);
  return await getYieldSetSnapshot({
    signedUrl,
    range,
    reportTime,
  });
}

async function getYieldSetSnapshot({ signedUrl, range, reportTime }) {
  try {
    const options = {
      headers: {
        range: `bytes=${range.start}-${range.end}`,
      },
    };
    const response = await fetch(signedUrl, options);
    const result = await response.arrayBuffer();

    const p = pako.inflate(result, { to: "string" });

    // Sample link
    // const link =
    //   "https://s3-ap-northeast-1.amazonaws.com/yb-observer-results-dev/snapshot-sample.json";

    // const snapshots = _.cloneDeep(osd.networkSnapshots);
    // const snapshots = require(link);
    // const data = await fetchRespJsonWithoutCredentials(link);

    return {
      reportCreateTime: reportTime,
      data: transformSnapshotData(JSON.parse(p)),
      // data,
    };
  } catch (err) {
    console.log("error get data", err);
    throw err;
  }
}

// local function
async function _queryNetworkDailyGoals({ networkIds }) {
  const ids = networkIds.join(",");
  const apiUrl = `${api}/query-network-daily-goal?networkIds=${ids}`;
  const resultData = await fetchRespJson(apiUrl);
  const { data } = resultData;
  return data;
}

async function _getLatestSnapshotKey() {
  let apiUrl = `${api}/get-latest-snapshot-key`;
  if (process.env.REACT_APP_ENV === "staging") {
    apiUrl = `${api}/staging-get-latest-snapshot-key`;
  }
  const data = await fetchRespJson(apiUrl);
  const key = _.get(data, "snapshotKey");
  return key;
}
