import React, { lazy, Suspense } from "react";
import _ from "lodash";
import { SnapshotAPI } from "apis";
import { orderNetworksBy, searchNetworkByInput } from "../../helpers/Snapshot";
import {
  snapshotSortKeyMap,
  snapshotSortByDayKeyMap,
} from "./../../constants/SortTypes";
import { trialTypes } from "../../constants/FilterTypes";
import { networkStatus } from "../../constants/NetworkStatus";
import LoadingUI from "../common/LoadingUI";
import QuickNavigateHeader from "./QuickNavigateHeader";
import { notify } from "react-notify-toast";
const SnapshotViewHeader = lazy(() => import("./SnapshotViewHeader"));
const NetworkWrap = lazy(() => import("./NetworkWrap"));

const NUM_PER_PAGE = 10;

class SnapshotViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      keyParams: _.get(this.props, "match.params.snapshotKey"),

      isLoading: false,
      snapshotKey: null,
      data: null,
      reportCreateTime: null,
      dataGroupedByNetworkStatus: null,

      filteredData: null,
      paginatedData: [],

      // Show data in TWD?
      isInTwd: true,
      // Expand all trend table
      showTrendTable: false,
      selectedSortType: snapshotSortKeyMap.NET_INCREASED_REVENUE,
      selectedSortByDayType: snapshotSortByDayKeyMap.PAST_7_DAYS_AVG,
      isOrderByDesc: true,

      selectedFilterType: networkStatus.RUNNING,
      selectedTrialType: trialTypes.ALL,
      filterTabs: null,
      trialFilterTabs: null,

      // if user is using search bar
      isInSearchingMode: false,
      numberOfSearchedData: 0,

      errMsg: null,
    };

    this.handleFilterNetworks = this.handleFilterNetworks.bind(this);
    this.handleSearch = this.handleSearch.bind(this);

    this.toggleSetTwd = this.toggleSetTwd.bind(this);
    this.handleSortTypeChange = this.handleSortTypeChange.bind(this);
    this.handleSortByDayTypeChange = this.handleSortByDayTypeChange.bind(this);
    this.toggleOrderBy = this.toggleOrderBy.bind(this);

    this.handleFilterTypeChange = this.handleFilterTypeChange.bind(this);
    this.handleTrialTypeChange = this.handleTrialTypeChange.bind(this);

    this.handleLoadMore = this.handleLoadMore.bind(this);

    this.handleCreateNetworkSnapshot =
      this.handleCreateNetworkSnapshot.bind(this);
    this.handleCreateYieldSetSnapshot =
      this.handleCreateYieldSetSnapshot.bind(this);
    this.handleForceRefreshSnapshot =
      this.handleForceRefreshSnapshot.bind(this);
  }

  async componentDidMount() {
    document.title = "Lastest Snapshot | YB Observer";

    this.setState({ isLoading: true });
    try {
      const snapshot = await SnapshotAPI.getSnapshot(this.state.keyParams);
      if (snapshot) {
        // for header tabs
        const dataGroupedByNetworkStatus = _.groupBy(snapshot.data, "status");
        const filterTabs = _.map(networkStatus, (status) => {
          const numOfNetworksByFilter = dataGroupedByNetworkStatus[status]
            ? dataGroupedByNetworkStatus[status].length
            : 0;

          switch (status) {
            case networkStatus.RUNNING: {
              return {
                title: `Running (${numOfNetworksByFilter})`,
                filterValue: status,
              };
            }
            case networkStatus.PAUSED: {
              return {
                title: `Paused (${numOfNetworksByFilter})`,
                filterValue: status,
              };
            }
            case networkStatus.TERMINATED: {
              return {
                title: `Terminated (${numOfNetworksByFilter})`,
                filterValue: status,
              };
            }

            default: {
              // nothing
            }
          }
        });

        const runningNetworks =
          dataGroupedByNetworkStatus[networkStatus.RUNNING];
        const runningNetworksGroupByTrialType = _.groupBy(
          runningNetworks,
          "trial_status.trialType"
        );

        const trialFilterTabs = _.map(trialTypes, (trialType) => {
          const title = _.startCase(_.lowerCase(trialType));

          if (trialType === "ALL") {
            return {
              title: `${title} (${runningNetworks && runningNetworks.length})`,
              filterValue: trialType,
            };
          } else {
            const numOfNetworksByFilter = runningNetworksGroupByTrialType[
              trialType
            ]
              ? runningNetworksGroupByTrialType[trialType].length
              : 0;

            return {
              title: `${title} (${numOfNetworksByFilter})`,
              filterValue: trialType,
            };
          }
        });

        const filteredData = orderNetworksBy(
          dataGroupedByNetworkStatus[this.state.selectedFilterType],
          this.state.selectedSortType,
          this.state.selectedSortByDayType,
          this.state.isOrderByDesc
        );

        this.setState({
          snapshotKey: snapshot.snapshotKey,
          data: snapshot.data,
          dataGroupedByNetworkStatus,
          reportCreateTime: snapshot.reportCreateTime,

          filterTabs,
          trialFilterTabs,

          filteredData,
          paginatedData: this.repaginateData(filteredData),
        });
      }
    } catch (err) {
      console.log("Error querying snapshot", err);
      this.setState({ errMsg: typeof err === "object" ? err.toString() : err });
    }

    this.setState({ isLoading: false });
  }

  handleFilterTypeChange(filterType) {
    this.setState({
      selectedFilterType: filterType,
      // reset trialType to ALL
      selectedTrialType: trialTypes.ALL,
    });
    this.handleFilterNetworks(filterType);
  }

  handleTrialTypeChange(trialType) {
    this.setState({ selectedTrialType: trialType });
    this.handleFilterNetworks(this.state.selectedFilterType, trialType);
  }

  toggleSetTwd() {
    this.setState({ isInTwd: !this.state.isInTwd });
  }

  handleSearch(inputValue) {
    this.setState({
      isInSearchingMode: true,
      selectedFilterType: null,
      numberOfSearchedData: 0,
    });

    // 1. show new search tab
    // 2. filter data
    const data = this.state.data;
    let filteredData = [];
    if (inputValue === "") {
      // Remain empty if no inputValue
      // filteredData = orderNetworksBy(
      //   data,
      //   this.state.selectedSortType,
      //   this.state.selectedSortByDayType,
      //   this.state.isOrderByDesc
      // );
    } else {
      const searchedData = searchNetworkByInput(data, inputValue, {
        isYieldSetReport: false,
      });
      filteredData = orderNetworksBy(
        searchedData,
        this.state.selectedSortType,
        this.state.selectedSortByDayType,
        this.state.isOrderByDesc
      );
    }
    this.setState({
      filteredData,
      numberOfSearchedData: filteredData.length,
      paginatedData: this.repaginateData(filteredData),
    });
  }

  handleFilterNetworks(filterType, trialType) {
    let data = [];
    if (trialType && trialType !== "ALL") {
      const dataOfStatus = _.get(
        this.state.dataGroupedByNetworkStatus,
        filterType,
        []
      );
      data = _.filter(dataOfStatus, (d) => {
        return d.trial_status.trialType === trialType;
      });
    } else {
      data = _.get(this.state.dataGroupedByNetworkStatus, filterType, []);
    }

    const filteredData = orderNetworksBy(
      data,
      this.state.selectedSortType,
      this.state.selectedSortByDayType,
      this.state.isOrderByDesc
    );

    this.setState({
      isInSearchingMode: false,
      filteredData,
      paginatedData: this.repaginateData(filteredData),
    });
  }

  handleSortTypeChange(newSortType) {
    if (newSortType === this.state.selectedSortType) {
      return;
    }

    const filteredData = orderNetworksBy(
      this.state.filteredData,
      newSortType,
      this.state.selectedSortByDayType,
      this.state.isOrderByDesc
    );

    this.setState({
      selectedSortType: newSortType,
      filteredData,
      paginatedData: this.repaginateData(filteredData),
    });
  }

  handleSortByDayTypeChange(newSortByDayType) {
    if (newSortByDayType === this.state.selectedSortByDayType) {
      return;
    }

    const filteredData = orderNetworksBy(
      this.state.filteredData,
      this.state.selectedSortType,
      newSortByDayType,
      this.state.isOrderByDesc
    );

    this.setState({
      selectedSortByDayType: newSortByDayType,
      filteredData,
      paginatedData: this.repaginateData(filteredData),
    });
  }

  toggleOrderBy() {
    const filteredData = orderNetworksBy(
      this.state.filteredData,
      this.state.selectedSortType,
      this.state.selectedSortByDayType,
      !this.state.isOrderByDesc
    );

    this.setState({
      isOrderByDesc: !this.state.isOrderByDesc,
      filteredData,
      paginatedData: this.repaginateData(filteredData),
    });
  }

  repaginateData(filteredData) {
    const nth = NUM_PER_PAGE;
    const paginatedData = _.slice(filteredData, 0, nth);
    return paginatedData;
  }

  handleLoadMore() {
    const nth = this.state.paginatedData.length + NUM_PER_PAGE;
    const paginatedData = _.slice(this.state.filteredData, 0, nth);

    this.setState({
      paginatedData,
    });
  }

  async handleCreateNetworkSnapshot(networkId) {
    await SnapshotAPI.createNetworkSnapshot({
      networkIds: [networkId],
    });
  }

  async handleCreateYieldSetSnapshot(networkId) {
    await SnapshotAPI.createYieldSetSnapshot({ networkIds: [networkId] });
  }

  async handleForceRefreshSnapshot(network, isYieldSet) {
    const userConfirm = window.confirm(
      `Refresh ${isYieldSet ? "yieldset " : ""}data for network ${
        network.gam_network_id
      } ${network.name}? (This might take a few seconds)`
    );
    if (!userConfirm) return;

    const { paginatedData } = this.state;
    const n = _.find(paginatedData, { gam_network_id: network.gam_network_id });
    n.isLoading = true;
    this.setState({ paginatedData });

    try {
      if (isYieldSet) {
        await this.handleCreateYieldSetSnapshot(network.gam_network_id);
      } else {
        await this.handleCreateNetworkSnapshot(network.gam_network_id);
      }

      const userConfirm2 = window.confirm(
        `${isYieldSet ? "Yieldset " : ""}Data for network ${
          network.gam_network_id
        } ${network.name} refreshed. Reload page to get the latest data.`
      );
      if (!userConfirm2) return;

      window.location.reload(false);
    } catch (err) {
      notify.show(
        `Failed to refresh ${isYieldSet ? "yieldset " : ""}data for network ${
          network.gam_network_id
        } ${network.name}. ${err.toString()}`,
        "error"
      );
      console.log(err);
    }

    // setTimeout(() => {
    //   const userConfirm2 = window.confirm(
    //     `Data for network ${network.gam_network_id} ${network.name} refreshed. Reload page to get the latest data`
    //   );
    //   if (!userConfirm2) return;

    //   window.location.reload(false);
    // }, 3000);
  }

  render() {
    const {
      data,
      filteredData,
      paginatedData,
      reportCreateTime,
      isInTwd,
      showTrendTable,
      isOrderByDesc,
      selectedSortType,
      selectedSortByDayType,
      selectedFilterType,
      selectedTrialType,
      filterTabs,
      trialFilterTabs,
      isInSearchingMode,
      numberOfSearchedData,
    } = this.state;

    return (
      <>
        {this.state.isLoading && (
          <div className="text-white">
            Preparing the newest snapshot data...
          </div>
        )}
        <Suspense fallback={<LoadingUI></LoadingUI>}>
          {this.state.errMsg && (
            <div className="text-white">{this.state.errMsg}</div>
          )}
          {data && (
            <div>
              <div className="sticky top-0">
                <div>
                  <QuickNavigateHeader data={data}></QuickNavigateHeader>
                </div>
                <SnapshotViewHeader
                  reportCreateTime={reportCreateTime}
                  toggleSetTwd={this.toggleSetTwd}
                  isInTwd={isInTwd}
                  showTrendTable={showTrendTable}
                  selectedSortType={selectedSortType}
                  selectedSortByDayType={selectedSortByDayType}
                  handleSortByDayTypeChange={this.handleSortByDayTypeChange}
                  isOrderByDesc={isOrderByDesc}
                  isInSearchingMode={isInSearchingMode}
                  handleSearch={this.handleSearch}
                  numberOfSearchedData={numberOfSearchedData}
                  handleSortTypeChange={this.handleSortTypeChange}
                  toggleOrderBy={this.toggleOrderBy}
                  filterTabs={filterTabs}
                  trialFilterTabs={trialFilterTabs}
                  selectedFilterType={selectedFilterType}
                  handleFilterTypeChange={this.handleFilterTypeChange}
                  selectedTrialType={selectedTrialType}
                  handleTrialTypeChange={this.handleTrialTypeChange}
                ></SnapshotViewHeader>
              </div>

              {paginatedData &&
                paginatedData.map((network) => {
                  return (
                    <NetworkWrap
                      key={network.gam_network_id}
                      network={network}
                      isInTwd={isInTwd}
                      showTrendTable={showTrendTable}
                      selectedSortType={selectedSortType}
                      handleForceRefreshSnapshot={
                        this.handleForceRefreshSnapshot
                      }
                    ></NetworkWrap>
                  );
                })}

              {paginatedData.length === filteredData.length ? (
                ""
              ) : (
                <button
                  type="button"
                  className="w-full bg-indigo-500 p-4 font-bold text-white hover:bg-indigo-600"
                  onClick={this.handleLoadMore}
                >
                  Load more
                </button>
              )}
            </div>
          )}
        </Suspense>
      </>
    );
  }
}

export default SnapshotViewer;
