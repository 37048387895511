import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import HighchartChart from "../../common/HighchartChart";

class RpmOverview extends React.PureComponent {
  render() {
    const {
      // reports
      rpms,

      // networkTimezone,
    } = this.props;
    return (
      <div className="mb-8">
        <div className="font-semibold text-lg text-gray-700 mb-2">
          RPM Overview:
        </div>

        <div className="bg-white rounded shadow px-4 py-6">
          <RpmChart reports={rpms}></RpmChart>
        </div>
      </div>
    );
  }
}

class RpmChart extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      chartOptions: createChartOptions(props.reports),
    };
  }
  render() {
    const { chartOptions } = this.state;
    return <HighchartChart options={chartOptions}></HighchartChart>;
  }
}

function createChartOptions(reports) {
  const metrics = [
    {
      text: "Unmanaged RPM",
      key: "unmanagedRpm",
      tooltipExtra: {
        text: "Unmanaged Eligible Rev",
        key: "unmanagedEligibleRevenue",
      },
    },
    {
      text: "Benchmark RPM",
      key: "benchmarkRpm",
      tooltipExtra: {
        text: "Benchmark Eligible Rev",
        key: "benchmarkEligibleRevenue",
      },
    },
    {
      text: "Total Managed RPM",
      key: "totalManagedRpm",
      tooltipExtra: {
        text: "Total Managed Eligible Rev",
        key: "totalManagedRevenue",
      },
    },
  ];
  let minY = 0;
  let maxY = 100;
  const series = _.map(metrics, (m) => {
    return {
      name: m.text,
      color: _getSeriesColor(m.key),
      data: _.map(reports, (r) => {
        // const y = r[m.key] * 100;
        const y = r[m.key];
        minY = y < minY ? y : minY;
        maxY = y > maxY ? y : maxY;
        return {
          x: new Date(r.date).getTime(),
          y,
          tooltipExtra: {
            name: m.tooltipExtra.text,
            data: r[m.tooltipExtra.key],
          },
        };
      }),
    };
  });

  const options = {
    title: {
      text: null,
    },
    chart: {
      type: "line",
      zoomType: "x",
      height: 260,
      backgroundColor: "rgba(0,0,0,0)",
    },
    // TODO: beautify tooltip & Use bm / unmanaged color!!
    tooltip: {
      xDateFormat: "%Y/%m/%d %a",
      headerFormat:
        '<div style="font-size:12px; font-weight: bold; text-align: center;">{point.key}</div><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0;font-weight: bold; text-align: right;padding-right: 4px;">{series.name}: </td>' +
        '<td style="padding:0; text-align: right;"><b>${point.y:,.4f}</b></td></tr>' +
        '<tr><td style="color:{series.color};padding:0;font-weight: bold; text-align: right;padding-right: 4px;">{point.tooltipExtra.name}: </td>' +
        '<td style="padding:0; text-align: right;"><b>${point.tooltipExtra.data:,.4f}</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
      crosshairs: true,
    },
    xAxis: getXAxisConfigDateTime(),
    yAxis: {
      title: {
        text: "RPM $",
      },
      // min: minY,
      // max: maxY
    },
    series,
  };

  return options;
}

function getXAxisConfigDateTime() {
  return {
    type: "datetime",
    labels: {
      formatter: function () {
        const m = moment(this.value);
        const d = m.format("MM/DD");
        const wd = m.format("ddd");
        const label = `${d} <br/> ${wd}`;
        // if (isWeekend(this.value)) {
        //   return `<span style="color: #dd6b20;">${label}</span>`;
        // }
        return label;
      },
    },
    tickInterval: 24 * 60 * 60 * 1000, // 1 day
    crosshair: true,
  };
}

const groupTypeMap = {
  benchmark: "#9593FF",
  optimization: "#BBE8D0",
  unmanaged: "#8e8e8e",
  managed: "#02a8e0",
};

function _getSeriesColor(key) {
  if (_.startsWith(key, "benchmark")) {
    return groupTypeMap.benchmark;
  }

  if (_.startsWith(key, "optimization")) {
    return groupTypeMap.optimization;
  }

  if (_.startsWith(key, "unmanaged")) {
    return groupTypeMap.unmanaged;
  }

  if (_.startsWith(key, "managed") || _.startsWith(key, "totalManaged")) {
    return groupTypeMap.managed;
  }

  return "";
}

export default RpmOverview;
