import _ from "lodash";

export function transformToFinalConfig({ config }) {
  let finalConfig = config;

  // omit __xxx in inventory_integration{}.ab_test_traffic_allocation
  _.forIn(finalConfig.inventory_integration, (value, key) => {
    if (value.ab_test_traffic_allocation) {
      if (!value.ab_test_traffic_allocation.__is_enabled) {
        finalConfig.inventory_integration[key] = _.omit(
          finalConfig.inventory_integration[key],
          "ab_test_traffic_allocation"
        );
      }

      _.forIn(value.ab_test_traffic_allocation, (v, k) => {
        if (_.startsWith(k, "__")) {
          value.ab_test_traffic_allocation = _.omit(
            value.ab_test_traffic_allocation,
            k
          );
        }
      });
    }
  });

  //   console.log(finalConfig);
  return finalConfig;
}

export function transformWithAbTestHelper({ config, isInit = false }) {
  const bValues = _.get(config, ["general", "benchmarkValues"]);
  const oValues = _.get(config, ["general", "managedValues"]);

  // check if config.inventory_integration[] has "ab_test_traffic_allocation"
  _.forIn(config.inventory_integration, (item, inv) => {
    const isEnabled = _.has(item, "ab_test_traffic_allocation");
    const ab = _.get(item, "ab_test_traffic_allocation", {});
    ab.__is_enabled = isInit ? isEnabled : ab.__is_enabled;

    ab.__opt_map = _.reduce(
      oValues,
      (result, o, i) => {
        result[o.id] = _.get(ab, ["traffic_ratio_for_opt_values", i], null);
        return result;
      },
      {}
    );

    ab.__bm_map = _.reduce(
      bValues,
      (result, o, i) => {
        result[o.id] = _.get(ab, ["traffic_ratio_for_bm_values", i], null);
        return result;
      },
      {}
    );

    ab.optimization_ratio = _.get(
      item,
      ["ab_test_traffic_allocation", "optimization_ratio"],
      ""
    );

    item.ab_test_traffic_allocation = ab;
  });

  return config;
}
