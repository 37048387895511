import React from "react";
import Tooltip from "react-simple-tooltip";

class MetricTooltip extends React.PureComponent {
  render() {
    const { tooltipMessage, place } = this.props;

    return (
      <>
        <Tooltip
          placement={place || "top"}
          background="rgba(0, 0, 0, 0.9)"
          content={tooltipMessage}
          padding={8}
          radius={4}
          fontSize={"12px"}
        >
          <span className="border-b border-dashed border-gray-600">
            {this.props.children}
          </span>
        </Tooltip>
      </>
    );
  }
}

export default MetricTooltip;
