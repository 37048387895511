import React from "react";
import _ from "lodash";
import LoadingUI from "../common/LoadingUI";
import DateTimeFormatter from "../common/DateTimeFormatter";

const buttonActionClass =
  "px-2 py-2 mr-1 my-1 bg-blue-400 text-xs rounded shadow hover:bg-blue-600 text-white font-semibold";

const buttonActionRedClass =
  "px-2 py-2 mr-1 my-1 bg-red-400 text-xs rounded shadow hover:bg-red-600 text-white font-semibold";

const thClass =
  "px-4 py-2 text-gray-600 text-left text-xs tracking-wider uppercase";
const tdClass = "px-4 py-2 border";

class VaultUsersTable extends React.PureComponent {
  render() {
    const {
      items,
      isLoading,
      errMsg,
      handleOpenPasswordModal,
      handleDeleteUser,
    } = this.props;

    if (isLoading) {
      return <LoadingUI></LoadingUI>;
    }

    if (errMsg) {
      return <div className="text-red-700">{errMsg}</div>;
    }

    if (!items) {
      return "no items";
    }

    return (
      <div>
        <table className="min-w-full shadow divide-y">
          <thead className="bg-gray-100">
            <tr>
              <th className={thClass}>id</th>
              <th
                className={thClass}
                // style={{ width: "40%" }}
              >
                email
              </th>
              <th className={thClass + " text-right"}>Created at</th>
              <th className={thClass + " text-right"}>Updated at</th>
              <th className={thClass + " text-right"}>Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y text-sm">
            {items.map((item) => {
              return (
                <tr key={item.username} className="hover:bg-gray-100">
                  <td className={tdClass}>{item.id}</td>
                  <td className={tdClass}>{item.username}</td>

                  <td className={tdClass + " text-right text-xs text-gray-800"}>
                    <DateTimeFormatter
                      datetime={item.created_at}
                      isLineBreak={true}
                    ></DateTimeFormatter>
                  </td>
                  <td className={tdClass + " text-right text-xs text-gray-800"}>
                    <DateTimeFormatter
                      datetime={item.updated_at}
                      isLineBreak={true}
                    ></DateTimeFormatter>
                  </td>

                  <td className={tdClass + " text-right"}>
                    <button
                      type="button"
                      className={buttonActionClass}
                      onClick={() => handleOpenPasswordModal(item)}
                    >
                      Set new password
                    </button>

                    <button
                      type="button"
                      className={buttonActionRedClass}
                      onClick={() => handleDeleteUser(item)}
                    >
                      Delete user
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default VaultUsersTable;
