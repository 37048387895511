import React from "react";
import _ from "lodash";
import { notify } from "react-notify-toast";

import { VaultAPI } from "apis";
import { buttonConfirmClass } from "helpers/StyleClass";
import {
  _encrypt,
  _decrypt,
  initVault,
  getSecret,
  generateVaultHash,
  generateUserMasterKey,
} from "components/vault/VaultHelper";
import MasterPasswordForm from "components/vault/MasterPasswordForm";
import VaultUserFormModal from "components/vault/VaultUserFormModal";
import VaultUsersTable from "components/vault/VaultUsersTable";
import VaultUserNewPasswordModal from "components/vault/VaultUserNewPasswordModal";

class VaultUsersViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,
      users: null,

      isVaultOpen: false,

      vaultSecret: null,
      mpErrorMsg: null,

      isModalOpen: false,
      currentUser: {},
      saveErrorMsg: null,

      isPWModalOpen: false,
    };

    this.handleSubmitMP = this.handleSubmitMP.bind(this);
    this.handleGetUsers = this.handleGetUsers.bind(this);
    this.handleSaveUser = this.handleSaveUser.bind(this);

    this.handleSetNewPassword = this.handleSetNewPassword.bind(this);
    this.handleDeleteUser = this.handleDeleteUser.bind(this);

    this.handleOpenAddModal = this.handleOpenAddModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenPasswordModal = this.handleOpenPasswordModal.bind(this);
  }

  async componentDidMount() {
    document.title = "Vault Intowow Users | YB Observer";
    // await this.handleSubmitMP("intowow666");
  }

  async handleGetUsers() {
    this.setState({ isLoading: true });

    try {
      const masterHash = await generateVaultHash();
      const users = await VaultAPI.getVaultUsers({ vaultHash: masterHash });

      this.setState({ users });
    } catch (err) {
      console.log(err);
    }

    this.setState({ isLoading: false });
  }

  handleOpenAddModal() {
    this.setState({ isModalOpen: true, currentUser: {} });
  }

  handleOpenPasswordModal(user) {
    this.setState({
      isPWModalOpen: true,
      currentUser: user,
    });
  }

  handleCloseModal() {
    this.setState({
      isModalOpen: false,
      isPWModalOpen: false,
      currentUser: {},
      saveErrorMsg: null,
    });
  }

  async handleDeleteUser(user) {
    const userConfirm = window.confirm(`Delete this user ${user.username}?`);
    if (!userConfirm) return;

    try {
      const masterHash = await generateVaultHash();
      const deleteParams = {
        userId: user.id,
        vaultHash: masterHash,
      };

      const r = await VaultAPI.deleteVaultUser(deleteParams);
      console.log(r);

      notify.show("User deleted! Automatically reloading page...", "success");

      setTimeout(() => {
        this.handleGetUsers();
      }, 1000);
    } catch (err) {
      console.error(err);
      notify.show(`Failed to delete user: ${err.toString()}`, "error");
    }
  }

  async handleSetNewPassword({ newPassword }) {
    const { currentUser } = this.state;

    this.setState({ isSaving: true });
    try {
      const masterHash = await generateVaultHash();
      const userMasterKey = await generateUserMasterKey(newPassword);
      const { masterKey, encryptionKey } = getSecret();

      const userSecret = _encrypt(userMasterKey, encryptionKey.toString("hex"));
      const pwSecret = _encrypt(masterKey, newPassword);

      const saveParams = {
        userId: currentUser.id,
        userSecret,
        pwSecret,
        vaultHash: masterHash,
      };

      const r = await VaultAPI.updateVaultUser(saveParams);
      console.log("save user response", r);

      this.setState({ saveErrorMsg: null });
      notify.show("User saved! Automatically reloading page...", "success");

      setTimeout(() => {
        this.handleGetUsers();
        this.handleCloseModal();
      }, 1000);
    } catch (err) {
      this.setState({
        saveErrorMsg: err.toString(),
      });
    }

    this.setState({ isSaving: false });
  }

  async handleSaveUser(user) {
    this.setState({ isSaving: true });
    try {
      const masterHash = await generateVaultHash();
      const userMasterKey = await generateUserMasterKey(user.password);
      const { masterKey, encryptionKey } = getSecret();

      const userSecret = _encrypt(userMasterKey, encryptionKey.toString("hex"));
      const pwSecret = _encrypt(masterKey, user.password);

      const saveParams = {
        email: user.email,
        userSecret,
        pwSecret,
        vaultHash: masterHash,
      };

      const r = await VaultAPI.createVaultUser(saveParams);
      console.log("save user response", r);

      this.setState({ saveErrorMsg: null });
      notify.show("User saved! Automatically reloading page...", "success");

      setTimeout(() => {
        this.handleGetUsers();
        this.handleCloseModal();
      }, 1000);
    } catch (err) {
      this.setState({
        saveErrorMsg: err.toString(),
      });
    }

    this.setState({ isSaving: false });
  }

  async handleSubmitMP(masterPassword) {
    this.setState({ isLoading: true });
    try {
      await initVault(masterPassword);
      this.setState({ mpErrorMsg: null, isVaultOpen: true });

      await this.handleGetUsers();
    } catch (err) {
      console.log("wrong password", err);
      this.setState({ mpErrorMsg: "Wrong Password" });
    }

    this.setState({ isLoading: false });
  }

  render() {
    const {
      mpErrorMsg,
      isVaultOpen,
      isLoading,
      users,
      errMsg,

      isModalOpen,
      isSaving,
      saveErrorMsg,
      currentUser,

      isPWModalOpen,
    } = this.state;

    return (
      <div className="bg-white">
        <div className="px-12">
          <div className="flex justify-between pt-8 mb-4">
            <div className="font-extrabold text-gray-900 text-4xl">
              Vault Intowow Users
            </div>
          </div>
        </div>
        <div className="bg-gray-200 px-12 py-8 min-h-screen">
          {isVaultOpen ? (
            <>
              <UsersListView
                users={users}
                isLoading={isLoading}
                errMsg={errMsg}
                handleOpenAddModal={this.handleOpenAddModal}
                handleOpenPasswordModal={this.handleOpenPasswordModal}
                handleDeleteUser={this.handleDeleteUser}
              ></UsersListView>

              {isModalOpen && (
                <VaultUserFormModal
                  isOpenModal={isModalOpen}
                  handleClose={this.handleCloseModal}
                  isSaving={isSaving}
                  saveErrorMsg={saveErrorMsg}
                  user={currentUser}
                  handleSave={this.handleSaveUser}
                ></VaultUserFormModal>
              )}

              {isPWModalOpen && (
                <VaultUserNewPasswordModal
                  user={currentUser}
                  isOpenModal={isPWModalOpen}
                  isSaving={isSaving}
                  saveErrorMsg={saveErrorMsg}
                  handleClose={this.handleCloseModal}
                  handleSave={this.handleSetNewPassword}
                ></VaultUserNewPasswordModal>
              )}
            </>
          ) : (
            <MasterPasswordForm
              handleSubmitMP={this.handleSubmitMP}
              mpErrorMsg={mpErrorMsg}
              isLoading={isLoading}
            ></MasterPasswordForm>
          )}
        </div>
      </div>
    );
  }
}

class UsersListView extends React.Component {
  render() {
    const {
      users,
      isLoading,
      errMsg,
      handleOpenAddModal,
      handleOpenPasswordModal,
      handleDeleteUser,
    } = this.props;

    return (
      <div>
        <div className="flex justify-between items-end">
          <div className="text-sm text-gray-800 pb-1">
            {users && users.length} users
          </div>
          <button
            type="button"
            className={buttonConfirmClass}
            onClick={handleOpenAddModal}
          >
            Create user
          </button>
        </div>
        <VaultUsersTable
          items={users}
          isLoading={isLoading}
          errMsg={errMsg}
          handleOpenPasswordModal={handleOpenPasswordModal}
          handleDeleteUser={handleDeleteUser}
        ></VaultUsersTable>
      </div>
    );
  }
}

export default VaultUsersViewer;
