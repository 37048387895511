import React from "react";
import _ from "lodash";
import Select from "react-select";
import { ACTION_CATEGORIES } from "../../../constants/Recipes";
import { UNIT_STATE } from "../../../constants/Unit-State";
import { UNIT_STATUS } from "../../../constants/Unit-Status";
import ActionPretty from "./ActionPretty";

const basicInputClass =
  "bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded py-1 px-4 block w-full appearance-none leading-normal";

class ActionsEditor extends React.Component {
  constructor(props) {
    super(props);

    this.handleAddAction = this.handleAddAction.bind(this);
    this.handleRemoveAction = this.handleRemoveAction.bind(this);
  }

  handleAddAction(item) {
    this.props.handleAdd(item);
  }

  handleRemoveAction(item) {
    this.props.handleRemove(item);
  }

  render() {
    const { actionTemplates } = this.props;

    return (
      <div>
        <div className="mb-4 flex flex-col gap-2 w-1/2">
          {actionTemplates.map((p) => {
            return (
              <div
                className="bg-gray-100 px-2 flex justify-between items-center"
                key={p.cat}
              >
                <ActionPretty item={p}></ActionPretty>

                <button
                  type="button"
                  className="text-blue-600 hover:underline px-2"
                  onClick={() => this.handleRemoveAction(p)}
                >
                  Remove
                </button>
              </div>
            );
          })}
        </div>
        <div>
          <ActionEditor
            actionTemplates={actionTemplates}
            handleAddAction={this.handleAddAction}
          ></ActionEditor>
        </div>
      </div>
    );
  }
}

class ActionEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cat: null,
      value: "",

      finalCat: null,
      finalValue: "",
    };

    this.handleCatChanged = this.handleCatChanged.bind(this);
    this.handleValueChanged = this.handleValueChanged.bind(this);
    this.handleAddClicked = this.handleAddClicked.bind(this);
  }

  handleCatChanged(option) {
    this.setState({ cat: option, finalCat: option.value });
  }

  handleValueChanged(value) {
    const { finalCat } = this.state;
    if (
      finalCat === ACTION_CATEGORIES.UNIT_STATE.key ||
      finalCat === ACTION_CATEGORIES.UNIT_STATUS.key
    ) {
      let finalValue = _.parseInt(value.value);
      // unit_state could be string (last_state)
      if (_.isNaN(finalValue)) {
        finalValue = value.value;
      }

      this.setState({ value, finalValue });
    } else {
      this.setState({ value, finalValue: value });
    }
  }

  handleAddClicked() {
    const { finalCat, finalValue } = this.state;
    const newItem = {
      cat: finalCat,
      value: finalValue,
    };
    this.props.handleAddAction(newItem);
    this.setState({
      cat: null,
      value: "",

      finalCat: null,
      finalValue: "",
    });
  }

  isAddClickable() {
    let isClickable = false;
    const { finalCat, finalValue } = this.state;
    if (finalCat && finalValue) {
      isClickable = true;
    }

    return isClickable;
  }

  render() {
    const { actionTemplates, handleAddAction } = this.props;
    const { cat, value, finalCat, finalValue } = this.state;

    return (
      <>
        <div className="border p-2">
          <div className="flex items-end gap-4">
            <div>
              <span className="font-semibold text-gray-800 text-sm">cat</span>
              <CatSelector
                actionTemplates={actionTemplates}
                selectedValue={cat}
                handleChange={this.handleCatChanged}
              ></CatSelector>
            </div>
            <div className="flex flex-col flex-auto">
              <span className="font-semibold text-gray-800 text-sm">value</span>
              <ValueSelector
                cat={finalCat}
                selectedValue={value}
                handleChange={this.handleValueChanged}
              ></ValueSelector>
            </div>
            <div>
              <button
                type="button"
                className="bg-blue-100 rounded font-semibold px-4 py-2 text-blue-800"
                onClick={this.handleAddClicked}
              >
                Add
              </button>
            </div>
          </div>
        </div>
        {/* <div>{JSON.stringify(this.state)}</div> */}
      </>
    );
  }
}

const CatSelector = (props) => {
  const { actionTemplates } = props;
  const existingCats = _.map(actionTemplates, "cat");
  // {value, label}
  const cats = _.difference(_.keys(ACTION_CATEGORIES), existingCats);
  const options = _.map(cats, (p) => {
    const label = ACTION_CATEGORIES[p].description;
    return { value: p, label };
  });

  const { selectedValue, handleChange } = props;

  return (
    <Select
      className="w-64"
      defaultValue={selectedValue}
      value={selectedValue}
      onChange={handleChange}
      options={options}
      isSearchable={false}
    ></Select>
  );
};

class ValueSelector extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { cat, selectedValue, handleChange } = this.props;

    if (!cat) {
      return <div>-</div>;
    }

    if (cat === ACTION_CATEGORIES.UNIT_STATE.key) {
      return (
        <UnitStateSelector
          selectedValue={selectedValue}
          handleChange={handleChange}
          isMulti={false}
          includeLastStateOption={true}
        ></UnitStateSelector>
      );
    }

    if (cat === ACTION_CATEGORIES.UNIT_STATUS.key) {
      return (
        <UnitStatusSelector
          selectedValue={selectedValue}
          handleChange={handleChange}
          isMulti={false}
        ></UnitStatusSelector>
      );
    }

    if (
      cat === ACTION_CATEGORIES.ADD_LABEL.key ||
      cat === ACTION_CATEGORIES.REMOVE_LABEL.key
    ) {
      return (
        <input
          type="text"
          value={selectedValue}
          className={basicInputClass}
          autoFocus
          onChange={(e) => handleChange(e.target.value)}
        ></input>
      );
    }

    if (cat === ACTION_CATEGORIES.ALERT.key) {
      return (
        <input
          type="text"
          value={selectedValue}
          className={basicInputClass}
          autoFocus
          onChange={(e) => handleChange(e.target.value)}
        ></input>
      );
    }

    return "Not implemented";
  }
}

const UnitStateSelector = (props) => {
  // {value, label}
  const { selectedValue, handleChange, isMulti, includeLastStateOption } =
    props;

  const items = _.keys(UNIT_STATE);
  const unitStates = _.map(items, (item) => {
    const name = UNIT_STATE[item];
    return { value: item, label: `${item}: ${name}` };
  });

  let options = [];
  if (includeLastStateOption) {
    const lastState = {
      value: "%% unit.lastState %%",
      label: "%% unit.lastState %%",
    };
    options = [...unitStates, lastState];
  } else {
    options = unitStates;
  }

  return (
    <Select
      defaultValue={selectedValue}
      value={selectedValue}
      onChange={handleChange}
      options={options}
      isSearchable={false}
      isMulti={isMulti}
    ></Select>
  );
};

const UnitStatusSelector = (props) => {
  // {value, label}
  const items = _.keys(UNIT_STATUS);
  const options = _.map(items, (item) => {
    const name = UNIT_STATUS[item];
    return { value: item, label: `${item}: ${name}` };
  });

  const { selectedValue, handleChange, isMulti } = props;

  return (
    <Select
      defaultValue={selectedValue}
      value={selectedValue}
      onChange={handleChange}
      options={options}
      isSearchable={false}
      isMulti={isMulti}
    ></Select>
  );
};

export default ActionsEditor;
