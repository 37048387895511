import React from "react";
import _ from "lodash";

import ModalWrapper from "../../../common/ModalWrapper";
import { NetworkAPI } from "apis";
import ReactJson from "@microlink/react-json-view";
import LoadingUI from "components/common/LoadingUI";

const buttonActionClass =
  "px-2 py-0 bg-gray-200 text-sm rounded border border-gray-400 shadow hover:bg-gray-300 text-gray-900";

class CompareBuildModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,
      build: props.build,

      comparison: null,
      compareBuildType: "LATEST",
      comparisonBuildId: null,
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.compareBuild = this.compareBuild.bind(this);
    this.handleChangeType = this.handleChangeType.bind(this);
  }

  async componentDidMount() {
    //
    await this.compareBuild();
    // console.log(this.props.builds);
  }

  async compareBuild() {
    this.setState({ isLoading: true, errMsg: null });
    try {
      const { networkId, buildId, build } = this.props;
      const r = await NetworkAPI.createBuildComparison({
        networkId,
        packageName: build.packageName,
        baseBuildId: buildId,
        // comparisonBuildId,
      });
      console.log(r);
      this.setState({ comparison: r });
    } catch (err) {
      this.setState({
        errMsg: err,
      });
    }
    this.setState({ isLoading: false });
  }

  async createBuildComparison({
    networkId,
    packageName,
    baseBuildId,
    comparisonBuildId,
  }) {
    return await NetworkAPI.createBuildComparison({
      networkId,
      packageName,
      baseBuildId,
      comparisonBuildId,
    });
  }

  handleChangeType(type) {
    this.setState({
      compareBuildType: type,
    });
  }

  handleCancel() {
    this.props.handleClose();
  }

  render() {
    const {
      isLoading,
      errMsg,
      comparison,
      compareBuildType,
      comparisonBuildId,
    } = this.state;
    const { isOpenViewModal, handleClose, buildId, build } = this.props;

    return (
      <ModalWrapper
        isOpen={isOpenViewModal}
        showCloseFooter={true}
        handleClose={handleClose}
      >
        <>
          <div className="border-b -mx-6 mb-4 px-6 pb-2 text-lg font-bold">
            {buildId}{" "}
            <span className="font-normal">
              ({build.packageName} version {build.packageVersion})
            </span>
          </div>

          <div className="overflow-y-auto" style={{ height: "360px" }}>
            <div>
              <div>Compare with:</div>
              <div>
                <label htmlFor="radio_latest" className="cursor-pointer">
                  <input
                    id="radio_latest"
                    type="radio"
                    value="LATEST"
                    checked={compareBuildType === "LATEST"}
                    onChange={() => this.handleChangeType("LATEST")}
                  />{" "}
                  Latest
                </label>
                {/* <label htmlFor="radio_previous" className="cursor-pointer">
                  <input
                    id="radio_previous"
                    type="radio"
                    value="PREVIOUS"
                    checked={compareBuildType === "PREVIOUS"}
                    onChange={() => this.handleChangeType("PREVIOUS")}
                  />{" "}
                  Previous build (TODO)
                </label> */}
                {/* <label htmlFor="radio_custom" className="cursor-pointer">
                  <input
                    id="radio_custom"
                    type="radio"
                    value="CUSTOM"
                    checked={compareBuildType === "CUSTOM"}
                    onChange={() => this.handleChangeType("CUSTOM")}
                  />{" "}
                  Custom 
                </label> */}
              </div>{" "}
              {/* <button
                type="button"
                className={buttonActionClass}
                onClick={this.handleCompareBuild}
              >
                Compare
              </button>{" "} */}
            </div>

            {isLoading && <LoadingUI></LoadingUI>}
            {comparison && (
              <ReactJson
                name={false}
                displayDataTypes={false}
                theme={"monokai"}
                enableClipboard={false}
                src={comparison}
              ></ReactJson>
            )}
            <div className="text-red-600">{errMsg}</div>
          </div>
        </>
      </ModalWrapper>
    );
  }
}

export default CompareBuildModal;
