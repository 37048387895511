import React from "react";
import _ from "lodash";
import TextAreaWithJsonCheck from "components/common/TextAreaWithJsonCheck";
import ClickToCopyWrapper from "components/common/ClickToCopyWrapper";

class PriceGroupsControl extends React.PureComponent {
  constructor(props) {
    super(props);

    let items = this.props.inputValue;
    let errorMsg = null;

    if (typeof items === "object" && !_.isEmpty(items)) {
      items = JSON.stringify(items);
    }

    this.state = {
      items,
      errorMsg,
    };

    this.handleItemsChanged = this.handleItemsChanged.bind(this);
  }

  handleItemsChanged(items) {
    this.setState({ items, errorMsg: null });

    if (_.isEmpty(items)) {
      this.setState({
        errorMsg: "Cannot be empty",
      });
      this.props.handleHasError(true);
      return;
    }

    // example: [{ "inclusiveMin": 0, "exclusiveMax": 8, "delta": 0.01 }]
    try {
      const value = JSON.parse(items);

      if (!_.isArray(value)) {
        this.setState({
          errorMsg: `Should be an array (ex. [{ "inclusiveMin": 0, "exclusiveMax": 8, "delta": 0.01 }])`,
        });
        this.props.handleHasError(true);
        return;
      }

      if (_.isArray(value)) {
        if (_.isEmpty(value)) {
          this.setState({
            errorMsg: `Should be an array of objects (ex. [{ "inclusiveMin": 0, "exclusiveMax": 8, "delta": 0.01 }])`,
          });
          this.props.handleHasError(true);
          return;
        }

        for (const p of value) {
          if (
            !_.has(p, "inclusiveMin") ||
            !_.has(p, "exclusiveMax") ||
            !_.has(p, "delta")
          ) {
            this.setState({
              errorMsg: `Should be an array of objects (ex. [{ "inclusiveMin": 0, "exclusiveMax": 8, "delta": 0.01 }])`,
            });
            this.props.handleHasError(true);
            return;
          }

          if (
            p.inclusiveMin === "" ||
            _.isNil(p.inclusiveMin) ||
            !_.isNumber(p.inclusiveMin)
          ) {
            this.setState({
              errorMsg: `inclusiveMin should be a number`,
            });
            this.props.handleHasError(true);
            return;
          }

          if (
            p.exclusiveMax === "" ||
            _.isNil(p.exclusiveMax) ||
            !_.isNumber(p.exclusiveMax)
          ) {
            this.setState({
              errorMsg: `exclusiveMax should be a number`,
            });
            this.props.handleHasError(true);
            return;
          }

          if (p.delta === "" || _.isNil(p.delta) || !_.isNumber(p.delta)) {
            this.setState({
              errorMsg: `delta should be a number`,
            });
            this.props.handleHasError(true);
            return;
          }
        }
      }
    } catch (err) {
      this.setState({
        errorMsg: `Not a valid json`,
      });
      this.props.handleHasError(true);
      return;
    }

    const value = JSON.parse(items);
    this.props.handleHasError(false);
    this.props.handleNewValueChanged(value);
  }

  render() {
    const { valueSchema } = this.props;

    const { items, errorMsg } = this.state;

    return (
      <div className="border rounded ml-4 bg-white p-2 text-sm text-gray-700">
        <>
          {valueSchema.sample && (
            <div className="flex items-center">
              ex. {valueSchema.sample}{" "}
              <ClickToCopyWrapper
                copyText={valueSchema.sample}
              ></ClickToCopyWrapper>
            </div>
          )}
          <TextAreaWithJsonCheck
            inputValue={items}
            handleInputChanged={this.handleItemsChanged}
          ></TextAreaWithJsonCheck>

          <div className="text-red-600">{errorMsg}</div>
        </>
      </div>
    );
  }
}

export default PriceGroupsControl;
