import React from "react";
import _ from "lodash";
import Select from "react-select";
import { PROPERTIES, OPERATIONS } from "../../../constants/Recipes";
import { UNIT_STATE } from "../../../constants/Unit-State";
import { UNIT_STATUS } from "../../../constants/Unit-Status";
import PreconditionPretty from "./PreconditionPretty";

const basicInputClass =
  "bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded py-1 px-4 block w-full appearance-none leading-normal";

class PreconditionsEditor extends React.Component {
  constructor(props) {
    super(props);

    this.handleAddPrecondition = this.handleAddPrecondition.bind(this);
    this.handleRemovePrecondition = this.handleRemovePrecondition.bind(this);
  }

  handleAddPrecondition(item) {
    this.props.handleAdd(item);
  }

  handleRemovePrecondition(item) {
    this.props.handleRemove(item);
  }

  render() {
    const { preconditions } = this.props;

    return (
      <div>
        <div className="mb-4 flex flex-col gap-2 w-1/2">
          {preconditions.map((p) => {
            return (
              <div
                className="bg-gray-100 px-2 flex justify-between items-center"
                key={p.property}
              >
                <PreconditionPretty item={p}></PreconditionPretty>

                <button
                  type="button"
                  className="text-blue-600 hover:underline px-2"
                  onClick={() => this.handleRemovePrecondition(p)}
                >
                  Remove
                </button>
              </div>
            );
          })}
        </div>
        <div>
          <PreconditionEditor
            preconditions={preconditions}
            handleAddPrecondition={this.handleAddPrecondition}
          ></PreconditionEditor>
        </div>
      </div>
    );
  }
}

class PreconditionEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      property: "",
      op: "",
      value: "",

      finalProperty: "",
      finalOp: "",
      finalValue: "",
    };

    this.handlePropertyChanged = this.handlePropertyChanged.bind(this);
    this.handleOpChanged = this.handleOpChanged.bind(this);
    this.handleValueChanged = this.handleValueChanged.bind(this);
    this.handleAddClicked = this.handleAddClicked.bind(this);
  }

  handlePropertyChanged(option) {
    this.setState({ property: option, finalProperty: option.value });
  }

  handleOpChanged(option) {
    this.setState({ op: option, finalOp: option.value });
  }

  handleValueChanged(value) {
    const { finalProperty } = this.state;
    if (
      finalProperty === PROPERTIES.unit_state.key ||
      finalProperty === PROPERTIES.unit_status.key
    ) {
      let finalValue = _.parseInt(value.value);
      if (_.isArray(value)) {
        finalValue = _.map(value, (v) => {
          return _.parseInt(v.value);
        });
      }
      this.setState({ value, finalValue });
    } else if (
      finalProperty === PROPERTIES.unit_age.key ||
      finalProperty === PROPERTIES.unit_state_age.key
    ) {
      let finalValue = _.parseInt(value);
      this.setState({ value, finalValue });
    } else {
      this.setState({ value, finalValue: value });
    }
  }

  handleAddClicked() {
    const { finalProperty, finalOp, finalValue } = this.state;
    const newItem = {
      property: finalProperty,
      op: finalOp,
      value: finalValue,
    };
    this.props.handleAddPrecondition(newItem);

    this.setState({
      property: "",
      op: "",
      value: "",

      finalProperty: "",
      finalOp: "",
      finalValue: "",
    });
  }

  isAddClickable() {
    let isClickable = false;
    const { finalProperty, finalOp, finalValue } = this.state;
    if (finalProperty && finalOp && finalValue) {
      isClickable = true;
    }

    return isClickable;
  }

  render() {
    const { preconditions } = this.props;
    const { property, op, value, finalProperty, finalOp, finalValue } =
      this.state;

    return (
      <>
        <div className="border p-2">
          <div className="flex items-end gap-4">
            <div className="flex flex-col flex-auto">
              <span className="font-semibold text-gray-800 text-sm">
                property
              </span>
              <PropertySelector
                preconditions={preconditions}
                selectedValue={property}
                handleChange={this.handlePropertyChanged}
              ></PropertySelector>
            </div>
            <div>
              <span className="font-semibold text-gray-800 text-sm">op</span>
              <OperationSelector
                property={finalProperty}
                selectedValue={op}
                handleChange={this.handleOpChanged}
              ></OperationSelector>
            </div>
            <div className="flex flex-col flex-auto">
              <span className="font-semibold text-gray-800 text-sm">value</span>
              <ValueSelector
                property={finalProperty}
                op={finalOp}
                selectedValue={value}
                handleChange={this.handleValueChanged}
              ></ValueSelector>
            </div>
            <div>
              <button
                type="button"
                className="bg-blue-100 rounded font-semibold px-4 py-2 text-blue-800"
                onClick={this.handleAddClicked}
              >
                Add
              </button>
            </div>
          </div>
        </div>
        {/* <div>{JSON.stringify(this.state)}</div> */}
      </>
    );
  }
}

const PropertySelector = (props) => {
  const { preconditions } = props;
  const existingProperties = _.map(preconditions, "property");
  // {value, label}
  const properties = _.difference(_.keys(PROPERTIES), existingProperties);
  const options = _.map(properties, (p) => {
    const key = PROPERTIES[p].key;
    const desc = PROPERTIES[p].description;
    return { value: key, label: `${key}: ${desc}` };
  });

  const { selectedValue, handleChange } = props;

  return (
    <Select
      defaultValue={selectedValue}
      value={selectedValue}
      onChange={handleChange}
      options={options}
      isSearchable={false}
    ></Select>
  );
};

const OperationSelector = (props) => {
  const { property, selectedValue, handleChange } = props;
  if (!property) {
    return <div>-</div>;
  }

  const availableOps = PROPERTIES[property].availableOps;

  // {value, label}
  const items = _.intersection(_.keys(OPERATIONS), availableOps);

  const options = _.map(items, (item) => {
    const i = OPERATIONS[item];
    return { value: i.key, label: i.description };
  });

  return (
    <Select
      className="w-56"
      defaultValue={selectedValue}
      value={selectedValue}
      onChange={handleChange}
      options={options}
      isSearchable={false}
    ></Select>
  );
};

class ValueSelector extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { property, op, selectedValue, handleChange } = this.props;

    if (!property || !op) {
      return <div>-</div>;
    }

    let isMulti = OPERATIONS[op].isMulti;

    if (property === PROPERTIES.unit_state.key) {
      return (
        <UnitStateSelector
          selectedValue={selectedValue}
          handleChange={handleChange}
          isMulti={isMulti}
        ></UnitStateSelector>
      );
    }

    if (property === PROPERTIES.unit_status.key) {
      return (
        <UnitStatusSelector
          selectedValue={selectedValue}
          handleChange={handleChange}
          isMulti={isMulti}
        ></UnitStatusSelector>
      );
    }

    if (property === PROPERTIES.unit_age.key) {
      return (
        <input
          type="number"
          value={selectedValue}
          className={basicInputClass}
          autoFocus
          onChange={(e) => handleChange(e.target.value)}
        ></input>
      );
    }

    if (property === PROPERTIES.unit_state_age.key) {
      return (
        <input
          type="number"
          value={selectedValue}
          className={basicInputClass}
          min={1}
          autoFocus
          onChange={(e) => handleChange(e.target.value)}
        ></input>
      );
    }

    return "Not implemented";
  }
}

const UnitStateSelector = (props) => {
  // {value, label}
  const items = _.keys(UNIT_STATE);
  const options = _.map(items, (item) => {
    const name = UNIT_STATE[item];
    return { value: item, label: `${item}: ${name}` };
  });

  const { selectedValue, handleChange, isMulti } = props;

  return (
    <Select
      defaultValue={selectedValue}
      value={selectedValue}
      onChange={handleChange}
      options={options}
      isSearchable={false}
      isMulti={isMulti}
    ></Select>
  );
};

const UnitStatusSelector = (props) => {
  // {value, label}
  const items = _.keys(UNIT_STATUS);
  const options = _.map(items, (item) => {
    const name = UNIT_STATUS[item];
    return { value: item, label: `${item}: ${name}` };
  });

  const { selectedValue, handleChange, isMulti } = props;

  return (
    <Select
      defaultValue={selectedValue}
      value={selectedValue}
      onChange={handleChange}
      options={options}
      isSearchable={false}
      isMulti={isMulti}
    ></Select>
  );
};

export default PreconditionsEditor;
