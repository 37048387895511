import React from "react";
import _ from "lodash";
import AdSizesFormatter from "./AdSizesFormatter";
import NewAdSizeControl from "./NewAdSizeControl";

const FLUID_SIZE = "fluid";

class AdSizesControl extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      adSizes: this.props.inputValue,
      currentAdSize: {
        width: "",
        height: "",
      },

      adSizeType: "CUSTOM", // FLUID, CUSTOM
      errorMsg: null,
    };

    this.handleAddNewAdSize = this.handleAddNewAdSize.bind(this);
    this.handleRemoveAdSize = this.handleRemoveAdSize.bind(this);
    this.handleChangeType = this.handleChangeType.bind(this);
    this.handleAddFluidSize = this.handleAddFluidSize.bind(this);
  }

  handleAddFluidSize() {
    const newAdSize = FLUID_SIZE;
    // validate
    console.log(
      this.state.adSizes,
      newAdSize,
      _.some(this.state.adSizes, newAdSize)
    );
    if (_.indexOf(this.state.adSizes, newAdSize) !== -1) {
      this.setState({
        errorMsg: "This ad size already exists",
      });
      return;
    }

    this.handleAddNewAdSize(newAdSize);
  }

  handleChangeType(type) {
    this.setState({
      adSizeType: type,
      errorMsg: null,
    });
  }

  handleAddNewAdSize(newAdSize) {
    console.log("add new adsiuze", newAdSize);

    const adSizes = [...this.state.adSizes, newAdSize];
    this.setState({
      adSizes,
    });

    this.props.handleNewValueChanged(adSizes);
  }

  handleRemoveAdSize(adSize) {
    const adSizes = _.without(this.state.adSizes, adSize);
    console.log("remove adize", adSize, adSizes);
    this.setState({
      adSizes,
    });

    this.props.handleNewValueChanged(adSizes);
  }

  render() {
    // const { valueSchema, configValue, inputValue } = this.props;
    // console.log(valueSchema, configValue, inputValue);

    const { allowFluidSize } = this.props;
    const { adSizes, currentAdSize, adSizeType, errorMsg } = this.state;

    return (
      <div className="text-sm">
        <div className="mb-2">
          <div className="text-gray-800">Ad Sizes ({adSizes.length}):</div>
          <AdSizesFormatter
            adSizes={adSizes}
            showRemoveButton={true}
            handleRemoveAdSize={this.handleRemoveAdSize}
          ></AdSizesFormatter>
        </div>

        {allowFluidSize && (
          <div>
            <div className="mb-1">
              <label htmlFor="radio_fluid" className="cursor-pointer">
                <input
                  id="radio_fluid"
                  type="radio"
                  value="FLUID"
                  checked={adSizeType === "FLUID"}
                  onChange={() => this.handleChangeType("FLUID")}
                />{" "}
                Fluid
              </label>

              {adSizeType === "FLUID" && (
                <button
                  type="button"
                  className="text-blue-700 bg-blue-100 font-semibold rounded px-2 py-1"
                  onClick={this.handleAddFluidSize}
                >
                  Add <b>fluid</b>
                </button>
              )}
            </div>

            <div>
              <label htmlFor="radio_custom" className="cursor-pointer">
                <input
                  id="radio_custom"
                  type="radio"
                  value="CUSTOM"
                  checked={adSizeType === "CUSTOM"}
                  onChange={() => this.handleChangeType("CUSTOM")}
                />{" "}
                Custom
              </label>
            </div>
          </div>
        )}

        {adSizeType === "CUSTOM" && (
          <NewAdSizeControl
            adSizes={adSizes}
            currentAdSize={currentAdSize}
            handleAddNewAdSize={this.handleAddNewAdSize}
          ></NewAdSizeControl>
        )}

        <div className="text-red-600">{errorMsg}</div>
      </div>
    );
  }
}

export default AdSizesControl;
