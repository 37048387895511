import React from "react";
import moment from "moment-timezone";
import { FaArrowRight } from "react-icons/fa";
import NumberFormat from "../../common/NumberFormat";
import FadeInWrapper from "./FadeInWrapper";
import InfoDescriptionTooltip from "./InfoDescriptionTooltip";
import InventoryScoreGaugeChart from "./InventoryScoreGaugeChart";

const fixedWidthStyle = { maxWidth: "1280px", margin: "auto" };

// V2
class InventorySectionForCst extends React.Component {
  render() {
    const { inventoryData, selectedYieldSetIds, updatedTime } = this.props;
    const isYieldSetFilter = selectedYieldSetIds !== -1;

    const ut = moment(updatedTime);
    const updatedTimeStr = `${ut.format("YYYY/MM/DD HH:mm")} (${ut.fromNow()})`;

    return (
      <div style={fixedWidthStyle}>
        <div className="mb-4 flex items-center justify-between">
          <div className="text-2xl font-bold">Inventory</div>

          <div className="text-xs text-gray-600">
            Updated at: {updatedTimeStr}
          </div>
        </div>

        <div className="grid grid-cols-10 gap-4">
          <div className="col-span-7">
            <div className="border rounded shadow flex justify-around p-4">
              <div className="flex flex-col items-center">
                <div className="flex items-center text-sm font-semibold text-gray-700">
                  Total Units
                  <InfoDescriptionTooltip
                    metricKey="TOTAL_UNITS"
                    place="top"
                  ></InfoDescriptionTooltip>
                </div>
                <div className="pb-1 text-3xl">
                  <FadeInWrapper key={inventoryData.totalUnits}>
                    {isYieldSetFilter ? (
                      "-"
                    ) : (
                      <NumberFormat
                        value={inventoryData.totalUnits}
                        format="0,0"
                      ></NumberFormat>
                    )}
                  </FadeInWrapper>
                </div>
              </div>

              <div className="flex items-center text-gray-600">
                <FaArrowRight></FaArrowRight>
              </div>

              <div className="flex flex-col items-center">
                <div className="flex items-center text-sm font-semibold text-gray-700">
                  Supported Units
                  <InfoDescriptionTooltip
                    metricKey="SUPPORTED_UNITS"
                    place="top"
                  ></InfoDescriptionTooltip>
                </div>
                <div className="pb-1 text-3xl">
                  <FadeInWrapper key={inventoryData.compatibleUnits}>
                    {isYieldSetFilter ? (
                      "-"
                    ) : (
                      <NumberFormat
                        value={inventoryData.compatibleUnits}
                        format="0,0"
                      ></NumberFormat>
                    )}
                  </FadeInWrapper>
                </div>
              </div>

              <div className="flex items-center text-gray-600">
                <FaArrowRight></FaArrowRight>
              </div>

              <div className="flex flex-col items-center">
                <div className="flex items-center text-sm font-semibold text-gray-700">
                  Onboarded Units
                  <InfoDescriptionTooltip
                    metricKey="ONBOARDED_UNITS"
                    place="top"
                  ></InfoDescriptionTooltip>
                </div>
                <div className="pb-1 text-3xl">
                  <FadeInWrapper key={inventoryData.onboardedUnits}>
                    {isYieldSetFilter ? (
                      <NumberFormat
                        value={inventoryData.onboardedUnits}
                        format="0,0"
                      ></NumberFormat>
                    ) : (
                      <NumberFormat
                        value={inventoryData.onboardedUnits}
                        format="0,0"
                      ></NumberFormat>
                    )}
                  </FadeInWrapper>
                </div>
              </div>

              <div className="flex items-center text-gray-600">
                <FaArrowRight></FaArrowRight>
              </div>

              <div className="flex flex-col items-center">
                <div className="flex items-center text-sm font-semibold text-gray-700">
                  Running Units
                  <InfoDescriptionTooltip
                    metricKey="RUNNING_UNITS"
                    place="top"
                  ></InfoDescriptionTooltip>
                </div>
                <div className="pb-1 text-3xl">
                  <FadeInWrapper key={inventoryData.runningUnits}>
                    <NumberFormat
                      value={inventoryData.runningUnits}
                      format="0,0"
                    ></NumberFormat>
                  </FadeInWrapper>
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-3">
            <div className="border rounded shadow flex justify-around bg-gray-200 p-4">
              <div className="flex flex-col items-center">
                <div className="flex items-center text-sm font-semibold text-gray-700">
                  Traffic Onboarded Score
                  <InfoDescriptionTooltip
                    metricKey="TRAFFIC_ONBOARDED_SCORE"
                    place="top"
                  ></InfoDescriptionTooltip>
                </div>
                <div>
                  {/* <FadeInWrapper key={inventoryData.compatibleRequests}>
                    <InventoryScoreGaugeChart
                      value={inventoryData.trafficProtectedScoreNetwork}
                    ></InventoryScoreGaugeChart>
                  </FadeInWrapper> */}
                  {isYieldSetFilter ? (
                    <FadeInWrapper key={inventoryData.onboardedRequests}>
                      <InventoryScoreGaugeChart
                        value={inventoryData.trafficProtectedScoreYieldSet}
                      ></InventoryScoreGaugeChart>
                    </FadeInWrapper>
                  ) : (
                    <FadeInWrapper key={inventoryData.compatibleRequests}>
                      <InventoryScoreGaugeChart
                        value={inventoryData.trafficProtectedScoreNetwork}
                      ></InventoryScoreGaugeChart>
                    </FadeInWrapper>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InventorySectionForCst;
