import React from "react";

class LayerSizesView extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { layerSizes } = this.props;
    return (
      <div>
        {layerSizes.map((layer, i) => {
          if (typeof layer === "string") {
            return (
              <div key={i}>
                <span className="bg-red-100 px-1">{layer}</span>
              </div>
            );
          }

          return (
            <div key={i}>
              {layer.map((adSizes, j) => {
                return (
                  <span className="bg-red-100 px-1" key={j}>
                    {typeof adSizes === "string" || typeof adSizes === "number"
                      ? adSizes
                      : adSizes.join("x")}
                  </span>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }
}

export default LayerSizesView;
