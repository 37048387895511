import React from "react";
import NumberFormat from "../../common/NumberFormat";
// import Gauge from "react-svg-gauge";
import ProgressBar from "./ProgressBar";

class InventoryScoreGaugeChart extends React.PureComponent {
  render() {
    const progress = this.props.value / 100;
    // const barColor = "#FFEA82"
    //const trailColor =  "#eee"
    const trailColor = "#cbd5e0";

    const barColor = "#56ecf7";

    const options = {
      strokeWidth: 6,
      color: barColor,
      trailColor,
      trailWidth: 2,
      easing: "easeInOut",
      duration: 1400,
      // text: {
      //   value: progress * 100 + "%",
      //   alignToBottom: false,
      //   autoStyleContainer: true,
      //   style: {
      //     color: "#000",
      //     position: "absolute",
      //     left: "50%",
      //     // top: "50%",
      //     // // bottom: "10%",
      //     // padding: 0,
      //     // margin: 100,
      //     fontSize: "22px",
      //     transform: {
      //       value: "translate(-5%, -5%)",
      //     },
      //   },
      // },
      from: { color: barColor },
      to: { color: "#ED6A5A" },
    };

    return (
      <div style={{ width: "90px", marginTop: "6px" }}>
        <ProgressBar.SemiCircle
          progress={progress <= 1 ? progress : 1}
          // text={progress}
          // options={{ strokeWidth: 4 }}
          options={options}
          initialAnimate={true}
        />
        <div className="text-gray-800 text-xl font-medium text-center -mt-6 -mb-2">
          <NumberFormat value={this.props.value} format="0,0"></NumberFormat>
          <span className="font-normal">%</span>
        </div>
      </div>
    );
  }
}

export default InventoryScoreGaugeChart;
