import React from "react";
import _ from "lodash";
import RecipeBox from "./recipe/RecipeBox";
import { VseAPI } from "apis";
import PopupWrapper from "../common/PopupWrapper";

class ChecksView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,

      checks: null,
    };
  }

  async componentDidMount() {
    document.title = `Checks | VSE`;

    this.setState({ isLoading: true });
    try {
      const { checks } = await VseAPI.getAllChecks();
      const recipesById = _.keyBy(this.props.recipes, "id");
      const checksWithRecipe = _.map(checks, (c) => {
        if (c.associated_recipes) {
          c.associated_recipes = _.map(c.associated_recipes, (ar) => {
            ar.recipe = recipesById[ar.id];
            return ar;
          });
        }
        return c;
      });
      this.setState({ checks: checksWithRecipe, errMsg: null });
    } catch (err) {
      this.setState({ errMsg: "Error loading checks" });
    }

    this.setState({ isLoading: false });
  }

  render() {
    const { isLoading, errMsg, checks } = this.state;

    return (
      <>
        <div>
          <table
            className="w-full table text-sm border shadow"
            style={{ marginBottom: "500px" }}
          >
            <thead className="bg-gray-200 text-blue-800 text-xs border-b">
              <tr>
                <th className="border py-1 px-2">Id</th>
                <th className="border py-1 px-2">Name</th>
                <th className="border py-1 px-2">Associated Recipes</th>
              </tr>
            </thead>
            <tbody className="text-gray-900 font-mono bg-white">
              {checks &&
                checks.map((r) => {
                  return (
                    <tr key={r.id} className="border-b hover:bg-gray-100">
                      <td className="font-semibold py-1 px-2 whitespace-no-wrap border font-sans">
                        {r.id}
                      </td>
                      <td className="py-1 px-3 whitespace-no-wrap border text-left">
                        {r.name}
                      </td>

                      <td className="py-1 px-3 whitespace-no-wrap border text-left">
                        {r.associated_recipes.map((ar) => {
                          return (
                            <div key={ar.id} className="mb-1">
                              <AssociatedRecipeBox
                                recipe={ar}
                              ></AssociatedRecipeBox>
                            </div>
                          );
                        })}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

const AssociatedRecipeBox = (props) => {
  const { recipe } = props;
  // id
  // name
  // status
  // recipe

  return (
    <>
      <PopupWrapper
        place="bottom left"
        hideArrow={true}
        triggerElement={
          <div className="px-2 bg-gray-200 rounded border cursor-pointer">
            {recipe.id} - {recipe.name}
          </div>
        }
        popupElement={
          <div
            className="shadow-lg border-4 border-gray-600"
            style={{ marginTop: "-9px" }}
          >
            <RecipeBox item={recipe.recipe}></RecipeBox>
          </div>
        }
      ></PopupWrapper>
    </>
  );
};

export default ChecksView;
