import React from "react";
import _ from "lodash";
import PasswordEyeInput from "../common/PasswordEyeInput";

const titleClass = "block text-gray-800 font-bold mb-1"; //"font-semibold";

const buttonConfirmClass =
  "my-2 text-blue-900 rounded shadow font-semibold bg-blue-200 hover:bg-blue-300 px-4 py-2 text-sm overflow-x-auto";

class UserMasterPasswordForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      masterPassword: "",
    };
    this.handleMPChanged = this.handleMPChanged.bind(this);
  }

  handleMPChanged(e) {
    this.setState({ masterPassword: e.target.value });
  }

  render() {
    const { handleSubmitUserMP, mpErrorMsg, isLoading } = this.props;
    const { masterPassword } = this.state;

    return (
      <div className="w-1/2 p-4">
        <div className={titleClass}>User Password:</div>
        <form
          autoComplete="false"
          onSubmit={(e) => {
            e.preventDefault(); // to prevent page refresh
            handleSubmitUserMP(masterPassword);
          }}
        >
          <PasswordEyeInput
            value={masterPassword}
            onChange={this.handleMPChanged}
            autoFocus={true}
          ></PasswordEyeInput>

          <button
            type="submit"
            className={buttonConfirmClass}
            disabled={isLoading}
          >
            {isLoading ? "Submitting" : "Submit"}
          </button>
          <div className="text-red-700 font-semibold">{mpErrorMsg}</div>
        </form>
      </div>
    );
  }
}

export default UserMasterPasswordForm;
