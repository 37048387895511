import _, { result } from "lodash";
import moment from "moment-timezone";

const PRESETS = {
  NETWORK_BILLABLE: "Network Billable", // default
  GOOGLE_ELIGIBLE: "Google Eligible",
  CUSTOM: "Custom",
};

const DEMAND_TYPES = {
  AD_EXCHANGE: "Ad Exchange",
  AD_SENSE: "AdSense",
  EXCHANGE_BIDDING: "Exchange Bidding",
  PRICE_PRIORITY: "Price Priority",
  NETWORK: "Network",
  BULK: "Bulk",
  HEADER_BIDDING: "Header Bidding",
  BILLABLE_HOUSE: "Billable House",
};

export function getGoogleEligibleDemandTypes() {
  return [
    DEMAND_TYPES.AD_EXCHANGE,
    DEMAND_TYPES.AD_SENSE,
    DEMAND_TYPES.EXCHANGE_BIDDING,
    // DEMAND_TYPES.PRICE_PRIORITY,
  ];
}

export function transformObserverData(
  resultData,
  { demandTypePreset, demandTypes }
) {
  const reportCreateTime = _.get(resultData, "updatedAt", new Date());

  resultData.reportCreateTime = moment(reportCreateTime)
    .tz("Asia/Taipei")
    .format("YYYY/MM/DD HH:mm");
  resultData.reportCreateTimeAgo = moment(reportCreateTime)
    .tz("Asia/Taipei")
    .fromNow();

  // Be careful of data with no segments...
  let hasExpWithNoSegment = false;

  resultData.expInfo = _.reduce(
    resultData.expInfo,
    (result, exp, key) => {
      exp.segmentId = exp.segmentId ? exp.segmentId : -1;
      result[key] = exp;
      return result;
    },
    {}
  );

  _.forIn(resultData.groupParamsMap, (groupIds, gpId) => {
    for (let i = 1; i < groupIds.length; ++i) {
      const lGroupId = groupIds[i - 1];
      const rGroupId = groupIds[i];
      resultData.groupInfo[rGroupId].prevGroupId = lGroupId;
      resultData.groupInfo[lGroupId].nextGroupId = rGroupId;
    }
  });

  const billableDemandTypes = _.get(
    resultData,
    "networkSetting.billableDemandTypes"
  );

  resultData.yieldLifts = _.map(resultData.yieldLifts, (data) => {
    data.startDateString = moment(data.startDate)
      .tz("Asia/Taipei")
      .format("YYYY/MM/DD HH:mm");
    data.startDateAgo = moment(data.startDate).tz("Asia/Taipei").fromNow();

    // once one experiment has no segment, we need to add -1 to segmentInfo
    if (!hasExpWithNoSegment) {
      hasExpWithNoSegment = data.segmentId ? false : true;
    }

    data.segmentId = data.segmentId ? data.segmentId : -1;

    // caculate rev and imp based network billable demand types
    // vs rev and imp based off of google eligible demand types
    data.reports = _.map(data.reports, (report) => {
      // Precalculate: Google Eligible
      const { gEligibleImp, gEligibleRev } =
        _calculateGoogleEligibleReport(report);
      report.gEligibleImp = gEligibleImp;
      report.gEligibleRev = gEligibleRev;

      // Precalculate: Network Billable
      const { networkBillableImp, networkBillableRev } =
        _calculateNetworkBillableReport(report, billableDemandTypes);
      report.networkBillableImp = networkBillableImp;
      report.networkBillableRev = networkBillableRev;

      report.reports = _.map(report.reports, (r) => {
        const { gEligibleImp, gEligibleRev } =
          _calculateGoogleEligibleReport(r);
        r.gEligibleImp = gEligibleImp;
        r.gEligibleRev = gEligibleRev;

        const { networkBillableImp, networkBillableRev } =
          _calculateNetworkBillableReport(r, billableDemandTypes);
        r.networkBillableImp = networkBillableImp;
        r.networkBillableRev = networkBillableRev;
        return r;
      });

      return report;
    });

    return data;
  });

  if (hasExpWithNoSegment) {
    resultData.segmentInfo.push({ id: -1, targeting: {} });
  }

  // calculate reports by demand types and benchmark metrics
  resultData = _calculateExpExtraMetrics(resultData, {
    demandTypePreset,
    demandTypes,
  });

  return resultData;
}

function _calculateGoogleEligibleReport(report) {
  // gEligibleImp =
  //   report.adxImpression +
  //   report.adsImpression +
  //   report.ebImpression +
  //   report.sbImpression;
  // gEligibleRev =
  //   report.adxRevenue +
  //   report.adsRevenue +
  //   report.ebRevenue +
  //   report.sbRevenue;
  let gEligibleImp = report.sbImpression; // smart bidding is included
  let gEligibleRev = report.sbRevenue;

  _.forEach(getGoogleEligibleDemandTypes(), (type) => {
    if (type === DEMAND_TYPES.AD_EXCHANGE) {
      gEligibleImp += report.adxImpression;
      gEligibleRev += report.adxRevenue;
    }

    if (type === DEMAND_TYPES.AD_SENSE) {
      gEligibleImp += report.adsImpression;
      gEligibleRev += report.adsRevenue;
    }

    if (type === DEMAND_TYPES.EXCHANGE_BIDDING) {
      gEligibleImp += report.ebImpression;
      gEligibleRev += report.ebRevenue;
    }

    if (type === DEMAND_TYPES.HEADER_BIDDING) {
      gEligibleImp += report.hbImpression;
      gEligibleRev += report.hbRevenue;
    }

    if (type === DEMAND_TYPES.PRICE_PRIORITY) {
      gEligibleImp += report.ppImpression;
      gEligibleRev += report.ppRevenue;
    }

    if (type === DEMAND_TYPES.NETWORK) {
      gEligibleImp += report.nwImpression;
      gEligibleRev += report.nwRevenue;
    }

    if (type === DEMAND_TYPES.BULK) {
      gEligibleImp += report.bulkImpression;
      gEligibleRev += report.bulkRevenue;
    }

    if (type === DEMAND_TYPES.BILLABLE_HOUSE) {
      gEligibleImp += report.bhImpression;
      gEligibleRev += report.bhRevenue;
    }
  });

  return { gEligibleImp, gEligibleRev };
}

function _calculateNetworkBillableReport(report, billableDemandTypes) {
  function _isBillableDemandType(type) {
    return _.indexOf(billableDemandTypes, type) !== -1;
  }

  let networkBillableImp = 0;
  let networkBillableRev = 0;

  _.forEach(_.values(DEMAND_TYPES), (type) => {
    if (type === DEMAND_TYPES.AD_EXCHANGE) {
      if (_isBillableDemandType(type)) {
        networkBillableImp += report.adxImpression;
        networkBillableRev += report.adxRevenue;
      }
    }

    if (type === DEMAND_TYPES.AD_SENSE) {
      if (_isBillableDemandType(type)) {
        networkBillableImp += report.adsImpression;
        networkBillableRev += report.adsRevenue;
      }
    }

    if (type === DEMAND_TYPES.EXCHANGE_BIDDING) {
      if (_isBillableDemandType(type)) {
        networkBillableImp += report.ebImpression;
        networkBillableRev += report.ebRevenue;
      }
    }

    if (type === DEMAND_TYPES.HEADER_BIDDING) {
      if (_isBillableDemandType(type)) {
        networkBillableImp += report.hbImpression;
        networkBillableRev += report.hbRevenue;
      }
    }

    if (type === DEMAND_TYPES.PRICE_PRIORITY) {
      if (_isBillableDemandType(type)) {
        networkBillableImp += report.ppImpression;
        networkBillableRev += report.ppRevenue;
      }
    }

    if (type === DEMAND_TYPES.NETWORK) {
      if (_isBillableDemandType(type)) {
        networkBillableImp += report.nwImpression;
        networkBillableRev += report.nwRevenue;
      }
    }

    if (type === DEMAND_TYPES.BULK) {
      if (_isBillableDemandType(type)) {
        networkBillableImp += report.bulkImpression;
        networkBillableRev += report.bulkRevenue;
      }
    }

    if (type === DEMAND_TYPES.BILLABLE_HOUSE) {
      if (_isBillableDemandType(type)) {
        networkBillableImp += report.bhImpression;
        networkBillableRev += report.bhRevenue;
      }
    }
  });

  return { networkBillableImp, networkBillableRev };
}

function _calculateLifts(reports) {
  // Calculate Lift
  let grossLift = 0;
  let netLift = 0;
  const benchReports = _.filter(reports, { type: "B" });
  if (benchReports.length > 0) {
    // const totalRev = _.sumBy(reports, "revenue"); // old google eligible
    const totalRev = _.sumBy(reports, "targetRevenue");
    const totalReq = _.sumBy(reports, "firstLayerRequest");
    const totalCost = _.sumBy(reports, "cost");

    // const benchRev = _.sumBy(benchReports, "revenue"); // old google eligible
    const benchRev = _.sumBy(benchReports, "targetRevenue");
    const benchReq = _.sumBy(benchReports, "firstLayerRequest");
    const benchRpm = benchReq > 0 ? (benchRev * 1000) / benchReq : 0;
    const originalRev = benchRpm * (totalReq / 1000);

    grossLift =
      originalRev > 0
        ? _.round(((totalRev - originalRev) / originalRev) * 100, 2)
        : "∞";
    netLift =
      originalRev > 0
        ? _.round(((totalRev - originalRev - totalCost) / originalRev) * 100, 2)
        : "∞";
  }

  return { grossLift, netLift };
}

// targetRevenue, targetImpression
function _calculateReportsByDemandTypes(
  reports,
  { demandTypePreset, demandTypes }
) {
  return _.map(reports, (report) => {
    // exp reports -> exp group reports -> layers reports
    if (report.reports) {
      report.reports = _calculateReportsByDemandTypes(report.reports, {
        demandTypePreset,
        demandTypes,
      });
    }

    if (demandTypePreset === PRESETS.NETWORK_BILLABLE) {
      report.targetRevenue = report.networkBillableRev;
      report.targetImpression = report.networkBillableImp;
    }

    if (demandTypePreset === PRESETS.GOOGLE_ELIGIBLE) {
      report.targetRevenue = report.gEligibleRev;
      report.targetImpression = report.gEligibleImp;
    }

    if (demandTypePreset === PRESETS.CUSTOM) {
      let targetImpression = 0;
      let targetRevenue = 0;
      if (_.indexOf(demandTypes, DEMAND_TYPES.AD_EXCHANGE) !== -1) {
        targetImpression += report.adxImpression;
        targetRevenue += report.adxRevenue;
      }
      if (_.indexOf(demandTypes, DEMAND_TYPES.AD_SENSE) !== -1) {
        targetImpression += report.adsImpression;
        targetRevenue += report.adsRevenue;
      }
      if (_.indexOf(demandTypes, DEMAND_TYPES.EXCHANGE_BIDDING) !== -1) {
        targetImpression += report.ebImpression;
        targetRevenue += report.ebRevenue;
      }
      if (_.indexOf(demandTypes, DEMAND_TYPES.HEADER_BIDDING) !== -1) {
        targetImpression += report.hbImpression;
        targetRevenue += report.hbRevenue;
      }
      if (_.indexOf(demandTypes, DEMAND_TYPES.PRICE_PRIORITY) !== -1) {
        targetImpression += report.ppImpression;
        targetRevenue += report.ppRevenue;
      }
      if (_.indexOf(demandTypes, DEMAND_TYPES.NETWORK) !== -1) {
        targetImpression += report.nwImpression;
        targetRevenue += report.nwRevenue;
      }
      if (_.indexOf(demandTypes, DEMAND_TYPES.BULK) !== -1) {
        targetImpression += report.bulkImpression;
        targetRevenue += report.bulkRevenue;
      }
      if (_.indexOf(demandTypes, DEMAND_TYPES.BILLABLE_HOUSE) !== -1) {
        targetImpression += report.bhImpression;
        targetRevenue += report.bhRevenue;
      }
      report.targetRevenue = targetRevenue;
      report.targetImpression = targetImpression;
    }

    report.ecpm =
      report.targetImpression > 0
        ? (report.targetRevenue * 1000) / report.targetImpression
        : 0;
    report.managedScore =
      report.totalImpression - report.smartImpression > 0
        ? report.targetImpression /
          (report.totalImpression - report.smartImpression)
        : 0;

    if (_.has(report, "firstLayerRequest")) {
      report.rpm =
        report.firstLayerRequest > 0
          ? ((report.targetRevenue - report.cost) * 1000) /
            report.firstLayerRequest
          : 0;
      report.sellThroughRate =
        report.firstLayerRequest > 0
          ? report.targetImpression / report.firstLayerRequest
          : 0;
    } else {
      report.rpm =
        report.request > 0
          ? ((report.targetRevenue - report.cost) * 1000) / report.request
          : 0;
      report.sellThroughRate =
        report.request > 0 ? report.targetImpression / report.request : 0;
    }

    // console.log(report);
    return report;
  });
}

export function recalculateData(resultData, { demandTypePreset, demandTypes }) {
  return _calculateExpExtraMetrics(resultData, {
    demandTypePreset,
    demandTypes,
  });
}

function _calculateExpExtraMetrics(
  resultData,
  { demandTypePreset, demandTypes }
) {
  resultData.yieldLifts = _.map(resultData.yieldLifts, (data) => {
    // targetRevenue, targetImpression
    data.reports = _calculateReportsByDemandTypes(data.reports, {
      demandTypePreset,
      demandTypes,
    });

    const { grossLift, netLift } = _calculateLifts(data.reports);
    data.grossLift = grossLift;
    data.netLift = netLift;

    let benchSumRev = 0;
    let benchSumReq = 0;
    let benchSumCost = 0;
    let benchSumImp = 0;
    let benchCounter = 0;
    // the first report will be the real benchmark
    const realBenchReport = _.first(data.reports);
    _.forEach(data.reports, (r) => {
      if (r.type === "B") {
        benchCounter++;
        // benchmark reports
        benchSumRev += r.targetRevenue;
        benchSumReq += _.get(r, "firstLayerRequest", r.request || 0);
        benchSumCost += r.cost;
        benchSumImp += r.targetImpression;
      }
    });
    data.hasMultiBench = false;
    if (benchCounter > 1) {
      data.hasMultiBench = true;
      data.benchSumRev = benchSumRev - benchSumCost;
      data.benchSumReq = benchSumReq;
      data.benchSumCost = benchSumCost;
      data.benchSumImp = benchSumImp;
      data.benchNetRPM = _.round(benchSumRev / (benchSumReq / 1000), 2);
      data.benchECPM = _.round((benchSumRev * 1000) / benchSumImp, 2);

      // calculate expected benchmark performance
      // benchmark multiplier
      data.bm = _.get(resultData.expInfo, [
        data.expId,
        "parameters",
        "auxiliary",
        "bm_multiplier",
      ]);
      data.benchExpNetRPM = _.round(realBenchReport.rpm * data.bm, 2);
      data.benchExpDiff = _.round(data.benchNetRPM - data.benchExpNetRPM, 2);
    }

    data.reports = _.map(data.reports, (r) => {
      if (r.type !== "B") {
        r.diffsWithBench = [
          // real benchmark
          {
            diff: _.round(r.rpm - realBenchReport.rpm, 2),
            bench: realBenchReport.rpm,
            name: "Real B: ",
          },
        ];
        if (data.hasMultiBench) {
          // final benchmark
          r.diffsWithBench.push({
            diff: _.round(r.rpm - data.benchNetRPM, 2),
            bench: data.benchNetRPM,
            name: "Final B: ",
          });
        }
      }
      return r;
    });

    return data;
  });

  return resultData;
}
