export const GAM_BROWSER = {
  500105: "Android Browser (x.x)",
  500084: "Firefox (x.x)",
  500072: "Google Chrome (x.x)",
  500153: "Microsoft Edge (x.x)",
  500078: "Microsoft Internet Explorer (x.x)",
  500085: "Mozilla (x.x)",
  500086: "Netscape Navigator (x.x)",
  500093: "Opera (x.x)",
  500103: "Safari (iPad) (x.x)",
  500104: "Safari (iPhone/iPod) (x.x)",
  500102: "Safari (x.x)",
};
