import React from "react";
// import _ from "lodash";
import moment from "moment-timezone";
import NumberFormat from "../common/NumberFormat";

const DATE_FORMAT = "YYYY-MM-DD";
const DATE_RANGE_TYPE = {
  THIS_MONTH: "THIS_MONTH", // default
  LAST_MONTH: "LAST_MONTH",
  PAST_3_MONTHS: "PAST_3_MONTHS",
};

class ChartHeaderWithSelectors extends React.PureComponent {
  render() {
    const {
      todayData,
      yesterdayData,
      dateRangeType,
      handleDateRangeTypeChanged,
      startDate,
      endDate,
      totalData,
    } = this.props;

    const currentDate = endDate ? endDate : moment().format(DATE_FORMAT);

    return (
      <>
        <div className="flex justify-between">
          <div className="flex">
            {/* BRIEF */}
            <div className="pl-8 pr-12">
              <div className="uppercase text-gray-600 font-semibold text-xs tracking-wide">
                Today
              </div>
              <div className="text-2xl font-semibold text-gray-700">
                {todayData && todayData.goalReachedPercentage ? (
                  <>
                    {todayData.goalReachedPercentage}{" "}
                    <span className="font-normal text-gray-800 text-base">
                      %
                    </span>
                  </>
                ) : (
                  "-"
                )}
              </div>
              <div className="text-sm text-gray-700">
                {todayData && (
                  <NumberFormat
                    value={todayData.netIncreasedRev}
                    format="$0,0"
                  ></NumberFormat>
                )}
                <span className="px-2">/</span>
                {todayData && (
                  <NumberFormat
                    value={todayData.dailyGoal}
                    format="$0,0"
                  ></NumberFormat>
                )}
              </div>
            </div>

            <div className="pr-6 border-r mr-6">
              <div className="uppercase text-blue-600 font-semibold text-xs tracking-wide">
                Yesterday
              </div>
              <div className="text-2xl font-semibold text-blue-900">
                {yesterdayData && yesterdayData.goalReachedPercentage ? (
                  <>
                    {yesterdayData.goalReachedPercentage}{" "}
                    <span className="font-normal text-gray-800 text-base">
                      %
                    </span>
                  </>
                ) : (
                  "-"
                )}
              </div>
              <div className="text-sm text-gray-700">
                <NumberFormat
                  value={yesterdayData.netIncreasedRev}
                  format="$0,0"
                ></NumberFormat>
                <span className="px-2">/</span>
                <NumberFormat
                  value={yesterdayData.dailyGoal}
                  format="$0,0"
                ></NumberFormat>
              </div>
            </div>

            <div>
              <div className="uppercase text-blue-600 font-semibold text-xs tracking-wide">
                Total
              </div>
              <div className="text-2xl font-semibold text-blue-900">
                {totalData && totalData.totalGoalReached ? (
                  <>
                    {totalData.totalGoalReached}{" "}
                    <span className="font-normal text-gray-800 text-base">
                      %
                    </span>
                  </>
                ) : (
                  "-"
                )}
              </div>
              <div className="text-sm text-gray-700">
                <NumberFormat
                  value={totalData.totalNetIncreasedRev}
                  format="$0,0"
                ></NumberFormat>
                <span className="px-2">/</span>
                <NumberFormat
                  value={totalData.totalDailyGoal}
                  format="$0,0"
                ></NumberFormat>
              </div>
            </div>
          </div>

          {/* DATE RANGE */}
          <div className="flex">
            <div className="pl-8 pr-12">
              <div className="uppercase text-gray-600 font-semibold text-xs tracking-wide">
                Date Range
              </div>
              <div className="">
                {/* SELECTOR */}
                <DateRangeSelect
                  dateRangeType={dateRangeType}
                  handleDateRangeTypeChanged={handleDateRangeTypeChanged}
                ></DateRangeSelect>

                {/* RANGE UI */}
                <div className="font-semibold text-gray-800 text-sm">
                  {startDate}{" "}
                  <span className="text-gray-600 font-normal">to</span>{" "}
                  {currentDate}
                </div>
              </div>
            </div>

            <div>
              {/* TODO */}
              {/* <div className="uppercase text-gray-600 font-semibold text-xs tracking-wide">
                Granularity
              </div>
              <div className="">SELECTOR</div> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

class DateRangeSelect extends React.PureComponent {
  constructor(props) {
    super(props);

    const options = [
      {
        name: "This month",
        value: DATE_RANGE_TYPE.THIS_MONTH,
      },
      {
        name: "Last month",
        value: DATE_RANGE_TYPE.LAST_MONTH,
      },
      {
        name: "Past 3 months",
        value: DATE_RANGE_TYPE.PAST_3_MONTHS,
      },
    ];

    const { dateRangeType } = props;

    this.state = {
      options,
      value: dateRangeType,
    };

    this.handleChanged = this.handleChanged.bind(this);
  }

  async handleChanged(event) {
    const dateRangeType = event.target.value;
    this.setState({
      value: dateRangeType,
    });
    await this.props.handleDateRangeTypeChanged(dateRangeType);
  }

  render() {
    const { value, options } = this.state;

    return (
      <>
        {options && (
          <div className="inline-block relative w-48">
            <select
              value={value}
              onChange={this.handleChanged}
              className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
            >
              {options.map((option) => {
                return (
                  <option key={option.value} value={option.value}>
                    {option.name}
                  </option>
                );
              })}
            </select>

            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default ChartHeaderWithSelectors;
