import React from "react";
import _ from "lodash";

import Checkbox from "components/common/Checkbox";

const titleClass = "block text-gray-800 text-sm font-bold"; //"font-semibold";

class RegisteredInventoryConfig extends React.Component {
  constructor(props) {
    super(props);

    const config = this.props.riConfig;

    this.state = {
      config,
    };

    this.handleChanged = this.handleChanged.bind(this);
  }

  handleChanged({ key, value }) {
    const { schema, integration, riConfig } = this.props;
    // console.log("handle changed", key, value, riConfig);

    let newConfig = riConfig;

    newConfig[key] = value;

    this.setState({ config: newConfig });

    // riConfig
    this.props.handleRIChanged({ key: schema.key, config: newConfig });
  }

  render() {
    const { schema, integration, riConfig, riKey, handleRIChanged } =
      this.props;
    const { config } = this.state;

    const isEnabled = config["enable_optimization"];

    let configInputElement = "";

    if (schema.valueType === "boolean") {
      configInputElement = (
        <div className="flex gap-4">
          <label
            htmlFor={`${integration}_${schema.key}_radio_true`}
            className="cursor-pointer"
          >
            <input
              id={`${integration}_${schema.key}_radio_true`}
              type="radio"
              value="true"
              checked={config[schema.key] === true}
              onChange={() =>
                this.handleChanged({
                  key: schema.key,
                  value: true,
                })
              }
              required
            />{" "}
            True
          </label>

          <label
            htmlFor={`${integration}_${schema.key}_radio_false`}
            className="cursor-pointer"
          >
            <input
              id={`${integration}_${schema.key}_radio_false`}
              type="radio"
              value="false"
              checked={config[schema.key] === false}
              onChange={() =>
                this.handleChanged({
                  key: schema.key,
                  value: false,
                })
              }
              required
            />{" "}
            False
          </label>
        </div>
      );
    } else if (schema.valueType === "integer_enum") {
      configInputElement = (
        <div className="flex gap-4">
          {schema.enum.map((option) => {
            return (
              <label
                key={option}
                htmlFor={`${integration}_${schema.key}_radio_${option}`}
                className="cursor-pointer"
              >
                <input
                  id={`${integration}_${schema.key}_radio_${option}`}
                  type="radio"
                  value="true"
                  checked={config[schema.key] === option}
                  onChange={() =>
                    this.handleChanged({
                      key: schema.key,
                      value: option,
                    })
                  }
                />{" "}
                {option}
              </label>
            );
          })}
        </div>
      );
    } else if (schema.valueType === "array") {
      configInputElement = (
        <div className="flex gap-4">
          {schema.arrayOptions.map((option) => {
            const isChecked = _.indexOf(config[schema.key], option) !== -1;
            return (
              <div key={option}>
                <label className="flex cursor-pointer items-center">
                  <Checkbox
                    isChecked={isChecked}
                    onChange={() => {
                      let value = config[schema.key] || [];
                      if (isChecked) {
                        value = _.without(value, option);
                      } else {
                        value.push(option);
                      }
                      return this.handleChanged({
                        key: schema.key,
                        value,
                      });
                    }}
                  ></Checkbox>
                  <span className="ml-1 align-middle text-sm font-medium text-gray-800 hover:text-gray-700">
                    {option}
                  </span>
                </label>
              </div>
            );
          })}
        </div>
      );
    }

    return (
      <div>
        <div className={titleClass}>{schema.key}</div>
        <div>{configInputElement}</div>
      </div>
    );
  }
}

export default RegisteredInventoryConfig;
