import React from "react";
import { Switch, Route, NavLink } from "react-router-dom";
import _ from "lodash";
import PendingActionsViewer from "./PendingActionsViewer";
import HealthCheckViewer from "./health-check/HealthCheckViewer";
import ExecutionsViewer from "./ExecutionsViewer";

// vse
const VSE_PATH_PREFIX = "/vse/network/:networkId";
const VSE_PATHS = {
  HEALTH_CHECK: `${VSE_PATH_PREFIX}/health-checks`,
  PENDING_ACTION: `${VSE_PATH_PREFIX}/pending-actions`,
  EXECUTION: `${VSE_PATH_PREFIX}/executions`,
};

class VseByNetworkViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,

      data: null,
      networkId: null,
    };
  }

  async componentDidMount() {
    const networkId = _.get(this.props, "match.params.networkId");
    this.setState({ networkId });
    // await this._queryAndUpdateUI();
    // TODO: get networkInfo here
  }

  render() {
    const { networkId } = this.state;
    const { recipes } = this.props;

    const pathPrefix = `/vse/network/${networkId}`;
    const paths = {
      HEALTH_CHECK: {
        path: `${pathPrefix}/health-checks`,
        title: "Health Checks",
      },
      PENDING_ACTION: {
        path: `${pathPrefix}/pending-actions`,
        title: "Pending Actions",
      },
      EXECUTION: { path: `${pathPrefix}/executions`, title: "Executions" },
    };

    const vseRecipes = "/vse/recipes";
    return (
      <div>
        <div className="py-2 px-12 bg-gray-100 border-b">
          <div className="flex items-center justify-between">
            <div className="flex gap-4 items-center">
              <div className="font-semibold text-lg text-gray-800">
                {/* VSE */}

                <NavLink
                  to={vseRecipes}
                  className="text-gray-900 rounded px-2 py-1 hover:bg-gray-300"
                >
                  VSE
                </NavLink>
              </div>

              <div className="flex gap-4">
                {_.keys(paths).map((path) => {
                  return (
                    <NavLink
                      key={paths[path].path}
                      to={paths[path].path}
                      className="text-sm font-semibold text-gray-900 rounded px-2 py-1 border border-gray-100 hover:border-gray-300"
                      activeClassName="bg-gray-300 border border-gray-300"
                    >
                      {paths[path].title}
                    </NavLink>
                  );
                })}
              </div>
            </div>

            <div>
              {/* <NavLink
                to={vseRecipes}
                className="text-sm text-gray-900 rounded border px-2 py-1 hover:bg-gray-300"
                activeClassName="bg-gray-300 border-gray-500 font-semibold"
              >
                Recipes
              </NavLink> */}
            </div>
          </div>
        </div>

        {networkId ? (
          <Switch>
            <Route
              path={VSE_PATHS.HEALTH_CHECK}
              render={(props) => {
                return (
                  <HealthCheckViewer
                    {...props}
                    networkId={networkId}
                  ></HealthCheckViewer>
                );
              }}
            ></Route>

            <Route
              path={VSE_PATHS.PENDING_ACTION}
              render={(props) => {
                return (
                  <PendingActionsViewer
                    {...props}
                    recipes={recipes}
                    networkId={networkId}
                  ></PendingActionsViewer>
                );
              }}
            ></Route>

            <Route
              path={VSE_PATHS.EXECUTION}
              render={(props) => {
                return (
                  <ExecutionsViewer
                    {...props}
                    recipes={recipes}
                    networkId={networkId}
                  ></ExecutionsViewer>
                );
              }}
            ></Route>
          </Switch>
        ) : (
          <div className="bg-white text-red-700">Invalid networkId</div>
        )}
      </div>
    );
  }
}

export default VseByNetworkViewer;
