import React from "react";
import _ from "lodash";
import DateRangeFilter from "./DateRangeFilter";

class AnatomyCstFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    const { selectedDateRange = 3, startDate, endDate } = props;

    this.state = {
      // date range
      selectedDateRange,
      startDate,
      endDate,
    };

    this.isFilterDisabled = this.isFilterDisabled.bind(this);
    this.onDateRangeChange = this.onDateRangeChange.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
  }

  isFilterDisabled() {
    const { startDate, endDate } = this.state;
    if (startDate && endDate) return false;
    return true;
  }

  onDateRangeChange({
    startDate,
    endDate,
    endDateNotIncludeToday,
    dateRangeNum,
  }) {
    this.setState({ startDate, endDate, selectedDateRange: dateRangeNum });
    console.log(startDate, endDate, dateRangeNum);
    this.props.refillStartEndDate({
      startDate,
      endDate,
      endDateNotIncludeToday,
      dateRangeNum,
    });
  }

  handleFilter() {
    this.props.handleFilterClicked();
  }

  render() {
    const { timezone } = this.props;
    const { selectedDateRange, startDate, endDate } = this.state;
    const isFilterDisabled = this.isFilterDisabled();

    return (
      <div className="flex items-center gap-4">
        <div className="-mb-4">
          <DateRangeFilter
            timezone={timezone}
            dateRanges={[3, 7, 14, 21, 30]}
            selectedDateRange={selectedDateRange}
            startDate={startDate}
            endDate={endDate}
            onDateRangeChange={this.onDateRangeChange}
          ></DateRangeFilter>
        </div>

        <button
          type="button"
          onClick={this.handleFilter}
          className={`rounded mt-2 px-6 py-2 font-semibold text-white ${
            isFilterDisabled
              ? "bg-blue-200"
              : "shadow bg-blue-400 hover:bg-blue-500"
          }`}
          disabled={isFilterDisabled}
        >
          Get data
        </button>
      </div>
    );
  }
}

export default AnatomyCstFilter;
