import React from "react";
import _ from "lodash";

const SAMPLE = `[
  {
    "id": 1,
    "name": "TEST_UNITS",
    "units": [
        12345
    ],
    "prices": [
        1,
        2,
        3,
        4,
        5
    ],
    "detector_prices": [
        1,
        2,
        3,
        4,
        5
    ],
    "catchall_prices": [
        1,
        2,
        3,
        4,
        5
    ]
  }
]
`;

class UnitSettingInputTextArea extends React.Component {
  constructor(props) {
    super(props);

    const { isSettingsValid, inputValue } = this.props;
    const hasError = this.checkIsValidJson(inputValue);

    this.state = {
      inputValue: inputValue || "",

      isJsonValid: !hasError,
      isSettingsValid: isSettingsValid,
    };

    this.handleInputChanged = this.handleInputChanged.bind(this);
    this.handleBeautifyJson = this.handleBeautifyJson.bind(this);
    this.handleImportSample = this.handleImportSample.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.inputValue !== this.props.inputValue) {
      const hasError = this.checkIsValidJson(this.props.inputValue);
      this.setState({
        inputValue: this.props.inputValue,
        isJsonValid: !hasError,
        isSettingsValid: this.props.isSettingsValid,
      });
    }

    if (prevProps.isSettingsValid !== this.props.isSettingsValid) {
      this.setState({ isSettingsValid: this.props.isSettingsValid });
    }
  }

  handleInputChanged(inputValue) {
    const hasError = this.checkIsValidJson(inputValue);

    this.setState({
      inputValue,

      isJsonValid: !hasError,
      isSettingsValid: false,
    });

    this.props.handleInputChanged(inputValue);
  }

  handleBeautifyJson() {
    const { inputValue } = this.state;

    const hasError = this.checkIsValidJson(inputValue);
    let errMsg = null;
    if (hasError) {
      errMsg = "Not a valid JSON";
    } else {
      const json = JSON.parse(inputValue);

      this.setState({
        inputValue: JSON.stringify(json, null, 4),
      });
    }

    this.setState({
      errMsg,
    });
  }

  checkIsValidJson(jsonText) {
    let hasError = false;

    try {
      JSON.parse(jsonText);
    } catch (err) {
      hasError = true;
    }

    return hasError;
  }

  handleImportSample() {
    this.handleInputChanged(SAMPLE);
  }

  render() {
    const { rows, handleValidateAndPreview, errors, isSettingsValidated } =
      this.props;
    const { inputValue, isJsonValid, isSettingsValid } = this.state;

    return (
      <>
        {/* TODO: put example in popover */}
        {/* <div className="bg-gray-100 text-xs text-gray-800">
          <div>"id": integer</div>
          <div>"name": string</div>
          <div>"units": Array of GAM Unit IDs</div>
          <div>"prices": Array of prices</div>
        </div> */}
        {!inputValue && (
          <button
            type="button"
            className="text-sm text-blue-600 hover:text-blue-800"
            onClick={this.handleImportSample}
          >
            Import sample price settings
          </button>
        )}
        <textarea
          rows={rows || 10}
          className="border rounded w-full border-gray-400 p-2"
          value={inputValue}
          onChange={(e) => this.handleInputChanged(e.target.value)}
        ></textarea>
        <div className="flex justify-between gap-2 text-sm">
          <div>
            <button
              type="button"
              onClick={this.handleBeautifyJson}
              className="rounded shadow bg-white px-4 py-1 text-gray-800 hover:bg-gray-100"
            >
              Beautify JSON
            </button>
          </div>
          <button
            type="button"
            onClick={() => {
              if (!isJsonValid) return;
              handleValidateAndPreview();
            }}
            className={`rounded shadow  px-4 py-1 font-medium ${
              isJsonValid
                ? "bg-blue-200 text-blue-800 hover:bg-blue-300"
                : "cursor-not-allowed bg-gray-200 text-gray-800 hover:bg-gray-300"
            }`}
            disabled={!isJsonValid}
          >
            Validate and Preview
          </button>
        </div>

        {isJsonValid ? (
          <div className="mb-1 bg-green-100 px-4 py-2 text-green-600">
            JSON valid
          </div>
        ) : (
          <div className="mb-1 bg-red-100 px-4 py-2 text-red-600">
            Not a valid JSON
          </div>
        )}

        {isSettingsValidated ? (
          <>
            {isSettingsValid ? (
              <div className="mb-1 bg-green-100 px-4 py-2 text-green-600">
                Settings valid
              </div>
            ) : (
              <>
                {errors && (
                  <div className="bg-red-100 px-4 py-2 text-red-600">
                    {errors.length} error{errors.length > 1 && "s"} →
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <div className="mb-1 bg-gray-100 px-4 py-2 text-gray-600">
            Need validation
          </div>
        )}
      </>
    );
  }
}

export default UnitSettingInputTextArea;
