import _ from "lodash";

import { api, fetchRespJson } from "apis/base";

import { transformUnitInsightReports } from "helpers/Unit-Insight";

export default {
  getUnitInsights,
};

async function getUnitInsights(unitId, dateRange = 7, isForceRefresh = false) {
  // const apiUrl = `https://s3-ap-northeast-1.amazonaws.com/yb-observer-results-dev/unit-insight-sample.json`;

  let apiUrl = `${api}/get-unit-insight/${unitId}?lookback=${dateRange}`;
  if (isForceRefresh) {
    apiUrl += "&isForceRefresh=true";
  }
  const resultData = await fetchRespJson(apiUrl);

  return transformUnitInsightReports(resultData);
}
