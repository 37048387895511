import _ from "lodash";

import {
  api,
  fetchRespJson,
  postData,
  fetchRespJsonWithoutCredentials,
} from "apis/base";

export default {
  // biz
  getBizOverview,
  getBizByNetwork,
  // platform index report
  getPlatformIndexReports,
  // business revenue reports
  getBusinessRevenueReports,
  getBusinessRevenueByNetworkReports,
  getBusinessNetworkRevenue,
  // business billing reports
  getBusinessBillingReports,
  // network reporting status
  getNetworkReportingStatuses,
  // mock home dashboard
  getHomeDashboardData,
  getHomeDashboardDataTemp,
  // yb console operation logs
  getConsoleOperationLogs,
};

async function getConsoleOperationLogs({ pubIds, st, et, limit = 50, cursor }) {
  try {
    let apiUrl = `${api}/operation-logs`;

    let queryParams = [];
    if (limit) {
      queryParams.push("limit=" + limit);
    }
    if (cursor) {
      queryParams.push("cursor=" + encodeURIComponent(cursor));
    }

    if (pubIds) {
      queryParams.push("pubIds=" + pubIds);
    }

    if (st) {
      queryParams.push("st=" + encodeURIComponent(st));
    }

    if (et) {
      queryParams.push("et=" + encodeURIComponent(et));
    }

    if (queryParams.length > 0) {
      apiUrl = apiUrl + "?" + queryParams.join("&");
    }

    return await fetchRespJson(apiUrl);
  } catch (err) {
    console.log(err);
    throw err;
  }
}

async function getHomeDashboardData(pubId) {
  try {
    const apiUrl = `${api}/get-home-dashboard-url?pubId=${pubId}`;
    const { url } = await fetchRespJson(apiUrl);
    const data = await fetchRespJsonWithoutCredentials(url);
    return data;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

async function getHomeDashboardDataTemp() {
  try {
    const apiUrl = `${api}/get-home-dashboard-temp`;
    const { url } = await fetchRespJson(apiUrl);

    const data = await fetchRespJsonWithoutCredentials(url);
    return data;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

async function getNetworkReportingStatuses() {
  const apiUrl = `${api}/healthcheck/network-report-status`;
  return await fetchRespJson(apiUrl);
}

async function getBizByNetwork({ startDate, endDate, networkId }) {
  if (!startDate || !networkId) {
    throw new Error("startDate and networkId are required");
  }

  const dateRangeQueryStr = `?startDate=${startDate}${
    endDate ? `&endDate=${endDate}` : ""
  }`;
  const apiUrl = `${api}/biz/network/${networkId}${dateRangeQueryStr}`;
  return await fetchRespJson(apiUrl);
}

async function getBusinessNetworkRevenue() {
  // return revSample;
  const apiUrl = `${api}/business/network-revenue`;
  return await fetchRespJson(apiUrl);
}

async function getBusinessBillingReports() {
  const apiUrl = `${api}/business/billing`;
  return await fetchRespJson(apiUrl);
}

async function getPlatformIndexReports(params) {
  const { startDate, endDate, networkIds, countryCodes } = params;

  const apiUrl = `${api}/business/platform-index`;
  let postParams = { startDate, endDate, networkIds, countryCodes };
  return await postData(apiUrl, postParams);
}

async function getBusinessRevenueReports({ numOfMonths }) {
  // return revOverviewSample;
  const queryStr = `?numOfMonths=${numOfMonths}`;
  const apiUrl = `${api}/business/revenue/v2${queryStr}`;

  const { link } = await fetchRespJson(apiUrl);
  const data = await fetchRespJsonWithoutCredentials(link);
  return data;
}

async function getBusinessRevenueByNetworkReports({ numOfMonths = 3 }) {
  // return revOverviewSample;
  const queryStr = `?numOfMonths=${numOfMonths}`;
  const apiUrl = `${api}/business/revenue-by-network${queryStr}`;

  const { link } = await fetchRespJson(apiUrl);
  const data = await fetchRespJsonWithoutCredentials(link);
  return data;
}

async function getBizOverview({ startDate, endDate }) {
  if (!startDate) {
    throw new Error("startDate is required");
  }

  const dateRangeQueryStr = `?startDate=${startDate}${
    endDate ? `&endDate=${endDate}` : ""
  }`;
  const apiUrl = `${api}/biz/overview/v2${dateRangeQueryStr}`;
  const { link } = await fetchRespJson(apiUrl);
  const data = await fetchRespJsonWithoutCredentials(link);
  return data;
}
