import React from "react";
import _ from "lodash";
import YbMockupHomeAlgorithmSection from "./AlgorithmSection"; // nothing changed for cst
import InventorySectionForCst from "./InventorySectionForCst";
import SummarySectionForCst from "./SummarySectionForCst";
import TrendSectionForCst from "./TrendSectionForCst";

class HomeDashboardBodyForCst extends React.Component {
  render() {
    const {
      updatedTime,
      selectedPubId,
      selectedNetworkId,
      selectedYieldSetIds,
      inventoryData,
      summaryData,
      algoData = {},
      startDate,
      endDate,
      distributionData,
      trendData,
      selectedCurrency,
    } = this.props;

    return (
      <>
        <div>
          <InventorySectionForCst
            updatedTime={updatedTime}
            inventoryData={inventoryData}
            selectedYieldSetIds={selectedYieldSetIds}
          ></InventorySectionForCst>
        </div>

        <div className="mt-8 mb-12">
          <YbMockupHomeAlgorithmSection
            inventoryData={inventoryData}
            selectedYieldSetIds={selectedYieldSetIds}
          ></YbMockupHomeAlgorithmSection>
        </div>

        <div className="my-8">
          <SummarySectionForCst
            selectedPubId={selectedPubId}
            selectedNetworkId={selectedNetworkId}
            selectedYieldSetIds={selectedYieldSetIds}
            startDate={startDate}
            endDate={endDate}
            summaryData={summaryData}
            distributionData={distributionData}
            selectedCurrency={selectedCurrency}
          ></SummarySectionForCst>
        </div>
        <div>
          <TrendSectionForCst
            selectedPubId={selectedPubId}
            selectedNetworkId={selectedNetworkId}
            selectedYieldSetIds={selectedYieldSetIds}
            startDate={startDate}
            endDate={endDate}
            reports={trendData}
            summaryData={summaryData}
            selectedCurrency={selectedCurrency}
          ></TrendSectionForCst>
        </div>
      </>
    );
  }
}

export default HomeDashboardBodyForCst;
