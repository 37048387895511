export const snapshotSortKeyMap = {
  NET_INCREASED_REVENUE: "g_inc_rev_net_twd",
  NET_HOLISTIC_INCREASED_REVENUE: "h_inc_rev_net_p_twd",
  TOTAL_HOLISTIC_REVENUE: "h_rev_wyb_t_twd",
};

export const snapshotSortKeyName = {
  NET_INCREASED_REVENUE: "Net Google Increased Revenue",
  NET_HOLISTIC_INCREASED_REVENUE: "Net Programmatic Holistic Increased Revenue",
  TOTAL_HOLISTIC_REVENUE: "Total Holistic Revenue",
};

export const snapshotSortByDayKeyMap = {
  TODAY: "TODAY",
  D_1: "D_1",
  PAST_3_DAYS_AVG: "PAST_3_DAYS_AVG",
  PAST_7_DAYS_AVG: "PAST_7_DAYS_AVG",
};
