import React from "react";
import _ from "lodash";
import { notify } from "react-notify-toast";
import ModalWrapper from "../common/ModalWrapper";
import { AutoPilotAPI } from "apis";
import AutoPilotViewHeader from "./auto-pilot/AutoPilotViewHeader";
import LoadingUI from "../common/LoadingUI";
import DateTimeFormatter from "../common/DateTimeFormatter";
import {
  createNewCustomHint,
  getAllHints,
  getHintTypeFromUserHint,
  resolveHintDefaultConfig,
} from "./constants/AutoPilotConfigHints";
import UnitAPConfigViewer from "./UnitAPConfigViewer";
import UnitHintAPConfigViewer from "./UnitHintAPConfigViewer";
import TargetingPopover from "./auto-pilot/config/TargetingPopover";

const PAGE_ID = {
  OVERVIEW: "OVERVIEW",
  BASE_CONFIG: "BASE_CONFIG",
  HINT_CONFIG: "HINT_CONFIG",
};

const basicInputClass =
  "bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded py-1 px-4 block w-full appearance-none leading-normal";

class AutoPilotViewerForUnitWithHints extends React.Component {
  constructor(props) {
    super(props);

    const unitId = _.get(props, "match.params.unitId");

    this.state = {
      currentPage: PAGE_ID.OVERVIEW,

      unitId,

      isLoading: false,
      errMsg: null,

      config: null,
      defaultConfig: null,
      updatedAt: null,
      isCustomSeg: false,

      userHints: [], // from query
      allHints: [], // predefined hints + custom hints from custom_segmentation
      currentHint: null, // the one currently editing
      currentHintId: null, // to trigger child element rerender

      unitInfo: null,
      networkInfo: null,

      isSaving: false,
      saveErrMsg: null,
    };

    this.onAddHint = this.onAddHint.bind(this);
    this.onEditBaseConfig = this.onEditBaseConfig.bind(this);
    this.onBackToOverview = this.onBackToOverview.bind(this);
    this.onEditHintConfig = this.onEditHintConfig.bind(this);
    this.onRemoveHint = this.onRemoveHint.bind(this);
  }

  async componentDidMount() {
    const { unitId } = this.state;

    this.setState({ isLoading: true });

    try {
      if (unitId) {
        document.title = `${unitId} Unit Auto Pilot Config | YB Control Center`;
        let {
          config,
          defaultConfig,
          userHints = [],
          unitInfo,
          networkInfo,
          updatedAt,
        } = await AutoPilotAPI.getAutoPilotConfig({
          unitId,
        });

        // SPECIAL CASE: cst_x_setting
        if (_.has(config, ["prophet", "cst_x_setting"])) {
          // create nested key -> cst_x_setting.xxx
          const objectKeys = _.keys(config.prophet.cst_x_setting);
          const cloneCstXSetting = _.cloneDeep(config.prophet.cst_x_setting);
          config.prophet = _.omit(config.prophet, "cst_x_setting");
          _.forEach(objectKeys, (key) => {
            const nestedKey = `cst_x_setting.${key}`;
            const nestedValue = _.get(cloneCstXSetting, key);
            config.prophet[nestedKey] = nestedValue;
          });
        }
        if (_.has(defaultConfig, ["prophet", "cst_x_setting"])) {
          // create nested key -> cst_x_setting.xxx
          const objectKeys = _.keys(defaultConfig.prophet.cst_x_setting);
          const cloneCstXSetting = _.cloneDeep(
            defaultConfig.prophet.cst_x_setting
          );
          defaultConfig.prophet = _.omit(
            defaultConfig.prophet,
            "cst_x_setting"
          );
          _.forEach(objectKeys, (key) => {
            const nestedKey = `cst_x_setting.${key}`;
            const nestedValue = _.get(cloneCstXSetting, key);
            defaultConfig.prophet[nestedKey] = nestedValue;
          });
        }
        // SPECIAL CASE: cst_algo_tuning
        if (_.has(config, ["prophet", "cst_algo_tuning"])) {
          // create nested key -> cst_algo_tuning.xxx
          const objectKeys = _.keys(config.prophet.cst_algo_tuning);
          const cloneCstXSetting = _.cloneDeep(config.prophet.cst_algo_tuning);
          config.prophet = _.omit(config.prophet, "cst_algo_tuning");
          _.forEach(objectKeys, (key) => {
            const nestedKey = `cst_algo_tuning.${key}`;
            const nestedValue = _.get(cloneCstXSetting, key);
            config.prophet[nestedKey] = nestedValue;
          });
        }
        if (_.has(defaultConfig, ["prophet", "cst_algo_tuning"])) {
          // create nested key -> cst_algo_tuning.xxx
          const objectKeys = _.keys(defaultConfig.prophet.cst_algo_tuning);
          const cloneCstXSetting = _.cloneDeep(
            defaultConfig.prophet.cst_algo_tuning
          );
          defaultConfig.prophet = _.omit(
            defaultConfig.prophet,
            "cst_algo_tuning"
          );
          _.forEach(objectKeys, (key) => {
            const nestedKey = `cst_algo_tuning.${key}`;
            const nestedValue = _.get(cloneCstXSetting, key);
            defaultConfig.prophet[nestedKey] = nestedValue;
          });
        }

        // if activeSegments has "intention", they should be custom hints
        const activeSegments = await AutoPilotAPI.getSegmentsByUnit({ unitId });

        // userHints: [{targetings: {}, autopilot_config: {}}]
        const allHints = getAllHints({ userHints, config, activeSegments });
        console.log(allHints);
        _.forEach(allHints, (hint) => {
          // SPECIAL CASE: cst_x_setting
          if (_.has(hint.config, ["prophet", "cst_x_setting"])) {
            // create nested key -> cst_x_setting.xxx
            const objectKeys = _.keys(hint.config.prophet.cst_x_setting);
            const cloneCstXSetting = _.cloneDeep(
              hint.config.prophet.cst_x_setting
            );
            hint.config.prophet = _.omit(hint.config.prophet, "cst_x_setting");
            _.forEach(objectKeys, (key) => {
              const nestedKey = `cst_x_setting.${key}`;
              const nestedValue = _.get(cloneCstXSetting, key);
              hint.config.prophet[nestedKey] = nestedValue;
            });
          }
          // SPECIAL CASE: cst_algo_tuning
          if (_.has(hint.config, ["prophet", "cst_algo_tuning"])) {
            // create nested key -> cst_algo_tuning.xxx
            const objectKeys = _.keys(hint.config.prophet.cst_algo_tuning);
            const cloneCstXSetting = _.cloneDeep(
              hint.config.prophet.cst_algo_tuning
            );
            hint.config.prophet = _.omit(
              hint.config.prophet,
              "cst_algo_tuning"
            );
            _.forEach(objectKeys, (key) => {
              const nestedKey = `cst_algo_tuning.${key}`;
              const nestedValue = _.get(cloneCstXSetting, key);
              hint.config.prophet[nestedKey] = nestedValue;
            });
          }
        });

        this.setState({
          config,
          defaultConfig,

          userHints,
          allHints,

          unitInfo,
          networkInfo,
          updatedAt,
        });
      } else {
        document.title = "404 | YB Control Center";
      }
    } catch (err) {
      console.log("Error querying auto pilot config", err);
      this.setState({ errMsg: typeof err === "object" ? err.toString() : err });
    }

    this.setState({ isLoading: false });
  }

  onAddHint(hint) {
    console.log("add", hint);

    this.setState({
      currentPage: PAGE_ID.HINT_CONFIG,
      currentHint: hint,
      currentHintId: hint.id,
    });
  }

  onEditHintConfig(hint) {
    this.setState({
      currentPage: PAGE_ID.HINT_CONFIG,
      currentHint: hint,
      currentHintId: hint.id,
    });
  }

  onEditBaseConfig() {
    this.setState({
      currentPage: PAGE_ID.BASE_CONFIG,
    });
  }

  onBackToOverview() {
    // TODO:
    // const userConfirm = window.confirm(
    //   `You have unsaved changes! Are you sure you want to go back?`
    // );
    // if (!userConfirm) return;

    // Clear some stuff!
    this.setState({
      currentPage: PAGE_ID.OVERVIEW,
      currentHint: null,
      currentHintId: null,
    });
  }

  async onRemoveHint(hint) {
    if (this.state.isSaving) {
      return;
    }

    this.setState({ isSaving: true, saveErrMsg: null });

    const { userHints, unitId } = this.state;

    let finalUserHints = _.filter(userHints, (userHint) => {
      const hintId = getHintTypeFromUserHint(userHint);
      // return hintId;
      return hintId !== hint.id;
    });

    console.log("remove user hints!", unitId, finalUserHints);
    try {
      const resp = await AutoPilotAPI.updateAutoPilotUserHints({
        unitId,
        userHints: finalUserHints,
      });
      console.log(resp);

      notify.show("Hint removed! Automatically reloading page...", "success");

      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
    } catch (err) {
      console.log("uopdae failedddd!!!");
      console.log(err);
      notify.show("Failed to save", "error");

      console.log("Error saving user hints", err);
      this.setState({
        saveErrMsg: typeof err === "object" ? err.toString() : err,
      });
    }

    this.setState({ isSaving: false });
  }

  render() {
    const {
      currentPage,

      config,
      defaultConfig,

      userHints,
      allHints,
      currentHint,
      currentHintId,

      networkInfo,
      unitInfo,
      updatedAt,
      unitId,

      isSaving,
      saveErrMsg,
    } = this.state;

    if (this.state.isLoading) {
      return <LoadingUI></LoadingUI>;
    }

    if (this.state.errMsg) {
      return <div className="text-red-600">{this.state.errMsg}</div>;
    }

    if (config) {
      return (
        <div className="pb-64">
          <AutoPilotViewHeader
            networkInfo={networkInfo}
            unitInfo={unitInfo}
            onBackToOverview={this.onBackToOverview}
            currentPage={currentPage}
            currentHint={currentHint}
          ></AutoPilotViewHeader>

          <AutoPilotViewBody
            unitId={unitId}
            networkInfo={networkInfo}
            currentPage={currentPage}
            updatedAt={updatedAt}
            config={config}
            defaultConfig={defaultConfig}
            userHints={userHints}
            allHints={allHints}
            currentHint={currentHint}
            currentHintId={currentHintId}
            onAddHint={this.onAddHint}
            onEditHintConfig={this.onEditHintConfig}
            onEditBaseConfig={this.onEditBaseConfig}
            onRemoveHint={this.onRemoveHint}
            isSaving={isSaving}
            saveErrMsg={saveErrMsg}
          ></AutoPilotViewBody>
        </div>
      );
    }

    return "Config does not exist";
  }
}

class AutoPilotViewBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSavingCommand: false,
    };

    this.handleCommandForceIterate = this.handleCommandForceIterate.bind(this);
  }

  async handleCommandForceIterate() {
    if (this.state.isSavingCommand) {
      return;
    }

    this.setState({ isSavingCommand: true });

    const { unitId } = this.props;
    try {
      const resp = await AutoPilotAPI.forceIterateAutoPilotUnit({
        unitId,
      });
      console.log(resp);

      notify.show("Force Iterate command received!", "success");
    } catch (err) {
      console.log("command failedddd!!!");
      console.log(err);
      notify.show("Failed to command", "error");

      console.log("Error commanding force iterate", err);
      this.setState({
        saveErrMsg: typeof err === "object" ? err.toString() : err,
      });
    }

    this.setState({ isSavingCommand: false });
  }

  render() {
    const {
      networkInfo,
      unitId,
      updatedAt,
      currentPage,

      config,
      defaultConfig,

      userHints,
      allHints,
      currentHint,
      currentHintId,

      onAddHint,
      onEditHintConfig,
      onEditBaseConfig,
      onRemoveHint,

      isSaving,
      saveErrMsg,
    } = this.props;

    switch (currentPage) {
      case PAGE_ID.OVERVIEW: {
        return (
          <div className="mt-4 flex">
            <div className="w-2/3 pl-4 pr-6">
              <APCOverviewPage
                unitId={unitId}
                config={config}
                defaultConfig={defaultConfig}
                userHints={userHints}
                allHints={allHints}
                currentHint={currentHint}
                onAddHint={onAddHint}
                onEditHintConfig={onEditHintConfig}
                onEditBaseConfig={onEditBaseConfig}
                onRemoveHint={onRemoveHint}
                isSaving={isSaving}
                saveErrMsg={saveErrMsg}
              ></APCOverviewPage>
            </div>

            <div className="w-1/3">
              {updatedAt && (
                <div className="text-sm text-gray-600">
                  Last update time:
                  <br />
                  <DateTimeFormatter datetime={updatedAt}></DateTimeFormatter>
                </div>
              )}

              <div className="mt-8">
                <div className="border-b font-semibold text-gray-600">
                  COMMANDS:
                </div>
                <div className="border-b p-4">
                  <div className="text-gray-600">
                    Force Auto Pilot config to iterate ASAP.
                  </div>
                  <button
                    type="button"
                    disabled={this.state.isSavingCommand}
                    className={`rounded shadow px-4 py-2 text-gray-800
                    ${
                      this.state.isSavingCommand
                        ? "cursor-not-allowed bg-gray-100"
                        : "bg-white hover:bg-gray-100"
                    }`}
                    onClick={this.handleCommandForceIterate}
                  >
                    {this.state.isSavingCommand
                      ? "Commanding..."
                      : "Force Iterate"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      }

      case PAGE_ID.BASE_CONFIG: {
        return (
          <UnitAPConfigViewer
            unitId={unitId}
            networkId={networkInfo.gamNetworkId}
            config={config}
            defaultConfig={defaultConfig}
            updatedAt={updatedAt}
          ></UnitAPConfigViewer>
        );
      }

      case PAGE_ID.HINT_CONFIG: {
        // Important! hint config should be resolved by combining unit config with default config
        let defaultHintConfig = resolveHintDefaultConfig({
          unitConfig: config,
          defaultConfig,
        });

        return (
          <UnitHintAPConfigViewer
            currentPage={currentPage}
            unitId={unitId}
            defaultHintConfig={defaultHintConfig}
            userHints={userHints}
            currentHint={currentHint}
            currentHintId={currentHintId}
            updatedAt={updatedAt}
          ></UnitHintAPConfigViewer>
        );
      }

      default: {
        // nothing
        return "404";
      }
    }
  }
}

class APCOverviewPage extends React.PureComponent {
  constructor(props) {
    super(props);

    const customHint = createNewCustomHint();

    this.state = {
      isConfirmModalOpened: false,
      confirmingHint: null,

      customIntention: "",
      customHint,
      customHintErrMsg: null,
    };

    this.openConfirmModal = this.openConfirmModal.bind(this);
    this.handleCloseConfirmModal = this.handleCloseConfirmModal.bind(this);

    this.handleCustomIntentionChanged =
      this.handleCustomIntentionChanged.bind(this);
    this.onAddCustomHint = this.onAddCustomHint.bind(this);
  }

  openConfirmModal(hint) {
    this.setState({
      isConfirmModalOpened: true,
      confirmingHint: hint,
    });
  }

  handleCloseConfirmModal() {
    this.setState({
      isConfirmModalOpened: false,
      confirmingHint: null,
    });
  }

  onAddCustomHint() {
    const { customIntention, customHint } = this.state;
    if (_.isEmpty(customIntention)) {
      this.setState({
        customHintErrMsg: "Please give intention a name",
      });
      return;
    }

    const allIntentions = _.map(this.props.userHints, "targetings.intention");
    if (_.indexOf(allIntentions, customIntention) !== -1) {
      this.setState({
        customHintErrMsg: "Intention already exists, please use another name",
      });
      return;
    }

    let newCustomHint = { ...customHint };
    newCustomHint.id = customIntention;
    newCustomHint.title = customIntention;
    newCustomHint.targetings = { intention: customIntention };
    this.props.onAddHint(newCustomHint);
  }

  handleCustomIntentionChanged(e) {
    this.setState({
      customIntention: e.target.value,
    });
  }

  render() {
    const {
      isSaving,
      saveErrMsg,

      userHints,
      allHints,
      currentHint,

      onAddHint,
      onEditHintConfig,
      onEditBaseConfig,
      onRemoveHint,
    } = this.props;
    const { customIntention, customHintErrMsg } = this.state;

    const currentHints = _.filter(allHints, (hint) => {
      return hint.hasConfig;
    });

    const availableCustomHints = _.filter(allHints, (hint) => {
      return !hint.hasConfig && hint.type === "CUSTOM";
    });

    const availablePredefinedHints = _.filter(allHints, (hint) => {
      return !hint.hasConfig && hint.type === "PREDEFINED";
    });

    return (
      <>
        <div>
          <div className="border rounded flex w-full items-center justify-between border-gray-400 bg-gray-300 px-8 py-6">
            <div className="font-bold uppercase text-blue-900">Base Config</div>
            <button
              type="button"
              className="rounded shadow bg-white px-4 py-2 text-gray-800 hover:bg-gray-200"
              onClick={onEditBaseConfig}
            >
              Edit
            </button>
          </div>
        </div>

        <div className="border-b mt-8 text-2xl font-bold text-gray-800">
          Hints
        </div>
        <div className="mt-8 mb-2 text-lg font-semibold text-gray-600">
          Create custom hint:
        </div>
        <div className="border rounded mb-2 flex w-full items-center justify-between border-gray-300 bg-gray-100 px-8 py-4">
          <div className="-ml-1 w-1/2">
            <div className="text-xs font-semibold leading-none tracking-wide text-gray-600">
              INTENTION
            </div>
            <input
              type="text"
              className={basicInputClass}
              value={customIntention}
              onChange={this.handleCustomIntentionChanged}
            ></input>
            <div className="text-xs text-red-600">{customHintErrMsg}</div>
          </div>

          <button
            type="button"
            className="rounded shadow bg-white px-4 py-2 text-gray-800 hover:bg-gray-200"
            onClick={this.onAddCustomHint}
          >
            Add
          </button>
        </div>

        {currentHints.length > 0 && (
          <>
            <div className="mt-8 mb-2 text-lg font-semibold text-gray-600">
              Current hints:
            </div>
            {currentHints.map((hint) => {
              return (
                <div
                  key={hint.id}
                  className="border rounded mb-2 flex w-full items-center justify-between border-gray-400 bg-gray-300 px-8 py-4"
                >
                  <div className="font-bold text-blue-900">
                    {hint.type === "CUSTOM" && (
                      <div className="text-xs font-semibold leading-none tracking-wide text-gray-600">
                        INTENTION
                      </div>
                    )}
                    {hint.title}
                  </div>

                  <div>
                    <button
                      type="button"
                      className="rounded shadow mr-4 bg-red-300 px-4 py-2 text-red-900 hover:bg-red-400"
                      onClick={() => this.openConfirmModal(hint)}
                    >
                      Remove this hint
                    </button>

                    <button
                      type="button"
                      className="rounded shadow bg-white px-4 py-2 text-gray-800 hover:bg-gray-100"
                      onClick={() => onEditHintConfig(hint)}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              );
            })}
          </>
        )}

        {availableCustomHints.length > 0 ? (
          <>
            <div className="mt-8 mb-2 text-lg font-semibold text-gray-600">
              Add new custom hints (based on current custom segmentation):
            </div>
            {availableCustomHints.map((hint) => {
              return (
                <div
                  key={hint.id}
                  className="border rounded mb-2 flex w-full items-center justify-between border-gray-300 bg-gray-100 px-8 py-4"
                >
                  <TargetingPopover
                    triggerElement={
                      <div className="cursor-pointer font-bold text-blue-900 hover:underline">
                        {hint.type === "CUSTOM" && (
                          <div className="text-xs font-semibold leading-none tracking-wide text-gray-500">
                            INTENTION
                          </div>
                        )}
                        {hint.title}
                      </div>
                    }
                    targetings={hint.targetingsDetail}
                  ></TargetingPopover>

                  <div>
                    <button
                      type="button"
                      className="rounded shadow bg-white px-4 py-2 text-gray-800 hover:bg-gray-100"
                      onClick={() => onAddHint(hint)}
                    >
                      Add
                    </button>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          ""
        )}

        {availablePredefinedHints.length > 0 && (
          <>
            <div className="mt-8 mb-2 text-lg font-semibold text-gray-600">
              Add new predefined hints:
            </div>
            {availablePredefinedHints.map((hint) => {
              return (
                <div
                  key={hint.id}
                  className="border rounded mb-2 flex w-full items-center justify-between border-gray-300 bg-gray-100 px-8 py-4"
                >
                  <TargetingPopover
                    triggerElement={
                      <div className="cursor-pointer font-bold text-blue-900 hover:underline">
                        {hint.title}
                      </div>
                    }
                    targetings={hint.targetingsDetail}
                  ></TargetingPopover>

                  <button
                    type="button"
                    className="rounded shadow bg-white px-4 py-2 text-gray-800 hover:bg-gray-200"
                    onClick={() => onAddHint(hint)}
                  >
                    Add
                  </button>
                </div>
              );
            })}
          </>
        )}

        {this.state.confirmingHint && (
          <ModalWrapper
            isOpen={this.state.isConfirmModalOpened}
            showCloseFooter={false}
            handleClose={this.handleCloseConfirmModal}
            width="60%"
          >
            <div>
              <div className="mb-2 text-xl font-bold text-gray-700">
                Are you sure you want to remove user hint:{" "}
                {this.state.confirmingHint.id}?
              </div>

              <div
                className="border overflow-y-auto"
                style={{ maxHeight: "320px" }}
              >
                <pre>
                  {JSON.stringify(this.state.confirmingHint.config, null, 2)}
                </pre>
              </div>

              <div className="mt-4 flex flex-row-reverse items-center">
                <div>
                  <button
                    type="button"
                    className="rounded shadow bg-red-500 px-4 py-2 font-semibold text-white hover:bg-red-700"
                    onClick={() => onRemoveHint(this.state.confirmingHint)}
                  >
                    {isSaving ? "Removing..." : "Yes, remove this hint"}
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    disabled={isSaving}
                    className={`px-4 py-2 text-blue-700 ${
                      isSaving ? "cursor-not-allowed" : ""
                    }`}
                    onClick={this.handleCloseConfirmModal}
                  >
                    Cancel
                  </button>
                </div>
                <div className="text-red-600">{saveErrMsg}</div>
              </div>
            </div>
          </ModalWrapper>
        )}
      </>
    );
  }
}

export default AutoPilotViewerForUnitWithHints;
