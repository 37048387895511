import React from "react";
import _ from "lodash";
import NumberFormat from "../common/NumberFormat";

class BizNetworkTable extends React.Component {
  render() {
    const {
      reports,
      filteredDateRange,
      isLinkable, // is network name in table linkable
      totalData,
    } = this.props;

    const data = _calculateDataByNetwork(reports, filteredDateRange);
    let increRevPercentage = 0;

    return (
      <>
        {/* <div className="uppercase text-xl text-gray-600 font-semibold">
          Networks ({data.length})
        </div> */}
        <table className="table-auto w-full">
          <thead className="text-sm text-gray-800 align-bottom">
            <tr>
              <th className="px-4 py-2">#</th>
              <th className="px-4 py-2 text-left">Network</th>
              {/* <th className="px-4 py-2 text-left">Publisher</th> */}
              <th className="px-4 py-2 text-right">Net Increased Rev</th>
              <th className="px-4 py-2 text-right">Revenue Goal</th>
              <th className="px-4 py-2 text-right">Goal Reached</th>
              <th className="px-4 py-2 text-right">
                Out of Total %{" "}
                <div className="text-xs text-gray-600 font-normal">
                  Increment%
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="text-gray-900">
            {data.map((d, i) => {
              const revPercentage = _.round(
                (d.netIncreasedRev / totalData.totalNetIncreasedRev) * 100,
                2
              );
              increRevPercentage += revPercentage;

              return (
                <tr key={d.networkId} className="hover:bg-gray-100">
                  <td className="border px-4 py-2 text-center">{i + 1}</td>

                  <td className="border px-4 py-2">
                    <span>
                      {isLinkable ? (
                        <a
                          className="text-blue-600 hover:text-blue-800"
                          href={`${window.location.origin}/#/biz/network/${d.networkId}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="pr-2">{d.networkId}</span>
                          <span className="font-semibold">{d.networkName}</span>
                        </a>
                      ) : (
                        <div>
                          <span className="pr-2">{d.networkId}</span>
                          <span className="font-semibold">{d.networkName}</span>
                        </div>
                      )}
                    </span>
                    <div className="text-xs text-gray-600">
                      Publisher {d.publisherId} {d.publisherName}
                    </div>
                  </td>

                  <td className="border px-4 py-2 text-right font-mono font-semibold">
                    <span className="text-gray-600 text-sm font-normal">
                      ${" "}
                    </span>
                    <NumberFormat
                      value={d.netIncreasedRev}
                      format="0,0"
                    ></NumberFormat>
                  </td>

                  <td className="border px-4 py-2 text-right whitespace-no-wrap font-mono text-gray-800">
                    {d.dailyGoal ? (
                      <>
                        <span className="text-gray-600 text-sm font-normal">
                          ${" "}
                        </span>
                        <NumberFormat
                          value={d.dailyGoal}
                          format="0,0"
                        ></NumberFormat>
                      </>
                    ) : (
                      "-"
                    )}
                  </td>

                  <td className="border px-4 py-2 text-right font-mono text-gray-800">
                    {d.goalReachedPercentage ? (
                      <>
                        <NumberFormat
                          value={d.goalReachedPercentage}
                          format="0,0.00"
                        ></NumberFormat>
                        <span className="text-gray-600 text-sm font-normal">
                          %
                        </span>
                      </>
                    ) : (
                      "-"
                    )}
                  </td>

                  <td className="border px-4 py-2 text-right font-mono font-semibold text-gray-800">
                    <NumberFormat
                      value={revPercentage}
                      format="0,0.00"
                    ></NumberFormat>

                    <span className="text-gray-600 text-sm font-normal">%</span>

                    <div className="text-xs text-gray-600 font-normal">
                      <NumberFormat
                        value={increRevPercentage}
                        format="0,0.00"
                      ></NumberFormat>

                      <span>%</span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }
}

function _calculateDataByNetwork(reports, filteredDateRange) {
  // console.log(filteredDateRange);

  let data = _.mapValues(reports, (rs) => {
    const rows = _.values(rs);
    // console.log(rows);

    // filter date

    const first = _.first(rows);
    const { networkId, networkName, publisherId, publisherName } = first;

    const netIncreasedRev = _.sumBy(rows, "netIncreasedRevTwd");
    const dailyGoal = _.sumBy(rows, "dailyGoalTwd");

    const goalReachedPercentage = _.round(
      (netIncreasedRev / dailyGoal) * 100,
      2
    );

    return {
      networkId,
      networkName,
      publisherId,
      publisherName,
      netIncreasedRev,
      dailyGoal,
      goalReachedPercentage: _.isFinite(goalReachedPercentage)
        ? goalReachedPercentage
        : 0,
    };
  });
  data = _.values(data);
  data = _.orderBy(data, ["netIncreasedRev"], ["desc"]);

  return data;
}

export default BizNetworkTable;
