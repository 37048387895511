import React from "react";
import _ from "lodash";

class CurrencyFormat extends React.PureComponent {
  render() {
    // value is optional
    const { currency, value } = this.props;

    if (!currency) return "";

    const { currencyString, amountString } = _formatAmountWithCurrency({
      currency,
      value,
    });

    if (value === undefined) return currencyString;
    return `${currencyString}${amountString}`;
  }
}

function _formatAmountWithCurrency({ currency, value }) {
  const formatParts = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
  }).formatToParts(value);

  const currencyString = _.get(
    _(formatParts)
      .filter((p) => p.type === "currency")
      .first(),
    "value"
  );

  const amountString = _(formatParts)
    .filter((p) => p.type !== "currency")
    .reduce((s, p) => s + p.value, "")
    .trim();

  return {
    currencyString,
    amountString,
  };
}

export default CurrencyFormat;
