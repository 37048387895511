import React from "react";
import _ from "lodash";
import DeviceCategorySelector from "../../../common/DeviceCategorySelector";
import OSSelector from "../../../common/OSSelector";
import CountrySelector from "../../../common/CountrySelector";
import HourSelector from "../../../common/HourSelector";
import AdSizesControl from "./AdSizesControl";
import SetsOfAdSizesControl from "./SetsOfAdSizesControl";
// import CustomTargetingControl from "./CustomTargetingControl";
import CustomSetTargetingControl from "./CustomSetTargetingControl";
import InventoryRegistrySelector from "components/common/InventoryRegistrySelector";
import BrowserSelector from "components/common/BrowserSelector";

const basicInputClass =
  "bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded py-1 px-2 block appearance-none leading-normal";

const TARGETING_CONTROL_TYPE = {
  TEXTAREA: "TEXTAREA",
  SELECTORS: "SELECTORS",
};

const TARGETING_TYPE = {
  OS: "os",
  DC: "device_category",
  COUNTRY: "country",
  HOUR: "hour",
  BROWSER: "browser",

  SIZES: "sizes",
  RRS: "relevant_requested_sizes",

  CUSTOM: "custom",
  CUSTOMS: "customs",

  UPR_SEGMENT_ID: "upr_segment_id",
  INVENTORY_REGISTRY: "inventory_registry",
  // REQUEST_PATTERN: "request_pattern", // tbd in the future
};

class TargetingsControl extends React.PureComponent {
  constructor(props) {
    super(props);

    const { targeting } = props;

    this.state = {
      type: TARGETING_CONTROL_TYPE.SELECTORS,
      targetingString: JSON.stringify(targeting),

      errorMsg: null,
    };

    this.handleTypeChanged = this.handleTypeChanged.bind(this);
    this.handleTargetingStringChanged =
      this.handleTargetingStringChanged.bind(this);
    this.handleTargetingFromSelectorChanged =
      this.handleTargetingFromSelectorChanged.bind(this);
    this.handleUprSegmentIdChanged = this.handleUprSegmentIdChanged.bind(this);
  }

  handleTypeChanged(type) {
    this.setState({ type });
  }

  handleTargetingStringChanged(e) {
    const targetingString = e.target.value;
    this.setState({ targetingString });

    let targeting = {};
    let isValid = true;
    if (targetingString && targetingString !== "") {
      try {
        targeting = JSON.parse(targetingString);
      } catch (err) {
        this.setState({
          errorMsg: "targeting not a valid JSON",
        });
        return;
      }

      const acceptableKeys = _.values(TARGETING_TYPE);
      let errMsg = null;
      _.forEach(_.keys(targeting), (t) => {
        if (_.indexOf(acceptableKeys, t) === -1) {
          isValid = false;
          errMsg = `${t} is not a valid key`;
          return false;
        }
      });

      if (errMsg) {
        this.setState({
          errorMsg: errMsg,
        });
        return;
      }

      if (isValid) {
        this.props.handleTargetingChanged(targeting);
        this.setState({ errorMsg: null });
      }
    }
  }

  handleTargetingFromSelectorChanged(sTargeting, type) {
    // console.log("tiuasdfj", sTargeting, type);
    const targetKey = type;

    let targeting = _.cloneDeep(this.props.targeting);
    if (!sTargeting || _.isEmpty(sTargeting)) {
      targeting = _.omit(targeting, targetKey);
    } else {
      targeting[targetKey] = sTargeting;
    }
    this.props.handleTargetingChanged(targeting);

    // important! also update targetingString
    this.setState({
      targetingString: JSON.stringify(targeting, null, 4),
    });
  }

  handleUprSegmentIdChanged(e) {
    const uprSegmentId = e.target.value;

    let targeting = _.cloneDeep(this.props.targeting);
    if (!uprSegmentId || _.isEmpty(uprSegmentId)) {
      targeting = _.omit(targeting, TARGETING_TYPE.UPR_SEGMENT_ID);
    } else {
      targeting[TARGETING_TYPE.UPR_SEGMENT_ID] = _.parseInt(uprSegmentId);
    }

    this.props.handleTargetingChanged(targeting);

    // important! also update targetingString
    this.setState({
      targetingString: JSON.stringify(targeting, null, 4),
    });
  }

  render() {
    const { type, targetingString, errorMsg } = this.state;
    const { targeting, networkId } = this.props;

    const tClass = "bg-gray-200 my-2 rounded";

    return (
      <div>
        <div className="flex items-center gap-4">
          <label htmlFor="radio_textview" className="cursor-pointer">
            <input
              id="radio_textview"
              type="radio"
              name={`${type}_radio`}
              value={TARGETING_CONTROL_TYPE.TEXTAREA}
              checked={type === TARGETING_CONTROL_TYPE.TEXTAREA}
              onChange={() =>
                this.handleTypeChanged(TARGETING_CONTROL_TYPE.TEXTAREA)
              }
            />{" "}
            Text View
          </label>

          <label htmlFor="radio_selector" className="cursor-pointer">
            <input
              id="radio_selector"
              type="radio"
              name={`${type}_radio`}
              value={TARGETING_CONTROL_TYPE.SELECTORS}
              checked={type === TARGETING_CONTROL_TYPE.SELECTORS}
              onChange={() =>
                this.handleTypeChanged(TARGETING_CONTROL_TYPE.SELECTORS)
              }
            />{" "}
            Use Selectors
          </label>
        </div>

        <div className="border p-1">
          {type === TARGETING_CONTROL_TYPE.TEXTAREA && (
            <>
              <textarea
                rows={6}
                className={basicInputClass + " w-full"}
                value={targetingString}
                onChange={this.handleTargetingStringChanged}
              ></textarea>
              <div className="text-red-600">{errorMsg}</div>
            </>
          )}

          {type === TARGETING_CONTROL_TYPE.SELECTORS && (
            <div>
              <div className={tClass}>
                <div className="font-semibold">UPR Segment ID:</div>
                <input
                  type="number"
                  value={targeting[TARGETING_TYPE.UPR_SEGMENT_ID] || ""}
                  className={basicInputClass}
                  onChange={this.handleUprSegmentIdChanged}
                  required
                ></input>
                <div className="text-xs text-gray-700">
                  Should match the ID of network UPR segmentation
                </div>
              </div>

              <div className={tClass}>
                <div className="font-semibold">Inventory registry:</div>
                <TargetingWithInExControl
                  targetingType={TARGETING_TYPE.INVENTORY_REGISTRY}
                  targeting={targeting[TARGETING_TYPE.INVENTORY_REGISTRY]}
                  handleTargetingChanged={(t) =>
                    this.handleTargetingFromSelectorChanged(
                      t,
                      TARGETING_TYPE.INVENTORY_REGISTRY
                    )
                  }
                ></TargetingWithInExControl>
              </div>

              <div className={tClass}>
                <div className="font-semibold">Device Category:</div>
                <TargetingWithInExControl
                  targetingType={TARGETING_TYPE.DC}
                  targeting={targeting[TARGETING_TYPE.DC]}
                  handleTargetingChanged={(t) =>
                    this.handleTargetingFromSelectorChanged(
                      t,
                      TARGETING_TYPE.DC
                    )
                  }
                ></TargetingWithInExControl>
              </div>
              <div className={tClass}>
                <div className="font-semibold">OS:</div>
                <TargetingWithInExControl
                  targetingType={TARGETING_TYPE.OS}
                  targeting={targeting[TARGETING_TYPE.OS]}
                  handleTargetingChanged={(t) =>
                    this.handleTargetingFromSelectorChanged(
                      t,
                      TARGETING_TYPE.OS
                    )
                  }
                ></TargetingWithInExControl>
              </div>
              <div className={tClass}>
                <div className="font-semibold">Country:</div>
                <TargetingWithInExControl
                  targetingType={TARGETING_TYPE.COUNTRY}
                  targeting={targeting[TARGETING_TYPE.COUNTRY]}
                  handleTargetingChanged={(t) =>
                    this.handleTargetingFromSelectorChanged(
                      t,
                      TARGETING_TYPE.COUNTRY
                    )
                  }
                ></TargetingWithInExControl>
              </div>
              <div className={tClass}>
                <div className="font-semibold">Hour:</div>
                <TargetingWithInExControl
                  targetingType={TARGETING_TYPE.HOUR}
                  targeting={targeting[TARGETING_TYPE.HOUR]}
                  handleTargetingChanged={(t) =>
                    this.handleTargetingFromSelectorChanged(
                      t,
                      TARGETING_TYPE.HOUR
                    )
                  }
                ></TargetingWithInExControl>
              </div>

              <div className={tClass}>
                <div className="font-semibold">Browser:</div>
                <TargetingWithInExControl
                  targetingType={TARGETING_TYPE.BROWSER}
                  targeting={targeting[TARGETING_TYPE.BROWSER]}
                  handleTargetingChanged={(t) =>
                    this.handleTargetingFromSelectorChanged(
                      t,
                      TARGETING_TYPE.BROWSER
                    )
                  }
                ></TargetingWithInExControl>
              </div>

              <div className={tClass}>
                <div className="font-semibold">Sizes:</div>
                <TargetingSelectOrNotControl
                  targetingType={TARGETING_TYPE.SIZES}
                  targeting={targeting[TARGETING_TYPE.SIZES]}
                  handleTargetingChanged={(t) =>
                    this.handleTargetingFromSelectorChanged(
                      t,
                      TARGETING_TYPE.SIZES
                    )
                  }
                ></TargetingSelectOrNotControl>
              </div>
              <div className={tClass}>
                <div className="font-semibold">Relevant Requested Sizes:</div>
                <TargetingSelectOrNotControl
                  targetingType={TARGETING_TYPE.RRS}
                  targeting={targeting[TARGETING_TYPE.RRS]}
                  handleTargetingChanged={(t) =>
                    this.handleTargetingFromSelectorChanged(
                      t,
                      TARGETING_TYPE.RRS
                    )
                  }
                ></TargetingSelectOrNotControl>
              </div>
              {/* Custom is deprecated. Replaced with Customs */}
              {/* <div className={tClass}>
                <div className="font-semibold">Custom:</div>
                <TargetingCustomControl
                  targetingType={TARGETING_TYPE.CUSTOM}
                  targeting={targeting[TARGETING_TYPE.CUSTOM]}
                  handleTargetingChanged={(t) =>
                    this.handleTargetingFromSelectorChanged(
                      t,
                      TARGETING_TYPE.CUSTOM
                    )
                  }
                ></TargetingCustomControl>
              </div> */}
              <div className={tClass}>
                <div className="font-semibold">Customs:</div>
                <div>
                  <TargetingCustomsSetControl
                    networkId={networkId}
                    targetingType={TARGETING_TYPE.CUSTOMS}
                    targeting={targeting[TARGETING_TYPE.CUSTOMS]}
                    handleTargetingChanged={(t) =>
                      this.handleTargetingFromSelectorChanged(
                        t,
                        TARGETING_TYPE.CUSTOMS
                      )
                    }
                    handleIsEditing={this.props.handleIsEditing}
                  ></TargetingCustomsSetControl>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

class TargetingCustomsSetControl extends React.PureComponent {
  constructor(props) {
    super(props);

    const { targeting } = props;
    // targeting: [ { keyId, valueIds, operator }... ]
    // array of customs:
    // custom: { keyId, valueIds, operator }

    let radioValue = TARGETING_OP_3.NONE;
    let finalValue = targeting;
    if (targeting) {
      radioValue = TARGETING_OP_3.INSERT;
      finalValue = targeting;
    }

    this.state = {
      radioValue,
      finalValue,
    };

    this.handleChangeRadio = this.handleChangeRadio.bind(this);
    this.handleValueChanged = this.handleValueChanged.bind(this);
  }

  handleChangeRadio(value) {
    this.setState({
      radioValue: value,
    });

    if (value === TARGETING_OP_3.NONE) {
      this.props.handleTargetingChanged(null);
      this.setState({ finalValue: [] });
    } else {
      const { finalValue } = this.state;
      this.props.handleTargetingChanged(finalValue);
    }
  }

  handleValueChanged(finalValue) {
    this.props.handleTargetingChanged(finalValue);
  }

  render() {
    // type: sizes, relevant_requested_sizes, custom
    const { networkId, targetingType } = this.props;
    const { radioValue, finalValue } = this.state;

    return (
      <>
        <div></div>

        <div className="flex items-center gap-4">
          <label
            htmlFor={`${targetingType}_radio_none`}
            className="cursor-pointer"
          >
            <input
              id={`${targetingType}_radio_none`}
              type="radio"
              name={`${targetingType}_radio`}
              value={TARGETING_OP_3.NONE}
              checked={radioValue === TARGETING_OP_3.NONE}
              onChange={() => this.handleChangeRadio(TARGETING_OP_3.NONE)}
            />{" "}
            None
          </label>

          <label
            htmlFor={`${targetingType}_radio_insert`}
            className="cursor-pointer"
          >
            <input
              id={`${targetingType}_radio_insert`}
              type="radio"
              name={`${targetingType}_radio`}
              value={TARGETING_OP_3.INSERT}
              checked={radioValue === TARGETING_OP_3.INSERT}
              onChange={() => this.handleChangeRadio(TARGETING_OP_3.INSERT)}
            />{" "}
            Insert
          </label>
        </div>

        {radioValue !== TARGETING_OP_3.NONE && (
          <CustomSetTargetingControl
            networkId={networkId}
            targeting={finalValue}
            handleValueChanged={this.handleValueChanged}
            handleIsEditing={this.props.handleIsEditing}
          ></CustomSetTargetingControl>
        )}
      </>
    );
  }
}

const TARGETING_OP_3 = {
  NONE: "NONE",
  INSERT: "INSERT",
};
// class TargetingCustomControl extends React.PureComponent {
//   constructor(props) {
//     super(props);

//     const { targeting } = props;
//     // { keyId, valueIds, operator }

//     let radioValue = TARGETING_OP_3.NONE;
//     let finalValue = {};
//     if (targeting) {
//       radioValue = TARGETING_OP_3.INSERT;
//       finalValue = targeting;
//     }

//     this.state = {
//       radioValue,
//       finalValue,
//     };

//     this.handleChangeRadio = this.handleChangeRadio.bind(this);
//     this.handleValueChanged = this.handleValueChanged.bind(this);
//   }

//   handleChangeRadio(value) {
//     this.setState({
//       radioValue: value,
//     });

//     if (value === TARGETING_OP_3.NONE) {
//       this.props.handleTargetingChanged(null);
//       this.setState({ finalValue: {} });
//     } else {
//       const { finalValue } = this.state;
//       this.props.handleTargetingChanged(finalValue);

//       // const items = this.state.selectedItems;
//       // if (_.isEmpty(items)) {
//       //   this.props.handleTargetingChanged(null);
//       // } else {
//       //   let tt = items;
//       //   this.props.handleTargetingChanged(tt);
//       // }
//     }
//   }

//   handleValueChanged(finalValue) {
//     this.props.handleTargetingChanged(finalValue);
//   }

//   render() {
//     // type: sizes, relevant_requested_sizes, custom
//     const { targetingType } = this.props;
//     const { radioValue, finalValue } = this.state;

//     return (
//       <>
//         <div className="flex items-center gap-4">
//           <label
//             htmlFor={`${targetingType}_radio_none`}
//             className="cursor-pointer"
//           >
//             <input
//               id={`${targetingType}_radio_none`}
//               type="radio"
//               name={`${targetingType}_radio`}
//               value={TARGETING_OP_3.NONE}
//               checked={radioValue === TARGETING_OP_3.NONE}
//               onChange={() => this.handleChangeRadio(TARGETING_OP_3.NONE)}
//             />{" "}
//             None
//           </label>

//           <label
//             htmlFor={`${targetingType}_radio_insert`}
//             className="cursor-pointer"
//           >
//             <input
//               id={`${targetingType}_radio_insert`}
//               type="radio"
//               name={`${targetingType}_radio`}
//               value={TARGETING_OP_3.INSERT}
//               checked={radioValue === TARGETING_OP_3.INSERT}
//               onChange={() => this.handleChangeRadio(TARGETING_OP_3.INSERT)}
//             />{" "}
//             Insert
//           </label>
//         </div>

//         {radioValue !== TARGETING_OP_3.NONE && (
//           <CustomTargetingControl
//             customValue={finalValue}
//             handleValueChanged={this.handleValueChanged}
//           ></CustomTargetingControl>
//         )}
//       </>
//     );
//   }
// }

const TARGETING_OP_2 = {
  NONE: "NONE",
  SELECT: "SELECT",
};
class TargetingSelectOrNotControl extends React.PureComponent {
  constructor(props) {
    super(props);

    const { targeting } = props;

    let radioValue = TARGETING_OP_2.NONE;
    let selectedItems = [];
    if (targeting) {
      radioValue = TARGETING_OP_2.SELECT;
      selectedItems = targeting;
    }

    this.state = {
      radioValue,
      selectedItems,
    };

    this.handleChangeRadio = this.handleChangeRadio.bind(this);
    this.handleItemsChanged = this.handleItemsChanged.bind(this);
  }

  handleChangeRadio(value) {
    this.setState({
      radioValue: value,
    });

    if (value === TARGETING_OP_2.NONE) {
      this.props.handleTargetingChanged(null);
      this.setState({ selectedItems: [] });
    } else {
      const items = this.state.selectedItems;
      if (_.isEmpty(items)) {
        this.props.handleTargetingChanged(null);
      } else {
        let tt = items;
        this.props.handleTargetingChanged(tt);
      }
    }
  }

  handleItemsChanged(items) {
    const { radioValue } = this.state;
    // console.log("items changed", items, radioValue);
    if (radioValue !== TARGETING_OP_2.NONE) {
      if (_.isEmpty(items)) {
        this.props.handleTargetingChanged(null);
      } else {
        let tt = items;
        this.props.handleTargetingChanged(tt);
      }
    }
  }

  render() {
    // type: sizes, relevant_requested_sizes, custom
    const { targetingType } = this.props;
    const { radioValue, selectedItems } = this.state;

    let TargetingSelector = "";
    switch (targetingType) {
      case TARGETING_TYPE.SIZES: {
        TargetingSelector = (
          <AdSizesControl
            inputValue={selectedItems || []}
            handleNewValueChanged={this.handleItemsChanged}
            allowFluidSize={false}
          ></AdSizesControl>
        );
        break;
      }

      case TARGETING_TYPE.RRS: {
        TargetingSelector = (
          <SetsOfAdSizesControl
            inputValue={selectedItems || []}
            handleNewValueChanged={this.handleItemsChanged}
            hideClientRequestSizes={true}
          ></SetsOfAdSizesControl>
        );
        break;
      }

      default: {
        TargetingSelector = "Selector not implemented";
      }
    }

    return (
      <>
        <div className="flex items-center gap-4">
          <label
            htmlFor={`${targetingType}_radio_none`}
            className="cursor-pointer"
          >
            <input
              id={`${targetingType}_radio_none`}
              type="radio"
              name={`${targetingType}_radio`}
              value={TARGETING_OP_2.NONE}
              checked={radioValue === TARGETING_OP_2.NONE}
              onChange={() => this.handleChangeRadio(TARGETING_OP_2.NONE)}
            />{" "}
            None
          </label>

          <label
            htmlFor={`${targetingType}_radio_select`}
            className="cursor-pointer"
          >
            <input
              id={`${targetingType}_radio_select`}
              type="radio"
              name={`${targetingType}_radio`}
              value={TARGETING_OP_2.SELECT}
              checked={radioValue === TARGETING_OP_2.SELECT}
              onChange={() => this.handleChangeRadio(TARGETING_OP_2.SELECT)}
            />{" "}
            Select
          </label>
        </div>

        {radioValue !== TARGETING_OP_2.NONE && TargetingSelector}
      </>
    );
  }
}

const TARGETING_OP = {
  NONE: "NONE",
  INCLUDES: "includes",
  EXCLUDES: "excludes",
};

// type: country, os, device_category, hour
class TargetingWithInExControl extends React.PureComponent {
  constructor(props) {
    super(props);

    const { targeting } = props;

    let radioValue = TARGETING_OP.NONE;
    let selectedItems = [];
    if (targeting) {
      if (targeting.includes) {
        radioValue = TARGETING_OP.INCLUDES;
        selectedItems = targeting.includes;
      } else {
        radioValue = TARGETING_OP.EXCLUDES;
        selectedItems = targeting.excludes;
      }
    }

    this.state = {
      radioValue,
      selectedItems,
    };

    this.handleChangeRadio = this.handleChangeRadio.bind(this);
    this.handleItemsChanged = this.handleItemsChanged.bind(this);
  }

  handleChangeRadio(value) {
    this.setState({
      radioValue: value,
    });

    if (value === TARGETING_OP.NONE) {
      this.props.handleTargetingChanged(null);
      this.setState({ selectedItems: [] });
    } else {
      const items = this.state.selectedItems;
      if (_.isEmpty(items)) {
        this.props.handleTargetingChanged(null);
      } else {
        let tt = {
          [value]: items,
        };
        this.props.handleTargetingChanged(tt);
      }
    }
  }

  handleItemsChanged(items) {
    const { radioValue } = this.state;
    // console.log("items changed", items, radioValue);
    if (radioValue !== TARGETING_OP.NONE) {
      if (_.isEmpty(items)) {
        this.props.handleTargetingChanged(null);
      } else {
        let tt = {
          [radioValue]: items,
        };
        this.props.handleTargetingChanged(tt);
      }
    }
  }

  render() {
    // type: country, os, device_category, hour
    const { targetingType } = this.props;
    const { radioValue, selectedItems } = this.state;

    let TargetingSelector = "";
    switch (targetingType) {
      case TARGETING_TYPE.DC: {
        TargetingSelector = (
          <DeviceCategorySelector
            selectedItems={selectedItems}
            handleChanged={this.handleItemsChanged}
          ></DeviceCategorySelector>
        );
        break;
      }

      case TARGETING_TYPE.OS: {
        TargetingSelector = (
          <OSSelector
            selectedItems={selectedItems}
            handleChanged={this.handleItemsChanged}
          ></OSSelector>
        );
        break;
      }

      case TARGETING_TYPE.COUNTRY: {
        TargetingSelector = (
          <CountrySelector
            selectedItems={selectedItems}
            handleChanged={this.handleItemsChanged}
          ></CountrySelector>
        );
        break;
      }

      case TARGETING_TYPE.HOUR: {
        TargetingSelector = (
          <HourSelector
            selectedItems={selectedItems}
            handleChanged={this.handleItemsChanged}
          ></HourSelector>
        );
        break;
      }

      case TARGETING_TYPE.BROWSER: {
        TargetingSelector = (
          <BrowserSelector
            selectedItems={selectedItems}
            handleChanged={this.handleItemsChanged}
          ></BrowserSelector>
        );
        break;
      }

      case TARGETING_TYPE.INVENTORY_REGISTRY: {
        TargetingSelector = (
          <InventoryRegistrySelector
            selectedItems={selectedItems}
            handleChanged={this.handleItemsChanged}
          ></InventoryRegistrySelector>
        );
        break;
      }

      default: {
        TargetingSelector = "Selector not implemented";
      }
    }

    return (
      <>
        <div className="flex items-center gap-4">
          <label
            htmlFor={`${targetingType}_radio_none`}
            className="cursor-pointer"
          >
            <input
              id={`${targetingType}_radio_none`}
              type="radio"
              name={`${targetingType}_radio`}
              value={TARGETING_OP.NONE}
              checked={radioValue === TARGETING_OP.NONE}
              onChange={() => this.handleChangeRadio(TARGETING_OP.NONE)}
            />{" "}
            None
          </label>

          <label
            htmlFor={`${targetingType}_radio_include`}
            className="cursor-pointer"
          >
            <input
              id={`${targetingType}_radio_include`}
              type="radio"
              name={`${targetingType}_radio`}
              value={TARGETING_OP.INCLUDES}
              checked={radioValue === TARGETING_OP.INCLUDES}
              onChange={() => this.handleChangeRadio(TARGETING_OP.INCLUDES)}
            />{" "}
            Includes
          </label>

          <label
            htmlFor={`${targetingType}_radio_exclude`}
            className="cursor-pointer"
          >
            <input
              id={`${targetingType}_radio_exclude`}
              type="radio"
              name={`${targetingType}_radio`}
              value={TARGETING_OP.EXCLUDES}
              checked={radioValue === TARGETING_OP.EXCLUDES}
              onChange={() => this.handleChangeRadio(TARGETING_OP.EXCLUDES)}
            />{" "}
            Excludes
          </label>
        </div>

        {radioValue !== TARGETING_OP.NONE && TargetingSelector}
      </>
    );
  }
}

export default TargetingsControl;
