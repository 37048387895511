import React from "react";
import _ from "lodash";

const TIMEZONE_TYPE_OPTIONS = [
  {
    label: "Network Timezone",
    value: "NETWORK",
  },
  {
    label: "Taipei Timezone",
    value: "TAIPEI",
  },
];

class AnatomyTimezoneTypeSelector extends React.Component {
  constructor(props) {
    super(props);

    const { timezoneType } = this.props;

    this.state = {
      options: TIMEZONE_TYPE_OPTIONS,
      timezoneType,
    };

    this.handleChanged = this.handleChanged.bind(this);
  }

  handleChanged(timezoneType) {
    this.setState({
      timezoneType,
    });
    this.props.handleTimezoneTypeChanged({ timezoneType });
  }

  render() {
    const { options, timezoneType } = this.state;

    return (
      <div className="p-4">
        <div className="mb-1 font-semibold text-gray-700">Timezone:</div>
        <div className="flex items-center justify-start text-sm">
          <div>
            {options.map((option) => {
              return (
                <div key={option.value} className="inline-block">
                  <button
                    disabled={timezoneType === option.value}
                    type="button"
                    className={`rounded mr-1 px-4 py-1 font-semibold text-gray-700 ${
                      timezoneType === option.value
                        ? "bg-blue-200"
                        : "bg-gray-100 hover:bg-gray-200 hover:text-gray-800"
                    }`}
                    onClick={() => this.handleChanged(option.value)}
                  >
                    {option.label}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default AnatomyTimezoneTypeSelector;
