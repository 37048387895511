import React from "react";
import _ from "lodash";

import { RI_CONFIG_SCHEMA } from "./CSTConfigSchema";
import EnabledIcon from "./EnabledIcon";

import RegisteredInventoryConfig from "./RegisteredInventoryConfig";

class RegisteredInventory extends React.Component {
  constructor(props) {
    super(props);

    // integration: "gpt", "mobile_ios"...
    // riConfig: (cstConfig.inventory_integration.gpt) => {desktop: {enabled...}...}
    const { integration, riConfig, handleRIChanged } = this.props;
    const riConfigSchema = RI_CONFIG_SCHEMA[integration];
    const tabs = _.map(riConfigSchema, "key");
    const currentTab = _.first(tabs);
    const currentSchema = _.find(riConfigSchema, { key: currentTab });

    this.state = {
      riConfigSchema,
      tabs,

      currentTab,
      currentSchema,

      riConfig: _.cloneDeep(riConfig),
    };

    this.handleTabChanged = this.handleTabChanged.bind(this);
    this.handleConfigChanged = this.handleConfigChanged.bind(this);
  }

  handleTabChanged(tab) {
    const { riConfigSchema } = this.state;
    console.log("tab changed", tab, riConfigSchema);
    this.setState({
      currentTab: tab,
      currentSchema: _.find(riConfigSchema, { key: tab }),
    });
  }

  handleConfigChanged({ key, config }) {
    const { currentTab } = this.state;
    // console.log(currentTab, config);
    // console.log(this.state.riConfig);

    const newConfig = this.state.riConfig;
    newConfig[currentTab] = config;
    this.setState({ riConfig: newConfig });
    this.props.handleRIChanged(newConfig);
  }

  render() {
    const { integration, handleRIChanged, isReadOnly = false } = this.props;
    const { tabs, currentTab, riConfig, currentSchema } = this.state;
    // console.log(integration, currentTab, riConfig, currentSchema);

    // return <div>{JSON.stringify(registeredInventory)}</div>;
    // const riConfigSchema = RI_CONFIG_SCHEMA[integration];
    // const riConfig = registeredInventory;
    // console.log(riConfig);
    return (
      <div>
        <div className="flex gap-2">
          {tabs.map((tab) => {
            const isEnabled = _.get(riConfig, [tab, "enable_optimization"]);

            return (
              <div
                key={tab}
                className={`border flex items-center gap-1 border-b-0 px-4 py-2 ${
                  tab === currentTab
                    ? "bg-blue-100 text-blue-800"
                    : "cursor-pointer bg-gray-100 hover:bg-gray-200"
                }`}
                onClick={() => this.handleTabChanged(tab)}
              >
                {tab}
                <EnabledIcon isEnabled={isEnabled}></EnabledIcon>
              </div>
            );
          })}
        </div>

        <div>
          <div key={currentSchema.key} className="border rounded mb-2 p-4">
            <div className="mb-2 font-bold">{currentSchema.key}</div>

            <div>
              {currentSchema.schema.map((schema) => {
                // console.log(schema, currentSchema);
                // console.log(currentSchema.key, currentTab);
                // currentSchema.key = currentTab
                return (
                  <div key={schema.key} className="mb-2">
                    <RegisteredInventoryConfig
                      schema={schema}
                      riConfig={riConfig[currentSchema.key] || {}}
                      handleRIChanged={this.handleConfigChanged}
                    ></RegisteredInventoryConfig>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RegisteredInventory;
