import React from "react";
import _ from "lodash";
import { AutoPilotAPI } from "apis";
import AutoPilotConfigControl from "./auto-pilot/AutoPilotConfigControl";
import ModalWrapper from "../common/ModalWrapper";
import { notify } from "react-notify-toast";
import { getHintTypeFromUserHint } from "./constants/AutoPilotConfigHints";

class UnitHintAPConfigViewer extends React.Component {
  constructor(props) {
    super(props);

    const {
      // hintConfig,
      // hintTargetings,
      defaultHintConfig,
      updatedAt,
      unitId,
      currentHint,
      currentHintId,
      // userHints,
    } = props;

    const hintConfig = currentHint.config;

    this.state = {
      // isLoading: false,
      // errMsg: null,
      unitId,

      // config,
      // defaultConfig,
      hintConfig,
      // hintTargetings,
      currentHint,
      defaultHintConfig,
      updatedAt,

      isReviewModalOpened: false,
      finalConfig: null,

      isSaving: false,
      saveErrMsg: null,
    };

    this.handleSaveHintConfig = this.handleSaveHintConfig.bind(this);
    this.handleConfirmSaveConfig = this.handleConfirmSaveConfig.bind(this);
    this.handleOpenReviewModal = this.handleOpenReviewModal.bind(this);
    this.handleCloseReviewModal = this.handleCloseReviewModal.bind(this);
  }

  handleOpenReviewModal() {
    this.setState({
      isReviewModalOpened: true,
    });
  }

  handleCloseReviewModal() {
    this.setState({
      isReviewModalOpened: false,
      finalConfig: null,
      saveErrMsg: null,
    });
  }

  handleSaveHintConfig({ config }) {
    // Show review modal first!
    // save when confirmed
    const { unitId, networkId } = this.state;
    console.log("Save config", config, networkId, unitId);

    let finalConfig = _.cloneDeep(config);
    // Important! special case! Added extra outer layer of array
    if (_.has(finalConfig, "prophet.benchmark_abuse_sizes")) {
      const bas = finalConfig.prophet.benchmark_abuse_sizes;
      finalConfig.prophet.benchmark_abuse_sizes = [bas];
    }

    // find all nested keys (ex. "cst_x_setting.xxx")
    // NOT nested objects!!
    const prophetKeys = _.keys(finalConfig.prophet);
    const nestedObjectKeys = _.filter(prophetKeys, (k) => {
      return k.split(".").length > 1;
    });
    if (nestedObjectKeys.length > 0) {
      _.forEach(nestedObjectKeys, (nestedKey) => {
        const keyParts = nestedKey.split(".");
        const nestedValue = _.get(finalConfig, ["prophet", nestedKey]);
        _.set(finalConfig, ["prophet", keyParts[0], keyParts[1]], nestedValue);
        _.unset(finalConfig, ["prophet", nestedKey]);
      });
    }

    this.setState({ finalConfig });

    this.handleOpenReviewModal();
  }

  async handleConfirmSaveConfig() {
    if (this.state.isSaving) {
      return;
    }

    // HINT CONFIG!
    const { unitId, finalConfig, currentHint } = this.state;

    this.setState({ isSaving: true, saveErrMsg: null });

    const { userHints, currentHintId } = this.props;
    // 1. get targeting
    // 2. check if this hint is already inside userHints
    //    if yes -> replace it
    //    if no -> push into it
    let finalUserHint = {
      targetings: currentHint.targetings,
      autopilot_config: finalConfig,
    };

    let isHintIncluded = false;
    let finalUserHints = [];
    _.forEach(userHints, (userHint) => {
      const hintId = getHintTypeFromUserHint(userHint);
      if (hintId === currentHintId) {
        // replace this hint
        finalUserHints.push(finalUserHint);
        isHintIncluded = true;
        return; // break
      }

      // convert other hints back to the right format
      // // Important! special case! Added extra outer layer of array
      if (_.has(userHint.autopilot_config, "prophet.benchmark_abuse_sizes")) {
        const bas = userHint.autopilot_config.prophet.benchmark_abuse_sizes;
        userHint.autopilot_config.prophet.benchmark_abuse_sizes = [bas];
      }

      finalUserHints.push(userHint);
    });

    if (!isHintIncluded) {
      finalUserHints.push(finalUserHint);
    }

    console.log("update user hints!", unitId, finalUserHints);
    try {
      const resp = await AutoPilotAPI.updateAutoPilotUserHints({
        unitId,
        userHints: finalUserHints,
      });

      console.log("updated!!!");
      console.log(resp);
      notify.show(
        "Hint Config saved! Automatically reloading page...",
        "success"
      );

      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
    } catch (err) {
      console.log("uopdae failedddd!!!");
      console.log(err);
      notify.show("Failed to save", "error");

      console.log("Error saving user hints", err);
      this.setState({
        saveErrMsg: typeof err === "object" ? err.toString() : err,
      });
    }

    this.setState({ isSaving: false });
  }

  render() {
    const {
      // config,
      // defaultConfig,
      updatedAt,
      unitId,

      hintConfig,
      defaultHintConfig,
    } = this.state;

    if (hintConfig) {
      return (
        <>
          <AutoPilotConfigControl
            unitId={unitId}
            isUnitConfig={!!unitId}
            config={hintConfig}
            defaultConfig={defaultHintConfig}
            handleSaveConfig={this.handleSaveHintConfig}
            updatedAt={updatedAt}
            isHintConfig={true}
          ></AutoPilotConfigControl>

          <ModalWrapper
            isOpen={this.state.isReviewModalOpened}
            showCloseFooter={false}
            handleClose={this.handleCloseReviewModal}
            width="60%"
          >
            <div>
              <div className="mb-2 text-xl font-bold text-gray-700">
                Preview Hint Config
              </div>

              <div
                className="border overflow-y-auto"
                style={{ maxHeight: "320px" }}
              >
                <pre>{JSON.stringify(this.state.finalConfig, null, 2)}</pre>
              </div>

              <div className="mt-4 flex flex-row-reverse items-center">
                <div>
                  <button
                    type="button"
                    className="rounded shadow bg-blue-500 px-4 py-2 font-semibold text-white hover:bg-blue-700"
                    onClick={this.handleConfirmSaveConfig}
                  >
                    {this.state.isSaving ? "Saving..." : "Confirm save"}
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    disabled={this.state.isSaving}
                    className={`px-4 py-2 text-blue-700 ${
                      this.state.isSaving ? "cursor-not-allowed" : ""
                    }`}
                    onClick={this.handleCloseReviewModal}
                  >
                    Cancel
                  </button>
                </div>
                <div className="text-red-600">{this.state.saveErrMsg}</div>
              </div>
            </div>
          </ModalWrapper>
        </>
      );
    }

    return "";
  }
}

export default UnitHintAPConfigViewer;
