import React from "react";
import _ from "lodash";
import CustomSetTargetingFormatterV2 from "./CustomSetTargetingFormatterV2";
import CustomTargetingControlV2 from "./CustomTargetingControlV2";

class CustomSetTargetingControlV2 extends React.PureComponent {
  constructor(props) {
    super(props);
    // props:
    // targeting: [{keyId, valueIds}...]
    // handleValueChanged
    // const {
    //   keyId = "",
    //   valueIds = [],
    //   operator = OPERATOR.IS,
    // } = props.customValue;
    this.state = {
      setsOfCustom: props.targeting || [],
      currentSet: {},
      originalSet: {},

      isEditCardOpen: false,
      isOrDisabled: false,
    };

    this.handleAddNewSet = this.handleAddNewSet.bind(this);
    this.handleEditSet = this.handleEditSet.bind(this);
    this.handleRemoveSet = this.handleRemoveSet.bind(this);
    this.handleCancelNewSet = this.handleCancelNewSet.bind(this);

    this.handleOpenEditCard = this.handleOpenEditCard.bind(this);
    this.handleCloseEditCard = this.handleCloseEditCard.bind(this);
    this.handleIsEditing = this.handleIsEditing.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.targeting, this.props.targeting)) {
      const { targeting } = this.props;

      this.setState({
        setsOfCustom: targeting,
      });
    }
  }

  handleCancelNewSet() {
    // console.log("cancel", this.state.originalSet, this.state.setsOfCustom);
    const setsOfCustom = _.isEmpty(this.state.originalSet)
      ? this.state.setsOfCustom
      : [...this.state.setsOfCustom, this.state.originalSet];

    this.setState({
      currentSet: [],
      originalSet: [],
      setsOfCustom,
      isEditCardOpen: false,
    });

    this.props.handleIsEditing(false);
  }

  // v2!!
  handleAddNewSet({ newSet, outerIndex }) {
    console.log("Add new SEt", newSet, outerIndex);

    let setsOfCustom = [...this.state.setsOfCustom];
    // OR
    if (outerIndex === null) {
      setsOfCustom.push([newSet]);
    }
    // AND
    else {
      setsOfCustom[outerIndex].push(newSet);
    }

    this.setState({
      currentSet: [],
      originalSet: [],
      setsOfCustom,
      isEditCardOpen: false,
      isOrDisabled: false,
    });

    this.props.handleValueChanged(setsOfCustom);
    this.props.handleIsEditing(false);
  }

  handleEditSet({ newSet, index, outerIndex }) {
    console.log("edit set", outerIndex, index);

    let setsOfCustom = [...this.state.setsOfCustom];
    setsOfCustom[outerIndex][index] = newSet;

    this.setState({
      currentSet: [],
      originalSet: [],
      setsOfCustom,
      isEditCardOpen: false,
      isOrDisabled: false,
    });

    this.props.handleValueChanged(setsOfCustom);
    this.props.handleIsEditing(false);
  }

  handleRemoveSet({ index, outerIndex, isEdit }) {
    console.log("removing set", outerIndex, index);

    const setsOfCustom = _.cloneDeep(this.state.setsOfCustom);
    setsOfCustom[outerIndex].splice(index, 1);

    // remove empty array inside setsOfCustom
    _.remove(setsOfCustom, (set) => set.length === 0);

    this.setState({
      setsOfCustom,
    });
    this.props.handleValueChanged(setsOfCustom);
  }

  handleOpenEditCard() {
    this.setState({
      isEditCardOpen: true,
      isOrDisabled: true,
    });
    this.props.handleIsEditing(true);
  }

  handleCloseEditCard() {
    this.setState({
      isEditCardOpen: false,
      isOrDisabled: false,
    });
    this.props.handleIsEditing(false);
  }

  handleIsEditing(isEditing) {
    this.setState({
      isOrDisabled: isEditing,
    });
    this.props.handleIsEditing(isEditing);
  }

  render() {
    const { setsOfCustom, currentSet, isEditCardOpen, isOrDisabled } =
      this.state;
    const { networkId } = this.props;

    return (
      <>
        <div className="ml-4 text-sm">
          <div className="mb-1">
            {/* <div className="text-gray-800">
              Customs ({setsOfCustom.length}):{" "}
            </div> */}
            {setsOfCustom.length === 0 ? (
              "None"
            ) : (
              <CustomSetTargetingFormatterV2
                networkId={networkId}
                setsOfCustom={setsOfCustom}
                showActionButtons={true}
                handleRemoveSet={this.handleRemoveSet}
                handleEditSet={this.handleEditSet}
                handleAddSet={this.handleAddNewSet}
                includeActionButtons={true}
                handleIsEditing={this.handleIsEditing}
                isActionButtonsDisabled={isEditCardOpen}
              ></CustomSetTargetingFormatterV2>
            )}

            <div>
              {setsOfCustom.length === 0 ? (
                <button
                  type="button"
                  className="rounded shadow bg-blue-100 px-2 py-1 text-blue-600 hover:bg-blue-200"
                  onClick={this.handleOpenEditCard}
                >
                  Create new custom
                </button>
              ) : (
                <div>
                  <button
                    type="button"
                    className={`rounded shadow ${
                      isEditCardOpen || isOrDisabled
                        ? "cursor-not-allowed bg-gray-400 text-gray-800"
                        : "bg-blue-500 text-white hover:bg-blue-600"
                    }  px-2 py-1`}
                    onClick={this.handleOpenEditCard}
                    disabled={isEditCardOpen || isOrDisabled}
                  >
                    OR
                  </button>
                </div>
              )}
            </div>
          </div>

          {isEditCardOpen && (
            <CustomTargetingControlV2
              networkId={networkId}
              customValue={currentSet}
              handleAddNewSet={this.handleAddNewSet}
              handleCancelNewSet={this.handleCancelNewSet}
            ></CustomTargetingControlV2>
          )}
        </div>
      </>
    );
  }
}

export default CustomSetTargetingControlV2;
