import React from "react";
import _ from "lodash";
import ModalWrapper from "components/common/ModalWrapper";
import PricingDefaultConfigSelector from "./PricingDefaultConfigSelector";
import PricingDefaultConfigForm from "./PricingDefaultConfigForm";
import { PublisherAPI } from "apis";

const radioClass = "font-semibold text-sm text-gray-900 cursor-pointer";

class PricingDefaultConfigModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errMsg: null,

      createType: "IMPORT", // IMPORT or BLANK
      step: 1,
      importFromConfigId: null,
      importConfig: null,
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleImportConfigChanged = this.handleImportConfigChanged.bind(this);
    this.handleNextStep = this.handleNextStep.bind(this);
    this.handlePrevStep = this.handlePrevStep.bind(this);
    this.handleCreateTypeChanged = this.handleCreateTypeChanged.bind(this);

    this.handleCreatePricingConfig = this.handleCreatePricingConfig.bind(this);
  }

  handleCancel() {
    this.setState({
      errMsg: null,
    });
    this.props.handleCloseModal();
  }

  async handleCreatePricingConfig(params) {
    try {
      // const params = {
      // pricingType,
      // name,
      // config,
      // }
      const { result } = await PublisherAPI.createPricingDefaultConfig(params);
      console.log(result);
      // result = pricingDefaultConfig

      // close modal
      // autoselect this newly created config
      this.handleCancel();
      this.props.handleConfigChanged(result);
    } catch (err) {
      console.log(err);
      this.setState({
        errMsg: err,
      });
    }
  }

  handleChanged({ key, value }) {
    this.setState({
      [key]: value,
    });
  }

  handleCreateTypeChanged(createType) {
    this.setState({ createType, importConfig: null, importFromConfigId: null });
  }

  handleImportConfigChanged(pricingConfig) {
    const { pricingDefaultConfigId } = pricingConfig;
    console.log(pricingConfig);
    this.setState({
      importFromConfigId: pricingDefaultConfigId,
      importConfig: pricingConfig,
    });
  }

  handleNextStep() {
    // const { pricingDefaultConfigId } = pricingConfig;
    this.setState({ step: 2 });
  }

  handlePrevStep() {
    this.setState({ step: 1, importFromConfigId: null, importConfig: null });
  }

  render() {
    const { isModalOpen } = this.props;
    const { importFromConfigId, errMsg, importConfig, step, createType } =
      this.state;

    // const isInvalid = !suggestion;
    const isInvalid = false;

    const isStep1Valid =
      createType === "BLANK" ||
      (createType === "IMPORT" && importFromConfigId !== null);

    const Step1 = (
      <>
        <div className="rounded border bg-gray-100 p-4">
          <div className="mb-4">
            {/* <label className={labelClass}> Type*</label> */}

            <div className="flex flex-col gap-4 py-1">
              <div>
                <label htmlFor={`create_by_import`} className={radioClass}>
                  <input
                    id={`create_by_import`}
                    type="radio"
                    name={`create_by`}
                    value={"IMPORT"}
                    checked={createType === "IMPORT"}
                    onChange={(e) => this.handleCreateTypeChanged("IMPORT")}
                  />{" "}
                  Import from existing config
                </label>

                {createType === "IMPORT" && (
                  <PricingDefaultConfigSelector
                    selectedValue={importFromConfigId}
                    handleOnChange={this.handleImportConfigChanged}
                  ></PricingDefaultConfigSelector>
                )}
              </div>
              <label htmlFor={`create_by_blank`} className={radioClass}>
                <input
                  id={`create_by_blank`}
                  type="radio"
                  name={`create_by`}
                  value={"BLANK"}
                  checked={createType === "BLANK"}
                  onChange={(e) => this.handleCreateTypeChanged("BLANK")}
                />{" "}
                Create from empty config
              </label>
            </div>

            <div className="mt-2 flex justify-end">
              <button
                type="button"
                disabled={false}
                className="px-4 py-2 text-blue-700"
                onClick={this.handleCancel}
              >
                Cancel
              </button>
              <button
                type="button"
                className={`px-4 py-2 ${
                  !isStep1Valid
                    ? "bg-gray-400 text-gray-800"
                    : "bg-blue-500 text-white hover:bg-blue-700"
                }  rounded shadow font-semibold`}
                onClick={this.handleNextStep}
                disabled={!isStep1Valid}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </>
    );

    return (
      <ModalWrapper
        isOpen={isModalOpen}
        showCloseFooter={false}
        handleClose={this.handleCloseModal}
        width="80%"
      >
        <div className="border-b -mx-6 mb-4 -mt-5 py-4 px-6 text-xl font-bold">
          Create new pricing config
        </div>

        <div
          style={{ minHeight: "300px", maxHeight: "600px", overflow: "auto" }}
        >
          {step === 1 && Step1}

          {step === 2 && (
            <div className="my-2">
              <PricingDefaultConfigForm
                config={_.cloneDeep(importConfig)}
                handlePrevStep={this.handlePrevStep}
                handleCreatePricingConfig={this.handleCreatePricingConfig}
                handleCancel={this.handleCancel}
                apiErrorMsg={errMsg}
              ></PricingDefaultConfigForm>
            </div>
          )}
        </div>
      </ModalWrapper>
    );
  }
}

export default PricingDefaultConfigModal;
