import React from "react";
import { FiExternalLink } from "react-icons/fi";
import ReactTooltip from "react-tooltip";
import Tooltip from "react-simple-tooltip";

class OpenLinkInNewWindowWrapper extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  goTo(href) {
    window.open(href);
  }

  render() {
    const { href, tooltipText } = this.props;
    return (
      <Tooltip
        placement="top"
        background="rgba(0, 0, 0, 0.9)"
        content={
          <div className="whitespace-no-wrap text-center text-xs">
            {tooltipText}
          </div>
        }
        padding={8}
        radius={4}
      >
        <button
          type="button"
          className="flex items-center px-2 hover:text-blue-600"
          onClick={() => this.goTo(href)}
        >
          <span data-tip data-for={tooltipText}>
            <FiExternalLink></FiExternalLink>
          </span>
        </button>
      </Tooltip>
    );
  }
}

export default OpenLinkInNewWindowWrapper;
