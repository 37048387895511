import React from "react";
// import _ from "lodash";
import ConfigValueReadonly from "./ConfigValueReadonly";
import APConfigItemForm from "./APConfigItemForm";

class AutoPilotConfigRadio extends React.PureComponent {
  constructor(props) {
    super(props);

    const { isUseDefault } = props;

    this.state = {
      radioValue: !isUseDefault ? "OVERWRITE" : "DEFAULT",
    };

    this.handleChangeRadio = this.handleChangeRadio.bind(this);
  }

  handleChangeRadio(radioValue) {
    this.setState({
      radioValue,
    });

    this.props.handleIsUseDefaultChanged(radioValue === "DEFAULT");
  }

  render() {
    const {
      unitId,
      networkId,
      configKey,
      defaultValue,
      configValue,
      valueType,
      unit,
      valueSchema,
    } = this.props;
    const { radioValue } = this.state;

    return (
      <>
        <div className="text-sm font-semibold text-gray-800">
          <div className="mb-1">
            <label htmlFor="radio_default" className="cursor-pointer">
              <input
                id="radio_default"
                type="radio"
                name={`${configKey}_radio`}
                value="DEFAULT"
                checked={radioValue === "DEFAULT"}
                onChange={() => this.handleChangeRadio("DEFAULT")}
              />{" "}
              Use default value:{" "}
              <span className="text-gray-600">
                <ConfigValueReadonly
                  valueType={valueType}
                  configValue={defaultValue}
                ></ConfigValueReadonly>{" "}
                {unit && <span className="font-normal">({unit})</span>}
              </span>
            </label>
          </div>

          <div>
            <label htmlFor="radio_overwrite" className="cursor-pointer">
              <input
                id="radio_overwrite"
                type="radio"
                name={`${configKey}_radio`}
                value="OVERWRITE"
                checked={radioValue === "OVERWRITE"}
                onChange={() => this.handleChangeRadio("OVERWRITE")}
              />{" "}
              Overwrite
            </label>
          </div>
        </div>

        <div>
          {radioValue === "OVERWRITE" && (
            <APConfigItemForm
              networkId={networkId}
              unitId={unitId}
              valueType={valueType}
              configValue={configValue}
              defaultValue={defaultValue}
              valueSchema={valueSchema}
              handleNewValueChanged={this.props.handleNewValueChanged}
              handleHasError={this.props.handleHasError}
            ></APConfigItemForm>
          )}
        </div>
      </>
    );
  }
}

export default AutoPilotConfigRadio;
