export const INVENTORY_FORMATS = [
  "Anchor",
  "App open",
  "Banner",
  "In-stream audio",
  "In-stream video",
  "Interstitial",
  "Native custom rendering",
  "Rewarded",
  "Rewarded interstitial",
];
