import React from "react";
import { FiExternalLink } from "react-icons/fi";

class OpenLinkInNewWindowBtn extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  goTo(href) {
    window.open(href);
  }

  render() {
    const { href } = this.props;
    return (
      <button
        type="button"
        className="border rounded hover:shadow flex items-center border-blue-300 bg-gray-100 px-1 text-xs text-blue-700"
        onClick={() => this.goTo(href)}
      >
        <span>
          <FiExternalLink></FiExternalLink>
        </span>
      </button>
    );
  }
}

export default OpenLinkInNewWindowBtn;
