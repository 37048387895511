import React from "react";
import SegmentTargeting from "../observer/SegmentTargeting";

class TargetingViewTable extends React.PureComponent {
  render() {
    const { targeting } = this.props;

    return (
      <>
        <div
          className="bg-gray-100 overflow-y-auto"
          style={{ maxHeight: "360px" }}
        >
          <table className="table w-full text-sm border">
            <thead className="bg-gray-200 text-gray-800">
              <tr>
                <th colSpan="10" className="text-center p-2 border">
                  Targeting
                </th>
              </tr>
              <tr>
                <th className="text-center border text-gray-900 font-semibold p-2">
                  UPR Segment ID
                </th>
                <th className="text-center border text-gray-900 font-semibold p-2">
                  Inventory Registry
                </th>
                <th className="text-center border text-gray-900 font-semibold p-2">
                  Country
                </th>
                <th className="text-center border text-gray-900 font-semibold p-2">
                  OS
                </th>
                <th className="text-center border text-gray-900 font-semibold p-2">
                  Device Category
                </th>
                <th className="text-center border text-gray-900 font-semibold p-2">
                  Hour
                </th>
                <th className="text-center border text-gray-900 font-semibold p-2">
                  Browser
                </th>
                <th className="text-center border text-gray-900 font-semibold p-2">
                  Sizes
                </th>
                <th className="text-center border text-gray-900 font-semibold p-2">
                  Relevant Requested Sizes
                </th>
                <th className="text-center border text-gray-900 font-semibold p-2">
                  Customs
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="p-2 border text-left break-words align-top">
                  <SegmentTargeting
                    targetKey="upr_segment_id"
                    target={targeting.upr_segment_id}
                  ></SegmentTargeting>
                </td>
                <td className="p-2 border text-left break-words align-top">
                  <SegmentTargeting
                    targetKey="inventory_registry"
                    target={targeting.inventory_registry}
                  ></SegmentTargeting>
                </td>
                <td className="p-2 border text-left break-words align-top">
                  <SegmentTargeting
                    targetKey="country"
                    target={targeting.country}
                  ></SegmentTargeting>
                </td>
                <td className="p-2 border text-left break-words align-top">
                  <SegmentTargeting
                    targetKey="os"
                    target={targeting.os}
                  ></SegmentTargeting>
                </td>
                <td className="p-2 border text-left break-words align-top">
                  <SegmentTargeting
                    targetKey="device_category"
                    target={targeting.device_category}
                  ></SegmentTargeting>
                </td>
                <td className="p-2 border text-left break-words align-top">
                  <SegmentTargeting
                    targetKey="hour"
                    target={targeting.hour}
                  ></SegmentTargeting>
                </td>
                <td className="p-2 border text-left break-words align-top">
                  <SegmentTargeting
                    targetKey="browser"
                    target={targeting.browser}
                  ></SegmentTargeting>
                </td>
                <td className="p-2 border text-left break-words align-top">
                  <SegmentTargeting
                    targetKey="sizes"
                    target={targeting.sizes}
                  ></SegmentTargeting>
                </td>
                <td className="p-2 border text-left break-words align-top">
                  <SegmentTargeting
                    targetKey="relevant_requested_sizes"
                    target={targeting.relevant_requested_sizes}
                  ></SegmentTargeting>
                </td>
                <td className="p-2 border text-left break-words align-top">
                  {targeting.customs ? (
                    <SegmentTargeting
                      targetKey="customs"
                      target={targeting.customs}
                    ></SegmentTargeting>
                  ) : (
                    <SegmentTargeting
                      targetKey="custom"
                      target={targeting.custom}
                    ></SegmentTargeting>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default TargetingViewTable;
