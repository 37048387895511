import React from "react";
import _ from "lodash";
import { NetworkAPI } from "apis";
import AsyncSelect from "react-select/async";
import Checkbox from "components/common/Checkbox";

const UNCLASSIFIED_OPTION = {
  // Include unclassified advertisers or brands
  advertiserId: "0",
  brandIds: [],
};

class AdvertiserAndBrandsSelector extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      // options,
      selectedValue: [],
      isUnclassifiedChecked: false,

      isLoading: false,
      errMsg: null,
    };

    this.handleChange = this.handleChange.bind(this);
    // this.handleInputChange = this.handleInputChange.bind(this);
    this.debounceLoadOptions = this.debounceLoadOptions.bind(this);
    this._loadOptions = this._loadOptions.bind(this);
    this.handleIsUnclassifiedChecked =
      this.handleIsUnclassifiedChecked.bind(this);
  }

  async componentDidMount() {
    // fill in selected value
    // 1. query with ids
    // 2. fill in selected values
    if (!this.props.networkId) return;

    try {
      let ids = [];
      let isUnclassifiedChecked = false;

      if (this.props.targeting) {
        ids = _.reduce(
          this.props.targeting,
          (result, item) => {
            // unclassified (show in checkbox, not in selector)
            if (item.advertiserId === "0") {
              isUnclassifiedChecked = true;
              return result;
            }

            if (item.brandIds.length > 0) {
              // result = _.concat(result, item.brandIds);
              result = [...result, ...item.brandIds];
            } else {
              result.push(item.advertiserId);
            }

            return result;
          },
          []
        );
        this.setState({ isLoading: true });

        if (ids.length !== 0) {
          const searchParams = {
            operation: "listByIds",
            ids,
          };
          const params = { networkId: this.props.networkId, searchParams };
          const r = await NetworkAPI.getNetworkAdvertiserAndBrands(params);
          if (r.status === "OK") {
            const options = this._transformDefaultValueToOptions(
              r.results,
              this.props.targeting
            );
            this.setState({ selectedValue: options });
          }
        }
        this.setState({
          isUnclassifiedChecked,
          isLoading: false,
          errMsg: null,
        });
      }
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        errMsg: typeof err === "object" ? err.toString() : err,
      });
    }
  }

  // always include advertiser when querying for selection
  _transformResultsToOptions(results) {
    const options = _.reduce(
      results,
      (result, r) => {
        const hasBrands = r.brands.length > 0;

        // always include advertiser when querying for selection
        // dont need to include advertiser when showing already selected items
        const label = `[A] ${r.advertiserName}`;
        const option = {
          label,
          type: "ADVERTISER",
          // key: "advertiserId",
          advertiserId: r.advertiserId,
          value: r.advertiserId,
        };

        result.push(option);

        if (hasBrands) {
          _.forEach(r.brands, (b) => {
            const label = `[A] ${r.advertiserName} [B] ${b.brandName}`;
            const option = {
              type: "BRAND",
              // key: "brandId",
              advertiserId: r.advertiserId,
              value: b.brandId,
              label,
            };
            result.push(option);
          });
        }

        return result;
      },
      []
    );
    return _.sortBy(options, "label");
  }

  // dont need to include advertiser when showing already selected items
  // match results with targeting, because we might only target advertiser not brands!
  _transformDefaultValueToOptions(results, targeting) {
    let options = [];

    const advertiserMap = _.keyBy(results, "advertiserId");
    _.forEach(targeting, (t) => {
      // t: {advertiserId, brandIds: []}
      if (t.advertiserId === "0") return; // ignore

      const r = advertiserMap[t.advertiserId];
      if (!r) {
        const label = `[A] ${t.advertiserId}`;
        const option = {
          label,
          type: "ADVERTISER",
          // key: "advertiserId",
          advertiserId: t.advertiserId,
          value: t.advertiserId,
        };

        return options.push(option);
      }

      // has no brands
      if (t.brandIds.length === 0) {
        const label = `[A] ${r.advertiserName}`;
        const option = {
          label,
          type: "ADVERTISER",
          // key: "advertiserId",
          advertiserId: r.advertiserId,
          value: r.advertiserId,
        };

        options.push(option);
      }
      // has brands
      else {
        const brandsMap = _.keyBy(r.brands, "brandId");
        _.forEach(t.brandIds, (bid) => {
          const b = brandsMap[bid];
          const label = `[A] ${r.advertiserName} [B] ${b.brandName}`;
          const option = {
            type: "BRAND",
            // key: "brandId",
            advertiserId: r.advertiserId,
            value: b.brandId,
            label,
          };
          options.push(option);
        });
      }
    });
    return _.sortBy(options, "label");
  }

  debounceLoadOptions = _.debounce(this._loadOptions, 300);

  _loadOptions(inputValue, callback) {
    const searchParams = {
      operation: "listByNames",
      names: [inputValue],
      exactMatch: false,
    };
    const params = { networkId: this.props.networkId, searchParams };
    NetworkAPI.getNetworkAdvertiserAndBrands(params).then((response) => {
      if (response.status === "OK") {
        const options = this._transformResultsToOptions(response.results);
        return callback(options);
      }
    });
  }

  handleChange(options) {
    this.setState({
      selectedValue: options,
    });

    this.props.handleTargetingChanged(options);
  }

  handleIsUnclassifiedChecked(isChecked) {
    this.setState({ isUnclassifiedChecked: isChecked });
    if (isChecked) {
      const newOptions = [...this.state.selectedValue, UNCLASSIFIED_OPTION];
      this.props.handleTargetingChanged(newOptions);
    } else {
      const newOptions = _.filter(this.state.selectedValue, (item) => {
        return item.advertiserId !== "0";
      });
      this.props.handleTargetingChanged(newOptions);
    }
  }

  render() {
    const { options, selectedValue, isLoading, errMsg, isUnclassifiedChecked } =
      this.state;
    const { isDisabled = false } = this.props;

    // const styles = {
    //   multiValue: (base, state) => {
    //     return state.data.isNotInOptions
    //       ? { ...base, backgroundColor: "gray" }
    //       : base;
    //   },
    //   multiValueLabel: (base, state) => {
    //     return state.data.isNotInOptions
    //       ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
    //       : base;
    //   },
    // };

    if (isLoading) {
      return <div className="text-sm italic text-gray-700">Loading...</div>;
    }

    return (
      <>
        {isDisabled ? (
          <div>
            {!selectedValue || selectedValue.length === 0 ? (
              "-"
            ) : (
              <AsyncSelect
                cacheOptions={true}
                loadOptions={this.debounceLoadOptions}
                defaultOptions={isDisabled ? selectedValue : true} // true: loads all options on init
                isMulti={true}
                value={selectedValue}
                onChange={this.handleChange}
                isDisabled={isDisabled}
              ></AsyncSelect>
            )}

            {isUnclassifiedChecked && (
              <div className="rounded mt-2 bg-gray-200 p-2">
                <label className="flex cursor-pointer items-center gap-1">
                  <Checkbox
                    isDisabled={isDisabled}
                    isChecked={isUnclassifiedChecked}
                    onChange={this.handleIsUnclassifiedChecked}
                  ></Checkbox>
                  <span className="ml-1 align-middle text-sm font-semibold text-gray-700 hover:text-gray-800">
                    Include unclassified advertisers or brands
                  </span>
                </label>
              </div>
            )}
          </div>
        ) : (
          <>
            <AsyncSelect
              cacheOptions={true}
              loadOptions={this.debounceLoadOptions}
              defaultOptions={isDisabled ? selectedValue : true} // true: loads all options on init
              isMulti={true}
              value={selectedValue}
              onChange={this.handleChange}
              isDisabled={isDisabled}
            ></AsyncSelect>
            <div className="rounded mt-2 bg-gray-200 p-2">
              <label className="flex cursor-pointer items-center gap-1">
                <Checkbox
                  isDisabled={isDisabled}
                  isChecked={isUnclassifiedChecked}
                  onChange={this.handleIsUnclassifiedChecked}
                ></Checkbox>
                <span className="ml-1 align-middle text-sm font-semibold text-gray-700 hover:text-gray-800">
                  Include unclassified advertisers or brands
                </span>
              </label>
              <div className="text-xs font-normal text-gray-700">
                An advertiser (and related brands) is considered "unclassified"
                if it doesn't appear on our list of advertisers available for
                setting pricing rules. Google Ads small businesses are also
                considered "unclassified."
              </div>
            </div>
          </>
        )}

        {errMsg && <div className="text-xs text-red-600">{errMsg}</div>}
      </>
    );
  }
}

export default AdvertiserAndBrandsSelector;
