import React from "react";
import ReactTooltip from "react-tooltip";
import { networkStatus } from "../../constants/NetworkStatus";
import { FiX } from "react-icons/fi";
import { MdPause, MdPlayArrow } from "react-icons/md";

class NetworkStatusIndicator extends React.PureComponent {
  render() {
    const { status } = this.props;

    let indicatorClass = null;
    let statusName = "";
    let icon = <span></span>;

    switch (status) {
      case networkStatus.RUNNING:
      case "RUNNING": {
        indicatorClass = "bg-green-300 border border-green-200";
        statusName = "Running";
        icon = <MdPlayArrow></MdPlayArrow>;
        break;
      }

      case networkStatus.PAUSED:
      case "PAUSED": {
        indicatorClass = "bg-orange-300 border border-orange-200";
        statusName = "Paused";
        icon = <MdPause></MdPause>;
        break;
      }

      case networkStatus.TERMINATED:
      case "TERMINATED":
      case "BUSINESS_TERMINATED": {
        indicatorClass = "bg-red-300 border border-red-200";
        statusName = "Terminated";
        icon = <FiX></FiX>;
        break;
      }

      default: {
        // nothing
      }
    }

    if (indicatorClass) {
      return (
        <>
          <div
            className={`rounded-full p-1 text-sm ${indicatorClass}`}
            data-tip
            data-for={`network-status-${status}`}
          >
            {icon}
          </div>

          <ReactTooltip
            id={`network-status-${status}`}
            type="dark"
            effect="solid"
          >
            {statusName}
          </ReactTooltip>
        </>
      );
    } else {
      return "";
    }
  }
}

export default NetworkStatusIndicator;
