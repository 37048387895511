import React from "react";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import { AnatomyAPI } from "apis";
import LoadingUI from "../common/LoadingUI";
import ObserverOverview from "./ObserverOverview";
import {
  recalculateData,
  transformObserverData,
  getGoogleEligibleDemandTypes,
} from "helpers/Observer";

const PRESETS = {
  NETWORK_BILLABLE: "Network Billable", // default
  GOOGLE_ELIGIBLE: "Google Eligible",
  CUSTOM: "Custom",
};
class ObserverViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      originalData: null,
      data: null,
      errorMsg: null,
      unitId: props.unitId,
      selectedDateRange: 3,
      useNewRequest: false,

      // show rev, imp of certain demand types
      demandTypePreset: null,
      billableDemandTypes: null, // get from api
      demandTypes: null,
    };

    this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
    this.handleForceRefresh = this.handleForceRefresh.bind(this);

    this.handleDemandTypesChanged = this.handleDemandTypesChanged.bind(this);

    this.handleReqCalcChanged = this.handleReqCalcChanged.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.unitId !== this.props.unitId) {
      this.setState({ unitId: this.props.unitId });
    }
  }

  async componentDidMount() {
    this.setState({ isLoading: true });

    let data = null;

    try {
      if (this.props.unitId) {
        document.title = `A:${this.props.unitId} | Anatomy`;

        const urlQueryParams = queryString.parse(this.props.location.search);
        const { useNewRequest } = urlQueryParams;
        if (useNewRequest) {
          const isForceRefresh = false;
          data = await this.queryLatestData(
            this.state.selectedDateRange,
            isForceRefresh,
            useNewRequest
          );
          this.setState({ useNewRequest });
        } else {
          data = await this.queryLatestData(this.state.selectedDateRange);
        }
      } else {
        // No
      }

      if (data) {
        const billableDemandTypes = data.networkSetting.billableDemandTypes;

        let demandTypes = null;
        const defaultPreset = PRESETS.NETWORK_BILLABLE;
        if (defaultPreset === PRESETS.NETWORK_BILLABLE) {
          demandTypes = billableDemandTypes;
        } else if (defaultPreset === PRESETS.GOOGLE_ELIGIBLE) {
          demandTypes = getGoogleEligibleDemandTypes();
        } else {
          demandTypes = [];
        }

        data = transformObserverData(data, {
          demandTypePreset: defaultPreset,
          demandTypes,
        });

        this.setState({
          data,
          originalData: data,
          billableDemandTypes,

          demandTypes,
          demandTypePreset: defaultPreset,
        });
      } else {
        this.setState({ errorMsg: "No Data" });
      }
    } catch (err) {
      this.setState({
        errorMsg: typeof err === "object" ? err.toString() : err,
      });
    }

    this.setState({ isLoading: false });
  }

  handleDemandTypesChanged(demandTypePreset, demandTypes) {
    if (demandTypePreset === PRESETS.CUSTOM) {
      if (!demandTypes || demandTypes.length === 0) {
        return;
      }
    }
    if (demandTypePreset === PRESETS.GOOGLE_ELIGIBLE) {
      demandTypes = getGoogleEligibleDemandTypes();
    } else if (demandTypePreset === PRESETS.NETWORK_BILLABLE) {
      demandTypes = this.state.billableDemandTypes;
    }
    this.setState({ isLoading: true, data: null });

    setTimeout(() => {
      const data = recalculateData(this.state.originalData, {
        demandTypePreset,
        demandTypes,
      });
      this.setState({ demandTypePreset, demandTypes, data, isLoading: false });
    }, 300);
  }

  async handleForceRefresh() {
    this.setState({ isLoading: true, data: null });

    try {
      const isForceRefresh = true;

      let data = await this.queryLatestData(
        this.state.selectedDateRange,
        isForceRefresh,
        this.state.useNewRequest
      );
      const billableDemandTypes = data.networkSetting.billableDemandTypes;

      data = transformObserverData(data, {
        demandTypePreset: this.state.demandTypePreset,
        demandTypes: this.state.demandTypes,
      });

      this.setState({ originalData: data, data, billableDemandTypes });
    } catch (err) {
      console.log("Error querying observer data", err);
    }

    this.setState({ isLoading: false });
  }

  // asdf
  handleReqCalcChanged(useNewRequest) {
    const unitId = this.props.unitId;
    window.open(
      `${window.location.origin}/#/anatomy/${unitId}?useNewRequest=${useNewRequest}`
    );
  }

  async queryLatestData(dateRange, isForceRefresh, useNewRequest) {
    const resultData = await AnatomyAPI.getLatestObserverData(
      this.props.unitId,
      dateRange,
      isForceRefresh,
      useNewRequest
    );
    return resultData;
  }

  async handleDateRangeChange(dateRange) {
    if (dateRange === this.state.selectedDateRange) {
      return;
    }

    this.setState({ isLoading: true, data: null });
    try {
      this.setState({ selectedDateRange: dateRange });
      let data = await this.queryLatestData(
        dateRange,
        this.state.isForceRefresh,
        this.state.useNewRequest
      );

      if (data) {
        const billableDemandTypes = data.networkSetting.billableDemandTypes;
        data = transformObserverData(data, {
          demandTypePreset: this.state.demandTypePreset,
          demandTypes: this.state.demandTypes,
        });
        this.setState({ originalData: data, data, billableDemandTypes });
      } else {
        this.setState({ errorMsg: "No Data" });
      }
    } catch (err) {
      this.setState({
        errorMsg: typeof err === "object" ? err.toString() : err,
      });
    }

    this.setState({ isLoading: false });
  }

  render() {
    const {
      data,
      isLoading,
      errorMsg,
      selectedDateRange,
      demandTypePreset,
      demandTypes,
      billableDemandTypes,
      useNewRequest,
    } = this.state;

    return (
      <>
        {isLoading && <LoadingUI></LoadingUI>}

        {data && (
          <>
            {this.state.unitId ? (
              <ObserverOverview
                data={data}
                selectedDateRange={selectedDateRange}
                onDateRangeChange={this.handleDateRangeChange}
                handleForceRefresh={this.handleForceRefresh}
                demandTypePreset={demandTypePreset}
                billableDemandTypes={billableDemandTypes}
                demandTypes={demandTypes}
                handleDemandTypesChanged={this.handleDemandTypesChanged}
                useNewRequest={useNewRequest}
                handleReqCalcChanged={this.handleReqCalcChanged}
              ></ObserverOverview>
            ) : (
              "Missing Unit ID"
            )}
          </>
        )}

        {errorMsg && <div className="text-white">{errorMsg}</div>}
      </>
    );
  }
}

export default withRouter(ObserverViewer);
