export const RECIPE_STATUS = {
  RUNNING: 1,
  PAUSE: 2,
  ARCHIVE: 3,
};

export const PROPERTIES = {
  unit_state: {
    key: "unit_state",
    description: "Unit State",
    availableOps: ["eq", "neq", "in", "con", "ncon"],
  },
  unit_status: {
    key: "unit_status",
    description: "Unit Status",
    availableOps: ["eq", "neq", "in", "con", "ncon"],
  },
  unit_age: {
    key: "unit_age",
    description: "How long this unit had been created (in days)?",
    availableOps: ["eq", "gt", "gte", "lt", "lte"],
  },
  unit_state_age: {
    key: "unit_state_age",
    description: "How long this unit stays in current state (in days)?",
    availableOps: ["eq", "gt", "gte", "lt", "lte"],
  },
  // AUTOPILOT_CONFIG
};

export const OPERATIONS = {
  eq: {
    key: "eq",
    description: "equal",
  },
  neq: {
    key: "neq",
    description: "not equal",
  },
  in: {
    key: "in",
    description: "in",
    isMulti: true,
  },
  gt: {
    key: "gt",
    description: "greater than",
  },
  gte: {
    key: "gte",
    description: "greater or equal than",
  },
  lt: {
    key: "lt",
    description: "less than",
  },
  lte: {
    key: "lte",
    description: "less or equal than",
  },
  con: {
    key: "con",
    description: "contains",
    isMulti: true,
  },
  ncon: {
    key: "ncon",
    description: "not contain",
    isMulti: true,
  },
};

export const CHECK_STATUS = {
  FAIL: "FAIL",
  PASS: "PASS",
  NEED_MORE_DATA: "NEED_MORE_DATA",
  UNSTABLE: "UNSTABLE",
  WEAK_PASS: "WEAK_PASS",
  WEAK_FAIL: "WEAK_FAIL",
  WEAK_UNSTABLE: "WEAK_UNSTABLE",
};

export const ACTION_CATEGORIES = {
  UNIT_STATE: {
    key: "UNIT_STATE",
    description: "Set unit.state to",
  },
  UNIT_STATUS: {
    key: "UNIT_STATUS",
    description: "Set unit.status to",
  },
  ADD_LABEL: {
    key: "ADD_LABEL",
    description: "Add label",
  },
  REMOVE_LABEL: {
    key: "REMOVE_LABEL",
    description: "Remove label",
  },
  ALERT: {
    key: "ALERT",
    description: "Alert",
  },
  // AUTOPILOT_CONFIG
};
