import React from "react";
import _ from "lodash";
import { BillingAPI } from "apis";
import BankAccountSelector from "../../BankAccountSelector";
import ModalWrapper from "components/common/ModalWrapper";

const labelClass = "font-semibold text-gray-800";
const radioClass = "font-semibold text-sm text-gray-900 cursor-pointer";

class BillingProfileFormModal extends React.PureComponent {
  constructor(props) {
    super(props);

    // Create Billing Profile
    // publisherId
    // method: WIRED
    // profile: JSON?
    // bankAccountId
    const { publisherId, billingProfile = {} } = this.props;
    const { method = "WIRED", bankAccountId = null } =
      billingProfile.bankAccount || {};

    this.state = {
      isSaving: false,
      saveErrorMsg: null,

      method,
      bankAccountId,
    };

    this.handleMethodChanged = this.handleMethodChanged.bind(this);
    this.handleBankAccountChanged = this.handleBankAccountChanged.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
  }

  handleMethodChanged(method) {
    this.setState({ method });
  }

  handleBankAccountChanged(bankAccount) {
    this.setState({
      bankAccountId: bankAccount.bankAccountId,
    });
  }

  handleCloseModal({ newProfileId }) {
    this.setState({
      isLoading: false,
      errorMsg: null,
    });
    console.log(newProfileId);
    this.props.handleCloseModal({ newProfileId });
  }

  async handleSubmit() {
    this.setState({ isSaving: true });
    try {
      const { publisherId } = this.props;
      const { method, bankAccountId } = this.state;
      if (!bankAccountId) {
        return this.setState({
          isSaving: false,
          errMsg: "Missing Bank Account",
        });
      }

      const params = {
        method,
        bankAccountId,
      };
      const r = await BillingAPI.createBillingProfile({ publisherId, params });
      console.log(r);
      this.handleCloseModal({ newProfileId: r.profileId });
    } catch (err) {
      console.log(err);
      this.setState({ errorMsg: null });
    }

    this.setState({ isSaving: false });
  }

  isFormValid() {
    const { method, bankAccountId } = this.state;
    console.log(method, bankAccountId);
    if (method && bankAccountId) return true;
    return false;
  }

  render() {
    const { isOpenModal, network } = this.props;
    const { isSaving, saveErrorMsg, method, bankAccountId } = this.state;
    const isValid = this.isFormValid();

    return (
      <ModalWrapper
        isOpen={isOpenModal}
        showCloseFooter={false}
        handleClose={this.handleCloseModal}
        width="50%"
      >
        <div>
          <div className="border-b -mx-6 mb-4 px-6 pb-2 text-lg font-bold">
            Create Billing Profile
          </div>

          <form
            autoComplete="false"
            onSubmit={(e) => {
              e.preventDefault();
              return this.handleSubmit();
            }}
          >
            <div>
              <div className="my-2">
                <label className={labelClass}>Method</label>

                <div className="flex items-center gap-4 py-1">
                  <label htmlFor={`method_wired`} className={radioClass}>
                    <input
                      id={`method_wired`}
                      type="radio"
                      name={`method`}
                      value={"WIRED"}
                      checked={method === "WIRED"}
                      onChange={this.handleMethodChanged}
                    />{" "}
                    WIRED
                  </label>
                </div>
              </div>

              <div className="my-2">
                <label className={labelClass}>Bank Account</label>
                {/* add height to selector so that the dropdown menu is still inside the modal */}
                <div className="w-full" style={{ minHeight: "120px" }}>
                  <BankAccountSelector
                    selectedValue={bankAccountId}
                    handleOnChange={this.handleBankAccountChanged}
                  ></BankAccountSelector>
                </div>
              </div>
            </div>

            <div className="mt-8 flex items-center justify-end">
              <div className="text-red-700">{saveErrorMsg}</div>
              <button
                className="rounded flex-shrink-0 border-4 border-transparent py-1 px-2 text-sm text-blue-500 hover:text-blue-800"
                type="button"
                onClick={this.handleCloseModal}
              >
                Cancel
              </button>

              <button
                className={`rounded flex-shrink-0 py-2 px-4 text-sm font-semibold  ${
                  isValid
                    ? " bg-blue-500 text-white hover:bg-blue-800"
                    : " cursor-not-allowed bg-gray-300 text-gray-900"
                }`}
                type="submit"
                disabled={!isValid || isSaving}
              >
                {isSaving ? "Saving..." : "Save billing profile"}
              </button>
            </div>
          </form>
        </div>
      </ModalWrapper>
    );
  }
}

export default BillingProfileFormModal;
