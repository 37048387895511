import PropTypes from "prop-types";
import React from "react";

export default class SparklinesLine extends React.Component {
  static propTypes = {
    color: PropTypes.string,
    style: PropTypes.object,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
  };

  static defaultProps = {
    style: {},
    onMouseMove: () => {},
  };

  render() {
    const {
      data,
      points,
      height,
      margin,
      color,
      style,
      onMouseMove,
      onMouseEnter,
    } = this.props;

    const linePoints = points
      .map((p) => [p.x, p.y])
      .reduce((a, b) => a.concat(b));

    const closePolyPoints = [
      points[points.length - 1].x,
      height - margin,
      margin,
      height - margin,
      margin,
      points[0].y,
    ];

    const fillPoints = linePoints.concat(closePolyPoints);

    const lineStyle = {
      stroke: color || style.stroke || "slategray",
      strokeWidth: style.strokeWidth || "1",
      strokeLinejoin: style.strokeLinejoin || "round",
      strokeLinecap: style.strokeLinecap || "round",
      fill: "none",
    };
    const fillStyle = {
      stroke: style.stroke || "none",
      strokeWidth: "0",
      fillOpacity: style.fillOpacity || ".1",
      fill: style.fill || color || "slategray",
      pointerEvents: "auto",
    };

    const pointStyle = {
      stroke: (style.point && style.point.stroke) || "none",
      strokeWidth: "0",
      fillOpacity: (style.point && style.point.fillOpacity) || ".1",
      fill: (style.point && style.point.fill) || color || "slategray",
      // pointerEvents: "auto",
    };

    const tooltips = points.map((p, i) => {
      return (
        <circle
          key={i}
          cx={p.x}
          cy={p.y}
          r={(style.point && style.point.r) || "2"}
          style={pointStyle}
          // onMouseEnter={e => onMouseMove("enter", data[i], p)}
          onMouseEnter={onMouseEnter && onMouseEnter.bind(this, p, i)}
          // onClick={(e) => onMouseMove("click", data[i], p)}
        />
      );
    });

    return (
      <g>
        {tooltips}
        <polyline points={fillPoints.join(" ")} style={fillStyle} />
        <polyline points={linePoints.join(" ")} style={lineStyle} />
      </g>
    );
  }
}
