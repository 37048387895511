import React from "react";
import _ from "lodash";

const ActionPretty = (props) => {
  const { item } = props;
  const { cat, value } = item;
  let action = "";

  if (cat === "UNIT_STATE") {
    action = (
      <span>
        Set unit.state to{" "}
        <LabelTextRed value={_getUnitStateString(value)}></LabelTextRed>
      </span>
    );
  } else if (cat === "UNIT_STATUS") {
    action = (
      <span>
        Set unit.status to{" "}
        <LabelTextRed value={_getUnitStatusString(value)}></LabelTextRed>
      </span>
    );
  } else if (cat === "REMOVE_LABEL") {
    action = (
      <span>
        Remove label <LabelTextBlue value={value}></LabelTextBlue>
      </span>
    );
  } else if (cat === "ADD_LABEL") {
    action = (
      <span>
        Add label <LabelTextBlue value={value}></LabelTextBlue>
      </span>
    );
  } else if (cat === "ALERT") {
    action = (
      <span>
        Alert <LabelBgRed value={value}></LabelBgRed>
      </span>
    );
  } else {
    action = JSON.stringify(item);
  }

  return <span>{action}</span>;
};

const LabelBgRed = (props) => {
  return (
    <span className="font-mono bg-red-200 rounded px-2 text-red-900 text-sm">
      {props.value}
    </span>
  );
};

const LabelTextRed = (props) => {
  return (
    <span className="font-mono bg-gray-200 rounded px-2 text-red-600 text-sm">
      {props.value}
    </span>
  );
};

const LabelTextBlue = (props) => {
  return (
    <span className="font-mono bg-gray-200 rounded px-2 text-blue-700 text-sm">
      {props.value}
    </span>
  );
};

function _getUnitStateString(state) {
  const stateMap = {
    0: "Unknown",
    1: "Wait for initialization",
    2: "Inspection",
    3: "Normal optimization",
    4: "Suspicious optimization",
    5: "Pause optimization",
    6: "Hibernation",
    7: "Debug",
    8: "Detention",
    9: "Negative optimization",
  };
  return stateMap[state] || state;
}

function _getUnitStatusString(status) {
  const statusMap = {
    0: "Running",
    1: "Paused",
  };
  return statusMap[status];
}

export default ActionPretty;
