import React from "react";
import _ from "lodash";
import Select from "react-select";

import { BillingAPI } from "apis";

class BillingProfileSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      options: [],
      selectedValue: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.getBillingProfiles = this.getBillingProfiles.bind(this);
  }

  async componentDidMount() {
    await this.getBillingProfiles();
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.selectedValue &&
      prevProps.selectedValue !== this.props.selectedValue
    ) {
      const newSelectedValue = _.find(this.state.options, {
        value: this.props.selectedValue,
      });
      if (!newSelectedValue) {
        await this.getBillingProfiles();
      }
    }
  }

  async getBillingProfiles() {
    const { publisherId } = this.props;
    if (!publisherId) {
      throw new Error("Missing Publisher ID");
    }

    const items = await BillingAPI.getBillingProfiles({ publisherId });
    console.log(items);
    let options = _.map(items, (r) => {
      const { bankAccount, creditCardProfile } = r;
      if (r.method === "CREDIT_CARD") {
        const brief = `****-****-****-${creditCardProfile.card4No} (${creditCardProfile.payerEmail})`;
        return {
          value: r.profileId,
          label: `${r.profileId} - ${r.method}: ${brief}`,
        };
      } else {
        const bankAccountBrief = `[${bankAccount.bankAccountId}] ${bankAccount.accountName} (${bankAccount.accountNo})`;
        return {
          value: r.profileId,
          label: `${r.profileId} - ${r.method}: ${bankAccountBrief}`,
        };
      }
    });

    const selectedValue = _.find(options, { value: this.props.selectedValue });

    this.setState({
      items,
      options,
      selectedValue,
    });
  }

  handleChange(option) {
    this.setState({
      selectedValue: option,
    });

    if (this.props.isMulti) {
      const selectedValues = _.map(option, "value");
      const results = _.filter(this.state.items, (item) => {
        return _.indexOf(selectedValues, item.profileId) !== -1;
      });
      this.props.handleOnChange(results);
    } else {
      const result = _.find(this.state.items, (item) => {
        return option.value === item.profileId;
      });
      this.props.handleOnChange(result);
    }
  }

  render() {
    const { options, selectedValue } = this.state;

    return (
      <>
        <Select
          value={selectedValue}
          onChange={this.handleChange}
          options={options}
          isMulti={this.props.isMulti}
        />
      </>
    );
  }
}

export default BillingProfileSelector;
